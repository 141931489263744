
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/orders">Cart Edit</a></li>
          <li>Edit</li>
        </ul>
        <div class="pull-right">
          <button class="btn-xs btn-primary-outline ml-3" *ngIf="user"><a class="clr-primary" routerLink="/chat/{{user?.get('username')}}" target="_blank">Chat</a></button>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <form class="col-xs-12 p-4 pt-0" #cartForm="ngForm" (submit)="update()">
        <div class="col-xs-12 mb-2" *ngFor="let product of cart?.get('lineItems');let i = index;">
          <p style="margin-top:5px;font-weight:bold">Products</p>
          <div style="padding:3px 10px;background:#5a5a5a;display:inline-block;border-radius:3px;margin:2px 10px 2px 0px;color:white"><i class="fa fa-trash-o pointer" (click)="removeProductFromCart(i)"></i>
            <div style="max-width:500px;display:inline-block;">&nbsp;&nbsp; {{product.title}} [{{product.quantity}}]</div>
          </div><a class="fa fa-plus-square-o ml-3 pointer" (click)="addQuantity(product)"></a><a class="fa fa-minus-square-o ml-3 pointer" (click)="removeQuantity(product, i)"></a>
        </div>
        <div class="col-xs-12 mt-2">
          <input class="form-control" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
          <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
            <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option)">{{option.name}}</mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-xs-12">
          <label style="margin-top:5px;font-weight:bold">Total Amount</label>
          <input class="form-control" [value]="cartAmout" name="amount" disabled="false" placeholder=""/>
        </div>
        <div class="col-xs-12 mb-4">
          <button class="mt-3" type="submit">Update</button>
        </div>
      </form>
      <order-edit></order-edit>
    </div>
  </div>
</div>