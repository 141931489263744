
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Bulk Article</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form class="col-xs-12" role="form" name="bulkArticle" #bulkArticleForm="ngForm">
      <div class="col-xs-12 flex flex-wrap justifySB">
        <div class="col-xs-3" *ngIf="!existingQuestionId">
          <label class="mt-0">Article Image Upload</label>
          <input class="w100" type="file" (change)="articleImage = $event.target.files[0]" name="imageFile" placeholder="Select image" title="Image file"/>
        </div>
        <div class="col-xs-3" *ngIf="!existingQuestionId">
          <label class="mt-0">Thumbnail Image Upload</label>
          <input class="w100" type="file" (change)="thumbnailImage = $event.target.files[0]" name="imageFile" placeholder="Select image" title="Image file"/>
        </div>
        <div class="col-xs-3">
          <label class="mt-0">Excel file</label>
          <input class="w100" type="file" (change)="xlsxFile = $event.target.files[0]" name="xlsxFile" placeholder="Select excel file" title="xlsx file"/>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12" *ngIf="!existingQuestionId">
          <label class="col-xs-12">Notification Text</label>
          <input class="col-xs-12 col-md-6" type="text" [(ngModel)]="notificationText" name="notificationText" placeholder="Enter text for notification here" title="Notification Text" required="required"/>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Job ID</label>
          <input class="col-xs-12 col-md-6" type="text" [(ngModel)]="jobId" (keyup)="verifyJobId()" name="jobId" placeholder="Enter job id here" title="Job ID" required="required"/>
        </div>
      </div>
      <div class="col-xs-12 p-3 mt-4 alert-warning" *ngIf="existingQuestionId">Question with Job ID {{jobId}} already exists, please upload excel file to send existing article to users.</div>
      <div class="col-xs-12 mt-4" *ngIf="existingQuestionId">
        <div class="col-xs-4"><img src="{{existingImageLink}}" width="200px"/></div>
        <div class="col-xs-4 pl-3"><img src="{{existingThumbnailLink}}" width="200px"/></div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" name="submit" [disabled]="!bulkArticleForm.valid" (click)="onSubmit()">Send</button>
    <div class="col-xs-12" *ngIf="success || failures">
      <label>Total success : {{success.length}}</label>
      <div class="col-xs-12 mt-2 fontS2" *ngFor="let fail of failures">{{fail}}</div>
    </div>
  </div>
</mat-dialog-content>