import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, Event } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant, Table } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { Broadcaster } from '../broadcaster';
import { ConnectionService } from '../../services/connection-service';
import { WindowRefService } from '../../services/window-ref-service';
import { AppConfig } from '../../app/app.config';
import { LocalStorage } from '../../services/local-storage-service';
import { PushNotificationModel } from './push-notification';
import { BulkArticleModel } from './bulk-article';
import { ChangePasswordModal } from './change-password';
import { FileUploaderModel } from './pincode-file-uploader';
import { ArticleImageUploadModal } from './articleImageUploadModal';
import { BulkUpdateOrderModal } from './bulk-update-order';
import { UploadAudioDialog } from '../upload-audio';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.html',
  styleUrls: ['./navbar.scss'],
})
export class NavbarComponent {
  app: any;
  router: Router;
  broadcaster: Broadcaster;
  conn: ConnectionService;
  user: any;
  menuItemSelected: number;
  zone: NgZone;
  route: ActivatedRoute;
  window: any;
  url: string;
  courierUrl: string;
  isOperatorRolePresent: boolean;
  uploader: FileUploader;
  userRoles: Array<any> = [];
  showKioskCall: boolean = false;
  isDoctorAdminRolePresent: boolean = false;
  @ViewChild('token', { static: false }) token: ElementRef;
  softphoneDeviceStatus: boolean = true;
  isSoftphoneStatusToggleDisabled: boolean = false;

  constructor(zone: NgZone,
    conn: ConnectionService,
    router: Router,
    route: ActivatedRoute,
    broadcaster: Broadcaster,
    windowRef: WindowRefService,
    public appConfig: AppConfig,
    private storage: LocalStorage,
    private dialog: MatDialog) {
    this.zone = zone;
    this.conn = conn;
    this.router = router;
    this.broadcaster = broadcaster;
    this.route = route;
    this.window = windowRef.nativeWindow;
  }

  async ngOnInit(): Promise<void> {
    this.menuItemSelected = -1;
    this.user = new Table.User();
    const loginSubscription = this.broadcaster.on < string >('UserLogin');
    loginSubscription.subscribe(() => this.zone.run(() => (this.user = new Table.User())));
    await this.conn.findOneUser({ where: { objectId: this.conn.getCurrentUser().id }, project: ['isSoftphoneDeviceActive'] });
    this.softphoneDeviceStatus = this.conn.getCurrentUser().get('isSoftphoneDeviceActive') !== false;
    this.courierUrl = ('/orders?stage=').concat(ApiClientConstant.Order.OrderStageFromShipNow
      .filter((each: ValueOf<typeof ApiClientConstant.Order.CompletedStages.values>,
      ) => !ApiClientConstant.Order.CompletedStages.includes(each)).join());
    if (this.conn.getCurrentUser().get('username') === 'raman') {
      this.courierUrl = `${this.courierUrl},${ApiClientConstant.Order.Stage.CONSULTATION_CREATED}`;
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
    this.app = {
      name: 'CureSkin',
      settings: {
        navbarHeaderColor: 'bg-primary dk',
        navbarCollapseColor: 'bg-info',
        asideColor: 'bg-info bg-gd-dk',
        headerFixed: true,
        asideFixed: true,
        asideFolded: true,
        asideDock: true,
        container: false,
        offScreen: false, // flag for show of sidebar for mobile view
        mobileHeader: false, // flag to show header Nav and Search in mobile view
      },
    };
    this.findUserRoles();
  }

  downloadOrderInShipStage(courier: string): void {
    this.conn.downloadOrderInShipStage(courier)
      .then((url: any) => {
        const fileUrl = `${this.conn.getParseUrl()}/api/file/excel/${url.split('/').pop()}?fileName=${url}`;
        this.window.open(fileUrl, '_blank');
      })
      .catch((err: any) => alert(err.message || JSON.stringify(err)));
  }

  async findUserRoles(): Promise<any> {
    const currentUser = this.conn.getCurrentUser();
    if (!currentUser) {
      return;
    }
    const roles = await this.conn.findUserRoles(currentUser)
      .catch(async (error: any) => {
        if (error.code === 209 && error.message === 'Invalid session token') {
          this.logOut();
        }
        return Promise.reject(error);
      });
    this.storage.setJsonValue('userRoles', roles.map((role: any) => role.get('name')));
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.isDoctorAdminRolePresent = this.userRoles.includes('adminDoctor');
    this.isOperatorRolePresent = this.userRoles.includes('operator');
    this.showKioskCall = this.userRoles.includes('kiosk-doctor');
  }

  openBulkArticle(): void {
    this.dialog.open(BulkArticleModel);
  }

  openPushNotification(): void {
    this.dialog.open(PushNotificationModel);
  }

  openAudioUploadDialog(): void {
    this.dialog.open(UploadAudioDialog);
  }

  openPincodeFileUploaderDialogue(): void {
    this.dialog.open(FileUploaderModel);
  }

  openPassword(): void {
    this.dialog.open(ChangePasswordModal);
  }

  openArticleFileUploaderDialogue(): void {
    this.dialog.open(ArticleImageUploadModal);
  }

  openBulkOrderUpdateDialogue(): void {
    this.dialog.open(BulkUpdateOrderModal);
  }

  logOut(): void {
    this.router.navigate(['/logout']);
  }

  openNewTab(url: string): void {
    this.window.open(url, '_blank');
  }

  isActive(baseURL: string, fullMatch?: boolean): boolean {
    if (!this.url) return false;
    if (fullMatch) return this.url === baseURL;
    return this.url.startsWith(baseURL);
  }

  copyToken(): void {
    const token = this.storage.getJsonValue('Parse/myAppId/currentUser').sessionToken;
    this.token.nativeElement.value = token;
    this.token.nativeElement.select();
    this.window.document.execCommand('copy');
    this.broadcaster.broadcast('NOTIFY', { message: 'Token copied', type: this.appConfig.Shared.Toast.Type.SUCCESS });
  }

  toggleSelection(selection: number): void {
    this.zone.run(() => {
      if (this.window.innerWidth > 767 || this.menuItemSelected === selection) {
        this.menuItemSelected = -1;
        return;
      }
      this.menuItemSelected = selection;
    });
  }

  validateTreeJSON(): void {
    this.conn.validateTree()
      .then(() => {
        this.broadcaster.broadcast('NOTIFY', { message: 'Tree JSON is fine.', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      })
      .catch((error: Error) => {
        this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR, timeout: 6000 });
      });
  }

  async handleSoftphoneDeviceStatusToggle(): Promise<void> {
    this.isSoftphoneStatusToggleDisabled = true;
    const status = await this.conn.updateSoftphoneDeviceStatus(this.softphoneDeviceStatus);
    this.isSoftphoneStatusToggleDisabled = false;
    if (status) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Successfully updated the softphone device status',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      });
    }
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
