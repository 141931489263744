import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MultiRegimenSelectionComponent } from './multi-regimen-selection.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
  declarations: [MultiRegimenSelectionComponent],
  exports: [MultiRegimenSelectionComponent],
})
export class MultiRegimenSelectionModule {
}
