
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Similar Language Strings</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body mt-0">
    <div class="col-xs-12 p-3 pl-0">
      <div class="flex col-xs-10">
        <label class="col-sm-6">String</label>
        <label class="col-sm-6 ml-2">Tags</label>
      </div>
    </div>
    <div class="col-xs-12 fontL my-2" *ngFor="let item of data.languageStrings">
      <div class="flex col-xs-10">
        <div class="col-sm-6">{{item.get('en')}}</div>
        <div class="col-sm-6"><span *ngFor="let tag of item.get('tags')">{{tag.get('name')}},</span></div>
      </div>
      <div class="col-xs-2">
        <button class="ml-3 pull-right" (click)="selectTag(item)">Use it</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-primary-outline pull-right" (click)="save()">Create New</button>
  </div>
</mat-dialog-content>