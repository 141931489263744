import { Component } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { ApiClientConstant, ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { WindowRefService } from '../../../services/window-ref-service';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';
import { PendingCallsActionComponent } from './pending-calls-action/pending-calls-action.component';

@Component({ selector: 'calls-pending-calls', templateUrl: './pending-calls.html' })
export class PendingCallsComponent {
  gridApi: GridApi;
  dataSource: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  count: number = 0;
  teams: Array<string> = [];
  typeOptions: Array<string> = [];
  operatorFilter: Array<string> = [];
  doctorFilter: Array<string> = [];
  languageFilter: Array<string> = [];
  teamFilter: Array<string> = [];
  typeFilter: Array<string> = [];
  resetTeamFilter: boolean = false;
  resetLanguageFilter: boolean = false;
  resetOperatorFilter: boolean = false;
  resetDoctorFilter: boolean = false;
  resetTypeFilter: boolean = false;
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  searchKey: string;
  isRegimenConatainsTablet: any = {};
  regimenClass: any = {};
  constructor(
    private windowRefService: WindowRefService,
    private connectionService: ConnectionService,
    public appConfig: AppConfig,
    private storage: LocalStorage) { }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    const roles = this.storage.getJsonValue('userRoles') || [];
    const teams = Object.keys(this.appConfig.Shared.Role.Name)
      .map((each: string) => this.appConfig.Shared.Role.Name[each]);
    this.teams = roles.filter((each: any) => teams.includes(each));
    if (!this.teams.includes(this.appConfig.Shared.Role.Name.CHAT_SUPPORT)) {
      this.operatorFilter = [];
    }
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Action',
      field: 'objectId',
      width: 150,
      cellRenderer: PendingCallsActionComponent,
      pinned: 'left',
    }, {
      headerName: 'userMod',
      field: 'user.mod20',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.data) {
          return '';
        }
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        const uniqueValue = Math.abs(params.value) % 11;
        eDiv.innerHTML = uniqueValue;
        return eDiv;
      },
    }, {
      headerName: 'Name',
      field: 'user.PatientName',
      cellRenderer: (params: any): any => {
        let eDiv;
        if (!params.data) {
          eDiv = this.windowRefService.nativeWindow.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        let highlightedClass = '';
        if (this.isRegimenConatainsTablet[params.data.referenceId]?.isTablet) {
          highlightedClass = 'list-label list-label-bg-red';
        }
        eDiv.innerHTML = `<a href="/chat/${params.data.user?.objectId}" class='${highlightedClass}', target='_blank'>${params.value}</a>`;
        return eDiv;
      },
      width: 200,
    }, {
      headerName: 'Type',
      field: 'type',
      width: 100,
    }, {
      headerName: 'Amount Spent',
      field: 'totalAmountSpent',
      name: 'totalAmountSpent',
      width: 100,
      sortable: true,
    }, {
      headerName: 'Time',
      field: 'requestTime',
      width: 120,
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value.iso).format('MMM DD,hh:mm a');
        return eDiv;
      },
    }, {
      headerName: 'Message',
      field: 'referenceMessage',
      flex: 1,
    }, {
      headerName: 'Class',
      width: 70,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = '';
        if (this.isRegimenConatainsTablet[params.data.referenceId]?.regimenClass) {
          eDiv.innerHTML = this.isRegimenConatainsTablet[params.data.referenceId]?.regimenClass;
        }
        return eDiv;
      },
    }, {
      headerName: 'Recent Note',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        const pendingCallType = [ApiClientConstant.PendingCall.Type.InitialOrder,
          ApiClientConstant.PendingCall.Type.AbandonedCart,
          ApiClientConstant.PendingCall.Type.DoctorOrder,
          ApiClientConstant.PendingCall.Type.OrderConfirmation,
          ApiClientConstant.PendingCall.Type.OnlinePaymentPending,
          ApiClientConstant.PendingCall.Type.CanceledOrder];
        if (this.isRegimenConatainsTablet[params.data.referenceId]?.message && pendingCallType.includes(params.data.type)) {
          eDiv.innerHTML = this.isRegimenConatainsTablet[params.data.referenceId]?.message;
        }
        return eDiv;
      },
    }];
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async getRecentNoteAndCheckIfTablet(referenceIds: Array<any>): Promise<any> {
    const payload: RequestQueryPayload<Table.Order> = {
      where: {
        $or: referenceIds,
      },
      include: ['products', 'regimen'],
      project: ['products.isTablet' as 'products', 'notes', 'type', 'regimen.class' as 'regimen'],
    };
    const result = await this.connectionService.findOrders(payload);
    result.forEach((order: any) => {
      const productOfTablet = order.get('products').find((each: any): any => each.get('isTablet'));
      let isTablet: boolean = false;
      if (productOfTablet && order.get('type') === ApiClientConstant.Order.Type.REGIMEN) {
        isTablet = true;
      }
      let message;
      if (order.get('notes')?.length) {
        const recentMessage = order.get('notes')[order.get('notes').length - 1].message;
        message = recentMessage;
      }
      let regimenClass;
      if (order.get('regimen')?.get('class')) {
        regimenClass = order.get('regimen').get('class');
      }
      Object.assign(this.isRegimenConatainsTablet,
        { [order.id]: { isTablet, message, regimenClass } });
    });
  }

  addTeamFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>, teamFieldName: string = 'teams'): void {
    if (!this.teamFilter.length) {
      return;
    }
    const requestPayload = requestPayload_;
    delete requestPayload.where.teams;
    requestPayload.where.$or = this.teamFilter.map((team: string) => {
      if (team === ApiClientConstant.Role.Name.CHAT_SUPPORT && this.operatorFilter.length) {
        return { allocatedOperator: { $in: this.operatorFilter }, [teamFieldName]: team };
      }
      if (team === ApiClientConstant.Role.Name.DOCTOR && this.doctorFilter?.length) {
        return { allocatedDoctor: { $in: this.doctorFilter }, [teamFieldName]: team };
      }
      return { [teamFieldName]: team };
    });
  }

  async loadData(params: any): Promise<Array<{ [key: string]: unknown }>> {
    const requestPayload: RequestQueryPayload<Table.PendingCall> = {
      where: {
        status: 'Requested',
        requestTime: { $lte: new Date() },
        languagePreference: { $exists: true },
        type: { $exists: true },
        teams: { $exists: true },
      },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['user'],
      project: ['type', 'referenceId', 'referenceMessage', 'user.MobileNumber' as 'user',
        'user.alternateNumber' as 'user', 'user.PatientName' as 'user', 'user.username' as 'user',
        'user.userType' as 'user', 'user.orderState' as 'user', 'requestTime' as 'user', 'languagePreference',
        'teams', 'user.tags' as 'user', 'user.age' as 'user', 'totalAmountSpent', 'user.mod20' as 'user', 'supportTicket'],
    };
    if (params.sortModel[0]) {
      if (params.sortModel[0].sort === 'asc') requestPayload.ascending = params.sortModel[0].colId;
      if (params.sortModel[0].sort === 'desc') requestPayload.descending = params.sortModel[0].colId;
    } else {
      requestPayload.descending = 'requestTime';
    }
    if (this.searchKey) {
      requestPayload.where.referenceMessage = { $regex: this.searchKey, $options: 'i' };
    }
    this.addTeamFilter(requestPayload);
    this.addLanguageFilter(requestPayload);
    this.addTypeFilter(requestPayload);
    let data: Array<any>;
    [data, this.count] = await Promise.all([
      ApiConnector.find(Table.PendingCall, requestPayload)
        .then((items: Array<any>) => items.map((item: any) => item.toJSON())),
      ApiConnector.count(Table.PendingCall, requestPayload),
    ]);
    if (!data.length) {
      return data;
    }
    const referenceIds: Array<any> = [];
    const filteredData: Array<any> = [];
    data.forEach((pendingCall: any) => {
      referenceIds.push({ objectId: pendingCall.referenceId });
      if (pendingCall.type === ApiClientConstant.PendingCall.Type.UnpaidUserDoctorCall
        && ['DELIVERED', 'PROCESSING'].includes(pendingCall.user.orderState)) {
        return;
      }
      filteredData.push(pendingCall);
    });
    data = filteredData;
    const payload: RequestQueryPayload<Table.Order> = {
      where: {
        $or: referenceIds,
      },
      include: ['serviceInfo'],
    };
    const result = await this.connectionService.findOrders(payload);
    result.forEach((element: any): any => {
      if (element.get('serviceInfo') && element.get('serviceInfo')[0]?.type === 'DIET_CONSULTATION') {
        data.forEach((elementData: any, index: any): any => {
          if (elementData.referenceId === element.id) {
            data.splice(index, 1);
          }
        });
      }
    });
    await this.getRecentNoteAndCheckIfTablet(referenceIds);
    return data;
  }

  updateLanguageFilter(languageFilter: Array<any>): void {
    this.resetLanguageFilter = false;
    this.languageFilter = languageFilter;
    this.reset();
  }

  addLanguageFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>): void {
    const requestPayload = requestPayload_;
    if (this.languageFilter.length) {
      requestPayload.where.languagePreference = { $in: this.languageFilter };
    }
  }

  addTypeFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>): void {
    const requestPayload = requestPayload_;
    if (this.typeFilter.length) {
      requestPayload.where.type = { $in: this.typeFilter };
    }
  }

  getAllTypes(): void {
    const teams = this.teamFilter;
    const teamTypes = this.appConfig.Shared.TeamTypes;
    const typeSet: Set<string> = new Set();

    teams.forEach((teamName:string) => {
      (teamTypes[teamName.toUpperCase()] || []).forEach((type:string) => {
        typeSet.add(type);
      });
    });
    this.typeOptions = Array.from(typeSet);
  }

  updateTeamFilter(teamFilter: Array<string>): void {
    this.resetTeamFilter = false;
    this.teamFilter = teamFilter;
    this.reset();
  }

  updateTypeFilter(typeFilter: Array<string>): void {
    this.resetTypeFilter = false;
    this.typeFilter = typeFilter;
    this.reset();
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  reset(): void {
    if (!this.gridApi || !this.operatorFilter || !this.languageFilter || !this.teamFilter || !this.operatorFilter) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  updateOperatorFilter(operatorFilter: Array<any>): void {
    this.resetOperatorFilter = false;
    this.operatorFilter = operatorFilter;
    this.reset();
  }

  updateDoctorFilter(doctorFilter: Array<any>): void {
    this.resetDoctorFilter = false;
    this.doctorFilter = doctorFilter;
    this.reset();
  }

  resetFilters(): void {
  }
}
