import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiConnector } from 'api-client';
import { AppConfig } from '../../../app/app.config';
import { InputType } from '../../../../typings/client/input';
import { InputHelperService } from '../input-helper';

@Component({
  selector: 'input-select',
  templateUrl: './input-select.html',
})
export class InputSelectComponent {
  @Input('prefix') prefix: string;
  @Input() placeholder: string;
  @Input('id')
  set onUpdateId(id: string) {
    this.id = id;
    this.onInputChange();
  }
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('type') type: string;
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input() defaultSelect: string;
  @Input('options')
  set onUpdateOptions(options: Array<InputType.SelectOption>) {
    if (!options) {
      this.options = [];
      return;
    }
    if (this.checkOptionValidity(options)) {
      this.options = options;
    } else {
      throw new Error(`Invalid Options: Expected option format for ${this.name
      } is { "value": string, "parseValue"?: any, "display": string }`);
    }
  }
  options: Array<InputType.SelectOption> = [];
  @Input('required') required: boolean = false;
  @Input('class') className: string = '';
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }
  disabled: boolean = false;
  @Output('onValueChanged') onValueChanged: EventEmitter<any> = new EventEmitter();

  ui: any = {};
  id: string;
  parseValue: any = '';
  parseObj: any;
  name: string;
  skipFirstUpdate: boolean = true;

  constructor(public appConfig: AppConfig, private inputHelper: InputHelperService) {}

  ngOnInit(): void {
    if (this.defaultSelect) {
      this.parseValue = this.defaultSelect;
    }
  }

  onInputChange(): void {
    if (!this.parseObj || !this.name) return;
    this.parseValue = this.inputHelper.getValue(this.parseObj, this.name);
    if (this.parseValue instanceof (ApiConnector as unknown as {
      getMongoToParseQueryBase: () => ({ getParse: () => ({ Object: any }) })
    }).getMongoToParseQueryBase().getParse().Object) {
      this.parseValue = this.parseValue.id;
    }
    if (this.id) {
      this.parseValue = this.inputHelper.getValue(this.parseValue, this.id);
      this.checkParseValueAndUpdate(this.name, this.parseObj, this.parseValue);
    }
    if (this.skipFirstUpdate) {
      this.skipFirstUpdate = false;
      return;
    }
    this.onValueChanged.emit(this.parseValue);
  }

  checkParseValueAndUpdate(name: string, parseObject: any, parseValue: string, maxAttempt: number = 10): void {
    if (this.name !== 'instructionSet') return;
    if (!this.options.length && maxAttempt > 0) {
      setTimeout(() => this.checkParseValueAndUpdate(name, parseObject, parseValue, maxAttempt - 1), 1000);
      return;
    }
    if (maxAttempt <= 0) {
      return;
    }
    const instructionFound = this.options.find((row: InputType.SelectOption) => (row.value === parseValue));
    if (instructionFound) return;
    const selectedInstructionTitle = parseObject.instructionSet.title.trim();
    const selectedInstruction = this.options
      .find((row: InputType.SelectOption) => (row.parseValue.title.trim() === selectedInstructionTitle));
    if (!selectedInstruction) return;
    this.parseValue = selectedInstruction.parseValue.id;
    // this.onValueChanged.emit(this.parseValue);
  }

  valueUpdated(): void {
    const selectedRow = this.options.find((row: InputType.SelectOption) => (row.value === this.parseValue));
    const value = selectedRow.parseValue || selectedRow.value;
    if (this.inputHelper.getValue(this.parseObj, this.name) !== value) {
      this.inputHelper.setValue(this.parseObj, this.name, value);
    }
    this.onValueChanged.emit(value);
  }

  private checkOptionValidity(options: Array<InputType.SelectOption>): boolean {
    return !options.some((row_: InputType.SelectOption) => {
      const row = row_;
      // eslint-disable-next-line angular/typecheck-number
      if (!row.display && (typeof row.display) !== 'number') return true;
      if (row.disabled) return false;
      // eslint-disable-next-line angular/typecheck-number
      if (!row.value && (typeof row.value) !== 'number') return true;
      // eslint-disable-next-line angular/typecheck-number
      if (typeof row.value !== 'string' && (typeof row.value) !== 'number') return true;
      return false;
    });
  }
}
