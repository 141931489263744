
<div class="hbox">
  <div class="flex-box scrollable">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a class="pl-4" routerLink="/products/medialink" href="/products/medialink">MediaLink</a></li>
          <li>{{mediaLinkObj?.id ? 'Edit' : 'New' }}</li>
        </ul>
      </div>
      <form class="col-xs-12">
        <div>
          <div class="col-xs-12 m-2">
            <label class="col-xs-6 alert-danger">* Note : - Kindly wait after creating new or updating old medialink , It will Takes some time to update.</label>
          </div>
          <div class="col-xs-12 flex m-2">
            <label class="col-xs-2">Media Type</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 m-2">
              <input-select [parseObj]="mediaLinkObj" name="mediaType" [options]="mediaTypeOptions || []" placeholder="Media Type" required="required"></input-select>
            </div>
          </div>
          <div class="col-xs-12 flex m-2">
            <label class="col-xs-2">Media Sub Type</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 m-2">
              <input-select [parseObj]="mediaLinkObj" name="mediaSubType" [options]="subTypeOptions || []" placeholder="Media Sub Type" required="required"></input-select>
            </div>
          </div>
          <div class="col-xs-12 flex m-2">
            <label class="col-xs-2">Title</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 m-2">
              <input-text [parseObj]="mediaLinkObj" name="title" placeholder="Title"></input-text>
            </div>
          </div>
          <div class="col-xs-12 flex m-2" *ngIf="products.length">
            <label class="col-xs-2">Products</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 m-2">
              <div class="flex" *ngFor="let product of products;let i = index;"><a class="inline product-label night" routerLink="/products/catalog/{{product.id}}">{{product.get('title')}}</a><i class="fa fa-trash-o pointer" *ngIf="!mediaLinkObj.id" (click)="removeProduct(i)"></i></div>
            </div>
          </div>
          <div class="col-xs-12 flex m-2" *ngIf="!mediaLinkObj?.id">
            <label class="col-xs-2">Select Products</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 m-2">
              <input class="form-control" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto" required="required"/>
              <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
                <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option)">{{option.name}}</mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div class="col-xs-12 flex m-2" *ngIf="mediaLinkObj?.id">
            <label class="col-xs-2">Regimen Cover Image</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 m-2"><img class="mt-3" style="padding-left:15px" height="200px" widht="200px" src="{{coverImageLink}}"/></div>
          </div>
          <div class="col-xs-12 flex m-2">
            <label class="col-xs-2">Upload Regimen Cover Image</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 m-2">
              <input-single-image-upload [parseObj]="mediaLinkObj" name="link" [accept]="'image/png'" (fileChanged)="fileChanged($event)" [username]="username"></input-single-image-upload>
            </div>
          </div>
          <button class="pull-left m-5" (click)="saveMediaLink()" type="button">{{mediaLinkObj?.id ? 'UPDATE' : 'CREATE'}}</button>
        </div>
      </form>
    </div>
  </div>
</div>