
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Treatment Progress</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal(false)"></i>
  </div>
  <div class="modal-body">
    <div class="treatment-progress section w100">
      <div class="content">
        <div class="bar">
          <div class="flex-score">
            <div class="div flex">
              <div class="concern-name"></div>
            </div>
            <div class="div flex" style="gap: 5px">
              <div class="progress-title">INCREASED</div>
              <div class="progress-title">SAME</div>
              <div class="progress-title">BETTER</div>
              <div class="progress-title">GOOD</div>
            </div>
          </div>
        </div>
        <div class="bar flex" *ngFor="let progress of treatmentProgress; let i=index">
          <div class="flex-score bdr-btm">
            <div class="div flex">
              <div class="concern-name">{{progress.issue}}</div>
            </div>
            <div class="div flex" style="gap: 5px">
              <div class="increased progress-score justifyC alignC" (click)="updateScore(i, 0)"><i class="fa-solid fa-check" *ngIf="progress.score == 0"></i></div>
              <div class="same progress-score justifyC alignC" (click)="updateScore(i, 1)"><i class="fa-solid fa-check" *ngIf="progress.score == 1"></i></div>
              <div class="better progress-score justifyC alignC" (click)="updateScore(i, 3)"><i class="fa-solid fa-check" *ngIf="progress.score == 3"></i></div>
              <div class="good progress-score justifyC alignC" (click)="updateScore(i, 4)"><i class="fa-solid fa-check" *ngIf="progress.score == 4"></i></div>
            </div>
          </div>
          <div class="flex-delete">
            <div class="delete"><i class="fa fa-trash-o" (click)="deleteBar(i)"></i></div>
          </div>
        </div>
        <div class="add-concern-button" (click)="addNewConcern()">Add new +</div>
      </div>
      <button class="pull-right" type="button" (click)="hideModal(true)">Save  </button>
    </div>
  </div>
</mat-dialog-content>