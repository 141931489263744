import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegimenSopComponent } from './regimen.component';
import { RegimenSopListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'regimens/sop',
    component: RegimenSopComponent,
    loadChildren: (): any => RegimenSopListModule,
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [RegimenSopComponent],
  exports: [RegimenSopComponent],
})
export class RegimeSopModule {}
