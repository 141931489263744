import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ViewComponent } from './view.component';
import { LanguageViewModule } from '../../../../components/language-string/language-view';

const ROUTES:Routes = [
  {
    path: '',
    component: ViewComponent,
  },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  LanguageViewModule,
  RouterModule.forChild(ROUTES),
],
declarations: [ViewComponent],
exports: [ViewComponent] })

export class ViewModule { }
