import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LanguageStringMultipleSelectionComponent } from './language-string-multiple-selection.component';
import { LanguageStringAutoCompleteModule } from '../language-string-auto-complete';
import { LanguageViewModule } from '../language-view';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LanguageStringAutoCompleteModule,
    LanguageViewModule,
  ],
  declarations: [LanguageStringMultipleSelectionComponent],
  exports: [LanguageStringMultipleSelectionComponent],
})

export class LanguageStringMultipleSelectionModule {}
