import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { MatDialogModule } from '@angular/material/dialog';
import { RegimenProductsViewComponent } from './regimen-products-view.component';
import { RegimenLogsViewModal } from './regimen-logs-view';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatDialogModule,
    MomentModule,
  ],
  declarations: [RegimenProductsViewComponent, RegimenLogsViewModal],
  exports: [RegimenProductsViewComponent],
})
export class RegimenProductsViewModule {
}
