import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'consultationSession-list', templateUrl: './consultationDialog.html' })
export class ConsultationSessionsList {
  consultationsList: Array<any>;
  selectedConsultation: string;

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private dialogRef: MatDialogRef<ConsultationSessionsList>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit(): void {
    this.consultationsList = this.data.consultationsList;
  }

  selectConsultation(): void {
    this.dialogRef.close(this.selectedConsultation);
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
