import * as moment from 'moment';
import { NgZone, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { WindowRefService } from 'src/services/window-ref-service';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'comingInstantCheckupCallList',
  templateUrl: './coming-instant-checkup-list.html',
  styleUrls: ['./coming-instant-checkup-list.scss'],
})

export class ComingInstantCheckupListComponent implements OnDestroy {
  gridApi: GridApi;
  tags: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  count: any;
  dataSource: any;
  static parameters: any = [ActivatedRoute, NgZone, ConnectionService, AppConfig, WindowRefService];
  window: any;
  constructor(private route: ActivatedRoute, private zone: NgZone, private conn: ConnectionService, private appConfig: AppConfig,
    windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.listViewSetup();
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const datas = await this.loadMore();
        if (params.startRow === 0 && !datas.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(datas, datas.length === this.ui.grid.pageSize ? -1 : params.startRow + datas.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'User Name',
      field: 'user.PatientName',
      pinned: 'left',
      editable: true,
      width: 270,
      cellRenderer: (params: any): any => {
        let eDiv;
        if (!params.data) {
          eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<a href='/chat/${params.value.UserId
          || params.data.user.username}' target='_blank'>${params.value || params.data.user.PatientName}</a>`;
        return eDiv;
      },
    }, {
      headerName: 'Contact Number',
      field: 'user.MobileNumber',
      width: 150,
    }, {
      headerName: 'Created At',
      field: 'createdAt',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.data || !params.value) {
          return '';
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `${moment(params.value).format('MMM DD, HH:mm A')}`;
        return eDiv;
      },
    }, {
      headerName: 'Next Follow Up Date',
      field: 'nextFollowUpDate',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data || !params.value) {
          return '';
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `${moment(params.value.iso).format('MMM DD, HH:mm A')}`;
        return eDiv;
      },
    }];
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 }, limit: 100 };
    this.setUpGrid();
  }

  async loadMore(): Promise<Array<any>> {
    let datas = await this.conn.getFollowUpWithStatePending('PENDING', false, this.ui.limit, this.ui.page * this.ui.limit);
    datas = JSON.parse(JSON.stringify(datas));
    return datas;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.tags;
    delete this.ui;
    delete this.route;
    delete this.zone;
    delete this.conn;
  }
}
