import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service/index';

@Component({
  selector: 'market-product-edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent {
  marketProductObj: any;
  name: string;
  alias: string;
  subString: string;
  label: string;
  nameAliases: Array<string> = [];
  nameSubStrings: Array<string> = [];
  labels: Array<string> = [];

  constructor(private appConfig: AppConfig,
              private conn: ConnectionService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.marketProductObj = new Table.MarketProduct();
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.marketProduct) {
        this.marketProductObj = this.route.parent.snapshot.data.marketProduct;
        this.name = this.marketProductObj.get('name');
        this.nameAliases = this.marketProductObj.get('aliases') || [];
        this.nameSubStrings = this.marketProductObj.get('subStringKeys') || [];
        this.labels = this.marketProductObj.get('label') || [];
      }
    });
  }

  addAlias(): void {
    this.nameAliases.push(this.alias);
    this.alias = '';
  }

  addSubStrings(): void {
    this.nameSubStrings.push(this.subString);
    this.subString = '';
  }

  removeAlias(index: number): void {
    this.nameAliases.splice(index, 1);
  }

  removeSubStrings(index: number): void {
    this.nameSubStrings.splice(index, 1);
  }

  addLabel(): void {
    this.labels.push(this.label);
    this.label = '';
  }

  removeLabel(index: number): void {
    this.labels.splice(index, 1);
  }

  async saveMarketProduct(): Promise<any> {
    try {
      if (this.name !== this.marketProductObj.get('name')) this.marketProductObj.set('name', this.name);
      this.marketProductObj.set('label', this.labels);
      this.marketProductObj.set('aliases', this.nameAliases);
      this.marketProductObj.set('subStringKeys', this.nameSubStrings);
      try {
        await this.marketProductObj.save();
      } catch (error) {
        alert(error.message || error);
      }
      await this.router.navigate(['/products/marketProducts']);
    } catch (err) {
      alert(err.message);
    }
  }
}
