import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadFileService } from '../../services/uploadFileService/uploadFile.service';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { WindowRefService } from '../../services/window-ref-service';
import { Broadcaster } from '../broadcaster';

@Component({ selector: 'instruction-upload', templateUrl: './instruction-video-upload.html' })
export class InstructionVideoUploadDialog {
  window: any;
  languages: Array<string> = ['en', 'hi', 'te', 'ta', 'mr', 'ma', 'kn', 'bn'];
  files: { en?: File, hi?: File, te?: File, ta?: File, mr?: File, ma?: File, kn?: File, bn?: File } = {};
  instructionVideoUrl: { en?: string, hi?: string, te?: string, ta?: string, mr?: string, ma?: string, kn?: string, bn?: string } = {};
  constructor(private upload: UploadFileService,
    private appConfig: AppConfig,
    private conn: ConnectionService,
    private windowRef: WindowRefService,
    private broadcasterService: Broadcaster,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<InstructionVideoUploadDialog>) {
    if (this.data.paidOrUnpaid === this.appConfig.Shared.Catalog.INSTRUCTION_VIDEO_TYPE.PAID) {
      this.instructionVideoUrl = this.data.instruction.paidInstructionVideo || {};
    } else if (this.data.paidOrUnpaid === this.appConfig.Shared.Catalog.INSTRUCTION_VIDEO_TYPE.UNPAID) {
      this.instructionVideoUrl = this.data.instruction.unpaidInstructionVideo || {};
    }
    this.window = windowRef.nativeWindow;
  }

  uploadFiles(file: any, lang: string): void {
    this.files[lang] = file;
  }

  public uploadToAws(lang: string): void {
    if (!this.data.instruction.partOfDay) {
      this.broadcasterService.broadcast('NOTIFY', {
        message: 'partOfDay missing',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return;
    }
    const filename = `${this.data.instruction.id}_${lang.toLowerCase()}_${this.data.paidOrUnpaid.toLowerCase()}`
    + `_${this.data.instruction.partOfDay.toLowerCase()}.mp4`;
    if (!this.files[lang]) {
      this.broadcasterService.broadcast('NOTIFY', {
        message: 'File not found',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return;
    }
    this.upload.uploadFileViaSignedURL({
      file: this.files[lang],
      bucket: 'HEALLO_INSTRUCTION_VIDEOS',
      username: this.conn.getCurrentUser().get('username'),
      filename,
    })
      .then((data: any) => {
        this.instructionVideoUrl[lang] = data.publicURL;
      });
  }

  public hideModal(passData?: boolean): void {
    if (passData) {
      this.dialogRef.close(this.instructionVideoUrl);
      return;
    }
    this.dialogRef.close();
  }
}
