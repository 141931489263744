import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EditComponent } from './edit.component';
import { FilePickerModule } from '../../../../components/file-picker';
import { DirectivesModule } from '../../../../components/directives.module';

const ROUTES: Routes = [
  { path: '', component: EditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    FilePickerModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
})
export class EditModule {
}
