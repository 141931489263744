
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Order Stage</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form #stageForm="ngForm">
      <div class="col-xs-12">
        <label class="col-xs-12 mt-0">Choose any Stage</label>
        <select class="col-xs-12" [(ngModel)]="dropdown.selectedStage" name="selectedStage">
          <option *ngFor="let each of keysOfDropDown;" [ngValue]="each">{{each}}</option>
          <option value="SELECT_STAGE" selected="selected" disabled="disabled">SELECT_STAGE</option>
        </select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" [disabled]="dropdown.selectedStage === 'SELECT_STAGE'" (click)="addNewStage()">Add</button>
  </div>
</mat-dialog-content>