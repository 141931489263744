import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../../../components/directives.module';
import { RegimenListComponent } from './list.component';
import { LanguageStringTagSelectionModule } from '../../../../components/language-string/language-string-tag-selection';

const ROUTES: Routes = [
  { path: '', component: RegimenListComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    LanguageStringTagSelectionModule,
    AgGridAngular,
  ],
  declarations: [RegimenListComponent],
  exports: [RegimenListComponent],
})
export class RegimenListModule {}
