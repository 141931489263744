import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiClientConstant, ParseKeys, Table } from 'api-client';
import { AppConfig } from '../../../../app.config';
import { TypeIndication } from '../indication-type';

@Component({
  selector: 'indication-view',
  templateUrl: './indication-view.html',
  styleUrls: ['./indication-view.scss'],
})
export class IndicationViewComponent implements OnDestroy {
  indicationTreeObj: any;
  subscriptions: Array<Subscription>;
  regimenQuestionMap: Array<TypeIndication>;
  static parameters: any = [ActivatedRoute, Router, AppConfig];
  constructor(private route: ActivatedRoute, private router: Router, public appConfig: AppConfig) {
  }

  ngOnInit(): void {
    this.subscriptions = [];
    this.indicationTreeObj = new Table.Tree();
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.subTree) {
        this.indicationTreeObj = this.route.parent.snapshot.data.subTree;
        const questionMap = {};
        this.indicationTreeObj.get('questions').forEach((question: any) => (questionMap[question.get('uniqueIdentifier')] = question));
        this.regimenQuestionMap = this.indicationTreeObj.get('nodeMap').map(({ regimen, nodes }: any) => {
          const questions = nodes.map(({ uniqueIdentifier, answer, questionId }: any) => {
            const title = questionMap[uniqueIdentifier].get('title');
            return { answer, title, uniqueIdentifier, questionId };
          });
          return { regimen, questions };
        });
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.route;
    delete this.appConfig;
    delete this.subscriptions;
    delete this.indicationTreeObj;
    delete this.regimenQuestionMap;
  }

  async copyTree(): Promise<any> {
    try {
      let newTreeName = prompt('Enter new tree name');
      if (newTreeName.split('_').length === 1) {
        newTreeName = `${this.indicationTreeObj.get('name').split('_')[0]}_${newTreeName}`;
      }
      const newTree = new Table.Tree();
      const fieldsToCopy = Object
        .keys(JSON.parse(JSON.stringify(this.indicationTreeObj)))
        .filter((field: string) => !['ACL', 'objectId', 'createdAt', 'updatedAt', 'name'].includes(field));
      fieldsToCopy.forEach((field: ParseKeys<Table.Tree>) => newTree.set(field, this.indicationTreeObj.get(field)));
      newTree.set('name', newTreeName);
      await newTree.save();
      await this.router.navigate(this.router.url.replace(this.indicationTreeObj.get('name'), newTree.get('name')).split('/'));
    } catch (err) {
      alert(err.message || err);
    }
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
