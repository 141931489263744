import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { WindowRefService } from '../../../services/window-ref-service';

@Component({ selector: 'list', templateUrl: './list.html' })
export class ListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  searchKey: string;

  // Local Variables
  data: Array<any> = [];
  static parameters: any = [Router, ConnectionService, NgZone, AppConfig, WindowRefService, ActivatedRoute];
  constructor(private router: Router, private conn: ConnectionService, private zone: NgZone, public appConfig: AppConfig,
    windowRef: WindowRefService, private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.listViewSetup();
    this.route.queryParams.subscribe(() => this.reset());
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite' as const, pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) {
              this.gridApi.showNoRowsOverlay();
            } else {
              this.gridApi.hideOverlay();
            }
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'UserId',
        field: 'username',
        pinned: 'left',
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          let eDiv;
          url.splice(url.length - 1, 1);
          if (!params.data) {
            eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = 'Loading ...';
            return eDiv;
          }
          eDiv = this.window.document.createElement('div');
          eDiv.style = 'display:flex;justify-content:center;align-items:center';
          let className;
          const paidStatus = this.conn.isPaidUser(params.data);
          if (paidStatus === 1) className = 'fa fa-rupee mLR5 text-warning';
          else if (paidStatus === 0) className = 'fa fa-rupee mLR5 text-success';
          else className = 'fa fa-rupee mLR5 text-danger';
          const id = params.value || params.data.username;
          eDiv.innerHTML = `<span class='${className}'></span><a class='col-xs-12 no-padder ellipsis' target="_blank" href='
            ${url.join('/')}/chat/${id}'>${id} (${this.conn.getUserHash(id)})</a>`;
          return eDiv;
        },
        width: 300,
        filter: true,
      },
      {
        headerName: 'Patient Name',
        field: 'PatientName',
        width: 150,
        filter: true,
      },
      {
        headerName: 'Mobile Number',
        field: 'MobileNumber',
        width: 150,
        filter: true,
      },
      {
        headerName: 'Alternate Number',
        field: 'alternateNumber',
        width: 150,
        filter: true,
      },
      {
        headerName: 'Gender',
        field: 'Gender',
        width: 100,
        filter: true,
      },
      {
        headerName: 'Type',
        field: 'type',
        width: 100,
        filter: true,
      },
      {
        headerName: 'Language',
        field: 'languagePreference',
        width: 100,
        filter: true,
      },
    ];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadData(params: any): Promise<any> {
    if (this.searchKey && this.searchKey.length === 10) {
      const user = await this.conn.getUserByNumber(this.searchKey);
      if (user) {
        return Promise.resolve(JSON.parse(JSON.stringify([user])));
      }
    }
    const searchOn = ['username', 'PatientName', 'MobileNumber', 'alternateNumber', 'type'];
    const where: any = {};
    const payload: any = { offset: params.startRow, limit: this.ui.grid.pageSize, searchOn, searchKey: this.searchKey };
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((key: any) => {
        where[key] = params.filterModel[key].filter;
      });
    }
    payload.where = where;
    const users = await this.conn.getUsers(payload);
    return Promise.resolve(JSON.parse(JSON.stringify(users)));
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.data;
    delete this.ui;
  }
}
