<div class="info-header-container">
  <div class="reg-type">Regimen type: </div>
  <div class="flex">
    <div class="totalSP">{{orderObj.fixedPrice}}</div>
    <div class="noProducts">({{orderObj.regimen.products.length}} products)</div>
  </div>
  <div class="desc">
    <ng-container *ngFor="let product of orderObj.regimen.products">
      <span> {{product.title}},</span>
    </ng-container>
  </div>
</div>