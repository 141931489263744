import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DirectivesModule } from '../../../../components/directives.module';
import { FollowupSectionComponent } from './followup-section.component';
import { SupportChatViewModule } from '../support-chat-view';
import { UserChatViewModule } from '../../../../components/user-chat-view';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DirectivesModule,
    MomentModule,
    SupportChatViewModule,
    UserChatViewModule,
    MomentModule,
    MatDatepickerModule,
  ],
  declarations: [
    FollowupSectionComponent,
  ],
  exports: [
    FollowupSectionComponent,
  ],
})
export class FollowupSectionModule {
}
