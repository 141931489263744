import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KioskCallsModule } from 'src/app/calls/kiosk-calls/kiosk-calls.module';
import { RegimenResolve } from '../regimen/regimen-resolver';
import { RegimenComponent } from './regimen.component';
import { RegimenListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'offlineRegimens',
    component: RegimenComponent,
    loadChildren: (): any => RegimenListModule,
  },
];

@NgModule({
  providers: [RegimenResolve],
  imports: [RouterModule.forChild(ROUTES), KioskCallsModule],
  declarations: [RegimenComponent],
  exports: [RegimenComponent],
})
export class OfflineRegimenModule {}
