
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Review</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form class="col-xs-12" role="form" name="review" #reviewForm="ngForm">
      <div class="col-xs-12">
        <label class="col-xs-12">Name</label>
        <div class="col-xs-10">
          <input-text class="w100" [parseObj]="doctorReviewObject" name="name" placeholder=""></input-text>
        </div>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-12">Treated For</label>
        <div class="col-xs-10">
          <language-string-selection class="mb-2" [parseObj]="doctorReviewObject" name="treatedForLanguageString" [required]="true" [tags]="['treated_for']"></language-string-selection>
        </div>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-12">Review</label>
        <div class="col-xs-10">
          <language-string-selection [parseObj]="doctorReviewObject" name="reviewLanguageString" [required]="true" [tags]="['doctor_review']"></language-string-selection>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" name="submit" [disabled]="!reviewForm.valid" (click)="updateDoctorReview()">Add Review<i class="fa fa-spin fa-spinner ml-2" *ngIf="popUpModel.loading"></i></button>
  </div>
</mat-dialog-content>