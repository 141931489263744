import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiConnector, Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';

@Component({ selector: 'pending-call-recent-history', templateUrl: './pending-call-recent-history.html' })
export class PendingCallRecentHistoryComponent {
  private userId: string;
  public userCalls: Array<any>;

  constructor(
    public dialogRef: MatDialogRef<PendingCallRecentHistoryComponent>,
    public injector: Injector,
    public appConfig: AppConfig,
    public connectionService: ConnectionService) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.userId = data.userId;
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    const user = new Table.User();
    user.id = this.userId;
    this.userCalls = (await ApiConnector.find(Table.PendingCall, {
      where: { user, status: { $ne: 'Requested' }, requestTime: { $lte: new Date() } },
      descending: 'requestTime',
      include: ['completedBy'],
      project: ['type', 'status', 'requestTime', 'completedBy.username' as 'completedBy', 'note'],
      limit: 50,
    })).map((each: any) => each.toJSON());
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
