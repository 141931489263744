import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RequestQueryPayload, Table } from 'api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../../app.config';
import { ConnectionService } from '../../../services/connection-service';
import {
  SimilarLanguageStringSuggestionComponent,
} from '../../../components/language-string/similar-language-string-suggestion/similar_language_string_suggestion.component';
import { Broadcaster } from '../../../components/broadcaster';

@Component({
  selector: 'language-string-edit',
  templateUrl: './language-string-edit.html',
  styleUrls: ['./language-string-edit.scss'],
})
export class LanguageStringEditComponent implements OnDestroy {
  languageStringObj: any;
  similarLanguageStrings: Array<any> = [];
  disableCreate: boolean;
  subscriptions: Array<Subscription> = [];
  options: Array<any> = [];
  currentUser: any;
  isNew: Boolean;
  isRichText: boolean = false;
  constructor(private appConfig: AppConfig,
    private conn: ConnectionService,
    private router: Router,
    private broadcaster: Broadcaster,
    private route: ActivatedRoute, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.currentUser });
    this.isNew = !this.route.snapshot.params.id;
    this.isRichText = this.route.snapshot.queryParams?.richText;

    this.languageStringObj = new Table.LanguageString();
    this.languageStringObj.set('tags', []);
    this.subscriptions.push(this.route.params.subscribe(async (params: any) => {
      if (!params.id) return;
      this.languageStringObj = await this.conn.getLanguageString(params.id);
      this.isRichText = this.languageStringObj.get('isRichText');
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  removeTag(index: number): void {
    const tags: Array<any> = this.languageStringObj.get('tags');
    tags.splice(index, 1);
    this.languageStringObj.set('tags', tags);
  }

  async findSimilarLanguageString(): Promise<any> {
    let regex = `^${this.languageStringObj.get('en')}$`;
    if (['!', '?', '.'].includes(this.languageStringObj.get('en').charAt(this.languageStringObj.get('en').length - 1))) {
      regex = `^${this.languageStringObj.get('en')}`;
    }
    const payload: RequestQueryPayload<Table.LanguageString> = {
      where: {},
      include: ['tags'],
    };
    if (this.languageStringObj.get('en')) {
      payload.where.en = { $regex: regex, $options: 'i' };
    }
    try {
      this.similarLanguageStrings = await this.conn.findLanguageStrings(payload);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', {
        message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  async saveLanguageString(): Promise<any> {
    if (!this.route.snapshot.params.id) await this.findSimilarLanguageString();
    if (this.similarLanguageStrings.length) {
      const dialogRef = this.dialog
        .open(SimilarLanguageStringSuggestionComponent, { data: { languageStrings: this.similarLanguageStrings } });
      dialogRef.afterClosed().subscribe(async (similarLanguageString: any): Promise<any> => {
        if (!similarLanguageString) return this.createLanguageString();
        if (similarLanguageString === this.appConfig.Shared.Actions.close) return '';
        this.languageStringObj.get('tags').forEach((tag: any): void => {
          const isTagPresent = similarLanguageString.get('tags').find((value: any): any => tag.get('name') === value.get('name'));
          if (!isTagPresent) {
            similarLanguageString.get('tags').push(tag);
          }
        });
        await similarLanguageString.save();
        return this.router.navigate([`/languageString/${similarLanguageString.id}`]);
      });
    } else {
      await this.createLanguageString();
    }
  }

  async createLanguageString(): Promise<void> {
    try {
      const isNew = !!this.languageStringObj.id;
      if (!this.languageStringObj.get('createdBy') && this.isNew) {
        this.languageStringObj.set('createdBy', this.currentUser);
      }
      await this.languageStringObj.save();
      this.router.navigate(
        [`${isNew ? '../' : ''}../../languageString`, this.languageStringObj.id],
        { relativeTo: this.route });
    } catch (err) {
      alert(err.message);
    }
  }
}
