import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { LanguageStringMultipleSelectionModule } from '../../../../components/language-string/language-string-multiple-selection';
import { InputTextModule } from '../../../../components/input/input-text';
import { LanguageViewModule } from '../../../../components/language-string/language-view';
import { EditComponent } from './edit.component';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { InputSelectModule } from '../../../../components/input/input-select';

const ROUTES:Routes = [
  {
    path: '',
    component: EditComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LanguageStringSelectionModule,
    LanguageStringMultipleSelectionModule,
    InputTextModule,
    InputCheckboxModule,
    LanguageViewModule,
    MatSelectModule,
    InputSelectModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
})

export class EditModule { }
