import { Component } from '@angular/core';
import clevertap from 'clevertap-web-sdk';
import { ConnectionService } from 'src/services/connection-service';
import { VOIPConstants } from 'src/shared';
import { initSignedCall } from 'clevertap-signed-call';

@Component({ selector: 'kiosk-calls', templateUrl: './kiosk-calls.html' })

export class KioskCallsComponent {
  SignedCallClient: any;
  voipConstants: any = VOIPConstants;

  constructor(private conn: ConnectionService) { }

  ngOnInit(): void {
    this.initialiseClevertap();
    this.initialiseVOIP();
  }

  tryCalling(): void {
    if (this.SignedCallClient.isEnabled()) {
      // allocated doctor's username/objectid
      this.SignedCallClient.call('mandar_ctmandar_ctmandar_ct', 'Test calling').then((response: any): void => {
        // if the call has been answered
      }).catch((err: any): void => {
        // if the call is either missed or declined
      });
    }
  }

  initialiseClevertap(): void {
    clevertap.init(this.voipConstants.projectID, 'eu1');
  }

  initialiseVOIP(): void {
    const user = this.conn.getCurrentUser();
    const userName = user.get('username');
    initSignedCall(
      {
        accountId: this.voipConstants.accountId,
        apikey: this.voipConstants.apikey,
        cuid: userName,
        clevertap,
      },
    ).then((client: any): void => {
      this.SignedCallClient = client;
      // this.tryCalling();
    }).catch((err: any): void => {
      // some error
    });
  }
}
