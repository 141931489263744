import { Input, Component, Output, EventEmitter } from '@angular/core';
import { FilterType } from '../../../typings/client/filter';

@Component({
  selector: 'filter',
  templateUrl: './filter-dropdown.html',
})
export class FilterDropdown {
  DATA_TYPE: { FIXED: string, BOOLEAN: string, STRING: string } = { FIXED: '0', BOOLEAN: '1', STRING: '2' };
  FILTER_TYPE: any = {
    BOOLEAN_EQUAL_TO: 'BOOLEAN_EQUAL_TO',
    EQUAL_TO: 'EQUAL_TO',
    EQUALS: 'EQUALS',
    EXISTS: 'EXISTS',
    DOES_NOT_EXITS: 'DOES_NOT_EXITS',
    STARTS_WITH: 'STARTS_WITH',
    ENDS_WITH: 'ENDS_WITH',
    IS: 'IS',
  };
  TYPE_FILTER_MAPPING: any = {
    [this.DATA_TYPE.FIXED]: [this.FILTER_TYPE.EQUAL_TO],
    [this.DATA_TYPE.BOOLEAN]: [
      this.FILTER_TYPE.EXISTS,
      this.FILTER_TYPE.DOES_NOT_EXITS,
      this.FILTER_TYPE.BOOLEAN_EQUAL_TO,
    ],
    [this.DATA_TYPE.STRING]: [
      this.FILTER_TYPE.EQUALS,
      this.FILTER_TYPE.STARTS_WITH,
      this.FILTER_TYPE.ENDS_WITH,
      this.FILTER_TYPE.IS,
    ],
  };
  CONDITION_MAP: any = {};
  inputArray: Array<FilterType.FilterConfigType>;
  ui: any;
  keyValueTrueFalse: Array<boolean> = [true, false];
  activeFilters: Array<FilterType.FilterConfigType> = [];
  isFilterApplied: boolean = false;

  @Input()
  set input(input: any) {
    this.inputArray = input;
    this.inputArray.forEach((r: any, index: number) => {
      const row: any = r;
      row.keyIndex = `${index}`;
    });
  }

  @Input()
  set defaultFilter(input: any) {
    this.initializeActiveFilter(input);
    this.activeFilters.forEach((data: any) => {
      const defaultFilterKeyIndexInInputArray = this.inputArray.filter((filter: any) => filter.key === data.key)[0];
      if (!defaultFilterKeyIndexInInputArray) return;
      const activefilter = data;
      activefilter.keyIndex = defaultFilterKeyIndexInInputArray.keyIndex;
    });
    this.updateIsFilterApplied();
  }

  @Input() filterDropdownView: Function;
  @Output() apply: EventEmitter<any> = new EventEmitter();
  static parameters: any = [];
  constructor() {
  }

  ngOnInit(): void {
    this.initialize();
  }

  convertKeyToDisplayName(key: string): string {
    return key.toLowerCase().split('_').join(' ');
  }

  initialize(): void {
    Object.keys(this.TYPE_FILTER_MAPPING).forEach((key: string) => {
      this.CONDITION_MAP[key] = this.TYPE_FILTER_MAPPING[key]
        .map((valueKey: string) => ({ key: valueKey, display: this.convertKeyToDisplayName(valueKey) }));
    });
    this.initializeActiveFilter(this.activeFilters);
  }

  initializeActiveFilter(defaultFilter: Array<any>): void {
    this.activeFilters = defaultFilter;
    if (!this.activeFilters || !this.activeFilters.length) {
      this.activeFilters = [{}];
    }
  }

  applyFilter(): void {
    const filters = this.activeFilters.map((x: any) => x);
    this.updateIsFilterApplied();
    this.apply.emit(filters.filter((item: FilterType.FilterConfigType) => !!item.key));
  }

  updateIsFilterApplied(): void {
    this.isFilterApplied = !!this.activeFilters.length && !!this.activeFilters[0].key;
  }

  setKey(keyIndex: string, row?: any): void {
    const rowCopy = row;
    this.activeFilters[this.activeFilters.length - 1].key = this.inputArray[keyIndex].key;
    delete rowCopy.filterType;
  }

  setBoolean(value: any): void {
    if (value === 'true') {
      this.activeFilters[this.activeFilters.length - 1].booleanValue = true;
    } else {
      this.activeFilters[this.activeFilters.length - 1].booleanValue = false;
    }
  }

  editFilters(): void {
    this.activeFilters = this.activeFilters.map((x: any) => x);
    if (!this.activeFilters.length) {
      this.activeFilters.push({});
    }
  }

  clearFilter(): void {
    this.activeFilters = [{ key: '', type: '' }];
  }
}
