
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/products/services">Services</a></li>
          <li>{{serviceObj.id ? 'Edit' : 'New'}}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content">
      <form class="p-4" #serviceForm="ngForm" (submit)="saveService()">
        <div class="col-xs-12">
          <label class="col-xs-12 mt-0">Type</label>
          <div class="col-xs-12">
            <select class="col-xs-7" [(ngModel)]="service.type" required="required" name="type" (ngModelChange)="service.type">
              <option value="{{apiClientConstant.Service.Type.CONSULTATION}}" selected="selected">Consultation</option>
              <option value="{{apiClientConstant.Service.Type.REGIMEN_CONSULTATION}}">Regimen Consultation</option>
              <option value="{{apiClientConstant.Service.Type.RENEW_CONSULTATION}}">Renew Consultation</option>
            </select>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Title Of the Service</label>
          <div class="col-xs-12">
            <input class="col-xs-7" [(ngModel)]="service.title" name="title" required="required" placeholder="Title"/>
          </div>
        </div>
        <div class="col-xs-7 mt-3">
          <language-string-selection-with-tag [parseObj]="serviceObj" name="titleLanguageString" [required]="true"></language-string-selection-with-tag>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Duration in weeks</label>
          <div class="col-xs-7">
            <input class="form-control" [(ngModel)]="service.duration" name="duration" required="required" placeholder="Duration" type="number" min="0"/>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">HSN code</label>
          <div class="col-xs-7">
            <input class="form-control" [(ngModel)]="service.hsnCode" name="hsnCode" required="required" placeholder="HSNCode"/>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Amount in Rupees</label>
          <div class="col-xs-7">
            <input class="form-control" [(ngModel)]="service.amount" name="amount" required="required" placeholder="Amount" type="number" min="0"/>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-2">Visible to User</label>
          <label class="i-switch i-switch-sm bg-success">
            <input class="form-control ng-pristine ng-valid ng-touched" type="checkbox" [(ngModel)]="service.visibility" name="visibility"/><i></i>
          </label>
        </div>
        <div class="col-xs-12 mt-3">
          <button type="submit" [disabled]="!serviceForm.valid">{{ serviceObj.id ? 'Update' : 'Create' }}</button>
        </div>
      </form>
    </div>
  </div>
</div>