import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { DirectivesModule } from '../../../components/directives.module';
import { AssignedListComponent } from './assigned-list.component';

const ROUTES: Routes = [
  { path: '', component: AssignedListComponent, pathMatch: 'full' },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  DirectivesModule,
  MomentModule,
  MomentModule,
  AgGridAngular,
  RouterModule.forChild(ROUTES),
],
declarations: [AssignedListComponent],
exports: [AssignedListComponent] })
export class AssignedListModule {}
