
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left pr-10">Continue Tablet In Regimen</h4><i class="fas fa-times pull-right fontL3 pl-10" (click)="hideModal()"></i>
  </div>
  <div class="modal-body mt-0">
    <div class="col-xs-12 px-4 mb-2"><span *ngIf="neverOrderedProducts.length" style="font-size: 16px; font-weight: bold">Patient Never Ordered 
        <ul *ngFor="let item of neverOrderedProducts">
          <li><span class="mr-3" style="font-size: 14px;">{{item.title.trim()}}:</span><span style="font-weight: normal;">The patient has never ordered this product. If you don't want to continue with this product, please remove it from the regimen.</span></li>
        </ul></span><span *ngIf="OrderedProducts.length" style="font-size: 16px; font-weight: bold">Patient is on the product
        <ul *ngFor="let item of OrderedProducts">
          <li><span class="mr-3" style="font-size: 14px;">{{item.title.trim()}}:</span><span style="font-weight: normal;">This patient is on this product for {{item.noOfDays}}. If you do not want to further continue with this product, please remove it from the regimen.</span></li>
        </ul></span></div>
  </div>
</mat-dialog-content>