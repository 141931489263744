import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { MomentModule } from 'ngx-moment';
import { InstantCheckUpMagnifierComponent } from './instant-checkup-magnifier.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxImageZoomModule,
    MomentModule,
  ],
  declarations: [InstantCheckUpMagnifierComponent],
  exports: [InstantCheckUpMagnifierComponent],
})
export class InstantCheckupMagnifierModule {
}
