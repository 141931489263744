
<div class="col-xs-12 title"><em class="fas fa-id-card pr-3"></em><span>Main Concerns</span></div>
<div class="col-xs-12 flex">
  <div class="col-xs-3 bold"><span>Selected Concern</span><a class="fa fa-pencil-square-o ml-3" *ngIf="!editConcernFlag" (click)="editConcernFlag = true"></a></div>
  <div class="pull-left pr-3">:</div>
  <div class="col-xs-7" *ngIf="!editConcernFlag">
    <div class="inline-flex" *ngFor="let mainConcern of mainConcernConsultationSession;let i = index;"><span class="fontS3 label label-primary p-2 mr-2 mb-2">{{mainConcern.get('PrivateMainConcern')}} ({{mainConcern.get('PrivateMainConcernClass')}})</span>
      <ng-container *ngFor="let concern of mainConcern.get('concernList')"><span class="fontS3 label label-default p-2 mr-2" *ngIf="concern !== mainConcern.get('PrivateMainConcern')">{{concern}}</span></ng-container>
    </div>
  </div>
  <div class="col-xs-4" *ngIf="editConcernFlag">
    <select class="form-control" [(ngModel)]="selectedConcernIndex" name="regimenObj" required="required">
      <option *ngFor="let concern of mainConcernOptions;let i = index;" value="{{i}}">{{concern.get('value')}}</option>
    </select>
  </div>
  <div class="col-xs-2" *ngIf="editConcernFlag">
    <button class="btn-xs ml-3" (click)="saveSelectedMainConcern();">Save</button>
  </div>
</div>
<div class="col-xs-12 flex mt-2" *ngIf="showRefreshDate">
  <div class="col-xs-2 bold">Refresh Date</div>
  <div class="pull-left pr-3">:</div>
  <div class="col-xs-7">{{ user.get('refreshDate') ? (user.get('refreshDate') | amDateFormat: "MMM DD, YYYY") : ' ' }}</div>
</div>