import { EnvType } from '../typings';

const Order = {
  States: [
    { name: 'Andaman And Nicobar', value: 'Andaman And Nicobar' },
    { name: 'Andhra Pradesh', value: 'Andhra Pradesh' },
    { name: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
    { name: 'Assam', value: 'Assam' },
    { name: 'Bihar', value: 'Bihar' },
    { name: 'Chhattisgarh', value: 'Chhattisgarh' },
    { name: 'Chandigarh', value: 'Chandigarh' },
    { name: 'Dadra And Nagar Haveli', value: 'Dadra And Nagar Haveli' },
    { name: 'Daman And Diu', value: 'Daman And Diu' },
    { name: 'Delhi', value: 'Delhi' },
    { name: 'Goa', value: 'Goa' },
    { name: 'Gujarat', value: 'Gujarat' },
    { name: 'Haryana', value: 'Haryana' },
    { name: 'Himachal Pradesh', value: 'Himachal Pradesh' },
    { name: 'Jammu And Kashmir', value: 'Jammu And Kashmir' },
    { name: 'Jharkhand', value: 'Jharkhand' },
    { name: 'Karnataka', value: 'Karnataka' },
    { name: 'Kerala', value: 'Kerala' },
    { name: 'Lakshadweep', value: 'Lakshadweep' },
    { name: 'Madhya Pradesh', value: 'Madhya Pradesh' },
    { name: 'Maharashtra', value: 'Maharashtra' },
    { name: 'Manipur', value: 'Manipur' },
    { name: 'Meghalaya', value: 'Meghalaya' },
    { name: 'Mizoram', value: 'Mizoram' },
    { name: 'Nagaland', value: 'Nagaland' },
    { name: 'Orissa', value: 'Orissa' },
    { name: 'Punjab', value: 'Punjab' },
    { name: 'Pondicherry', value: 'Pondicherry' },
    { name: 'Rajasthan', value: 'Rajasthan' },
    { name: 'Sikkim', value: 'Sikkim' },
    { name: 'Tamil Nadu', value: 'Tamil Nadu' },
    { name: 'Telangana', value: 'Telangana' },
    { name: 'Tripura', value: 'Tripura' },
    { name: 'Uttar Pradesh', value: 'Uttar Pradesh' },
    { name: 'Uttarakhand', value: 'Uttarakhand' },
    { name: 'West Bengal', value: 'West Bengal' },
  ],
  NUMBER_OF_DAYS_LEFT_FOR_DELIVERY: 4,
  SAMPLE_PRICE: 500,
  DIALOG_MESSAGE: {
    FOLLOWUP_EXIST: 'Images cannot be deleted now, as your doctor needs them for correct evaluation of the progress. '
      + 'If you accidentally took a photo please type the concern in chat and we will address the same',
    ORDER_PLACED_STATE: 'Your order has been placed. Doctor will be reviewing your images to ensure the right treatment'
      + ' is sent to you. Image deletion is paused for now until your treatment. '
      + 'If you have any concern please let us know in chat and we will address the same.',
  },
  PaymentType: {
    COD: 'COD',
    NOT_SELECTED: 'NOT_SELECTED',
    ONLINE: 'ONLINE',
    NA: 'NA',
  },
  Type: {
    TRIAL_REGIMEN: 'TRIAL_REGIMEN',
    REGIMEN: 'REGIMEN',
    PRODUCT: 'PRODUCT',
    REPLACEMENT_PRODUCT: 'REPLACEMENT_PRODUCT',
    CONSULTATION: 'CONSULTATION',
  },
  Courier: {
    BLUEDART: 'Bluedart',
    DELHIVERY: 'Delhivery',
    FEDEX: 'Fedex',
    ARAMEX: 'Aramex',
    XPRESS_BEES: 'XpressBees',
    ECOMEXPRESS: 'Ecom Express',
    EKART: 'EKART',
    INDIAPOST: 'India Post',
    DOTZOT: 'DotZot',
    GATI: 'Gati',
    NONE: 'None',
    AMAZON: 'AMAZON',
    PICKRR: 'Pickrr',
    SMARTR: 'Smartr',
    DUNZO: 'Dunzo',
    ShadowFax: 'ShadowFax',
    ZFW: 'ZFW',
    GROW_SIMPLEE: 'Grow Simplee',
    PHARM_EASY: 'pharmeasy',
    DTDC: 'DTDC',
    SHIP_ROCKET: 'ShipRocket',
  },
  Stage: {
    PAYMENT_ERROR: 'PAYMENT_ERROR',
    INVOICE_GENERATED: 'INVOICE_GENERATED',
    AWB_GENERATED: 'AWB_GENERATED',
    OUT_OF_STOCK: 'OUT_OF_STOCK',
    RETURN_REQUESTED: 'RETURN_REQUESTED',
    RETURN_INITIATED: 'RETURN_INITIATED',
    CONSULTATION_COMPLETED: 'CONSULTATION_COMPLETED',
    ONLINE_PAYMENT_PENDING: 'ONLINE_PAYMENT_PENDING',
    ONLINE_PAYMENT_SUCCESS: 'ONLINE_PAYMENT_SUCCESS',
    SHIP_NOW: 'SHIP_NOW',
    ADDRESS_CONFIRMATION: 'ADDRESS_CONFIRMATION',
    ADDRESS_VERIFIED: 'ADDRESS_VERIFIED',
    ORDER_PLACED: 'ORDER_PLACED',
    DR_VERIFIED: 'DR_VERIFIED',
    SHIPPED: 'SHIPPED',
    SOURCE_HUB_REACHED: 'SOURCE_HUB_REACHED',
    CONNECTED_TO_DESTINATION: 'CONNECTED_TO_DESTINATION',
    OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
    STUCK_IN_TRANSIT: 'STUCK_IN_TRANSIT',
    COURIER_NOT_AVAILABLE: 'COURIER_NOT_AVAILABLE',
    DELIVERED: 'DELIVERED',
    RETURNED: 'RETURNED',
    BOT_CANCELED: 'BOT_CANCELED',
    CANCELED: 'CANCELED',
    COMPLETED: 'COMPLETED',
    REFUND_REQUESTED: 'REFUND_REQUESTED',
    REFUND_INITIATED: 'REFUND_INITIATED',
    REFUND_PROCESSED: 'REFUND_PROCESSED',
    SHIP_LATER: 'SHIP_LATER',
    WAITING_FOR_IMAGE: 'WAITING_FOR_IMAGE',
    PICKUP_READY: 'PICKUP_READY',
    DELIVERY_FAIL: 'DELIVERY_FAIL',
    RETRY_DELIVERY: 'RETRY_DELIVERY',
    CONFIRMATION_PENDING: 'CONFIRMATION_PENDING',
    ORDER_CONFIRMED: 'ORDER_CONFIRMED',
    DOCTOR_APPROVAL_PENDING: 'DOCTOR_APPROVAL_PENDING',
    IMAGE_VERIFICATION_PENDING: 'IMAGE_VERIFICATION_PENDING',
    IMAGE_VERIFIED: 'IMAGE_VERIFIED',
    PACKED: 'PACKED',
    QC_PASS: 'QC_PASS',
    CANCEL_REQUESTED: 'CANCEL_REQUESTED',
    INITIAL: 'INITIAL',
    CONSULTATION_CREATED: 'CONSULTATION_CREATED',
    CUSTOMER_PICKUP: 'CUSTOMER_PICKUP',
    USER_CONFIRMATION_PENDING: 'USER_CONFIRMATION_PENDING',
    LOST_IN_TRANSIT: 'LOST_IN_TRANSIT',
    CALL_CONFIRMATION: 'CALL_CONFIRMATION',
  },
  WaitingForImageReasons: [
    { key: 'Only Front face absent', images: ['FRONT_FACE'] },
    { key: 'Only Side face absent', images: ['SIDE_FACE'] },
    { key: 'No Face photos present', images: ['FRONT_FACE', 'FRONT_FACE'] },
    { key: 'No front face for HAIR regimen', images: ['FRONT_FACE'] },
    { key: 'Only Top view hair absent', images: ['HAIR_TOP'] },
    { key: 'Only hairline view hair absent', images: ['HAIR'] },
    { key: 'No Hair photos present', images: ['HAIR', 'HAIR_TOP'] },
    { key: 'No Body photos present', images: ['BODY'] },
    { key: 'Multiple People', images: ['FRONT_FACE', 'BODY', 'HAIR', 'SIDE_FACE'] },
    { key: 'Different Gender Photos', images: ['FRONT_FACE', 'BODY', 'HAIR', 'SIDE_FACE'] },
  ],
  PossibleStageChange: {},
  CompletedStages: [],
  CancelledStages: [],
  OrderInProcessStages: [],
  OrderStageBeforeShipNow: [],
  OrderStageFromShipNow: [],
  BEFORE_TRANSIT: 'BEFORE_TRANSIT',
  FORWARD_TRANSIT: 'FORWARD_TRANSIT',
  ANY_NON_PARTIAL: 'ANY_NON_PARTIAL',
};
Order.CompletedStages.push(Order.Stage.CANCELED, Order.Stage.COMPLETED, Order.Stage.DELIVERED, Order.Stage.RETURNED);

Order.OrderStageBeforeShipNow.push(
  Order.Stage.INITIAL,
  Order.Stage.ONLINE_PAYMENT_PENDING,
  Order.Stage.CONFIRMATION_PENDING,
  Order.Stage.WAITING_FOR_IMAGE,
  Order.Stage.DOCTOR_APPROVAL_PENDING,
  Order.Stage.DR_VERIFIED,
  Order.Stage.IMAGE_VERIFICATION_PENDING,
  Order.Stage.CONSULTATION_CREATED,
  Order.Stage.OUT_OF_STOCK,
  Order.Stage.SHIP_LATER);

Order.OrderStageFromShipNow = Object.keys(Order.Stage).filter((stage: any) => !(Order.OrderStageBeforeShipNow.includes(stage)));
Order.CancelledStages.push(
  Order.Stage.REFUND_REQUESTED,
  Order.Stage.REFUND_PROCESSED,
  Order.Stage.CANCELED,
  Order.Stage.RETURNED,
  Order.Stage.RETURN_INITIATED);

const AllStages = Object.keys(Order.Stage).map((key: string) => Order.Stage[key]);
Object.keys(Order.Stage).forEach((stage: any) => (Order.PossibleStageChange[stage] = AllStages));
Order.OrderInProcessStages.push(...Object.keys(Order.Stage)
  .filter((key: string) => (!Order.CompletedStages.includes(Order.Stage[key]) && key !== Order.Stage.ONLINE_PAYMENT_PENDING))
  .map((key: string) => Order.Stage[key]));

const SMS = {
  MSG91: {
    ROUTE: {
      PROMOTIONAL: '1',
      TRANSACTIONAL: '4',
    },
    SENDER: {
      CURSKN: 'CURSKN',
    },
    URI: {
      SENDSMS: 'http://api.msg91.com/api/v2/sendsms',
    },
  },
};
const ScheduledNotification = {
  Type: {
    FOLLOWUP_DOCTOR: 'DOCTOR',
    ORDER_MESSAGE: 'ORDER_MESSAGE',
    FOLLOWUP_USER: 'USER',
    DIALOG_NOTIFICATION: 'DialogOnlyNotification',
    FOLLOWUP_MESSAGE_USER: 'FOLLOWUP_MESSAGE_USER',
    FOLLOWUP_PRIORITY_MESSAGE_USER: 'FOLLOWUP_PRIORITY_MESSAGE_USER',
    TREE_REMINDER_NODE: 'TREE_REMINDER_NODE',
    TREE_SCHEDULE_NODE: 'TREE_SCHEDULE_NODE',
  },
  Message: {
    DOCTOR_MESSAGE: 'Patient followup',
    USER_MESSAGE: 'Your followup with our dermatologist is this week. Hope you are following the instructions correctly.'
      + ' Please take an instant checkup photo today to track your progress. She will review the photos and call you.',
    TREE_NOT_COMPLETED_WITHIN_THRESHOLD: 'You have not answered all the questions for your skin/hair concern.'
      + ' Please answer them to get a regimen designed for your skin.',
  },
};
const Languages = {
  EN: 'en',
  HI: 'hi',
  KN: 'kn',
  TE: 'te',
  TA: 'ta',
  MA: 'ma',
  MR: 'mr',
  BN: 'bn',
};

const Gender = {
  MALE: 'Male',
  FEMALE: 'Female',
  BOTH: 'Both',
};

const LeaveAndHoliday = {
  STATUS: {
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
  },
  MODE: {
    LEAVE: 'LEAVE',
    COMPENSATION_LEAVE: 'COMPENSATION_LEAVE',
    CASH: 'CASH',
  },
  MAX_DOCTOR_NO_OF_LEAVES: 20,
  MAX_OPERATOR_NO_OF_LEAVES: 24,
};
const BlockAssistant = {
  BlockStage: {
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    MOVE_TO_ASSISTANT: 'MOVE_TO_ASSISTANT',
    MOVE_TO_ASSISTANT_COMPLETED: 'MOVE_TO_ASSISTANT_COMPLETED',
    DELETED: 'DELETED',
  },
};

const Assistant = {
  Mode: {
    GENERIC_USER_INPUT: 'GENERIC_USER_INPUT',
    AUDIO: 'AUDIO',
    PAYMENT: 'PAYMENT',
    SMS: 'SMS',
    ATTACHMENT: 'ATTACHMENT',
    REQUEST_IMAGE_RESPONSE: 'REQUEST_IMAGE_RESPONSE',
    BLOCK: 'BLOCK',
    ARTICLE: 'ARTICLE',
    RICH_TEXT: 'RICH_TEXT',
    WITH_TEXT_REPLY: 'WITH_TEXT_REPLY',
    REQUEST_IMAGE: 'REQUEST_IMAGE',
    SINGLE_SELECTOR: 'SINGLE_SELECTOR',
    MULTI_SELECTOR: 'MULTI_SELECTOR',
    ACTION: 'ACTION',
    REGIMEN: 'REGIMEN',
    REQUEST_IMAGE_FACE: 'REQUEST_IMAGE_FACE',
    REQUEST_IMAGE_SIDE_FACE: 'REQUEST_IMAGE_SIDE_FACE',
    REQUEST_IMAGE_FRONT_FACE: 'REQUEST_IMAGE_FRONT_FACE',
    REQUEST_IMAGE_HAIR: 'REQUEST_IMAGE_HAIR',
    REQUEST_IMAGE_BODY: 'REQUEST_IMAGE_BODY',
    REQUEST_IMAGE_REPORT: 'REQUEST_IMAGE_REPORT',
    REQUEST_REPORT: 'REQUEST_REPORT',
    STICKY_ACTION: 'STICKY_ACTION',
    VIDEO: 'VIDEO',
    IMAGE: 'IMAGE',
    CUSTOM_FORM: 'CUSTOM_FORM',
    CUSTOM_DIET: 'CUSTOM_DIET',
  },
  Owner: {
    Doctor: 'DOCTOR',
    Operator: 'OPERATOR',
    Bot: 'BOT',
    User: 'USER',
  },
  Attachment: {
    Type: {
      PDF: 'PDF',
      IMAGE: 'IMAGE',
    },
    Thumbnail: {
      PDF: 'https://cdn.cureskin.com/article_image/1629989795_14e81ac9d16542c6b38d91a03879fe29.png',
    },
  },
  INPUTS: {
    FACE_IMAGE_ACTION: [
      {
        type: 'BUTTON',
        action: 'ACTION',
        params: {
          actionName: 'InstantCheckup',
          queryParams: '{ "tag": "FULL_FACE" }',
        },
        text: 'Take face photo',
      },
    ],
    SIDE_FACE_IMAGE_ACTION: [
      {
        type: 'BUTTON',
        action: 'ACTION',
        params: {
          actionName: 'InstantCheckup',
          queryParams: '{ "tag": "SIDE_FACE" }',
        },
        text: 'Take side face photo',
      },
    ],
    FRONT_FACE_IMAGE_ACTION: [
      {
        type: 'BUTTON',
        action: 'ACTION',
        params: {
          actionName: 'InstantCheckup',
          queryParams: '{ "tag": "FRONT_FACE" }',
        },
        text: 'Take front face photo',
      },
    ],
    HAIR_IMAGE_ACTION: [
      {
        type: 'BUTTON',
        action: 'ACTION',
        params: {
          actionName: 'InstantCheckup',
          queryParams: '{ "tag": "HAIR" }',
        },
        text: 'Take hair photo',
      },
    ],
    BODY_IMAGE_ACTION: [
      {
        type: 'BUTTON',
        action: 'ACTION',
        params: {
          actionName: 'InstantCheckup',
          queryParams: '{ "tag": "BODY" }',
        },
        text: 'Take photo',
      },
    ],
    REQUEST_REPORT_ACTION: [
      {
        type: 'BUTTON',
        action: 'ACTION',
        params: {
          actionName: 'InstantCheckup',
          queryParams: '{ "tag": "REPORT" }',
        },
        text: 'Submit report ',
      },
    ],
  },
};
const FollowUp = {
  NUMBER_OF_DAYS_INSTANT_CHECKUP_TAKEN: 5,
  NUMBER_OF_DAYS_REMINDER_MESSAGE: 3,
  NUMBER_OF_DAYS_PRIORITY_REMINDER_MESSAGE: 1,
  State: {
    PENDING: 'PENDING',
    WAITING_FOR_IMAGE: 'WAITING_FOR_IMAGE',
    FINISHED: 'FINISHED',
  },
  AllocatedTeam: {
    DERMATOLOGIST: 'DERMATOLOGIST',
    MBBS: 'MBBS',
  },
  Regular: 'Regular',
  Product: 'New Product added to regimen',
  Immediate: 'Immediate followUp requested by user.',
};
const Payment = {
  PaymentPlan: {
    PRODUCT: 'PRODUCT',
    TRIAL_REGIMEN: 'TRIAL_REGIMEN',
    CONSULTATION: 'CONSULTATION',
    REGIMEN: 'REGIMEN',
  },
  PaymentSource: {
    REFUND: 'Refund',
    COD: 'COD',
    RAZORPAY: 'razorpay',
    PAYTM: 'paytm',
  },
  SuccessStages: [],
  Stage: {
    TXN_STARTED: 'TXN_STARTED',
    TXN_FAILURE: 'TXN_FAILURE',
    TXN_SUCCESS: 'TXN_SUCCESS',
    authorized: 'authorized',
    captured: 'captured',
  },
};
Payment.SuccessStages.push(Payment.Stage.TXN_SUCCESS, Payment.Stage.authorized, Payment.Stage.captured);
const Consultation = {
  Charge: 300,
  Stage: {
    CREATED: 'CREATED',
    CALLED: 'CALLED',
    READY_TO_CALL: 'READY_TO_CALL',
    NOT_READY_TO_CALL: 'NOT_READY_TO_CALL',
  },
};
const Tree = {
  Type: {
    BASIC_INFO: 'BASIC_INFO',
    INDICATION: 'INDICATION',
    ACTION: 'ACTION',
    REGIMEN_SOP: 'REGIMEN_SOP',
    POST_REGIMEN: 'POST_REGIMEN',
    ANSWER_INDEPENDENT: 'ANSWER_INDEPENDENT',
    ANSWER_DEPENDENT: 'ANSWER_DEPENDENT',
  },
};
const Question = {
  StoreInTable: {
    ASSISTANT: 'Assistant',
    USER_BLOG: 'UserBlog',
    SUPPORT_CHAT: 'SupportChat',
    FOLLOW_UP_CHAT: 'FollowUpChat',
    CONSULTATION_CHAT: 'ConsultationChat',
    NO_STORAGE: 'NoStorage',
  },
  Mode: {
    GENERIC_USER_INPUT: 'GENERIC_USER_INPUT',
    STICKY_ACTION: 'STICKY_ACTION',
    PAYMENT: 'PAYMENT',
    REQUEST_IMAGE: 'REQUEST_IMAGE',
    ARTICLE: 'ARTICLE',
    RICH_TEXT: 'RICH_TEXT',
    IMAGE: 'IMAGE',
  },
  InputType: {
    BUTTON: 'BUTTON',
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
  },
  InputAction: {
    TREE: 'TREE',
    ACTION: 'ACTION',
  },
  Type: {
    EVALUATE: 'EVALUATE',
    SEND_TO_USER: 'SEND_TO_USER',
  },
  Task: {
    NONE: 'NONE',
    ASSISTANT_TASK: 'ASSISTANT_TASK',
  },
  Conditions: {
    Operand: {
      AND: 'AND',
      OR: 'OR',
    },
    Comparator: {
      EQUAL: '==',
      NOT_EQUAL: '!=',
      GREATER_THAN: '>',
      GREATER_THAN_OR_EQUAL: '>=',
      LESS_THAN_OR_EQUAL: '<=',
      LESS_THAN: '<',
      EXISTS: 'exist',
      NOT_EXISTS: 'not_exist',
    },
    Type: {
      AND: 'AND',
      OR: 'OR',
      INSTANT_CHECKUP: 'INSTANT_CHECKUP',
      ACTIVE_REGIMEN: 'ACTIVE_REGIMEN',
      QUESTION_ANSWER_MATCH: 'QUESTION_ANSWER_MATCH',
      ANY_ORDER_IN_STAGE_GROUP: 'ANY_ORDER_IN_STAGE_GROUP',
      MATCH_WITH_CONTEXT: 'MATCH_WITH_CONTEXT',
      NUMBER_OF_STEROID_USING: 'NUMBER_OF_STEROID_USING',
      ACTIVE_REGIMEN_HAVING_CONCERN: 'ACTIVE_REGIMEN_HAVING_CONCERN',
      ALLOCATED_DOCTOR: 'ALLOCATED_DOCTOR',
      FOLLOWUP_STATE: 'FOLLOWUP_STATE',
    },
  },
};
const User = {
  Type: {
    OPERATOR: 'operator',
    DOCTOR: 'doctor',
  },
  State: {
    PAID: 'PAID',
    UNPAID: 'UNPAID',
  },
  OrderState: {
    NEW_USER: 'NEW_USER',
    UNPAID: 'UNPAID',
    PROCESSING: 'PROCESSING',
    DELIVERED: 'DELIVERED',
    CONSULTATION: 'CONSULTATION',
    CANCELED: 'CANCELED',
  },
  Tags: {
    TEENAGER: 'TEENAGER',
  },
  CallerType: {
    LIMITED: 'LIMITED',
    UNLIMITED: 'UNLIMITED',
    UNLIMITED_SOFTPHONE: 'UNLIMITED_SOFTPHONE',
  },
  UserType: {
    MBBS: 'MBBS',
    DERMATOLOGIST: 'DERMATOLOGIST',
    SALES: 'SALES',
    CHAT_SUPPORT: 'CHAT_SUPPORT',
    COURIER: 'COURIER',
    MARKETING: 'MARKETING',
  },
  MarketingTags: {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    AGE_BETWEEN_20_TO_40: 'AGE_BETWEEN_20_TO_40',
    AGE_BELOW_20: 'AGE_BELOW_20',
    AGE_ABOVE_40: 'AGE_ABOVE_40',
    ACNE_OR_PIMPLES: 'ACNE_OR_PIMPLES',
    FACE_PIGMENTATION: 'FACE_PIGMENTATION',
    HAIR_FALL: 'HAIR_FALL',
    DANDRUFF: 'DANDRUFF',
    SMOKER: 'SMOKER',
    POST_ACNE: 'POST_ACNE',
    ACNE_DETECTED: 'ACNE_DETECTED',
    HORMONAL_IMBALANCE: 'HORMONAL_IMBALANCE',
    PREGNANT: 'PREGNANT',
    STEROID: 'STEROID',
    NEW_MOM: 'NEW_MOM',
    BODY: 'BODY',
    ON_REGIMEN: 'ON_REGIMEN',
    NOT_ON_REGIMEN: 'NOT_ON_REGIMEN',
  },
};
const Regimen = {
  Type: {
    MAIN: 'MAIN',
    ALTERNATE: 'ALTERNATE',
    PERSONALIZED: 'PERSONALIZED',
  },
  Class: {
    BODY: 'BODY',
    FACE: 'FACE',
    HAIR: 'HAIR',
  },
  Variants: {
    VariantId: {
      variant_1499: 'variant_1499',
      variant_1999: 'variant_1999',
      variant_4999: 'variant_4999',
      variant_999: 'variant_999',
    },
  },
};

const Toast = {
  Type: {
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
  },
};

const AllocatedDoctorPopup = {
  Type: {
    ORDER: 'ORDER',
    FOLLOWUP: 'FOLLOWUP',
  },
};

const ActionLog = {
  STATUS: {
    CREATED: 'CREATED',
    COMPLETE: 'COMPLETE',
    FAILURE: 'FAILURE',
  },
  Type: {
    REGIMEN_CHANGE: 'REGIMEN_CHANGE',
    INSTANT_CHECKUP_TYPE_CHANGED: 'INSTANT_CHECKUP_TYPE_CHANGED',
  },
};
const InstantCheckup = {
  Type: {
    INVALID: 'INVALID',
    FULL_FACE: 'FULL_FACE',
    SIDE_FACE: 'SIDE_FACE',
    FRONT_FACE: 'FRONT_FACE',
    HAIR: 'HAIR',
    HAIR_TOP: 'HAIR_TOP',
    HAIR_FRONT: 'HAIR_FRONT',
    BODY: 'BODY',
    REPORT: 'REPORT',
    LEFT_SIDE_FACE: 'LEFT_SIDE_FACE',
    RIGHT_SIDE_FACE: 'RIGHT_SIDE_FACE',
  },
  Tags: ['GOOD',
    'MANAGEABLE',
    'BAD',
    'FOLLOWUP',
    'BLUR_SLIGHT',
    'BLUR_HIGH',
    'DIM_SLIGHT',
    'DIM_HIGH',
    'BAD_AI_RESULT'],
  Concern: {
    OPEN_PORES: 'OpenPores',
    DARKSPOT: 'darkspot-region',
    MELASMA: 'Melasma',
    FACIAL_HAIR: 'Hirsutism',
    PERIORAL_PIGMENTATION: 'PerioralPigmentation',
    INFLAMMATORY_ACNE: 'pimple-region',
    COMEDONE: 'come-region',
    FRECKLES: 'Freckles',
    DARK_CIRCLE: 'DarkCircle',
    PUFFY_EYES: 'PuffyEyes',
    ASCAR: 'ascar-region',
    OSCAR: 'oscar-region',
    WRINKLE_BETWEEN_EYEBROWS: 'BEB',
    WRINKLE_ON_FOREHEAD: 'OF',
    NASOLABIAL_LINES: 'N2L',
    WRINKLE_AROUND_EYES: 'AE',
    MARRIOTT_LINES: 'L2C',
    CROWS_FEET: 'CF',
  },
  concernLanguageString: {
    OPEN_PORES: '4alscrVYfr',
    DARKSPOT: 'NtsFlRn0YP',
    MELASMA: '2Zfck2zRNv',
    FACIAL_HAIR: 'BnOGA68GvD',
    PERIORAL_PIGMENTATION: 'h2K4dk5vHf',
    INFLAMMATORY_ACNE: 'qBeQLaXQFn',
    COMEDONE: 'EQDMUuq3Wn',
    FRECKLES: 'Ek7iFnNBkz',
    DARK_CIRCLE: 'diAzPkytIE',
    PUFFY_EYES: 'mIwl8qgJVp',
    ASCAR: 'FU9eyNGIvc',
    OSCAR: 'EA4WGmG2IA',
    WRINKLE_BETWEEN_EYEBROWS: 'SS4EFfCnlh',
    WRINKLE_ON_FOREHEAD: 'CAN96zPUVH',
    NASOLABIAL_LINES: 'YnIpcJONXU',
    WRINKLE_AROUND_EYES: '2DDPG6wmMm',
    MARRIOTT_LINES: 'eR9XtxdpmW',
    CROWS_FEET: 'TJ2I78Ztit',
  },
};
const Tag = {
  Type: {
    USER_LEVEL: 'USER_LEVEL',
    MESSAGE_LEVEL: 'MESSAGE_LEVEL',
    EXTERNAL: 'EXTERNAL',
  },
  Tag: {
    ASSIGNED_TO_DOCTOR: 'ASSIGNED_TO_DOCTOR',
    ASSIGNED_TO_OPERATOR: 'ASSIGNED_TO_OPERATOR',
    WAITLIST: 'WAITLIST',
    REQUEST_INSTANT_CHECKUP: 'REQUEST_INSTANT_CHECKUP',
    REQUEST_IMAGE: 'REQUEST_IMAGE',
    CALL_DOCTOR: 'CALL_DOCTOR',
    CALL_OPERATOR: 'CALL_OPERATOR',
  },
  Status: {
    ASSIGNED: 'ASSIGNED',
    FINISHED: 'FINISHED',
    NOT_READY_TO_ATTEND: 'NOT_READY_TO_ATTEND',
    READY_TO_ATTEND: 'READY_TO_ATTEND',
  },
};
const MAIN_CONCERN = {
  ACNE_OR_PIMPLES: 'ACNE_OR_PIMPLES',
  DARK_SPOTS_OR_MARK: 'DARK_SPOTS_OR_MARK',
  ACNE_SCARS: 'ACNE_SCARS',
  DULL_SKIN: 'DULL_SKIN',
  PIGMENTATION: 'PIGMENTATION',
  DRY_SKIN: 'DRY_SKIN',
  UNDER_EYE_DARK_CIRCLES: 'UNDER_EYE_DARK_CIRCLES',
  HAIR_FALL: 'HAIR_FALL',
  SOMETHING_ELSE: 'SOMETHING_ELSE',
};
const Seller = {
  DrCHARU: { id: 'essentialAesthetics', name: 'Essential Aesthetics' },
  CURESKIN: { id: 'healloOnlineHealth', name: 'Heallo Online Health' },
};

const Service = {
  Type: {
    CONSULTATION: 'CONSULTATION',
    REGIMEN_CONSULTATION: 'REGIMEN_CONSULTATION',
    RENEW_CONSULTATION: 'RENEW_CONSULTATION',
    DIET_CONSULTATION: 'DIET_CONSULTATION',
  },
};

const ArticleData = {
  ClickToAction: {
    BUY_PRODUCT: 'BUY_PRODUCT',
    NONE: 'NONE',
    BUY_REGIMEN: 'BUY_REGIMEN',
    SHARE: 'SHARE',
    REFER: 'REFER',
  },
  Template: {
    NONE: 'none',
    IMAGE: 'imageArticle',
    VIDEO: 'videoArticle',
    GENERAL: 'generalArticle',
    PRODUCT: 'productArticle',
    DIET: 'dietArticle',
    HTML: 'html',
    pug: 'pug',
  },
};

const Tax = {
  CONSULTATION_TAX: { cgst: 9, sgst: 9 },
  DEFAULT_TAX: { cgst: 6, sgst: 6 },
};

const RequestMessage = {
  REQUEST_INSTANT_CHECKUP: 'Your doctor needs a newer photo. Please click below to take your '
    + 'instant-checkup. We will arrange a call, after you have uploaded the photo.',
  REQUEST_IMAGE: 'Your doctor requested for a photo.'
    + ' Please click below to take the photo. We will arrange a call, after you have uploaded the photo.',
};

const Catalog = {
  InventoryStatus: {
    DISCONTINUED: 'DISCONTINUED',
    UNAVAILABLE: 'UNAVAILABLE',
    AVAILABLE: 'AVAILABLE',
    RESTRICTED: 'RESTRICTED',
  },
  Type: {
    MAIN: 'main',
    SAMPLE: 'sample',
    VARIANT: 'variant',
  },
  REORDER_TYPE: {
    INDEFINITE: 'INDEFINITE',
    LIMITED: 'LIMITED',
    NEVER: 'NEVER',
  },
  PART_OF_DAY: {
    MORNING: 'MORNING',
    NIGHT: 'NIGHT',
  },
  SUB_CATEGORY: {
    ANTI_ACNE: 'ANTI_ACNE',
    DARK_CIRCLES: 'DARK_CIRCLES',
    GLOW_RADIANCE_MAINTENANCE: 'GLOW_RADIANCE_MAINTENANCE',
    HYPER_PIGMENTATION: 'HYPER_PIGMENTATION',
    SCARRING: 'SCARRING',
    OTHER_FACE: 'OTHER (FACE)',
    FRIZZY_HAIR: 'FRIZZY HAIR',
    GREY_HAIR: 'GREY HAIR',
    DANDRUFF: 'DANDRUFF',
    HAIRFALL: 'HAIRFALL',
    OTHER_HAIR: 'OTHER (HAIR)',
    BODY_DRYNESS: 'BODY DRYNESS',
    OTHER_BODY: 'OTHER (BODY)',
  },
  CATEGORY: {
    FACE: 'FACE',
    HAIR: 'HAIR',
    BODY: 'BODY',
  },
  INSTRUCTION_VIDEO_TYPE: {
    PAID: 'PAID',
    UNPAID: 'UNPAID',
  },
  TRACKING_CATEGORY_TYPE: {
    SUNCSCREEN: 'SUNCSCREEN',
    FACEWASH: 'FACEWASH',
    REPAIR: 'REPAIR',
    MOISTURISER: 'MOISTURISER',
    PROTECTORS: 'PROTECTORS',
  },
  SAFTEY_PROFILE_OPTION: {
    PREGNANCY_AND_BREASTFEEDING_SAFE: 'Pregnancy And Breastfeeding Safe',
    PREGNANCY_CATEGORY_B: 'Pregnancy Category B',
    LIMITED_DATA_ON_SAFTEY: 'Limited Data On Saftey',
    CONTRAINDICATED_DURING_PREGNANCY_AND_BREASTFEEDING: 'Contraindicated During Pregnancy and Breastfeeding',
  },
  PREGNANT_AND_BREASTFEEDING_TAG: 'preg_and_bf_safe',
};

const ConsultationSession = {
  PrivateMainConcernClass: {
    HAIR: 'HAIR',
  },
};

const Event = {
  Type: {
    ORDER: 'Order',
  },
};

const EventScheduler = {
  Type: {
    SEND_REGIMEN_INSTRUCTION: 'SEND_REGIMEN_INSTRUCTION',
    ADD_ADDON_TO_USER: 'ADD_ADDON_TO_USER',
    SEND_NOTIFICATION: 'SEND_NOTIFICATION',
    BATCH_REQUEST_STATUS: 'BATCH_REQUEST_STATUS',
  },
};

const TreeTables = {
  ASSISTANT_TASK: 'AssistantTask',
  SELF_CARE_CONCERN_SESSION: 'SelfCareConcernSession',
  USER: '_User',
  ORDER_MEDICAL_RECORD: 'OrderMedicalRecord',
  CHAT_CACHE: 'AttendToChatCache',
  CHAT_SESSION: 'ChatSession',
  CONSULTATION_SESSION: 'ConsultationSession',
  FOLLOWUP: 'FollowUp',
  ARTICLE_RESPONSE: 'ArticleResponse',
  USER_EXTRA_INFORMATION: 'UserExtraInformation',
  TEMPORARY_ANSWER: 'TemporaryAnswer',
  SUPPORT_TICKET: 'SupportTicket',
};

const Table = {
  ORDER: 'Order',
  REGIMEN: 'Regimen',
  USER: '_User',
  FOLLOWUP: 'FollowUp',
};

const Coupon = {
  Type: {
    CURE_SKIN_COMPLEMENTARY: 'CURE_SKIN_COMPLEMENTARY',
    SERVICE_DISCOUNT: 'SERVICE_DISCOUNT',
    NORMAL: 'NORMAL',
    USER_REFERRAL: 'USER_REFERRAL',
  },
};

const CashTransaction = {
  referenceType: {
    PROMOTIONAL: 'promotional',
  },
};

const Notes = {
  Type: {
    MEDICAL: 'MEDICAL',
    OPERATOR: 'OPERATOR',
  },
};

const EmbeddedLink = {
  Type: {
    Folder: 'Folder',
    Link: 'Link',
  },
  LinkType: {
    Metabase: 'Metabase',
    Grafana: 'Grafana',
  },
};

const Role = {
  Name: {
    FOLLOWUP: 'followUp',
    BetterHair: 'betterHair',
    CHAT_SUPPORT: 'chatSupport',
    OPERATOR: 'operator',
    DOCTOR: 'doctor',
    SHIPPING: 'shipping',
    REVIEW: 'review',
    PAYMENT: 'payment',
    DEVELOPER: 'developerRole',
    INVENTORY: 'inventory',
    ORDER_TRACKING: 'orderTracking',
    SALES: 'sales',
    Intern: 'intern',
    WAITING_FOR_IMAGE: 'waitingForImage',
    ADMIN_OPERATOR: 'adminOperator',
    ADMIN: 'admin',
  },
};

const Team = {
  Name: [
    'followUp',
    'chatSupport',
    'doctor',
    'shipping',
    'review',
    'payment',
    'inventory',
    'sales',
    'orderTracking',
    'waitingForImage',
    'intern',
    'betterHair',
    'checkIn',
    'diet',
    'initialTeam',
  ],
};

const LanguageStringId = {
  GIVE_YOUR_FEEDBACK: 'AZCXlRYx9I',
  REQUEST_YOUR_FRONT_FACE_IMAGE: 'RsWBj18lXe',
  REQUEST_YOUR_SIDE_FACE_IMAGE: 'kBarEOsBdw',
  REQUEST_YOUR_HAIR_IMAGE: 'jg4YUQGsTi',
  REQUEST_YOUR_BODY_IMAGE: '2xbndcJltB',
  TAKE_PHOTO: 'BXmAMSGi6w',
  REQUEST_REPORT: 'eJMd2Zkfl8',
  UPLOAD: 'NHlGi20iLt',
};

const FollowUpReport = {
  Type: {
    BODY: 'BODY',
    FACE: 'FACE',
    HAIR: 'HAIR',
  },
};

const ShopTags = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  AGE_BETWEEN_20_TO_40: 'AGE_BETWEEN_20_TO_40',
  AGE_BELOW_20: 'AGE_BELOW_20',
  AGE_ABOVE_40: 'AGE_ABOVE_40',
  ACNE_OR_PIMPLES: 'ACNE_OR_PIMPLES',
  FACE_PIGMENTATION: 'FACE_PIGMENTATION',
  HAIR_FALL: 'HAIR_FALL',
  DANDRUFF: 'DANDRUFF',
  SMOKER: 'SMOKER',
  POST_ACNE: 'POST_ACNE',
  ACNE_DETECTED: 'ACNE_DETECTED',
  HORMONAL_IMBALANCE: 'HORMONAL_IMBALANCE',
  PREGNANT: 'PREGNANT',
  STEROID: 'STEROID',
  NEW_MOM: 'NEW_MOM',
  BODY: 'BODY',
};

const boostYourRegimenTags = {
  UNDER_EYE: 'UNDER_EYE',
  GLOW: 'GLOW',
  DARK_SPOT: 'DARK_SPOT',
  ACNE: 'ACNE',
  MELASMA: 'MELASMA',
};

const ExcludeBoostProductsTags = {
  ACNE_DETECTED: 'ACNE_DETECTED',
  STEROID: 'STEROID',
  SENSITIVE_SKIN: 'SENSITIVE_SKIN',
  PREGNANT: 'PREGNANT',
  NEW_MOM: 'NEW_MOM',
  I_DERIVA: 'I_DERIVA',
};

const CannedResponse = {
  PossibleTags: ['acne', 'product', 'regimen', 'complaint', 'thank you'],
};

const BatchRequest = {
  Stage: {
    CREATED: 'CREATED',
  },
  Group: {
    COURIER: 'Courier',
    MARKETING: 'Marketing',
  },
};

const MetaBaseQueryType = {
  INITIAL_ORDER_CALLS: 'INITIAL_CALL_LIST',
  DELHIVERY: 'SHIP_NOW_DELIVERY',
  ECOM_EXPRESS: 'SHIP_NOW_ECOM',
  EKART: 'SHIP_NOW_EKART',
  EZ_SLIP: 'SHIP_NOW_EZSLIP',
  ALL: 'SHIP_NOW_ALL',
};

const ListSideBar = {
  Types: {
    DOCTOR: 'DOCTOR',
    LANGUAGES: 'LANGUAGES',
    OPERATOR: 'OPERATOR',
    LABELS: 'LABELS',
    TEAMS: 'TEAMS',
    DATE_FILTER: 'DATE FILTER',
    TYPE: 'TYPE',
    INTERNAL_TEAMS: 'INTERNAL TEAMS',
    LEAVES: 'LEAVES',
  },
  Icons: {
    DOCTOR: 'fas fa-user-md',
    LANGUAGES: 'fas fa-language',
    OPERATOR: 'fas fa-cog',
    LABELS: 'fas fa-user-tag',
    TEAMS: 'fas fa-users',
    DATE_FILTER: 'far fa-calendar-minus',
    TYPE: 'fas fa-stream',
    LEAVES: 'fas fa-stream',
  },
};

const CallConfirmation = {
  DOCTOR_CALL: 'DOCTOR_CALL',
  VOICE_INSTRUCTION: 'VOICE_INSTRUCTION',
  NONE: 'NONE',
};

const PendingCall: EnvType.PendingCall = {
  Type: {
    AddressConfirmation: 'AddressConfirmation',
    FollowUpSkipped: 'FollowUpSkipped',
    FollowUpImage: 'FollowUpImage',
    FollowUpPurchase: 'FollowUpPurchase',
    AreYouOk: 'AreYouOk',
    AbandonedCart: 'AbandonedCart',
    InitialOrder: 'InitialOrder',
    DoctorOrder: 'DoctorOrder',
    RegimenFollowUp: 'RegimenFollowUp',
    MissedCall: 'MissedCall',
    OrderConfirmation: 'OrderConfirmation',
    WaitingForImage: 'WaitingForImage',
    ScheduledCall: 'ScheduledCall',
    OnlinePaymentPending: 'OnlinePaymentPending',
    ConsultationOrder: 'ConsultationOrder',
    CanceledOrder: 'CanceledOrder',
    UnpaidUserDoctorCall: 'UnpaidUserDoctorCall',
    CheckIn: 'checkIn',
    DietCall: 'DietCall',
    MinoxidilCall: 'MinoxidilCall',
    RegimenOrderCancel: 'RegimenOrderCancel',
    UserRequestedCall: 'UserRequestedCall',
    ReacquisitionCall: 'ReacquisitionCall',
  },
  Status: {
    Requested: 'Requested',
  },
};

const Feed = {
  Type: {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
  },
};

const Actions = {
  close: 'CLOSE',
  edit: 'EDIT',
  new: 'NEW',
};

const Support = {
  Type: {
    COMPONENT: 'COMPONENT',
    URL: 'URL',
  },
  Queries: {
    Type: {
      FETCH_BY_OBJECT_ID: 'FETCH_BY_OBJECT_ID',
    },
  },
  Component: {
    HTML: 'HTML',
    REGIMEN_PRODUCT_LIST: 'REGIMEN_PRODUCT_LIST',
    REGIMEN_INSTRUCTION: 'REGIMEN_INSTRUCTION',
    ORDER_UPDATE: 'ORDER_UPDATE',
    PLACE_NEW_ORDER: 'PLACE_NEW_ORDER',
    GET_QUERY: 'GET_QUERY',
    ABOUT_MY_DOCTOR: 'ABOUT_MY_DOCTOR',
    SELECT_SUB_QUERY: 'SELECT_SUB_QUERY',
    TREE: 'TREE',
    REGIMEN_CLASS_SELECTION: 'REGIMEN_CLASS_SELECTION',
    VIEW_EDIT_ORDER: 'VIEW_EDIT_ORDER',
  },
};

const Ticket = {
  Status: {
    Pending: 'Pending',
    Completed: 'Completed',
    RequestClosure: 'RequestClosure',
  },
};

const ExternalUser = {
  username: 'evaluationInstantCheckupAccess',
};

const RegimenSop = {
  RegimenSop: 'REGIMENSOP',
  Class: {
    MILD: 'MILD',
    MODERATE: 'MODERATE',
    SEVERE: 'SEVERE',
  },
  Sensitivity: {
    SENSITIVE: 'SENSITIVE',
    NOT_SENSITIVE: 'NOT SENSITIVE',
    STEROID_ABUSE: 'STEROID ABUSE',
  },
  Type: {
    MAIN: 'MAIN',
    OBSERVATION: 'OBSERVATION',
  },
};

const Notification = {
  Type: {
    Text: 'Text',
    Audio: 'Audio',
  },
  Category: {
    Regimen: 'regimen',
    Order: 'order',
    Ticket: 'ticket',
    FollowUp: 'followUp',
    Referral: 'referral',
    General: 'general',
    Doctor: 'doctor',
    Cash: 'cash',
  },
};

const AddressBook = {
  TAGS: ['home', 'work', 'office', 'other'],
};

/**
 * Reminder feature configs
 */

const Reminder = {
  TYPE: {
    POPUP: 'POPUP',
    FULL_PAGE_POPUP: 'FULL_PAGE_POPUP',
  },
};

const Refund: EnvType.Refund = {
  Status: {
    REFUND_REQUESTED: 'REFUND_REQUESTED',
    REFUND_INITIATED: 'REFUND_INITIATED',
    REFUND_PROCESSED: 'REFUND_PROCESSED',
    PAYMENT_TRANSFER: 'PAYMENT_TRANSFER',
    REFUND_CANCELED: 'REFUND_CANCELED',
  },
};

const MedicalCouncils: Array<string> = [
  'Andhra Pradesh Medical Council',
  'Arunachal Pradesh Medical Council',
  'Assam Medical Council',
  'Bihar Medical Council',
  'Chattisgarh Medical Council',
  'Delhi Medical Council',
  'Goa Medical Council',
  'Gujarat Medical Council',
  'Haryana Medical Council',
  'Himanchal Pradesh Medical Council',
  'Jammu & Kashmir Medical Council',
  'Jharkhand Medical Council',
  'Karnataka Medical Council',
  'Kerala State Medical Councils',
  'Madhya Pradesh Medical Council',
  'Maharashtra Medical Council',
  'Maharashtra University of Health Sciences',
  'Manipur Medical Council',
  'Medical Council Of India',
  'Mizoram Medical Council',
  'Nagaland Medical Council',
  'Orissa Council of Medical Registration',
  'Punjab Medical Council',
  'Rajasthan Medical Council',
  'Sikkim Medical Council',
  'Tamil Nadu Medical Council',
  'Telangana State Medical Council',
  'Travancore Cochin Medical Council',
  'Tripura State Medical Council',
  'Uttarakhand Medical Council',
  'Uttar Pradesh Medical Council',
  'West Bengal Medical Council',
];

const TeamTypes = {
  SALES: [PendingCall.Type.CanceledOrder, PendingCall.Type.InitialOrder, PendingCall.Type.OnlinePaymentPending,
    PendingCall.Type.MissedCall, PendingCall.Type.ConsultationOrder],
  FOLLOWUP: [PendingCall.Type.RegimenFollowUp, PendingCall.Type.CheckIn, PendingCall.Type.FollowUpPurchase,
    PendingCall.Type.FollowUpImage, PendingCall.Type.FollowUpSkipped],
  CHATSUPPORT: [PendingCall.Type.DoctorOrder, PendingCall.Type.InitialOrder, PendingCall.Type.MissedCall,
    PendingCall.Type.FollowUpImage, PendingCall.Type.CheckIn],
  DOCTOR: [PendingCall.Type.FollowUpImage, PendingCall.Type.DoctorOrder],
  SHIPPING: [PendingCall.Type.DoctorOrder],
  WAITINGFORIMAGE: [PendingCall.Type.WaitingForImage, PendingCall.Type.MissedCall],
  CHECKIN: [PendingCall.Type.MissedCall, PendingCall.Type.FollowUpSkipped, PendingCall.Type.MinoxidilCall, PendingCall.Type.CheckIn],
  DIET: [PendingCall.Type.DietCall],
  INITIALTEAM: [PendingCall.Type.AbandonedCart, PendingCall.Type.InitialOrder, PendingCall.Type.DoctorOrder,
    PendingCall.Type.MinoxidilCall, PendingCall.Type.ReacquisitionCall, PendingCall.Type.UserRequestedCall],
  INTERN: [PendingCall.Type.OrderConfirmation],
};

const captureTimeoutInMinutes = 10;
const allocatedDoctorTimeoutInMinutes = 5;
const Shared: EnvType.Shared = {
  MedicalCouncils,
  AllocatedDoctorPopup,
  MetaBaseQueryType,
  Catalog,
  Refund,
  Coupon,
  Regimen,
  User,
  SMS,
  Order,
  FollowUp,
  Payment,
  Consultation,
  ScheduledNotification,
  MAIN_CONCERN,
  Assistant,
  BlockAssistant,
  BatchRequest,
  Question,
  Tree,
  Tag,
  ActionLog,
  InstantCheckup,
  RequestMessage,
  Seller,
  Tax,
  Event,
  EventScheduler,
  TreeTables,
  Service,
  ArticleData,
  Role,
  EmbeddedLink,
  CashTransaction,
  Notes,
  Languages,
  LanguageStringId,
  LeaveAndHoliday,
  CannedResponse,
  Team,
  Toast,
  Gender,
  ListSideBar,
  CallConfirmation,
  PendingCall,
  Feed,
  Actions,
  Table,
  Support,
  ExternalUser,
  Ticket,
  RegimenSop,
  AddressBook,
  Reminder,
  Notification,
  ShopTags,
  ExcludeBoostProductsTags,
  boostYourRegimenTags,
  FollowUpReport,
  TeamTypes,
  ConsultationSession,
  captureTimeoutInMinutes,
  allocatedDoctorTimeoutInMinutes,
};

const Default = {
  // List of user roles
  userRoles: ['guest', 'user', 'admin'],
};

export const VOIPConstants:{ [key: string]: string } = {
  projectID: '576-R9R-Z46Z',
  accountId: '655760f1c8667f9a854ea3cc',
  apikey: 'WizUQqTf85WZ88V5rtaLtbeJiN175d7xqcHMTPDlAk4wzndt0bs30cTsQGIs8jjp',
};

export { Shared, Default };
