import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Table } from 'api-client';
import { ConnectionService } from '../../../../../services/connection-service/index';
import { WindowRefService } from '../../../../../services/window-ref-service/index';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'add-section',
  templateUrl: './add-section.html',
  styleUrls: ['./add-section.scss'],
})

export class AddSectionComponent implements OnInit {
  supportCategory: any;

  constructor(private conn: ConnectionService,
    private document: WindowRefService,
    private dialogRef: MatDialogRef<AddSectionComponent>,
    private route: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig) {}

  async ngOnInit(): Promise<any> {
    this.supportCategory = new Table.SupportCategory();
  }

  async addCategory(): Promise<void> {
    if (this.supportCategory.get('isPrimary') === undefined) this.supportCategory.set('isPrimary', false);
    await this.supportCategory.save();
    this.hideModal();
  }

  public hideModal(): void {
    this.dialogRef.close();
  }
}
