
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/marketing/FAQ">FAQ</a></li>
          <li>{{editId? 'Edit' : 'New' }}</li>
        </ul>
        <button class="btn btn-xs pull-right" *ngIf="!addId && !editId" (click)="addNewId();">Add New FAQ Category</button>
      </div>
    </div>
    <div class="flex-content col-xs-12 p-0">
      <form class="col-xs-12 col-md-6 p-4" #FAQform="ngForm">
        <div class="col-xs-12 mt-0">
          <label class="col-xs-4" *ngIf="!addId || editId">Category</label>
          <div class="col-xs-7">
            <select class="w100" *ngIf="!addId || editId" [(ngModel)]="FAQId" required="required" name="FAQId" (ngModelChange)="FAQId" [value]="FAQId">
              <option *ngFor="let id of FAQIds; let i = index;" [value]="id.id">{{id.get('title')}}</option>
            </select>
          </div>
          <div class="col-xs-7">
            <select class="w100" *ngIf="addId && !editId" [(ngModel)]="newFAQCategory" required="required" name="newFAQCategory" (ngModelChange)="newFAQCategory" [value]="newFAQCategory">
              <option *ngFor="let id of faqSections; let i = index;" [value]="id.id">{{id.get('en')}}</option>
            </select>
          </div>
          <div class="col-xs-12 mt-3">
            <button class="btn btn-xs" *ngIf="addId" (click)="addNewFAQCategory();">Add FAQ Category</button>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="!addId">
          <label class="col-xs-4">Question</label>
          <div class="col-xs-7">
            <select class="w100" [(ngModel)]="question" required="required" name="question" (ngModelChange)="question" [value]="question">
              <option *ngFor="let id of faqQAndA; let i = index;" [value]="id.id">{{id.get('en')}}</option>
            </select>
          </div>
        </div>
        <div class="col-xs-12 mt-3" *ngIf="!addId">
          <label class="col-xs-4">Answer</label>
          <div class="col-xs-7">
            <select class="w100" [(ngModel)]="answer" required="required" name="answer" (ngModelChange)="answer" [value]="answer">
              <option *ngFor="let id of faqQAndA; let i = index;" [value]="id.id">{{id.get('en')}}</option>
            </select>
          </div>
        </div>
        <div class="col-xs-12 mt-3" *ngIf="!addId">
          <label class="col-xs-4">Search Keys</label>
          <div class="col-xs-7">
            <input class="w100" [(ngModel)]="keyword" name="keyword" type="text" required="required" placeholder="Keywords seperated by comma"/>
          </div>
        </div>
        <div class="col-xs-12 mt-4" *ngIf="!addId">
          <button class="btn btn-xs" type="reset" [disabled]="!FAQform.valid" (click)="addQuestionInFAQ();">{{ editId ? "Update FAQ": "Add FAQ"}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
