import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'show-sms-modal',
  templateUrl: './sms.html',
})
export class SMSDialog {
  message: any = '';
  messageRoute: any;
  userMobileNumber: any;
  userId: any;
  operator: any;
  static parameters: any = [ConnectionService, AppConfig, MatDialogRef, MAT_DIALOG_DATA];
  constructor(private conn: ConnectionService,
              public appConfig: AppConfig,
              private dialogRef: MatDialogRef<SMSDialog>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.messageRoute = this.appConfig.Shared.SMS.MSG91.ROUTE.TRANSACTIONAL;
    this.userId = this.data.userId;
    this.userMobileNumber = this.data.userMobileNumber;
    this.operator = this.data.operator;
  }

  public sendMessage(): void {
    this.conn.sendNotificationSMS(this.message, this.userMobileNumber, this.userId, this.operator, this.messageRoute)
      .then(() => this.hideModal());
  }

  public hideModal(): void {
    this.dialogRef.close();
  }
}
