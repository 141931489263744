import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiConnector } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { CatalogVariantModal } from './catolog-variant-modal';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({
  selector: 'catalog-view',
  templateUrl: './catalog-view.html',
  styleUrls: ['./catalog-view.scss'],
})
export class CatalogViewComponent implements OnDestroy {
  productObj: any;
  variantObj: Array<any>;
  sampleObj: Array<any>;
  showInactive: Boolean;
  regimens: any;
  ui: any;
  restrictions: Array<any>;
  sideEffects: Array<any>;
  indicationRestrictions: Array<any>;
  subscriptions: Array<Subscription>;
  data: any;
  articles: any;
  user: any;
  embeddedLink: any;
  iframeLink: SafeResourceUrl;
  constructor(private route: ActivatedRoute, private conn: ConnectionService, public appConfig: AppConfig, private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private broadcaster: Broadcaster) {
  }

  ngOnInit(): void {
    this.regimens = [];
    this.showInactive = false;
    this.subscriptions = [];
    this.subscriptions = [];
    this.variantObj = [];
    this.sampleObj = [];
    this.restrictions = [];
    this.sideEffects = [];
    this.indicationRestrictions = [];
    this.user = ApiConnector.getCurrentUser();
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.user });
    this.ui = { editModalOpenFlag: false, editProduct: undefined };
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(async () => {
      if (this.route.parent.snapshot.data.catalog) {
        const mainProduct = this.route.parent.snapshot.data.catalog;
        this.productObj = mainProduct;
        await this.conn.getCatalogs({ mainProduct: mainProduct.id, include: ['article'] })
          .then((data: any) => {
            this.data = data;
            data.map((item: any) => {
              if (item.get('type') === 'variant') this.variantObj.push(item);
              else if (item.get('type') === 'sample') this.sampleObj.push(item);
              return 0;
            });
          });
        const [tempData]: any = JSON.parse(JSON.stringify(this.data));
        if (tempData) {
          this.articles = tempData.article;
        }
        this.iframeLink = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${this.conn.getWebAppUrl()}/article/${this.articles?.objectId}`);
        this.findProductRegimens();
        this.fetchFieldLanguageStrings('restrictions', this.data[0].get('restrictions'));
        this.fetchFieldLanguageStrings('sideEffects', this.data[0].get('sideEffects'));
        this.fetchFieldLanguageStrings('indicationRestrictions', this.data[0].get('indicationRestrictions'));
      }
    }));
  }

  async fetchFieldLanguageStrings(localFieldName: string, languageStringPointers: Array<any> = []): Promise<any> {
    this[localFieldName] = await this.conn.getLanguageStrings({ id: languageStringPointers.map((item: any) => item.id) });
  }

  async findProductRegimens(): Promise<any> {
    this.regimens = await this.conn.getCatalogRegimens(this.productObj);
  }

  saveResponse(responseFromModal: any): void {
    if (responseFromModal.info === 'SAVED'
      && responseFromModal.product.get('createdAt') === responseFromModal.product.get('updatedAt')) {
      if (responseFromModal.product.get('type') === 'variant') this.variantObj.push(responseFromModal.product);
      else this.sampleObj.push(responseFromModal.product);
    } else if (responseFromModal.err) alert(responseFromModal.err.message);
  }

  openCatalogVariantModal(product: any): void {
    const dialogRef = this.dialog.open(CatalogVariantModal, { data: { product } });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((responseFromModal: any) => {
      if (!responseFromModal) return;
      this.saveResponse(responseFromModal);
    }));
  }

  addCatalog(type: string): void {
    const catalog = new Table.Catalog();
    const parentJson = JSON.parse(JSON.stringify(this.productObj));
    const excludeKeys = ['createdAt', 'updatedAt', 'objectId', 'mainProduct', 'mrp', 'price', 'quantity'];
    Object.keys(parentJson)
      .map((item: any) => {
        if (!excludeKeys.includes(item)) {
          catalog.set(item, this.productObj.get(item));
        }
        return 0;
      });
    catalog.set('type', type.toLowerCase());
    if (type === 'SAMPLE') {
      catalog.set('mrp', 0);
      catalog.set('price', 0);
    }
    catalog.set('mainProduct', this.productObj);
    this.openCatalogVariantModal(catalog);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.route;
    delete this.conn;
    delete this.subscriptions;
    delete this.productObj;
    delete this.regimens;
  }
}
