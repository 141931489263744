import { Component, Inject } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'add-payment-modal', templateUrl: './add-payment.html' })
export class AddPaymentModal {
  payment: { amount?: number, note: string, source: string };
  public paymentSources: Array<string>;

  constructor(
    private conn: ConnectionService,
    private appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddPaymentModal>) {
    this.payment = { note: '', source: ApiClientConstant.Payment.PaymentSource.COD };
    this.paymentSources = Object.values(ApiClientConstant.Payment.PaymentSource);
  }

  ngOnInit(): void {
    this.paymentSources = this.paymentSources.filter((source: string) => !['COD', 'CCOD'].includes(source));
    this.payment = { note: '', source: ApiClientConstant.Payment.PaymentSource.Refund };
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  savePayment(): void {
    const payload = {
      PaymentSource: this.payment.source,
      userId: this.data.order.user.username,
      paymentPlan: this.data.order.type,
      TransactionAmount: this.payment.source === ApiClientConstant.Payment.PaymentSource.Refund
        ? `${this.payment.amount * -100}`
        : `${this.payment.amount * 100}`,
      byUserObjectId: this.conn.getCurrentUser().id,
      PaymentStage: ApiClientConstant.Payment.Stage.TXN_SUCCESS,
      UserEmailId: this.data.order.user.PatientEmail,
      UserMobileNumber: this.data.order.user.MobileNumber,
      orderObjectId: this.data.order.objectId,
      notes: this.payment.note,
      uniqueId: uuid(),
      regimenId: undefined,
    };
    if (this.data.order.regimenId) {
      payload.regimenId = this.data.order.regimenId;
    }
    this.conn.savePayment(payload).then((payment:any) => this.dialogRef.close(payment));
  }
}
