import { NgZone, Component, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { LocalStorage } from 'src/services/local-storage-service';
import { ApiClientConstant, ParseKeys, RequestQueryPayload, Table } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'outgoing',
  templateUrl: './outgoing.html',
  styleUrls: ['./outgoing.scss'],
})
export class OutgoingComponent implements OnDestroy {
  @ViewChild('scrollHeading', { static: false }) scrollHeadingView: ElementRef;
  @ViewChild('scrollBody', { static: false }) scrollBodyView: ElementRef;
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  chats: any;
  user: any;
  sort: { field: string, ascending: boolean };
  params: any;
  parametersObservable: any;
  dataSource: any;
  window: any;
  components: any;
  gridApi: GridApi;
  ui: any = {};
  columnDefs: any;
  count: any;
  selectedIndex: number = -1;
  teams: any;
  resetLanguageFilter: boolean;
  languageFilter: any[];
  resetOperatorFilter: boolean;
  operatorFilter: any[];
  resetDoctorFilter: boolean;
  doctorFilter: any[];
  currentUser: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private conn: ConnectionService,
    private el: ElementRef, windowRef: WindowRefService,
    public appConfig: AppConfig,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.conn.getCurrentUser();
    this.user = new Table.User();
    this.listViewSetup();
    const roles = this.storage.getJsonValue('userRoles') || [];
    const teams = Object.keys(ApiClientConstant.Role.Name)
      .map((each: string) => ApiClientConstant.Role.Name[each]);
    this.teams = roles.filter((each: any) => teams.includes(each));
    this.route.queryParams.subscribe(() => this.reset());
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  resetFilters(): void {
    this.resetLanguageFilter = true;
    this.resetDoctorFilter = this.teams.includes(ApiClientConstant.Role.Name.DOCTOR);
    this.resetOperatorFilter = this.teams.includes(ApiClientConstant.Role.Name.CHAT_SUPPORT);
    delete this.count;
    this.reset();
  }

  async loadMore(params: any): Promise<Array<any>> {
    const { colId, sort }: { colId: ParseKeys<Table.AttendToChatCache>, sort: 'asc' | 'desc' } = params.sortModel[0]
    || { colId: 'messageUpdateTime', sort: 'asc' };
    const asc: boolean = sort !== 'desc';
    const payload: {
      limit?: number,
      offset?: number,
      attended: boolean,
      orderDes?: string,
      sort?: { field: ParseKeys<Table.AttendToChatCache>, ascending: boolean },
      count?: boolean,
      operators?: Array<any>,
      doctors?: Array<any>,
      teams?: Array<ValueOf<typeof ApiClientConstant.Role.Name>>,
      languagePreference?: Array<ValueOf<typeof ApiClientConstant.LanguageString>>,
    } = {
      sort: { field: colId, ascending: asc },
      limit: this.ui.limit,
      offset: this.ui.page * this.ui.limit,
      attended: true,
      languagePreference: this.languageFilter,
      doctors: this.doctorFilter,
      operators: this.operatorFilter,
    };
    let datas = await this.conn.getAttendToChatMessages(payload);
    this.count = await this.conn.getAttendToChatMessages({ attended: true,
      count: true,
      languagePreference: this.languageFilter,
      doctors: this.doctorFilter,
      operators: this.operatorFilter },
    );
    datas = JSON.parse(JSON.stringify(datas));
    return datas;
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const datas = await this.loadMore(params);
        if (params.startRow === 0 && !datas.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(datas, datas.length === this.ui.grid.pageSize ? -1 : params.startRow + datas.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'UserId',
      field: 'user.username',
      pinned: 'left',
      editable: true,
      width: 270,
      cellRenderer: (params: any): any => {
        const url = this.router.url.split('/');
        let eDiv;
        url.splice(url.length - 1, 1);
        if (!params.data) {
          eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv = this.window.document.createElement('div');
        const id = params.value;
        eDiv.innerHTML = `<a href='/chat/${params.value.UserId
          || params.data.user.username}' target='_blank'>${id} (${this.conn.getUserHash(id)})</a>`;
        return eDiv;
      },
    }, {
      headerName: 'Patient Name',
      field: 'user.PatientName',
      width: 170,
    }, {
      headerName: 'Message',
      field: 'message',
      width: 450,
      cellRenderer: (params: any): any => {
        if (!params.data || !params.value) {
          return '';
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<a class="w100" style="display: block" href='/chat/${params.value.UserId
        || params.data.user.username}' target='_blank'> ${params.value.Message}</a>`;
        eDiv.setAttribute('title', params.value.Message);
        return eDiv;
      },
    },
    {
      headerName: 'Last Active',
      field: 'updatedAt',
      width: 120,
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<span> ${moment(params.value).fromNow()} </span>`;
        return eDiv;
      },
    }, {
      headerName: 'Waiting For',
      field: 'messageUpdateTime',
      width: 120,
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = params.value ? `<span> ${moment(params.value.iso).fromNow()} </span>` : params.data.id;
        return eDiv;
      },
    }, {
      headerName: 'Operator',
      field: 'allocatedOperator.username',
      width: 120,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        let operator = 'NONE';
        if (params.value) {
          operator = params.value.split('operator')[0];
        }
        eDiv.innerHTML = `${operator}`;
        return eDiv;
      },
    }];
  }

  updateOperatorFilter(operatorFilter: Array<any>): void {
    this.resetOperatorFilter = false;
    this.operatorFilter = operatorFilter;
    this.reset();
  }

  updateDoctorFilter(doctorFilter: Array<any>): void {
    this.resetDoctorFilter = false;
    this.doctorFilter = doctorFilter;
    this.reset();
  }

  updateLanguageFilter(languageFilter: Array<any>): void {
    this.resetLanguageFilter = false;
    this.languageFilter = languageFilter;
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  ngOnDestroy(): void {
    if (this.parametersObservable != null) {
      this.parametersObservable.unsubscribe();
    }
    delete this.route;
    delete this.zone;
    delete this.conn;
    delete this.el;
    delete this.appConfig;
    delete this.ui;
    delete this.chats;
    delete this.user;
    delete this.params;
    delete this.parametersObservable;
    delete this.window;
    delete this.selectedIndex;
    delete this.operatorFilter;
    delete this.doctorFilter;
    delete this.languageFilter;
  }
}
