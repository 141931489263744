import { isFunction, noop } from 'lodash';

export function safeCb(cb: Function): Function {
  return isFunction(cb) ? cb : noop;
}

export function rotateCanvasBasedOnOrientation(orientation: any, ctx: any, canvas: any): void {
  switch (orientation) {
    case 6: {
      ctx.translate(canvas.width, 0);
      ctx.rotate(90 * (Math.PI / 180));
      break;
    }
    case 8: {
      ctx.rotate(-0.5 * Math.PI);
      ctx.translate(-canvas.height, 0);
      break;
    }
    case 3: {
      ctx.rotate(Math.PI);
      ctx.translate(-canvas.width, -canvas.height);
      break;
    }
    case 5: {
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    }
    case 7: {
      ctx.transform(0, -1, -1, 0, canvas.width, canvas.height);
      break;
    }
    case 4: {
      ctx.transform(1, 0, 0, -1, 0, canvas.height);
      break;
    }
    case 2: {
      ctx.transform(-1, 0, 0, 1, canvas.width, 0);
      break;
    }
    default: break;
  }
}
