import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { AppConfig } from '../../../app/app.config';
import { InputType } from '../../../../typings/client/input';
import { InputHelperService } from '../input-helper';

@Component({
  selector: 'input-select-text',
  templateUrl: './input-select-text.html',
  styleUrls: ['./input-select-text.scss'],
})
export class InputSelectTextComponent {
  @Input('name') name: string;
  @Input() defaultSelect: string;
  @Input('selectWidth') selectWidth: string = '160px';
  @ViewChild('inputSelect', { static: false }) inputSelect: ElementRef;
  @ViewChild('optionsElement', { static: false }) optionsElement: ElementRef;

  @Input('options')
  set onUpdateOptions(options: Array<InputType.SelectOption>) {
    if (!options?.length) {
      this.options = [];
      return;
    }
    this.options = options;
  }
  options: Array<InputType.SelectTextOption> = [];
  @Input('class') className: string = '';
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }
  disabled: boolean = false;
  selectedValue: string | number;
  selectedDisplay: string | number;
  @Output('onValueChanged') onValueChanged: EventEmitter<string> = new EventEmitter();

  constructor(public appConfig: AppConfig, private inputHelper: InputHelperService) {}

  ngOnInit(): void {
    this.selectedDisplay = this.defaultSelect;
  }

  onInputChange(selectedValue: string, selectedDisplay: string): void {
    this.selectedValue = selectedValue;
    this.selectedDisplay = selectedDisplay;
    this.onValueChanged.emit(this.selectedValue);
    this.hideOptions();
  }

  showOption(): void {
    if (this.optionsElement.nativeElement.style.visibility === 'visible') {
      this.hideOptions();
      return;
    }
    this.showOptions();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    const isInside = this.inputSelect.nativeElement.contains(clickedElement);

    if (!isInside) {
      this.hideOptions();
    }
  }

  hideOptions(): void {
    this.optionsElement.nativeElement.style.visibility = 'hidden';
    this.inputSelect.nativeElement.style.borderBottomLeftRadius = '20px';
    this.inputSelect.nativeElement.style.borderBottomRightRadius = '20px';
  }

  showOptions(): void {
    this.optionsElement.nativeElement.style.visibility = 'visible';
    this.inputSelect.nativeElement.style.borderBottomLeftRadius = '0px';
    this.inputSelect.nativeElement.style.borderBottomRightRadius = '0px';
  }
}
