import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../components/directives.module';
import { LanguageStringViewComponent } from './language-string-view.component';

const ROUTES: Routes = [
  { path: '', component: LanguageStringViewComponent, pathMatch: 'full' },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  MomentModule,
  DirectivesModule,
  RouterModule.forChild(ROUTES),
],
declarations: [LanguageStringViewComponent],
exports: [LanguageStringViewComponent] })
export class LanguageStringViewModule {
}
