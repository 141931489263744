import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../../../components/directives.module';
import { OpenSupportTicketsListComponent } from './open-support-tickets-list.component';

const ROUTES: Routes = [
  { path: '', component: OpenSupportTicketsListComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MomentModule,
    MatDialogModule,
    RouterModule.forChild(ROUTES),
    AgGridAngular,
  ],
  declarations: [OpenSupportTicketsListComponent],
  exports: [OpenSupportTicketsListComponent],
})
export class OpenSupportTicketsListModule {}
