import * as moment from 'moment';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { FileUploader } from 'ng2-file-upload';
import { ApiClientConstant, RequestQueryPayload, Table } from 'api-client';
import { FilterType } from '../../../../typings/client/filter';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { WindowRefService } from '../../../services/window-ref-service';

@Component({ selector: 'list', templateUrl: './refund.html', styleUrls: ['./refund.scss'] })
export class RefundComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  partialOrder: boolean = false;
  resetLanguageFilter: boolean = false;
  userService: Array<string> = [];
  languageFilter: Array<string> = [];
  orders: Array<any>;
  window: any;
  filters: any = [{}];
  dropdownFilter: any[] = [];
  components: any;
  Orders: any;
  ui: any;
  url: any;
  columnDefs: any;
  filterConfig: Array<FilterType.FilterConfigType>;
  uploader: FileUploader;
  OrderAsc: boolean = false;
  a: any;
  count: number;
  doctorFilter: Array<any> = [];
  searchKey: any;
  isDoctor: boolean;
  resetDoctorFilter: boolean = false;
  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    public windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Order No.',
      field: 'order.orderNumber',
      pinned: 'left',
      cellRenderer: (params: any): any => {
        if (!params.data) {
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const eDiv = this.window.document.createElement('div');
        const id = params.value || params.data.objectId;
        const link = `/order/${id}`;
        eDiv.innerHTML = `<div class='list-label'><a class="p-0 ellipsis mLR5" target="_blank"
          href='${link}'>${id}</a></div>`;
        return eDiv;
      },
      width: 110,
    }, {
      headerName: 'Patient Name',
      field: 'order.contactDetail.contactName',
      cellRenderer: (params: any): any => {
        if (!params.data) {
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
      width: 150,
    }, {
      headerName: 'Created On',
      field: 'createdAt',
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('MMM DD,hh:mm a');
        return eDiv;
      },
      width: 120,
    }, {
      headerName: 'stage',
      field: 'order.stage',
    }, {
      headerName: 'Recent Note',
      field: 'order.notes',
      cellRenderer: (params: any): any => {
        if (!params.value || !params.value.length) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = params.value[params.value.length - 1].message;
        return eDiv;
      },
      width: 500,
    }];
  }

  async loadMore(params: any): Promise<any> {
    const requestPayload: RequestQueryPayload<Table.Refund> = {
      where: { status: ApiClientConstant.Refund.Status.REFUND_REQUESTED },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['order'],
      project: [],
    };
    this.count = 0;
    let data;
    [data, this.count] = await Promise.all([
      this.conn.findRefunds(requestPayload).then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      this.conn.countRefunds(requestPayload),
    ]);
    return data;
  }

  reset(): void {
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.orders;
    delete this.filters;
    delete this.ui;
  }
}
