import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageStringMultipleSelectionWithTagComponent } from './language-string-multiple-selection-with-tag.component';
import { LanguageStringSelectionModule } from '../language-string-selection';
import { LanguageStringTagSelectionModule } from '../language-string-tag-selection';
import { LanguageViewModule } from '../language-view';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LanguageViewModule,
    LanguageStringSelectionModule,
    LanguageStringTagSelectionModule,
  ],
  declarations: [LanguageStringMultipleSelectionWithTagComponent],
  exports: [LanguageStringMultipleSelectionWithTagComponent],
})
export class LanguageStringMultipleSelectionWithTagModule {}
