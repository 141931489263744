import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiClientConstant, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { Broadcaster } from '../../../components/broadcaster';

@Component({
  selector: 'dashboard-leave',
  templateUrl: './leave.html',
  styleUrls: ['./leave.scss', '../dashboard.scss'],
})

export class DashboadLeaveComponent {
  @Input('message') message: string = '';
  @Input('user') user: any;
  @Input('startDate') startDate: any;
  @Input('endDate') endDate: any;
  @Input('userType') userType: string;
  @Input('ignoreUserType') ignoreUserType: boolean;

  // leaves variable
  doctorLeaves: Array<any> = [];

  constructor(private conn: ConnectionService,
      private router: Router,
      private route: ActivatedRoute,
      private appConfig: AppConfig,
      private broadcaster: Broadcaster) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.startDate && this.endDate && this.user) {
      await this.getDoctorsLeaves();
    }
  }

  async getDoctorsLeaves(): Promise<void> {
    if (!this.endDate || !this.startDate || !this.user) {
      return;
    }
    const payload: RequestQueryPayload<Table.LeaveAndHoliday> = {
      where: {
        endTime: { $gte: this.endDate },
        startTime: { $lte: this.startDate },
        status: ApiClientConstant.LeaveAndHoliday.STATUS.APPROVED,
      },
      include: ['user'],
      project: ['user.username' as 'user', 'user.type' as 'user', 'user.DoctorDisplayName' as 'user',
        'user.userType' as 'user', 'holidayType'],
    };
    const totalLeaves = await this.conn.findLeavesAndHolidays(payload);
    this.doctorLeaves = totalLeaves.filter((each: any) => each.get('user')?.get('type') === ApiClientConstant.User.Type.DOCTOR);
    if (this.userType) {
      this.doctorLeaves = totalLeaves.filter((each: any) => each.get('user')?.get('userType').includes(this.userType));
    }
  }
}
