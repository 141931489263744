import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadFileService } from '../../services/uploadFileService/uploadFile.service';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { WindowRefService } from '../../services/window-ref-service';

@Component({ selector: 'upload-audio', templateUrl: './upload-audio.html' })
export class UploadAudioDialog {
  window: any;
  file: File;
  audioUrl: string;
  @ViewChild('url', { static: false }) url: ElementRef;
  constructor(private upload: UploadFileService,
      private appConfig: AppConfig,
      private conn: ConnectionService,
      private windowRef: WindowRefService,
      private dialogRef: MatDialogRef<UploadAudioDialog>) {
    this.window = windowRef.nativeWindow;
  }

  uploadFiles(file: any): void {
    this.file = file;
  }

  public uploadToAws(): void {
    this.upload.uploadFile({
      file: this.file,
      bucket: 'AUDIO',
      username: this.conn.getCurrentUser().get('username'),
      source: 'voice_recording',
    })
      .then((data: any) => {
        this.url.nativeElement.value = data;
        this.audioUrl = data;
        setTimeout(() => {
          this.url.nativeElement.select();
          this.window.document.execCommand('copy');
          alert('Link copied to clipboard. If not copy it manually from the below input box');
        });
      });
  }

  public hideModal(): void {
    this.dialogRef.close();
  }
}
