import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiClientConstant, Table, RequestQueryPayload } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Broadcaster } from '../../../../components/broadcaster';
import { InputType } from '../../../../../typings/client/input';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent {
  startDate: Date;
  endDate: Date;
  isPersonalLeave: boolean = true;
  leaveAndHolidayObj: any;
  username: any;
  doctorList: Array<any>;
  userRoles: Array<any>;
  holidayType: string;
  status: string;
  otherInternalUserLeaves: Array<any> = [];
  exceptions: Array<string> = [];
  remainingLeaves: number;
  currentUser: any;
  usertype: string;
  operators: Array<any> = [];
  doctors: Array<any> = [];
  leaveEditor: boolean = false;
  modeOptions: Array<InputType.SelectOption> = [];
  today: Date;
  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private router: Router,
    private route: ActivatedRoute,
    private broadcaster: Broadcaster,
    private storage: LocalStorage) { }

  async ngOnInit(): Promise<any> {
    this.leaveAndHolidayObj = new Table.LeaveAndHoliday();
    this.userRoles = this.storage.getJsonValue('userRoles') || [];
    this.currentUser = this.conn.getCurrentUser();
    this.usertype = this.currentUser.get('type');
    const modes = Object.values(this.appConfig.Shared.LeaveAndHoliday.MODE);
    modes.forEach((each: string) => this.modeOptions.push({ display: each, value: each }));
    await this.getAllDoctorsAndOperators();
    await this.subscribeToUrlQueryParams();
    await this.getOtherInternalUsersLeaves();
    this.checkIfCurrentUserCanApproveLeave();
    this.today = new Date();
  }

  checkIfCurrentUserCanApproveLeave(): void {
    if (this.userRoles.includes('leaveEditor')) {
      this.leaveEditor = true;
    }
  }

  async getOtherInternalUsersLeaves(): Promise<void> {
    if (!this.startDate || !this.endDate) return;
    const requestPayload: RequestQueryPayload<Table.LeaveAndHoliday> = {
      where: {
        status: ApiClientConstant.LeaveAndHoliday.STATUS.APPROVED,
        $or: [
          {
            $and: [
              { startTime: { $gte: this.startDate } },
              { startTime: { $lte: this.endDate } },
            ],
          },
          {
            $and: [
              { startTime: { $lte: this.startDate } },
              { endTime: { $gte: this.endDate } },
            ],
          },
          {
            $and: [
              { startTime: { $lte: this.startDate } },
              { endTime: { $gte: this.startDate } },
            ],
          },
        ],
      },
      include: ['user'],
      project: ['startTime', 'endTime', 'holidayType', 'status', 'user.DoctorDisplayName' as 'user', 'user.username' as 'user'],
      descending: 'createdAt',
    };
    if (this.usertype === ApiClientConstant.User.Type.OPERATOR) {
      requestPayload.where.user = { $in: this.operators };
    }
    if (this.usertype === ApiClientConstant.User.Type.DOCTOR) {
      requestPayload.where.user = { $in: this.doctors };
    }
    this.otherInternalUserLeaves = JSON.parse(JSON.stringify(await this.conn.findLeavesAndHolidays(requestPayload)));
    this.otherInternalUserLeaves.map((value: any): void => Object.assign(value,
      { startDate: moment(value.startTime.iso).format('MMM DD,YYYY'), endDate: moment(value.endTime.iso).format('MMM DD,YYYY') }),
    );
  }

  async getAllDoctorsAndOperators(): Promise<void> {
    this.operators = await this.conn.fetchAllActiveOperators({}, ['username']);
    this.doctors = await this.conn.findUsers({
      where: { type: 'doctor', inactive: false },
      limit: 2000,
      project: ['username'],
    });
  }

  subscribeToUrlQueryParams(): void {
    this.route.parent.params.subscribe(async () => {
      if (this.route.parent.snapshot.data.leavesAndHolidays) {
        this.leaveAndHolidayObj = this.route.parent.snapshot.data.leavesAndHolidays;
        this.startDate = this.leaveAndHolidayObj.get('startTime');
        this.endDate = this.leaveAndHolidayObj.get('endTime');
        this.isPersonalLeave = !!this.leaveAndHolidayObj.get('user');
        this.status = this.leaveAndHolidayObj.get('status');
        this.exceptions = this.leaveAndHolidayObj.get('exceptions');
        this.usertype = this.leaveAndHolidayObj.get('user')?.get('type');
        if (this.isPersonalLeave) {
          const user = await this.leaveAndHolidayObj.get('user').fetch();
          this.username = user.get('username');
          await this.updateRemainingLeaves();
        } else {
          this.username = 'Public Holiday';
        }
      } else {
        await this.togglePersonalLeave();
      }
    });
  }

  async togglePersonalLeave(): Promise<void> {
    if (!this.isPersonalLeave) {
      return this.leaveAndHolidayObj.unset('user');
    }
    this.username = this.currentUser.get('username');
    this.leaveAndHolidayObj.set('user', this.currentUser);
    return this.updateRemainingLeaves();
  }

  async updateRemainingLeaves(): Promise<void> {
    let totalLeaves;
    if (this.usertype === ApiClientConstant.User.Type.OPERATOR) {
      totalLeaves = this.appConfig.Shared.LeaveAndHoliday.MAX_OPERATOR_NO_OF_LEAVES;
    } else {
      totalLeaves = this.appConfig.Shared.LeaveAndHoliday.MAX_DOCTOR_NO_OF_LEAVES;
    }
    this.remainingLeaves = totalLeaves - await this.conn.getNumberOfApprovedLeavesForUserDuringLastYear(this.username);
  }

  async onChangeDoctor(internalUser: any = this.currentUser): Promise<void> {
    if (this.username === 'Public Holiday') {
      this.isPersonalLeave = false;
      return;
    }
    if (this.usertype === ApiClientConstant.User.Type.OPERATOR) {
      return;
    }
    this.leaveAndHolidayObj.set('user', internalUser);
    await this.updateRemainingLeaves();
  }

  async approveLeave(): Promise<void> {
    this.leaveAndHolidayObj.set('status', ApiClientConstant.LeaveAndHoliday.STATUS.APPROVED);
    await this.leaveAndHolidayObj.save();
    this.broadcaster.broadcast('NOTIFY', { message: 'Leave Approved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    this.router.navigate(['/users/leaves']);
  }

  async rejectLeave(): Promise<void> {
    this.leaveAndHolidayObj.set('status', ApiClientConstant.LeaveAndHoliday.STATUS.REJECTED);
    await this.leaveAndHolidayObj.save();
    this.broadcaster.broadcast('NOTIFY', { message: 'Leave Rejected Successfully', type: this.appConfig.Shared.Toast.Type.ERROR });
    this.router.navigate(['/users/leaves']);
  }

  async saveLeave(): Promise<any> {
    if (this.leaveAndHolidayObj.id) {
      return;
    }
    if (this.otherInternalUserLeaves.length > 2
      && this.usertype === ApiClientConstant.User.Type.DOCTOR) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'More than two others doctors hve applied for leave on same day',
        type: this.appConfig.Shared.Toast.Type.WARNING,
      });
    }
    if (this.otherInternalUserLeaves.length) {
      const confirmLeave: boolean = confirm(`Other ${this.usertype}'s is also leave on these dates. Do you still want to continue?`);
      if (!confirmLeave) return;
    }
    try {
      if (this.username === 'Public Holiday') {
        this.leaveAndHolidayObj.unset('user');
        this.leaveAndHolidayObj.set('holidayType', this.holidayType);
      }
      this.leaveAndHolidayObj.set('startTime', this.startDate);
      this.leaveAndHolidayObj.set('endTime', this.endDate);
      this.leaveAndHolidayObj.set('exceptions', this.exceptions);
      await this.leaveAndHolidayObj.save();
      this.router.navigate(['/users/leaves']);
    } catch (err) {
      alert(err.message);
    }
  }

  onSelectDoctor(doctorDetails: { name: string, object: any}): void {
    this.username = doctorDetails.object.get('username');
    this.onChangeDoctor(doctorDetails.object);
  }
}
