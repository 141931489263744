import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { LocalStorage } from '../../../../services/local-storage-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { ImageUploadDialog } from './image-upload-dialog';
import { Broadcaster } from '../../../../components/broadcaster';
import { HelperService } from '../../../../services/helper-service';
import { EditInstantCheckup } from './edit-instant-checkup';

@Component({
  selector: 'instant-checkup-modal',
  templateUrl: './instant-checkup.html',
  styleUrls: ['./instant-checkup.scss'],
})
export class InstantCheckupDialog {
  beforeBottomCallback: Function = () => this.getInstantCheckupReport();
  messages: Array<any> = [];
  file: File;
  chatUser: any;
  userRoles: Array<any>;
  baseUrlForWebApp: string;
  instantCheckups: Array<any> = [];
  instantCheckupCheckCount: number = 2;
  popUpModel: { loading: boolean };
  imageType: string;
  types: Array<any>;
  currentDoctor: any;
  loading: boolean = false;
  comparePhoto: Array<any> = [];

  @Input() set user(userObj: any) {
    this.setUserAndFetchData(userObj);
  }
  @Input() set doctor(userObj: any) {
    this.currentDoctor = userObj;
  }
  @Input('showImagesInColumn') showImagesInColumn: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() onComparisonImageSelection: EventEmitter<any> = new EventEmitter();
  public subscriptions: Subscription[] = [];

  constructor(private windowRef: WindowRefService,
        private conn: ConnectionService,
        private appConfig: AppConfig,
        private zone: NgZone,
        private storage: LocalStorage,
        private http: HttpClient,
        private broadcaster: Broadcaster,
        private helperService: HelperService,
        private dialog: MatDialog) {
    this.popUpModel = { loading: false };
  }

  ngOnInit(): void {
    this.baseUrlForWebApp = this.conn.getBaseUrl();
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.types = ['FRONT_FACE', 'LEFT_SIDE_FACE', 'RIGHT_SIDE_FACE', 'SIDE_FACE', 'HAIR_TOP', 'HAIR_FRONT', 'BODY', 'REPORT', 'OTHER'];
  }

  async setUserAndFetchData(user: any): Promise<any> {
    if (user.id) {
      this.chatUser = user;
      this.getInstantCheckupReport();
    }
  }

  openImageUploadDialog(): void {
    this.messages = [];
    const dialogRef = this.dialog.open(ImageUploadDialog, { data: { file: this.file,
      imageType: this.imageType,
      types: this.types,
      chatUser: this.chatUser } });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) return;
      this.file = result.file;
      this.imageType = result.imageType;
      this.loading = false;
      this.instantCheckups = [];
      this.getInstantCheckupReport();
    }));
  }

  openInstantCheckupEditPopup(id: string, imageUrl: string): void {
    const dialogRef = this.dialog.open(EditInstantCheckup, { data: { id, imageUrl } });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) return;
      this.loading = false;
      this.instantCheckups = [];
      this.getInstantCheckupReport();
    });
  }

  onSelectInstantCheckup(element: any, id: string): void {
    if (this.comparePhoto[0]) {
      const instantCheckupItem1 = this.instantCheckups.find((value: any) => value.objectId === this.comparePhoto[0]);
      const instantCheckupItem2 = this.instantCheckups.find((value: any) => value.objectId === id);
      if (this.helperService.getInstantCheckupClassByType(instantCheckupItem1.type)
        !== this.helperService.getInstantCheckupClassByType(instantCheckupItem2.type)) {
        event.preventDefault();
        this.broadcaster.broadcast('NOTIFY', { message: 'Choose Same Type of Image', type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
    }
    const indexOfId: number = this.comparePhoto.indexOf(id);
    if (indexOfId >= 0) this.comparePhoto.splice(indexOfId, 1);
    if (this.comparePhoto.length === 2) {
      event.preventDefault();
      alert('Please select any two');
    } else if (indexOfId === -1) this.comparePhoto.push(id);
  }

  clearComparePhoto(): void {
    this.comparePhoto = [];
  }

  deleteUserInstantCheckup(index: number): void {
    const deleteObj = new Table.InstantCheckup();
    deleteObj.id = this.instantCheckups[index].objectId;
    deleteObj.destroy()
      .then(() => this.instantCheckups.splice(index, 1))
      .catch((err: any) => {
        this.broadcaster.broadcast('NOTIFY', { message: err.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      });
  }

  async getInstantCheckupReport(): Promise<void> {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const instantCheckups = await this.conn.fetchUserInstantCheckup({
      userId: this.chatUser.get('username'),
      project: ['imagePath', 'star', 'thumbnailImagePath', 'type', 'tags', 'aiResponse'],
      limit: 10,
      skip: this.instantCheckups.length,
    });
    this.instantCheckups.push(...instantCheckups);
    if (!this.instantCheckups.length) return;
    const previousCheckUpDate = moment(this.instantCheckups[this.instantCheckups.length - 1].createdAt)
      .startOf('day');
    this.instantCheckups.forEach((instantCheckup_: any) => {
      const instantCheckup = instantCheckup_;
      instantCheckup.source = 'unknown';
      if (instantCheckup.imagePath) {
        if (instantCheckup.imagePath.includes('back')) {
          instantCheckup.source = 'BACK_CAM';
        }
        if (instantCheckup.imagePath.includes('front')) {
          instantCheckup.source = 'FRONT_CAM';
        }
        if (instantCheckup.imagePath.includes('whatsapp')) {
          instantCheckup.source = 'WhatsApp';
        }
      }
      instantCheckup.timeDifference = moment(instantCheckup.createdAt).diff(previousCheckUpDate, 'days');
    });
    this.loading = false;
  }

  async saveInstantCheckupType(selectedInstantCheckup_: any): Promise<any> {
    const selectedInstantCheckup = selectedInstantCheckup_;
    const instantCheckup = await this.conn.getInstantCheckupById(selectedInstantCheckup.objectId);
    instantCheckup.set('type', selectedInstantCheckup.editedType);
    await instantCheckup.save();
    instantCheckup.allowEditingType = false;
    selectedInstantCheckup.type = selectedInstantCheckup.editedType;
  }

  async openComparison(): Promise<void> {
    if (this.onComparisonImageSelection.observers.length) {
      this.onComparisonImageSelection.emit([this.comparePhoto[0], this.comparePhoto[1]]);
      return;
    }
    this.windowRef.nativeWindow.open(`/compare/${this.comparePhoto[0]}/${this.comparePhoto[1]}/${this.chatUser.get('username')}`, '_blank');
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
