import { Component, NgZone, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { ApiClientConstant, Table, RequestQueryPayload, ApiConnector, ParseKeys } from 'api-client';
import { FileUploader } from 'ng2-file-upload';
import { FilterType } from '../../../../typings/client/filter';
import { Broadcaster } from '../../../components/broadcaster';
import { ChangeAllocatedDoctor } from '../../../components/change-allocated-doctor';
import { FilterConstants } from '../../../components/filter-dropdown/filter-constants';
import { ConnectionService } from '../../../services/connection-service';
import { LocalStorage } from '../../../services/local-storage-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';

@Component({ selector: 'doctor-approval', templateUrl: './doctor-approval.html', styleUrls: ['./doctor-approval.scss'] })
export class DoctorApprovalComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  autoRefresh: boolean = true;
  autoRefreshInterval: any;
  partialOrder: boolean = false;
  resetLanguageFilter: boolean = false;
  userService: Array<string> = [];
  languageFilter: Array<string> = [];
  orders: Array<any>;
  window: any;
  filters: any = [{}];
  dropdownFilter: any[] = [];
  components: any;
  Orders: any;
  ui: any;
  url: any;
  columnDefs: any;
  filterConfig: Array<FilterType.FilterConfigType>;
  uploader: FileUploader;
  OrderAsc: boolean = false;
  a: any;
  count: number;
  isDoctor: boolean;
  resetInternalTeamFilter: boolean;
  internalTeamFilter: Array<any> = [];
  currentUser: any;
  currentDoctorCases: Array<any> = [];
  dietServices:Array<any>;
  doctorFilter: Array<any> = [];
  searchKey: any;
  resetDoctorFilter: boolean = false;
  startDate: Date;
  endDate: Date;
  stage: string;
  changingApprovalDoctorList: Array<string> = [];

  constructor(private router: Router,
              private conn: ConnectionService,
              private zone: NgZone,
              public appConfig: AppConfig,
              public windowRef: WindowRefService,
              private dialog: MatDialog,
              private storage: LocalStorage,
              private broadcaster: Broadcaster,
              private route: ActivatedRoute,
  ) {
    this.window = windowRef.nativeWindow;
  }

  async ngOnInit(): Promise<void> {
    this.userService = this.storage.getValue('userRoles');
    this.isDoctor = this.conn.getCurrentUser().get('type') === 'doctor';
    this.currentUser = this.conn.getCurrentUser();
    this.OrderAsc = false;
    this.ui = { isFilterOpen: false, grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.filterConfig = [
      { key: 'stage', type: FilterConstants.DATA_TYPE.FIXED, value: Object.keys(ApiClientConstant.Order.Stage) },
      { key: 'paymentType', type: FilterConstants.DATA_TYPE.FIXED, value: Object.keys(ApiClientConstant.Order.PaymentType) },
      { key: 'type', type: FilterConstants.DATA_TYPE.FIXED, value: Object.keys(ApiClientConstant.Order.Type) },
      { key: 'alternateNumber', type: FilterConstants.DATA_TYPE.STRING },
      { key: 'isPartialOrder', type: FilterConstants.DATA_TYPE.BOOLEAN }];

    this.route.queryParams.subscribe((params: Params): void => {
      this.stage = params.stage;
    });
    this.startDate = moment().subtract(2, 'months').startOf('day').toDate();
    this.endDate = moment().endOf('day').toDate();
    this.setUpGrid();
    this.resetFilters();
    this.toggleAutoRefresh();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Order No.',
      field: 'orderNumber',
      pinned: 'left',
      cellRenderer: (params_: any): any => {
        const params = params_;
        if (!params.data) {
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        if (!params.data.doctorConfirmed) {
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', async () => {
            if (!this.isDoctor) {
              this.windowRef.nativeWindow.open(`/order/${params.data.objectId}`, '_blank');
              return;
            }
            const requestPayload1: RequestQueryPayload<Table.Order> = {
              where: { objectId: params.data.objectId },
              project: ['doctorConfirmed'],
            };
            const requestPayload2: RequestQueryPayload<Table.Order> = {
              where: {
                stage: ApiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING,
                doctorConfirmed: true,
                allocatedDoctor: this.currentUser,
                type: ApiClientConstant.Order.Type.REGIMEN,
              },
              include: ['regimen'],
              project: ['regimen.optedForDoctorCall' as 'regimen'],
            };
            let order: any = '';
            let capturedOrder: any = '';
            [order, capturedOrder] = await Promise.all([
              ApiConnector.findOne(Table.Order, requestPayload1),
              ApiConnector.find(Table.Order, requestPayload2),
            ]);
            let capturedOrderOfOptedForDoctorCall = 0;
            capturedOrder?.forEach((each: any): void => {
              if (!each.get('regimen')?.get('optedForDoctorCall')) {
                capturedOrderOfOptedForDoctorCall += 1;
              }
            });
            if (capturedOrderOfOptedForDoctorCall > 5) {
              this.broadcaster.broadcast('NOTIFY', { message: 'You have captured more than 5 cases',
                type: this.appConfig.Shared.Toast.Type.ERROR });
              return;
            }
            if (order.get('doctorConfirmed')) {
              this.broadcaster.broadcast('NOTIFY', { message: 'Order already Taken By Other Doctor',
                type: this.appConfig.Shared.Toast.Type.ERROR });
              this.gridApi.redrawRows({ rowNodes: [params.node] });
              return;
            }
            try {
              order.set('doctorConfirmed', true);
              order.set('capturedAt', moment().toDate());
              order.set('allocatedDoctor', this.conn.getCurrentUser());
              await order.save();
              params.data.doctorConfirmed = true;
              this.gridApi.redrawRows({ rowNodes: [params.node] });
              this.windowRef.nativeWindow.open(`/order/${params.data.objectId}/approval`, '_blank');
            } catch (error) {
              this.broadcaster.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
            }
          });
          eDiv.innerHTML = '<a><button class="btn-xs">CAPTURE</button></a>';
          return eDiv;
        }
        const eDiv = this.window.document.createElement('div');
        const id = params.value || params.data.objectId;
        let highlightCssClass;
        let title = '';
        if (params.data.regimenId?.startsWith('v4_999') || params.data.regimen?.optedForDoctorCall) {
          highlightCssClass = 'list-label-bg-red';
          title = 'Doctor call needed';
        }
        if (params.data.regimen?.optedForDoctorCall === false) {
          highlightCssClass = 'list-label-bg-yellow';
          title = 'Voice note needed';
        }
        const link = this.isDoctor && params.data.stage === ApiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING
          ? `/order/${id}/approval`
          : `/order/${id}`;
        eDiv.innerHTML = `<div class='list-label ${highlightCssClass}' title='${title}'><a class="p-0 ellipsis mLR5" target="_blank"
          href='${link}'>${id}</a></div>`;
        return eDiv;
      },
      width: 110,
      filter: true,
    }, {
      headerName: 'Language Preference',
      field: 'languagePreference',
      width: 100,
    }, {
      headerName: 'Consultation Type',
      field: 'services',
      width: 150,
      hide: this.stage !== this.appConfig.Shared.Order.Stage.CONSULTATION_CREATED,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<div >${params.value.map((service: any) => service.type)}</div>`;
        return eDiv;
      },
    }, {
      headerName: 'Waiting Time',
      field: 'cameForApprovalAt',
      width: 120,
      sortable: true,
      hide: !(this.userService.includes('admin') || this.userService.includes('adminDoctor')),
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        const differenceInTime = this.getDifferenceInTime(params.value.iso);
        eDiv.innerHTML = `<div >${differenceInTime}</div>`;
        return eDiv;
      },
    }, {
      headerName: 'Waiting Time After Capture',
      field: 'capturedAt',
      width: 120,
      sortable: true,
      hide: !(this.userService.includes('admin') || this.userService.includes('adminDoctor')),
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        const differenceInTime = this.getDifferenceInTime(params.value.iso);
        eDiv.innerHTML = `<div >${differenceInTime}</div>`;
        return eDiv;
      },
    }, {
      headerName: 'Patient Name',
      field: 'user.PatientName',
      cellRenderer: (params: any): any => {
        if (!this.shouldShowPatientName(params)) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<a class='col-xs-12 p-0 ellipsis' target="_blank" href='/chat/${params.data.user?.objectId}'>${
          params.value || ''} </a>`;
        return eDiv;
      },
      width: 150,
    }, {
      headerName: 'Created On',
      field: 'actualCreatedAt',
      hide: !(this.userService.includes('admin') || this.userService.includes('adminDoctor')),
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        const date = moment(params.value.iso).toDate();
        eDiv.innerHTML = moment(date).format('MMM DD,hh:mm a');
        return eDiv;
      },
      width: 120,
      sortable: true,
    }, {
      headerName: 'Allocated Doctor',
      field: 'allocatedDoctor',
      cellRenderer: (params: any): any => {
        if (!params.data || (!params.data.doctorConfirmed)) return '';
        const eDiv = this.window.document.createElement('div');
        if (params.data.allocatedDoctor) {
          eDiv.innerHTML = '<input style="min-height: unset !important;" type="checkbox"'
            + `[checked]="${this.changingApprovalDoctorList.includes(params.data.allocatedDoctor.objectId)}">`;
          eDiv.addEventListener('click', async () => {
            const index = this.changingApprovalDoctorList.indexOf(params.data.objectId);
            if (index === -1) {
              this.changingApprovalDoctorList.push(params.data.objectId);
            } else {
              this.changingApprovalDoctorList.splice(index, 1);
            }
          });
          eDiv.className = 'mr-2';
          eDiv.innerHTML += '&nbsp;&nbsp&nbsp';
          eDiv.innerHTML += params.value.DoctorDisplayName;
        }
        return eDiv;
      },
      width: 200,
    }, {
      headerName: 'Order Type',
      field: 'type',
      width: 130,
      cellRenderer: (params: any): any => {
        if (!params.data || (!params.data?.doctorConfirmed)) {
          return '';
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<div class="ellipsis pl-2">${params.data.type || ''}</div>`;
        return eDiv;
      },
      filter: true,
    }, {
      headerName: 'City',
      field: 'contactDetail',
      width: 100,
      cellRenderer: (params: any): any => {
        if (!params.data || (!params.data.doctorConfirmed)) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = params.value.city;
        return eDiv;
      },
      filter: true,
    }, {
      headerName: 'Recent Note',
      field: 'notes',
      cellRenderer: (params: any): any => {
        if (!params.data || (!params.data.doctorConfirmed)) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.className = 'ellipsis';
        const notes = params.value || [];
        const recentNote = (notes[notes.length - 1] || { message: '-' }).message;
        eDiv.innerHTML = recentNote;
        return eDiv;
      },
      width: 200,
    }, {
      headerName: 'Phone Number',
      field: 'contactNumber',
      width: 150,
      filter: true,
      editable: true,
      cellRenderer: (params: any): any => {
        if (!params.data || !params.data.doctorConfirmed) {
          return '';
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<div>${params.value}</div>`;
        return eDiv;
      },
    }, {
      headerName: 'Stage',
      field: 'stage',
      filter: true,
      filterOptions: {
        values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      },
      cellRenderer: (params: any): any => {
        if (!params.data || (!params.data.doctorConfirmed)) return '';
        let markRed = false;
        markRed = (ApiClientConstant.Order.CompletedStages.includes(params.value)
            && (moment().diff(params.data.updatedAt, 'days') >= 2))
          || ([ApiClientConstant.Order.Stage.SHIPPED].includes(params.value)
            && (moment().diff(params.data.updatedAt, 'days') > 5));
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<div class="${markRed ? 'bg-danger' : ''} ellipsis">${params.value || ''}</div>`;
        return eDiv;
      },
      width: 200,
    }, {
      headerName: 'Payment Type',
      field: 'paymentType',
      width: 130,
      filter: true,
      cellRenderer: (params: any): any => {
        if (!params.data || (!params.data.doctorConfirmed)) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<div>${params.value}</div>`;
        return eDiv;
      },
    }, {
      headerName: 'Last Updated On',
      field: 'updatedAt',
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.data || (!params.data.doctorConfirmed)) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('MMM DD,hh:mm a');
        return eDiv;
      },
      width: 120,
    }];
  }

  resetFilters(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.filters = Object.keys(params)
        .filter((key: string) => ['stage', 'paymentType', 'type'].includes(key))
        .map((key: string) => ({ key, filterType: FilterConstants.FILTER_TYPE.EQUAL_TO, value: params[key].split(',') }));
      this.resetInternalTeamFilter = true;
      this.reset();
    });
  }

  async findDietServicesLocally(): Promise<Array<any>> {
    if (!this.dietServices) {
      this.dietServices = await this.conn.getServices({
        type: ApiClientConstant.Service.Type.DIET_CONSULTATION,
        ascending: 'createdAt',
      });
    }
    return this.dietServices;
  }

  openAllocatedDoctorDialog(): void {
    const dialogRef = this.dialog.open(ChangeAllocatedDoctor, {
      data: { approvalList: this.changingApprovalDoctorList, type: this.appConfig.Shared.AllocatedDoctorPopup.Type.ORDER },
    });
    dialogRef.afterClosed().subscribe(async (data: any): Promise<any> => {
      if (!data) return;
      this.clearAllSelectedApprovals();
    });
  }

  clearAllSelectedApprovals(): void {
    this.changingApprovalDoctorList = [];
    this.reset();
  }

  async loadMore(params: any): Promise<any> {
    if (this.stage && this.stage === this.appConfig.Shared.Order.Stage.CONSULTATION_CREATED) {
      return this.loadDataForConsultations(params);
    }
    return this.loadDataForOrders(params);
  }

  applyFilter(data: any): void {
    this.filters = data;
    this.reset();
  }

  reset(doctorFilter?: Array<any>): void {
    if (!this.gridApi) return;
    if (doctorFilter) {
      this.doctorFilter = doctorFilter;
      this.resetDoctorFilter = false;
    }
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  updatedInternalTeamList(internalTeamFilter: Array<any>): void {
    this.internalTeamFilter = internalTeamFilter;
    this.resetInternalTeamFilter = false;
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 10000);
    } else if (this.autoRefreshInterval) clearInterval(this.autoRefreshInterval);
  }

  togglePartialOrder(): void {
    this.reset();
  }

  getDifferenceInTime(value: Date): any {
    const createdAt: Date = moment(moment(value).toISOString()).toDate();
    const morning8Am: Date = moment(value)
      .startOf('day')
      .add(8, 'hours')
      .toDate();
    const night8Pm: Date = moment(value)
      .subtract(1, 'day')
      .startOf('day')
      .add(20, 'hours')
      .toDate();
    const effectiveOrderTime = ((night8Pm.getTime() < createdAt.getTime()) && (createdAt.getTime() < morning8Am.getTime()))
      ? morning8Am
      : createdAt;
    const differenceInSeconds: number = moment().diff(effectiveOrderTime, 'second');
    let differenceTime: any;
    if (differenceInSeconds > 86400) {
      differenceTime = `${Math.floor(differenceInSeconds / 86400)} days`;
    } else if (differenceInSeconds < 3600) {
      differenceTime = `${Math.floor(differenceInSeconds / 60)} mins`;
    } else {
      differenceTime = `${Math.floor(differenceInSeconds / 3600)} hr`;
    }
    return differenceTime;
  }

  updateLanguageFilter(languageFilter: Array<any>): void {
    this.resetLanguageFilter = false;
    this.languageFilter = languageFilter;
    this.reset();
  }

  shouldShowPatientName(params: Record<string, any>): boolean {
    if (!params.data
        || (!params.data.doctorConfirmed && params.data.type === this.appConfig.Shared.Order.Type.REGIMEN)) return false;
    return true;
  }
  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.orders;
    delete this.filters;
    delete this.ui;
  }

  private async loadDataForConsultations(params: Record<string, any>): Promise<Array<Table.Order>> {
    const searchOn = ['contactName', 'contactDetail.city', 'contactNumber'];
    const requestPayload: RequestQueryPayload<Table.Order> = {
      where: {
        createdAt: { $gt: this.startDate, $lt: this.endDate },
        stage: this.stage,
      },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['user', 'allocatedDoctor', 'regimen', 'services'],
      project: [
        'actualCreatedAt',
        'contactNumber',
        'orderNumber',
        'contactDetail.city' as 'contactDetail',
        'stage',
        'paymentType',
        'notes',
        'deliveryAddress',
        'contactName',
        'user.PatientName' as 'user',
        'user.mod11' as 'user',
        'user.languagePreference' as 'user',
        'user.PatientName' as 'user',
        'type',
        'allocatedDoctor.DoctorDisplayName' as 'allocatedDoctor',
        'regimenId',
        'isPartialOrder',
        'regimen',
        'doctorConfirmed',
        'languagePreference',
        'services',
      ],
    };
    if (!this.userService.includes('adminDoctor')) {
      requestPayload.where.$or = [
        { allocatedDoctor: this.currentUser, doctorConfirmed: true },
        { doctorConfirmed: { $ne: true } },
      ];
    }
    let alternateNumber;
    const { colId, sort }: { colId: ParseKeys<Table.Order>, sort: 'asc' | 'desc' } = {
      colId: 'createdAt',
      sort: 'desc',
    } || params.sortModel[0];
    if (sort === 'desc') {
      requestPayload.descending = colId;
    } else {
      requestPayload.ascending = colId;
    }
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    this.filters.forEach((item: any) => {
      if (item.key !== 'alternateNumber') {
        requestPayload.where[item.key] = item.key === 'isPartialOrder' ? item.booleanValue : item.value;
      } else alternateNumber = item.value;
    });
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((key: any) => {
        if (key === 'contactDetail') {
          requestPayload.where[`${key}.city`] = params.filterModel[key].filter;
        } else {
          requestPayload.where[key] = params.filterModel[key].filter;
        }
      });
    }
    if (this.doctorFilter && this.doctorFilter.length) {
      requestPayload.where.allocatedDoctor = this.doctorFilter;
    }
    if (this.partialOrder) {
      requestPayload.where.isPartialOrder = true;
    }
    if (alternateNumber) {
      const user = await this.conn.getUserByAlternateNumber(alternateNumber);
      if (user) requestPayload.where.contactNumber = user.get('MobileNumber');
    }
    if (!requestPayload.where.stage) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'stage missing',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return [];
    }
    const data = await this.conn.findOrders(requestPayload).then((result: Array<any>) => result.map((each: any) => each.toJSON()));
    this.count = 0;
    this.conn.countOrders(requestPayload).then((count: number) => this.count = count);
    return data;
  }

  private async loadDataForOrders(params: Record<string, any>): Promise<Array<Table.Order>> {
    const services = await this.findDietServicesLocally();
    const requestPayload: RequestQueryPayload<Table.Order> = {
      where: {
        stage: this.appConfig.Shared.Order.Stage.DOCTOR_APPROVAL_PENDING,
        services: { $nin: services },
      },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      ascending: 'actualCreatedAt',
      include: ['user', 'allocatedDoctor', 'regimen'],
      project: [
        'actualCreatedAt',
        'contactNumber',
        'orderNumber',
        'contactDetail.city' as 'contactDetail',
        'stage',
        'paymentType',
        'notes',
        'deliveryAddress',
        'contactName',
        'user.PatientName' as 'user',
        'user.mod11' as 'user',
        'user.languagePreference' as 'user',
        'type',
        'allocatedDoctor.DoctorDisplayName' as 'allocatedDoctor',
        'regimenId',
        'isPartialOrder',
        'regimen',
        'doctorConfirmed',
        'languagePreference',
        'cameForApprovalAt',
        'capturedAt',
      ],
    };
    if (!this.userService.includes('adminDoctor')) {
      if (this.internalTeamFilter.length) {
        requestPayload.where.allocatedDoctor = this.internalTeamFilter;
        requestPayload.where.doctorConfirmed = false;
      } else {
        requestPayload.where.doctorConfirmed = { $ne: true };
      }
    } else if (this.internalTeamFilter.length) {
      requestPayload.where.$or = [
        { $and: [{ allocatedDoctor: this.internalTeamFilter }] }, { doctorConfirmed: { $ne: true } },
      ];
    }
    if (this.languageFilter.length) {
      requestPayload.where.languagePreference = { $in: this.languageFilter };
    }
    let alternateNumber;
    this.filters?.forEach((item: any) => {
      if (item.key !== 'alternateNumber') {
        requestPayload.where[item.key] = item.key === 'isPartialOrder' ? item.booleanValue : item.value;
      } else alternateNumber = item.value;
    });
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((key: any) => {
        if (key === 'contactDetail') {
          requestPayload.where[`${key}.city`] = params.filterModel[key].filter;
        } else {
          requestPayload.where[key] = params.filterModel[key].filter;
        }
      });
    }
    if (this.partialOrder) {
      requestPayload.where.isPartialOrder = true;
    }
    let data;
    if (alternateNumber) {
      const user = await this.conn.getUserByAlternateNumber(alternateNumber);
      if (user) requestPayload.where.contactNumber = user.get('MobileNumber');
    }
    [data, this.count] = await Promise.all([
      this.conn.findOrders(requestPayload).then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      this.conn.countOrders(requestPayload),
    ]);
    if (params.startRow === 0 && this.currentUser.get('type') === ApiClientConstant.User.Type.DOCTOR) {
      delete requestPayload.where.$or;
      requestPayload.where.doctorConfirmed = true;
      requestPayload.where.allocatedDoctor = this.currentUser;
      requestPayload.where.type = [
        ApiClientConstant.Order.Type.REGIMEN,
        ApiClientConstant.Order.Type.PRODUCT,
        ApiClientConstant.Order.Type.REPLACEMENT_PRODUCT,
        ApiClientConstant.Order.Type.CONSULTATION,
      ];
      this.currentDoctorCases = await this.conn.findOrders(requestPayload)
        .then((result: Array<any>) => result.map((each: any) => each.toJSON()));
    }
    if (this.currentDoctorCases && params.startRow === 0) {
      this.count += this.currentDoctorCases.length;
      const updatedData = [...this.currentDoctorCases, ...data];
      return updatedData;
    }
    return data;
  }
}
