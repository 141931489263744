import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({ selector: 'addstagePopup', templateUrl: './addStagePopup.html' })

export class AddStagePopupModal {
  dropdown: any;
  keysOfDropDown: any;
  subscriptions: Array<any> = [];
  constructor(private dialogRef: MatDialogRef<AddStagePopupModal>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.dropdown = this.data.dropdown;
    this.keysOfDropDown = this.data.keysOfDropDown;
  }

  public hideModal(): void {
    this.dialogRef.close();
  }

  addNewStage():void {
    this.dialogRef.close(this.dropdown);
  }
}
