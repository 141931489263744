<form class="form col-xs-12 p-0" #personForm="ngForm">
  <div class="col-xs-12 title posR">
    <div>
      <em class="fas fa-comment pr-3"></em>
      <span>Send Message</span>
      <button *ngIf="callNeeded" class="pull-right btn-xxxs btn-danger ml-3">Call Needed</button>
      <div class="ml-3">
        <div class="label label-primary mr-2 fontS3 p-1" *ngFor="let label of attendToChatCache?.get('label');">{{label}}</div>
      </div>
    </div>
    <div class="mobile call-icon pull-right"><span class="text-capitalize fontS clr-red mr-3" *ngIf="patient.get('marketingTags')?.includes('STEROID')">STEROID</span><i class="fas fa-upload mr-3" (click)="openImageUploadDialog()" title="Upload &amp; Send file"></i><em class="fas fa-phone-alt" (click)="connectionService.callThroughExotel(patient.get('MobileNumber'), 'chatPage')" title="Mobile"></em><em class="fas fa-phone-alt alt-2" *ngIf="patient.get('alternateNumber')" (click)="connectionService.callThroughExotel(patient.get('alternateNumber'), 'chatPage')" title="Alternate Mobile"></em></div>
  </div>
  <div class="mb-3">
    <div class="col-sm-12 col-md-5 mr-2">
      <select class="form-control" id="modeDropdown" [(ngModel)]="data.mode" required="required" name="mode" (change)="onMessageModeChange()">
        <option *ngFor="let mode of modes" value="{{mode.key}}">{{mode.value}}</option>
      </select>
    </div>
    <div class="col-sm-12 col-md-2 mr-2" *ngIf="data.mode === appConfig.Shared.Assistant.Mode.GENERIC_USER_INPUT">
      <select class="form-control" name="storedOrder" (change)="changeSelectedOrder($event.target.value)">
        <option value="{{-1}}">select</option>
        <option *ngFor="let order of pendingOrders; let i=index;" value="{{i}}">{{order.get('orderNumber')}}</option>
      </select>
    </div>
    <div class="col-sm-2 col-md-1 mr-2">
      <button id="sendMessage" (click)="insertMessage()" *ngIf="(personForm.valid || data.mode === appConfig.Shared.Assistant.Mode.ARTICLE || data.mode!==appConfig.Shared.Assistant.Mode.GENERIC_USER_INPUT)" style="font-weight:bold" [disabled]="!personForm.valid || (!data.message && (data.mode === appConfig.Shared.Assistant.Mode.WITH_TEXT_REPLY))">Send</button>
    </div>
  </div>
  <div class="col-sm-12 col-md-3 mr-2" *ngIf="[appConfig.Shared.Assistant.Mode.WITH_TEXT_REPLY].includes(data.mode)">
    <canned-response-selection [object]="data" name="message" [user]="patient"></canned-response-selection>
  </div>
  <div class="col-xs-12 p-0" *ngIf="data.mode === appConfig.Shared.Assistant.Mode.AUDIO">
    <audio-recorder (afterSave)="afterSaveAudioFile($event)" (onChange)="onAudioChange($event)" [disableVoiceToText]="true"></audio-recorder>
  </div>
  <div class="col-sm-12 p-0 mb-3" *ngIf="data.mode === 'CUSTOM_DIET'">
    <textarea class="form-control" [(ngModel)]="data.dietLink" name="dietLink" required="required" placeholder="Type Diet link"></textarea>
  </div>
  <div class="form-group mb-3" *ngIf="data.mode !== 'REGIMEN' && data.mode !== appConfig.Shared.Assistant.Mode.ARTICLE && data.mode!==appConfig.Shared.Assistant.Mode.GENERIC_USER_INPUT && data.mode !== 'ConsentForm'">
    <textarea class="form-control message-input" [ngClass]="{ redBorder: data.mode === appConfig.Shared.Assistant.Mode.AUDIO }" style="min-height: 150px;" [(ngModel)]="data.message" name="message" [required]="data.mode !== appConfig.Shared.Assistant.Mode.AUDIO" [placeholder]='placeHolder'></textarea>
  </div>
  <div class="form-group mb-3" *ngIf="data.mode === appConfig.Shared.Assistant.Mode.PAYMENT">
    <select class="form-control" [(ngModel)]="data.orderId" required="required" name="orderId">
      <option *ngFor="let order of pendingOrders" [ngValue]="order.id">{{order.get('orderNumber')}}  ({{order.get('type')}})</option>
    </select>
  </div>
  <div class="form-group mb-3" *ngIf="data.mode === 'PAYMENT'">
    <textarea class="form-control" [(ngModel)]="data.notes" name="notes" required="required" placeholder="Payment Notes (not visible to user)"></textarea>
  </div>
  <div class="form-group mb-3" *ngIf="['RICH_TEXT'].includes(data.mode)">
    <textarea class="form-control" [(ngModel)]="data.metadata" name="metadata" required="required" placeholder="Type Content..."></textarea>
  </div>
  <div class="form-group mb-3" *ngIf="data.mode === 'RICH_TEXT'">
    <textarea class="form-control" [(ngModel)]="data.thumbnail" name="thumbnail" required="required" placeholder="Type Image Thumbnail URL..."></textarea>
  </div>
  <div class="form-group mb-3" *ngIf="data.mode === 'RICH_TEXT'">
    <textarea class="form-control" [(ngModel)]="data.contentUrl" name="contentUrl" required="required" placeholder="Type Landing Url..."></textarea>
  </div>
  <div class="form-group mb-3" *ngIf="data.mode === 'REGIMEN'">
    <select class="form-control" id="regimenDropdown" [(ngModel)]="data.regimenObj" name="regimenObj" required="required">
      <option *ngFor="let regimen of activeMainRegimens" [ngValue]="regimen">{{regimen.get('regimenId')}} {{regimen.get('title')}}</option>
    </select><a class="col-xs-12 text-info bold mt-2" *ngIf="data.regimenObj" href="{{baseUrlForWebApp}}/user?tab=regimen&amp;username={{patient.get('username')}}&amp;regimenId={{data.regimenObj.get('regimenId')}}" target="_blank">Click To View Regimen</a>
  </div>
  <div class="col-sm-12 col-md-2 mr-2" *ngIf="internalUserRoles.includes('sales')">
    <select class="form-control" #selectedCleverTapEvent>
      <option value="">Trigger CleverTap Event</option>
      <option *ngFor="let ctEvent of clevertapSupportedEvents;" value="{{ctEvent}}">{{ctEvent}}</option>
    </select>
    <button (click)="sendCleverTapEvent(selectedCleverTapEvent.value)">Trigger</button>
  </div>
  <div class="col-xs-12 mt-2">
    <div class="pull-right flex">
      <label class="flex fontS2 mr-3 my-0" *ngIf="internalUserRoles.includes('doctor')">
        <input class="checkbox-inline" type="checkbox" (change)="isConfirmationNeeded()" checked="checked"/><span class="pl-2">Notify</span>
      </label>
      <button class="btn-xs fontS3 btn-danger mr-3" (click)="deleteLastOperatorMessage()">Delete my last message</button>
      <button class="btn-xs fontS3" *ngIf="userActiveRegimensAvailableCount" (click)="sendFeedbackForm()">Send Feedback Form</button>
    </div>
  </div>
</form>
