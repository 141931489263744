
<mat-dialog-content class="mat-dialog-content" style="width:50vw">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Regimen edit reason</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form #form="ngForm">
      <div class="col-xs-10">
        <label class="col-xs-12 mt-0">Edit Reason</label>
        <input-text [parseObj]="updatedText" name="editReason" minLength="10" placeholder="Enter the Edit reason" [required]="true"></input-text>
        <p class="clr-red">min 10 chars required</p>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="submit" (click)="addEditReason()" [disabled]="!form.valid">Update</button>
  </div>
</mat-dialog-content>