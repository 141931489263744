const followupChatFieldsToSkip: Array<string> = [
  'ACL',
  'objectId',
  'createdAt',
  'updatedAt',
  'UpdatedBy',
  'doctorConfirmed',
  'allocatedTeam',
  'followUpNeeded',
  'newInstructionCallNeeded',
  'effectiveFollowUpDate',
  'ready',
  'followUpCount',
  'isPreviousFollowUpSkipped',
  'products',
  'FollowUpChatOrCall',
  'isAnytimeFollowUpEnabled',
  'chatEnableNotification',
  'message',
  'latestResponseTimeFromInternalUser',
  'evaluateIsUserAllowedToTakeFollowUp',
  'waitingForImageMessage',
  'completedOn',
  'allocatedOperator',
  'user',
  'nextFollowUpDate',
  'ConsultationSession',
  'regimenCount',
  'pendingFromDate',
  'context',
  'skipFollowUpProducts',
  'messageInputs',
  'sendWaitingForImageMessage',
  'newFollowUpReason',
  'SkippedRegimenV2SecFollowUp',
  'doctorCount',
  'State',
  'capturedAt',
  'ProgressCheckHairPhotoOne',
  'regimens',
  'languagePreference',
  'label',
  'Action',
  'deadline',
  'attended',
  'FollowUpInfo',
  'newFollowUpDate',
  'cacheChat',
  'ProgressCheckFaceFinalOk',
  'type',
  'previousState',
  'newFollowUpMessage',
  'orderCreationInfo',
  'firstFollowup',
  'labels',
  'InstructionsUnderstandingV2Followup',
  'treatmentSinceWeek',
  'updatedBy',
  'disableChat',
  'followUpInfo',
  'FollowUpinfo',
  'followUpReason',
  'followUpTakeInstantCheckupFrontFace',
  'followUpTakeInstantCheckupHairFront',
  'followUpTakeInstantCheckupHairTop',
  'instantCheckupWidth',
  'followUpTakeInstantCheckupSideFace',
  'followUpReportStatus',
  'FollowupInfo',
  'allocatedDoctor',
];

export { followupChatFieldsToSkip };
