import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { UserChatViewSummaryComponent } from './user-chat-view-summary/user-chat-view-summary.component';
import { UserChatViewHumanInteractionComponent } from './user-chat-view-human-interaction/user-chat-view-human-interaction.component';
import { DirectivesModule } from '../directives.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MomentModule,
    DirectivesModule,
  ],
  declarations: [
    UserChatViewSummaryComponent,
    UserChatViewHumanInteractionComponent,
  ],
  exports: [
    UserChatViewSummaryComponent,
    UserChatViewHumanInteractionComponent,
  ],
})
export class UserChatViewModule {
}
