import { Component } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { ApiClientConstant, Table, RequestQueryPayload, ApiConnector } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { LocalStorage } from '../../services/local-storage-service';
import { AppConfig } from '../app.config';
import { WindowRefService } from '../../services/window-ref-service';
import { Broadcaster } from '../../components/broadcaster';

@Component({
  selector: 'experiment',
  templateUrl: './experiment.html',
})

export class ExperimentComponent {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;

  // Local Variables
  data: Array<string> = [];
  teams: Array<string>;
  currentUser: any;
  autoRefresh: boolean = false;
  incomingSwitch: boolean = false;
  outgoingSwitch: boolean = false;
  autoRefreshInterval: any;
  queueCount: number = 0;
  searchKey: string;

  constructor(
    private windowRefService: WindowRefService,
    private connectionService: ConnectionService,
    public appConfig: AppConfig,
    private storage: LocalStorage) { }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    const roles = this.storage.getJsonValue('userRoles') || [];
    const teams = Object.keys(ApiClientConstant.Role.Name)
      .map((each: string) => this.appConfig.Shared.Role.Name[each]);
    this.teams = roles.filter((each: any) => teams.includes(each));
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 50,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pointer' href='/experiment/${params.data.objectId}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'name',
      field: 'name',
      width: 250,
    }, {
      headerName: 'minHashCode',
      field: 'minHashCode',
      width: 70,
    }, {
      headerName: 'maxHashCode',
      field: 'maxHashCode',
      width: 70,
    }, {
      headerName: 'enable',
      field: 'enable',
      width: 70,
    }, {
      headerName: 'EndDate',
      field: 'endDate',
      width: 120,
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value.iso).format('MMM DD,hh:mm a');
        return eDiv;
      },
    }, {
      headerName: 'variant',
      field: 'variant',
      width: 500,
    }];
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadData(params: any): Promise<Array<{ [key: string]: unknown }>> {
    const searchOn = ['name', 'key'];
    const requestPayload: RequestQueryPayload<Table.Experiment> = {
      where: { },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      project: ['name', 'variant', 'enable', 'minHashCode', 'maxHashCode', 'endDate'],
      descending: 'createdAt',
    };
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    let data: Array<any>;
    [data, this.queueCount] = await Promise.all([
      ApiConnector.find(Table.Experiment, requestPayload)
        .then((items: Array<any>) => items.map((item: any) => item.toJSON())),
      ApiConnector.count(Table.Experiment, requestPayload),
    ]);
    if (!data.length) {
      return data;
    }
    return data;
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }
}
