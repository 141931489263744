/* eslint-disable angular/typecheck-array */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, filter, mergeMap, Observable } from 'rxjs';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { FilePickerComponent } from '../../../../components/file-picker/file-picker.component';
import { Broadcaster } from '../../../../components/broadcaster';

const CTAList = [{
  key: 'Regimen (FACE)',
  languageString: 'YypyV2UkrW',
  url: '/user?tab=regimen&class=FACE',
}, {
  key: 'Regimen (HAIR)',
  languageString: 'YypyV2UkrW',
  url: '/user?tab=regimen&class=HAIR',
}, {
  key: 'Regimen (BODY)',
  languageString: 'YypyV2UkrW',
  url: '/user?tab=regimen&class=BODY',
}, {
  key: 'Chat',
  languageString: 'QS6DbJGGru',
  url: '/user?tab=chat',
}, {
  key: 'Checkups',
  languageString: 'UNv8EzIvEE',
  url: '/user?tab=followups',
}, {
  key: 'Take photo',
  languageString: 'BXmAMSGi6w',
  url: '/user/instantCheckup',
}, {
  key: 'Refer and earn',
  languageString: 'nW4johFLLF',
  url: '/user/wallet?tab=earn',
}, {
  key: 'Play Game',
  languageString: 'vpIn6NXwCV',
  url: '/game',
}, {
  key: 'Buy Now',
  languageString: 'Ut492DhA14',
  url: '',
}];

const CLASSList = ['FACE', 'HAIR'];

const CATEGORYList = ['MALE',
  'FEMALE',
  'AGE_BETWEEN_20_TO_40',
  'AGE_BELOW_20',
  'AGE_ABOVE_40',
  'FACE',
  'HAIR',
  'SMOKER',
  'HORMONAL_IMBALANCE',
  'PREGNANT',
  'STEROID',
  'NEW_MOM',
  'BODY',
];

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent {
  feed: any;
  feedId: string;
  languages: string[];
  currentLanguage: string = ApiClientConstant.LanguageString.EN;
  currentLanguageCache: string = '';
  conditions: { Gender?: any, UserState?: any } = { Gender: -1, UserState: -1 };
  genderValues: any = {
    male: 'MALE',
    female: 'FEMALE',
    both: 'BOTH',
  };
  userStates: any = {
    unpaid: 'UNPAID',
    paid: 'PAID',
    both: 'BOTH',
  };
  ctaList: any[] = CTAList;
  classList: any[] = CLASSList;
  categoryList: any[] = CATEGORYList;
  classTag: String;
  categoryTag: String;
  isFileUploading: boolean;
  isLoading: boolean;
  languageDialogRef: MatDialogRef<any>;
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  product: any;

  form: UntypedFormGroup = this.fb.group({
    active: true,
    caption: '',
    cta: '',
    classTag: '',
    categoryTag: '',
    gender: -1,
    userState: -1,
    type: '',
    media: this.fb.array([]),
    userPaidState: 'BOTH',
    targetGender: 'BOTH',
  });

  get mediaArray(): UntypedFormArray {
    return this.form.get('media') as UntypedFormArray;
  }
  addMedia(data: any): void {
    this.mediaArray.push(this.fb.group(data));
  }
  constructor(public appConfig: AppConfig,
    private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private broadcasterService: Broadcaster,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));

    this.languages = Object.values(this.appConfig.Shared.Languages);
    this.feedId = this.route.parent.snapshot.params.id;
    if (!this.feedId) {
      this.feed = new Table.Feed();
      return;
    }
    this.feed = await ApiConnector.findOne(Table.Feed, { where: { objectId: this.feedId } });
    this.patchForm(JSON.parse(JSON.stringify(this.feed)));

    if ((<string> this.feed.get('ctaUrl')).startsWith('/product')) {
      const productId = (<string> this.feed.get('ctaUrl')).split('/').pop();
      [this.product] = await this.conn.getProducts({ where: { objectId: productId } });
    }
  }

  patchForm(feed: any): void {
    if (feed?.targetGender) {
      this.form.get('targetGender').setValue(feed.targetGender);
    }
    if (feed?.userPaidState) {
      this.form.get('userPaidState').setValue(feed.userPaidState);
    }
    if (feed.mediaLanguageJson && feed.mediaLanguageJson[this.currentLanguage]) {
      feed.mediaLanguageJson[this.currentLanguage].forEach((value: any): void => {
        this.mediaArray.push(this.fb.group(value));
      });
    } else {
      this.mediaArray.push(this.fb.group({
        url: feed.urlLanguageJson[this.currentLanguage],
        thumbnailUrl: feed.thumbnailUrlLanguageJson[this.currentLanguage],
        type: feed.type,
      }));
    }

    const cta = (<string>feed?.ctaUrl).startsWith('/product')
      ? 8
      : this.ctaList.findIndex((each: any) => each.url === feed.ctaUrl);

    this.form.patchValue({
      caption: feed?.captionLanguageJson[this.currentLanguage],
      cta,
      active: feed?.active,
      classTag: feed.classTag,
      categoryTag: feed.categoryTag,
    });
  }

  openLanguageCofirmDialog(template: any, language: string): void {
    this.currentLanguageCache = language;
    this.languageDialogRef = this.dialog.open(template);
  }
  closeLanguageCofirmDialog(): void {
    if (this.languageDialogRef) {
      this.languageDialogRef.close();
    }
  }

  async onSelectLanguage(language?: string): Promise<void> {
    this.currentLanguageCache = language || this.currentLanguageCache;
    this.closeLanguageCofirmDialog();
    if (this.isFileUploading) {
      this.broadcasterService.broadcast('NOTIFY', {
        message: 'Uploading is in progress.. Please wait.',
        type: this.appConfig.Shared.Toast.Type.WARNING,
      });
      return;
    }
    this.currentLanguage = language || this.currentLanguageCache;
    this.form.reset({
      active: true,
      caption: '',
      cta: '',
      gender: -1,
      userState: -1,
      type: '',
    });
    while (this.mediaArray.length) {
      this.mediaArray.removeAt(0);
    }
    this.feed = await ApiConnector.findOne(Table.Feed, { where: { objectId: this.feedId } });
    this.patchForm(JSON.parse(JSON.stringify(this.feed)));
  }

  async saveFeed(): Promise<void> {
    this.isLoading = true;
    if (!this.currentLanguage) {
      this.broadcasterService.broadcast('NOTIFY',
        { message: 'No language selected, please try again.', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.isLoading = false;
      return;
    }

    let mediaArray: any[] = this.mediaArray.value;
    if (!Array.isArray(mediaArray) || mediaArray.length === 0) {
      this.broadcasterService.broadcast('NOTIFY',
        { message: 'No media file is attached. Kindly attach and try.', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.isLoading = false;
      return;
    }
    if (mediaArray.some((media: any): boolean => media.type === ApiClientConstant.Feed.Type.Video && !media?.thumbnailUrl)) {
      this.broadcasterService.broadcast('NOTIFY',
        { message: 'Kindly add a thumbnail to the video.', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.isLoading = false;
      return;
    }
    mediaArray = mediaArray.map((value: any): any => ({
      type: value?.type,
      url: value?.url,
      aspectRatio: value?.aspectRatio,
      thumbnailUrl: value?.thumbnailUrl,
    }));
    const mediaLanguageJson = this.feed.get('mediaLanguageJson') || {};
    mediaLanguageJson[this.currentLanguage] = mediaArray;

    const captionLanguageJson = this.feed.get('captionLanguageJson') || {};
    captionLanguageJson[this.currentLanguage] = this.form.get('caption').value;

    const targetGender = this.form.get('targetGender').value;
    this.feed.set('targetGender', targetGender);
    const userPaidState = this.form.get('userPaidState').value;
    this.feed.set('userPaidState', userPaidState);

    const ctaData = await this.getCtaData(this.form.get('cta').value);

    let body = {
      active: this.form.get('active').value,
      classTag: this.classTag,
      categoryTag: this.categoryTag,
      captionLanguageJson,
      mediaLanguageJson,
    };

    if (ctaData) {
      body = { ...body, ...ctaData };
    }

    try {
      const feed = await this.feed.save(body);
      this.feedId = feed?.id;
      this.isLoading = false;
      this.broadcasterService.broadcast('NOTIFY',
        { message: 'Post saved successfully!', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.closeLanguageCofirmDialog();
      this.form.markAsPristine();
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: error.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
      this.isLoading = false;
    }
  }

  async getCtaData(ctaIndex: number): Promise<any> {
    if ((!ctaIndex || ctaIndex < 0) && ctaIndex !== 0) return;
    const ctaTextLanguageString = new Table.LanguageString();
    ctaTextLanguageString.id = this.ctaList[ctaIndex].languageString;
    if (!(await this.conn.fetchObject(ctaTextLanguageString, 'en'))) {
      throw new Error('CTA language string not found');
    }

    let ctaUrl = '';
    if (ctaIndex === 8 && this.product) {
      ctaUrl = `/product/${this.product?.id}`;
    } else if (ctaIndex === 8) {
      ctaUrl = '/user?tab=shop';
    } else {
      ctaUrl = this.ctaList[ctaIndex].url;
    }

    // eslint-disable-next-line consistent-return
    return { ctaTextLanguageString, ctaUrl };
  }
  onFileChange(data: { file: any, type: string, aspectRatio: string }, index: number): void {
    this.mediaArray.removeAt(index);
    this.mediaArray.insert(index, this.fb.group(data));
  }

  onUploadingFile(status: boolean): void {
    this.isFileUploading = status;
  }

  autoCompleteOnProductSelect(item: { name: string; object: any }): void {
    this.autoCompleteProductController.setValue('');
    this.product = item.object;
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
      },
      project: ['title', 'quantity', 'quantityUnit', 'type', 'margUnit', 'mrp', 'price'],
      ascending: 'margUnit',
      limit: 10,
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(product),
      object: product,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
