import { Component, Input, NgZone } from '@angular/core';
import { ConnectionService } from '../../services/connection-service';
import { HelperService } from '../../services/helper-service';

@Component({
  selector: 'morning-night-list',
  templateUrl: './morning-night-list.html',
  styleUrls: ['./morning-night.scss'],
})

export class MorningNightListComponent {
  regimen: any;
  @Input('regimen')
  set regimenList(regimen: any) {
    this.regimen = regimen;
  }
  constructor(private conn: ConnectionService, private zone: NgZone, public helper: HelperService) {
  }

  async ngOnInit(): Promise<any> {
    this.regimen = JSON.parse(JSON.stringify(this.regimen));
  }
}
