import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentFormComponent } from './consent-form.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [ConsentFormComponent],
  exports: [ConsentFormComponent],
})
export class ConsentFormModule {
}
