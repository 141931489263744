
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">private Instant Checkup</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body" *ngIf="this.consultationSession.get('PrivateInstantCheckupDebug')">
    <div class="col-xs-12 flex">
      <label class="mt-0 col-xs-3">Detected Issues</label>
      <div class="col-xs-9">
        <div class="col-xs-12" *ngFor="let issues of detectedIssues">{{issues.key}}  {{issues.value}}</div>
      </div>
    </div>
    <div class="col-xs-12 flex">
      <label class="col-xs-3">Active Tree</label>
      <div class="col-xs-9">{{consultationSession.get('PrivateInstantCheckupDebug').activeTree}}</div>
    </div>
    <div class="col-xs-12 flex">
      <label class="col-xs-3">InstantCheckup</label>
      <div class="col-xs-9">
        <div class="col-xs-12" *ngFor="let instantCheckup of consultationSession.get('PrivateInstantCheckupDebug')?.instantCheckups"><a href="/user/instantCheckup/{{instantCheckup?.id}}?username={{consultationSession.get('username')}}" target="_blank">{{instantCheckup?.id}}</a></div>
      </div>
    </div>
    <div class="col-xs-12 flex">
      <label class="col-xs-3">Regimen Id</label>
      <div class="col-xs-9">{{consultationSession.get('PrivateRegimenGiven')}}</div>
    </div>
  </div>
</mat-dialog-content>