import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { OrderNoteComponent } from './order-note.component';

@NgModule({
  imports: [CommonModule, FormsModule, MomentModule],
  declarations: [OrderNoteComponent],
  exports: [OrderNoteComponent],
})
export class OrderNoteModule {}
