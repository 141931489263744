
<div class="hbox" *ngIf="keywordTriggerTree">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/trees/trigger-by-keyword">Trigger By Keyword</a></li>
          <li>{{triggerByKeywordId ? 'Edit' : 'New'}}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content">
      <form class="col-xs-12 p-4" #keywordTriggerTreeForm="ngForm">
        <div class="col-xs-12">
          <label class="col-xs-4 mt-0">Tree Name</label>
          <div class="col-xs-12">
            <input-text class="col-xs-5" [parseObj]="keywordTriggerTree" name="treeName" placeholder="Tree Name" [required]="true"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Priority</label>
          <div class="col-xs-12">
            <input-text class="col-xs-5" [parseObj]="keywordTriggerTree" name="priority" type="number" placeholder="Priority" [required]="true"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Positive Keywords</label>
          <div class="col-xs-12">
            <div class="badge mr-2 mb-2" *ngFor="let value of positiveKeywords; let i = index;">{{value}}<i class="fa fa-trash-o ml-2" (click)="removePositive(i)"></i></div>
          </div>
          <div class="col-xs-6 flex">
            <input class="col-xs-10" type="text" placeholder="Type any Positive Key" [(ngModel)]="positiveKeywordInput"/>
            <button class="ml-3" (click)="addpositive()" [disabled]="!positiveKeywordInput">Add</button>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Negative Keywords</label>
          <div class="col-xs-12">
            <div class="badge mr-2 mb-2" *ngFor="let value of negativeKeywords; let i = index;">{{value}}<i class="fa fa-trash-o ml-2" (click)="removeNegative(i)"></i></div>
          </div>
          <div class="col-xs-6 flex">
            <input class="col-xs-10" type="text" placeholder="Type any Negative Key" [(ngModel)]="negativeKeywordInput"/>
            <button class="ml-3" (click)="addnegative()" [disabled]="!negativeKeywordInput">Add</button>
          </div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Enable for paid user</label>
          <div class="col-xs-6">
            <input-checkbox [parseObj]="keywordTriggerTree" name="paidUser" [required]="true"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <button class="db" [disabled]="!keywordTriggerTreeForm.valid" (click)="saveKeywordTriggerTree()">{{triggerByKeywordId ? 'Update' : 'Create'}}</button>
        </div>
      </form>
    </div>
  </div>
</div>