import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Broadcaster } from '../../components/broadcaster';
import { ConnectionService } from '../../services/connection-service';
import { LocalStorage } from '../../services/local-storage-service';

@Component({ selector: 'logout', template: '<div></div>' })
export class LogoutComponent {
  static parameters: any = [ConnectionService, Router, Broadcaster, LocalStorage];

  constructor(private conn: ConnectionService, private router: Router, private broadcaster: Broadcaster,
     private storage: LocalStorage) {
  }

  async ngOnInit(): Promise<any> {
    try {
      await this.conn.logout();
    } catch (err) {
      alert(err.message || err);
    }
    this.storage.clear();
    await this.router.navigate(['/login']);
    this.broadcaster.broadcast('UserLogout');
  }
}
