import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ApiClientConstant, Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({
  selector: 'regimen-view',
  templateUrl: './view.html',
  styleUrls: ['./view.scss'],
})
export class RegimenViewComponent implements OnDestroy {
  variants: Array<any> = [];
  selectedVariant: any = { products: [], approvalPercentage: 100 };
  regimenObj: any = {};
  voiceIdentifier: string;
  voiceLanguage: string;
  treesHavingThisRegimen: Array<any> = [];
  isMainRegimen: boolean = false;
  isOperatorRolePresent: boolean;
  user: any;
  actionLog: Array<any> = [];
  regimenHistory: Array<any> = [];
  alternateRegimens: Array<any>;
  products: Array<any> = [];
  userRoles: Array<string> = [];
  subscriptions: Array<Subscription>;
  fetchingRegimenHistory: boolean;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    public conn: ConnectionService,
    private storage: LocalStorage,
    private broadcaster: Broadcaster) {
  }

  ngOnInit(): void {
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.isOperatorRolePresent = this.userRoles.includes('operator');
    this.user = this.conn.getCurrentUser();
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.user });
    this.subscriptions = [];
    this.alternateRegimens = [];
    this.regimenObj = new Table.Regimen();
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(async () => {
      if (this.route.snapshot.data.alternateRegimen) {
        this.regimenObj = this.route.snapshot.data.alternateRegimen;
      } else if (this.route.parent.snapshot.data.regimen && !this.isAlternateRegimen()) {
        this.regimenObj = this.route.parent.snapshot.data.regimen;
        this.isMainRegimen = this.regimenObj.get('type') === ApiClientConstant.Regimen.Type.MAIN;
        this.products = []
          .concat(this.regimenObj.get('morning'))
          .concat(this.regimenObj.get('night'))
          .reduce((result: Array<any>, { product }: any): Array<any> => {
            if (!result.filter((x: any) => x.id === product.id).length) {
              result.push(product);
            }
            return result;
          }, []);
        this.findAlternateRegimen();
        this.findRegimenChangeLog();
        this.findAllTreesHavingThisRegimen();
        this.enableVoiceInstruction();
      }
      this.updateVariantDetails();
      this.findRegimenHistory();
    }));
  }

  async getVariantCoverImage(): Promise<void> {
    await Promise.all(this.variants.map(async (variant_: any) => {
      const variant = variant_;
      variant.regimenCoverImage = await this.conn.getCoverImageFromUniqueId(variant.combinedProductIds);
    }));
  }

  async enableVoiceInstruction(): Promise<void> {
    if (!this.regimenObj.has('forUser')) {
      delete this.voiceIdentifier;
      delete this.voiceLanguage;
      return;
    }
    const user = this.regimenObj.get('forUser');
    const [{ voiceIdentifier }]: Array<any> = await Promise.all([
      this.conn.findVoiceIdentifier({ regimenId: this.regimenObj.id }),
      user.fetch(),
    ]);
    this.voiceLanguage = user.get('languagePreference');
    this.voiceIdentifier = voiceIdentifier;
  }

  findAllTreesHavingThisRegimen(): void {
    if (this.isMainRegimen) {
      this.conn.getAllTreesHaving(this.regimenObj)
        .then((trees: Array<any>) => {
          this.treesHavingThisRegimen = trees;
        });
    }
  }

  isAlternateRegimen(): boolean {
    const urlSplit = this.router.url.split('/');
    if (urlSplit[urlSplit.length - 2] === 'alternate') return true;
    return false;
  }

  findRegimenChangeLog(): void {
    this.conn.getActionLog({ regimen: this.regimenObj })
      .then((actionLog: Array<any>) => (this.actionLog = actionLog));
  }

  async findRegimenHistory(): Promise<void> {
    if (this.fetchingRegimenHistory) return;
    this.fetchingRegimenHistory = true;
    const regimenHistory = await this.conn.getRegimenHistory(this.regimenObj.get('regimenId'), this.regimenHistory.length);
    this.regimenHistory = [...this.regimenHistory, ...regimenHistory];
    this.fetchingRegimenHistory = regimenHistory.length === 0;
  }

  async onScroll(): Promise<void> {
    const isAtBottom = this.isScrolledToBottom();
    if (isAtBottom) {
      await this.findRegimenHistory();
    }
  }

  private isScrolledToBottom(): boolean {
    const element = this.scrollContainer.nativeElement;
    const distanceBtwBottom = Math.abs((element.scrollHeight - element.scrollTop) - element.clientHeight);
    return distanceBtwBottom < 50;
  }

  findAlternateRegimen(): void {
    const where = {
      $start: { regimenId: this.regimenObj.get('regimenId') },
    };
    this.conn.getRegimens({ where, showAlternate: true })
      .then((regimens: Array<any>) => (this.alternateRegimens = regimens));
  }

  regimenPrice(): number {
    const uniqueProducts = {};
    return [...this.regimenObj.get('morning'), ...this.regimenObj.get('night')]
      .reduce((total: number, item: any) => {
        if (uniqueProducts[item.product.id]) {
          return total;
        }
        uniqueProducts[item.product.id] = item.product.get('price');
        return total + item.product.get('price');
      }, 0);
  }

  copyRegimen(): void {
    if (this.regimenObj.get('type') === ApiClientConstant.Regimen.Type.ALTERNATE) {
      this.router.navigate(
        ['../../alternates/new'],
        { queryParams: { copy: this.regimenObj.id }, relativeTo: this.route });
      return;
    }
    this.router.navigate(
      ['../../regimens/new'],
      { queryParams: { copy: this.regimenObj.id }, relativeTo: this.route });
  }

  async sendVoiceInstruction(): Promise<void> {
    try {
      const user = this.conn.getCurrentUser();
      if (!this.regimenObj.get('forUser').get('allocatedOperator')) {
        await this.regimenObj.get('forUser').fetch();
      }
      await this.conn.createEvent(
        this.regimenObj.get('forUser').id,
        'SEND_REGIMEN_INSTRUCTION',
        new Date(),
        `regimen_voice_by_${user.get('username')}_${moment().format('YYYY_MM_DD')}`,
        {
          Priority: { DataType: 'String', StringValue: '2' },
          regimenId: { DataType: 'String', StringValue: this.regimenObj.id },
          language: { DataType: 'String', StringValue: this.regimenObj.get('forUser').get('languagePreference') || 'en' },
          operatorId: { DataType: 'String', StringValue: this.regimenObj.get('forUser').get('allocatedOperator').id },
        });
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Send Regimen Voice Instruction event created. Instruction will be sent within 5min.',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      });
    } catch ({ message }) {
      this.broadcaster.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.router;
    delete this.route;
    delete this.appConfig;
    delete this.subscriptions;
    delete this.regimenObj;
  }

  displayVariant(variant: any): void {
    this.selectedVariant = variant;
  }

  private updateVariantDetails(): void {
    this.variants = this.regimenObj.get('type') === ApiClientConstant.Regimen.Type.MAIN
      ? this.regimenObj.get('variants') : [];
    if (this.variants?.length && this.regimenObj.get('type') === ApiClientConstant.Regimen.Type.MAIN) {
      this.displayVariant(this.variants[0]);
      this.getVariantCoverImage();
    } else {
      this.displayVariant({
        products: this.products,
        approvalPercentage: this.regimenObj.get('approvalPercentage'),
        fixedPrice: this.regimenObj.get('fixedPrice'),
        numberOfProductsLimit: this.regimenObj.get('numberOfProductsLimit'),
        morning: this.regimenObj.get('morning'),
        night: this.regimenObj.get('night'),
        regimenCoverImage: this.regimenObj.get('regimenCoverImage'),
      });
    }
  }
}
