import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConnectionService } from '../connection-service';

@Injectable()
export class MetabaseServices {
  constructor(private sanitizer: DomSanitizer, private conn: ConnectionService) {
  }

  async fetchMetabaseQuestion(params: { [key:string]: string | Date }): Promise<SafeResourceUrl> {
    const { url }: { url: string } = await this.conn.getSignedMetabaseLink(params);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
