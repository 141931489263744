import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiClientConstant } from 'api-client';
import { AppConfig } from 'src/app/app.config';
import { SalesService } from 'src/app/sales/services/sales.service';
import { Broadcaster } from 'src/components/broadcaster';
import { ConnectionService } from 'src/services/connection-service';
import { WindowRefService } from 'src/services/window-ref-service';

@Component({
  selector: 'order-info-invoice',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './order-info-invoice.component.html',
  styleUrl: './order-info-invoice.component.scss',
})
export class OrderInfoInvoiceComponent {
  @Input('orderObj') orderObj: any;
  @Input('parseOrderObj') parseOrderObj: any;
  @Input('user') user: any;
  paymentOptions: any = [
    ApiClientConstant.Order.PaymentType.ONLINE,
  ];
  discount: number;
  couponCode: string;
  coupon: any;
  selectedPaymentOption: string = ApiClientConstant.Order.PaymentType.ONLINE;
  showWalletDiscount: boolean;
  walletDiscount: number;

  constructor(
    private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
    private window: WindowRefService,
    private salesService: SalesService) { }

  ngOnChanges(): void {
    if (this.orderObj) {
      this.updatePaymentOptions();
      this.calculateDiscount();
      this.checkForCoupon();
      this.checkForWalletCashApplied();
    }
  }

  updatePaymentOptions(): void {
    if (this.orderObj.paymentGateway.includes('COD')) {
      this.paymentOptions.push(ApiClientConstant.Order.PaymentType.COD);
    }
    if (this.orderObj.paymentGateway.includes('CCOD')) {
      this.paymentOptions.push('CCOD');
    }
  }

  checkForWalletCashApplied(): void {
    this.orderObj?.couponInfo.forEach((coupon: any): void => {
      if (coupon?.type === ApiClientConstant.Coupon.Type.CURE_SKIN_CASH) {
        this.walletDiscount = coupon?.amount;
        this.showWalletDiscount = true;
      }
    });
  }

  checkForCoupon(): void {
    this.coupon = this.orderObj.couponInfo
      ? this.orderObj.couponInfo.find((each: any) => each.type === ApiClientConstant.Coupon.Type.NORMAL)
      : '';
  }

  calculateDiscount(): void {
    const actualPrice = this.orderObj?.actualPrice;
    const amount = this.orderObj?.amount;
    this.discount = Math.floor(((actualPrice - amount) * 100) / actualPrice);
  }

  async applyCoupon(): Promise<void> {
    try {
      await this.conn.applyCoupon(this.orderObj, this.couponCode);
      this.broadcaster.broadcast('NOTIFY', {
        message: 'coupon applied',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      });
      this.window.nativeWindow.location.reload();
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', {
        message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  async placeOrder(): Promise<void> {
    const confirmationText = `Are you sure want to place the order with ${this.selectedPaymentOption}?`;
    if (confirm(confirmationText)) {
      try {
        await this.conn.changeOrderPaymentMode(this.selectedPaymentOption, this.orderObj.objectId);
        if (this.selectedPaymentOption === 'COD') {
          this.broadcaster.broadcast('NOTIFY', {
            message: 'Order placed successfully',
            type: this.appConfig.Shared.Toast.Type.SUCCESS,
          });
        }
        this.window.nativeWindow.location.reload();
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', {
          message: error.message,
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
      }
    }
  }

  reorder(): void {
    const url = `${this.conn.getBaseUrl()}/orders/new?username=${this.user.get('username')}`;
    this.window.nativeWindow.open(url, '_blank');
  }

  async cancelOrder(): Promise<void> {
    try {
      const orderNote = prompt('Please enter cancellation note');
      if (!orderNote) {
        return;
      }
      await this.conn.cancelOrder(this.orderObj.objectId);
      this.salesService.emitEvent({ orderObj: this.parseOrderObj, note: orderNote });
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Order Canceled',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      });
      this.window.nativeWindow.location.reload();
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', {
        message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }
}
