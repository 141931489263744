
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/experiment">Experiments</a></li>
          <li *ngIf="experimentId">Edit</li>
          <li *ngIf="!experimentId">New</li>
        </ul>
      </div>
    </div>
    <div class="flex-content p-4">
      <form class="col-xs-12" #form="ngForm">
        <div class="col-xs-12 col-md-6">
          <div class="col-xs-12">
            <label class="mt-0">Key</label>
            <input-text [parseObj]="experiment" name="key" [required]="true"></input-text>
          </div>
          <div class="col-xs-12">
            <label class="mt-0">Name</label>
            <input-text [parseObj]="experiment" name="name" [required]="true"></input-text>
          </div>
          <div class="col-xs-12">
            <label>Variant</label>
            <input-text [parseObj]="experiment" name="variant"></input-text>
          </div>
          <div class="col-xs-12">
            <label>Min Hash Code</label>
            <input-text [parseObj]="experiment" name="minHashCode"></input-text>
          </div>
          <div class="col-xs-12">
            <label>Max Hash Code</label>
            <input-text [parseObj]="experiment" name="maxHashCode"></input-text>
          </div>
          <div class="col-xs-12">
            <label>Username</label>
            <input-text [parseObj]="experiment" name="username"></input-text>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">EmailIds</label>
            <input class="col-xs-12" type="text" [(ngModel)]="emailIds" placeholder="Can Enter Multiple EmailId By Putting Comma In Between" required="required"/>
          </div>
          <div class="col-xs-12">
            <label class="mb-2">End Date</label>
            <date-selector class="col-xs-12 mb-2" [(value)]="endDate" (valueChange)="onEndDateChange()" required="required"></date-selector>
          </div>
          <div class="col-xs-12 flex">
            <label>Enable</label>
            <input-checkbox [parseObj]="experiment" name="enable"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12 mt-4">
          <button (click)="saveExperiment()" [disabled]="!form.valid">{{ experimentId ? 'Update' : 'Create' }}</button>
        </div>
      </form>
    </div>
  </div>
</div>