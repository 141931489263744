
<div class="div chart-wrapper">
  <div class="div y-axis">
    <div class="label" *ngFor="let label of getYAxisLabels()" [style.height.px]="scale*10">{{ label }}</div>
  </div>
  <div class="div chart-container" id="chartContain">
    <div class="bar-container" *ngFor="let date of getDateKeys()">
      <div class="bars" [style.height.px]="ordersGroupedByDate[date].count * (300 / maxOrder)">
        <div class="tooltip">Order count: {{ ordersGroupedByDate[date].count }}</div>
      </div>
      <div class="label" style="height:24px; letter-spacing: unset; white-space: normal;">{{ getFormattedDate(date) }}</div>
    </div>
  </div>
</div>