import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { AudioRecorderComponent } from '../../components/audio-recorder/audio-recorder.component';

const ROUTES: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
];

@NgModule({ imports: [
  BrowserModule,
  FormsModule,
  RouterModule.forChild(ROUTES),
],
declarations: [LoginComponent],
exports: [LoginComponent] })
export class LoginModule {}
