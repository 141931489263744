
<mat-dialog-content style="border-radius:30px">
  <div class="modal-body">
    <div class="hbox of-auto">
      <div class="col-md-5">
        <div class="form-area">
          <form role="form" name="smsForm" #SmsForm="ngForm"><br style="clear:both"/>
            <h3 style="margin-bottom: 25px; text-align: center;">Send SMS</h3>
            <div class="form-group">
              <input class="form-control" [(ngModel)]="userMobileNumber" name="Mobile Number" type="number" ng-required="true" placeholder="Contact Number"/>
            </div>
            <div class="form-group">
              <textarea class="form-control" [(ngModel)]="message" rows="4" cols="50" name="Message" type="text" required="required" placeholder="Message"></textarea>
            </div>
            <label class="radio-inline">
              <input type="radio" name="optradio" value="{{ appConfig.Shared.SMS.MSG91.ROUTE.PROMOTIONAL }}" [(ngModel)]="messageRoute" required="required"/>Promotional
            </label>
            <label class="radio-inline">
              <input type="radio" name="optradio" value="{{ appConfig.Shared.SMS.MSG91.ROUTE.TRANSACTIONAL }}" [(ngModel)]="messageRoute" required="required"/>Transactional
            </label><br style="clear:both"/>
            <button class="btn btn-primary pull-right" type="button" name="submit" (click)="sendMessage()" [disabled]="!SmsForm.valid">Send</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>