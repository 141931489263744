import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../components/directives.module';
import { ViewComponent } from './view.component';
import { AddPaymentModal } from './add-payment';
import { CouponModal } from './coupon';
import { LostInTransitModal } from './lost-in-transit';
import { OrderEscalationModal } from './order-escalation';
import { OrderNoteModule } from './order-note/order-note.module';
import { OrderRefundTrackingModule } from './order-refund-tracking/order-refund-tracking.module';
import { AddOrderLabelModal } from './add-order-label';

const ROUTES: Routes = [{ path: '', component: ViewComponent, pathMatch: 'full' }];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MomentModule,
    DirectivesModule,
    OrderNoteModule,
    OrderRefundTrackingModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [ViewComponent, AddPaymentModal, AddOrderLabelModal, CouponModal, LostInTransitModal, OrderEscalationModal],
  exports: [ViewComponent, AddPaymentModal, AddOrderLabelModal, CouponModal, LostInTransitModal, OrderEscalationModal],
})
export class OrderViewModule {
}
