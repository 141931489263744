import { Component, EventEmitter, Input, NgZone, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { AssignChatConfirmationComponent } from './assign-chat-confirmation/assign-chat-confirmation.component';
import { AssignChatHistoryComponent } from './assign-chat-history/assign-chat-history.component';

@Component({ selector: 'blocked-bot-assistant-modal', templateUrl: './blocked-bot-assistant.html' })
export class BlockedBotAssistantDialog {
  messages: Array<any> = [];
  username: string;
  teamOptions: any[];
  chatLabelOptions: any[];
  attendToChatCacheObj: any;
  attendToChatCache: any;
  checkedMessage: Array<any> = [];
  popUpModel: { loading: { message: boolean }, skip: number, limit: number, lazyload: boolean };
  dateNow: Date = new Date();
  teams: Array<string> = [];
  labels: Array<string> = [];
  assignedUserLabelList: Array<any> = [];
  labelList: any = {};
  pendingAssignedChats: Array<any> = [];
  dialogRef: MatDialogRef<any>;
  userObj: any;

  @ViewChild('blockedMessageDialog', { static: true }) public blockedMessageDialog: TemplateRef<any>;
  @Input('updateStatus')
  set updateStatus(status: boolean) {
    // this.fetchBlockedMessages();
  }
  @Output('updateStatusChange') updateStatusChange: EventEmitter<boolean> = new EventEmitter();
  @Input('hideChatStatus') hideChatStatus: boolean = false;

  @Input() set user(userObj: any) {
    this.userObj = userObj;
    if (userObj.id) {
      this.username = userObj.get('username');
      this.conn.findAttendToChatCache(userObj)
        .then((attendToChatCache: any) => {
          this.updateAttendToChatCache(attendToChatCache);
          const promises: Array<Promise<any>> = [this.fetchPendingAssignedChats()];
          if (!this.attendToChatCache.attended) {
            // promises.push(this.fetchBlockedMessages(true));
          }
          return Promise.all(promises);
        });
    }
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  public subscriptions: Subscription[] = [];

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private zone: NgZone,
    private dialog: MatDialog) {
    this.popUpModel = { loading: { message: false }, skip: 0, limit: 20, lazyload: true };
  }
  async ngOnInit(): Promise<void> {
    this.teams = [...this.appConfig.Shared.Team.Name];
    this.teamOptions = this.teams;
    await this.findLabels();
    this.chatLabelOptions = this.labels;
  }

  updateAttendToChatCache(attendToChatCache: any): void {
    this.attendToChatCacheObj = attendToChatCache;
    this.attendToChatCache = this.attendToChatCacheObj.toJSON();
    ['createdAt', 'updatedAt'].forEach((key: string) => (delete this.attendToChatCache[key]));
    if (!this.attendToChatCache.teams) {
      this.attendToChatCache.teams = [];
    }
    if (!this.attendToChatCache.userLabel) {
      this.attendToChatCache.userLabel = [];
    }
    this.chatLabelOptions = this.filterOnlyUnselectedValues(this.labels, this.attendToChatCache.userLabel);
    this.teamOptions = this.filterOnlyUnselectedValues(this.teams, this.attendToChatCache.teams);
    this.updateUserLabels();
  }

  async findLabels(): Promise<any> {
    const labelList = JSON.parse(JSON.stringify(await this.conn.findAllTags()));
    labelList.forEach((value: any) => {
      this.labelList[value.name] = value.colorCode;
    });
    this.labels = Object.keys(this.labelList);
  }

  updateUserLabels(): void {
    this.assignedUserLabelList = [];
    this.attendToChatCache.userLabel.forEach((label: string) => {
      this.assignedUserLabelList.push({
        name: label,
        color: this.labelList[label],
      });
    });
  }

  // public async fetchBlockedMessages(skipAutoUnBlock?: boolean): Promise<any> {
  //   const messages: Array<any> = await this.conn.getBlockedMessages(this.attendToChatCacheObj.get('user'),
  //     { context: {}, limit: this.popUpModel.limit, offset: this.popUpModel.skip });
  //   this.zone.run(() => {
  //     this.messages.push(...messages.map((message: any) => JSON.parse(JSON.stringify(message))));
  //     this.popUpModel.skip = this.messages.length;
  //     this.popUpModel.lazyload = messages.length === this.popUpModel.limit;
  //     this.popUpModel.loading.message = false;
  //   });
  //   if (!this.messages.length && !skipAutoUnBlock) {
  //     await this.unBlockBotMessages();
  //   }
  //   this.updateStatusChange.emit(false);
  // }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  removeItem(index: number, field: string): void {
    this.attendToChatCache[field].splice(index, 1);
    this.updateUserLabels();
    this.attendToChatCacheObj.set(field, this.attendToChatCache[field]);
    this.saveAttendToChatCacheObject()
      .catch((error: any) => alert(error.message));
  }

  autoCompleteOnSelect(event: any, field: string): void {
    const { value }: any = event.target;
    if (!value || this.attendToChatCache[field].includes(value)) {
      return;
    }
    this.attendToChatCache[field].push(value);
    this.updateUserLabels();
    this.attendToChatCacheObj.set(field, this.attendToChatCache[field]);
    this.saveAttendToChatCacheObject()
      .then(() => this.chatLabelOptions = this.filterOnlyUnselectedValues(this.labels, this.attendToChatCache[field]))
      .catch((error: any) => alert(error.message));
  }

  filterOnlyUnselectedValues(data: string[], selectedValues: string[]): string[] {
    return data.filter((each: any) => !selectedValues.includes(each));
  }

  autoCompleteOnTeamSelect(event: any): void {
    const { value }: any = event.target;
    this.openAssignedChatDialog({
      assignedTeams: this.attendToChatCache.teams,
      team: value,
      attendToChatCache: this.attendToChatCacheObj,
      type: 'Assign',
    });
  }

  openAssignedChatDialog(data: any): void {
    this.dialog
      .open(AssignChatConfirmationComponent, { width: '50%', data })
      .afterClosed()
      .subscribe(async (result: boolean) => {
        if (!result) {
          return;
        }
        await this.attendToChatCacheObj.fetch();
        this.updateAttendToChatCache(this.attendToChatCacheObj);
        await this.fetchPendingAssignedChats();
      });
  }

  unAssignChat(item: any): void {
    this.openAssignedChatDialog({
      assignedTeams: this.attendToChatCache.teams,
      team: item.team,
      attendToChatCache: this.attendToChatCacheObj,
      type: 'UnAssign',
      skipConfirmation: item.skipConfirmation,
    });
  }

  markChatUnAttended(): Promise<any> {
    this.attendToChatCacheObj.set('attended', false);
    return this.saveAttendToChatCacheObject()
      .catch((err: any) => alert(err.message || err));
  }

  unBlockBotMessages(maxRetryCount: number = 5): Promise<any> {
    this.attendToChatCacheObj.set('blockBotMessages', false);
    this.attendToChatCacheObj.set('attended', true);
    return this.saveAttendToChatCacheObject()
      // .then(() => this.hideModal())
      .catch((err: any) => {
        if (!maxRetryCount) return Promise.reject(err);
        if (err.code === 412 && err.message === 'User has some blocked messages.') {
          return this.unBlockBotMessages(maxRetryCount - 1);
        }
        return Promise.reject(err);
      });
  }

  saveAttendToChatCacheObject(): Promise<any> {
    return this.attendToChatCacheObj.save()
      .then(() => this.updateAttendToChatCache(this.attendToChatCacheObj));
  }

  markStatusTo(status: string): void {
    const selectedMessages = this.messages.filter((message: any) => message.checked);
    if (!selectedMessages.length) return;
    const lastMessage = status === this.appConfig.Shared.BlockAssistant.BlockStage.MOVE_TO_ASSISTANT
      ? selectedMessages[selectedMessages.length - 1].Message
      : '';
    this.conn.updateBlockAssistantStage(selectedMessages.map((x: any) => x.objectId), status)
      .then(() => {
        this.messages = this.messages.filter((message: any) => !message.checked);
        if (!this.messages.length) {
          this.unBlockBotMessages()
            .catch((err: any) => {
              alert(err.message || err);
            });
          if (lastMessage) {
            this.conn.pushNotification({ username: this.username, typeName: 'Assistant', message: lastMessage });
          }
        }
      });
  }

  markAll(): void {
    this.messages.forEach((message: any) => {
      const msg = message;
      msg.checked = 'checked';
      this.checkedMessage.push(msg);
    });
    this.messages = [];
    this.messages = this.checkedMessage;
    this.checkedMessage = [];
  }

  hideModal(): void {
    this.dialogRef?.close();
  }

  openAssignedChatHistory(): void {
    if (!this.attendToChatCacheObj) {
      return;
    }
    this.dialog.open(AssignChatHistoryComponent, {
      panelClass: 'mat-panel-sm',
      data: { user: this.attendToChatCacheObj.get('user') },
    });
  }

  private async fetchPendingAssignedChats(): Promise<void> {
    const assignedChats = await this.conn.fetchAssignedChats({
      user: this.attendToChatCacheObj.get('user'),
      status: 'Assigned',
      includes: ['assignedBy'],
    });
    this.attendToChatCache.teams = this.attendToChatCacheObj.get('teams');
    this.pendingAssignedChats = assignedChats.map((each: any) => ({
      team: each.get('team'),
      assignedBy: each.get('assignedBy')?.get('username')?.replace('operator', '') || 'BOT',
      message: each.get('message') || '-',
      parseObject: each,
    }));
    this.attendToChatCache.teams.forEach((team: string) => {
      const isTeamAssigned = this.pendingAssignedChats.map((each: any) => each.team).includes(team);
      if (isTeamAssigned) {
        return;
      }
      if (team === ApiClientConstant.Role.Name.CHAT_SUPPORT && !this.attendToChatCache.assigned) {
        return;
      }
      this.pendingAssignedChats.unshift({ team, assignedBy: 'BOT', skipConfirmation: true, message: 'Assigned By BOT' });
    });
    this.teamOptions = this.filterOnlyUnselectedValues(this.teams, this.attendToChatCache.teams);
    if (!this.attendToChatCache.assigned
      && this.attendToChatCache.teams.length === 1
      && this.attendToChatCache.teams[0] === ApiClientConstant.Role.Name.CHAT_SUPPORT) {
      this.teamOptions.unshift(ApiClientConstant.Role.Name.CHAT_SUPPORT);
    }
  }

  public openModal(): Promise<any> {
    return new Promise<any>((resolve: Function, reject: Function): void => {
      setTimeout(() => {
        this.dateNow = new Date();
        this.popUpModel = { loading: { message: false }, skip: 0, limit: 20, lazyload: true };
        this.messages = [];
        this.dialogRef = this.dialog.open(this.blockedMessageDialog, { maxWidth: '100%', panelClass: 'hbox' });
        this.popUpModel.loading.message = true;
        // this.fetchBlockedMessages()
        //   .catch((err: any) => {
        //     this.popUpModel.loading.message = false;
        //     alert(err.message || err);
        //     reject(err);
        //   });
      }, 0);
    });
  }
}
