
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/marketing/support">Support</a></li>
          <li>{{editId? 'Edit' : 'New' }}</li>
        </ul>
        <button class="btn btn-xs pull-right" (click)="addNewSection()">Add New Section</button>
      </div>
    </div>
    <div class="flex-content p-4">
      <form class="col-xs-12 col-md-6" #supportForm="ngForm">
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Question</label>
          <div class="col-xs-7">
            <language-string-selection [parseObj]="supportQuestion" name="questionLanguageString" [required]="true" [tags]="['support_question']"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Response Title</label>
          <div class="col-xs-7">
            <language-string-selection [parseObj]="supportQuestion" name="responseTitleLanguageString" [tags]="['support_response_title']"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Team to which the ticket belongs to</label>
          <div class="col-xs-7">
            <input-multi-select [parseObj]="supportQuestion" name="teams" [options]="teams"></input-multi-select>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Icon ClassName</label>
          <div class="col-xs-7">
            <input-text [parseObj]="supportQuestion" [required]="true" name="iconClassName"></input-text>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Inputs<i class="fas fa-plus-square ml-3 pointer" (click)="addInputs()"></i></label>
          <div class="col-xs-8 flex">
            <div class="badge" *ngFor="let input of supportQuestion.get('inputs') || []; let i = index;"><span>{{input}}</span><i class="fa fa-trash-o pl-2 pointer" (click)="removeInputs(i)"></i></div>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Conditions</label>
          <div class="col-xs-8">
            <question-condition [conditions]="supportQuestion.get('conditions')" (conditionUpdated)="updateQuestionConditions()" [inputVariables]="supportQuestion.get('inputs')"></question-condition>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <div class="col-xs-12">
            <label class="col-xs-4">Query<i class="fas fa-plus-square ml-3 pointer" (click)="addQuery()"></i></label>
          </div>
          <div class="col-xs-12">
            <div class="col-xs-12 p-0 flex mb-3" *ngFor="let row of supportQuestion.get('queries') || []; let i = index;">
              <div class="col-xs-4">
                <input class="input-sm" [(ngModel)]="row.name" name="query_name_{{i}}" required="required"/><i class="fas fa-trash pointer ml-3" (click)="removeQuery(i)"></i>
              </div>
              <div class="col-xs-8">
                <div class="col-xs-12">
                  <select class="form-control p-0 input-sm" [(ngModel)]="row.type" name="query_type-{{i}}" required="required">
                    <option [value]="undefined" disabled="disabled">Query Type</option>
                    <option *ngFor="let type of queryFunctionType" [value]="type">{{type}}</option>
                  </select>
                </div>
                <div class="col-xs-12 mt-1" *ngIf="appConfig.Shared.Support.Queries.Type.FETCH_BY_OBJECT_ID === row.type">
                  <div class="col-xs-4 pr-1">
                    <select class="form-control p-0 input-sm" [(ngModel)]="row.table" name="query_table-{{i}}" required="required">
                      <option [value]="undefined" disabled="disabled">Select Table</option>
                      <option *ngFor="let table of tables;" [value]="table">{{table}}</option>
                    </select>
                  </div>
                  <div class="col-xs-4 pl-1">
                    <select class="form-control p-0 input-sm col-xs-6" [(ngModel)]="row.value" name="query_value-{{i}}" required="required">
                      <option [value]="undefined" disabled="disabled">Select ObjectId</option>
                      <option *ngFor="let input of supportQuestion.get('inputs') || []" [value]="input">{{input}}</option>
                    </select>
                  </div>
                  <div class="col-xs-4 pl-1">
                    <input class="w100 input-sm" [(ngModel)]="row.value2" name="query_value_2{{i}}" placeholder="pointer include values separated by ','"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Response JSON<i class="fas fa-plus-square ml-3 pointer" (click)="addResponseKeys()"></i></label>
          <div class="col-xs-12">
            <div class="col-xs-12 p-0" *ngIf="responseJson.length">
              <label class="col-xs-4 mt-0">Key</label>
              <label class="col-xs-4 mt-0">Value</label>
            </div>
            <div class="col-xs-12 p-0 flex mb-3" *ngFor="let response of responseJson; let i = index;">
              <div class="col-xs-4">
                <input class="w100" [(ngModel)]="response.key" name="resp_key_{{i}}"/>
              </div>
              <div class="col-xs-4 ml-2">
                <input class="w100" [(ngModel)]="response.value" name="resp_value_{{i}}" placeholder="values separated  by ',' for OR condition"/>
              </div><i class="fas fa-trash pointer ml-3" (click)="removeResponseKeys(i)"></i>
            </div>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Response HTML</label>
          <div class="col-xs-7">
            <language-string-selection [parseObj]="supportQuestion" name="responseHtmlLanguageString" [tags]="['support_response']"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Output Type</label>
          <div class="col-xs-7">
            <input-select [parseObj]="supportQuestion" name="outputType" [options]="outputTypes" [required]="true"></input-select>
          </div>
        </div>
        <div class="col-xs-12 mt-3" *ngIf="supportQuestion?.get('outputType') === appConfig.Shared.Support.Type.COMPONENT">
          <label class="col-xs-4">Component</label>
          <div class="col-xs-7">
            <input-select [parseObj]="supportQuestion" name="outputComponent" [options]="displayComponents" [required]="true"></input-select>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">ListView Type</label>
          <div class="col-xs-7">
            <input-select [parseObj]="supportQuestion" name="listViewType" [options]="listViewType" [required]="true"></input-select>
          </div>
        </div>
        <div class="col-xs-12 mt-3" *ngIf="supportQuestion?.get('outputType') === appConfig.Shared.Support.Type.URL">
          <label class="col-xs-4">Landing Url</label>
          <div class="col-xs-7">
            <input-text [parseObj]="supportQuestion" name="outputUrl" [required]="true"></input-text>
          </div>
        </div>
        <div class="col-xs-12 mt-3" *ngIf="supportQuestion?.get('outputComponent') === appConfig.Shared.Support.Component.TREE">
          <label class="col-xs-4">Tree node name</label>
          <div class="col-xs-7">
            <input-text [parseObj]="supportQuestion" name="treeNode" [required]="true"></input-text>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Parent Questions<i class="fas fa-plus-square ml-3 pointer" (click)="addParentQuestion()"></i></label>
          <div class="col-xs-7"><span class="inline label label-default m-l-xs m-t-xs" *ngFor="let question of supportQuestion.get('parentSupportQuestions') || []; let i = index;">
              <support-question-name [supportQuestion]="question"></support-question-name><i class="fa fa-trash-o pointer m-2"></i></span></div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Start Question</label>
          <div class="col-xs-7">
            <input-checkbox [parseObj]="supportQuestion" name="start"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Rank</label>
          <div class="col-xs-7">
            <input-text [parseObj]="supportQuestion" name="rank" type="number" [min]="0"></input-text>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Active</label>
          <div class="col-xs-7">
            <input-checkbox [parseObj]="supportQuestion" name="active"></input-checkbox>
          </div>
        </div>
      </form>
    </div>
    <div class="flex-footer p-4">
      <button (click)="saveQuestion()" [disabled]="!supportForm.valid">{{ !loading ? "Save Question" : "Saving ..." }}</button>
    </div>
  </div>
</div>