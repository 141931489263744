import { Component, ElementRef, Injector, ViewChild, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../app/app.config';
import { Broadcaster } from '../broadcaster';
import { UploadFileService } from '../../services/uploadFileService/uploadFile.service';

@Component({ selector: 'signature-crop-popup', templateUrl: './signature-crop-popup.html' })

export class SignatureCropPopupComponent {
  imgSrc: any;
  scale: number = 1;
  ctx: CanvasRenderingContext2D;
  @ViewChild('canvas') canvasRef: ElementRef;
  img: HTMLImageElement;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  username: string;
  isDrawing: boolean = false;
  croppedFile: any;

  constructor(private injector: Injector,
    private dialogRef: MatDialogRef<SignatureCropPopupComponent>,
    private renderer: Renderer2,
    private uploadFileService: UploadFileService,
    private broadcast: Broadcaster,
    private appConfig: AppConfig) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.username = data.username;
  }
  handleChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.img = new Image();
      this.img.crossOrigin = 'Anonymous';
      this.img.onload = () => {
        const canvas = this.canvasRef.nativeElement;
        this.ctx = canvas.getContext('2d');
        canvas.width = this.img.width;
        canvas.height = this.img.height;
        this.ctx.drawImage(this.img, 0, 0);
      };
      this.img.src = e.target.result as string;
      this.imgSrc = this.img.src;
    };
    reader.readAsDataURL(file);
  }

  onMouseDown(event: MouseEvent): void {
    this.startX = event.offsetX;
    this.startY = event.offsetY;
    this.isDrawing = true;
  }

  onMouseUp(event: MouseEvent): void {
    if (!this.isDrawing) return;
    const canvas = this.canvasRef.nativeElement;
    this.endX = Math.min(Math.max(event.offsetX, 0), canvas.width);
    this.endY = Math.min(Math.max(event.offsetY, 0), canvas.height);
    this.isDrawing = false;
    this.drawRectangle();
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isDrawing) return;
    const canvas = this.canvasRef.nativeElement;
    this.endX = Math.min(Math.max(event.offsetX, 0), canvas.width);
    this.endY = Math.min(Math.max(event.offsetY, 0), canvas.height);
    this.drawRectangle();
  }
  drawRectangle(): void {
    const canvas = this.canvasRef.nativeElement;
    const startX = Math.min(this.startX, this.endX);
    const startY = Math.min(this.startY, this.endY);
    const width = Math.abs(this.endX - this.startX);
    const height = Math.abs(this.endY - this.startY);
    this.clearCanvas();
    this.ctx.drawImage(this.img, 0, 0);
    this.ctx.strokeStyle = 'black';
    this.ctx.lineWidth = 2;
    this.ctx.strokeRect(startX, startY, width, height);
  }

  clearCanvas(): void {
    const canvas = this.canvasRef.nativeElement;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  crop(): void {
    const canvas = this.canvasRef.nativeElement;
    const startX = Math.min(this.startX, this.endX);
    const startY = Math.min(this.startY, this.endY);
    const width = Math.abs(this.endX - this.startX);
    const height = Math.abs(this.endY - this.startY);

    const tempCanvas = this.renderer.createElement('canvas');
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempCtx = tempCanvas.getContext('2d');

    tempCtx.drawImage(canvas, startX, startY, width, height, 0, 0, width, height);

    this.clearCanvas();

    canvas.width = width;
    canvas.height = height;

    this.ctx.drawImage(tempCanvas, 0, 0);
    tempCanvas.toBlob((blob: any) => {
      if (!blob) {
        this.broadcast.broadcast('NOTIFY', { message: 'Unable to Crop Image.', type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
      this.croppedFile = new File([blob], 'cropped_image.png', { type: 'image/png' });
    }, 'image/png');
  }
  reset(): void {
    this.img = new Image();
    this.img.crossOrigin = 'Anonymous';
    this.img.onload = () => {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      canvas.width = this.img.width;
      canvas.height = this.img.height;
      this.ctx.drawImage(this.img, 0, 0);
    };
    this.img.src = this.imgSrc;
  }
  hideModal(): void {
    this.dialogRef.close();
  }

  async uploadFile(file: any): Promise<any> {
    if (!this.username) {
      throw Error('Username missing.');
    }
    const { publicURL }: { publicURL: string } = await this.uploadFileService.uploadFileViaSignedURL({
      file,
      bucket: 'OTHER',
      username: this.username,
      source: 'doctor',
    });
    return publicURL;
  }

  async saveImage(): Promise<void> {
    const imageurl = await this.uploadFile(this.croppedFile);
    this.dialogRef.close(imageurl);
  }
}
