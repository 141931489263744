import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConnectionService } from '../../../services/connection-service';

@Injectable()
export class QuestionResolve {
  constructor(private conn: ConnectionService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.conn.getQuestion(route.params.id)
      .then((questionObj: any): any => {
        if (questionObj) return Promise.resolve(questionObj);
        this.router.navigate(['/trees/questions']);
        return false;
      });
  }
}
