
<div class="col-xs-12 p-0 mb-3 flex" *ngIf="showSearch">
  <div class="col-xs-8">
    <input class="w100" name="{{prefix ? prefix + '_' : ''}}{{name}}input" [formControl]="searchControl" placeholder="Type here to search"/>
  </div>
  <div class="col-xs-4 pl-3">
    <button type="button" [disabled]="!searchControl.value" (click)="addLanguageString()">Add</button>
  </div>
</div>
<div class="col-xs-12 flex p-0">
  <div class="col-xs-10">
    <select class="w100" [required]="required" name="{{prefix ? prefix + '_' : ''}}{{name}}" [(ngModel)]="parseValue" (ngModelChange)="onUpdateSelection()" [ngClass]="{ 'bg-light': !parseValue}">
      <option [value]="defaultValue" disabled="disabled" selected="selected">Tags: {{placeholder}}</option>
      <option *ngFor="let option of options;" [value]="option.value">{{option.display}}</option>
    </select>
  </div>
  <div class="col-xs-2 pl-3"><span data-tooltip-pos="bottom" data-tooltip-text="Search" (click)="showSearch = true"><i class="fa fa-search pointer mx-2" *ngIf="!showSearch" [ngClass]="{ 'text-info': !showSearch }"></i></span><span data-tooltip-pos="bottom" data-tooltip-text="Edit" (click)="edit(languageStringObj.id)"><a class="fa fa-pencil-square-o pointer mx-2" *ngIf="languageStringObj"></a></span></div>
</div>