import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoFaceComponent } from './components/auto-face/auto-face.component';
import { AlignFaceDirective } from './components/align-face/align-face.directive';

@NgModule({
  declarations: [
    AutoFaceComponent,
    AlignFaceDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AutoFaceComponent,
  ],
})
export class AutoFaceModule { }
