import { v4 as uuid } from 'uuid';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ControlContainer, NgForm } from '@angular/forms';
import { InputHelperService } from '../../../../../components/input/input-helper';
import { HelperService } from '../../../../../services/helper-service';
import { CatalogInstructionEditModal } from './catolog-instruction-edit-modal';
import { LocalStorage } from '../../../../../services/local-storage-service';
import { CatalogSelectionComponent } from '../../../../../components/catalog-selection/catalog-selection.component';
import {
  CatalogSelectInstructionToCopyComponent,
} from './catolog-select-instruction-to-copy-modal/catalog-select-instruction-to-copy.component';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'catalog-instruction',
  templateUrl: './catalog-instruction.html',
  styleUrls: ['./catalog-instruction.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CatalogInstructionComponent {
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onUpdateInput();
  }
  @Input('name')
  set onUpdateName(name: string) {
    this.name = name;
    this.onUpdateInput();
  }
  name: string = uuid();
  parseObj: any;
  instruction: any;
  template: any;
  ui: any = { modal: { edit: { open: false } } };
  instructions: Array<any> = [];
  isAdminDoctor: boolean = false;
  @Input('title') title: string;

  constructor(private inputHelper: InputHelperService,
    private helperService: HelperService,
    private dialog: MatDialog,
    public appConfig: AppConfig,
    private localStorage: LocalStorage) {
  }

  ngOnInit(): void {
    const userRoles = this.localStorage.getJsonValue('userRoles');
    this.isAdminDoctor = userRoles.includes('adminDoctor');
  }

  openCatalogInstructionEditModal(): void {
    this.dialog.open(CatalogInstructionEditModal, { data: { instruction: this.instruction, productObj: this.parseObj } })
      .afterClosed().subscribe(() => {
        delete this.instruction;
        this.instructions = this.parseObj.get('instructions');
      });
  }

  private onUpdateInput(): void {
    if (!this.name || !this.parseObj) return;
    this.instructions = this.inputHelper.getValue(this.parseObj, this.name) || [];
  }

  private onUpdateResult(): void {
    this.inputHelper.setValue(this.parseObj, this.name, this.instructions);
    this.parseObj.save();
  }

  addNewInstruction(): void {
    delete this.instruction;
    this.openCatalogInstructionEditModal();
  }

  async deleteInstruction(index: number): Promise<void> {
    this.instructions.splice(index, 1);
    this.onUpdateResult();
  }

  editInstruction(index: number): void {
    this.instruction = this.instructions[index];
    this.openCatalogInstructionEditModal();
  }

  useInstructionAsTemplate(index: number): void {
    this.instruction = this.helperService.createCopyOfObjectItem(this.instructions[index]);
    delete this.instruction.id;
    delete this.instruction.creationId;
    this.openCatalogInstructionEditModal();
  }

  pullInstructionsFromCatalog(): void {
    this.dialog.open(CatalogSelectionComponent, { panelClass: 'w-full', data: {} })
      .afterClosed().subscribe((copyFromCatalog: any) => {
        if (!copyFromCatalog) {
          return;
        }
        this.dialog.open(CatalogSelectInstructionToCopyComponent, { panelClass: 'w-full', data: { catalog: copyFromCatalog } })
          .afterClosed().subscribe(async (instructions: Array<any>) => {
            try {
              const currentInstructions = this.parseObj.get('instructions') || [];
              currentInstructions.push(...instructions);
              await this.parseObj.save({ instructions: currentInstructions });
              delete this.instruction;
              this.instructions = this.parseObj.get('instructions');
            } catch (error) {
              alert(error.message);
            }
          });
      });
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.parseObj.set('instructions', this.instructions);
    this.parseObj.save();
  }
}
