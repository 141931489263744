import { Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { ApiClientConstant, Table } from 'api-client';
import { AppConfig } from '../../../../app.config';
import { HelperService } from '../../../../../services/helper-service';
import { ConnectionService } from '../../../../../services/connection-service';
import { TypeTree } from '../../../tree-type';

@Component({ selector: 'post-regimen-edit', templateUrl: './post-regimen-edit.html', styleUrls: ['./post-regimen-edit.scss'] })
export class PostRegimenEditComponent implements OnDestroy {
  tree: TypeTree;
  treeObj: any;
  treeClasses: Array<string> = [];
  subscriptions: Array<Subscription>;
  static parameters: any = [AppConfig, Router, ActivatedRoute, HelperService, ConnectionService];
  constructor(public appConfig: AppConfig, private router: Router, private route: ActivatedRoute,
     private helper: HelperService, private conn: ConnectionService) {
    this.treeClasses.push(...Object.keys(ApiClientConstant.Regimen.Class)
      .map((key: string) => ApiClientConstant.Regimen.Class[key]));
  }

  private getAutoComplete(name: string): { controller: UntypedFormControl; options: Observable<Array<{ name: string; object: any }>> } {
    const controller = new UntypedFormControl(name);
    const options = controller.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getQuestions(token)));
    return { controller, options };
  }

  async getQuestions(name: string): Promise<any> {
    const questions = await this.conn.findQuestions({
      where: {
        $or: ['title', 'uniqueIdentifier'].map((each: string) => ({ [each]: { $regex: name, $options: 'i' } })),
      },
      project: ['uniqueIdentifier', 'title'],
      limit: 10,
    });
    return questions.map((question: any) => ({
      name: this.getDisplayNameOfQuestion(question),
      object: question,
    }));
  }

  ngOnInit(): void {
    this.subscriptions = [];
    this.treeObj = new Table.Tree();
    this.tree = {
      class: '',
      name: '',
      type: ApiClientConstant.Tree.Type.BASIC_INFO,
      questions: [],
      treeTables: [],
      active: true,
    };
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.parent.params.subscribe(async () => {
      if (this.route.parent.snapshot.data.subTree) {
        this.treeObj = this.route.parent.snapshot.data.subTree;
        this.tree.name = this.treeObj.get('name');
        const tree: any = {
          class: '',
          name: '',
          type: ApiClientConstant.Tree.Type.BASIC_INFO,
          questions: [],
        };
        this.helper.convertParseToDictionary(this.treeObj, tree);
        const questions = await this.conn.findQuestions({
          where: { objectId: this.treeObj.get('questions').map((each: any) => each.id) },
          limit: this.treeObj.get('questions').length,
        });
        tree.questions = questions.map((question: any) => ({
          name: this.getDisplayNameOfQuestion(question),
          question,
          autoComplete: this.getAutoComplete(this.getDisplayNameOfQuestion(question)),
        }));
        this.tree = tree;
      }
    }));
  }

  getDisplayNameOfQuestion(question: any): string {
    return `${question.get('uniqueIdentifier')}: ${question.get('title')}`;
  }

  autoCompleteOnSelect(item: { name: string; object: any }, questionItem_: { name: string; question: any }): void {
    const questionItem = questionItem_;
    questionItem.name = item.name;
    questionItem.question = item.object;
  }

  addQuestion(): void {
    this.tree.questions.push({ autoComplete: this.getAutoComplete('') });
  }

  removeQuestion(index: number): void {
    this.tree.questions.splice(index, 1);
  }

  isTreeValid(): boolean {
    return this.tree.questions.every((i: any) => {
      const item = i;
      if (item.question) return true;
      delete item.name;
      return false;
    });
  }

  saveTree(): void {
    if (!this.isTreeValid()) return;
    this.helper.convertDictionaryToParse(this.tree, this.treeObj);
    this.treeObj.set('questions', this.tree.questions.map((x: any) => x.question));
    this.treeObj.set('type', this.treeObj.get('type'));
    this.treeObj.set('nodeMap', []);
    this.treeObj.save()
      .then(() => this.router.navigate([`/tree/${this.treeObj.get('name').split('_')[0]}`]))
      .catch((err: any) => alert(err.message.message || err.message));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.appConfig;
    delete this.router;
    delete this.route;
    delete this.helper;
    delete this.conn;
    delete this.subscriptions;
    delete this.tree;
    delete this.treeObj;
  }

  moveQuestionUp(index: number): void {
    if (index < 1) return;
    const temp = this.tree.questions[index];
    this.tree.questions[index] = this.tree.questions[index - 1];
    this.tree.questions[index - 1] = temp;
  }

  moveQuestionDown(index: number): void {
    if (index < 0 || index >= this.tree.questions.length - 1) return;
    const temp = this.tree.questions[index];
    this.tree.questions[index] = this.tree.questions[index + 1];
    this.tree.questions[index + 1] = temp;
  }
}
