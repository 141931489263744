import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, UntypedFormControl, NgForm } from '@angular/forms';
import { ApiConnector } from 'api-client';
import { AppConfig } from '../../../app/app.config';
import { InputType } from '../../../../typings/client/input';
import { InputHelperService } from '../input-helper';
import SelectOption = InputType.SelectOption;

@Component({
  selector: 'input-multi-select',
  templateUrl: './input-multi-select.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class InputMultiSelectComponent {
  @Input('prefix') prefix: string;
  @Input('id')
  set onUpdateId(id: string) {
    this.id = id;
    this.onInputChange();
  }
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input() defaultSelect: string;
  @Input('options')
  set onUpdateOptions(options: Array<InputType.SelectOption>) {
    if (!options) {
      this.options = [];
      return;
    }
    if (this.checkOptionValidity(options)) {
      this.options = options;
    } else {
      throw new Error(`Invalid Options: Expected option format for ${this.name
      } is { "value": string, "parseValue"?: any, "display": string }`);
    }
  }
  options: Array<InputType.SelectOption> = [];
  @Input('required') required: boolean = false;
  @Input('class') className: string = 'bdr form-control';
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }
  disabled: boolean = false;
  @Output('onValueChanged') onValueChanged: EventEmitter<any> = new EventEmitter();

  formController: UntypedFormControl = new UntypedFormControl();
  ui: any = {};
  id: string;
  parseValue: any = '';
  parseObj: any;
  name: string;

  constructor(public appConfig: AppConfig, private inputHelper: InputHelperService) {
  }

  ngOnInit(): void {
    if (this.defaultSelect) {
      this.parseValue = this.defaultSelect;
    }
  }

  onInputChange(): void {
    if (!this.parseObj || !this.name) return;
    this.parseValue = this.inputHelper.getValue(this.parseObj, this.name);
    if (this.parseValue instanceof (ApiConnector as unknown as {
      getMongoToParseQueryBase: () => ({ getParse: () => ({ Object: any }) })
    }).getMongoToParseQueryBase().getParse().Object) {
      this.parseValue = this.parseValue.id;
    }
    if (this.id) {
      this.parseValue = this.inputHelper.getValue(this.parseValue, this.id);
    }
    this.onValueChanged.emit(this.parseValue);
  }

  valueUpdated(): void {
    const selectedRows = this.options.filter((row: InputType.SelectOption) => this.parseValue?.includes(row.value));
    const values = selectedRows.map((selectedRow: SelectOption) => (selectedRow.parseValue || selectedRow.value));
    if (!this.inputHelper.getValue(this.parseObj, this.name)
      || values.some((value: string) => !this.inputHelper.getValue(this.parseObj, this.name).includes(value))
      || values.length !== this.inputHelper.getValue(this.parseObj, this.name).length) {
      this.inputHelper.setValue(this.parseObj, this.name, values);
    }
    this.onValueChanged.emit(values);
  }

  private checkOptionValidity(options: Array<InputType.SelectOption>): boolean {
    return !options.some((row_: InputType.SelectOption) => {
      const row = row_;
      if (!row.display) return true;
      if (row.disabled) return false;
      if (!row.value) return true;
      if (typeof row.value !== 'string') return true;
      return false;
    });
  }
}
