import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { InstantCheckupDialog } from './instant-checkup.component';
import { DirectivesModule } from '../../../../components/directives.module';

@NgModule({
  imports: [CommonModule, FormsModule, MomentModule, DirectivesModule, MatDialogModule],
  declarations: [InstantCheckupDialog],
  exports: [InstantCheckupDialog],
})
export class InstantCheckupModule {}
