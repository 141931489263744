import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'quick-action-buttons',
  templateUrl: './quick-action-buttons.html',
  styleUrls: ['./quick-action-buttons.scss'],
})

export class QuickActionButtonsComponent implements OnInit {
  @Output('sectionToScrollTo') sectionToScrollTo: EventEmitter<string> = new EventEmitter();
  @Input('quickActions') quickActions: Array<{ section: string, icon: string, name: string }> = [];
  constructor() {
  }
  ngOnInit(): void {
  }
  quickAction(section: string): void {
    this.sectionToScrollTo.emit(section);
  }
}
