import { Component } from '@angular/core';
import { WindowRefService } from '../../services/window-ref-service';
import { ConnectionService } from '../../services/connection-service';

@Component({ selector: 'app-update', templateUrl: './app-update.html', styleUrls: ['./app-update.scss'] })
export class AppUpdateComponent {
  user: any;

  constructor(private window: WindowRefService, private conn: ConnectionService) {
    this.user = this.conn.getCurrentUser();
  }

  reloadApp(): void {
    this.window.nativeWindow.location.reload();
  }
}
