import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConnectionService } from '../../../../services/connection-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { ConsultationSessionsList } from '../consultation-dialog';
import { Broadcaster } from '../../../../components/broadcaster';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'tree-trigger', templateUrl: './tree-trigger.html', styleUrls: ['./tree-trigger.scss'] })
export class TreeTriggerComponent {
  @Input('user') user: any;

  actOnState: string;
  selectedConcern: string;
  treeList: Array<any> = [];
  mainConcernOptions: Array<any> = [];
  isDeveloper: boolean = false;
  public subscriptions: Subscription[] = [];

  constructor(
    private conn: ConnectionService,
    private storage: LocalStorage,
    private broadcasterService: Broadcaster,
    public appConfig: AppConfig,
    private dialog: MatDialog) {}

  async ngOnInit(): Promise<any> {
    const userRoles = this.storage.getJsonValue('userRoles') || [];
    this.isDeveloper = userRoles.includes('developerRole');

    if (this.isDeveloper) {
      this.treeList = await this.conn.findTree({ where: {}, project: ['name'] });
    } else {
      this.mainConcernOptions = await this.conn.findMainConcernsOptions();
    }
  }

  async activateState(type_?: string): Promise<any> {
    let type = type_;
    if (!this.user || !this.user.get('username')) {
      await Promise.reject(new Error('User is not available'));
    }
    if (!type && this.actOnState.includes('FollowUp')) {
      type = 'followUp';
    } else if (!type) {
      type = 'consultationSession';
    }
    try {
      const stateId = this.actOnState.includes(':') ? this.actOnState : `${this.actOnState}:`;
      await this.conn.activateState({ username: this.user.get('username'), stateId, context: { } });
    } catch (err) {
      switch (type) {
        case 'consultationSession': {
          await this.openConsultationPopup();
          break;
        }
        case 'followUp': {
          await this.selectLastFollowUp();
          break;
        }
        default:
      }
    }
  }

  async openConsultationPopup(): Promise<void> {
    const userConsultationsSessions = await this.conn.fetchConsultationSessions({
      where: { user: this.user, archive: false },
      project: ['PrivateMainConcernClass'],
    });
    if (!userConsultationsSessions.length) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'no ConsultationsSession Found',
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
    const dialogRef = this.dialog.open(ConsultationSessionsList, { data: { consultationsList: userConsultationsSessions } });
    dialogRef.afterClosed().subscribe(async (selectedConsultation: string) => {
      if (!selectedConsultation) return;
      const context = { consultationSessionId: selectedConsultation };
      const stateId = this.actOnState.includes(':') ? this.actOnState : `${this.actOnState}:`;
      try {
        await this.conn.activateState({ username: this.user.get('username'), stateId, context });
        this.broadcasterService.broadcast('NOTIFY', { message: 'Tree Triggered Successfully',
          type: this.appConfig.Shared.Toast.Type.SUCCESS });
      } catch (error) {
        this.broadcasterService.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    });
  }

  async selectLastFollowUp(): Promise<void> {
    const [lastFollow]: any = await this.conn.findFollowUps({
      where: { user: this.user, State: this.appConfig.FollowUp.State.PENDING },
      descending: 'followUpCount',
      limit: 1,
      project: [],
    });
    if (!lastFollow) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'no Followup available',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const context = { followUpId: lastFollow.id };
    const stateId = this.actOnState.includes(':') ? this.actOnState : `${this.actOnState}:`;
    try {
      await this.conn.activateState({ username: this.user.get('username'), stateId, context });
      this.broadcasterService.broadcast('NOTIFY', { message: 'Tree Triggered Successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async reTriggerSameTree(): Promise<any> {
    this.actOnState = 'v4';
    await this.activateState('consultationSession');
  }

  async triggerFollowUpTree(): Promise<any> {
    this.actOnState = 'v4_followup';
    await this.activateState('followUp');
  }

  async saveSelectedMainConcern(): Promise<any> {
    if (!this.user || !this.user.get('username')) {
      await Promise.reject(new Error('User is not available'));
    }
    await this.conn.updateUserData({ id: this.user.id, PrivateMainConcern: this.selectedConcern });
    delete this.selectedConcern;
  }

  async clearSession(className: string): Promise<any> {
    const sessions = await this.conn.getAllConsultationSession(this.user);
    const yes = confirm(`Are you sure to delete ${className} answers`);
    if (yes) {
      const del = sessions.find((each: any) => each.get('PrivateMainConcernClass') === className);
      await del.destroy();
      alert(`Refresh and Then select and save any concern from dropdown to initiate new tree for ${className}`);
    }
  }
}
