import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SalesService {
  private eventSubject: Subject<any> = new Subject<any>();

  eventObservable$:Observable<string> = this.eventSubject.asObservable();

  emitEvent(data: any): void {
    this.eventSubject.next(data);
  }
}
