
<div class="div doctor-productivity">
  <div>Doctor Productivity</div>
  <div class="svg-container">
    <svg class="circular-chart" viewBox="-5 -5 50 50" width="180" height="180">
      <path class="circle-bg" d="M20 0 a 15 15 0 0 1 0 35 a 15 15 0 0 1 0 -35"></path>
      <path class="circle" [attr.stroke-dasharray]="arcLength + ' ' + (pathLength - arcLength)" [attr.stroke-dashoffset]="strokeDashoffset" d="M20 0 a 15 15 0 0 1 0 35 a 15 15 0 0 1 0 -35"></path>
    </svg>
    <div class="percentage">{{ percentage }}</div>
  </div>
</div>