import { Component, Input } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'coupon-modal', templateUrl: './coupon.html' })
export class CouponModal {
  @Input('order')
  set setOrder(x: any) {
    this.updateOrder(x);
  }
  order: any;
  coupon: any;

  constructor(private conn: ConnectionService,
    private windowRefService: WindowRefService,
    private appConfig: AppConfig) {
  }

  private updateOrder(order: any): void {
    this.order = order;
    this.coupon = order.get('coupons')
      ? order.get('coupons').find((each: any) => each.get('type') === ApiClientConstant.Coupon.Type.NORMAL)
      : '';
  }

  async applyCoupon(): Promise<any> {
    const couponCode = prompt('Enter coupon code');
    if (!couponCode) return;
    await this.conn.applyCoupon(this.order, couponCode)
      .then(() => this.windowRefService.nativeWindow.location.reload())
      .catch((err: any) => alert(err.message));
  }
}
