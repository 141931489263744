
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="list-header col-xs-12"><span class="pR25">Assigned Chats ({{count}})</span></div>
  </div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content p-0">
          <div class="flex mb-3 px-3">
            <label class="mr-3">Auto Refresh</label>
            <label class="i-switch i-switch-sm bg-success">
              <input type="checkbox" [(ngModel)]="autoRefresh" (ngModelChange)="toggleAutoRefresh()" name="autoRefresh"/><i></i>
            </label>
          </div>
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.OPERATOR" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.OPERATOR" [showList]="true">
              <operator-filter (updatedOperatorList)="udpateOperatorFilter($event)" [reset]="resetOperatorFilter"></operator-filter>
            </collapse>
          </div>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>