import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from 'src/components/directives.module';
import { InternalTeamComponent } from './internal-team.component';
import { InputInternalUserSelectModule } from '../../../components/input/input-internal-user-select';

const ROUTES: Routes = [
  {
    path: '',
    component: InternalTeamComponent,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  FormsModule,
  CommonModule,
  RouterModule.forChild(ROUTES),
  InputInternalUserSelectModule,
  DirectivesModule,
],
declarations: [InternalTeamComponent],
exports: [InternalTeamComponent] })
export class InternalTeamModule {}
