import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppConfig } from '../../../app.config';
import { InputType } from '../../../../../typings/client/input';

@Component({
  selector: 'node-modal',
  templateUrl: './node.html',
})
export class NodeModal {
  node: any;
  parentNode: any;
  types: Array<InputType.SelectOption>;
  linkTypes: Array<InputType.SelectOption>;
  public subscriptions: Subscription[] = [];

  static parameters: any = [AppConfig, MatDialogRef, MAT_DIALOG_DATA];
  constructor(public appConfig: AppConfig, private dialogRef: MatDialogRef<NodeModal>, @Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit(): void {
    this.node = this.data.node;
    this.parentNode = this.data.parentNode;
    this.subscriptions.push(this.dialogRef.afterOpened().subscribe((result: any) => {
      this.updateTypes();
      this.node.set('parentNode', this.parentNode);
      this.subscriptions.push(this.dialogRef.afterClosed().subscribe((reason: string) => {
        this.unsubscribe();
      }));
    }));
  }

  updateTypes(): void {
    this.types = [
      { display: '>>>>>>>>Select<<<<<<<', disabled: true, value: '' },
      { display: 'Folder', value: this.appConfig.Shared.EmbeddedLink.Type.Folder },
      { display: 'Link', value: this.appConfig.Shared.EmbeddedLink.Type.Link },
    ];
    this.linkTypes = [
      { display: '>>>>>>>>Select<<<<<<<', disabled: true, value: '' },
      { display: 'Metabase', value: this.appConfig.Shared.EmbeddedLink.LinkType.Metabase },
      { display: 'Grafana', value: this.appConfig.Shared.EmbeddedLink.LinkType.Grafana },
    ];
    if (!this.parentNode) {
      this.types.pop();
    }
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  async updateOrCreateNode(): Promise<any> {
    await this.node.save();
    this.dialogRef.close(this.node);
  }
}
