
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading" #headingView>
    <div class="col-xs-12 list-header"></div>
  </div>
  <div class="flex-content">
    <div class="list-view">
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
