
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{productObj.get('title')}} ( {{productObj.get('type')}} )</h4><span class="bold-600" style="font-size: 18px">( {{productObj.id}} )</span><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form class="col-xs-12" role="form" name="catalog" #catalogForm="ngForm">
      <div class="col-xs-12">
        <div class="col-xs-12 col-sm-12 col-md-4 mr-3">
          <label class="col-xs-8">Selling Price</label>
          <div class="col-xs-12">
            <input-text [parseObj]="productObj" name="price" required="required" [disabled]="productObj.get('type') === 'sample'" type="number" [min]="0"></input-text>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 mr-3">
          <label class="col-xs-8">MRP</label>
          <div class="col-xs-12">
            <input-text [parseObj]="productObj" name="mrp" required="required" [disabled]="productObj.get('type') === 'sample'" type="number" [min]="0"></input-text>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3">
          <label class="col-xs-12">Effective Cost Price</label>
          <div class="col-xs-12">
            <input-text [parseObj]="productObj" name="effectiveCostPrice" required="required" [disabled]="productObj.get('type') === 'sample'" type="number" [min]="0"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Weight/Volume</label>
          <div class="col-xs-2 mr-3">
            <input-text [parseObj]="productObj" name="quantity" required="required"></input-text>
          </div>
          <div class="col-xs-1">
            <input-select [parseObj]="productObj" name="quantityUnit" [options]="quantityUnits" required="required"></input-select>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-12 col-sm-3 mr-3">
            <label class="col-xs-12">Marg ID</label>
            <input-text [parseObj]="productObj" required="required" name="margId" placeholder=""></input-text>
          </div>
          <div class="col-xs-12 col-sm-5 mr-3">
            <label class="col-xs-12">Marg Name</label>
            <input-text [parseObj]="productObj" name="margName" placeholder=""></input-text>
          </div>
          <div class="col-xs-12 col-sm-3">
            <label class="col-xs-12">Marg Unit</label>
            <input-text [parseObj]="productObj" name="margUnit" placeholder="" type="number"></input-text>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <div class="col-xs-12 col-sm-3 flex">
            <label class="col-xs-5">Tablet</label>
            <div class="col-xs-7">
              <input-checkbox [parseObj]="productObj" name="isTablet"></input-checkbox>
            </div>
          </div>
          <div class="col-xs-12 col-sm-3 flex">
            <label class="col-xs-5">Antibiotic</label>
            <div class="col-xs-7">
              <input-checkbox [parseObj]="productObj" name="isAntibiotic"></input-checkbox>
            </div>
          </div>
          <div class="col-xs-12 col-sm-3 flex">
            <label class="col-xs-5">AntiFungal</label>
            <div class="col-xs-7">
              <input-checkbox [parseObj]="productObj" name="isAntiFungal"></input-checkbox>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Inventory Status</label>
          <div class="col-xs-6 col-md-4">
            <input-select [parseObj]="productObj" name="inventoryStatus" [options]="inventoryStatus" required="true"></input-select>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Image</label>
          <div class="col-xs-6 col-md-4">
            <ng-container *ngIf="productObj.get('rebrandedImageWithBackground')?.length">
              <div class="m-r-xs" *ngFor="let image of productObj.get('rebrandedImageWithBackground');"><a href="{{image}}" target="_blank"><img class="img-thumbnail" src="{{image}}" style="height: 150px;"/></a></div>
            </ng-container>
            <ng-container *ngIf="productObj.get('rebrandedImageWithBackground')?.length === 0 && productObj.get('productUnboxedImage')?.length">
              <div class="m-r-xs" *ngFor="let image of productObj.get('productUnboxedImage');"><a href="{{image}}" target="_blank"><img class="img-thumbnail" src="{{image}}" style="height: 150px;"/></a></div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" (click)="saveCatalog()" [disabled]="!catalogForm.valid">Update</button>
  </div>
</mat-dialog-content>
