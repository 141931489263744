import { Component, Input } from '@angular/core';

@Component({ selector: 'arrow-line', templateUrl: './arrow-line.html' })
export class ArrowLine {
  @Input('errorY') errorY: number = 0;
  @Input('to') to: HTMLElement;
  @Input('from') from: HTMLElement;
  color: string = '#f00';
  @Input('height') height: number = 5000;
  @Input('width') width: number = 5000;
  @Input('scrollY') scrollY: number = 0;
}
