import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent {
  pageTitle: string = 'Internal Users';
  type: any;
  userObj: any;
  user: any;
  userId: any;
  userType: string;
  active: boolean = true;
  currentUserRoles: Array<string> = [];
  userTypes: Array<any> = [];
  languages: Array<any> = [];
  preferredKnownLanguage: Array<any> = [];
  rolesParse: Array<any> = [];
  userRoles: Array<any> = [];
  beforeSaveUserRoles: Array<any> = [];
  newUserRoles: Array<any> = [];
  rolesThatCanBeAdded: Array<any> = [];
  genderType: Array<any> = [];
  popUpModel: { loading: boolean };
  serviceStartDate: Date;
  registeredMobileNumber: string = '';
  callerId: string = '';
  username: string = '';
  doctorLoadConfig: { minFollowUpAllocation?: number; minOrderAllocation?: number; } = {};
  userTypeOptions: Array<any> = [];
  callerTypeOptions: Array<any> = [];
  loading:boolean = true;

  constructor(
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private route: ActivatedRoute,
    private window: WindowRefService,
    private localStorage: LocalStorage,
    private broadcastService: Broadcaster,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<any> {
    this.route.queryParams.subscribe((queryParam: any) => {
      if (queryParam.type) {
        this.pageTitle = queryParam.type;
        this.userType = queryParam.type || ApiClientConstant.User.Type.OPERATOR;
      }
    });
    this.languages = [
      { value: ApiClientConstant.LanguageString.EN, display: 'English', selected: false },
      { value: ApiClientConstant.LanguageString.HI, display: 'Hindi', selected: false },
      { value: ApiClientConstant.LanguageString.TE, display: 'Telugu', selected: false },
      { value: ApiClientConstant.LanguageString.KN, display: 'Kannada', selected: false },
      { value: ApiClientConstant.LanguageString.MA, display: 'Malayalam', selected: false },
      { value: ApiClientConstant.LanguageString.MR, display: 'Marathi', selected: false },
      { value: ApiClientConstant.LanguageString.TA, display: 'Tamil', selected: false },
      { value: ApiClientConstant.LanguageString.BN, display: 'Bengali', selected: false },
    ];
    this.userTypes = [
      { value: ApiClientConstant.User.Type.OPERATOR, display: 'Operator' },
      { value: ApiClientConstant.User.Type.DOCTOR, display: 'Doctor' },
    ];
    this.genderType = [
      { value: ApiClientConstant.User.Gender.MALE, display: 'Male' },
      { value: ApiClientConstant.User.Gender.FEMALE, display: 'Female' },
    ];
    if (this.route.snapshot.params.id) {
      this.editUser(await this.conn.findUserByObjectId(this.route.snapshot.params.id));
      await this.fetchRoles();
    } else {
      this.createUser();
    }
    this.user = this.conn.getCurrentUser();
    this.currentUserRoles = this.localStorage.getJsonValue('userRoles') || [];
    this.registeredMobileNumber = this.userObj.get('exotelNumber');
    this.callerId = this.userObj.get('CallerId');
    this.username = this.userObj.get('username');
    Object.keys(ApiClientConstant.User.UserType).forEach((each: any) => this.userTypeOptions.push({ display: each, value: each }));
    Object.keys(ApiClientConstant.User.CallerType).forEach((each: any) => this.callerTypeOptions.push({ display: each, value: each }));
    this.loading = false;
  }

  createUser(): void {
    this.type = 'CREATE_USER';
    this.userObj = new Table.User();
    this.userObj.set('inactive', true);
    this.checkLanguages(this.userObj);
  }

  checkNumberValidity(event: Event): void {
    if (Number(this.registeredMobileNumber) === 0 || Number(this.registeredMobileNumber.charAt(0)) === 0
      || !(/^[0-9]*$/.test(this.registeredMobileNumber))) {
      this.broadcastService.broadcast('NOTIFY', { message: 'Phone Number cannot start with zero',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.registeredMobileNumber = '';
    }
    this.userObj.set('exotelNumber', this.registeredMobileNumber);
  }

  checkCallerIdValidity(event: Event): void {
    if (!(/^0[0-9]*$/.test(this.callerId))) {
      this.broadcastService.broadcast('NOTIFY', { message: 'CallerId should start with zero',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.callerId = '';
    }
    this.userObj.set('CallerId', this.callerId);
  }

  editUser(user: any): void {
    if (!user) return;
    this.type = 'EDIT_USER';
    this.userObj = user;
    this.serviceStartDate = this.userObj.get('serviceStartDate');
    this.doctorLoadConfig = user.get('doctorLoadConfig') || {};
    this.checkLanguages(user);
    this.findUserRoles(user);
  }

  userConfigChange(): void {
    this.userObj.set('doctorLoadConfig', this.doctorLoadConfig);
  }

  removeRole(roleIndexToRemove: number): void {
    this.rolesThatCanBeAdded.push(...this.newUserRoles.splice(roleIndexToRemove, 1));
  }

  addRole(roleIndexToAdd: number): void {
    this.newUserRoles.push(...this.rolesThatCanBeAdded.splice(roleIndexToAdd, 1));
  }

  async findUserRoles(user: any): Promise<any> {
    this.userRoles = [];
    this.newUserRoles = [];
    this.rolesThatCanBeAdded = [];
    this.beforeSaveUserRoles = [];
    this.userRoles = await this.conn.findUserRoles(user);
    this.beforeSaveUserRoles = this.userRoles.map((each: any) => each.get('name'));
    this.newUserRoles = this.beforeSaveUserRoles.map((each: any) => each);
    this.rolesThatCanBeAdded = this.rolesParse.map((each: any) => each.get('name'))
      .filter((each: string) => !this.newUserRoles.includes(each));
  }

  checkLanguages(user: any): void {
    const languagesKnown = user.get('knownLanguages') || [];
    this.preferredKnownLanguage = languagesKnown;
    this.languages.forEach((each_: any) => {
      const each = each_;
      each.selected = languagesKnown.includes(each.value);
    });
  }

  async saveUser(): Promise<boolean> {
    if (this.userObj.get('userType') && this.userObj.get('userType').includes('MBBS')
    && this.userObj.get('userType').includes('DERMATOLOGISTS')) {
      this.broadcastService.broadcast('NOTIFY', { message: 'UserType can be either DERMATOLOGIST or MBBS',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    const languagesKnown = this.languages.filter((each: any) => each.selected)
      .map((each: any) => each.value);
    if (!languagesKnown.length) {
      this.broadcastService.broadcast('NOTIFY', { message: 'Select any language.', type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    if (this.type === 'EDIT_USER' && this.userObj.dirtyKeys().includes('password') && !confirm('Do you want to change password.')) {
      return false;
    }
    if (this.serviceStartDate !== this.userObj.get('serviceStartDate')) {
      this.userObj.set('serviceStartDate', this.serviceStartDate);
    }
    try {
      this.userObj.set('knownLanguages', this.preferredKnownLanguage);
      await this.userObj.save();
      this.broadcastService.broadcast('NOTIFY', { message: 'User created successfully.' });
      return true;
    } catch (error: any) {
      this.broadcastService.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
  }

  async updateRoles(user: any): Promise<void> {
    const rolesToAdd = this.newUserRoles.filter((each: string) => !this.beforeSaveUserRoles.includes(each))
      .map((each: string) => this.rolesParse.find((parseRole: any) => parseRole.get('name') === each));
    const rolesToRemove = this.beforeSaveUserRoles.filter((each: string) => !this.newUserRoles.includes(each))
      .map((each: string) => this.rolesParse.find((parseRole: any) => parseRole.get('name') === each));
    rolesToAdd.forEach((role: any) => role.getUsers().add([user]));
    rolesToRemove.forEach((role: any) => role.getUsers().remove([user]));
    await this.conn.saveAll([].concat(...rolesToAdd).concat(...rolesToRemove));
  }

  async updateUser(user: any): Promise<any> {
    try {
      const savedSuccessfully = await this.saveUser();
      if (!savedSuccessfully) {
        return;
      }
      if (this.type !== 'CREATE_USER') {
        await this.updateRoles(user);
        this.router.navigate(['/users/internal-users']);
      }
    } catch (error) {
      this.broadcastService.broadcast('NOTIFY',
        { message: error.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async fetchRoles(): Promise<any> {
    this.rolesParse = await this.conn.findUserRoles();
    this.editUser(this.userObj);
  }

  updateLanguage(language:any):void {
    if (!this.preferredKnownLanguage.includes(language.value)) {
      this.preferredKnownLanguage.push(language.value);
    } else {
      const index = this.preferredKnownLanguage.indexOf(language.value);
      if (index > -1) {
        this.preferredKnownLanguage.splice(index, 1);
      }
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
