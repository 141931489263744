
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header"><span class="pr-3">Analytics</span>
        <div class="pull-right"><i class="fa fa-plus-square-o pointer" (click)="createNode()"></i></div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 p-3">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node;">
            <div class="col-xs-6 bdr-btm p-3 pl-0 flex">
              <mat-icon class="mat-icon-rtl-mirror" matTreeNodeToggle="matTreeNodeToggle" [attr.aria-label]="'toggle ' + node.item">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
              <div class="col-xs-3"><a class="text-primary" routerLink="{{node.item.id}}" target="_blank">{{node.item.get('name')}}</a></div>
              <div class="col-xs-2"><i class="fa fa-pencil-square-o pointer ml-3" (click)="editNode(node)"></i></div>
            </div>
          </mat-tree-node>
          <mat-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="col-xs-6 p-3 pl-0 bdr-btm flex">
              <mat-icon class="mat-icon-rtl-mirror" matTreeNodeToggle="matTreeNodeToggle" [attr.aria-label]="'toggle ' + node.item">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
              <div class="col-xs-3">{{node.item.get('name')}}</div>
              <div class="col-xs-2"><i class="fa fa-pencil-square-o pointer ml-3" (click)="editNode(node)"></i><i class="fa fa-plus-square-o pointer ml-3" (click)="createNode(node)"></i></div>
              <div class="col-xs-6">
                <mat-progress-bar class="ml-3" *ngIf="node.isLoading" mode="indeterminate"></mat-progress-bar>
              </div>
            </div>
          </mat-tree-node>
        </mat-tree>
      </div>
    </div>
  </div>
</div>