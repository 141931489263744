
<label class="col-xs-12 p-0 mb-3">Instructions<i class="fas fa-plus-square pointer ml-3" (click)="addNewInstruction()"></i><i class="fas fa-file-import pointer ml-3 ml-2" (click)="pullInstructionsFromCatalog()"></i></label>
<div>
  <div class="col-xs-12 pl-0" cdkDropList="cdkDropList" [cdkDropListData]="instructions" (cdkDropListDropped)="drop($event)">
    <div class="col-xs-12 pl-0 mb-3" *ngFor="let instructionItem of instructions; let i = index;" cdkDrag="cdkDrag" [cdkDragDisabled]="!isAdminDoctor" style="display: flex; align-items: center">
      <div class="col-xs-6 pl-0">
        <catalog-instruction-view [instruction]="instructionItem" (onDelete)="deleteInstruction(i)" cssClasses="w-fit-content" [showAction]="true" (onEdit)="editInstruction(i)" (onUseAsTemplate)="useInstructionAsTemplate(i)"></catalog-instruction-view>
      </div>
      <div class="col-xs-6">
        <div class="product-details pull-right" [ngClass]="{'night': instructionItem.title.toLowerCase().includes('night') || instructionItem.partOfDay === appConfig.Shared.Catalog.PART_OF_DAY.NIGHT}">
          <div class="w70">
            <div class="product-name" *ngIf="title">{{title}}
              <ul class="otherDetails">
                <li *ngIf="instructionItem?.frequencyOfApplicationLanguageString">{{instructionItem.frequencyOfApplicationLanguageString?.get('en')}}</li>
                <li *ngIf="instructionItem?.quantityUnitLanguageString">{{instructionItem.quantity}} {{instructionItem.quantityUnitLanguageString?.get('en')}}</li>
                <li *ngIf="instructionItem?.areaOfApplicationLanguageString">{{instructionItem.areaOfApplicationLanguageString.get('en')}}</li>
                <li *ngIf="instructionItem?.durationOfApplicationLanguageString">{{instructionItem.durationOfApplicationLanguageString.get('en')}}</li>
              </ul>
            </div>
          </div>
          <div class="w30 flex justify-content-end"><img class="quantity-image" *ngIf="instructionItem?.quantityImage?.length" [src]="instructionItem.quantityImage[0]" width="100" height="100"/></div>
        </div>
      </div>
    </div>
  </div>
</div>
