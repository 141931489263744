import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ApiClientConstant } from 'api-client';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'language-view',
  templateUrl: './language-view.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class LanguageViewComponent {
  @Input('languageStringObj')
  set onUpdateLanguageStringObj(languageStringObj: any) {
    this.languageStringObjs = (languageStringObj instanceof Array
      ? languageStringObj
      : [languageStringObj]).filter((each: any) => each.id);
    this.updateName();
  }

  name: string;
  languageStringObjs: Array<any>;
  static parameters: any = [AppConfig];
  constructor(private appConfig: AppConfig) {
  }

  ngOnInit(): void {
  }

  private async updateName(): Promise<any> {
    if (!this.languageStringObjs || !this.languageStringObjs.length) return;
    const names = await this.findDisplayTestFor(this.languageStringObjs);
    this.name = names.join(', ');
  }

  private async findDisplayTestFor(languageStringObjs: Array<any>): Promise<Array<string>> {
    const languageStringObj = languageStringObjs.shift();
    if (!languageStringObj) return [];
    if (!languageStringObj.get(ApiClientConstant.LanguageString.EN)) {
      await languageStringObj.fetch();
    }
    const name = languageStringObj.get(ApiClientConstant.LanguageString.EN);
    const names = await this.findDisplayTestFor(languageStringObjs);
    names.unshift(name);
    return names;
  }
}
