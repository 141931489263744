import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConnectionService } from '../../services/connection-service';

@Injectable()
export class OrderResolve {
  constructor(private conn: ConnectionService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.conn.getOrderWithSignedURL(route.params.id)
      .then((orderObj: any): any => {
        if (orderObj) return Promise.resolve(orderObj);
        this.router.navigate(['doctor/orders']);
        return false;
      });
  }
}
