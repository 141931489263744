
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Regimen Product Change History</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="col-xs-12" *ngIf="loading">Loading...</div>
    <div class="col-xs-12" *ngFor="let actionLog of actionLogs">
      <div class="col-xs-2">{{actionLog.createdAt | amDateFormat: 'MMM DD,YY hh:mm A'}}</div>
      <div class="col-xs-10">{{actionLog.Action}}</div>
    </div>
  </div>
</mat-dialog-content>