import { Component, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { InputType } from '../../../../../typings/client/input';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({ selector: 'media-link-edit', templateUrl: './edit.html', styleUrls: ['edit.scss'] })
export class EditComponent implements OnDestroy {
  imageFile: any;
  imageSrc: any;
  isValidImageFile: boolean = true;
  mediaLinkObj:any;
  products: Array<any> = [];
  dropBox: {
    mediaType: Array<string>,
    subType: Array<string>,
  } = {
      mediaType: ['Image'],
      subType: ['Regimen Cover Photo'],
    };
  subTypeOptions: Array<InputType.SelectOption> = this.dropBox.subType.map((each: any): InputType.SelectOption => ({
    value: each,
    display: each,
  }));
  mediaTypeOptions: Array<InputType.SelectOption> = this.dropBox.mediaType.map((each: any): InputType.SelectOption => ({
    value: each,
    display: each,
  }));
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  uniqueId: string = '';
  maxImageSizeInKb: number = 400000;
  username: string;
  userRoles: Array<string> = [];
  userType: string = '';
  ImagefileUrl: string;
  coverImageLink:string;

  constructor(private conn: ConnectionService,
              public appConfig: AppConfig,
              private router: Router,
              private route: ActivatedRoute, private broadcaster: Broadcaster) {
  }

  async ngOnInit(): Promise<void> {
    const currentUser = this.conn.getCurrentUser();
    this.username = currentUser.get('username');
    this.userType = currentUser.get('type');
    this.mediaLinkObj = new Table.MediaLink();
    if (this.route.snapshot.params.id) {
      await this.conn.getMediaLink({ where: { objectId: this.route.snapshot.params.id } })
        .then(async (mediaLinkObj: any): Promise<any> => {
          if (mediaLinkObj) {
            this.mediaLinkObj = mediaLinkObj[0];
            this.coverImageLink = this.mediaLinkObj.get('link');
            await this.setProducts();
          }
        });
    }
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
  }

  async setProducts(): Promise<void> {
    const mainProductIds: Array<string> = [];
    if (!this.mediaLinkObj.get('reference')) {
      return;
    }
    this.mediaLinkObj?.get('reference').forEach((product: any) => (mainProductIds.push(product.split('_')[0])));
    const requestPayload: RequestQueryPayload<Table.Catalog> = {
      where:
        {
          objectId: { $in: mainProductIds },
        },
      project: ['title', 'quantity', 'quantityUnit', 'type', 'margUnit', 'mrp', 'price', 'mainProduct'],
    };
    const result = await ApiConnector.find(Table.Catalog, requestPayload);
    this.products.push(result);
    this.products = this.products[0];
  }

  removeProduct(index: number): void {
    this.products.splice(index, 1);
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
        type: 'main',
      },
      project: ['title', 'quantity', 'quantityUnit', 'type', 'margUnit', 'mrp', 'price', 'mainProduct'],
      ascending: 'margUnit',
      limit: 10,
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(product),
      object: product,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  autoCompleteOnProductSelect(item: { name: string; object: any }): void {
    if (!this.products) this.products = [];
    this.products.push(item.object);
    this.autoCompleteProductController.setValue('');
  }

  isNewMediaLink(): boolean {
    return this.router.url.split('/').join('').endsWith('new');
  }

  setProductsIdsWithProductType(productIds: Array<string>): Array<string> {
    if (!productIds || productIds.length === 0) {
      return [];
    }
    return productIds.map((id:any) => `${id}_catalog`);
  }
  firstLower(lower: string): string {
    return lower && (lower[0].toLowerCase() + lower.slice(1) || lower);
  }

  async saveMediaLink(): Promise<void> {
    if (this.isNewMediaLink()) {
      const mainProductIds: Array<string> = [];
      if (!this.products || !this.products.length) {
        this.broadcaster.broadcast('NOTIFY', { message: 'Add At-least One Product',
          type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
      this.products.forEach((product:any) => mainProductIds.push(product.get('mainProduct').id));
      this.mediaLinkObj.set('reference', this.setProductsIdsWithProductType(mainProductIds) || []);
      this.uniqueId = mainProductIds.sort().join('_');
      this.uniqueId = `${this.firstLower(this.mediaLinkObj.get('mediaSubType').split(' ').join(''))}_${this.uniqueId}`;
      this.mediaLinkObj.set('uniqueId', this.uniqueId);
      let isAllProductsCombinationNew:boolean;
      await this.isAlreadyExistedProducts(this.uniqueId)
        .then((result:boolean) => {
          isAllProductsCombinationNew = result;
        });
      if (isAllProductsCombinationNew) {
        this.broadcaster.broadcast('NOTIFY', { message: 'These Products are Already Stored  Find in MediaLink list',
          type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
      if (!this.mediaLinkObj.get('mediaType') || !this.mediaLinkObj.get('mediaSubType')) {
        this.broadcaster.broadcast('NOTIFY', { message: 'Media Type / Media Sub Type is Required',
          type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
    }
    if (this.imageFile) {
      if (this.imageFile.size < this.maxImageSizeInKb) {
        await this.readFile(this.imageFile);
      } else {
        this.broadcaster.broadcast('NOTIFY', { message: 'Size should be less than 400 kb',
          type: this.appConfig.Shared.Toast.Type.ERROR });
        this.isValidImageFile = false;
      }
      if (!this.isValidImageFile) {
        return;
      }
    }
    if (!this.imageFile && !this.ImagefileUrl) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Upload Image In Properly',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    try {
      await this.mediaLinkObj.save();
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    await this.router.navigate(
      ['/products/medialink'],
      { relativeTo: this.route });
  }

  async isAlreadyExistedProducts(combinedProductIds:string): Promise<boolean> {
    try {
      const checkDuplicateMediaLink = await ApiConnector.find(Table.MediaLink,
        { where: { uniqueId: combinedProductIds } });
      if (checkDuplicateMediaLink?.length) {
        return true;
      }
      return false;
    } catch (error) {
      return true;
    }
  }

  fileChanged(files: any): void {
    this.imageFile = files;
  }

  async readFile(item: any): Promise<any> {
    return new Promise((resolve: Function, reject: Function): void => {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = async (data: any): Promise<any> => {
        this.imageSrc = data.target.result;
        this.isValidImageFile = await this.getAspectRatio(this.imageSrc);
        resolve();
      };
    });
  }

  getAspectRatio(imageSrc: any): Promise<boolean> {
    return new Promise((resolve: Function, reject: Function): void => {
      const image = new Image();
      image.onload = (): void => {
        if (image.height !== image.width) {
          const acceptImageHeight = Math.floor(image.width * 0.8);
          const acceptImageHeight2 = Math.floor(image.width * 1);
          if ((!(image.height <= (acceptImageHeight + (acceptImageHeight * 0.1)))
              && image.height >= (acceptImageHeight - (acceptImageHeight * 0.1)))
            && (!(image.height <= (acceptImageHeight2 + (acceptImageHeight2 * 0.1))
              && image.height >= (acceptImageHeight2 - (acceptImageHeight2 * 0.1))))) {
            this.broadcaster.broadcast('NOTIFY', { message: 'Aspect ratio of image should be 5:4 or 1:1',
              type: this.appConfig.Shared.Toast.Type.ERROR });
            resolve(false);
          }
        }
        resolve(true);
      };
      image.src = imageSrc;
    });
  }

  ngOnDestroy(): void {
    delete this.router;
    delete this.appConfig;
    delete this.conn;
    delete this.route;
    delete this.mediaLinkObj;
  }
}
