import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

@Component({ selector: 'dataSelect-modal', templateUrl: './dataSelectModal.html' })

export class DataSelectModal {
  response: Array<string> = [];
  multipleSelect: boolean = false;

  constructor(public dialogRef: MatDialogRef<DataSelectModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.response = data.preSelectedData || [];
    this.multipleSelect = data.multipleSelect || false;
  }

  addSelectedData(selectedOption: string): void {
    if (this.response.includes(selectedOption)) return;
    this.response.push(selectedOption);
  }

  removeItem(index: number): void {
    this.response.splice(index, 1);
  }

  sendDataMultiple(): void {
    this.dialogRef.close(this.response);
  }

  sendDataSingle(selectedOption: string): void {
    this.dialogRef.close(selectedOption);
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
