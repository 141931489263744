import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { EditComponent } from './edit.component';

const ROUTES:Routes = [
  {
    path: '',
    component: EditComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    InputCheckboxModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
})

export class EditModule { }
