
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="list-header col-xs-12"><span class="pR25">Pending Calls ({{count}})</span></div>
  </div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content p-0">
          <div class="flex mb-3 px-3">
            <label class="mr-3">Auto Refresh</label>
            <label class="i-switch i-switch-sm bg-success">
              <input type="checkbox" [(ngModel)]="autoRefresh" (ngModelChange)="toggleAutoRefresh()" name="autoRefresh"/><i></i>
            </label>
          </div>
          <div class="mb-3 px-3">
            <label class="db">Search</label>
            <input class="w100" placeholder="Message" (change)="reset()" [(ngModel)]="searchKey"/>
          </div>
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.LANGUAGES" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.LANGUAGES">
              <language-filter (updatedLanguageList)="updateLanguageFilter($event)" [reset]="resetLanguageFilter"></language-filter>
            </collapse>
          </div>
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.TEAMS" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.TEAMS" [showList]="true">
              <team-filter (updatedTeamList)="updateTeamFilter($event)" [excludeTeams]="true" [reset]="resetTeamFilter"></team-filter>
            </collapse>
          </div>
<!--          <div class="px-3 bdr-top" *ngIf="userRoles.includes(appConfig.Shared.Role.Name.CHAT_SUPPORT)">-->
<!--            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.OPERATOR" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.OPERATOR">-->
<!--              <operator-filter (updatedOperatorList)="updateOperatorFilter($event)" [reset]="resetOperatorFilter" [filter]="{ userType: {$in: ['CHAT_SUPPORT','SALES', 'COURIER', 'MARKETING']} }"></operator-filter>-->
<!--            </collapse>-->
<!--          </div>-->
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.TYPE" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.TYPE" [showList]="true">
              <type-filter (updatedTypeList)="updateTypeFilter($event)" [reset]="resetTypeFilter"></type-filter>
            </collapse>
          </div>
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.DOCTOR" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.DOCTOR">
              <doctor-filter (updatedDoctorList)="updateDoctorFilter($event)" [reset]="resetDoctorFilter"></doctor-filter>
            </collapse>
          </div>
          <section class="list-color-holder"><span class="list-colors-circle list-label-bg-red" data-tooltip-text="Regimen contains Tablet" data-tooltip-pos="right"></span></section>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
