import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../app.config';

@Injectable()
export class TreeResolver {
  constructor(private conn: ConnectionService, private router: Router, private appConfig: AppConfig) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    if (route.params.indication) {
      return this.conn
        .getTree({
          name: route.params.indication,
          type: ApiClientConstant.Tree.Type.INDICATION,
        })
        .then((treeObj: any): any => {
          if (treeObj) return Promise.resolve(treeObj);
          this.router.navigate(['../']);
          return false;
        });
    }
    if (route.params.answerDependentTreeId) {
      return this.conn
        .getTree({
          name: route.params.answerDependentTreeId,
          type: ApiClientConstant.Tree.Type.ANSWER_DEPENDENT,
        })
        .then((treeObj: any): any => {
          if (treeObj) return Promise.resolve(treeObj);
          this.router.navigate(['../']);
          return false;
        });
    }
    if (route.params.postRegimenId) {
      return this.conn
        .getTree({
          name: route.params.postRegimenId,
          type: ApiClientConstant.Tree.Type.POST_REGIMEN,
        })
        .then((treeObj: any): any => {
          if (treeObj) return Promise.resolve(treeObj);
          this.router.navigate(['../']);
          return false;
        });
    }
    if (route.params.answerIndependentTreeId) {
      return this.conn
        .getTree({
          name: route.params.answerIndependentTreeId,
          type: ApiClientConstant.Tree.Type.ANSWER_INDEPENDENT,
        })
        .then((treeObj: any): any => {
          if (treeObj) return Promise.resolve(treeObj);
          this.router.navigate(['../']);
          return false;
        });
    }
    if (route.params.treatmentDeliveryTreeId) {
      return this.conn
        .getTree({
          name: route.params.treatmentDeliveryTreeId,
          type: ApiClientConstant.Tree.Type.TREATMENT_DELIVERY,
        })
        .then((treeObj: any): any => {
          if (treeObj) return Promise.resolve(treeObj);
          this.router.navigate(['../']);
          return false;
        });
    }
    return this.conn
      .getTree({
        name: route.params.name,
        type: ApiClientConstant.Tree.Type.BASIC_INFO,
      })
      .then((treeObj: any): any => {
        if (treeObj) return Promise.resolve(treeObj);
        this.router.navigate(['/trees']);
        return false;
      });
  }
}
