import * as moment from 'moment';
import { Directive, HostListener, Input } from '@angular/core';
import { v4 as uuid } from 'uuid';
import Parse from 'parse';
import { ApiConnector } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { Broadcaster } from '../broadcaster';
import { WindowRefService } from '../../services/window-ref-service';
import { ElasticService } from '../../services/elastic-search';

declare type InternalUser = {
  id: string;
  username: string;
  type: string;
  teams?: Array<string>;
};

declare type User = {
  id: string;
  username: string;
  durationInDays: number;
  durationInWeeks: number;
};

declare type ActivityTracking = {
  id: string;
  url: string;
  params: { [key: string]: unknown };
  urlPattern: string;
  startTime: string;
  endTime: string;
  duration: number;
  internalUser: InternalUser;
  user?: User;
};

declare type ActivityStatus = 'INACTIVE' | 'ACTIVE';

declare interface PatternKey {
  pattern: string;
  group?: string;
  keys: { [key: string]: number; };
  concernTeams?: Array<string>;
  queryKeys?: { [key: string]: string };
}

@Directive({ selector: '[activityTracker]' })
export class ActivityTrackerDirective {
  @Input() activityTracker: string;

  // private static LOGOUT_TIMEOUT: number = 10 * 60 * 1000;
  private static LOGOUT_CONFIG: Array<{name: string, logout: number}> = [
    { name: 'ROLE_disableLogout', logout: 7 * 24 * 60 * 60 * 1000 },
    { name: 'ROLE_kiosk-doctor', logout: 24 * 60 * 60 * 1000 },
    { name: 'ROLE_admin', logout: 24 * 60 * 60 * 1000 },
    { name: 'ROLE_adminOperator', logout: 24 * 60 * 60 * 1000 },
    { name: 'ROLE_adminDoctor', logout: 24 * 60 * 60 * 1000 },
    { name: 'CHAT_SUPPORT', logout: 10 * 60 * 1000 },
    { name: 'MBBS', logout: 10 * 60 * 1000 },
    { name: 'DERMATOLOGIST', logout: 10 * 60 * 1000 },
  ];
  // private static LOGOUT_TIMEOUT_ADMIN: number = 1000 * 60 * 60 * 24;
  private static IN_ACTIVITY_TIMEOUT: number = 20 * 1000;
  private static LOG_INTERVAL: number = 30 * 1000;
  private static LOG_FLUSH_INTERVAL: number = 120 * 1000;
  private static LOG_FLUSH_SIZE: number = 10;
  private static PATTERN: { USERNAME: string, PARSE_ID: string, MOBILE_NUMBER: string, REGIMEN_ID: string, ORDER_NUMBER: string } = {
    USERNAME: '[a-zA-Z0-9_]',
    PARSE_ID: '[a-zA-Z0-9_-]{10}',
    MOBILE_NUMBER: '[0-9]{10}',
    REGIMEN_ID: 'v\\d_\\w+',
    ORDER_NUMBER: 'C0[0-9A-Z]{7}',
  } as const;

  private activityStatus: ActivityStatus = 'INACTIVE';
  private interval: { flush?: any, log?: any } = {};
  private trackURL: string;
  private inActivityTimeout: any;
  private internalUser: InternalUser;
  private user: User;
  private activityTime: Date;
  private lastLogTime: Date = new Date();
  private sending: boolean = false;
  private logs: Array<ActivityTracking> = [];
  private patternKeys: Array<PatternKey> = [];

  constructor(private connectionService: ConnectionService,
              private broadcaster: Broadcaster,
              private windowRefService: WindowRefService,
              private conn: ConnectionService,
              private elasticService: ElasticService) {
    this.initializePatternKeys();
    this.connectionService.initialize();
    this.setUpListeners();
    this.reset();
  }

  reset(): void {
    this.logs.splice(0, this.logs.length);
    const user = this.connectionService.getCurrentUser();
    if (!user) {
      return;
    }
    this.startIntervals();
    this.internalUser = { id: user.id, username: user.get('username'), type: user.get('type'), teams: user.get('userType') };
    this.updateActivityStatus(
      this.windowRefService.nativeWindow.document.hasFocus() ? 'ACTIVE' : 'INACTIVE');
  }

  @HostListener('click')
  onClickEvent(): void {
    this.updateActivityStatus('ACTIVE');
  }

  @HostListener('keypress')
  onKeyPressEvent(): void {
    this.updateActivityStatus('ACTIVE');
  }

  @HostListener('wheel')
  onWheelEvent(): void {
    this.updateActivityStatus('ACTIVE');
  }

  @HostListener('window:beforeunload')
  async onBeforeUnloadEvent(): Promise<void> {
    this.updateActivityStatus('INACTIVE');
    this.stopInterval();
    await this.sendDataToAnalytics(true);
  }

  @HostListener('document:visibilitychange', ['$event.target.visibilityState'])
  async onVisibilityChangeEvent(visibilityChange: 'hidden' | 'visible'): Promise<void> {
    if (visibilityChange === 'visible') {
      const { sessionToken }: { sessionToken: string; } = JSON.parse(localStorage.getItem('Parse/myAppId/currentUser'));
      if (Parse.User.current().getSessionToken() !== sessionToken) {
        await ApiConnector.become(sessionToken, { sessionToken });
      }
    }
    this.updateActivityStatus(
      visibilityChange === 'visible' ? 'ACTIVE' : 'INACTIVE');
  }

  @HostListener('window:pagehide')
  onPageHideEvent(): void {
    this.updateActivityStatus('INACTIVE');
  }
  private async sendDataToAnalytics(flushAll?: boolean): Promise<void> {
    if (!this.logs.length) {
      this.sending = false;
      return;
    }
    this.sending = true;
    const batchSize = flushAll
      ? this.logs.length
      : ActivityTrackerDirective.LOG_FLUSH_SIZE;
    const batchLogs = this.logs.splice(0, batchSize);
    try {
      await this.elasticService.logActivity(batchLogs);
      await this.sendDataToAnalytics();
    } catch (error) {
      // eslint-disable-next-line angular/log
      this.logs.unshift(...batchLogs);
      this.sending = false;
    }
  }

  private stopIntervalFlushInterval(): void {
    if (!this.interval.flush) {
      return;
    }
    clearInterval(this.interval.flush);
    delete this.interval.flush;
  }

  private startLogFlushInterval(): void {
    this.stopIntervalFlushInterval();
    this.interval.flush = setInterval(() => {
      if (this.sending) {
        return;
      }
      this.sendDataToAnalytics();
    }, ActivityTrackerDirective.LOG_FLUSH_INTERVAL);
  }

  private setUpListeners(): void {
    this.broadcaster.on<string>('UserLogin')
      .subscribe(() => this.reset());
    this.broadcaster.on<string>('UserLogout')
      .subscribe(() => {
        this.updateActivityStatus('INACTIVE');
        this.sendDataToAnalytics(true);
        delete this.internalUser;
        delete this.user;
      });
    this.broadcaster.on<{ user: any, url: string }>('ChatUserUpdate')
      .subscribe(({ user, url }: { user: any, url: string }) => {
        this.addActivityLog();
        delete this.user;
        this.trackURL = url || this.trackURL;
        if (user) {
          this.user = {
            id: user.id,
            username: user.get('username'),
            durationInDays: moment().diff(user.get('createdAt'), 'day'),
            durationInWeeks: moment().diff(user.get('createdAt'), 'week'),
          };
        }
      });
  }

  private updateActivityStatus(status: ActivityStatus): void {
    const lastActivityTime = localStorage.getItem('lastActivityTime');
    if (lastActivityTime) {
      const delayInMinutes = new Date().getTime() - new Date(lastActivityTime).getTime();
      const rolesToCheck = [
        ...(this.internalUser?.teams || []),
        ...(JSON.parse(localStorage.getItem('userRoles')) || []).map((e: string) => `ROLE_${e}`),
      ];
      const matchingRoleToTime = ActivityTrackerDirective.LOGOUT_CONFIG
        .find((obj: { name: string, logout: number}) => rolesToCheck.includes(obj.name));
      if (matchingRoleToTime) {
        const logoutTimeout = matchingRoleToTime.logout;
        if (delayInMinutes > logoutTimeout) {
          this.logoutUser()
            .then(() => alert('Session terminated due to inactivity.'))
            .catch((error: Error) => alert(error.stack));
        }
      }
    }
    if (status === 'ACTIVE') {
      localStorage.setItem('lastActivityTime', (new Date()).toString());
      this.startInactivityTimeOut();
    }
    if (this.activityStatus === status) {
      return;
    }
    this.activityStatus = status;
    if (this.activityStatus === 'INACTIVE') {
      this.addActivityLog();
      this.stopInactivityTimeOut();
    } else {
      this.lastLogTime = new Date();
    }
  }

  startInactivityTimeOut(): void {
    this.stopInactivityTimeOut();
    this.activityTime = new Date();
    const currentTime = this.activityTime;
    this.inActivityTimeout = setTimeout(() => {
      if (currentTime.getTime() !== this.activityTime.getTime()) {
        return;
      }
      this.updateActivityStatus('INACTIVE');
    }, ActivityTrackerDirective.IN_ACTIVITY_TIMEOUT);
  }

  private startIntervals(): void {
    this.startAddLogsInterval();
    this.startLogFlushInterval();
  }

  private startAddLogsInterval(): void {
    this.stopIntervalAddLogs();
    this.lastLogTime = new Date();
    this.interval.log = setInterval(() => {
      if (this.activityStatus === 'INACTIVE') {
        return;
      }
      this.addActivityLog();
    }, ActivityTrackerDirective.LOG_INTERVAL);
  }

  private stopIntervalAddLogs(): void {
    if (!this.interval.log) {
      return;
    }
    clearInterval(this.interval.log);
    delete this.interval.log;
  }

  private addActivityLog(): void {
    const startTime = this.lastLogTime;
    const endTime = new Date();
    const duration = (endTime.getTime() - startTime.getTime());
    if (this.trackURL && duration) {
      const [params, , group]
        : [{ [key: string]: unknown }, string, string] = this.findParamAndPattern(this.trackURL);
      this.logs.push({
        id: uuid(),
        url: this.trackURL,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        duration,
        user: this.user,
        internalUser: this.internalUser,
        params,
        urlPattern: group,
      });
    }
    this.lastLogTime = endTime;
  }

  private stopInactivityTimeOut(): void {
    if (!this.inActivityTimeout) {
      return;
    }
    clearTimeout(this.inActivityTimeout);
    this.inActivityTimeout = undefined;
  }

  private stopInterval(): void {
    this.stopInactivityTimeOut();
    this.stopIntervalAddLogs();
    this.stopIntervalFlushInterval();
  }

  private initializePatternKeys(): void {
    this.patternKeys.push(...[
      {
        pattern: `^/order/${ActivityTrackerDirective.PATTERN.PARSE_ID}$`,
        group: '/order/:order',
        keys: { orderId: 1 },
        concernTeams: ['cs', 'sales', 'courier'],
      },
      {
        pattern: `^/order/${ActivityTrackerDirective.PATTERN.PARSE_ID}/edit$`,
        group: '/order/:order',
        keys: { orderId: 1 },
        concernTeams: ['courier'],
      },
      {
        pattern: `^/order/${ActivityTrackerDirective.PATTERN.PARSE_ID}/approval`,
        group: '/order/:order/approval',
        keys: { orderId: 1 },
        concernTeams: ['doctor'],
      },
      {
        pattern: `^/order/${ActivityTrackerDirective.PATTERN.ORDER_NUMBER}$`,
        group: '/order/:order',
        keys: { orderNumber: 1 },
        concernTeams: ['cs', 'sales', 'courier'],
      },
      {
        pattern: `^/order/${ActivityTrackerDirective.PATTERN.ORDER_NUMBER}/edit$`,
        group: '/order/:order',
        keys: { orderNumber: 1 },
        concernTeams: ['courier'],
      },
      {
        pattern: `^/order/${ActivityTrackerDirective.PATTERN.ORDER_NUMBER}/approval`,
        group: '/order/:order/approval',
        keys: { orderNumber: 1 },
        concernTeams: ['doctor'],
      },
      {
        pattern: `^/ticket/${ActivityTrackerDirective.PATTERN.PARSE_ID}$`,
        group: '/ticket/:user',
        keys: { userId: 1 },
        queryKeys: { ticketId: 'ticketId' },
        concernTeams: ['cs', 'courier'],
      },
      {
        pattern: `^/ticket/${ActivityTrackerDirective.PATTERN.USERNAME}`,
        group: '/ticket/:user',
        keys: { username: 1 },
        concernTeams: ['doctor'],
        queryKeys: { ticketId: 'ticketId' },
      },
      {
        pattern: '^/calls/pending-calls$',
        group: 'pending-call-list',
        keys: { },
        concernTeams: ['cs', 'sales', 'courier'],
      },
      {
        pattern: '^/chats/open-tickets$',
        group: 'open-tickets-list',
        keys: { },
        concernTeams: ['cs', 'sales', 'courier'],
      },
      {
        pattern: '^/marketing/patientEdit',
        group: '/marketing/patientEdit',
        keys: { },
        concernTeams: ['sales'],
        queryKeys: { username: 'username' },
      },
      {
        pattern: `^/chat/${ActivityTrackerDirective.PATTERN.PARSE_ID}$`,
        group: '/chat/:user',
        keys: { userId: 1 },
        concernTeams: ['cs', 'sales', 'courier', 'doctor'],
      },
      {
        pattern: '^/patient',
        group: '/patient',
        keys: { },
        concernTeams: ['doctor'],
        queryKeys: { username: 'username' },
      },
      {
        pattern: `^/operatorChat/${ActivityTrackerDirective.PATTERN.MOBILE_NUMBER}`,
        group: '/operatorChat/:user',
        keys: { mobileNumber: 1 },
        concernTeams: ['courier'],
      },
      {
        pattern: `^/operatorChat/${ActivityTrackerDirective.PATTERN.PARSE_ID}$`,
        group: '/operatorChat/:user',
        keys: { userId: 1 },
        concernTeams: ['courier'],
      },
      {
        pattern: `^/operatorChat/${ActivityTrackerDirective.PATTERN.USERNAME}`,
        group: '/operatorChat/:user',
        keys: { username: 1 },
        concernTeams: ['courier'],
      },
      {
        pattern: `^/trees/question/${ActivityTrackerDirective.PATTERN.PARSE_ID}$`,
        group: '/trees/question/:tree',
        keys: { questionId: 2 },
        concernTeams: ['-'],
      },
      {
        pattern: `^/trees/question/${ActivityTrackerDirective.PATTERN.PARSE_ID}/edit$`,
        group: '/trees/question/:tree',
        keys: { questionId: 2 },
        concernTeams: ['-'],
      },
      {
        pattern: `^/products/catalog/${ActivityTrackerDirective.PATTERN.PARSE_ID}$`,
        group: '/products/catalog/:catalog',
        keys: {
          catalogId: 2,
        },
        concernTeams: ['-'],
      },
      {
        pattern: `^/products/catalog/${ActivityTrackerDirective.PATTERN.PARSE_ID}/edit$`,
        group: '/products/catalog/:catalog',
        keys: {
          catalogId: 2,
        },
        concernTeams: ['-'],
      },
      {
        pattern: `^/products/regimen/${ActivityTrackerDirective.PATTERN.REGIMEN_ID}$`,
        group: '/products/regimen/:regimen',
        keys: { regimenId: 2 },
        concernTeams: ['-'],
      },
      {
        pattern: `^/products/regimen/${ActivityTrackerDirective.PATTERN.REGIMEN_ID}/edit$`,
        group: '/products/regimen/:regimen',
        keys: { regimenId: 2 },
        concernTeams: ['-'],
      },
      {
        pattern: `^/compare/${ActivityTrackerDirective.PATTERN.PARSE_ID}/${
          ActivityTrackerDirective.PATTERN.PARSE_ID}/${ActivityTrackerDirective.PATTERN.USERNAME}$`,
        group: '/compare/:instantCheckup1/:instantCheckup2/:user',
        keys: {
          instantCheckupIdOne: 1,
          instantCheckupIdTwo: 2,
          username: 3,
        },
        concernTeams: ['-'],
      },
      {
        pattern: `^/languageString/${ActivityTrackerDirective.PATTERN.PARSE_ID}$`,
        group: '/languageString/:languageString',
        keys: {
          languageStringId: 1,
        },
        concernTeams: ['-'],
      },
      {
        pattern: `^/languageString/${ActivityTrackerDirective.PATTERN.PARSE_ID}/edit$`,
        group: '/languageString/:languageString',
        keys: {
          languageStringId: 1,
        },
        concernTeams: ['-'],
      },
      {
        pattern: `^/ecom/coupons/${ActivityTrackerDirective.PATTERN.PARSE_ID}/edit$`,
        group: '/ecom/coupons/:coupon',
        keys: {
          couponId: 2,
        },
        concernTeams: ['-'],
      },
      {
        pattern: `^/user/instantCheckup/${ActivityTrackerDirective.PATTERN.PARSE_ID}$`,
        group: '/user/instantCheckup/:instantCheckup',
        keys: {
          instantCheckUpId: 2,
        },
        concernTeams: ['-'],
      },
    ]);
  }

  private removeAllRegexPattern(input: string, replaceValues: Array<string>): string {
    const replaceValue = replaceValues.shift();
    if (!replaceValue) {
      return input;
    }
    if (replaceValue === 'orderNumber') {
      return this.removeAllRegexPattern(
        input.replace(ActivityTrackerDirective.PATTERN.ORDER_NUMBER, `:${replaceValue}`),
        replaceValues);
    }
    if (replaceValue === 'regimenId') {
      return this.removeAllRegexPattern(
        input.replace(ActivityTrackerDirective.PATTERN.REGIMEN_ID, `:${replaceValue}`),
        replaceValues);
    }
    if (replaceValue === 'mobileNumber') {
      return this.removeAllRegexPattern(
        input.replace(ActivityTrackerDirective.PATTERN.MOBILE_NUMBER, `:${replaceValue}`),
        replaceValues);
    }
    if (replaceValue === 'username') {
      return this.removeAllRegexPattern(
        input.replace(ActivityTrackerDirective.PATTERN.USERNAME, `:${replaceValue}`),
        replaceValues);
    }
    return this.removeAllRegexPattern(
      input.replace(ActivityTrackerDirective.PATTERN.PARSE_ID, `:${replaceValue}`),
      replaceValues);
  }

  private findParamAndPattern(trackURL: string): [{ [key: string]: unknown }, string, string] {
    const pathAndQueryParams = trackURL.split('?');
    const path = pathAndQueryParams[0];
    const queryParams = pathAndQueryParams[1];
    const patternKey: PatternKey = this.patternKeys.find(({ pattern }: PatternKey) => path.match(pattern));
    if (!patternKey) {
      return [{}, 'no-tracking', 'no-tracking'];
    }
    const params: { [key: string]: unknown } = {};
    const keyEntries = Object.entries(patternKey.keys);
    const patternNames: Array<string> = [];
    if (keyEntries.length) {
      const URLpatterns = path.match(/[a-zA-Z0-9_-]{1,}/g);
      keyEntries.sort(([, value1]: [string, number], [, value2]: [string, number]) => {
        if (value1 === value2) {
          return 0;
        }
        if (value1 > value2) {
          return 1;
        }
        return -1;
      });
      keyEntries.forEach(([key, index]: [string, number]) => {
        patternNames.push(key);
        params[key] = URLpatterns[index];
      });
    }
    if (queryParams) {
      const queryParamspatterns = queryParams.match(/[a-zA-Z0-9_-]{1,}/g);
      queryParamspatterns.forEach((key: string, index: number) => {
        if (!(index & 1)) params[key] = queryParamspatterns[index + 1];
      });
    }
    return [
      params,
      this.removeAllRegexPattern(
        patternKey.pattern.replace('^', '').replace('$', ''),
        patternNames),
      patternKey.group,
    ];
  }

  private async logoutUser(): Promise<void> {
    await this.conn.logout();
    this.windowRefService.nativeWindow.location.reload();
    localStorage.removeItem('lastActivityTime');
    this.updateActivityStatus('INACTIVE');
  }
}
