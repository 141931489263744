import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, UntypedFormControl, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { ApiClientConstant, RequestQueryPayload, Table } from 'api-client';
import { RegimenType } from '../../../../../../../typings/client/regimen';
import { ConnectionService } from '../../../../../../services/connection-service';
import { InputType } from '../../../../../../../typings/client/input';
import { AppConfig } from '../../../../../app.config';
import { LocalStorage } from '../../../../../../services/local-storage-service';

@Component({
  selector: 'regimen-edit-product',
  templateUrl: './regimen-edit-product.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class RegimenEditProductComponent {
  userRoles: Array<string> = [];
  @Input('name') name: string;
  @Input('isProductAdd') isProductAdd: boolean;
  @Input('type') type: string;
  @Input('onProductChange') onProductChange: boolean;
  @Input('regimenProducts') regimenProducts: any;
  @Input('partOfDay') partOfDay: any;
  @Input('isFollowUp') isFollowUp: boolean = false;
  @Input('hideNotNecessaryThings') hideNotNecessaryThings: boolean;
  @Input('product')
  set onUpdateProduct(product: RegimenType.Product) {
    this.skipUpdate = !this.product;
    this.product = product;
    if (!this.product) return;
    this.autoCompleteProductController.setValue(this.product.productName);
    this.updateProductData();
  }
  @Output('onProductUpdate') onProductUpdate: EventEmitter<any> = new EventEmitter();

  skipUpdate: boolean;
  product: RegimenType.Product;
  productInstructions: Array<InputType.SelectOption> = [];
  autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private localStorage: LocalStorage) {}

  async ngOnInit(): Promise<any> {
    this.userRoles = this.localStorage.getJsonValue('userRoles') || [];
    this.productOptions = this.autoCompleteProductController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getProducts(token)));
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    let products: any;
    if (this.type === 'removeProduct' || this.type === 'changeInstruction') {
      products = this.regimenProducts;
    } else {
      const payload: RequestQueryPayload<Table.Catalog> = {
        where: {
          title: { $regex: name, $options: 'i' },
          inventoryStatus: [
            ApiClientConstant.Catalog.InventoryStatus.AVAILABLE,
            ApiClientConstant.Catalog.InventoryStatus.UNAVAILABLE,
            ApiClientConstant.Catalog.InventoryStatus.RESTRICTED,
          ],
        },
        limit: 10,
        include: ['mainProduct'],
        ascending: 'margUnit',
      };
      if (!this.userRoles.includes('adminDoctor')) {
        payload.where.mrp = { $ne: 0 };
      }
      products = await this.conn.findCatalogs(payload);
    }
    return products?.map((object: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(object),
      object,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  async autoCompleteProductOnSelect(item: { name: string; object: any }): Promise<any> {
    this.product.productName = item.name;
    this.product.product = item.object;
    this.product.updated = true;
    this.product.purposeLanguageString = this.product.product.get('mainProduct').get('purposeLanguageString');
    this.product.purposeDescriptionLanguageString = this.product.product.get('mainProduct').get('purposeDescriptionLanguageString');
    if (!this.skipUpdate) {
      this.onProductUpdate.emit();
    }
    if (this.onProductChange) {
      this.onProductUpdate.emit();
    }
    this.skipUpdate = false;
    delete this.product.instructionSet;
    this.updateLanguageSelections();
  }

  private async updateLanguageSelections(skipAutoSelect?: boolean): Promise<void> {
    let mainProduct = this.product.product.get('mainProduct');
    if (!mainProduct.get('instructions')) {
      await this.product.product.get('mainProduct').fetch();
      mainProduct = this.product.product.get('mainProduct');
    }
    let instructions = mainProduct.get('instructions') || [];
    if (this.partOfDay) {
      instructions = instructions.filter((instruction: any): boolean => instruction?.partOfDay === this.partOfDay?.toUpperCase());
    }
    this.productInstructions = instructions.map((instruction: any): InputType.SelectOption => ({
      display: instruction.title,
      value: instruction.id,
      parseValue: instruction,
    }));

    if (!this.productInstructions.length) {
      delete this.product.instructionSet;
    } else if (!skipAutoSelect && !this.product.instructionSet && this.productInstructions.length) {
      this.product.purposeDescriptionLanguageString = this.product.product.get('mainProduct').get('purposeDescriptionLanguageString');
    }
    const { product }: any = this;
    delete this.product;
    setTimeout(() => (this.product = product), 0);
  }

  private async updateProductData(): Promise<any> {
    if (!this.product.product) return;
    if (!this.product.product.get('mainProduct')) {
      await this.product.product.fetch();
    }
    if (!this.product.product.get('mainProduct').get('title')) {
      await this.product.product.get('mainProduct').fetch();
    }
    this.autoCompleteProductController.setValue(this.getProductDisplayName(this.product.product));
    this.updateLanguageSelections(true);
  }

  showAlertToChangeInstruction(): void {
    alert('For Hold Products Use the Hold Instruction');
    this.onProductUpdate.emit();
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
