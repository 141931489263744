import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LanguageStringSelectionWithTagComponent } from './language-string-selection-with-tag.component';
import { LanguageStringSelectionModule } from '../language-string-selection';
import { LanguageStringTagSelectionModule } from '../language-string-tag-selection';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LanguageStringSelectionModule,
    LanguageStringTagSelectionModule,
  ],
  declarations: [LanguageStringSelectionWithTagComponent],
  exports: [LanguageStringSelectionWithTagComponent],
})
export class LanguageStringSelectionWithTagModule {
}
