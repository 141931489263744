import { Component, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { addDays, addHours, differenceInDays, isAfter, startOfDay } from 'date-fns';

@Component({
  selector: 'dashboard-order-created',
  templateUrl: './order-created-information.html',
  styleUrls: ['../dashboard.scss'],
})

export class DashboardOrderCreatedComponent {
  orderCreatedByDoctor: Array<any> = [];
  totalOrderCreated: number = 0;
  totalDeliveredOrder: number = 0;
  minOrderToCreate: number = 0;
  diffInDays: number = 0;
  dataItems: Array<{ title: string, value: number }> = [];
  user: Table.User;
  startDate: Date;
  endDate: Date;

  @Input('userDetails')
  set updateStartDate(userDetails: { startDate: Date, endDate: Date, user: Table.User }) {
    this.startDate = addHours(startOfDay(userDetails.startDate), 6);
    this.endDate = addHours(startOfDay(userDetails.endDate), 6);
    this.user = userDetails.user;
    this.getOrdersCreatedDetails();
  }
  constructor() { }

  async getOrdersCreatedByDoctors(): Promise<void> {
    const orderCreatedByDoctor = await ApiConnector.find(Table.Order, {
      where: {
        createdAt: { $gte: this.startDate, $lte: this.endDate },
        createdBy: this.user,
      },
      project: ['stage'],
    });
    this.totalOrderCreated = orderCreatedByDoctor.length;
    this.totalDeliveredOrder = orderCreatedByDoctor
      .filter((each: any) => each.get('stage') === ApiClientConstant.Order.Stage.DELIVERED).length;
  }

  async getOrdersCreatedDetails(): Promise<void> {
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.dataItems = [];
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.dataItems = [];
    if (isAfter(this.startDate, this.endDate)) {
      return;
    }
    this.diffInDays = differenceInDays(this.endDate, this.startDate);
    this.minOrderToCreate = 26;
    if ((this.user.get('userType') || []).includes('MBBS')) {
      this.minOrderToCreate = 49;
    }
    await this.getOrdersCreatedByDoctors();
    this.dataItems.push(
      { title: 'Created Today', value: this.totalOrderCreated },
      { title: 'Delivered Order', value: this.totalDeliveredOrder },
    );
  }
}
