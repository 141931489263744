import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({ selector: 'stagePopup', templateUrl: './stagePopup.html' })

export class StagePopupModal {
  newStage: any;
  constructor(private dialogRef: MatDialogRef<StagePopupModal>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.newStage = this.data;
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  createNewStage(): void {
    this.dialogRef.close(this.newStage);
  }
}
