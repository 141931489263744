import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'label-filter',
  templateUrl: './label-filter.html',
})

export class LabelFilterComponent {
  @Output() updatedLabelList: EventEmitter<any> = new EventEmitter();
  @Input() set reset(status: boolean) {
    this.resetting = status;
    if (status) this.resetSelection();
  }
  @Input() set defaultLabels(names: Array<string>) {
    if (!names?.length) return;
    this.names = names;
  }
  rows: Array<{ name: any, selected?: boolean }> = [];
  currentUser: any;
  resetting: boolean = false;
  labels: Array<any> = [];
  names: Array<any> = [];
  constructor(private conn: ConnectionService, private zone: NgZone) {
  }

  async ngOnInit(): Promise<any> {
    if (!this.names?.length) {
      this.labels = await this.conn.getLabels();
      this.names = this.labels.map((each: any) => each.get('name'));
    }
    this.rows = this.names.map((name: any) => ({ name, selected: false }));
    this.sendUpdatedList();
  }

  sendUpdatedList(): void {
    if (this.resetting) {
      return;
    }
    this.updatedLabelList.emit(this.rows.filter(({ selected }: any) => selected).map(({ name }: any) => name));
  }

  toggleSelection(r: { name: any, selected: boolean }): any {
    const row = r;
    row.selected = !row.selected;
    this.sendUpdatedList();
  }

  resetSelection(): void {
    this.zone.run(() => (this.rows = this.names
      .map((name: any) => ({ name, selected: this.currentUser.get('label').includes(name) }))));
    this.resetting = false;
    this.sendUpdatedList();
  }
}
