export interface ImageConfig {
  instantCheckup: any;
  compressedImage: boolean;
  width: string;
  height: string;
  left: number;
  top: number;
}

export interface LandmarkInterface {
  LEFT_EYE: { x: number; y: number };
  RIGHT_EYE: { x: number; y: number };
  MIDPOINT_BETWEEN_EYES?: { x: number; y: number };
  NOSE_TIP?: { x: number; y: number };
  MOUTH_CENTER?: { x: number; y: number };
}

export interface InstantCheckupInterface {
  objectId: string;
  compressedImagePath?: string;
  imagePath: string;
  aiResponse: {
    FaceDetected?: boolean,
    faceRatio?: number,
    faceBox?: number[],
    imageWidth?: number,
    imageHeight?: number,
    rotationNeeded?: string,
    googleResponse: {
      faceAnnotations: {
        boundingPoly?: any,
        landmarks: LandmarkInterface,
      }[]
    },
    bucketPublicUrl?: {
      object_url: string,
    }
  };
}
