import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AddSectionComponent } from './add-section/add-section.component';
import { AppConfig } from '../../../app.config';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Broadcaster } from '../../../../components/broadcaster';
import { SearchSupportQuestionComponent } from '../../../../components/search-support-question/search-support-question.component';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent implements OnDestroy {
  subscriptions: Array<Subscription> = [];
  editId: string;
  questionCategoryId: string;
  subCategoryId: string;
  supportQuestion: any;
  tables: string[];
  isDeveloper: boolean;
  displayComponents: any[];
  outputTypes: any[] = [{ value: 'COMPONENT', display: 'COMPONENT' }, { value: 'URL', display: 'URL' }];
  listViewType: any[] = [{ value: 'Expand', display: 'DropDown' }, { value: 'Next', display: 'New Page' }];
  responseJson: any[] = [];
  loading: boolean;
  teams: Array<Record<string, string>> = [];
  queryFunctionType: string[] = Object.keys(this.appConfig.Shared.Support.Queries.Type)
    .map((key: string) => this.appConfig.Shared.Support.Queries.Type[key]);

  constructor(private conn: ConnectionService,
    private document: WindowRefService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public appConfig: AppConfig,
    private localStorage: LocalStorage,
    private broadcaster: Broadcaster) {}

  async ngOnInit(): Promise<any> {
    this.supportQuestion = new Table.SupportQuestion();
    this.supportQuestion.set('conditions', []);
    this.fillDisplayComponents();
    await this.findOrCreateQuestion();
    this.tables = Object.values(this.appConfig.Shared.Table);
    const roles = this.localStorage.getJsonValue('userRoles') || [];
    this.isDeveloper = roles.includes(this.appConfig.Shared.Role.Name.DEVELOPER);
    this.teams = Object.keys(this.appConfig.Shared.Role.Name)
      .map((each: string) => ({ value: this.appConfig.Shared.Role.Name[each], display: each }));
  }

  async findOrCreateQuestion(): Promise<void> {
    const supportQuestionId = this.route.snapshot.params.id;
    if (!supportQuestionId) {
      return;
    }
    [this.supportQuestion] = await this.conn.fetchSupportQuestions({ where: { objectId: supportQuestionId } });
    if (!this.supportQuestion.has('conditions')) {
      this.supportQuestion.set('conditions', []);
    }
    this.questionCategoryId = this.supportQuestion.get('category')?.id;
    this.subCategoryId = this.supportQuestion.get('subCategory')?.id;
    this.responseJson = Object.keys(this.supportQuestion.get('responseJson') || {})
      .map((each: string): any => ({ key: each, value: this.supportQuestion.get('responseJson')[each].toString() }));
  }

  updateQuestionConditions(): void {
    this.supportQuestion.set('conditions', this.supportQuestion.get('conditions'));
  }

  addNewSection(): void {
    this.dialog.open(AddSectionComponent, { data: {} });
  }

  addResponseKeys(): void {
    this.responseJson.push({ key: '', value: '' });
  }

  removeResponseKeys(index: number): void {
    this.responseJson.splice(index, 1);
  }

  addQuery(): void {
    const queries = this.supportQuestion.get('queries') || [];
    queries.push({ name: '' });
    this.supportQuestion.set('queries', queries);
  }

  removeQuery(index: number): void {
    this.supportQuestion.get('queries').splice(index, 1);
  }

  addInputs(): void {
    const input = prompt('Enter input name');
    if (!input) {
      return;
    }
    if (!/^[a-zA-Z0-9]*$/.test(input)) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Only a-zA-Z0-9 supported.', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    this.supportQuestion.addUnique('inputs', input);
  }

  removeInputs(index: number): void {
    this.supportQuestion.get('inputs').splice(index, 1);
  }

  async saveQuestion(): Promise<void> {
    this.loading = true;
    const questionCategory = new Table.SupportCategory();
    questionCategory.id = this.questionCategoryId;
    this.supportQuestion.set('category', questionCategory);
    this.setSubCategory();
    const responseJson = {};
    let invalidResponse: boolean = false;
    const queryNames: string[] = (this.supportQuestion.get('queries') || []).map((each: any): string => each.name);
    this.responseJson.forEach((each: { [key: string]: any }): void => {
      const valuesArr: string[] = each.value.split(',').map((eachValue: string): string => eachValue.trim());
      valuesArr.forEach((eachValue: string): void => {
        if (!invalidResponse && !queryNames.includes(eachValue.split('.')[0])) {
          invalidResponse = true;
        }
      });
      responseJson[each.key] = valuesArr;
    });
    if (invalidResponse) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Invalid Response json. Accessor present in value is wrong.',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.loading = false;
      return;
    }
    this.supportQuestion.set('responseJson', responseJson);
    try {
      await this.supportQuestion.save();
    } finally {
      this.loading = false;
    }
    this.router.navigate(['/marketing/support']);
  }

  setSubCategory(): void {
    if (this.supportQuestion.get('outputComponent') !== this.appConfig.Shared.Support.Component.SELECT_SUB_QUERY) return;
    const subQuestionCategory = new Table.SupportCategory();
    subQuestionCategory.id = this.subCategoryId;
    this.supportQuestion.set('subCategory', subQuestionCategory);
  }

  fillDisplayComponents(): void {
    this.displayComponents = Object.keys(this.appConfig.Shared.Support.Component)
      .map((each: string): { [key: string]: unknown } => ({ value: each, display: each }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription) => each.unsubscribe());
    this.subscriptions = [];
  }

  addParentQuestion(): void {
    const dialogRef = this.dialog.open(SearchSupportQuestionComponent, {
      panelClass: 'w-full',
      data: { excludeIds: (this.supportQuestion.get('parentSupportQuestions') || []).map((each: any) => each.id) },
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((question: any) => {
      if (!question) {
        return;
      }
      const parentSupportQuestions = this.supportQuestion.get('parentSupportQuestions') || [];
      parentSupportQuestions.push(question);
      this.supportQuestion.set('parentSupportQuestions', parentSupportQuestions);
    }));
  }
}
