import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { OrderRefundTrackingComponent } from './order-refund-tracking.component';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputTextModule } from '../../../../components/input/input-text';

@NgModule({
  imports: [CommonModule, FormsModule, MomentModule, InputSelectModule, InputTextModule],
  declarations: [OrderRefundTrackingComponent],
  exports: [OrderRefundTrackingComponent],
})
export class OrderRefundTrackingModule {}
