import * as moment from 'moment';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { FileUploader } from 'ng2-file-upload';
import { ApiClientConstant, ParseKeys, RequestQueryPayload, Table } from 'api-client';
import { FilterType } from '../../../../typings/client/filter';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { WindowRefService } from '../../../services/window-ref-service';
import { FilterConstants } from '../../../components/filter-dropdown/filter-constants';
import { ChangeAllocatedDoctor } from '../../../components/change-allocated-doctor';
import { LocalStorage } from '../../../services/local-storage-service';
import { Broadcaster } from '../../../components/broadcaster';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  partialOrder: boolean = false;
  resetLanguageFilter: boolean = false;
  userService: Array<string> = [];
  languageFilter: Array<string> = [];
  orders: Array<any>;
  window: any;
  filters: any = [{}];
  dropdownFilter: any[] = [];
  components: any;
  Orders: any;
  ui: any;
  url: any;
  columnDefs: any;
  filterConfig: Array<FilterType.FilterConfigType>;
  uploader: FileUploader;
  OrderAsc: boolean = false;
  a: any;
  count: number;
  doctorFilter: Array<any> = [];
  searchKey: any;
  isDoctor: boolean;
  resetDoctorFilter: boolean = false;
  startDate: Date;
  endDate: Date;
  dietServices:Array<any>;
  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    public windowRef: WindowRefService,
    private dialog: MatDialog,
    private storage: LocalStorage,
    private broadcaster: Broadcaster,
    private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.startDate = moment().subtract(2, 'months').startOf('day').toDate();
    this.endDate = moment().endOf('day').toDate();
    this.userService = this.storage.getValue('userRoles');
    this.isDoctor = this.conn.getCurrentUser().get('type') === 'doctor';
    this.OrderAsc = false;
    this.ui = { isFilterOpen: false, grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.filterConfig = [
      { key: 'stage', type: FilterConstants.DATA_TYPE.FIXED, value: Object.keys(ApiClientConstant.Order.Stage) },
      { key: 'paymentType', type: FilterConstants.DATA_TYPE.FIXED, value: Object.keys(ApiClientConstant.Order.PaymentType) },
      { key: 'type', type: FilterConstants.DATA_TYPE.FIXED, value: Object.keys(ApiClientConstant.Order.Type) },
      { key: 'alternateNumber', type: FilterConstants.DATA_TYPE.STRING },
      { key: 'isPartialOrder', type: FilterConstants.DATA_TYPE.BOOLEAN }];
    this.setUpGrid();
    this.resetFilters();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Mod',
      field: 'user.mod11',
      cellRenderer: (params: any): any => {
        if (!params.data) {
          return '';
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = Math.abs(params.value);
        return eDiv;
      },
      width: 70,
      filter: true,
      pinned: 'left',
      editable: true,
    }, {
      headerName: 'Order No.',
      field: 'orderNumber',
      pinned: 'left',
      cellRenderer: (params: any): any => {
        if (!params.data) {
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const eDiv = this.window.document.createElement('div');
        const id = params.value || params.data.objectId;
        let highlightCssClass;
        let title = '';
        if (params.data.regimenId?.startsWith('v4_999') || params.data.regimen?.optedForDoctorCall) {
          highlightCssClass = 'list-label-bg-red';
          title = 'Doctor call needed';
        }
        if (params.data.regimen?.optedForDoctorCall === false) {
          highlightCssClass = 'list-label-bg-yellow';
          title = 'Voice note needed';
        }
        const link = this.isDoctor && params.data.stage === ApiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING
          ? `/order/${id}/approval`
          : `/order/${id}`;
        eDiv.innerHTML = `<div class='list-label ${highlightCssClass}' title='${title}'><a class="p-0 ellipsis mLR5" target="_blank"
          href='${link}'>${id}</a></div>`;
        return eDiv;
      },
      width: 110,
      filter: true,
    }, {
      headerName: 'Patient Name',
      field: 'user.PatientName',
      cellRenderer: (params: any): any => {
        if (!params.data) {
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<a class='col-xs-12 p-0 ellipsis' target="_blank" href='/chat/${params.data.user.objectId}'>${
          params.value || ''}</a>`;
        return eDiv;
      },
      width: 150,
    }, {
      headerName: 'Allocated Doctor',
      field: 'allocatedDoctor',
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.addEventListener('click', async () => {
          const order = await this.conn.getOrder(params.data.objectId);
          const dialogRef = this.dialog.open(ChangeAllocatedDoctor,
            { data: { parseObject: order, allocatedDoctor: params.value, type: this.appConfig.Shared.AllocatedDoctorPopup.Type.ORDER } });
          dialogRef.afterClosed().subscribe(async (data: any): Promise<any> => {
            if (!data) return;
            this.broadcaster.broadcast('NOTIFY', { message: 'Order Allocated Successfully',
              type: this.appConfig.Shared.Toast.Type.SUCCESS });
            this.reset();
          });
        });
        eDiv.innerHTML = '&nbsp;&nbsp&nbsp';
        eDiv.innerHTML += params.value.DoctorDisplayName;
        eDiv.className = 'fa fa-pencil-square-o pr-3';
        return eDiv;
      },
      width: 200,
      hide: !this.userService.includes('adminDoctor'),
    }, {
      headerName: 'Created On',
      field: 'createdAt',
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('MMM DD,hh:mm a');
        return eDiv;
      },
      width: 120,
      sortable: true,
    }, {
      headerName: 'Order Type',
      field: 'type',
      width: 130,
      cellRenderer: (params: any): any => {
        if (!params.data) {
          return '';
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<div class="ellipsis pl-2">${params.data.type || ''}</div>`;
        return eDiv;
      },
      filter: true,
    }, {
      headerName: 'City',
      field: 'contactDetail',
      width: 100,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = params.value.city;
        return eDiv;
      },
      filter: true,
    }, {
      headerName: 'Recent Note',
      field: 'notes',
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.className = 'ellipsis';
        const notes = params.value || [];
        const recentNote = (notes[notes.length - 1] || { message: '-' }).message;
        eDiv.innerHTML = recentNote;
        return eDiv;
      },
      width: 200,
    }, {
      headerName: 'Phone Number',
      field: 'contactNumber',
      width: 150,
      filter: true,
      editable: true,
    }, {
      headerName: 'Stage',
      field: 'stage',
      filter: true,
      filterOptions: {
        values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      },
      cellRenderer: (params: any): any => {
        let markRed = false;
        if (params.data) {
          markRed = (ApiClientConstant.Order.CompletedStages.includes(params.value)
              && (moment().diff(params.data.updatedAt, 'days') >= 2))
            || ([ApiClientConstant.Order.Stage.SHIPPED].includes(params.value)
              && (moment().diff(params.data.updatedAt, 'days') > 5));
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<div class="${markRed ? 'bg-danger' : ''} ellipsis">${params.value || ''}</div>`;
        return eDiv;
      },
      width: 200,
    }, {
      headerName: 'Payment Type',
      field: 'paymentType',
      width: 130,
      filter: true,
    }, {
      headerName: 'Last Updated On',
      field: 'updatedAt',
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('MMM DD,hh:mm a');
        return eDiv;
      },
      width: 120,
    }];
  }

  resetFilters(): void {
    this.route.queryParams.subscribe((params: any) => {
      delete this.searchKey;
      this.filters = Object.keys(params)
        .filter((key: string) => ['stage', 'paymentType', 'type'].includes(key))
        .map((key: string) => ({ key, filterType: FilterConstants.FILTER_TYPE.EQUAL_TO, value: params[key].split(',') }));
      this.resetDoctorFilter = true;
      this.reset();
    });
  }

  async loadMore(params: any): Promise<any> {
    const searchOn = ['contactName', 'contactDetail.city', 'contactNumber'];
    const requestPayload: RequestQueryPayload<Table.Order> = {
      where: {
        createdAt: { $gt: this.startDate, $lt: this.endDate },
      },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['user', 'allocatedDoctor', 'regimen'],
      project: [
        'contactNumber',
        'orderNumber',
        'contactDetail.city' as 'contactDetail',
        'stage',
        'paymentType',
        'notes',
        'deliveryAddress',
        'contactName',
        'user.PatientName' as 'user',
        'user.mod11' as 'user',
        'type',
        'allocatedDoctor.DoctorDisplayName' as 'allocatedDoctor',
        'regimenId',
        'isPartialOrder',
        'regimen',
        'serviceInfo',
      ],
    };
    let alternateNumber;
    const { colId, sort }: { colId: ParseKeys<Table.Order>, sort: 'asc' | 'desc' } = params.sortModel[0]
      || { colId: 'createdAt', sort: 'desc' };
    if (sort === 'desc') {
      requestPayload.descending = colId;
    } else {
      requestPayload.ascending = colId;
    }
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    this.filters.forEach((item: any) => {
      if (item.key !== 'alternateNumber') {
        requestPayload.where[item.key] = item.key === 'isPartialOrder' ? item.booleanValue : item.value;
      } else alternateNumber = item.value;
    });
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((key: any) => {
        if (key === 'contactDetail') {
          requestPayload.where[`${key}.city`] = params.filterModel[key].filter;
        } else {
          requestPayload.where[key] = params.filterModel[key].filter;
        }
      });
    }
    if (this.doctorFilter && this.doctorFilter.length) {
      requestPayload.where.allocatedDoctor = this.doctorFilter;
    }
    if (this.partialOrder) {
      requestPayload.where.isPartialOrder = true;
    }
    if (alternateNumber) {
      const user = await this.conn.getUserByAlternateNumber(alternateNumber);
      if (user) requestPayload.where.contactNumber = user.get('MobileNumber');
    }
    const payloadStage:any = requestPayload.where.stage || [];
    if (payloadStage.includes('CONSULTATION_CREATED')) {
      const services = await this.findDietServicesLocally();
      requestPayload.where.services = { $in: services };
    }
    if (!requestPayload.where.stage) {
      this.broadcaster.broadcast('NOTIFY', { message: 'stage missing',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return [];
    }
    const data = await this.conn.findOrders(requestPayload).then((result: Array<any>) => result.map((each: any) => each.toJSON()));
    this.count = 0;
    this.conn.countOrders(requestPayload).then((count: number) => this.count = count);
    return data;
  }

  async findDietServicesLocally(): Promise<Array<any>> {
    if (!this.dietServices) {
      this.dietServices = await this.conn.getServices({
        type: ApiClientConstant.Service.Type.DIET_CONSULTATION,
        ascending: 'createdAt',
      });
    }
    return this.dietServices;
  }

  applyFilter(data: any): void {
    this.filters = data;
    this.reset();
  }

  changeDate(event: any, type: 'startDate' | 'endDate'): void {
    if (type === 'startDate') {
      this.startDate = event.target.valueAsDate;
    } else {
      this.endDate = event.target.valueAsDate;
    }
    this.reset();
  }

  reset(doctorFilter?: Array<any>): void {
    if (!this.gridApi) return;
    if (doctorFilter) {
      this.doctorFilter = doctorFilter;
      this.resetDoctorFilter = false;
    }
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else if (this.autoRefreshInterval) clearInterval(this.autoRefreshInterval);
  }

  togglePartialOrder(): void {
    this.reset();
  }

  updateLanguageFilter(languageFilter: Array<any>): void {
    this.resetLanguageFilter = false;
    this.languageFilter = languageFilter;
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.orders;
    delete this.filters;
    delete this.ui;
  }
}
