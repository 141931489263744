import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { FollowUpReportCreationComponent } from './followUp-report-creation.component';
import { AddConcernModal } from './add-concern-popup';
import { InputSelectTextModule } from '../input/input-select-text';
import { DirectivesModule } from '../directives.module';
import { InstantCheckupImageView } from '../instant-checkup-image-view';
import { ImageComparisonPopup } from '../image-comparison-popup';
import { AutoFaceModule } from '../auto-face/auto-face.module';
import { ComparisonViewerModule } from '../comparison-viewer';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MomentModule,
    MatSelectModule,
    MatDialogModule,
    InputSelectTextModule,
    DirectivesModule,
    AutoFaceModule,
    ComparisonViewerModule,
  ],
  declarations: [FollowUpReportCreationComponent, AddConcernModal, InstantCheckupImageView, ImageComparisonPopup],
  exports: [FollowUpReportCreationComponent],
})
export class FollowUpReportCreationModule {
}
