
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">Trees<i class="fa fa-plus-square-o m-l-xs pointer" routerLink="new"></i></div>
  <div class="flex-content">
    <div class="col-xs-12">
      <div class="col-xs-12 flex" *ngFor="let tree of trees">
        <div class="col-xs-8 flex">
          <div class="text-info pointer" routerLink="../tree/{{tree.get('name')}}">{{tree.get('name')}}</div><i class="fa fa-pencil-square-o m-l-xs pointer" routerLink="../tree/{{tree.get('name')}}/edit"></i>
        </div>
      </div>
    </div>
  </div>
</div>