import { NgZone, Component, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';

@Component({
  selector: 'waitlist',
  templateUrl: './waitlist.html',
  styleUrls: ['./waitlist.scss'],
})
export class WaitlistComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  searchKey: string;

  // Local Variables
  data: Array<any> = [];
  operatorFilter: Array<any> = [];
  doctorFilter: Array<any> = [];
  teams: Array<string> = [];
  currentUser: any;
  resetOperatorFilter: boolean = false;
  resetDoctorFilter: boolean = false;
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  queueCount: number = 0;
  static parameters: any = [Router, ConnectionService, NgZone, AppConfig, WindowRefService, ActivatedRoute, LocalStorage];
  constructor(
    private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.listViewSetup();
    const roles = this.storage.getJsonValue('userRoles') || [];
    const teams = Object.keys(ApiClientConstant.Role.Name)
      .map((each: string) => ApiClientConstant.Role.Name[each]);
    this.teams = roles.filter((each: any) => teams.includes(each));
    this.resetFilters();
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  findUserPriorityClasses(user: any): string {
    if (!user) {
      return '';
    }
    const orderState = user.orderState || ApiClientConstant.User.OrderState.NEW_USER;
    if (orderState === ApiClientConstant.User.OrderState.NEW_USER) {
      return '';
    }
    if (orderState === ApiClientConstant.User.OrderState.PROCESSING) {
      return 'fa fa-rupee mLR5 text-success';
    }
    if (orderState === ApiClientConstant.User.OrderState.CANCELED) {
      return 'fa fa-rupee mLR5 text-danger';
    }
    if (orderState === ApiClientConstant.User.OrderState.DELIVERED && user.refreshDate) {
      return moment(user.refreshDate.iso).isAfter(moment().subtract(4, 'month'))
        ? 'fa fa-rupee mLR5 text-success'
        : 'fa fa-rupee mLR5 text-warning';
    }
    return '';
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'UserId',
        field: 'user.username',
        pinned: 'left',
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          let eDiv;
          url.splice(url.length - 1, 1);
          if (!params.data) {
            eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = 'Loading ...';
            return eDiv;
          }
          eDiv = this.window.document.createElement('div');
          const className = this.findUserPriorityClasses(params.data.user);
          const id = params.value;
          eDiv.innerHTML = `<span class='${className}' ></span></span><a href='/chat/${id}' target='_blank'>${
            id} (${this.conn.getUserHash(id)})</a>`;
          return eDiv;
        },
        width: 270,
      },
      {
        headerName: 'Description',
        field: 'description',
        width: 120,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<span>${params.value || ''}</span>`;
          return eDiv;
        },
        sortable: true,
      },
      {
        headerName: 'updatedAt',
        field: 'user.updatedAt',
        width: 150,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<span> ${moment(params.value).fromNow()} </span>`;
          return eDiv;
        },
      },
      {
        headerName: 'Tagged By',
        field: 'taggedBy.username',
        width: 100,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<span> ${params.value} </span>`;
          return eDiv;
        },
        sortable: false,
      },
      {
        headerName: 'Doctor',
        field: 'taggedTo.username',
        width: 100,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<span> ${params.value} </span>`;
          return eDiv;
        },
        sortable: false,
      },
      {
        headerName: 'operator',
        field: 'user.allocatedOperator.username',
        width: 100,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<span> ${params.value} </span>`;
          return eDiv;
        },
        sortable: false,
      },
      {
        headerName: 'Recent message',
        field: 'messageId.Message',
        width: 500,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<span>${params.value}</span>`;
          return eDiv;
        },
        sortable: true,
      },
    ];
  }

  reset(): void {
    if (!this.gridApi || this.resetDoctorFilter || this.resetOperatorFilter) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.resetDoctorFilter = this.teams.includes(ApiClientConstant.Role.Name.DOCTOR);
    this.resetOperatorFilter = this.teams.includes(ApiClientConstant.Role.Name.CHAT_SUPPORT);
    if (!this.resetDoctorFilter && !this.resetOperatorFilter) {
      this.reset();
    }
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  loadData(params: any): Promise<any> {
    const payload: any = {
      taggedBy: [],
      offset: params.startRow,
      limit: this.ui.grid.pageSize,
      attended: false,
      tag: this.appConfig.Shared.Tag.Tag.WAITLIST,
      status: [this.appConfig.Shared.Tag.Status.ASSIGNED],
    };
    if (params.sortModel[0]) {
      if (params.sortModel[0].sort === 'asc') payload.sort = { field: params.sortModel[0].colId, ascending: true };
      if (params.sortModel[0].sort === 'desc') payload.sort = { field: params.sortModel[0].colId, ascending: false };
    }
    return this.getTags(payload)
      .then((data: any) => Promise.resolve(JSON.parse(JSON.stringify(data))));
  }

  updateOperatorFilter(operatorFilter: Array<any>): void {
    this.resetOperatorFilter = false;
    this.operatorFilter = operatorFilter;
    this.reset();
  }

  updateDoctorFilter(doctorFilter: Array<any>): void {
    this.resetDoctorFilter = false;
    this.doctorFilter = doctorFilter;
    this.reset();
  }

  async getTags(payload: any): Promise<any> {
    this.addTeamFilter(payload);
    const data = await this.conn.getTags(payload);
    this.queueCount = await this.conn.getTags(payload, true);
    return data;
  }

  addTeamFilter(where_: any): void {
    const where = where_;
    if (this.teams.includes(ApiClientConstant.Role.Name.CHAT_SUPPORT)) {
      where.taggedBy.push(...this.operatorFilter);
    }
    if (this.teams.includes(ApiClientConstant.Role.Name.DOCTOR)) {
      where.taggedBy.push(...this.doctorFilter);
    }
    where.teams = this.teams;
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.data;
    delete this.ui;
  }
}
