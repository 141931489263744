import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageStringSelectionComponent } from './language-string-selection.component';
import { SimilarLanguageStringModule } from '../similar-language-string-suggestion/similar-language-string.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SimilarLanguageStringModule],
  declarations: [LanguageStringSelectionComponent],
  exports: [LanguageStringSelectionComponent],
})
export class LanguageStringSelectionModule {
}
