
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/languageStrings">Language Strings</a></li>
        </ul>
        <div class="pull-right">
          <button class="btn-primary-outline btn-xs mr-2" *ngIf="!languageStringObj.get('knApproved')" (click)="approve('kn')">Approve Kn</button>
          <button class="btn-primary-outline btn-xs mr-2" *ngIf="!languageStringObj.get('teApproved')" (click)="approve('te')">Approve Te</button>
          <button class="btn-primary-outline btn-xs mr-2" *ngIf="!languageStringObj.get('hiApproved')" (click)="approve('hi')">Approve Hi</button>
          <button class="btn-primary-outline btn-xs mr-2" *ngIf="!languageStringObj.get('taApproved')" (click)="approve('ta')">Approve Ta</button>
          <button class="btn-primary-outline btn-xs mr-2" *ngIf="!languageStringObj.get('bnApproved')" (click)="approve('bn')">Approve Bn</button>
          <button class="btn-primary-outline btn-xs mr-2" *ngIf="!languageStringObj.get('mrApproved')" (click)="approve('mr')">Approve Mr</button>
          <button class="btn-primary-outline btn-xs mr-2" *ngIf="!languageStringObj.get('maApproved')" (click)="approve('ma')">Approve Ma</button>
          <button class="btn-primary-outline btn-xs mr-2" routerLink="edit">Edit</button>
          <button class="btn-primary-outline btn-xs mr-2" *ngIf="isLanguageEditorRole" (click)="deleteLanguageString()">Delete</button>
          <button class="btn-primary-outline btn-xs mr-2" routerLink="../../languageStrings/new">New</button>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 p-4 col-md-8">
        <div class="col-xs-12 flex">
          <label class="col-xs-2 mt-0">English</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('en')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">हिंदी (Hindi)</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('hi')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">ಕನ್ನಡ(Kannada)</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('kn')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">తెలుగు(Telugu)</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('te')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">தமிழ்(Tamil)</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('ta')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">বাংলা(Bengali)</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('bn')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">मराठी(Marathi)</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('mr')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">മലയാളം(Malayalam)</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('ma')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Approved</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8">{{languageStringObj.get('approved') ? 'YES' : 'NO'}}</div>
        </div>
        <div class="col-xs-12 flex mt-2" *ngIf="languageStringObj.get('tags')?.length">
          <label class="col-xs-2 mt-2">Tags</label>
          <div class="col-xs-1 pull-left">:</div>
          <div class="col-xs-8 flex">
            <div class="badge m-2 ml-0" *ngFor="let tag of languageStringObj.get('tags');">{{tag.get('name')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>