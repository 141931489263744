<div class="photos-container">
  <div class="flex">
    <div class="cta pointer" (click)="uploadPhoto()">
      <span>Upload photo</span>
      <img class="icon" src="/assets/images/publish.svg">
      <button *ngIf="!endOfList" (click)="loadMoreImages()">Load more</button>
    </div>
  </div>
  <div class="flex">
    <ng-container *ngFor="let checkup of instantCheckups">
      <div class="photo-info-container">
        <img class="image-path" [src]="checkup?.thumbnailImagePath || checkup?.imagePath" alt="">
        <div class="date">{{ checkup?.createdAt | amDateFormat: 'MMM DD YYYY' }}</div>
        <div class="days">{{ getDayDifference(checkup) || 0 }} days</div>
        <div class="type pointer" (click)="editType(checkup.objectId, checkup.imagePath)">
          <span>{{ checkup?.type }}</span>
          <img src="/assets/images/edit.svg" alt="" class="icon pointer">
        </div>
      </div>
    </ng-container>
  </div>
</div>