import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { InputMultiSelectModule } from 'src/components/input/input-multi-select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DirectivesModule } from '../../../../components/directives.module';
import { CatalogEditComponent } from './catalog-edit.component';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputTextareaModule } from '../../../../components/input/input-textarea';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { InputImageUploadModule } from '../../../../components/input/input-image-upload';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { LanguageStringTagSelectionModule } from '../../../../components/language-string/language-string-tag-selection';
import { LanguageStringSelectionWithTagModule } from '../../../../components/language-string/language-string-selection-with-tag';
import {
  LanguageStringMultipleSelectionWithTagModule,
// eslint-disable-next-line max-len
} from '../../../../components/language-string/language-string-multiple-selection-with-tag/language-string-multiple-selection-with-tag.module';
import { QuestionConditionModule } from '../../../tree/question/edit/question-condition';

const ROUTES: Routes = [
  { path: '', component: CatalogEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSelectModule,
    InputTextModule,
    InputSelectModule,
    InputTextareaModule,
    InputCheckboxModule,
    InputImageUploadModule,
    LanguageStringSelectionModule,
    LanguageStringTagSelectionModule,
    LanguageStringSelectionWithTagModule,
    LanguageStringMultipleSelectionWithTagModule,
    QuestionConditionModule,
    InputMultiSelectModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [CatalogEditComponent],
  exports: [CatalogEditComponent],
})
export class CatalogEditModule {
}
