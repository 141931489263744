
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="col-xs-12 list-header"><span class="pR10">Events</span></div>
  </div>
  <div class="flex-content">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
        </div>
        <div class="content">
          <div class="fontS2 mt-3">
            <input [(ngModel)]="batchId" name="batchId" placeholder="BatchId"/>
            <button class="btn-xs mt-2" (click)="applyFilter()">Apply</button>
          </div>
        </div>
      </div>
      <div class="list-content">
        <iframe class="w-full h-full" *ngIf="iframeLink" [src]="iframeLink" allowtransparency="allowtransparency"></iframe>
      </div>
    </div>
  </div>
</div>