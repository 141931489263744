import * as RecordRTC from 'recordrtc';
import { Component, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Mp3Encoder, WavHeader } from 'lamejs';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRefService } from '../../services/window-ref-service';
import { UploadFileService } from '../../services/uploadFileService/uploadFile.service';
import { VoiceRecognitionService } from '../../services/voice-recognition-service/voice-recognition.service';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'audio-recorder',
  templateUrl: './audio-recorder.html',
  styleUrls: ['./audio-recorder.scss'],
})
export class AudioRecorderComponent {
  @Output('afterSave') afterSave: EventEmitter<string> = new EventEmitter();
  @Output('onChange') onChange: EventEmitter<any[]> = new EventEmitter();
  @Output('onVoiceRecognitionChange') onVoiceRecognitionChange: EventEmitter<string> = new EventEmitter();
  @ViewChild('audioPlayer', { static: false }) audioPlayer: ElementRef;
  @Input('disableVoiceToText') disableVoiceToText: boolean;
  @Input('btnClass') btnClass: string = '';
  mimeType: string = 'audio/wav';
  window: any;
  recordRTC: RecordRTC;
  error: string;
  stream: MediaStream;
  recordingInterval: any;
  blob: Blob;
  saveInProcess: boolean;
  ui: any = {};
  isMobileBrowser: boolean = false;
  isVoiceToTextSupported: boolean = false;
  audioConfig: {
    bitRate: number;
    sampleRate: number;
    channels: number;
  } = { bitRate: 64, sampleRate: 44100, channels: 1 };
  blobCollection: Array<Blob> = [];
  voiceText: string = '';
  voiceTextCollection: string[] = [];
  newBlob: Blob;

  constructor(private windowRef: WindowRefService,
    private sanitizer: DomSanitizer,
    private upload: UploadFileService,
    private connectionService: ConnectionService,
    private voiceRecognitionService: VoiceRecognitionService) {
    this.window = windowRef.nativeWindow;
    this.isMobileBrowser = this.connectionService.isMobileBrowser;
    this.isVoiceToTextSupported = this.voiceRecognitionService.isSupported();
  }

  ngOnInit(): void {
    this.reset();
    this.error = '';
  }

  private initRecording(): void {
    this.startRecordingTimer();
    delete this.error;
  }

  async startRecording(): Promise<any> {
    try {
      this.reset();
      if (!this.stream) {
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const options = {
          type: 'audio',
          recorderType: RecordRTC.StereoAudioRecorder,
          mimeType: this.mimeType,
          audio: true,
          bitsPerSecond: this.audioConfig.bitRate * 1000,
          sampleRate: this.audioConfig.sampleRate,
          numberOfAudioChannels: this.audioConfig.channels,
        };
        this.recordRTC = new RecordRTC(this.stream, options);
      }
      this.initRecording();

      if (!this.disableVoiceToText && !this.isMobileBrowser && this.isVoiceToTextSupported) {
        this.voiceRecognitionService.startVoiceRecognition((transcript: string) => {
          this.onVoiceRecognitionChange.emit(transcript);
          this.voiceText = transcript;
        });
      }
      this.recordRTC.startRecording();
    } catch (error) {
      const err = error.message === 'Permission denied' ? 'Please allow permission to access microphone.' : error.message;
      alert(err);
      this.reset();
    }
  }

  cancelRecordingTimer(): void {
    clearInterval(this.recordingInterval);
    delete this.recordingInterval;
  }

  startRecordingTimer(): void {
    this.cancelRecordingTimer();
    this.ui.recordingTime = 0;
    this.ui.recording = true;
    this.recordingInterval = setInterval(() => (this.ui.recordingTime += 1), 1000);
  }

  stopRecording(): void {
    this.ui = { loading: false, recording: false, recordingTime: 0, uploading: false };
    this.cancelRecordingTimer();
    this.voiceRecognitionService.stopVoiceRecognition();
    this.addVoiceText();
    if (this.recordRTC) {
      this.recordRTC.stopRecording(async () => {
        const blob = await this.recordRTC.getBlob();
        this.blob = await this.wavBlobToMp3Blob(blob);
        this.blobCollection.push(this.blob);
        this.addAudioPlayer();
        this.closeMediaRecorder();
        this.onChange.emit([this.blob]);
      });
    }
  }

  addAudioPlayer(): void {
    this.audioPlayer.nativeElement.innerHTML = '';
    this.blobCollection.forEach((blob: Blob, index: number) => {
      const audio = this.windowRef.nativeWindow.document.createElement('audio');
      audio.setAttribute('controls', true);
      audio.setAttribute('class', 'mR20 mB5 audio');
      audio.setAttribute('type', blob.type);
      audio.innerHTML = `<source src = ${URL.createObjectURL(blob)}>`;
      const span = this.windowRef.nativeWindow.document.createElement('span');
      span.setAttribute('class', 'fa fa-trash-o');
      span.addEventListener('click', (event: any) => this.deleteAudio(index));
      const div = this.windowRef.nativeWindow.document.createElement('div');
      div.setAttribute('class', 'flex');
      div.appendChild(audio);
      div.appendChild(span);
      this.audioPlayer.nativeElement.appendChild(div);
    });
  }

  addVoiceText():void {
    if (!this.voiceText) {
      return;
    }
    this.voiceTextCollection.push(this.voiceText);
    let allTranscript = '';
    allTranscript = this.voiceTextCollection.join(' ');
    this.onVoiceRecognitionChange.emit(allTranscript);
    this.voiceText = '';
  }

  deleteVoiceText(index: number): void {
    this.voiceTextCollection.splice(index, 1);
    let allTranscript = '';
    allTranscript = this.voiceTextCollection.join(' ');
    this.onVoiceRecognitionChange.emit(allTranscript);
    this.voiceText = '';
    this.onChange.emit(this.blobCollection);
  }

  deleteAudio(index:any): void {
    this.blobCollection.splice(index, 1);
    this.deleteVoiceText(index);
    this.addAudioPlayer();
  }

  mergeAudio(): void {
    this.newBlob = new Blob(this.blobCollection, { type: this.blobCollection[0].type });
    const allTranscript = this.voiceTextCollection.join(' ');
    this.blobCollection = [];
    this.voiceTextCollection = [];
    this.onVoiceRecognitionChange.emit(allTranscript);
    this.voiceTextCollection.push(allTranscript);
    this.blobCollection.push(this.newBlob);
    this.addAudioPlayer();
  }

  resetAudio():void {
    this.blobCollection = [];
    this.voiceTextCollection = [];
    this.voiceText = '';
    this.addAudioPlayer();
    this.onVoiceRecognitionChange.emit('');
    this.reset();
  }

  closeMediaRecorder(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track: any) => track.stop());
      delete this.stream;
    }
  }

  isRecordingAvailable(): boolean {
    return !!this.blobCollection.length;
  }

  async saveRecording(): Promise<void> {
    if (!this.newBlob && this.blobCollection.length) this.mergeAudio();
    if (!this.newBlob) {
      alert('No Audio Found');
      this.afterSave.emit('');
      return;
    }
    this.saveInProcess = true;
    try {
      const { publicURL: unSignedURL, signedGetURL: signedURL }: { publicURL: string; signedGetURL: string; } = await this
        .uploadBlobToServer(this.newBlob);
      this.afterSave.emit(unSignedURL);
      this.saveInProcess = false;
    } catch (error) {
      alert(error.toString());
      this.saveInProcess = false;
      this.afterSave.emit('');
    }
  }

  async checkAudioQualityAndSendLink(signedURL: string, unSignedURL: string): Promise<string> {
    return new Promise((resolve: Function) => {
      const audioAfterSave = this.windowRef.nativeWindow.document.createElement('audio');
      audioAfterSave.setAttribute('type', this.newBlob.type);
      const audioBeforeSave = new Audio();
      audioBeforeSave.src = URL.createObjectURL(this.newBlob);
      audioAfterSave.addEventListener('loadeddata', () => {
        if (audioAfterSave.duration === audioBeforeSave.duration) {
          this.afterSave.emit(unSignedURL);
          resolve(unSignedURL);
        } else {
          this.error = 'Audio not saved properly. Kindly play the audio,'
            + 'If not proper then reset & record it again. If proper then try sending it again.';
          this.audioPlayer.nativeElement.appendChild(audioAfterSave);
          resolve('');
        }
      });
      audioAfterSave.src = signedURL;
      audioAfterSave.load();
    });
  }

  encodeSamplesToMp3(samples: Int16Array, opts: any): Blob {
    const defaultEncodeOptions = {
      channels: this.audioConfig.channels,
      sampleRate: this.audioConfig.sampleRate,
      kbps: this.audioConfig.bitRate,
      maxSamples: 1152,
    };

    const options = {
      ...defaultEncodeOptions,
      ...opts,
    };

    const { maxSamples }: any = options;
    let remaining = samples.length;
    const mp3encoder = new Mp3Encoder(options.channels, options.sampleRate, options.kbps);
    const mp3Data = [];
    let mp3buffer;
    let index = 0;

    while (remaining > 0) {
      const sample = samples.slice(index, index + maxSamples);
      mp3buffer = mp3encoder.encodeBuffer(sample);

      if (mp3buffer.length > 0) {
        mp3Data.push(new Int8Array(mp3buffer));
      }

      index += maxSamples;
      remaining -= maxSamples;
    }
    mp3buffer = mp3encoder.flush();
    mp3Data.push(mp3buffer);

    return new Blob(mp3Data, { type: 'audio/mp3' });
  }

  async uploadBlobToServer(blob: Blob): Promise<{ publicURL: string; signedGetURL: string; }> {
    const file = new File([blob], `audio.${this.fileExtension(blob.type)}`, {
      type: blob.type,
      lastModified: Date.now(),
    });
    return this.upload.uploadFileViaSignedURL({ file, bucket: 'AUDIO' });
  }

  fileExtension(type: string): string {
    switch (type) {
      case 'audio/mpeg': return 'mp3';
      case 'audio/mp3': return 'mp3';
      default: return 'wav';
    }
  }

  reset(): void {
    this.saveInProcess = false;
    delete this.recordRTC;
    this.stopRecording();
    this.closeMediaRecorder();
    if (this.audioPlayer) this.audioPlayer.nativeElement.src = undefined;
    delete this.recordRTC;
    delete this.blob;
    delete this.newBlob;
    delete this.error;
    this.onChange.emit(this.blobCollection);
  }

  private async wavBlobToMp3Blob(blob: Blob): Promise<Blob> {
    const fileReader = new FileReader();
    return new Promise((resolve: Function) => {
      fileReader.onload = async (event: any): Promise<void> => {
        const blobResult = event.currentTarget.result as ArrayBuffer;
        const samples = new Int16Array(blobResult);
        const wav = WavHeader.readHeader(new DataView(blobResult));

        const mp3Blob = this.encodeSamplesToMp3(samples, {
          channels: wav.channels,
          sampleRate: wav.sampleRate,
        });
        resolve(mp3Blob);
      };
      if (!blob) {
        return;
      }
      fileReader.readAsArrayBuffer(blob);
    });
  }
}
