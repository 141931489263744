import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ApiConnector, Table } from 'api-client';
import { CallLogModule } from 'src/app/chat/view/call-logs';
import { ConnectionService } from 'src/services/connection-service';

@Component({
  selector: 'sales-call-logs',
  standalone: true,
  imports: [CommonModule, CallLogModule],
  templateUrl: './sales-call-logs.component.html',
  styleUrl: './sales-call-logs.component.scss',
})
export class SalesCallLogsComponent {
  @Input('user') user: any;
  callLogs: any = [];
  constructor(private conn: ConnectionService) { }

  async ngOnInit(): Promise<void> {
    const callLogs = await ApiConnector.find(Table.CallLog, {
      where: { user: this.user },
      descending: 'createdAt',
      project: ['direction', 'to', 'from', 'status', 'calledAt', 'conversationDuration'],
    });
    this.callLogs = callLogs;
  }
}
