import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';
import { Broadcaster } from '../../../components/broadcaster';

@Component({
  selector: 'language-string-view',
  templateUrl: './language-string-view.html',
  styleUrls: ['./language-string-view.scss'],
})
export class LanguageStringViewComponent implements OnDestroy {
  languageStringObj: any;
  subscriptions: Array<Subscription> = [];
  currentUser:any;
  isLanguageEditorRole: boolean = false;
  constructor(private broadcaster: Broadcaster,
              private route: ActivatedRoute,
              private router: Router,
              private conn: ConnectionService,
              private appConfig: AppConfig,
              private storage: LocalStorage) {
    this.languageStringObj = new Table.LanguageString();
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.currentUser });
    const roles = this.storage.getJsonValue('userRoles') || [];
    if (roles.includes('languageEditor')) {
      this.isLanguageEditorRole = true;
    }
    this.subscriptions.push(this.route.params.subscribe(async (params: any) => {
      if (!params.id) return;
      this.languageStringObj = await this.conn.getLanguageString(params.id);
    }));
  }

  approve(language: string): void {
    this.languageStringObj.set(`${language}Approved`, true);
    this.languageStringObj.save();
  }

  async deleteLanguageString(): Promise<void> {
    try {
      if (confirm('Are you sure you want to delete?')) {
        await this.languageStringObj.destroy();
        await this.broadcaster.broadcast('NOTIFY', { message: 'Successfully Deleted Language String',
          type: this.appConfig.Shared.Toast.Type.SUCCESS });
        await this.router.navigate(['/languageStrings']);
      }
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.toString(),
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
