import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConnectionService } from '../../../../services/connection-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Broadcaster } from '../../../../components/broadcaster';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'regimen-list', templateUrl: './regimen-list.html', styleUrls: ['./regimen-list.scss'] })
export class RegimenListComponent {
  @Input('ui') ui: any = {};
  @Input('supportTicketId') supportTicketId: string;
  @Input('user')
  set userFunction(user: any) {
    this.user = user;
    if (!this.user || !this.user.id) {
      this.regimens = [];
      return;
    }
    this.getUserRegimens();
  }
  user: any;
  baseUrlForWebApp: string;
  userRoles: Array<string> = [];
  regimens: Array<any> = [];

  public subscriptions: Subscription[] = [];

  constructor(private conn: ConnectionService,
    private storage: LocalStorage,
    private appConfig: AppConfig,
    private broadcaster: Broadcaster) {
  }

  async ngOnInit(): Promise<any> {
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.baseUrlForWebApp = this.conn.getWebAppUrl();
  }

  async getUserRegimens(): Promise<any> {
    this.regimens = await this.conn.findRegimens({
      where: { forUser: this.user },
      include: ['concernsLanguageString', 'night.product' as 'night', 'morning.product' as 'morning'],
      project: ['regimenId', 'updateVersion', 'concernsLanguageString.en' as 'concernsLanguageString',
        'title', 'night', 'morning', 'active'],
    });
  }

  async deleteRegimen(regimen: any): Promise<void> {
    try {
      regimen.destroy();
      this.broadcaster.broadcast('NOTIFY', { message: 'Regimen deleted successfully.' });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.toString(),
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async resendRegimen(index: number): Promise<any> {
    try {
      this.ui.savingMessage = true;
      const regimenId: string = this.regimens[index].get('regimenId');
      let context = {};
      if (this.supportTicketId) {
        context = { supportTicketId: this.supportTicketId };
      }
      const personalizedRegimen = await this.conn.sendOrCreatePersonalizedRegimen(regimenId, this.user.get('username'), context);
      if (this.regimens.every((regimen: any) => (regimen.get('regimenId') !== personalizedRegimen.get('regimenId')))) {
        this.regimens.push(personalizedRegimen);
      }
      this.ui.savingMessage = false;
    } catch (err) {
      this.ui.savingMessage = false;
    }
  }
}
