import { Shared } from '../shared';
import { EnvType } from '../../typings';

class AppConfig {
  static Shared: any = Shared;
  static FollowUp: any = {
    PopupType: {
      WAITING_FOR_IMAGE: 'WAITING_FOR_IMAGE',
      ADD_OR_EDIT_FOLLOWUP: 'ADD_OR_EDIT_FOLLOWUP',
      MAKE_NEW_FOLLOWUP: 'MAKE_NEW_FOLLOWUP',
      FINISH_FOLLOWUP: 'FINISH_FOLLOWUP',
      HISTORY_FOLLOWUP: 'HISTORY_FOLLOWUP',
    },
    State: {
      PENDING: 'PENDING',
      FINISHED: 'FINISHED',
      WAITING_FOR_IMAGE: 'WAITING_FOR_IMAGE',
      SKIP: 'SKIP',
    },
  };
  static Notes: any = {
    Type: {
      MEDICAL: 'MEDICAL',
      OPERATOR: 'OPERATOR',
    },
  };
  static ChatConstants: any = {
    QUESTION_TYPES: {
      SINGLE_SELECTOR: 1,
      MESSAGE: 2,
      TEXT_INPUT: 3,
    },
  };
  Shared: EnvType.Shared;
  FollowUp: any;
  Notes: any;
  ChatConstants: any;
}

interface Coordinate {
  x: number;
  y: number;
}

export { AppConfig, Coordinate };
