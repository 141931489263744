import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'searchBox',
  templateUrl: './search-box.html',
  styleUrls: ['./search-box.scss'],
})
export class SearchBoxComponent {
  open: boolean = false;
  search: string = '';
  @Output() searchKey: EventEmitter<any> = new EventEmitter();
  static parameters: any = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  textChange(key: string): void {
    setTimeout(() => {
      if (this.search === key) this.searchKey.emit(this.search);
    }, 300);
  }

  toggle(): void {
    this.open = !this.open;
  }
}
