
<div class="col-xs-12 p-0">
  <collapse title="Consultations" iconClass="fas fa-comment" extraClass="highlight-head">
    <div class="col-xs-12 of-auto fontS box" *ngIf="consultationSessions.length">
      <div class="col-xs-12">
        <label class="col-xs-3">Class</label>
        <label class="col-xs-3">mainConcern</label>
        <label class="col-xs-3">Status</label>
        <label class="col-xs-3">CreatedAt</label>
      </div><a class="col-xs-12 mt-3" *ngFor="let consultation of consultationSessions;">
        <div class="col-xs-3"><span (click)="loadConsultation(consultation.id)">{{consultation.get('PrivateMainConcernClass')}}</span><i class="fas fa-info-circle ml-3" (click)="openConsultationPopup(consultation)"></i></div>
        <div class="col-xs-3">{{consultation.get('PrivateMainConcern')}}</div>
        <div class="col-xs-3">{{consultation.get('archive') ? "inactive" : "active"}}</div>
        <div class="col-xs-3">{{consultation.get('createdAt')| amDateFormat: 'DD/MM/YYYY'}}</div></a>
    </div>
  </collapse>
</div>