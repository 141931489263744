import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { WorkTimeComponent } from './work-time.component';
import { TimeRangePopupModel } from './time-range-popup';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
  ],
  declarations: [WorkTimeComponent, TimeRangePopupModel],
  exports: [WorkTimeComponent, TimeRangePopupModel],
})
export class WorkTimeModule {
}
