
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Approve Leave</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body mt-0">
    <div class="col-xs-12">
      <label>Select Status</label>
      <div class="col-xs-12">
        <mat-form-field class="col-xs-5">
          <mat-select class="w100" [(ngModel)]="selectedStatus">
            <mat-option *ngFor="let option of leaveStatusList" [value]="option">{{option}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="pull-right" (click)="save()" [disabled]="!selectedStatus">Save</button>
  </div>
</mat-dialog-content>