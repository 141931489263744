<div class="support-ticket-container">
  <div class="flex-box">
    <div class="heading">Support tickets</div>
    <a routerLink="/chats/open-tickets/new" [queryParams]="{ userId: user?.id }" target="_blank" class="icon pointer">
      <img src="/assets/images/add.svg" alt="Add">
    </a>
  </div>
  <ng-container *ngFor="let ticket of tickets">
    <div class="support-ticket-cell-container">
      <div class="flex-box pointer" (click)="redirectToTicketPage(ticket)">
        <div class="date">{{ticket.get("createdAt") | amDateFormat: 'DD MMM YY'}}</div>
        <div class="title">{{ticket.get('ticketNumber')}} : {{ticket.get('title') ||
          ticket.get('titleLanguageString')?.get('en')}}</div>
        <div class="concern-txt">{{ticket.get("closedAt") | amDateFormat: 'DD MMM YY'}}</div>
      </div>
      <hr>
    </div>
  </ng-container>
</div>