import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { DirectivesModule } from '../../../../components/directives.module';
import { ChatSidebarComponent } from './chat-sidebar.component';
import { SupportChatViewModule } from '../support-chat-view';
import { UserChatViewModule } from '../../../../components/user-chat-view';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MomentModule,
    SupportChatViewModule,
    UserChatViewModule,
    InputCheckboxModule,
  ],
  declarations: [
    ChatSidebarComponent,
  ],
  exports: [
    ChatSidebarComponent,
  ],
})
export class ChatSidebarModule {
}
