import { Directive, HostListener, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({ selector: '[beforeTopView]' })
export class BeforeTopView {
  @Input() beforeTopView: Function;
  el: ElementRef;
  static parameters: any = [ElementRef, Renderer2];
  constructor(el: ElementRef) {
    this.el = el;
  }

  @HostListener('scroll', ['$event'])
  confirmFirst(): void {
    const elm = this.el.nativeElement;
    const threshold = 150;
    if (elm.scrollTop < threshold) {
      this.beforeTopView();
    }
  }
}
