import { Component, Input } from '@angular/core';
import { Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';
import { LocalStorage } from '../../../services/local-storage-service';

@Component({
  selector: 'user-notes-operator',
  templateUrl: './user-notes-operator.html',
})
export class UserNotesOperatorComponent {
  @Input('user')
  set setUser(user: any) {
    if (!user) {
      return;
    }
    this.user = user;
    this.loadNotes();
  }
  @Input() enableAddNote: boolean = true;
  user: any;
  notes: Array<any> = [];
  addNew: boolean;
  noteMessage: string;
  newNote: any = new Table.OperatorNote();
  currentUser: any;
  userRoles: Array<any> = [];

  constructor(private conn: ConnectionService, private appConfig: AppConfig, public storage: LocalStorage) {
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.userRoles = this.storage.getJsonValue('userRoles');
  }

  private async loadNotes(): Promise<void> {
    this.notes = await this.conn.findOperatorNotes({
      where: { userId: this.user.get('username') },
      descending: 'createdAt',
      project: ['operatorName', 'message', 'operator'],
    });
  }

  async addNote(): Promise<void> {
    if (!this.newNote.id) {
      this.newNote.set('userId', this.user.get('username'));
      this.newNote.set('operator', this.conn.getCurrentUser());
      this.newNote.set('operatorName', this.currentUser.get('username'));
    }
    try {
      this.newNote.set('message', this.noteMessage);
      await this.newNote.save();
      delete this.noteMessage;
      this.addNew = false;
      this.newNote = new Table.OperatorNote();
      await this.loadNotes();
    } catch (e) {
      alert('Unable to save medical note');
    }
  }

  editSelectedNote(index: number): void {
    this.newNote = this.notes[index];
    this.noteMessage = this.newNote.get('message');
    this.addNew = true;
  }

  async deleteNote(index: number): Promise<void> {
    const selectedNote = this.notes[index];
    await selectedNote.destroy();
    await this.loadNotes();
  }
}
