import { Component, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ApiClientConstant, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { ValueOf } from '../../../../../typings/server/common';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})

export class EditComponent {
  eventTime: Date;
  selectedProductIndex: number;
  showProgressBar: boolean;
  progress: number;
  total: number;
  imageLinkLanguageString: any;
  messageLanguageString: any;
  openUrl: any;
  action: string;
  category: string;
  typeName: string;
  question: string;
  errorMessage: string;
  ui: { error?: string } = {};
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  csvText: string;
  bulkActionList: Array<Array<string>> = [];
  actions: Array<{ name: string, value: string }> = [];
  categories: Array<string> = ['Order', 'Notification', 'AddOnProduct'];
  productList: Array<any>;
  typeNames: Array<string> = ['Assistant', 'Feedback'];
  public subscriptions: Subscription[] = [];
  static parameters: any = [ConnectionService, AppConfig, Router];

  constructor(private conn: ConnectionService,
              private appConfig: AppConfig,
              private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.actions = [
      ApiClientConstant.Order.Stage.AWB_GENERATED,
      ApiClientConstant.Order.Stage.PACKED,
      ApiClientConstant.Order.Stage.QC_PASS,
      ApiClientConstant.Order.Stage.RETURNED,
      ApiClientConstant.Order.Stage.DELIVERY_FAIL,
      ApiClientConstant.Order.Stage.ADDRESS_VERIFIED,
      'ADD_NOTE',
    ].map((each: string) => ({ name: each, value: each }));
    this.showProgressBar = false;
    await this.fetchProducts();
  }

  async fetchProducts(): Promise<void> {
    const payload: RequestQueryPayload<Table.Catalog> = {
      where: { allowAsAddon: true, articleId: { $exists: true } },
      include: ['article'],
      project: ['title', 'article'],
    };
    this.productList = await this.conn.findCatalogs(payload);
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  addDataToProcess(): void {
    this.csvText.split('\n')
      .forEach((row: string) => {
        if (!row.trim()) {
          return;
        }
        this.bulkActionList.push(row.split(',')
          .map((each: string) => each.trim())
          .filter((each: string) => each));
      });
    delete this.csvText;
    this.validateList();
  }

  readMargFileForPackedAction(): Promise<any> {
    return new Promise((resolve: Function): void => {
      const fileReader = new FileReader();
      fileReader.onload = (): void => {
        const text: string = fileReader.result as string;
        const orderProductMap = {};
        text.replace(/"/g, '').split('\n')
          .filter((row: any, index: number) => row && index)
          .map((each: string) => each.split(','))
          .forEach((row: Array<string>) => {
            const orderNumber = row[9];
            if (!orderProductMap[orderNumber]) {
              orderProductMap[orderNumber] = [];
            }
            orderProductMap[orderNumber].push(...new Array(Number(row[5])).fill(row[3]));
          });
        Object.keys(orderProductMap).forEach((key: string) => orderProductMap[key] = orderProductMap[key].join(','));
        resolve(orderProductMap);
      };
      fileReader.readAsText(this.fileInput.nativeElement.files[0]);
    });
  }

  async processBatch(userIds: Array<string>, batchId: string, type: string, params_?: { [key: string]: string }): Promise<void> {
    if (!userIds.length) return;
    const Events = [];
    const params: any = {
      typeName: this.typeName || 'Assistant',
      messageLanguageString: this.messageLanguageString,
      imageLinkLanguageString: this.imageLinkLanguageString,
      openUrl: this.openUrl,
      ...params_,
    };
    userIds.forEach((userId: string) => {
      const user = new Table.User();
      user.id = userId;
      const event = new Table.EventScheduler();
      event.set('completionStatus', false);
      event.set('batchId', batchId);
      event.set('eventTime', this.eventTime);
      event.set('user', user);
      event.set('params', params);
      event.set('type', type);
      event.set('jobId', `${batchId}_${userId}`);
      Events.push(event);
    });
    await this.conn.saveAll(Events)
      .then(() => this.progress += userIds.length)
      .catch((error: any) => alert(`Error: ${error.message || error}`));
  }

  async createBatchEvents(userIds: Array<string>, batchId: string, type: string, params_?: { [key: string ]: string }): Promise<void> {
    if (!userIds.length) return;
    const batch = userIds.splice(0, Math.min(200, userIds.length));
    await this.processBatch(batch, batchId, type, params_);
    await this.createBatchEvents(userIds, batchId, type, params_);
  }

  async createBatchRequestEvent(user: any, batchRequest: any): Promise<void> {
    const batchRequestEvent = new Table.EventScheduler();
    batchRequestEvent.set('completionStatus', false);
    batchRequestEvent.set('type', this.appConfig.Shared.EventScheduler.Type.BATCH_REQUEST_STATUS);
    batchRequestEvent.set('jobId', batchRequest.get('batchId'));
    batchRequestEvent.set('eventTime', moment(this.eventTime).add(5, 'minute').toDate());
    batchRequestEvent.set('batchRequest', batchRequest);
    batchRequestEvent.set('user', user);
    await batchRequestEvent.save();
  }

  async createBatchRequest(requestCount: number, type: string): Promise<string> {
    const batchRequest = new Table.BatchRequest();
    const requestUser = await this.conn.getCurrentUser();
    let group: ValueOf<typeof ApiClientConstant.BatchRequest.Group> = ApiClientConstant.BatchRequest.Group.MARKETING;
    if (requestUser.get('username').endsWith('ecom')) group = ApiClientConstant.BatchRequest.Group.COURIER;
    batchRequest.set('batchId', `${this.category}_${this.eventTime.toISOString()}`);
    batchRequest.set('requestCount', requestCount);
    batchRequest.set('requestUser', requestUser);
    batchRequest.set('stage', ApiClientConstant.BatchRequest.Stage.CREATED);
    batchRequest.set('group', group);
    batchRequest.set('type', type);
    try {
      await Promise.all([batchRequest.save(), this.createBatchRequestEvent(requestUser, batchRequest)]);
    } catch (error) {
      alert(`Error: ${error.message || error}`);
    }
    return batchRequest.get('batchId');
  }

  validateEventTime(): boolean {
    const currentTime = new Date();
    if (!this.eventTime || (this.eventTime.getTime() - currentTime.getTime()) < 120 * 60 * 1000) {
      alert('Selected Time must be at-least 2hrs from now');
      return false;
    }
    return true;
  }

  async processBulkAction(): Promise<void> {
    switch (this.category) {
      case 'Order': {
        await this.updateOrder();
        break;
      }
      case 'Notification': {
        if (!this.validateEventTime()) return;
        const userIds = this.bulkActionList.flat();
        if (!userIds.length) return;
        this.initializeProgressBar(userIds.length);
        const batchId = await this.createBatchRequest(userIds.length, this.appConfig.Shared.EventScheduler.Type.SEND_NOTIFICATION);
        await this.createBatchEvents(userIds, batchId, this.appConfig.Shared.EventScheduler.Type.SEND_NOTIFICATION);
        this.showProgressBar = false;
        this.reset();
        break;
      }
      case 'AddOnProduct': {
        if (!this.validateEventTime()) return;
        const userIds = this.bulkActionList.flat();
        if (!userIds.length) return;
        this.initializeProgressBar(userIds.length);
        const product = this.productList[this.selectedProductIndex];
        this.messageLanguageString = product.get('article').get('notificationMessageLanguageString');
        this.imageLinkLanguageString = product.get('article').get('notificationImageLanguageString');
        this.openUrl = `${this.conn.getWebAppUrl()}/article/${product.get('article').id}`;
        const batchId = await this.createBatchRequest(userIds.length, this.appConfig.Shared.EventScheduler.Type.ADD_ADDON_TO_USER);
        await this.createBatchEvents(userIds,
          batchId,
          this.appConfig.Shared.EventScheduler.Type.ADD_ADDON_TO_USER,
          { productId: product.id });
        this.showProgressBar = false;
        this.reset();
        break;
      }
      default:
    }
  }

  async updateOrder(): Promise<any> {
    try {
      if (this.action === ApiClientConstant.Order.Stage.QC_PASS) {
        const orderProductMap = await this.readMargFileForPackedAction();
        this.bulkActionList.forEach((each: any) => {
          if (orderProductMap[each[0]]) {
            each.push(orderProductMap[each[0]]);
          }
        });
      }
      const params = {
        stage: this.action,
        rows: this.bulkActionList,
        batchId: `${this.action}_${moment().toISOString()}`,
      };
      const results = await this.conn.bulkUpdateOrder(params);
      alert(`Request successful. RequestId: ${results.batchId
      }, BatchRequestId: ${results.batchRequestId}`);
    } catch (error) {
      alert(error.message);
    }
    this.reset();
  }

  removeEntry(row: number): void {
    this.bulkActionList.splice(row, 1);
    this.validateList();
  }

  validateList(): void {
    delete this.errorMessage;
    if (!this.bulkActionList.length) {
      return;
    }
    const { length }: any = this.bulkActionList[0];
    if (this.bulkActionList.some((row: Array<string>) => row.length !== length)) {
      this.errorMessage = 'Given list is not valid';
    }
  }

  initializeProgressBar(total: number): void {
    this.progress = 0;
    this.total = total;
    this.showProgressBar = true;
  }

  reset(): any {
    this.router.navigate(['/couriers/bulkUpdate']);
  }
}
