
<div class="col-xs-12 br-5 px-2 {{cssClasses}}" *ngIf="instruction">
  <collapse [title]="instruction.title">
    <div class="col-xs-12 flex">
      <div class="m-r-xs">{{instruction.quantity}}</div>
      <div class="m-r-xs">{{instruction.quantityUnit}}</div>
      <div class="col-xs-12" *ngIf="!instruction.quantityUnit">
        <language-view [languageStringObj]="instruction.quantityUnitLanguageString"></language-view>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="m-r-xs">{{instruction.areaOfApplication}}</div>
      <div class="col-xs-12" *ngIf="!instruction.areaOfApplication">
        <language-view [languageStringObj]="instruction.areaOfApplicationLanguageString"></language-view>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="m-r-xs">{{instruction.frequencyOfApplication}}</div>
      <div class="col-xs-12" *ngIf="!instruction.frequencyOfApplication">
        <language-view [languageStringObj]="instruction.frequencyOfApplicationLanguageString"></language-view>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="m-r-xs">{{instruction.durationOfApplication}}</div>
      <div class="col-xs-12" *ngIf="!instruction.durationOfApplication">
        <language-view [languageStringObj]="instruction.durationOfApplicationLanguageString"></language-view>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="m-r-xs">{{instruction.durationOfProduct}}</div>
      <div class="col-xs-12" *ngIf="!instruction.durationOfProduct">
        <language-view [languageStringObj]="instruction.durationOfProductLanguageString"></language-view>
      </div>
    </div>
    <div class="col-xs-12 pl-0" *ngFor="let row of multipleValueFields;">
      <div class="col-xs-12 pl-0" *ngIf="instruction[row.fieldName] && instruction[row.fieldName].length">
        <label class="mt-0">{{row.title}}</label>
        <div class="col-xs-12 fontS1" *ngFor="let item of instruction[row.fieldName];">
          <language-view [languageStringObj]="item"></language-view>
        </div>
      </div>
    </div>
    <div class="col-xs-12" *ngIf="instruction.quantityImage">
      <div *ngFor="let image of instruction.quantityImage;"><img class="mt-3 img-thumbnail" src="{{image}}" style="height: 80px;"/></div>
    </div>
  </collapse>
  <div class="col-xs-12" *ngIf="showAction">
    <div class="pull-right"><i class="fa fa-trash-o pointer mr-3" (click)="onDelete.emit()" title="Delete instruction"></i><i class="fa fa-pencil-square-o mr-3 pointer" (click)="onEdit.emit()" title="Edit instruction"></i><i class="fa fa-clone pointer" (click)="onUseAsTemplate.emit()" title="Use as template"></i></div>
  </div>
</div>
