
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Choose Concern</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body mt-0">
    <div class="col-xs-12 pt-4">
      <label class="db">Choose Concern</label>
      <mat-form-field>
        <mat-select class="w100" [(ngModel)]="selectedConcern">
          <mat-option *ngFor="let item of mainConcernList; let i = index" [value]="item">{{item.get('displayNameLanguageString').get('en')}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-xs pull-right" (click)="updateConcern()">Save</button>
  </div>
</mat-dialog-content>