import { Component, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'view', templateUrl: './view.html' })
export class ViewComponent implements OnDestroy {
  uniqueId: string = '';
  loading: boolean = false;
  mediaLink: any;
  products: Array<any> = [];
  constructor(private conn: ConnectionService,
              public appConfig: AppConfig,
              private router: Router,
              private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params: any) => {
      this.uniqueId = params.combinedProductId || [];
    });
    await this.getMediaLinkData();
    this.loading = true;
  }
  async getMediaLinkData():Promise<void> {
    this.mediaLink = await ApiConnector.find(Table.MediaLink, { where: {
      uniqueId: this.uniqueId,
    } });
    if (this.mediaLink?.length) {
      this.mediaLink = this.mediaLink[0];
      await this.setProducts();
    }
  }

  async setProducts():Promise<void> {
    const mainProductIds: Array<string> = [];
    this.mediaLink?.get('reference').map((product: any) => (mainProductIds.push(product.split('_')[0])));
    const result = await ApiConnector.find(Table.Catalog, { where:
        {
          objectId: { $in: mainProductIds },
        } });
    this.products.push(result);
    this.products = this.products[0];
  }

  ngOnDestroy(): void {
    this.loading = false;
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
