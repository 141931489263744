import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { map } from 'rxjs/operators';
import { ListNode } from './list.node';
import { ConnectionService } from '../../../services/connection-service';

export class ListDataSource implements DataSource<ListNode> {
  dataChange: BehaviorSubject<ListNode[]> = new BehaviorSubject<ListNode[]>([]);

  get data(): ListNode[] { return this.dataChange.value; }
  set data(value: ListNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private treeControl: FlatTreeControl<ListNode>, private conn: ConnectionService) {
    this.loadRootData();
  }

  connect(collectionViewer: CollectionViewer): Observable<ListNode[] | ReadonlyArray<ListNode>> {
    this.treeControl.expansionModel.changed.subscribe((change: SelectionChange<ListNode>) => {
      if (change.added || change.removed) {
        this.handleTreeControl(change);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<ListNode>): void {
    if (change.added) {
      change.added.forEach((node: ListNode) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach((node: ListNode) => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  async toggleNode(node_: ListNode, expand: boolean): Promise<void> {
    const node = node_;
    node.isLoading = true;
    const index = this.data.indexOf(node);
    if (index < 0) { // If no children, or cannot find the node, no op
      node.isLoading = false;
      return;
    }

    if (expand) {
      const children = await this.findChildren(node.item);
      const nodes = children.map((name: any) => new ListNode(name, node.level + 1, false));
      this.data.splice(index + 1, 0, ...nodes);
    } else {
      let count = 0;
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i += 1) {
        count += 1;
      }
      this.data.splice(index + 1, count);
    }

    // notify the change
    this.dataChange.next(this.data);
    node.isLoading = false;
  }

  private async loadRootData(): Promise<any> {
    const children = await this.findChildren(undefined);
    this.data = children.map((each: any) => new ListNode(each, 1, false));
  }

  private async findChildren(parentNode: any): Promise<any> {
    return this.conn.fetchEmbeddedLinks({ parentNode });
  }

  updateRootNodes(parentNode?: any): void {
    if (!parentNode) {
      this.loadRootData();
      return;
    }
    this.toggleNode(parentNode, true);
  }
}
