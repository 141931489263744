
<div class="hbox">
  <div class="col-xs-12" *ngIf="regimen">
    <div class="flex" style="border-bottom: 1px solid rgba(0,0,0,0.1);">
      <div class="title" *ngIf="!isSelectedRegimen && regimenList?.length">Select Regimen</div>
      <div class="title" *ngIf="isSelectedRegimen && regimenList?.length">Regimen Selected</div>
      <div class="title" *ngIf="!regimenList?.length">Regimen</div>
      <div class="flex flex-wrap" *ngIf="regimenList?.length === 1"><span class="mr-3 mb-3"><span class="buttonRegimen selectedRegimen" (click)="onChangeRegimen(regimenList[0])">{{regimenList[0]?.get('title')}}</span><span class="fas fa-check mr-3 selectedRegimenIcon" *ngIf="selectedSopRegimen?.id === regimenList[0]?.id"></span></span></div>
      <div class="flex flex-wrap" *ngIf="regimenList?.length > 1"><span class="mr-3 mb-3" *ngFor="let reg of regimenList"><span class="buttonRegimen" (click)="onChangeRegimen(reg)" [ngClass]="{ selectedRegimen: selectedSopRegimen?.id === reg?.id }">{{reg?.get('title')}}</span><span class="fas fa-check mr-3 selectedRegimenIcon" *ngIf="selectedSopRegimen?.id === reg?.id"></span></span></div>
      <div class="div flex flex-grow justifySB p-3">
        <language-string-multiple-selection class="regimen-concern" [parseObj]="regimen" name="concernsLanguageString" [required]="true" [tags]="['concern','display']"></language-string-multiple-selection>
        <div class="flex" *ngIf="!orderApprovalPage && !regimen.get('active')">
          <label class="mr-3">Active</label>
          <label class="i-switch i-switch-sm bg-success">
            <input type="checkbox" [(ngModel)]="active" (ngModelChange)="toggleRegimenActive(active)" name="toggleActive"/><i></i>
          </label>
        </div>
      </div>
    </div>
    <div class="flex align-start">
      <div class="flex flex-1" style="background-color: rgba(226, 235, 245, 0.50); align-self: stretch !important">
        <div class="products morning-product flex-1 h-auto" cdkDropList="cdkDropList" [cdkDropListData]="morningProducts" (cdkDropListDropped)="moveProduct($event)">
          <div class="product-title">
            <div class="icon"><i><img src="../../assets/images/sun.png" style="width: 30px; height: 30px;"/></i></div><span>Morning routine</span>
          </div>
          <div class="product" *ngFor="let product of morningProducts; let i = index">
            <div class="index morning-product-index">{{i + 1}}</div>
            <div class="product-details" *ngIf="product.product?.id" cdkDrag="cdkDrag" [cdkDragData]="product">
              <div class="w70">
                <div class="product-name" *ngIf="product.product?.get('title')">{{product.product?.get("title")}}</div>
                <ul class="otherDetails">
                  <li *ngIf="product.instructionSet && product.instructionSet.frequencyOfApplicationLanguageString">{{product.instructionSet.frequencyOfApplicationLanguageString?.get('en')}}</li>
                  <li *ngIf="product.instructionSet && product.instructionSet.quantityUnitLanguageString">{{product.instructionSet.quantity}} {{product.instructionSet.quantityUnitLanguageString.get('en')}}</li>
                  <li *ngIf="product.instructionSet && product.instructionSet.areaOfApplicationLanguageString">{{product.instructionSet.areaOfApplicationLanguageString.get('en')}}</li>
                  <li *ngIf="product.instructionSet && product.instructionSet.durationOfApplicationLanguageString && product.instructionSet.durationOfApplicationLanguageString.get('en')">{{product.instructionSet.durationOfApplicationLanguageString.get('en')}}</li>
                </ul>
              </div>
              <div class="w30 flex justify-content-end"><img class="quantity-image" *ngIf="product.instructionSet?.quantityImage?.length" [src]="product.instructionSet.quantityImage[0]" width="100" height="100"/></div>
            </div>
            <div class="product-details" *ngIf="!product.product?.id">
              <div class="w100">
                <div class="col-xs-12">
                  <input class="form-control product-select" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_morning_product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
                  <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
                    <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option, 'morning', i)">{{option.name}}</mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
            <div class="product-edit-section" *ngIf="!disableEdit"><i> <img src="../../assets/images/border_color.png" style="height: 17px; width: 17px; margin-top: 10px;" (click)="editProductInstruction(i, 'morning')"/></i><i> <img src="../../assets/images/delete.png" style="height: 17px; width: 17px; margin-top: 10px;" (click)="removeProduct(i, 'morning')"/></i>
              <div class="flex"><span style="margin-top: 10px; font-weight: 700; color: #000; font-size: 16px !important">on hold</span>
                <input class="toggleProduct" type="checkbox" id="dayProduct{{i}}" [(ngModel)]="product.onHold" name="holdSelectedMorning{{i}}"/>
                <label class="label" for="dayProduct{{i}}"></label>
              </div>
            </div>
          </div>
          <div class="add-product-button" *ngIf="!disableEdit" (click)="addNewProduct('morning')">Add new +</div>
        </div>
      </div>
      <div class="flex flex-1" style="background-color: #EDE8F1; align-self: stretch !important">
        <div class="products night-product flex-1 h-auto" cdkDropList="cdkDropList" [cdkDropListData]="nightProducts" (cdkDropListDropped)="moveProduct($event)">
          <div class="product-title">
            <div class="icon night-product-index"><i class="fas fa-moon-o"></i></div><span>Night routine</span>
          </div>
          <div class="product" *ngFor="let product of nightProducts; let i = index">
            <div class="index night-product-index">{{i + 1}}</div>
            <div class="product-details" *ngIf="product.product?.id" cdkDrag="cdkDrag" [cdkDragData]="product">
              <div class="w60">
                <div class="product-name" *ngIf="product.product?.get('title')">{{product.product.get("title")}}</div>
                <ul class="otherDetails">
                  <li *ngIf="product.instructionSet && product.instructionSet.frequencyOfApplicationLanguageString">{{product.instructionSet.frequencyOfApplicationLanguageString?.get('en')}}</li>
                  <li *ngIf="product.instructionSet && product.instructionSet.quantityUnitLanguageString">{{product.instructionSet.quantity}} {{product.instructionSet.quantityUnitLanguageString.get('en')}}</li>
                  <li *ngIf="product.instructionSet && product.instructionSet.areaOfApplicationLanguageString">{{product.instructionSet.areaOfApplicationLanguageString.get('en')}}</li>
                  <li *ngIf="product.instructionSet && product.instructionSet.durationOfApplicationLanguageString && product.instructionSet.durationOfApplicationLanguageString.get('en')">{{product.instructionSet.durationOfApplicationLanguageString.get('en')}}</li>
                </ul>
              </div>
              <div class="w40 flex justify-content-end"><img class="quantity-image" *ngIf="product.instructionSet?.quantityImage?.length" [src]="product.instructionSet.quantityImage[0]" width="100" height="100"/></div>
            </div>
            <div class="product-details" *ngIf="!product.product?.id">
              <div class="w100">
                <div class="col-xs-12">
                  <input class="form-control product-select" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_night_product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
                  <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
                    <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option, 'night', i)">{{option.name}}</mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
            <div class="product-edit-section" *ngIf="!disableEdit"><i><img src="../../assets/images/border_color.png" style="height: 17px; width: 17px; margin-top: 10px;" (click)="editProductInstruction(i, 'night')"/></i><i> <img src="../../assets/images/delete.png" style="height: 17px; width: 17px; margin-top: 10px;" (click)="removeProduct(i, 'night')"/></i>
              <div class="flex"><span style="margin-top: 10px; font-weight: 700; color: #000; font-size: 16px !important">on hold</span>
                <input class="toggleProduct" type="checkbox" id="nightProduct{{i}}" [(ngModel)]="product.onHold" name="holdSelectedNight{{i}}"/>
                <label class="label" for="nightProduct{{i}}"></label>
              </div>
            </div>
          </div>
          <div class="add-product-button" *ngIf="!disableEdit" (click)="addNewProduct('night')">Add new +</div>
          <ng-container *ngIf="isEditAllowed">
            <button class="btn stop-btn" *ngIf="disableEdit && showRegimenEditButton" (click)="onEditRegimen()"><i class="fas fa-edit"></i><span class="ml-3 fontR">Edit Regimen</span></button>
            <button class="btn save-btn" *ngIf="!disableEdit" (click)="onSaveRegimen()"><i class="fas fa-save"></i><span class="ml-3 fontR">Save Regimen</span></button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
