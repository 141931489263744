import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FeaturedProductsComponent } from './featured-products.component';

const ROUTES: Routes = [
  {
    path: '',
    component: FeaturedProductsComponent,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  FormsModule,
  CommonModule,
  RouterModule.forChild(ROUTES),
],
declarations: [FeaturedProductsComponent],
exports: [FeaturedProductsComponent] })
export class FeaturedProductsModule {}
