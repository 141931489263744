import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Table, ApiClientConstant, ApiConnector } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';
import { Broadcaster } from '../broadcaster';

@Component({
  selector: 'consent-form',
  templateUrl: 'consent-form.html',
  styleUrl: 'consent-form.scss',
})

export class ConsentFormComponent implements OnInit {
    @Input('currentUser') currentUser: any;
    @Input('user') user: any;
    @Input('order') order: any;
    regimen: any;
    @Output('sendFormEmitter') sendFormEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('cancelFormEmitter') cancelFormEmitter: EventEmitter<void> = new EventEmitter<void>();
    @Input('isFinasterideConsent') isFinasterideConsent: boolean = false;
    @Input('isIsotretConsent') isIsotretConsent: boolean = false;
    @Input('selectedRegimen') selectedRegimen: any;
    showIsotretConsentForm: boolean = false;
    showFinasterideConsentForm: boolean = false;

    constructor(private conn: ConnectionService,
        private appConfig: AppConfig,
        private broadcast: Broadcaster) {
    }

    async ngOnInit(): Promise<void> {
      if (this.order) {
        this.regimen = this.order.get('regimen');
        if (this.user.get('Gender') === this.appConfig.Shared.Gender.MALE
        && this.regimen.get('class') === this.appConfig.Shared.Regimen.Class.HAIR) { this.showFinasterideConsentForm = true; }
        if (this.regimen.get('class') === this.appConfig.Shared.Regimen.Class.FACE) { this.showIsotretConsentForm = true; }
      } else {
        if (this.user.get('Gender') === this.appConfig.Shared.Gender.MALE
        && this.selectedRegimen.get('class') === this.appConfig.Shared.Regimen.Class.HAIR) { this.showFinasterideConsentForm = true; }
        if (this.selectedRegimen.get('class') === this.appConfig.Shared.Regimen.Class.FACE) { this.showIsotretConsentForm = true; }
      }
    }

    sendConsentFormHandler(isIsotretConsent: boolean): void {
      this.sendFormEmitter.emit(isIsotretConsent);
    }

    cancelConsentFormSendingHandler(): void {
      this.cancelFormEmitter.emit();
    }

    async triggerFinasterideConsentForm(): Promise<void> {
      const supportTicketObject = new Table.SupportTicket();
      try {
        if (this.currentUser.get('type') !== ApiClientConstant.User.Type.DOCTOR) {
          this.broadcast.broadcast('NOTIFY', { message: 'Only Doctor Can Send Finasteride Consent Form',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        const supportQuestion = await ApiConnector.findOne(Table.SupportQuestion, {
          where: { question: 'Finasteride Consent Form' },
          include: ['questionLanguageString'],
        });
        const languageString = await ApiConnector.findOne(Table.LanguageString, {
          where: { en: 'Finasteride Consent Form' },
        });
        if (!this.user) {
          return;
        }
        supportTicketObject.set('title', languageString?.get(this.user?.get('languagePreference')) || 'Finasteride Consent Form');
        supportTicketObject.set('user', this.user);
        supportTicketObject.set('status', ApiClientConstant.SupportTicket.Status.Pending);
        supportTicketObject.set('languagePreference', this.user?.get('languagePreference'));
        supportTicketObject.set('teams', ['doctor']);
        supportTicketObject.set('supportQuestion', supportQuestion);
        supportTicketObject.set('allocatedDoctor', this.currentUser);
        supportTicketObject.set('titleLanguageString', languageString);
        supportTicketObject.set('skipReAllocateDoctor', true);
        await supportTicketObject.save();
      } catch (error) {
        this.broadcast.broadcast('NOTIFY', { message: 'Some Problem in Sending Finasteride Consent Form',
          type: this.appConfig.Shared.Toast.Type.ERROR });
      }
      const context = { supportTicketId: supportTicketObject.id };
      try {
        const questionId = `consentFormFinasterideMale${this.user.get('languagePreference')
          .charAt(0).toUpperCase() + this.user.get('languagePreference').slice(1)}`;
        await this.conn.sendQuestionTo(questionId, this.user.get('username'), context);
      } catch (error) {
        const questionId = 'consentFormFinasterideMaleEn';
        await this.conn.sendQuestionTo(questionId, this.user.get('username'), context);
      }
      try {
        await this.conn.sendQuestionTo('consentFormFinasterideAcknowledgement', this.user.get('username'), context);
      } catch (error) {
        this.broadcast.broadcast('NOTIFY', { message: 'Some Problem in Sending Finasteride Consent Form',
          type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    }

    async triggerIsotretConsentForm(): Promise<void> {
      const supportTicketObject = new Table.SupportTicket();
      try {
        if (this.currentUser.get('type') !== this.appConfig.Shared.User.Type.DOCTOR) {
          this.broadcast.broadcast('NOTIFY', { message: 'Only Doctor Can Send Isotret Consent Form',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        const supportQuestion = await ApiConnector.findOne(Table.SupportQuestion, {
          where: { question: 'Others' },
          include: ['questionLanguageString'],
        });
        const languageString = await ApiConnector.findOne(Table.LanguageString, {
          where: { en: 'Isotret Consent' },
        });
        if (!this.user || !supportQuestion) {
          return;
        }
        supportTicketObject.set('title', languageString?.get(this.user?.get('languagePreference')) || 'Isotret Consent Form');
        supportTicketObject.set('user', this.user);
        supportTicketObject.set('status', ApiClientConstant.SupportTicket.Status.Pending);
        supportTicketObject.set('languagePreference', this.user?.get('languagePreference'));
        supportTicketObject.set('teams', ['doctor']);
        supportTicketObject.set('supportQuestion', supportQuestion);
        supportTicketObject.set('allocatedDoctor', this.currentUser);
        supportTicketObject.set('titleLanguageString', languageString);
        supportTicketObject.set('skipReAllocateDoctor', true);
        await supportTicketObject.save();
        let questionId: string;
        switch (this.user.get('Gender')) {
          case 'Male': {
            questionId = this.user.get('languagePreference') === 'hi'
              ? 'consentFormIsotretinoinMaleHi'
              : 'consentFormIsotretinoinMaleEn';
            break;
          }
          case 'Female': {
            questionId = this.user.get('languagePreference') === 'hi'
              ? 'consentFormIsotretinoinFemaleHi'
              : 'consentFormIsotretinoinFemaleEn';
            break;
          }
          default: {
            this.broadcast.broadcast('NOTIFY', { message: 'Gender is not present',
              type: this.appConfig.Shared.Toast.Type.ERROR });
            return;
          }
        }
        await supportTicketObject.save();
        const context = { supportTicketId: supportTicketObject.id };
        await this.conn.sendQuestionTo(questionId, this.user.get('username'), context);
        await this.conn.sendQuestionTo('consentFormIsotretinoinAcknowledgement', this.user.get('username'), context);
      } catch (error) {
        this.broadcast.broadcast('NOTIFY', { message: 'Some Problem in Sending Isotret Consent Form',
          type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    }
}
