import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorage {
  static parameters: any = [];
  constructor() {}

  setJsonValue(field: string, value: any): void {
    localStorage.setItem(field, JSON.stringify(value));
  }

  getJsonValue(field: string): any {
    return JSON.parse(this.getValue(field));
  }

  getValue(field: string): any {
    return localStorage.getItem(field);
  }

  clear(): void {
    localStorage.clear();
  }
}
