import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as moment from 'moment';
import { Table } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'roster',
  templateUrl: './roster.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class RosterComponent {
  @Input('user')
  set onUpdateUser(user: any) {
    this.user = user;
    if (!this.user) {
      return;
    }
    this.fetchOrCreateRoster();
  }
  startDate: string;
  weeks: Array<Array<number>>;
  user: any;
  roster: any;

  constructor(private conn: ConnectionService, private appConfig: AppConfig) {
  }

  addWeek(): void {
    this.weeks.push([0, 0, 0, 0, 0, 0, 0]);
    this.updateWeek();
  }

  updateDate(): void {
    this.roster.set('startDate', moment(this.startDate).toDate());
  }

  removeWeek(index: number): void {
    this.weeks.splice(index, 1);
    this.updateWeek();
  }

  toggleWorking(weekNumber: number, dayOfWeek: number): void {
    this.weeks[weekNumber][dayOfWeek] = this.weeks[weekNumber][dayOfWeek] === 1 ? 0 : 1;
    this.updateWeek();
  }

  updateWeek(): void {
    this.roster.set('weeks', this.weeks);
  }

  async saveRoster(): Promise<void> {
    try {
      await this.roster.save();
      alert('Roster Updated.');
    } catch (error) {
      alert(error.message);
    }
  }

  private async fetchOrCreateRoster(): Promise<void> {
    this.roster = await this.conn.findRoster(this.user);
    if (!this.roster) {
      this.roster = new Table.Roster();
      this.roster.set('user', this.user);
      this.roster.set('weeks', []);
      this.roster.set('startDate', new Date());
    }
    this.startDate = moment(this.roster.get('startDate')).format('YYYY-MM-DD');
    this.weeks = this.roster.get('weeks');
  }
}
