import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegimenEditComponent } from './regimen-edit.component';
import { RegimenEditWithoutRouteModule } from './regimen-edit-without-route.module';
import { VoiceNoteAddModule } from '../../../../components/voice-note-add';
import { FollowUpSOPComponent } from './followup-sop/followup-sop.component';
import { RegimenEditProductComponent } from './regimen-edit-part-of-day/regimen-edit-product';

const ROUTES: Routes = [
  { path: '', component: RegimenEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RegimenEditWithoutRouteModule,
    VoiceNoteAddModule,
    RouterModule.forChild(ROUTES),
  ],
})
export class RegimenEditModule {
}
