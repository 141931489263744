import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { EditComponent } from './edit.component';
import { DirectivesModule } from '../../../../components/directives.module';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { LanguageViewModule } from '../../../../components/language-string/language-view';
import { InputTextareaModule } from '../../../../components/input/input-textarea';
import { QuestionConditionModule } from '../../../tree/question/edit/question-condition';
import { InputMultiSelectModule } from '../../../../components/input/input-multi-select';
import { TeamSearchModule } from '../../../../components/team-search/team-search.module';
import { AddQuestionModule } from '../../../tree/view/indication/edit/add-question/add-question.module';
import { SearchSupportQuestionModule } from '../../../../components/search-support-question/search-support-question.module';
import { SupportQuestionNameModule } from '../../../../components/support-question-name/support-question-name.module';

const ROUTES: Routes = [
  { path: '', component: EditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    InputTextModule,
    InputSelectModule,
    InputMultiSelectModule,
    InputCheckboxModule,
    MatDialogModule,
    InputTextareaModule,
    LanguageViewModule,
    LanguageStringSelectionModule,
    QuestionConditionModule,
    TeamSearchModule,
    SearchSupportQuestionModule,
    SupportQuestionNameModule,
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
})
export class EditModule {}
