
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading" #headingView>
    <div class="col-xs-12 list-header pl-4">
      <div class="pull-left"><span class="pR10">Media Link ({{count || 0}})</span></div>
      <div class="pull-right"><i class="fa fa-plus-square-o mr-3 pointer" routerLink="/products/margId/new"></i></div>
    </div>
  </div>
  <div class="flex-content"></div>
  <div class="list-view">
    <div class="list-sidebar">
      <div class="header">
        <div class="title">Filters</div>
        <button class="btn-xxs btn-secondary" (click)="reset()">Reset</button>
      </div>
      <div class="content">
        <div class="col-xs-12">
          <label>Search</label>
          <input class="w100" placeholder="Marg Id, Generic Name" (change)="reset()" [(ngModel)]="searchKey"/>
        </div>
      </div>
    </div>
    <div class="list-content">
      <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
    </div>
  </div>
</div>
