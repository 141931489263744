import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'add-diet-card-modal',
  templateUrl: './add-diet-card.html',
})
export class AddDietCardModal {
  diet: any = {};

  constructor(private dialogRef: MatDialogRef<AddDietCardModal>) {
  }

  hideModal(data?: any): void {
    this.dialogRef.close(data);
  }
}
