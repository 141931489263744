import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SendMessageSectionComponent } from './send-message-section.component';
import { AudioRecorderModule } from '../../../../components/audio-recorder/audio-recorder.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AudioRecorderModule,
  ],
  declarations: [
    SendMessageSectionComponent,
  ],
  exports: [
    SendMessageSectionComponent,
  ],
})
export class SendMessageSectionModule {}
