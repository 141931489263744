
<div class="hbox" *ngIf="regimenObj">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><span routerLink="/products/regimens">Regimens</span></li>
          <li><a routerLink="/products/regimen/{{regimenObj?.get('regimenId')}}">{{regimenObj?.get('regimenId')}}</a></li>
        </ul>
      </div>
    </div>
    <div class="flex-content p-4">
      <div class="col-xs-12 flex">
        <label class="col-xs-2 mt-0">Regimen Id</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get('regimenId')}}</div>
      </div>
      <div class="col-xs-12 flex" *ngIf="products.length">
        <label class="col-xs-2">Products</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8"><a class="inline product-label night" *ngFor="let product of products;" routerLink="/products/catalog/{{product.id}}">{{product.get('title')}} &nbsp; [{{product.get('quantity')}}{{product.get('quantityUnit')}}] {{product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</a></div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Skin Type</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get('skinType')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Sample Available</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get('sampleAvailable') ? 'YES' : 'NO'}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Skip Dr. approval</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get('skipDrApproval') ? 'YES' : 'NO'}}</div>
      </div>
      <div class="col-xs-12 flex" *ngIf="regimenObj.get('class')">
        <label class="col-xs-2">Regimen Class</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get('class')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Concern</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">
          <language-view [languageStringObj]="this.regimenObj.get('concernsLanguageString')"></language-view>
        </div>
      </div>
      <div class="col-xs-12 flex" *ngIf="regimenObj.get('whatToExpect')">
        <label class="col-xs-2">What to expect</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get('whatToExpect')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Duration</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get('durationInWeek')}} Weeks</div>
      </div>
      <div class="col-xs-12" *ngIf="regimenObj.get('morning').length">
        <label class="col-xs-2">Morning</label>
        <div class="col-xs-8">
          <div class="col-xs-12 no-padder">
            <ul class="nav">
              <li *ngFor="let item of regimenObj.get('morning'); let i = index;">
                <div class="col-xs-12 product-morning-view">
                  <label class="col-xs-12 mt-0">{{item.product.get('title')}} ({{item.purpose}}) [{{item.product.get('quantity')}}{{item.product.get('quantityUnit')}}] {{item.product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? ('(' + item.product.get('type').toUpperCase() + ')') : ''}}</label>
                  <div class="col-xs-12 b-t py-2" *ngIf="item.purposeDescription">{{item.purposeDescription}}</div>
                  <div class="col-xs-12 b-t py-2" *ngIf="!regimenObj.get('showSummaryView')">{{item.instruction}}</div>
                  <del class="col-xs-12 b-t py-2" *ngIf="regimenObj.get('showSummaryView')">{{item.instruction}}</del>
                  <div *ngIf="item.instructionSet && regimenObj.get('showSummaryView')">
                    <catalog-instruction-view [instruction]="item.instructionSet" [type]="apiClientConstant.Order.Type.REGIMEN"></catalog-instruction-view>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="regimenObj.get('night').length">
        <label class="col-xs-2">Night</label>
        <div class="col-xs-8">
          <div class="col-xs-12 no-padder">
            <ul class="nav">
              <li *ngFor="let item of regimenObj.get('night'); let i = index;">
                <div class="col-xs-12 product-night-view">
                  <label class="col-xs-12 mt-0">{{item.product.get('title')}} ({{item.purpose}}) [{{item.product.get('quantity')}}{{item.product.get('quantityUnit')}}] {{item.product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? ('(' + item.product.get('type').toUpperCase() + ')') : ''}}</label>
                  <div class="col-xs-12 b-t py-2" *ngIf="item.purposeDescription">{{item.purposeDescription}}</div>
                  <div class="col-xs-12 b-t py-2" *ngIf="!regimenObj.get('showSummaryView')">{{item.instruction}}</div>
                  <del class="col-xs-12 b-t py-2" *ngIf="regimenObj.get('showSummaryView')">{{item.instruction}}</del>
                  <div *ngIf="item.instructionSet && regimenObj.get('showSummaryView')">
                    <catalog-instruction-view [instruction]="item.instructionSet" [type]="apiClientConstant.Order.Type.REGIMEN"></catalog-instruction-view>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xs-12 flex" *ngIf="regimenObj.get('dietAndLifestyleQuestion')">
        <label class="col-xs-2">Diet And Lifestyle</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get("dietAndLifestyleQuestion").get('title')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Products Price</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenPrice()}} Rs.</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Discount</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get("discount")}}%</div>
      </div>
      <div class="col-xs-12 flex" *ngIf="regimenObj.get('endDate')">
        <label class="col-xs-2">End Date</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get("endDate")| amDateFormat: 'MMM DD, YYYY'}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Note (Not Visible To User)</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get("note") || '-'}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Active</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{regimenObj.get('active') ? 'YES' : 'NO'}}</div>
      </div>
      <div class="col-xs-12 flex m-b-sm">
        <label class="col-xs-2">Regimen Link</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8"><a href="{{conn.getWebAppUrl()}}/user/?tab=regimen&amp;username={{user.get('username')}}&amp;regimenId={{regimenObj.get('regimenId')}}" target="_blank">Click To View</a></div>
      </div>
      <div class="col-xs-12 flex" *ngIf="!!regimenObj.get('regimenCoverImage')">
        <label class="col-xs-2">Regimen Cover Image</label>
        <div class="col-pull-left">:<img style="padding-left:15px" height="100px" widht="100px" src="{{regimenObj.get('regimenCoverImage')}}"/></div>
      </div>
    </div>
  </div>
</div>
