import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectionService } from '../../../../services/connection-service';
import { HelperService } from '../../../../services/helper-service';
import { TimeService } from '../../../../services/time-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'cart-list', templateUrl: './cart-list.html', styleUrls: ['./cart-list.scss'] })
export class CartListComponent {
  @Output() updatedCartId: EventEmitter<any> = new EventEmitter();
  @Input('user')
  set userFunction(user: any) {
    this.user = user;
    if (!this.user || !this.user.id) {
      this.cart = '';
      return;
    }
    this.fetchUserCart();
  }
  @Input('hideTitle') hideTitle: boolean;
  user: any;
  cart: any;
  products: Array<any> = [];

  constructor(public appConfig: AppConfig,
      private conn: ConnectionService,
      private time: TimeService,
      private window: WindowRefService,
      private router: Router,
      public helper: HelperService,
      private dom: DomSanitizer) {
  }

  async ngOnInit(): Promise<any> { }

  async fetchUserCart(): Promise<any> {
    const cart: any = await this.conn.findCart({
      where: { user: this.user },
    });
    this.cart = JSON.parse(JSON.stringify(cart));
    if (this.user.get('languagePreference') === 'en') return;
    const productsId = this.cart.lineItems.map((product: any) => product.productId);
    this.products = JSON.parse(JSON.stringify(await this.conn.findCatalogs({ where: { objectId: productsId }, project: ['title'] })));
    this.products.forEach((product: any) => {
      const index = this.cart.lineItems.indexOf((product_: any) => product_.productId === product.objectId);
      this.cart.lineItems[index].title = product.title;
    });
  }
}
