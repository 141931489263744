import { Component, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { Broadcaster } from '../broadcaster';
import { AddConcernModal } from '../followUp-report-creation/add-concern-popup';

@Component({ selector: 'treatment-progress-add', templateUrl: './treatment-progress-add.html', styleUrls: ['treatment-progress-add.scss'] })
export class TreatmentProgressAddDialog implements OnInit {
  treatmentProgress: Array<{ issue: string, concernLanguageString: any, score: number }>;
  mainConcernList: Array<any>;
  class: string;

  constructor(public appConfig: AppConfig,
    private dialog: MatDialog,
    private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private injector: Injector,
    public dialogRef: MatDialogRef<TreatmentProgressAddDialog>) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.treatmentProgress = data.treatmentProgress;
    this.class = data.class;
  }
  async ngOnInit(): Promise<any> {
    await this.fetchMainConcernList();
  }

  async fetchMainConcernList(): Promise<void> {
    this.mainConcernList = await this.conn.fetchMainConcerns({
      where: { class: this.class, section: 'TREATMENT' },
      include: ['displayNameLanguageString'],
      project: ['displayNameLanguageString.en' as 'displayNameLanguageString', 'aiClassName'],
    });
  }
  addNewConcern(): void {
    const dialogRef = this.dialog.open(AddConcernModal, {
      data: { mainConcernList: this.mainConcernList },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (this.treatmentProgress.find((progress: any) => progress.issue === result.issue)) {
        this.broadcaster.broadcast('NOTIFY', { message: 'concern already exists', type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
      this.treatmentProgress.push({ issue: result.issue, concernLanguageString: result.concernLanguageString, score: 1 });
    });
  }
  deleteBar(index: number): void {
    this.treatmentProgress.splice(index, 1);
  }
  updateScore(index: number, score: number): void {
    this.treatmentProgress[index].score = score;
  }
  hideModal(isSave: boolean): void {
    if (!isSave) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.treatmentProgress);
    }
  }
}
