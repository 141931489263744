import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { LanguageStringApprovalComponent } from './language-string-approval.component';
import { LanguageStringTagSelectionModule } from '../../../components/language-string/language-string-tag-selection';

const ROUTES: Routes = [
  {
    path: '',
    component: LanguageStringApprovalComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    LanguageStringTagSelectionModule,
    AgGridAngular,
  ],
  declarations: [LanguageStringApprovalComponent],
  exports: [LanguageStringApprovalComponent],
})
export class LanguageStringApprovalModule {}
