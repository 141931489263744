import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as EXIF from 'exif-js';

@Component({ selector: 'image', templateUrl: './image.html' })
export class ImageComponent {
  src: any;
  imgWidth: any;
  loading: boolean = true;
  @ViewChild('imageContainer', { static: false }) imgCtnr: ElementRef;
  @ViewChild('img', { static: false }) img: ElementRef;
  @Input()
  set data(data: string) {
    this.src = data;
  }
  @Input()
  set width(w: any) {
    this.imgWidth = w;
  }
  orientation: any;
  constructor(private dom: DomSanitizer) {
  }

  ngOnInit(): void {
    const img: any = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = (): void => {
      this.loading = false;
      EXIF.getData(img, (): void => {
        this.orientation = EXIF.getTag(img, 'Orientation');
        let rotation;
        if (this.orientation === 6) rotation = 'rotate(90deg)';
        if (this.orientation === 8) rotation = 'rotate(-90deg)';
        if (rotation) {
          this.imgCtnr.nativeElement.style.transform = rotation;
          this.imgCtnr.nativeElement.style.height = this.imgWidth || `${img.width}px`;
        }
      });
    };
    img.src = this.src;
  }
}
