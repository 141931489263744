
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="list-header col-xs-12">
        <ul class="breadcrumb">
          <li><a routerLink="/ecom/coupons">Coupons</a></li>
          <li>{{ coupon?.id ? 'Edit' : 'New' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content p-4">
      <form class="col-xs-12" *ngIf="coupon" #form="ngForm" (submit)="saveCoupon()">
        <div class="col-xs-12 col-md-6">
          <div class="col-xs-12 mt-0 mb-3">
            <label class="col-xs-5">Code</label>
            <div class="col-xs-7">
              <input class="form-control" (keyup)="codeChange($event)" [(ngModel)]="couponCode" required="required" name="code" placeholder="Enter Code (eg: DIWALI_300, SALES_YUVAN_200)"/>
            </div>
          </div>
          <div class="col-xs-12 mb-3">
            <label class="col-xs-5">Amount</label>
            <div class="col-xs-7">
              <input-text [parseObj]="coupon" [required]="true" name="amount" placeholder="Enter discount amount" [disabled]="coupon.get('percentage') > 0" type="number"></input-text>
            </div>
          </div>
          <div class="col-xs-12 mb-3">
            <label class="col-xs-5">Description</label>
            <div class="col-xs-7">
              <language-string-selection [parseObj]="coupon" [tags]="['coupon_description']" name="descriptionLanguageString" [required]="coupon.get('showInPaymentPage')"></language-string-selection>
            </div>
          </div>
          <div class="col-xs-12 mb-3">
            <label class="col-xs-5">Percentage</label>
            <div class="col-xs-7">
              <input-text [parseObj]="coupon" required="required" name="percentage" placeholder="Enter discount percentage" [disabled]="coupon.get('amount') > 0" type="number"></input-text>
            </div>
          </div>
          <div class="col-xs-12 mb-3">
            <label class="col-xs-5">Max Usage Limit</label>
            <div class="col-xs-7">
              <input-text [parseObj]="coupon" required="required" name="maxUsageLimit" placeholder="" type="number" [min]="0"></input-text>
            </div>
          </div>
          <div class="col-xs-12 mb-3">
            <label class="col-xs-5">Max Usage Limit Per User</label>
            <div class="col-xs-7">
              <input-text class="disabled" [parseObj]="coupon" required="required" name="perUserMaxUsageLimit" placeholder="" type="number" [min]="0"></input-text>
            </div>
          </div>
          <div class="col-xs-12 mb-3">
            <label class="col-xs-5">Active</label>
            <div class="col-xs-7">
              <input-checkbox [parseObj]="coupon" required="required" name="active"></input-checkbox>
            </div>
          </div>
          <div class="col-xs-12 mb-3">
            <label class="col-xs-5">Order Types</label>
            <div class="col-xs-7">
              <input-multi-select [parseObj]="coupon" name="orderTypes" [options]="orderTypes" required="required"></input-multi-select>
            </div>
          </div>
          <div class="col-xs-12 mb-3">
            <label class="col-xs-5">Show In Payment Page</label>
            <div class="col-xs-7">
              <input-checkbox [parseObj]="coupon" required="required" name="showInPaymentPage"></input-checkbox>
            </div>
          </div>
          <div class="col-xs-12">
            <button class="btn-xs" type="submit" [disabled]="!form.valid">{{ coupon.id ? 'Update' : 'Create' }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>