<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">SBAR History</h4><i class="fas fa-times pull-right fontL3 ml-3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="col-xs-12 flex bold fontL">
      <span class="col-xs-2">situation</span>
      <span class="col-xs-3">background</span>
      <span class="col-xs-2">assessment</span>
      <span class="col-xs-3">recommendation</span>
      <span class="col-xs-1">category</span>
      <span class="col-xs-1">createdBy</span>
      <span class="col-xs-1">createdAt</span>
    </div>
    <div class="col-xs-12 flex bdr-btm" *ngFor="let sbar of sbarHistory">
      <span class="col-xs-2">{{sbar.get('situation')}}</span>
      <span class="col-xs-3">{{sbar.get('background')}}</span>
      <span class="col-xs-2">{{sbar.get('assessment')}}</span>
      <span class="col-xs-3">{{sbar.get('recommendation')}}</span>
      <span class="col-xs-1">{{sbar.get('category')}}</span>
      <span class="col-xs-1 clr-link" *ngIf="sbar.get('createdBy')">{{sbar.get('createdBy')?.get('username')}}</span>
      <span class="col-xs-1" >{{sbar.get('createdAt') | amDateFormat: 'DD/MM/YYYY'}}</span>
    </div>
  </div>
</mat-dialog-content>
