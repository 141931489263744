import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { KioskCallsModule } from 'src/app/calls/kiosk-calls/kiosk-calls.module';
import { DirectivesModule } from '../../../../components/directives.module';
import { OfflineRegimenListComponent } from './list.component';
import { LanguageStringTagSelectionModule } from '../../../../components/language-string/language-string-tag-selection';

const ROUTES: Routes = [
  { path: '', component: OfflineRegimenListComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    LanguageStringTagSelectionModule,
    KioskCallsModule,
    AgGridAngular,
  ],
  declarations: [OfflineRegimenListComponent],
  exports: [OfflineRegimenListComponent],
})
export class RegimenListModule {}
