import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConnectionService } from '../../../services/connection-service';

@Component({ selector: 'view', templateUrl: './view.html' })
export class ViewComponent {
  embeddedLink: any;
  iframeLink: SafeResourceUrl;

  static parameters: any = [ActivatedRoute, ConnectionService, DomSanitizer];
  constructor(private route: ActivatedRoute,
              private conn: ConnectionService,
              private sanitizer: DomSanitizer) {
  }

  async ngOnInit(): Promise<any> {
    this.embeddedLink = await this.conn.findEmbeddedLinkById(this.route.parent.snapshot.params.id);
    const url = this.embeddedLink.get('signedURL') || this.embeddedLink.get('link');
    this.iframeLink = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
