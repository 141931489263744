import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderComponent } from './order.component';
import { OrderViewModule } from './view';
import { ListModule } from './list';
import { OrderEditModule } from './edit';
import { OrderResolve } from './order-resolver';
import { StageModule } from './stage';
import { OrderShipNowModule } from './ship-now';
import { OrderApprovalModule } from './approval';
import { DoctorApprovalModule } from './doctor-approval/doctor-approval.module';
import { RefundModule } from './refund';
import { RefundComponent } from './refund/refund.component';

const ROUTES: Routes = [
  {
    path: 'orders',
    component: OrderComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'orders/shipNow',
    component: OrderComponent,
    loadChildren: (): any => OrderShipNowModule,
    pathMatch: 'full',
  },
  {
    path: 'orders/approval',
    component: OrderComponent,
    loadChildren: (): any => DoctorApprovalModule,
    pathMatch: 'full',
  },
  {
    path: 'orders/new',
    component: OrderComponent,
    loadChildren: (): any => OrderEditModule,
    pathMatch: 'full',
  },
  {
    path: 'orders/refund',
    component: RefundComponent,
    loadChildren: (): any => RefundModule,
    pathMatch: 'full',
  },
  {
    path: 'orders/stages',
    component: OrderComponent,
    loadChildren: (): any => StageModule,
    pathMatch: 'full',
  },
  {
    path: 'order/:id',
    component: OrderComponent,
    loadChildren: (): any => OrderViewModule,
    resolve: { order: OrderResolve },
    pathMatch: 'full',
  },
  {
    path: 'order/:id/edit',
    component: OrderComponent,
    loadChildren: (): any => OrderEditModule,
    resolve: { order: OrderResolve },
    pathMatch: 'full',
  },
  {
    path: 'order/:id/approval',
    component: OrderComponent,
    loadChildren: (): any => OrderApprovalModule,
    resolve: { order: OrderResolve },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [OrderResolve],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [OrderComponent],
  exports: [OrderComponent] })
export class OrderModule {}
