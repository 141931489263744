import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceComponent } from './service.component';
import { ServiceViewModule } from './view';
import { ListModule } from './list';
import { ServiceEditModule } from './edit';
import { ServiceResolve } from './service-resolver';

const ROUTES: Routes = [
  {
    path: 'services',
    component: ServiceComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'services/new',
    component: ServiceComponent,
    loadChildren: (): any => ServiceEditModule,
    pathMatch: 'full',
  },
  {
    path: 'service/:id',
    component: ServiceComponent,
    loadChildren: (): any => ServiceViewModule,
    resolve: { catalog: ServiceResolve },
    pathMatch: 'full',
  },
  {
    path: 'service/:id/edit',
    component: ServiceComponent,
    loadChildren: (): any => ServiceEditModule,
    resolve: { catalog: ServiceResolve },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [ServiceResolve],
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [ServiceComponent],
  exports: [ServiceComponent] })
export class ServiceModule {}
