import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AudioTemplateComponent } from './audio-template.component';
import { ListModule } from './list/list.module';

const ROUTES: Routes = [
  {
    path: 'audioTemplate',
    component: AudioTemplateComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [AudioTemplateComponent],
  exports: [AudioTemplateComponent],
})
export class AudioTemplateModule {}
