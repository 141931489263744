import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../components/directives.module';
import { CallListComponent } from './call-list.component';
import { ComingInstantCheckupListModule } from './coming-instant-checkup-list';

const ROUTES: Routes = [
  {
    path: 'comingInstantCheckupCallList',
    component: CallListComponent,
    loadChildren: (): any => ComingInstantCheckupListModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  CommonModule,
  MomentModule,
  DirectivesModule,
  RouterModule.forChild(ROUTES),
],
declarations: [CallListComponent],
exports: [CallListComponent] })
export class CallListModule {}
