import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { Table } from 'api-client';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'list-action-cell', templateUrl: './list-action-cell.html' })
export class ListActionCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  id: string;
  deleted: boolean;
  ui: { loading: boolean } = { loading: false };

  constructor(private appConfig: AppConfig) {}

  agInit(params: ICellRendererParams): void {
    this.id = params.value;
    this.params = params;
  }

  async deleteItem(): Promise<void> {
    try {
      this.ui.loading = true;
      const event = new Table.LanguageStringTag();
      event.id = this.id;
      await event.destroy();
      this.deleted = true;
      this.ui.loading = false;
    } catch (error) {
      alert(error.message);
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
