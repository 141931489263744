import { Component, NgZone, OnDestroy } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestQueryPayload, ApiConnector, Table } from 'api-client';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConnectionService } from '../../../../services/connection-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { FilterConstants } from '../../../../components/filter-dropdown/filter-constants';

@Component({ selector: 'marg-id-info-list', templateUrl: './marg-id-info-list.html' })
export class MargIdInfoListComponent {
  window: any;
  gridApi: GridApi;
  user: any;
  ui: any = {};
  dataSource: any;
  columnDefs: any;
  components:any;
  count:number;
  filters: any = [{}];
  searchKey: string;
  serarchOn: Array<string> = ['genericName', 'margId'];

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private storage: LocalStorage,
    private router: Router,
    private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.user = this.conn.getCurrentUser();
    this.ui = { isFilterOpen: false, grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.listViewSetup();
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };

    this.columnDefs = [
      {
        headerName: 'MargId',
        field: 'margId',
        width: 400,
        filter: true,
      },
      {
        headerName: 'Generic Name',
        field: 'genericName',
        width: 400,
        filter: true,
      },
    ];
  }

  async loadMore(params:any): Promise<any> {
    const requestPayload: RequestQueryPayload<Table.MargIdInfo> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      project: ['margId', 'genericName'],
    };
    if (this.searchKey) {
      requestPayload.where.$or = this.serarchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    const [data, count]: [Array<unknown>, number] = await Promise.all([
      ApiConnector.find(Table.MargIdInfo, requestPayload)
        .then((items: any) => items.map((i: any) => JSON.parse(JSON.stringify(i)))),
      ApiConnector.count(Table.MargIdInfo,
        requestPayload),
    ]);
    this.count = count;
    return data;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }
}
