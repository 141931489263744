
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Payment</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form #paymentForm="ngForm">
      <div class="col-xs-12">
        <label class="col-xs-12 mt-0">Payment Source</label>
        <div class="col-xs-12">
          <select class="col-xs-6" [(ngModel)]="payment.source" required="required" name="text">
            <option *ngFor="let source of paymentSources" value="{{source}}">{{source}}</option>
          </select>
        </div>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-12">Amount</label>
        <div class="col-xs-12">
          <input class="col-xs-12" [(ngModel)]="payment.amount" name="amount" required="required" type="number" min="0"/>
        </div>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-12">Notes</label>
        <div class="col-xs-12">
          <textarea class="col-xs-12" [(ngModel)]="payment.note" name="note" required="required"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="submit" [disabled]="!paymentForm.valid" (click)="savePayment()">Create</button>
  </div>
</mat-dialog-content>