import { Component, Input } from '@angular/core';
import { ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { HelperService } from '../../../../services/helper-service';
import { TimeService } from '../../../../services/time-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({ selector: 'call-logs', templateUrl: './call-logs.html', styleUrls: ['./call-logs.scss'] })
export class CallLogsComponent {
  @Input('user')
  set userFunction(user: any) {
    this.user = user;
    if (!this.user || !this.user.id) {
      this.callLogs = [];
      return;
    }
    this.fetchUserCallLogs();
  }
  user: any;
  callLogs: Array<any> = [];
  operatorList: any = {};

  constructor(public appConfig: AppConfig,
    private conn: ConnectionService,
    private time: TimeService,
    private window: WindowRefService,
    private broadcasterService: Broadcaster,
    public helper: HelperService) {
  }

  async ngOnInit(): Promise<any> {
  }

  async fetchUserCallLogs(): Promise<any> {
    const callLogs = await ApiConnector.find(Table.CallLog, {
      where: { user: this.user },
      descending: 'createdAt',
      project: ['direction', 'to', 'from', 'status', 'calledAt', 'conversationDuration'],
    });
    this.callLogs = callLogs;
    const mobileNumber = Array.from(new Set(this.callLogs
      .map((each: any) => (['outgoing', 'outbound-api'].includes(each.get('direction')) ? each.get('from')?.slice(1)
        : each.get('to')?.slice(1)))));
    const operatorList = await ApiConnector.find(Table.User,
      { where: { exotelNumber: { $in: mobileNumber } }, project: ['username', 'exotelNumber', 'DoctorDisplayName'] });
    operatorList.forEach((each: any) => this.operatorList[`0${each.get('exotelNumber')}`] = each.get('DoctorDisplayName')
      || each.get('username'));
  }

  callUserThroughExotel(number: any): void {
    this.conn.callThroughExotel(number, 'chatPage')
      .then(() => this.broadcasterService.broadcast('NOTIFY', { message: 'Connecting',
        type: this.appConfig.Shared.Toast.Type.SUCCESS }))
      .catch((error: any) => this.broadcasterService.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR }));
  }
}
