import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref-service';

@Injectable()
export class FaviconService {
  window: any;
  static parameters: any = [WindowRefService];
  constructor(windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow;
  }

  setFavicon(name: string, isNonUserPage?: boolean): void {
    const linkElement = this.window.document.createElement('link');
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', 'image/png');
    if (isNonUserPage) {
      linkElement.setAttribute('href', '/assets/images/logo.png');
      this.window.document.head.appendChild(linkElement);
      return;
    }
    const letter = this.getFavicon(name);
    linkElement.setAttribute('href', `/assets/images/favicon_letter_${letter}.png`);
    this.window.document.head.appendChild(linkElement);
  }

  private getFavicon(name: string): string {
    return name ? name[0].toLowerCase() : '';
  }
}
