
<div class="pTB20">
  <div class="alert-warning py-2 px-3 mb-3 fontS2">To record multiple chunks of audio & merge them. Do (Start -> Record -> Stop) & Repeat & finally merge the chunks.</div>
  <div class="col-xs-12 p0 flex" style="align-items:center;">
    <button class="btn btn-primary mR15 {{btnClass}}" (click)="startRecording()" [disabled]="ui.recording">Start</button>
    <button class="btn btn-danger mR15 {{btnClass}}" (click)="stopRecording()" [disabled]="!ui.recording">Stop</button>
    <button class="btn btn-secondary mR15 {{btnClass}}" (click)="resetAudio()" [disabled]="!blob">Reset</button>
    <button class="btn btn-info mR15 {{btnClass}}" *ngIf="blobCollection.length" (click)="mergeAudio()">Merge</button>
    <div class="font-bold" *ngIf="!saveInProcess">{{ui.recordingTime}} Sec</div>
    <div class="font-bold" *ngIf="saveInProcess">Uploading Audio ...</div>
  </div>
  <div class="col-xs-12 mT15" #audioPlayer></div>
  <div class="col-xs-12 mt-2 fontS2 bg-danger p-2 bold" *ngIf="isVoiceToTextSupported && isMobileBrowser">Voice to text might not working in Mobile or Tablet devices.</div>
  <div class="col-xs-12 mt-2 fontS2 bg-danger p-2 bold" *ngIf="!isVoiceToTextSupported">Voice Recognition is not supported in this browser.</div>
  <div class="col-xs-12 mt-2 fontS2 bg-danger p-2 bold" *ngIf="error">{{error}}</div>
</div>
