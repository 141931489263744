import { Component, NgZone, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router, Event, NavigationEnd } from '@angular/router';
import { ApiConnector, Table } from 'api-client';
import { Broadcaster } from '../components/broadcaster';
import { ConnectionService } from '../services/connection-service';
import { TabNamingService } from '../services/tab-naming-service';
import { WindowRefService } from '../services/window-ref-service';
import { AppConfig } from './app.config';
import { ElasticService } from '../services/elastic-search';
import { FaviconService } from '../services/favicon-service';

@Component({
  selector: 'app',
  templateUrl: './app.html',
  styleUrls: ['../styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  window: any;
  router: Router;
  broadcaster: Broadcaster;
  zone: NgZone;
  user: any;
  conn: ConnectionService;
  toast: { message?: string | object, type?: string } = { message: '', type: '' };
  timeout: any;
  showAppUpdate: boolean;
  hideNavigator: boolean;

  constructor(
    router: Router,
    windowRef: WindowRefService,
    broadcaster: Broadcaster,
    zone: NgZone,
    conn: ConnectionService,
    public appConfig: AppConfig,
    private elasticSearch: ElasticService,
    private titleService: TabNamingService,
    private faviconService: FaviconService) {
    this.window = windowRef.nativeWindow;
    this.router = router;
    this.broadcaster = broadcaster;
    this.zone = zone;
    this.conn = conn;
  }

  ngOnInit(): void {
    this.window.ApplicationClass = this;
    const loadTime = this.window.performance.now() - this.window.DOCTOR_LOAD_TIME;
    this.elasticSearch.log({ track: 'DOCTOR_SITE_LOAD_TIME', params: { time: loadTime } });
    this.router.events.subscribe((event: Event) => {
      this.user = ApiConnector.getCurrentUser();
      if (event instanceof NavigationStart) {
        this.faviconService.setFavicon('', true);
        if (event.url !== '/login' && !this.user) {
          return this.router.navigate(['/login']);
        }
        if (event.url.startsWith('/patient')) {
          this.hideNavbar();
        }
        if (event.url.startsWith('/order/') && event.url.endsWith('/approval')) {
          this.hideNavbar();
        }
        if (this.user && this.user.get('username') === this.appConfig.Shared.ExternalUser.username
          && (!event.url.startsWith('/user/instantCheckup'))) {
          return this.window.history.back();
        }
        if (this.user && this.user.get('username') !== this.appConfig.Shared.ExternalUser.username && this.user && event.url === '/login') {
          return this.router.navigate(['/chats']);
        }
      }
      if (event instanceof NavigationEnd) {
        this.titleService.setTitle(event.url);
        this.broadcaster.broadcast('ChatUserUpdate', { url: event.url });
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
      return 0;
    });
    if (this.user) this.setClarityKeys();

    this.broadcaster.on<object>('NOTIFY_APP_UPDATE')
      .subscribe(() => this.showAppUpdate = true);

    this.broadcaster.on<object>('NOTIFY')
      .subscribe(({ message, type, timeout }: { message: string | object, type: string, timeout: number }) => {
        clearTimeout(this.timeout);
        this.toast.message = (message instanceof Object) ? JSON.stringify(message) : message;
        this.toast.type = type || this.appConfig.Shared.Toast.Type.SUCCESS;
        this.timeout = setTimeout(() => this.toast = { message: '', type: '' }, timeout || 3000);
      });

    const logoutSubscription = this.broadcaster.on<string>('UserLogout');
    logoutSubscription.subscribe(() => this.zone.run(() => (delete this.user)));
    const loginSubscription = this.broadcaster.on<string>('UserLogin');
    loginSubscription.subscribe(() => {
      this.zone.run(() => {
        this.user = this.conn.isUserLoggedIn
          ? this.conn.getCurrentUser()
          : new Table.User();
        if (this.user.id) {
          this.setClarityKeys();
        }
      });
    });
  }

  async hideNavbar(): Promise<void> {
    if (!this.user) return;
    this.hideNavigator = true;
  }

  setClarityKeys(): void {
    this.window.clarity('set', 'type', this.conn.getCurrentUser().get('type'));
    this.window.clarity('set', 'username', this.conn.getCurrentUser().get('username'));
    this.window.clarity('set', 'id', this.conn.getCurrentUser().id);
  }

  broadcastEvent(eventName: string, value: string): void {
    this.zone.run(() => {
      try {
        this.broadcaster.broadcast(eventName, JSON.parse(value));
      } catch (e) {
        this.broadcaster.broadcast(eventName, value);
      }
    });
  }
}
