import { Component, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiClientConstant, Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { InputType } from '../../../../../typings/client/input';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent {
  id: string;
  mainConcernId: any;
  mainConcern: any;
  classes: Array<any> = [];
  selectedClass: string = '';
  keywords: Array<any> = [];
  tempKeywords: Array<any> = [];
  modifiedKeywords: Array<any> = [];
  genderOption: Array<InputType.SelectOption> = [];

  constructor(public appConfig: AppConfig, private router: Router, private route: ActivatedRoute,
    private conn: ConnectionService, private zone: NgZone) {
  }
  async ngOnInit(): Promise<any> {
    this.mainConcernId = this.route.snapshot.params.id;
    if (this.mainConcernId) {
      this.mainConcern = await this.conn.findMainConcernsById(this.mainConcernId);
      this.keywords = this.mainConcern.get('keywords');
      this.keywords.map((each:any) => this.tempKeywords.push(each.keyLanguageString));
      this.mainConcern.set('tempKeywords', this.tempKeywords);
    } else {
      this.mainConcern = new Table.MainConcern();
    }
    this.classes = [ApiClientConstant.Regimen.Class.BODY,
      ApiClientConstant.Regimen.Class.FACE,
      ApiClientConstant.Regimen.Class.HAIR];
    if (this.mainConcern) {
      this.selectedClass = this.mainConcern.get('class');
    }
    this.genderOption = [
      { display: ApiClientConstant.User.Gender.MALE, value: ApiClientConstant.User.Gender.MALE },
      { display: ApiClientConstant.User.Gender.FEMALE, value: ApiClientConstant.User.Gender.FEMALE },
      { display: ApiClientConstant.User.Gender.BOTH, value: ApiClientConstant.User.Gender.BOTH },
    ];
  }

  async saveMainConcern(): Promise<any> {
    await this.setKeywords();
    await this.mainConcern.save();
    this.router.navigate(['/trees/main-concerns']);
  }

  setKeywords(): void {
    this.mainConcern.unset('tempKeywords');
    this.tempKeywords.map((each:any) => this.modifiedKeywords.push({ keyLanguageString: each }));
    this.mainConcern.set('keywords', this.modifiedKeywords);
  }

  changeMainConcernClass(): void {
    this.mainConcern.set('class', this.selectedClass);
  }
}
