class FilterConstants {
  static DATA_TYPE: {
    FIXED: string,
    BOOLEAN: string,
    STRING: string,
  } = { FIXED: '0', BOOLEAN: '1', STRING: '2' };
  static FILTER_TYPE: any = {
    BOOLEAN_EQUAL_TO: 'BOOLEAN_EQUAL_TO',
    EQUAL_TO: 'EQUAL_TO',
    EXISTS: 'EXISTS',
    DOES_NOT_EXITS: 'DOES_NOT_EXITS',
    STARTS_WITH: 'STARTS_WITH',
    ENDS_WITH: 'ENDS_WITH',
  };
}

export { FilterConstants };
