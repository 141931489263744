import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { LanguageViewModule } from '../../../../../../components/language-string/language-view';
import { DirectivesModule } from '../../../../../../components/directives.module';
import { CatalogSelectInstructionToCopyComponent } from './catalog-select-instruction-to-copy.component';

@NgModule({
  imports: [CommonModule, FormsModule, LanguageViewModule, DirectivesModule, MatDialogModule],
  declarations: [CatalogSelectInstructionToCopyComponent],
  exports: [CatalogSelectInstructionToCopyComponent],
})
export class CatalogSelectInstructionToCopyModule {
}
