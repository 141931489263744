import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { TimeService } from '../../../../services/time-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { HelperService } from '../../../../services/helper-service';

@Component({ selector: 'order-list', templateUrl: './order-list.html', styleUrls: ['./order-list.scss'] })
export class OrderListComponent {
  @Output() updatedOrderId: EventEmitter<any> = new EventEmitter();
  @Input('user')
  set userFunction(user: any) {
    this.user = user;
    if (!this.user || !this.user.id) {
      this.orders = [];
      return;
    }
    this.fetchUserOrders();
  }

  @Input('selectedOrderType')
  set updateSelectedOrderType(selectedOrderType: any) {
    this.orderType = selectedOrderType;
    this.fetchUserOrders();
  }
  @Input('hideTitle') hideTitle: boolean;
  user: any;
  orders: Array<any> = [];
  isOlderThanTwoMonth: boolean = false;
  isMacDevice: boolean;
  webAppUrl: any;
  orderType: string;

  public subscriptions: Subscription[] = [];

  constructor(public appConfig: AppConfig,
    private conn: ConnectionService,
    private time: TimeService,
    private window: WindowRefService,
    private router: Router,
    public helper: HelperService,
    private dom: DomSanitizer) {
  }

  async ngOnInit(): Promise<any> {
    this.isMacDevice = this.window.nativeWindow.navigator.userAgent.toLowerCase().includes('mac');
  }

  async fetchUserOrders(): Promise<any> {
    const payload: RequestQueryPayload<Table.Order> = {
      where: { user: this.user },
      include: ['products', 'services', 'regimen'],
      descending: 'createdAt',
      project: [
        'partialProductOrder',
        'regimenId',
        'stage',
        'deliveredOn',
        'products.title' as 'products',
        'products.quantity' as 'products',
        'products.quantityUnit' as 'products',
        'products.allowAsAddon' as 'products',
        'products.type' as 'products',
        'products.price' as 'products',
        'services.type' as 'services',
        'services.title' as 'services',
        'regimen',
        'amount',
        'productInfo',
        'type',
      ],
    };
    if (this.orderType && this.orderType !== 'ALL') {
      payload.where.stage = this.orderType;
    }
    const orders = await this.conn.findOrders(payload);
    this.orders = orders.map((each: any) => each.toJSON());
    if (this.orders[0] && this.time.addDays(new Date(this.orders[0].deliveredOn), 60) < new Date()) {
      this.isOlderThanTwoMonth = true;
    }
  }

  openOrderChat(id: string): void {
    this.updatedOrderId.emit(id);
  }
}
