import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SchedulerComponent } from './scheduler.component';
import { ListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'scheduler',
    component: SchedulerComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [SchedulerComponent],
  exports: [SchedulerComponent],
})
export class SchedulerModule {
}
