
<ng-container *ngIf="params.data">
    <i class="fa fa-check-circle-o pointer" *ngIf="!completed" (click)="markCallCompleted()"></i>
    <i class="fa fa-check-circle-o pointer text-success ml-3" *ngIf="completed"></i>
    <a class="fa fa-external-link pointer ml-3" *ngIf="link" href="{{link}}" target="_blank"></a>
    <span class="call-icon">
      <em class="fas fa-phone-alt ml-3" *ngIf="primaryNumber" (click)="callUserThroughExotel(primaryNumber)" title="Mobile"></em>
      <em class="fas fa-phone-alt alt-2 ml-3" *ngIf="secondaryNumber" (click)="callUserThroughExotel(secondaryNumber)" title="Alternate Mobile"></em>
    </span>
    <i class="fa fa-history ml-3" *ngIf="params?.data?.user?.objectId" (click)="openRecentPendingCallHistory()"></i>
</ng-container>
