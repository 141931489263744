
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="col-xs-12 bg-light">
      <div class="col-xs-12 no-padder no-margin font-bold">Routes {{ui.loading}}</div>
      <div class="col-xs-12">Filters :<span class="m-l-sm">Questions:
          <label class="i-switch i-switch-sm bg-success dker m-l-xs" style="top: 3px;">
            <input class="ng-pristine ng-valid ng-touched" type="checkbox" (change)="updateTreeFilter(ui.filters.question)" [(ngModel)]="ui.filters.question.status" name="enableQuestionFilter"/><i></i>
          </label></span><span class="m-l-sm">Questions With Answer:
          <label class="i-switch i-switch-sm bg-success dker m-l-xs" style="top: 3px;">
            <input class="ng-pristine ng-valid ng-touched" type="checkbox" (change)="updateTreeFilter(ui.filters.questionWithAnswer)" [(ngModel)]="ui.filters.questionWithAnswer.status" name="enableQuestionWithAnswerFilter"/><i></i>
          </label></span><span class="m-l-sm">Products:
          <label class="i-switch i-switch-sm bg-success dker m-l-xs" style="top: 3px;">
            <input class="ng-pristine ng-valid ng-touched" type="checkbox" (change)="updateTreeFilter(ui.filters.products)" [(ngModel)]="ui.filters.products.status" name="enableProductsFilter"/><i></i>
          </label></span></div>
      <div class="col-xs-12 flex" style="flex-wrap:wrap;"><span class="label label-warning m-t-xs m-l-xs pointer m-b-xs m-b-xs" *ngFor="let item of ui.filters.question.list; let i = index;">{{item}}<i class="fa fa-times-rectangle-o m-l-xs" (click)="updateFilterSelectionOnFilter(item, ui.filters.question)"></i></span><span class="label label-success m-t-xs m-l-xs pointer m-b-xs m-b-xs" *ngFor="let item of ui.filters.questionWithAnswer.list; let i = index;">{{item}}<i class="fa fa-times-rectangle-o m-l-xs" (click)="updateFilterSelectionOnFilter(item, ui.filters.questionWithAnswer)"></i></span><span class="label label-danger m-t-xs m-l-xs pointer m-b-xs m-b-xs" *ngFor="let item of ui.filters.products.list; let i = index;">{{item}}<i class="fa fa-times-rectangle-o m-l-xs" (click)="updateFilterSelectionOnFilter(item, ui.filters.products)"></i></span></div>
    </div>
    <div class="col-xs-12 text-center" *ngIf="ui.loading"><i class="fa fa-spinner fa-spin"></i></div>
  </div>
  <div class="flex-content">
    <div class="col-xs-12">
      <div class="col-xs-12 no-padder" *ngFor="let group of displayGroupKeys;let i = index">
        <div class="col-xs-12 bg-black no-padder m-b-xs m-t-sm" *ngIf="i"></div>
        <div class="col-xs-2 no-padder no-margin">
          <div class="col-xs-12 no-padder no-margin">{{group}}</div>
          <div class="col-xs-12 no-margin no-padder flex" *ngIf="regimenMap[group]" style="flex-wrap:wrap;"><span class="label label-info m-t-xs m-l-xs pointer" *ngFor="let product of regimenMap[group];" [ngClass]="{ 'label-danger': ui.filters.products.list.includes(product) }" (click)="updateProductFilterSelection(product)">{{product}}</span></div>
        </div>
        <div class="col-xs-10 no-padder no-margin">
          <table>
            <tr class="b-b" *ngFor="let routes of displayGroups[group];">
              <td class="wrapper-xs no-padder-left no-margin-top" *ngFor="let route of routes; let col = index;">
                <div class="label label-default m-t-xs m-l-xs col-xs-12 pointer" *ngFor="let answer of route.answers;" [ngClass]="{ 'label-hide': route.hideInUI, 'label-info': (col === routes.length - 1), 'label-warning': ui.filters.question.list.includes(route.fieldName), 'label-success': ui.filters.questionWithAnswer.list.includes(route.fieldNameAnswers) }" [attr.title]="questionTooltip[route.fieldName]" data-html="true" (click)="updateFilterSelection(route)" style="text-align:left;">{{route.fieldName}}<span *ngIf="answer">| {{answer}}</span></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>