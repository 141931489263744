import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent, ColDef } from 'ag-grid-community';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { ApiConnector, ParseKeys, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { LocalStorage } from '../../../../services/local-storage-service';

@Component({ selector: 'regimen-list', templateUrl: './list.html' })
export class RegimenListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  activeRegimen: boolean = true;
  dataSource: any;
  window: any;
  components: any;
  ui: any = { grid: { isFilterOpen: false, rowModelType: 'infinite', pageSize: 100 } };
  columnDefs: ColDef[];
  count: any;

  // Local Variables
  filterByTag: any = { tags: [] };
  searchKey: string;
  user: any;
  userRoles: Array<any> = [];
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.user = this.conn.getCurrentUser();
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const data = await this.loadMore(params);
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Title',
        field: 'title',
        pinned: 'left',
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) {
            const eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = 'Loading ...';
            return eDiv;
          }
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<a class='col-xs-12 no-padder ellipsis' target="_blank" href='products/regimen/${
            params.data.regimenId}'>${params.value}</a>`;
          return eDiv;
        },
        width: 350,
        filter: true,
      },
      {
        headerName: 'Actions',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) return '';
          const { data }: any = params;
          const eDiv = this.window.document.createElement('div');
          let html = '';
          const id = data.regimenId;
          if (this.userRoles.includes('regimenEditor')) {
            html += `<a class='fa fa-pencil-square-o pR10' target="_blank" href='/products/regimen/${id}/edit'></a>`;
          }
          if (this.userRoles.includes('regimenEditor')) {
            html += `<a class='fa fa-copy pR10' target="_blank" href='/products/regimens/new?copy=${params.data.objectId}'></a>`;
          }
          html += `<a class='fa fa-eye' href='${this.conn.getWebAppUrl()}/user?tab=regimen&username=${
            this.user.get('username')}&regimenId=${id}' target='_blank'></a>`;
          eDiv.innerHTML = html;
          return eDiv;
        },
      },
      { headerName: 'Id', field: 'regimenId', width: 150, filter: true, sortable: true },
      { headerName: 'Class', field: 'class', width: 100, filter: true, sortable: true },
      { headerName: 'F.Price', field: 'fixedPrice', width: 100, sortable: true },
      { headerName: 'Concern', field: 'concern', width: 200, filter: true, sortable: true },
      { headerName: 'approvalPercentage', field: 'approvalPercentage', width: 100, filter: true, sortable: true },
      {
        headerName: 'Products',
        field: 'products',
        cellRenderer: (params: any): any => {
          if (!params.data) {
            return '';
          }
          const eDiv = this.window.document.createElement('div');
          const products = [];
          params.value.forEach((each: any) => {
            products.push(each.title);
          });
          eDiv.innerHTML = products.join(', ');
          return eDiv;
        },
        width: 800,
        filter: true,
      },
    ];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.filterByTag = [];
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  toggleActiveRegimen(): void {
    this.reset();
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  async loadMore(params: any): Promise<any> {
    const searchOn = ['title', 'class', 'skinType', 'regimenId', 'concern'];
    const requestPayload: RequestQueryPayload<Table.Regimen> = {
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      where: { active: this.activeRegimen, type: 'MAIN' },
      include: ['products'],
      project: ['regimenId', 'title', 'class', 'fixedPrice', 'products.title' as 'products', 'concern', 'tags', 'approvalPercentage'],
    };
    const { colId, sort }: { colId: ParseKeys<Table.Regimen>, sort: 'asc' | 'desc' } = params.sortModel[0]
      || { colId: 'regimenId', sort: 'asc' };

    if (sort === 'desc') {
      requestPayload.descending = colId;
    } else {
      requestPayload.ascending = colId;
    }
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((e: any) => (requestPayload.where[e] = params.filterModel[e].filter));
    }
    if (this.filterByTag.tags.length) {
      requestPayload.where.$and = [{ tags: this.filterByTag.tags }];
    }
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    const [data, count]: [Array<unknown>, number] = await Promise.all([
      ApiConnector.find(Table.Regimen, requestPayload)
        .then((items: any) => items.map((i: any) => JSON.parse(JSON.stringify(i)))),
      ApiConnector.count(Table.Regimen, requestPayload),
    ]);
    this.count = count;
    return data;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.ui;
  }
}
