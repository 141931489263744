import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { ServiceViewComponent } from './service-view.component';

const ROUTES: Routes = [
  { path: '', component: ServiceViewComponent, pathMatch: 'full' },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  DirectivesModule,
  RouterModule.forChild(ROUTES),
],
declarations: [ServiceViewComponent],
exports: [ServiceViewComponent] })
export class ServiceViewModule {
}
