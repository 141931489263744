import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { WindowRefService } from 'src/services/window-ref-service';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { AddConcernModal } from './add-concern-popup';
import { Broadcaster } from '../broadcaster';
import { InputType } from '../../../typings/client/input';
import { DataUploadModal } from '../modal/dataUploadModal';
import { InstantCheckupImageView } from '../instant-checkup-image-view';
import { ImageComparisonPopup } from '../image-comparison-popup';
import { AutoFaceComponent } from '../auto-face/components/auto-face/auto-face.component';
import { ComparisonViewerComponent } from '../comparison-viewer/comparison-viewer.component';

interface ReportObject {
  imageConfigs: Array<{ beforeInstantId?: string, afterInstantId?: string, imageConfig?: any }>;
  treatmentProgress: Array<{
    issue: string,
    concernLanguageString: any,
    score: number,
  }>;
  investigations: {
    tests?: string[],
    name?: string,
    testsStatus?: { each: string, status: boolean }[],
    feedback?: string,
  };
  treatmentOutcome: { selectedTreatmentOutcome: string };
}

@Component({
  selector: 'followup-report-creation',
  templateUrl: './followUp-report-creation.html',
  styleUrls: ['./followUp-report-creation.scss'],
})
export class FollowUpReportCreationComponent {
  @Input('class')
  set onUpdateClass(className: string) {
    if (!className) return;
    this.class = className;
    this.fetchDataAndStartComparing();
  }
  @Input('user') user: any;
  @Input('followUp') followUp: any;
  @Input('regimen')
  set onUpdateRegimen(regimen: any) {
    if (!regimen) return;
    this.regimen = regimen;
    this.fetchFollowUpSopOptions();
  }
  @Output() createReport: EventEmitter<Partial<ReportObject>> = new EventEmitter();
  @Output() changeRegimen: EventEmitter<Array<any>> = new EventEmitter();
  @Output() changeVoiceNote: EventEmitter<string> = new EventEmitter();
  @Output() disableRegimenEdit: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(AutoFaceComponent) AutoFaceComponent: AutoFaceComponent;
  @ViewChild('secondaryComparison') SecondaryComparisonViewerComponent: ComparisonViewerComponent;
  @ViewChild('primaryComparison') PrimaryComparisonViewerComponent: ComparisonViewerComponent;
  reportObject: Partial<ReportObject> = { imageConfigs: [{}, {}] };
  editedCheckup: boolean = false;
  class: string;
  mainConcernList: Array<any>;
  investigationOptions: Array<{ display: string, value: Table.Investigations}> = [];
  primaryInstantCheckUpIds: Array<string>;
  secondaryInstantCheckUpIds: Array<string>;
  treatmentOutcomeOptions: Array<InputType.SelectTextOption> = [];
  instantCheckups: Array<any> = [];
  primaryComparisonTitle: string;
  secondaryComparisonTitle: string;
  treatmentProgress: Array<{ issue: string, concernLanguageString: any, score: number }> = [];
  followUpSopOptions: Array<InputType.SelectTextOption> = [];
  followUpSop: {
    selectedFollowUpSopName?: string,
    changes?: Array<any>,
    currentFollowUpSop?: Array<any>,
    selectedFollowUpSop?: {
      changes: Array<any>,
      tests: any,
      nextFollowUps: Array<any>,
      treatmentProgress: Array<any>,
      audioTemplateName: string,
      name: string,
    },
  } = {};
  regimen: any;
  followUpSopEnabled: boolean;
  showSteroidQuestion: boolean = true;
  constructor(public appConfig: AppConfig,
    private dialog: MatDialog,
    private broadcaster: Broadcaster,
    private connectionService: ConnectionService,
    private window: WindowRefService) {}

  async ngOnInit(): Promise<void> {
    await this.fetchInvestigations();
    this.fetchTreatmentOutcomeOptions();
    await this.fetchFollowUpSopOptions();
  }

  async reset(): Promise<void> {
    this.primaryInstantCheckUpIds = [];
    this.secondaryInstantCheckUpIds = [];
    this.treatmentProgress = [];
    this.reportObject = { imageConfigs: [{}, {}] };
    this.mainConcernList = [];
  }

  async fetchDataAndStartComparing(): Promise<void> {
    await this.reset();
    await this.automaticCompare(this.class);
    await this.fetchMainConcernList();
    await this.findDetectedConcerns();
    await this.updateTitleOnBasisOfClass();
  }

  updateTitleOnBasisOfClass(): void {
    switch (this.class) {
      case ApiClientConstant.FollowUpReport.Type.FACE: {
        this.primaryComparisonTitle = 'Front face comparison';
        this.secondaryComparisonTitle = 'Side face comparison';
        break;
      }
      case ApiClientConstant.FollowUpReport.Type.HAIR: {
        this.primaryComparisonTitle = 'Hair front comparison';
        this.secondaryComparisonTitle = 'Hair top comparison';
        break;
      }
      case ApiClientConstant.FollowUpReport.Type.BODY: {
        this.primaryComparisonTitle = 'Body comparison';
        break;
      }
      default:
    }
  }

  async fetchMainConcernList(): Promise<void> {
    this.mainConcernList = await this.connectionService.fetchMainConcerns({
      where: { class: this.class, section: 'TREATMENT' },
      include: ['displayNameLanguageString'],
      project: ['displayNameLanguageString.en' as 'displayNameLanguageString', 'aiClassName'],
    });
  }

  fetchTreatmentOutcomeOptions(): void {
    this.treatmentOutcomeOptions = [
      { display: 'Change as expected', value: 'CHANGE_AS_EXPECTED' },
      { display: 'Shift to secondary concern', value: 'SHIFT_TO_SECONDARY_CONCERN' },
      { display: 'Shift to maintenance', value: 'SHIFT_TO_MAINTENANCE' },
      { display: 'Slow improvement', value: 'SLOW_IMPROVEMENT' },
      { display: 'No change', value: 'NO_CHANGE' },
      { display: 'Slightly increased', value: 'SLIGHTLY_INCREASED' },
      { display: 'Flare up', value: 'FLARE_UP' },
    ];
  }

  // treatment progress section
  deleteBar(index: number): void {
    this.treatmentProgress.splice(index, 1);
    this.setCreateReport();
  }

  updateScore(index: number, score: number): void {
    this.treatmentProgress[index].score = score;
    this.setCreateReport();
  }

  addNewConcern(): void {
    const dialogRef = this.dialog.open(AddConcernModal, {
      data: { mainConcernList: this.mainConcernList },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (!result) return;
      if (this.treatmentProgress.find((progress: any) => progress.issue === result.issue)) {
        this.broadcaster.broadcast('NOTIFY', { message: 'concern already exists', type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
      this.treatmentProgress.push({ issue: result.issue, concernLanguageString: result.concernLanguageString, score: 1 });
      this.setCreateReport();
    });
  }

  async findDetectedConcerns(): Promise<any> {
    const instantCheckups = this.instantCheckups.filter((each: any) => this.primaryInstantCheckUpIds.includes(each.objectId));
    const concernsDetected = [];
    instantCheckups.forEach((each: any) => {
      if (each.aiResponse) {
        const aiResponseResult = each.aiResponse.result;
        concernsDetected.push(...(aiResponseResult.filter((each_: any) => each_.Condition === 'Detected')));
      }
    });
    if (this.class === ApiClientConstant.Regimen.Class.FACE) {
      const aiClassList: string[] = concernsDetected.map((each: any) => each.class);
      this.mainConcernList.forEach((each: any) => {
        if (!each.get('aiClassName')) return;
        each.get('aiClassName').forEach((className: string) => {
          if (aiClassList.includes(className)) {
            this.treatmentProgress.push({ issue: each.get('displayNameLanguageString')?.get('en'), concernLanguageString: each, score: 1 });
          }
        });
      });
    }
    this.reportObject.treatmentProgress = this.treatmentProgress;
    this.setCreateReport();
  }

  async fetchInvestigations(): Promise<void> {
    const investigations = await this.connectionService.fetchInvestigations();
    investigations.forEach((investigation: Table.Investigations) => {
      this.investigationOptions.push({ display: `${investigation.get('name')} (${investigation.get('tests')?.length})`,
        value: investigation });
    });
    const emptyTest = new Table.Investigations();
    emptyTest.set('name', 'No Tests');
    emptyTest.set('tests', []);
    this.investigationOptions.unshift({ display: 'No Tests', value: emptyTest });
  }

  addCustomTest(): void {
    const dialogRef = this.dialog.open(DataUploadModal, { data: { title: 'Custom Test Name' } });
    dialogRef.afterClosed().subscribe(async (data: string) => {
      if (!data) return;
      if (!this.reportObject.investigations) {
        this.broadcaster.broadcast('NOTIFY', {
          message: 'First Choose Investigation',
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
        return;
      }
      this.reportObject.investigations.tests.push(data);
      this.setCreateReport();
    });
  }

  removeTestFromInvestigation(index: any): void {
    if (index >= 0) this.reportObject.investigations.tests.splice(index, 1);
    this.setCreateReport();
  }

  onSelectInvestigation(investigation: any): void {
    if (!investigation) return;
    if (!investigation.get('tests').length) {
      this.reportObject.investigations = {};
      this.setCreateReport();
      return;
    }
    this.reportObject.investigations = {
      tests: investigation.get('tests'),
      name: investigation.get('name'),
      testsStatus: investigation.get('tests').map((each: any) => ({ name: each, status: false })),
      feedback: '',
    };
    this.setCreateReport();
  }

  onSelectTreatmentOutcome(outcome: string): void {
    if (!outcome) return;
    this.reportObject.treatmentOutcome = {
      selectedTreatmentOutcome: outcome,
    };
    this.setCreateReport();
  }

  selectBeforePhotoForInvestigation(index: number): void {
    // const query = {
    //   userId: this.user.get('username'),
    //   descending: 'createdAt',
    //   project: ['imagePath', 'type', 'compressedImagePath'],
    //   limit: 20,
    //   dateRange: {},
    // };
    let dateRange = { };
    if (this.followUp.get('markedReadyOn')) {
      const date = moment(this.followUp.get('markedReadyOn')).subtract(2, 'days').toDate();
      dateRange = { startDate: date };
    }
    const dialogRef = this.dialog.open(InstantCheckupImageView, { data: { userId: this.user.get('username'), dateRange } });
    dialogRef.afterClosed().subscribe(async (data: any) => {
      this.updateInstantCheckups(data, index);
    });
  }

  updateInstantCheckups(data: any, index: number): void {
    if (index === 0) {
      if (!this.primaryInstantCheckUpIds) this.primaryInstantCheckUpIds = [];
      if (data?.instantCheckUp?.objectId && this.primaryInstantCheckUpIds.length < 2) {
        this.primaryInstantCheckUpIds.push(data?.instantCheckUp?.objectId);
      }
      if (this.primaryInstantCheckUpIds.length === 2) {
        if (this.editedCheckup || this.class !== ApiClientConstant.FollowUpReport.Type.FACE) {
          this.PrimaryComparisonViewerComponent.loadData(this.primaryInstantCheckUpIds);
        } else {
          this.AutoFaceComponent.onUpdateInstantCheckupIds(this.primaryInstantCheckUpIds);
        }
      }
      return;
    }
    if (!this.secondaryInstantCheckUpIds) this.secondaryInstantCheckUpIds = [];
    if (data?.instantCheckUp?.objectId && this.secondaryInstantCheckUpIds.length < 2) {
      this.secondaryInstantCheckUpIds.push(data?.instantCheckUp?.objectId);
    }
    if (this.secondaryInstantCheckUpIds.length === 2) {
      this.SecondaryComparisonViewerComponent.loadData(this.secondaryInstantCheckUpIds);
    }
  }

  selectAfterPhotoForInvestigation(index: number): void {
    let dateRange = { };
    if (this.followUp.get('markedReadyOn')) {
      const date = moment(this.followUp.get('markedReadyOn')).subtract(2, 'days').toDate();
      dateRange = { endDate: date };
    }
    const dialogRef = this.dialog.open(InstantCheckupImageView, { data: { userId: this.user.get('username'), dateRange } });
    dialogRef.afterClosed().subscribe(async (data: any) => {
      this.updateInstantCheckups(data, index);
    });
  }

  async openImageComparisonPopup(index: number): Promise<void> {
    const instantCheckupIds = index === 0 ? this.primaryInstantCheckUpIds : this.secondaryInstantCheckUpIds;
    const params = {
      username: this.user.get('username'),
      id1: instantCheckupIds[0],
      id2: instantCheckupIds[1],
      index: 0,
    };
    const dialogRef = this.dialog.open(ImageComparisonPopup, { width: '90%', maxWidth: '90vw', data: { params } });
    dialogRef.afterClosed().subscribe(async (data: { config: any, params: any}) => {
      if (!data) return;
      let beforeInstant = this.instantCheckups.find((each: any) => each.objectId === data.params.id1);
      if (!beforeInstant) {
        beforeInstant = JSON.parse(JSON.stringify(await this.connectionService.findOneInstantCheckUp({
          where: { objectId: data.params.id1 },
          project: ['aiResponse', 'type'],
        })));
      }
      let afterInstant = this.instantCheckups.find((each: any) => each.objectId === data.params.id2);
      if (!afterInstant) {
        afterInstant = JSON.parse(JSON.stringify(await this.connectionService.findOneInstantCheckUp({
          where: { objectId: data.params.id2 },
          project: ['aiResponse', 'type'],
        })));
      }
      if (afterInstant.createdAt < beforeInstant.createdAt) {
        const temp = beforeInstant;
        beforeInstant = afterInstant;
        afterInstant = temp;
      }
      const imageConfig = {
        imageConfig: data.config,
        beforeInstantId: beforeInstant.objectId,
        afterInstantId: afterInstant.objectId,
      };
      if (index === 0) {
        this.primaryInstantCheckUpIds = [beforeInstant.objectId, afterInstant.objectId];
      } else {
        this.secondaryInstantCheckUpIds = [beforeInstant.objectId, afterInstant.objectId];
      }
      this.reportObject.imageConfigs[index] = imageConfig;
      if (index === 0) {
        this.editedCheckup = true;
      }
      this.setCreateReport();
    });
  }

  setImageConfig(event: any, index: number): void {
    if (!event) return;
    this.reportObject.imageConfigs[index] = event;
    this.setCreateReport();
  }

  setCreateReport(): void {
    this.createReport.emit(this.reportObject);
  }

  async automaticCompare(type: string): Promise<void> {
    const types = [];
    switch (type) {
      case 'FACE': {
        types.push(ApiClientConstant.InstantCheckup.Type.FULL_FACE);
        types.push(ApiClientConstant.InstantCheckup.Type.SIDE_FACE);
        types.push(ApiClientConstant.InstantCheckup.Type.FRONT_FACE);
        types.push(ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE);
        types.push(ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE);
        break;
      }
      case 'HAIR': {
        types.push(ApiClientConstant.InstantCheckup.Type.HAIR);
        types.push(ApiClientConstant.InstantCheckup.Type.HAIR_TOP);
        types.push(ApiClientConstant.InstantCheckup.Type.HAIR_FRONT);
        break;
      }
      case 'BODY': {
        types.push(ApiClientConstant.InstantCheckup.Type.BODY);
        break;
      }
      default:
    }
    this.instantCheckups = JSON.parse(JSON.stringify(await ApiConnector.find(Table.InstantCheckup, {
      where: { user: this.user, type: { $in: types }, createdAt: { $gte: moment().subtract(120, 'days').toDate() } },
      descending: 'createdAt',
      project: ['type', 'createdAt', 'aiResponse'],
    })));
    switch (type) {
      case 'FACE': {
        const frontFaceIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.FULL_FACE,
          ApiClientConstant.InstantCheckup.Type.FRONT_FACE], this.instantCheckups);
        const leftSideFaceIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE],
          this.instantCheckups);
        const rightSideFaceIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE],
          this.instantCheckups);
        const sideFaceIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.SIDE_FACE],
          this.instantCheckups);
        if (frontFaceIds.length !== 2) {
          this.broadcaster.broadcast('NOTIFY', { message: 'Can\'t find two front face',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.primaryInstantCheckUpIds = [frontFaceIds[0], frontFaceIds[1]];
        if (leftSideFaceIds.length === 2) {
          this.secondaryInstantCheckUpIds = [leftSideFaceIds[0], leftSideFaceIds[1]];
          return;
        }
        if (rightSideFaceIds.length === 2) {
          this.secondaryInstantCheckUpIds = [rightSideFaceIds[0], rightSideFaceIds[1]];
          return;
        }
        if (sideFaceIds.length === 2) {
          this.secondaryInstantCheckUpIds = [sideFaceIds[0], sideFaceIds[1]];
        }
        break;
      }
      case 'HAIR': {
        const hairTopIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.HAIR_TOP], this.instantCheckups);
        const hairFrontIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.HAIR_FRONT], this.instantCheckups);
        if (hairTopIds.length !== 2 && hairFrontIds.length !== 2) {
          this.broadcaster.broadcast('NOTIFY', { message: 'Can\'t find two hair Photo',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.primaryInstantCheckUpIds = [hairFrontIds[0], hairFrontIds[1]];
        break;
      }
      case 'BODY': {
        const bodyIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.BODY], this.instantCheckups);
        if (bodyIds.length !== 2) {
          this.broadcaster.broadcast('NOTIFY', { message: 'Can\'t find two Body photo',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.primaryInstantCheckUpIds = [bodyIds[0], bodyIds[1]];

        break;
      }
      default:
    }
  }

  getTwoComparisonOfType(types: Array<string>, instantCheckups_: Array<any>): Array<string> {
    const instantCheckups = instantCheckups_.filter((each: any) => types.includes(each.type));
    if (!instantCheckups?.length) return [];
    const instantCheckupIds = [];
    instantCheckupIds.push(instantCheckups[0].objectId);
    const minDiffBtwPhotos = this.followUp.get('followUpCount') === 1 ? 14 : 21;
    // eslint-disable-next-line no-restricted-syntax
    for (const each of instantCheckups) {
      if (moment(each.createdAt).isSameOrBefore(moment().subtract(minDiffBtwPhotos, 'days'))) {
        instantCheckupIds.push(each.objectId);
        break;
      }
    }
    if (instantCheckupIds.length !== 2 || instantCheckupIds[0] === instantCheckupIds[1]) {
      return [];
    }
    return instantCheckupIds;
  }

  deleteComparison(index: number): void {
    this.reportObject.imageConfigs[index] = {};
    switch (index) {
      case 0: {
        this.primaryInstantCheckUpIds = [];
        this.PrimaryComparisonViewerComponent?.loadData(this.primaryInstantCheckUpIds);
        this.AutoFaceComponent?.onUpdateInstantCheckupIds(this.primaryInstantCheckUpIds);
        break;
      } case 1: {
        this.secondaryInstantCheckUpIds = [];
        this.SecondaryComparisonViewerComponent.loadData(this.secondaryInstantCheckUpIds);
        break;
      }
      default:
    }
    this.setCreateReport();
  }

  async fetchFollowUpSopOptions(): Promise<void> {
    // if sop is not availabe in regimen then return
    if (!this.regimen || !this.regimen.get('followUpSop')?.length) return;

    // todo check selectedVariantId is coming
    if (this.regimen.get('selectedVariantId') === ApiClientConstant.Regimen.SelectedVariantId.variant_999
      || this.regimen.get('selectedVariantId') === ApiClientConstant.Regimen.SelectedVariantId.variant_1999
      || this.regimen.get('selectedVariantId') === ApiClientConstant.Regimen.SelectedVariantId.variant_4999
      || this.regimen.get('disableFollowUpSop')) {
      return;
    }

    if (this.followUp.get('followUpCount') === 1) {
      this.regimen.set('followUpSopTransition', []);
    }
    const followUpSopTransition = this.regimen.get('followUpSopTransition') || [];
    const followUpSop = this.regimen.get('followUpSop');

    // if till this followup we are following followupSop then only we can use followupSop
    if (followUpSopTransition?.length !== this.followUp.get('followUpCount') - 1) {
      return;
    }
    this.followUpSop.currentFollowUpSop = followUpSop;
    if (!followUpSopTransition?.length) {
      this.followUpSopEnabled = true;
      this.disableRegimenEdit.emit(true);
      this.updateFollowUpSopOptions(followUpSop);
      return;
    }
    let currentFollowUpSop = followUpSop;
    followUpSopTransition.forEach((transition: any) => {
      currentFollowUpSop = this.getFollowUpSopForCurrentTransition([...currentFollowUpSop], transition);
    });
    if (!currentFollowUpSop?.length) { return; }
    this.updateFollowUpSopOptions(currentFollowUpSop);
    this.followUpSop.currentFollowUpSop = currentFollowUpSop;
    this.followUpSopEnabled = true;
    this.disableRegimenEdit.emit(true);
  }

  updateFollowUpSopOptions(currentFollowUpSop: any): void {
    currentFollowUpSop.forEach((each: any) => {
      this.followUpSopOptions.push({ value: each.name, display: each.shortName || each.name });
    });
    this.followUpSopOptions.push({ value: 'other', display: 'other' });
  }

  getFollowUpSopForCurrentTransition(followUpSop_: any, transition: string): any {
    const [followUpSop]: any = followUpSop_.filter((each: any) => each.name === transition);
    return followUpSop.nextFollowUps;
  }

  async onSelectFollowUpSop(event: string): Promise<void> {
    if (event === 'other') {
      this.disableRegimenEdit.emit(false);
      this.changeRegimen.emit([]);
      this.followUpSopOptions = [];
      this.followUpSopEnabled = false;
      const dialogRef = this.dialog.open(DataUploadModal,
        { data: {
          title: 'Reason for disabling FollowUpSOP',
          minCharLength: 10,
        } });
      dialogRef.afterClosed().subscribe(async (result: any) => {
        if (!result) {
          await this.fetchFollowUpSopOptions();
          return;
        }
        if (result) {
          const details = {
            reason: result,
            followUpCount: this.followUp.get('followUpCount'),
            doctor: this.connectionService.getCurrentUser(),
          };
          this.regimen.set('disableFollowUpSop', true);
          this.regimen.set('followUpSopDisableReason', details);
        }
      });
      return;
    }
    this.followUpSop.selectedFollowUpSop = this.followUpSop.currentFollowUpSop
      .find((each: any) => each.name === event);
    this.changeRegimen.emit(this.followUpSop.selectedFollowUpSop.changes);
    const audioTemplateName = `${this.regimen.get('regimenId').split('_personalized_')[0]}`
      + `_${this.followUpSop.selectedFollowUpSop.name}_${(this.followUp.get('followUpCount') - 1) || 0}`;
    this.changeVoiceNote.emit(this.followUpSop.selectedFollowUpSop.audioTemplateName || audioTemplateName);
    this.disableRegimenEdit.emit(true);
    this.reportObject.investigations = this.followUpSop.selectedFollowUpSop?.tests;
    this.reportObject.treatmentProgress = this.followUpSop.selectedFollowUpSop?.treatmentProgress;
    this.reportObject.treatmentOutcome = { selectedTreatmentOutcome: event };
    await this.updateFollowUpSopTransition(event);
    this.setCreateReport();
  }

  async updateFollowUpSopTransition(selectedFollowUpSopName: string): Promise<void> {
    const followUpSopTransition = (this.regimen.get('followUpSopTransition') || []).slice(0, this.followUp.get('followUpCount') - 1);
    this.regimen.set('followUpSopTransition', [...followUpSopTransition, selectedFollowUpSopName]);
    this.regimen.set('disableFollowUpSop', false);
  }

  getBackgroundColor(score: number): string {
    switch (score) {
      case 0: return '#F9E7D7';
      case 1: return '#F3F3F3';
      case 3: return '#F3F3F3';
      case 4: return '#EBF1CE';
      default: return '';
    }
  }

  getTextForScore(score: number): string {
    switch (score) {
      case 0: return 'INCREASED';
      case 1: return 'SAME';
      case 3: return 'BETTER';
      case 4: return 'GOOD';
      default: return '';
    }
  }

  async updateSteroidTag(updateTag: boolean): Promise<void> {
    if (updateTag && !this.user.get('marketingTags').includes(this.appConfig.Shared.User.MarketingTags.STEROID)) {
      this.user.set('marketingTags', [...this.user.get('marketingTags'), this.appConfig.Shared.User.MarketingTags.STEROID]);
      await this.user.save();
    }
    this.showSteroidQuestion = false;
  }
}
