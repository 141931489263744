import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EasyAccessComponent } from './easyAccess.component';
import { ListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'easyAccess',
    component: EasyAccessComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [EasyAccessComponent],
exports: [EasyAccessComponent] })
export class EasyAccessModule {}
