
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/languageStrings">Language Strings</a></li>
          <li>{{languageStringObj?.id ? 'Edit' : 'New' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content col-xs-12 p-0">
      <form class="col-xs-12 col-md-8 p-4" #languageStringForm="ngForm">
        <div class="form-group col-xs-12" *ngIf="languageStringObj">
          <ng-container *ngIf="isRichText">
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">English</label>
              <div class="col-xs-12">
                <div class="wysiwyg" [appWysiwyg]="[appWysiwyg]" [parseObj]="languageStringObj" name="en" placeholder="English"></div>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">हिंदी (Hindi)</label>
              <div class="col-xs-12">
                <div class="wysiwyg" [appWysiwyg]="[appWysiwyg]" [parseObj]="languageStringObj" name="hi" placeholder="हिंदी"></div>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">ಕನ್ನಡ (Kannada)</label>
              <div class="col-xs-12">
                <div class="wysiwyg" [appWysiwyg]="[appWysiwyg]" [parseObj]="languageStringObj" name="kn" placeholder="ಕನ್ನಡ"></div>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">తెలుగు (Telugu)</label>
              <div class="col-xs-12">
                <div class="wysiwyg" [appWysiwyg]="[appWysiwyg]" [parseObj]="languageStringObj" name="te" placeholder="తెలుగు"></div>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">தமிழ் (Tamil)</label>
              <div class="col-xs-12">
                <div class="wysiwyg" [appWysiwyg]="[appWysiwyg]" [parseObj]="languageStringObj" name="ta" placeholder="தமிழ்"></div>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">മലയാളം (Malayalam)</label>
              <div class="col-xs-12">
                <div class="wysiwyg" [appWysiwyg]="[appWysiwyg]" [parseObj]="languageStringObj" name="ma" placeholder="മലയാളം"></div>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">বাংলা (Bengali)</label>
              <div class="col-xs-12">
                <div class="wysiwyg" [appWysiwyg]="[appWysiwyg]" [parseObj]="languageStringObj" name="bn" placeholder="বাংলা"></div>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">मराठी (Marathi)</label>
              <div class="col-xs-12">
                <div class="wysiwyg" [appWysiwyg]="[appWysiwyg]" [parseObj]="languageStringObj" name="mr" placeholder="मराठी"></div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isRichText">
            <div class="col-xs-12">
              <label class="col-xs-12 mt-0">English</label>
              <div class="col-xs-12">
                <input-textarea [parseObj]="languageStringObj" name="en" required="required" placeholder="English"></input-textarea>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">हिंदी (Hindi)</label>
              <div class="col-xs-12">
                <input-textarea [parseObj]="languageStringObj" name="hi" [required]="true" placeholder="हिंदी"></input-textarea>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">ಕನ್ನಡ (Kannada)</label>
              <div class="col-xs-12">
                <input-textarea [parseObj]="languageStringObj" name="kn" [required]="false" placeholder="ಕನ್ನಡ"></input-textarea>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">తెలుగు (Telugu)</label>
              <div class="col-xs-12">
                <input-textarea [parseObj]="languageStringObj" name="te" [required]="false" placeholder="తెలుగు"></input-textarea>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">தமிழ் (Tamil)</label>
              <div class="col-xs-12">
                <input-textarea [parseObj]="languageStringObj" name="ta" [required]="false" placeholder="தமிழ்"></input-textarea>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">മലയാളം (Malayalam)</label>
              <div class="col-xs-12">
                <input-textarea [parseObj]="languageStringObj" name="ma" [required]="false" placeholder="മലയാളം"></input-textarea>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">বাংলা (Bengali)</label>
              <div class="col-xs-12">
                <input-textarea [parseObj]="languageStringObj" name="bn" [required]="false" placeholder="বাংলা"></input-textarea>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">मराठी (Marathi)</label>
              <div class="col-xs-12">
                <input-textarea [parseObj]="languageStringObj" name="mr" [required]="false" placeholder="मराठी"></input-textarea>
              </div>
            </div>
          </ng-container>
          <div class="col-xs-12 mt-3">
            <language-string-tag-selection [parseObj]="languageStringObj" name="tags"></language-string-tag-selection>
          </div>
          <div class="col-xs-12 mt-4">
            <button class="btn-xs" (click)="saveLanguageString()" [disabled]="!languageStringForm.valid">{{languageStringObj?.id ? 'Update' : 'Create' }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>