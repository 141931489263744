import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegimenComponent } from './regimen.component';
import { RegimenViewModule } from './view';
import { HistoryViewModule } from './history-view';
import { RegimenListModule } from './list';
import { RegimenEditModule } from './edit';
import { RegimenResolve } from './regimen-resolver';
import { AlternateRegimenResolver } from './alternate-regimen-resolver';

const ROUTES: Routes = [
  {
    path: 'regimens',
    component: RegimenComponent,
    loadChildren: (): any => RegimenListModule,
  },
  {
    path: 'regimens/new',
    component: RegimenComponent,
    loadChildren: (): any => RegimenEditModule,
  },
  {
    path: 'regimen/:id',
    component: RegimenComponent,
    loadChildren: (): any => RegimenViewModule,
    resolve: { regimen: RegimenResolve },
  },
  {
    path: 'regimen/:id/edit',
    component: RegimenComponent,
    loadChildren: (): any => RegimenEditModule,
    resolve: { regimen: RegimenResolve },
  },
  {
    path: 'regimen/:id/alternates/new',
    component: RegimenComponent,
    loadChildren: (): any => RegimenEditModule,
    resolve: { regimen: RegimenResolve },
  },
  {
    path: 'regimen/:id/alternate/:regimenId',
    component: RegimenComponent,
    loadChildren: (): any => RegimenViewModule,
    resolve: {
      regimen: RegimenResolve,
      alternateRegimen: AlternateRegimenResolver,
    },
  },
  {
    path: 'regimen/:id/alternate/:regimenId/edit',
    component: RegimenComponent,
    loadChildren: (): any => RegimenEditModule,
    resolve: {
      alternateRegimen: AlternateRegimenResolver,
    },
  },
  {
    path: 'regimen/history/:id',
    component: RegimenComponent,
    loadChildren: (): any => HistoryViewModule,
  },
];

@NgModule({
  providers: [RegimenResolve, AlternateRegimenResolver],
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [RegimenComponent],
  exports: [RegimenComponent],
})
export class RegimenModule {}
