import { Component } from '@angular/core';
import { ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { MatDialog } from '@angular/material/dialog';
import { Broadcaster } from '../../components/broadcaster';
import { AddKioskDoctorModalComponent } from './add-kiosk-doctor-modal/add-kiosk-doctor-modal.component';
import { AppConfig } from '../app.config';

@Component({
  selector: 'offline-doctor-schedule',
  templateUrl: './offline-doctor-schedule.component.html',
  styleUrl: './offline-doctor-schedule.component.scss',
})
export class OfflineDoctorScheduleComponent {
  days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  doctors:any = [];
  selectedDoctorId: string;
  doctorsScheduleWithDays: any = {};
  doctorSchedule: any;

  constructor(
    private dialog: MatDialog,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
  ) {

  }
  async ngOnInit(): Promise<void> {
    await this.fetchKioskDoctors();
    await this.loadDoctorSchedule();
  }

  async loadDoctorSchedule(): Promise<void> {
    const payload: RequestQueryPayload<Table.OfflineDoctorSchedule> = { where: {} };
    this.doctorSchedule = await ApiConnector.findOne(Table.OfflineDoctorSchedule, payload);
    this.days.forEach((day: any) => {
      this.doctorsScheduleWithDays[day.toLowerCase()] = this.doctorSchedule.get(day.toLowerCase());
    });
  }

  async fetchKioskDoctors(): Promise<void> {
    const kioskDoctorRole:Parse.Role = await ApiConnector.findOne(Table.Role, {
      where: { name: 'kiosk-doctor' },
    });

    const usersRelation = kioskDoctorRole.relation('users');
    const kioskDoctors = await usersRelation.query().find();

    kioskDoctors.forEach((user: any, index: number): void => {
      this.doctors[index] = { DoctorDisplayName: user.get('DoctorDisplayName'),
        objectId: user.id };
    });
  }

  getDoctorNameFromObjectId(doctorId: string): string {
    const doctor = this.doctors.find((doctorDetails: any) => doctorDetails.objectId === doctorId);
    return doctor ? doctor.DoctorDisplayName : '';
  }

  convertMinutesToHours(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    return `${hours}`;
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  removeDoctor(index:any, doctorId: any, day: any): void {
    const dayData = this.doctorsScheduleWithDays[day];
    if (dayData) {
      dayData[index].doctorIds = dayData[index].doctorIds.filter((id: string) => id !== doctorId);
    }
    this.doctorsScheduleWithDays[day] = dayData;
    this.updateChangedDaySchedule(day);
  }

  updateChangedDaySchedule(day: string): void {
    this.doctorSchedule.set(day.toLowerCase(), this.doctorsScheduleWithDays[day]);
  }

  handleUpdate(): void {
    try {
      this.doctorSchedule.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'Modified doctor schedule successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  addDoctorToSchedule(data: any, day: string): void {
    this.dialog.open(AddKioskDoctorModalComponent, {
      data: { data, day, doctorsList: this.doctors, addDoctorToRoaster: this.addDoctorToRoaster.bind(this) },
    });
  }

  addDoctorToRoaster(startTime: string, endTime: string, doctorId: string, day: string): void {
    const start = this.convertHoursToMinutes(startTime);
    const end = this.convertHoursToMinutes(endTime);
    this.doctorsScheduleWithDays[day].forEach((hourData: any) => {
      if (hourData.start === start && hourData.end === end) {
        hourData.doctorIds.push(doctorId);
      }
    });
    this.dialog.closeAll();
    this.updateChangedDaySchedule(day);
    this.broadcaster.broadcast('NOTIFY', { message: 'Added doctor successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
  }

  convertHoursToMinutes(hours: string): string {
    const numericHours = parseFloat(hours);
    return (numericHours * 60).toString();
  }
}
