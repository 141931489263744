import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { ConnectionService } from 'src/services/connection-service';
import { Broadcaster } from 'src/components/broadcaster';
import { AddressBookModule } from 'src/app/order/edit/address-book-selection/address-book.module';
import { AddressBookEditModal } from 'src/app/order/edit/address-edit-selection';
import { MatDialog } from '@angular/material/dialog';
import { WindowRefService } from 'src/services/window-ref-service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'sales-patient-info',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AddressBookModule],
  templateUrl: './patient-info.component.html',
  styleUrl: './patient-info.component.scss',
})

export class PatientInfoComponent {
  @Input('username') username: string;
  @Input('order') order: any;
  @Input('user') user: any;
  @Input('parseObject') parseObject: any;
  addressOptions: Array<any> = [];
  patientForm: FormGroup;
  patientUser: any;
  showAddAltNo: boolean = false;
  altNo: number;
  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
  options: any = [
    { value: 'English', key: this.apiClientConstant.LanguageString.EN },
    { value: 'Hindi', key: this.apiClientConstant.LanguageString.HI },
    { value: 'Kannada', key: this.apiClientConstant.LanguageString.KN },
    { value: 'Telugu', key: this.apiClientConstant.LanguageString.TE },
    { value: 'Tamil', key: this.apiClientConstant.LanguageString.TA },
    { value: 'Malayalam', key: this.apiClientConstant.LanguageString.MA },
    { value: 'Marathi', key: this.apiClientConstant.LanguageString.MR },
    { value: 'Bengali', key: this.apiClientConstant.LanguageString.BN },
  ];
  genderOptions: any = [
    { value: 'Female', key: this.apiClientConstant.User.Gender.FEMALE },
    { value: 'Male', key: this.apiClientConstant.User.Gender.MALE },
  ];

  constructor(
    private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
    private dialog: MatDialog,
    private windowRef: WindowRefService,
  ) { }

  async saveChanges(): Promise<void> {
    if (this.patientForm.valid) {
      const changedValues = this.getDirtyValues(this.patientForm);
      if (Object.keys(changedValues).length > 0) {
        changedValues.id = this.user.id;
        try {
          await this.conn.updateUserData(changedValues);
          this.broadcaster.broadcast('NOTIFY', {
            message: 'User info updated',
            type: this.appConfig.Shared.Toast.Type.SUCCESS,
          });
        } catch (error) {
          this.broadcaster.broadcast('NOTIFY', {
            message: error.message,
            type: this.appConfig.Shared.Toast.Type.ERROR,
          });
        }
      }
    } else {
      alert('please check the user info and save again');
    }
  }

  initForm(): void {
    this.patientForm = new FormGroup({
      PatientName: new FormControl({ value: '', disabled: true }, [Validators.required]),
      Age: new FormControl('', [Validators.required, Validators.min(1)]),
      Gender: new FormControl('Female', [Validators.required]),
      MobileNumber: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern(/^\d{10}$/)]),
      alternateNumber: new FormControl(''),
      languagePreference: new FormControl('English', [Validators.required]),
      assignedDoctor: new FormControl({ value: '', disabled: true }),
      address: new FormControl({ value: '', disabled: true }),
    });
  }

  async ngOnChanges(): Promise<void> {
    if (!this.user) return;
    if (!this.patientForm) this.initForm();
    this.patientUser = JSON.parse(JSON.stringify(this.user));
    if (this.order?.addressBook?.alternateNumber) this.showAddAltNo = true;
    this.fillForm();
  }

  getDirtyValues(formGroup: FormGroup): any {
    const allowedKeys: string[] = ['PatientName', 'Age', 'Gender', 'languagePreference'];
    const dirtyValues = {};
    Object.keys(formGroup.controls).forEach((key: any): void => {
      const currentControl = formGroup.controls[key];
      if (currentControl.dirty && allowedKeys.includes(key)) {
        dirtyValues[key] = currentControl.value;
      }
    });
    return dirtyValues;
  }

  fillForm(): void {
    this.patientForm.patchValue({
      PatientName: this.order?.addressBook?.contactName || this.patientUser.PatientName,
      Age: this.patientUser.Age,
      Gender: this.patientUser.Gender,
      MobileNumber: this.order?.addressBook?.mobileNumber || this.patientUser.MobileNumber,
      assignedDoctor: this.patientUser?.allocatedDoctor?.DoctorDisplayName || '',
      address: this.order?.addressBook?.deliveryAddress,
      languagePreference: this.patientUser.languagePreference,
      alternateNumber: this.order?.addressBook?.alternateNumber,
    });
  }

  addAltNo(): void {
    this.showAddAltNo = true;
  }

  showAddressEditModal(): void {
    if (this.order?.trackingId) return;
    const action = this.appConfig.Shared.Actions.edit;
    const currentAddress = this.parseObject.get('addressBook');
    const dialogRef = this.dialog.open(AddressBookEditModal, {
      data: { action, user: this.user, currentAddress },
    });
    dialogRef.afterClosed().subscribe((addressBook: any) => {
      if (addressBook) {
        this.parseObject.set('addressBook', addressBook);
        this.windowRef.nativeWindow.location.reload();
      }
    });
  }
}
