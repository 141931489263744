import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExploreComponent } from './explore.component';
import { EditModule } from './edit';
import { ListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'explore',
    component: ExploreComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'explore/new',
    component: ExploreComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: 'explore/:id/edit',
    component: ExploreComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [ExploreComponent],
exports: [ExploreComponent] })
export class ExploreModule {}
