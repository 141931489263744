import { Component, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dashboard-card',
  templateUrl: './card.html',
  styleUrls: ['../dashboard.scss'],
})
export class CardComponent {
  @Input('title') title: string;
  @Input('dataItems') dataItems: Array<{ title: string, value: number }> = [];
  @Input('dailyTarget') dailyTarget: number = 0;
  @Input('soFarAchieved') soFarAchieved: number = 0;
  @Input('diffInDays') diffInDays: number;
  constructor() {}

  ngOnInIt(): void {}
}
