
<div class="h-full" fillComponentParentView="fillComponentParentView">
  <div class="col-xs-12 wrapper-sm" *ngIf="editFlag && !addNew "><strong style="font-size:16px;">&nbsp;&nbsp;&nbsp;&nbsp;Update Information</strong><br/><br/>
    <form class="form-horizontal" #updateForm="ngForm" (submit)="updateLink()">
      <div class="form-group">
        <div class="col-xs-12">
          <label class="col-xs-4">Description</label>
          <div class="col-xs-12">
            <input class="form-control" [(ngModel)]="editLinkObj.description" name="description_edit" placeholder="Enter Description of the Link"/>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Link</label>
          <div class="col-xs-12">
            <input class="form-control" [(ngModel)]="editLinkObj.link" name="link_edit" placeholder="Enter the link here"/>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-12">
            <button class="btn btn-success m-t-xs" type="submit" [disabled]="!updateForm.valid">Update</button>&nbsp;&nbsp;&nbsp;
            <button class="btn btn-danger m-t-xs" type="button" (click)="editFlag = !editFlag">Close</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-12 wrapper-sm" *ngIf="addNew && !editFlag"><strong style="font-size:16px;">&nbsp;&nbsp;&nbsp;&nbsp;Add New Link</strong><br/><br/>
    <form class="form-horizontal" #linkForm="ngForm" (submit)="saveLink()">
      <div class="form-group">
        <div class="col-xs-12">
          <label class="col-xs-4">Description</label>
          <div class="col-xs-12">
            <input class="form-control" [(ngModel)]="newLink.description" name="description_new" required="required" placeholder="Enter Description of the Link" autocomplete="off"/>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Link</label>
          <div class="col-xs-12">
            <input class="form-control" [(ngModel)]="newLink.link" name="link_new" required="required" placeholder="Enter the link here" autocomplete="off"/>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-12">
            <button class="btn btn-success m-t-xs" type="submit" [disabled]="!linkForm.valid">Add</button>&nbsp;&nbsp;&nbsp;
            <button class="btn btn-danger m-t-xs" type="button" (click)="addNew = !addNew">Close</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="float-bottom-corner" title="Add New Link" (click)="newLink.description = ''; newLink.link = '';addNew = !addNew">+</div>
  <div *ngIf="!addNew && !editFlag">
    <div class="col-xs-12" style="font-weight:600;font-size:15px" *ngIf="!addNew">
      <div class="col-xs-4" style="padding:7px;">Description</div>
      <div class="col-xs-4" style="padding:7px;">Link</div>
      <div class="col-xs-2" style="padding:7px;">Edit</div>
      <div class="col-xs-2" style="padding:7px;">Delete</div>
    </div>
    <div *ngIf="links.length">
      <div class="col-xs-12" *ngFor="let link of links; let i = index" style="padding:0px;">
        <div class="col-xs-12 flex" [ngClass]="{'oddRow': i%2 !==0, 'evenRow': i%2 ===0 }">
          <div class="col-xs-4" style="padding:7px;">{{link.get('description')||'-'}}</div><a class="underline col-xs-4 ellipsis" style="padding:7px; max-height:60px;overflow:hidden" href="{{link.get('link')}}" target="_blank">{{link.get('link')||'-'}}</a>
          <div class="col-xs-2" style="padding:7px;cursor:pointer;" (click)="editLink(i)">&#x270E;</div>
          <div class="col-xs-2 fa fa-trash-o" (click)="deleteLink(i)" style="cursor:pointer;padding:7px;"></div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 flex" *ngIf="ui.loading" style="justify-content:center;"><img src="../../../assets/images/loading.svg" style="width:50px;height:50px;"/></div>
    <div *ngIf="links.length === 0 && ui.loading === false">
      <div class="col-xs-12 wrapper-sm text-center">-- No Links Saved Yet Now --</div>
    </div>
  </div>
</div>
