import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant, Table, RequestQueryPayload, ApiConnector } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';
import { ChatType } from '../../../../typings/client/chat';
import { FaviconService } from '../../../services/favicon-service';
import { LocalStorage } from '../../../services/local-storage-service';
import { Broadcaster } from '../../../components/broadcaster';
import { ImageUploadModal } from '../../../components/modal/imageUploadModal';
import { TabNamingService } from '../../../services/tab-naming-service';
import { AddWaitingCallDialogComponent } from './add-waiting-call-dialog';
import { UserListDialog } from './user-list';
import { ChatSidebarComponent } from './chat-sidebar/chat-sidebar.component';

@Component({ selector: 'chat-view', templateUrl: './chat-view.html', styleUrls: ['./chat-view.scss'] })
export class ChatViewComponent {
  @ViewChild('toast', { static: false }) toast: ElementRef;
  @ViewChild(ChatSidebarComponent, { static: false }) chatSidebarComponent: ChatSidebarComponent;

  updateStatus: boolean = false;
  actOnState: string = '';
  showRefreshDate: boolean;
  consultationSession: any;
  paymentPlans: Array<{ name: string, value: string }>;
  pendingOrders: Array<any>;
  regimens: any;
  deletedRegimens: any = [];
  userId: any;
  username: string;
  instantCheckups: Array<any> = [];
  moment: any;
  baseUrlForWebApp: any;
  user: any;
  window: any;
  chatUser: any;
  title: any;
  SelectedPopUpType: any;
  deletedInstantCheckups: Array<any>;
  data: any = {};
  payment: any = {};
  internalUserRoles: Array<any>;
  followUp: any;
  userMobileNumber: { number?: string, isDirty: boolean } = { isDirty: true };
  ui: ChatType.UIType;
  chatViewToggle: boolean;
  cannedResponses: Array<any> = [];
  allocatedDoctor: any;
  message: string;
  mainConcerns: Array<string> = [];
  mainConcernConsultationSession: any;
  attendToChatCache: any;
  selectedConcernIndex: any;
  isDoctor: boolean = false;
  confirmationNeededFlag: boolean = false;
  editConcernFlag: boolean;
  prescriptions: Array<any> = [];
  userFiles: Array<any> = [];
  mainConcernOptions: Array<string>;
  internalUsers: Array<any> = [];
  changeAllocationData: any = { input: '', status: 'Change' };
  pullFromAccountNumber: any;
  swapNumber2: any;
  blockInputAfterMessage: boolean;
  languageStringIdMap: any = {};
  uiArrangementConfig: { DOCTOR: string[], OPERATOR: string[], ALL_SECTIONS: string[] } = { DOCTOR: [], OPERATOR: [], ALL_SECTIONS: [] };
  waitingCallRefresh: boolean = false;
  supportTicketId: string;
  selectedOrderType: string;
  orderType: Array<string> = ['ALL', 'DELIVERED', 'SHIPPED', 'OUT_FOR_DELIVERY', 'INITIAL', 'CANCELED', 'RETURNED',
    'ONLINE_PAYMENT_PENDING', 'OUT_OF_STOCK'];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private conn: ConnectionService,
    public windowService: WindowRefService,
    public appConfig: AppConfig,
    private storage: LocalStorage,
    private popupD: MatSnackBar,
    private dom: DomSanitizer,
    private dialog: MatDialog,
    private broadcaster: Broadcaster,
    private faviconService: FaviconService,
    private tabNaming: TabNamingService) {
    this.moment = moment;
    this.window = windowService.nativeWindow;
  }

  async ngOnInit(): Promise<any> {
    this.supportTicketId = this.route.parent.snapshot.queryParams.ticketId;
    this.userId = this.route.parent.snapshot.params.id;
    await this.getUserAndLoadData();
    this.getUrlForInstantCheckup();
    this.mainConcernOptions = [];
    this.editConcernFlag = false;
    this.internalUserRoles = this.storage.getJsonValue('userRoles');
    this.user = this.conn.getCurrentUser();
    this.isDoctorSet();
    this.initPaymentPlans();
    this.pendingOrders = [];
    this.data = { mode: this.appConfig.Shared.Assistant.Mode.WITH_TEXT_REPLY };
    this.instantCheckups = [];
    this.updateUsername();
    this.chatViewToggle = true;
    this.showRefreshDate = false;
    this.internalUsers = await this.conn.findUsers({
      where: {
        inactive: false,
        $or: [{ doctorAllocationFactor: { $gt: 0 } }, { operatorAllocationFactor: { $gt: 0 } }],
        type: [ApiClientConstant.User.Type.DOCTOR, ApiClientConstant.User.Type.OPERATOR],
      },
      project: ['username'],
    });
    this.languageStringIdMap = this.appConfig.Shared.LanguageStringId;
    this.reArrangeUIElements();
  }

  initPaymentPlans(): void {
    this.paymentPlans = Object.keys(ApiClientConstant.Payment.PaymentPlan).map((key: string) => ({
      name: ApiClientConstant.Payment.PaymentPlan[key].replace(/_/g, ' '),
      value: ApiClientConstant.Payment.PaymentPlan[key],
    }));
  }

  async fetchAllMainConcernsForUser(): Promise<any> {
    const mainConcerns = await this.conn.fetchAllMainConcernsForUser(this.chatUser.get('username'));
    this.mainConcernConsultationSession = mainConcerns;
    this.mainConcerns = mainConcerns.map((mainConcern: any) => mainConcern.get('PrivateMainConcern'));
    this.mainConcernOptions = await this.conn.findMainConcernsOptions();
  }

  async saveSelectedMainConcern(): Promise<void> {
    const selectedConcern: any = this.mainConcernOptions[this.selectedConcernIndex];
    this.chatUser.set('PrivateMainConcern', selectedConcern.get('value'));
    await this.chatUser.save();
    this.editConcernFlag = false;
    await this.fetchAllMainConcernsForUser();
  }

  isDoctorSet(): void {
    if (this.user.get('type') === 'doctor') {
      this.isDoctor = true;
      this.confirmationNeededFlag = true;
    }
  }

  getUrlForInstantCheckup(): void {
    this.baseUrlForWebApp = this.conn.getWebAppUrl();
  }

  showToastMessage(message: string): void {
    this.message = message;
    this.broadcaster.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.SUCCESS });
  }

  async getDeletedInstantCheckup(): Promise<void> {
    this.deletedInstantCheckups = await this.conn.findActionLog({
      where: { Reference: this.userId },
      descending: 'createdAt',
      project: ['updatedAt'],
    });
  }

  reloadChat(): void {
    this.chatSidebarComponent.reloadIframe();
  }

  async getUserAndLoadData(): Promise<void> {
    const users = await this.conn.getUser({ userId: this.userId });
    if (!users.length) {
      alert('No user found.');
      return;
    }
    if (users.length === 1) {
      this.chatUser = users[0];
      this.broadcaster.broadcast('ChatUserUpdate', { user: this.chatUser });
    } else {
      this.dialog.open(UserListDialog, { data: { user: users } })
        .afterClosed().subscribe((selectedUser: any) => {
          this.windowService.nativeWindow.close();
          this.windowService.nativeWindow.open(`/chat/${selectedUser.id}`, '_blank');
        });
      return;
    }
    this.blockInputAfterMessage = this.chatUser.get('disableChat');
    this.userId = this.chatUser.get('username');
    this.faviconService.setFavicon(this.chatUser.get('PatientName'));
    this.tabNaming.setTitle('', this.chatUser.get('PatientName'), 'chat');
    if (this.moment(this.chatUser.get('refreshDate')).isAfter('2000-01-01', 'year')) {
      this.showRefreshDate = true;
    }
    this.updateUsername();
    try {
      await Promise.all([
        this.getDeletedInstantCheckup(),
        this.getAllDeletedRegimen(),
        this.fetchAllMainConcernsForUser(),
        this.getUserData(),
      ]);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async getUserData(): Promise<void> {
    this.userFiles = await this.conn.getUserFiles(this.chatUser);
  }

  async getAllDeletedRegimen(): Promise<void> {
    this.deletedRegimens = await this.conn.getDeletedRegimens(this.userId);
  }

  updateUsername(): void {
    if (this.chatUser.get('PatientName')) {
      this.username = this.chatUser.get('PatientName');
      return;
    }
    this.username = this.chatUser.get('username');
  }

  openImageUploadDialog(): any {
    if (!this.supportTicketId) {
      this.broadcaster.broadcast('NOTIFY', { message: 'supportTicketId missing',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const dialogRef = this.dialog.open(ImageUploadModal, { data: { title: 'Upload other image/pdf',
      userObj: this.chatUser,
      context: { supportTicketId: this.supportTicketId } } });
    dialogRef.afterClosed().subscribe(() => this.reloadChat());
  }

  userOptedOutOfSMS(): void {
    this.conn.addUserToOptOutOfSMS(this.chatUser.id)
      .then(() => this.showToastMessage('User opted out of SMS'))
      .catch(() => this.showToastMessage('Failed to opt out of SMS'));
  }

  async changeAllocation(): Promise<any> {
    try {
      this.changeAllocationData.status = 'Changing in process';
      await this.conn.changeAllocation(this.chatUser.id, this.changeAllocationData.input);
      this.broadcaster.broadcast('NOTIFY', { message: 'Allocation changed' });
      this.changeAllocationData.status = 'Change';
      delete this.changeAllocationData.input;
    } catch (err) {
      this.broadcaster.broadcast('NOTIFY', { message: `Error Changing ${err.message}`,
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.changeAllocationData.status = 'Change';
    }
  }

  reArrangeUIElements(): void {
    this.uiArrangementConfig.ALL_SECTIONS = ['operatorAndDoctorAllocation', 'sendMessageSection', 'SBARSection',
      'followupSection', 'regimenSection', 'callLogSection', 'ticketListSection',
      'ticketNotesSection', 'notesSection', 'chatActionSection', 'orderSection', 'consultationList',
      'instantCheckupSection', 'followupReportSection', 'mainConcernSection', 'treeSection', 'assignChatSection',
      'quickActionSection', 'prescriptionSection', 'userFilesSection', 'waitingCallSection',
      'deletedContentSection', 'userTagSection'];
    this.uiArrangementConfig.DOCTOR = ['operatorAndDoctorAllocation', 'sendMessageSection', 'ticketListSection',
      'SBARSection', 'ticketNotesSection', 'followupSection', 'regimenSection', 'orderSection', 'notesSection',
      'mainConcernSection', 'instantCheckupSection', 'followupReportSection', 'chatActionSection', 'callLogSection',
      'consultationList', 'assignChatSection', 'quickActionSection', 'prescriptionSection', 'userFilesSection',
      'waitingCallSection', 'userTagSection'];
    this.uiArrangementConfig.OPERATOR = ['operatorAndDoctorAllocation', 'userTagSection', 'sendMessageSection',
      'SBARSection', 'ticketListSection', 'ticketNotesSection', 'notesSection', 'orderSection', 'callLogSection',
      'regimenSection', 'followupSection', 'chatActionSection', 'instantCheckupSection', 'userFilesSection',
      'waitingCallSection', 'consultationList'];
    let config: string[] = this.uiArrangementConfig[this.user.get('type').toUpperCase()];
    const extraAccessRoles = ['admin', 'adminDoctor'];
    if (this.internalUserRoles.filter((each: string) => extraAccessRoles.includes(each)).length) {
      config = this.uiArrangementConfig.ALL_SECTIONS;
    }
    const parentContainer = this.windowService.nativeWindow.document.getElementById('parentContainer');
    let tempChildElements: {[key: string]: Node} = {};
    this.uiArrangementConfig.ALL_SECTIONS.forEach((each: string) => {
      const element = this.windowService.nativeWindow.document.getElementById(each);
      if (element) {
        tempChildElements[each] = element;
        parentContainer.removeChild(element);
      }
    });
    config.forEach((each: string) => {
      if (tempChildElements[each]) {
        parentContainer.appendChild(tempChildElements[each]);
      }
    });
    tempChildElements = {};
  }

  addWaitingCall(): void {
    this.waitingCallRefresh = false;
    this.dialog.open(AddWaitingCallDialogComponent, {
      data: {
        userId: this.userId,
        chatUser: this.chatUser,
      },
    }).afterClosed().subscribe((res: Boolean) => {
      if (res) {
        this.waitingCallRefresh = true;
      }
    });
  }

  updatedConsultationId(id: string): void {
    this.chatSidebarComponent.getWebIframeUrl('consultation', id);
  }

  updatedOrderId(id: string): void {
    this.chatSidebarComponent.getWebIframeUrl('order', id);
  }
}
