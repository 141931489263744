
<div class="col-xs-12 pl-4" *ngIf="order.get('notes')?.length">
  <div class="col-xs-12 p-0">
    <label class="col-xs-1">Category</label>
    <label class="col-xs-6">Message</label>
    <label class="col-xs-2">Created By</label>
    <label class="col-xs-3">Created At</label>
  </div>
  <div class="col-xs-12 pl-0 my-2" *ngFor="let note of order.get('notes') || []">
    <div class="col-xs-1">{{note.category || '-'}}</div>
    <div class="col-xs-6">{{note.message}}</div>
    <div class="col-xs-2">{{note.byUser}}</div>
    <div class="col-xs-3">{{note.createdAt| amDateFormat: 'MMM DD YYYY,hh:mm a'}}</div>
  </div>
</div>
<div class="col-xs-12 pl-4" *ngIf="showAddNote">
  <label class="col-xs-12 p-0">Add Note</label>
  <div class="col-xs-4">
    <div>
      <label> category </label>
      <select class="form-control" [(ngModel)]="category" name="editNoteCategory" (ngModelChange)="onChangeCategory()" >
        <option *ngFor="let note of noteCategories" value="{{note.value}}">{{note.display}}</option>
      </select>
    </div>
    <div *ngIf="noteMessages.length">
      <label> note message </label>
      <select class="form-control" [(ngModel)]="note" name="editNote">
        <option value="" selected>Select an option</option>
        <option *ngFor="let note of noteMessages" value="{{note.value}}">{{note.display}}</option>
      </select>
    </div>
  </div>
  <div class="col-xs-4">
    <textarea class="form-control" [(ngModel)]="note" name="note" placeholder="Add Notes" rows="8"></textarea>
  </div>
  <div class="col-xs-1">
    <button class="btn-xs mt-2" [disabled]="!note" (click)="addOrderNote()">Add</button>
  </div>
</div>
