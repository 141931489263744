import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SupportQuestionNameComponent } from './support-question-name.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [SupportQuestionNameComponent],
  exports: [SupportQuestionNameComponent],
})
export class SupportQuestionNameModule {}
