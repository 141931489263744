import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { InputHelperService } from '../../input/input-helper';

@Component({
  selector: 'language-string-multiple-selection',
  templateUrl: './language-string-multiple-selection.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class LanguageStringMultipleSelectionComponent {
  @Input('title') title: string;
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputUpdate();
  }
  @Input('name')
  set onOnUpdateName(name: string) {
    this.name = name;
    this.onInputUpdate();
  }
  @Input('required') required: boolean;
  @Input('tags') tags: Array<any>;
  @Output('onSelection') onSelection: EventEmitter<any> = new EventEmitter();
  @Output('onChangeEditReasonRegimenSOP') onChangeEditReasonRegimenSOP: EventEmitter<any> = new EventEmitter();

  list: Array<any> = [];
  name: string;
  parseObj: any;
  static parameters: any = [InputHelperService, NgZone];
  constructor(public inputHelper: InputHelperService, private zone: NgZone) {
  }

  ngOnInit(): void {
  }

  private onInputUpdate(): void {
    if (!this.name || !this.parseObj) return;
    this.list = this.inputHelper.getValue(this.parseObj, this.name);
    if (!this.list) {
      this.list = [];
      this.inputHelper.setValue(this.parseObj, this.name, this.list);
    }
  }

  private onResultUpdate(): void {
    const temp = this.list;
    this.list = [];
    setTimeout(() => this.zone.run(() => {
      this.list = temp;
      this.inputHelper.setValue(this.parseObj, this.name, this.list);
      this.onSelection.emit(this.list);
      this.onChangeEditReasonRegimenSOP.emit();
    }), 0);
  }

  addToRowLanguageString(item: any): void {
    this.list.push(item);
    this.onResultUpdate();
  }

  removeToRowLanguagesStringValues(index: number): void {
    this.list.splice(index, 1);
    this.onResultUpdate();
  }
}
