
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Notification</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">  
    <form class="col-xs-12" role="form" name="bulkNotification" #bulkNotificationForm="ngForm">
      <div class="col-xs-12">
        <label class="mt-0">Select type</label>
        <select class="col-xs-12" id="pushNotification-type" [(ngModel)]="pushNotificationData.type" name="type">
          <option value="Consult Now">Consult Now</option>
          <option value="Treatment Plan">Treatment Plan</option>
        </select>
      </div>
      <div class="col-xs-12 flex justifySB">
        <div class="col-lg-5 col-md-6 col-xs-12">
          <label>Enter usernames</label>
          <textarea class="form-control" placeholder="Type usernames..." [(ngModel)]="pushNotificationData.userId" name="userId"></textarea>
        </div>
        <div class="col-lg-5 col-md-6 col-xs-12">
          <label>Enter message</label>
          <textarea class="form-control" placeholder="Type message..." [(ngModel)]="pushNotificationData.message" name="message"></textarea>
        </div>
      </div>
      <div class="col-xs-12 flex justifySB">
        <div class="col-lg-5 col-md-6 col-xs-12">
          <label>Image link</label>
          <input class="form-control" type="text" placeholder="Enter image link" [(ngModel)]="pushNotificationData.imageLink" name="imageLink"/>
        </div>
        <div class="col-lg-5 col-md-6 col-xs-12 ml-3">
          <label>Open url in app</label>
          <input class="form-control" type="text" placeholder="Enter url to open in app" [(ngModel)]="pushNotificationData.openUrl" name="openUrl"/>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" name="submit" [disabled]="!bulkNotificationForm.valid" (click)="sendPushNotification()">Send</button>
  </div>
</mat-dialog-content>