import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { EditComponent } from './edit.component';
import { InputTextareaModule } from '../../../../components/input/input-textarea/index';

const ROUTES: Routes = [
  { path: '', component: EditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputTextareaModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
})
export class EditModule {
}
