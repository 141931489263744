<div class="sales-payment-container">
  <div class="flex-box space-between">
    <div class="heading">Payments</div>
    <img src="/assets/images/add.svg" alt="" class="icon pointer" (click)='openAddPaymentModal()'>
  </div>
  <div>
    <div class="pl-4 bg-white" *ngIf="payments.length">
      <label class="col-xs-1">Payment Source</label>
      <label class="col-xs-1">Transaction Amount</label>
      <label class="col-xs-2">Txn Id</label>
      <label class="col-xs-2">Notes</label>
      <label class="col-xs-2">Created By</label>
      <label class="col-xs-2">Created At</label>
      <label class="col-xs-2">Transfer Payment</label>
    </div>
    <div class="col-xs-12 pl-4 my-3" *ngFor="let payment of payments">
      <div class="col-xs-1">{{payment.get('PaymentSource')}}</div>
      <div class="col-xs-1">{{payment.get('TransactionAmount')/100}}Rs.</div>
      <div class="col-xs-2"><span *ngFor="let each of payment.get('txnIds');">&nbsp;{{each}}&nbsp;</span></div>
      <div class="col-xs-2">{{payment.get('notes')}}</div>
      <div class="col-xs-2">{{payment.get('byUser')?.get('username')}}</div>
      <div class="col-xs-2">{{payment.get('createdAt')}}</div>
      <div class="col-xs-2">
        <div class="col-xs-12">
          <div class="col-xs-8">
            <select [disabled]="!transferPaymentAllowed" id="transferPayment">
              <option *ngFor="let item of unpaidOrders" [value]="item.id">{{item.get('orderNumber')}} / {{item.id}}
              </option>
            </select>
          </div>
          <div class="col-xs-4">
            <button class="btn-xs" (click)="movePayment()" [disabled]="!transferPaymentAllowed">Transfer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center bold mt-2" *ngIf="!payments.length">-- No Payments Made --</div>
</div>