import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Table } from 'api-client';
import { ConnectionService } from 'src/services/connection-service';
import { ExotelService } from 'src/services/exotel-service';

@Component({
  selector: 'app-exotel-softphone',
  templateUrl: './exotel-softphone.html',
  styleUrls: ['./exotel-softphone.scss'],
})
export class ExotelSoftPhoneComponent implements OnInit {
  @Input('user') user: Table.User;

  constructor(public exotelService: ExotelService, private conn: ConnectionService) {}

  async ngOnInit(): Promise<void> {
    const authToken = await this.conn.getSoftPhoneAuthTokenServerAPI();
    if (authToken) {
      this.exotelService.initializeWebPhone(authToken, this.user.id);
    }
  }

  ngOnDestroy(): void {
    this.exotelService.resetState();
    this.exotelService.hangup();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent): void {
    this.ngOnDestroy();
  }
}
