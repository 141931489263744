import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../../../components/directives.module';
import { EditComponent } from './edit.component';
import { InputTextareaModule } from '../../../../components/input/input-textarea';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputTextModule } from '../../../../components/input/input-text';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { InputMultiSelectModule } from '../../../../components/input/input-multi-select';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';

const ROUTES: Routes = [
  { path: '', component: EditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    InputCheckboxModule,
    InputSelectModule,
    InputTextModule,
    InputTextareaModule,
    LanguageStringSelectionModule,
    InputMultiSelectModule,
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
})
export class EditModule {}
