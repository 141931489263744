import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {
  validateMargId(margIds_: string): boolean {
    const margIds = margIds_.split('|').map((each: string) => parseInt(each, 10));
    if (margIds.some((id: number) => id <= 0)) { return false; }
    return true;
  }
}
