import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InputCheckboxComponent } from './input-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
  ],
  declarations: [InputCheckboxComponent],
  exports: [InputCheckboxComponent],
})
export class InputCheckboxModule {
}
