
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Lifestyle Card</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form class="col-xs-12" #lifestyleForm="ngForm">
      <div class="col-xs-12"> 
        <label class="col-xs-12 mt-0">Image Url</label>
        <input class="col-xs-12" [(ngModel)]="lifestyle.url" pattern="https.*" name="lifestylecardImage" type="text" placeholder="card image url" required="required"/>
      </div>
      <div class="col-xs-12"> 
        <label class="col-xs-12">Card Heading</label>
        <input class="col-xs-12" [(ngModel)]="lifestyle.heading" name="lifestylecardHeading" type="text" placeholder="card heading" required="required"/>
      </div>
      <div class="col-xs-12"> 
        <label class="col-xs-12">Card Text</label>
        <input class="col-xs-12" [(ngModel)]="lifestyle.text" name="lifestylecardText" type="text" placeholder="card text" required="required"/>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="submit" [disabled]="!lifestyleForm.valid" (click)="hideModal(lifestyle)">Save</button>
  </div>
</mat-dialog-content>