import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'leaves-filter',
  templateUrl: './leaves-filter.html',
})

export class LeavesFilterComponent {
  @Output() updatedLeavesList: EventEmitter<any> = new EventEmitter();
  @Input() set reset(status: boolean) {
    this.resetting = status;
    if (status) this.resetSelection();
  }
  @Input('filter') filter: { [key: string]: unknown } = { inactive: false };
  resetting: boolean = false;
  rows: Array<{ type: any, selected?: boolean }> = [];
  currentUser: any;
  leaves: Array<any> = [];
  constructor(private conn: ConnectionService, private zone: NgZone, public appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.currentUser = this.conn.getCurrentUser();
    this.leaves = await this.conn.findLeavesAndHolidays({ where: { }, project: ['status'] });
    this.zone.run(() => {
      this.rows = Object.keys(this.appConfig.Shared.LeaveAndHoliday.STATUS)
        .map((type: any) => ({
          type,
          selected: false,
        }));
    });

    this.sendUpdatedList();
  }

  sendUpdatedList(): void {
    if (this.resetting) {
      return;
    }
    this.updatedLeavesList.emit(this.rows.filter(({ selected }: any) => selected).map(({ type }: any) => type));
  }

  toggleSelection(r: { leave: any, selected?: boolean }): any {
    const row = r;
    row.selected = !row.selected;
    this.sendUpdatedList();
  }

  resetSelection(): void {
    this.zone.run(() => (this.rows = Object.keys(this.appConfig.Shared.LeaveAndHoliday.STATUS)
      .map((type: any) => ({
        type,
        selected: false,
      }))));
    this.resetting = false;
    this.sendUpdatedList();
  }
}
