import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkuComponent } from './sku.component';
import { ListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'skus',
    component: SkuComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [RouterModule.forChild(ROUTES)],
  declarations: [SkuComponent],
  exports: [SkuComponent] })
export class SKUModule {}
