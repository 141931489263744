import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { CompareImageProgressComponent } from './compare-progress.component';
import { InstantCheckupModule } from '../chat/view/instant-checkup/instant-checkup.module';
import { DirectivesModule } from '../../components/directives.module';
import { AudioRecorderModule } from '../../components/audio-recorder/audio-recorder.module';
import { AutoFaceModule } from '../../components/auto-face/auto-face.module';
import { RegimenEditWithoutRouteModule } from '../product/regimen/edit/regimen-edit-without-route.module';

const ROUTES: Routes = [
  { path: 'compare/:id1/:id2/:username', component: CompareImageProgressComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    BrowserModule,
    MomentModule,
    FormsModule,
    MatSelectModule,
    AutoFaceModule,
    RouterModule.forChild(ROUTES),
    InstantCheckupModule,
    DirectivesModule,
    AudioRecorderModule,
    RegimenEditWithoutRouteModule,
  ],
  declarations: [
    CompareImageProgressComponent,
  ],
  exports: [CompareImageProgressComponent],
})
export class CompareImageProgressModule { }
