import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SupportComponent } from './support.component';
import { ListModule } from './list';
import { EditModule } from './edit';

const ROUTES: Routes = [
  {
    path: 'support',
    component: SupportComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'support/new',
    component: SupportComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: 'support/:id/edit',
    component: SupportComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  RouterModule.forChild(ROUTES),
],
declarations: [SupportComponent],
exports: [SupportComponent] })
export class SupportModule {}
