import { v4 as uuid } from 'uuid';
import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AppConfig } from '../../../app/app.config';
import { InputHelperService } from '../input-helper';

@Component({
  selector: 'input-textarea',
  templateUrl: './input-textarea.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class InputTextareaComponent {
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input('required') required: boolean = false;
  @Input('placeholder') placeholder: string = '';
  @Input('type') type: string = 'text';
  @Input('cols') cols: number = 20;
  @Input('rows') rows: number = 2;
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }
  disabled: boolean = false;

  ui: any = {};
  parseValue: any;
  parseObj: any;
  name: string = uuid();

  static parameters: any = [AppConfig, InputHelperService];
  constructor(public appConfig: AppConfig, private inputHelper: InputHelperService) {
  }

  ngOnInit(): void {
  }

  onInputChange(): void {
    if (!this.parseObj || !this.name) return;
    this.parseValue = this.inputHelper.getValue(this.parseObj, this.name);
  }

  valueUpdated(): void {
    this.inputHelper.setValue(this.parseObj, this.name, this.parseValue);
  }
}
