
<div class="col-xs-12 title"><em class="fas fa-tree"></em><span>Tree Actions</span></div>
<div class="col-xs-12 p-0">
  <div class="col-xs-12 flex mb-3" *ngIf="isDeveloper">
    <div class="col-xs-4 flex">
      <label class="col-xs-10">Enter state name</label>
      <label class="i-switch i-switch-sm bg-success mr-3">
        <input type="checkbox" [(ngModel)]="enableStateName" name="enableStateName"/><i></i>
      </label>
    </div>
    <div class="col-xs-6" *ngIf="!enableStateName">
      <select class="form-control" [(ngModel)]="actOnState" name="actOnState" required="required">
        <option *ngFor="let tree of treeList" value="{{tree.get('name')}}">{{tree.get('name')}}</option>
      </select>
    </div>
    <div class="col-xs-6" *ngIf="enableStateName">
      <input class="form-control" [(ngModel)]="actOnState" name="actOnState" required="required"/>
    </div>
    <div class="col-xs-2 pl-2">
      <button class="btn-xs" (click)="activateState()" *ngIf="actOnState">Trigger Tree</button>
    </div>
  </div>
  <div class="col-xs-12 mb-3" *ngIf="!isDeveloper">
    <div class="col-xs-9">
      <select class="form-control" name="selectMainConcern" [(ngModel)]="selectedConcern" required="required">
        <option [value]="undefined" disabled="disabled" selected="selected">Select Main Concern</option>
        <option *ngFor="let concern of mainConcernOptions" value="{{concern.get('value')}}">{{concern.get('value')}}</option>
      </select>
    </div>
    <div class="col-xs-2">
      <button class="btn-xs ml-3" [disabled]="!selectedConcern" (click)="saveSelectedMainConcern()">Save</button>
    </div>
  </div>
  <div class="col-xs-12 flex mb-3">
    <button class="btn-xs mr-3" (click)="reTriggerSameTree()">Re-trigger Same Tree</button>
    <button class="btn-xs" (click)="triggerFollowUpTree()">Trigger Followup Tree</button>
  </div>
  <div class="col-xs-12">
    <button class="btn-xs btn-primary-outline mr-3" (click)="clearSession('FACE')">Clear Face Tree answers</button>
    <button class="btn-xs btn-primary-outline mr-3" (click)="clearSession('BODY')">Clear Body Tree answers</button>
    <button class="btn-xs btn-primary-outline mr-3" (click)="clearSession('HAIR')">Clear Hair Tree answers</button>
  </div>
</div>