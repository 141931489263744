import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { LanguageViewModule } from '../../../../components/language-string/language-view';
import { ArticleViewComponent } from './article-view.component';

const ROUTES: Routes = [
  { path: '', component: ArticleViewComponent, pathMatch: 'full' },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  DirectivesModule,
  LanguageViewModule,
  RouterModule.forChild(ROUTES),
],
declarations: [ArticleViewComponent],
exports: [ArticleViewComponent] })
export class ArticleViewModule {
}
