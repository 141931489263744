<div class="wrapper">
  <div class="calendar-header">
    <div class="fw-700 col-xs-8">Timings Calendar</div>
    <div class="fw-400 col-xs-2 custom-select">
      <select id="monthSelect" [(ngModel)]="selectedMonth" (change)="onMonthChange()">
        <option *ngFor="let month of months" [value]="month">{{ month }}</option>
      </select>
    </div>
    <div class="fw-400 col-xs-2 custom-select">
      <select id="weekSelect" [(ngModel)]="selectedWeek" (change)="onWeekChange()">
        <option *ngFor="let week of weeks" [value]="week">Week {{ week }}</option>
      </select>
    </div>
  </div>
  <div class="calendar-body">
    @for (date of selectedDates; track date; let i = $index) {
      <div class="calendar-cell" [ngClass]="{'teal-bdr': isSameDay(date, today)}">
        <div class="flex flex-dir-col">
          <div class="fw-700 bold text-gray" [style.font-size.px]="32" [ngClass]="{'teal-text-dark': isSameDay(date, today)}">{{date | date:'dd'}}</div>
          <div class="text-upr text-gray" [ngClass]="{'teal-text-light': isSameDay(date, today)}">{{date | date:'EEE'}}</div>
        </div>
        <div *ngIf="weekData[i]?.login" class="flex flex-dir-col">
          <div class="font12" [ngClass]="{'clr-orange': hasLoggedInLate(weekData[i].login)}">login: {{weekData[i].login}}</div>
          <div *ngIf="weekData[i]?.logout" class="font12">logout: {{weekData[i].logout}}</div>
        </div>
        <div *ngIf="!weekData[i]?.login && date < today" class="flex flex-dir-col">
          <div class="flex flex-dir-row">
            <div class="dots mr-1"></div>
            <div class="font12">on leave</div>
          </div>
        </div>
      </div>
    }
  </div>
</div>