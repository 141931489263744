<form [formGroup]="patientForm">
  <div class="patient-info">
    <div class="column-1">
      <div class="label-field">
        <p> Name </p>
        <input type="text" formControlName="PatientName" placeholder="patient name">
      </div>
      <div class="flex">
        <div class="label-field">
          <p> Age </p>
          <input type="number" class="age" formControlName="Age" placeholder="30">
        </div>
        <div class="label-field">
          <p> Gender </p>
          <select class="select gender" formControlName="Gender" required aria-label="Gender">
            <option *ngFor="let option of genderOptions" [value]="option.key">{{ option.value }}</option>
          </select>
        </div>
      </div>
      <div class="label-field">
        <p> Mobile </p>
        <div class="flex">
          <input type="number" formControlName="MobileNumber" placeholder="+91 9999999999">
          <div class="alt-number pointer" *ngIf="!showAddAltNo" (click)="addAltNo()">+ Add alt no.</div>
        </div>
        <input *ngIf="showAddAltNo" type="text" placeholder="+91 9999999999" formControlName="alternateNumber">
      </div>
      <div class="label-field">
        <p> Language </p>
        <div class="flex relative">
          <select class="select" formControlName="languagePreference" required aria-label="Language">
            <option *ngFor="let option of options" [value]="option.key">{{ option.value }}</option>
          </select>
          <button type="submit" (click)="saveChanges()">Save Changes</button>
        </div>
      </div>
    </div>
    <div class="column-2">
      <div class="label-field">
        <p> Assigned doctor </p>
        <input type="text" placeholder="Assigned doctor" formControlName="assignedDoctor">
      </div>
      <div class="label-field">
        <p> Address </p>
        <div class="relative">
          <textarea class="address-field" placeholder="NA" formControlName="address"></textarea>
          <div class="overlay" (click)="showAddressEditModal()"></div>
        </div>
        <div *ngIf="false" class="confirmation-cta">
          <div class="pointer">
            <img src="/assets/images/warning.svg" alt="" class="icon">
            <span> Send for confirmation </span>
          </div>
        </div>
        <div *ngIf="false" class="not-confirmed">Not confirmed</div>
        <div *ngIf="false" class="confirmed">Confirmed by GoQwik</div>
      </div>
    </div>
  </div>
</form>