
<mat-dialog-content style="min-width:100%;">
  <div class="col-xs-12 no-padder full-width-typeahead">
    <div class="col-xs-12 h2">Select Instructions To Copy</div>
    <div class="col-xs-12 b-a"></div>
    <div class="col-xs-12" *ngFor="let each of instructions">
      <div class="form-check d-flex">
        <input class="form-check-input" type="checkbox" id="flexCheckChecked" [(ngModel)]="each.selected"/>
        <label class="form-check-label ml-3" for="flexCheckChecked">{{each.title}}</label>
      </div>
    </div>
    <div class="col-xs-12 mt-2">
      <button class="btn btn-sm btn-success pull-right" (click)="copyInstructions()">Copy</button>
    </div>
  </div>
</mat-dialog-content>