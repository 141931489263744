
<mat-dialog-content style="border-radius:30px">
  <div class="modal-dialog modal-lg">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Bulk Update Order</h4>
      <button class="close pull-right" type="button" aria-label="Close" (click)="hideModal()"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
      <div class="form-group custom-layout">
        <div class="flex">
          <div class="col-xs-12 no-padder">
            <form class="form" #bulkUploadForm="ngForm">
              <div class="col-xs-12 m-t-xs" style="max-height: 250px; overflow:scroll;">
                <div class="m-l-xs rounded wrapper-xs b-a w-fit-content m-b-xs" *ngFor="let row of updateOrderList; let i = index;"><span class="label-default rounded m-r-xs text-white" *ngFor="let col of row" style="padding:1px 5px;">{{col}}</span><i class="fa fa-trash-o pointer" (click)="removeEntry(i)"></i></div>
              </div>
              <textarea class="col-xs-12 text-area m-t-xs" [(ngModel)]="csvText" placeholder="Paste CSV here." name="csvText"></textarea>
              <div class="col-xs-12 m-t-xs">
                <button class="btn btn-xs btn-warning m-l-xs pull-left" [disabled]="!csvText" (click)="addDataToProcess()">Add</button>
                <button class="btn btn-xs btn-success m-l-xs pull-right" [disabled]="!bulkUploadForm.valid || !updateOrderList.length" (click)="updateOrder()">Update</button>
                <select class="select pull-right" [(ngModel)]="action" required="true" name="action">
                  <option *ngFor="let each of actions;" [value]="each.value" [disabled]="!each.value">{{each.name}}</option>
                </select>
                <input class="m-l-xs pull-right file" #fileInput *ngIf="action === &quot;QC_PASS&quot;" [(ngModel)]="file" type="file" required="true" name="file"/>
              </div>
              <div class="col-xs-12 m-t-xs" *ngIf="errorMessage">
                <div class="text-danger">{{errorMessage}}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
