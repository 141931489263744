
<form #assignedChatForm="ngForm">
  <div class="col-xs-12 p-0">
    <div class="h4">{{type === "Assign" ? "Assign" : "UnAssign"}} Team: {{team}}</div>
  </div>
  <div class="line bg-light-gray m-t-xs"></div>
  <textarea class="col-xs-12 m-t-xs wrapper-xs" [(ngModel)]="message" name="message" matInput="matInput" placeholder="Type Your Message Here" rows="5" minlength="10" [required]="['UnAssign'].includes(type)"></textarea>
  <div class="col-xs-12 p-0 m-t-sm">
    <button class="pull-right" *ngIf="type === 'orderCreated'" mat-raised-button="mat-raised-button" color="primary" (click)="removeTeam({ orderCreatedConfirmed: true })">Yes, Order Created</button>
    <button class="pull-right" *ngIf="type === 'UnAssign'" [disabled]="!assignedChatForm.valid" mat-raised-button="mat-raised-button" color="primary" (click)="removeTeam()">Remove</button>
    <button class="pull-right" *ngIf="type === 'Assign'" mat-raised-button="mat-raised-button" color="primary" (click)="addTeam()">Add</button>
    <button mat-raised-button="mat-raised-button" color="warn" (click)="cancel()">Cancel</button>
    <button class="ml-2" *ngIf="type === 'orderCreated'" mat-raised-button="mat-raised-button" color="accent" (click)="removeTeam({ orderCreatedConfirmed: false })">No, Order Not Created</button>
  </div>
</form>