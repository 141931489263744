import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { AgGridAngular } from 'ag-grid-angular';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { DirectivesModule } from '../../components/directives.module';
import { OfflineDoctorScheduleComponent } from './offline-doctor-schedule.component';
import { AddKioskDoctorModalComponent } from './add-kiosk-doctor-modal/add-kiosk-doctor-modal.component';

const ROUTES: Routes = [
  { path: 'offline-doctor-schedule', component: OfflineDoctorScheduleComponent, pathMatch: 'full' },

];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    DirectivesModule,
    MomentModule,
    MatDialogModule,
    AgGridAngular,
    MatSelectModule,
  ],
  declarations: [OfflineDoctorScheduleComponent, AddKioskDoctorModalComponent],
  exports: [OfflineDoctorScheduleComponent],

})
export class OfflineDoctorScheduleModule {}
