
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading" #headingView>
    <div class="list-header col-xs-12"><span class="pR20">Language Strings  ({{count || 0}})</span>
      <div class="pull-right"><i class="fa fa-plus-square-o m-l-xs pointer" routerLink="new"></i>
        <label class="mr-3">Plain text</label><i class="fa fa-plus-square-o m-l-xs pointer" [routerLink]="['new']" [queryParams]="{richText: true}"></i>
        <label>Rich text</label>
      </div>
    </div>
  </div>
  <div class="flex-content">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content">
          <div>
            <label class="db">Search</label>
            <select class="mb-2" [(ngModel)]="searchOn" (change)="changeSearchLanguage()">
              <option *ngFor="let key of languageKeys">{{key}}</option>
            </select>
            <input class="w100" placeholder="Search..." (change)="reset()" [(ngModel)]="searchKey"/>
          </div>
          <div class="mt-3">
            <language-string-tag-selection [parseObj]="filter" name="tags" (onValueChange)="onTagUpdate()"></language-string-tag-selection>
          </div>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" #agGrid style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
