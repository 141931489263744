import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({ selector: 'user-tags', templateUrl: './user-tags.html', styleUrls: ['./user-tags.scss'] })
export class UserTagsComponent {
  chatLabelOptions: any[];
  attendToChatCacheObj: any;
  attendToChatCache: any;
  labels: Array<string> = [];
  assignedUserLabelList: Array<any> = [];
  labelList: any = {};
  userRoles: Array<string>;
  @Input('user')
  set userFunction(user: any) {
    if (!user || !user.id) {
      this.user = undefined;
      return;
    }
    this.user = user;
    this.fetchData();
  }
  user: any;
  tickets: any[] = [];
  marketingTagsOptions: any = [];
  showSteroidQuestion: boolean = true;
  public subscriptions: Subscription[] = [];

  constructor(private conn: ConnectionService,
    private storage: LocalStorage,
    public appConfig: AppConfig,
    private dialog: MatDialog,
    private broadcaster: Broadcaster) {
  }

  async fetchData(): Promise<void> {
    this.attendToChatCache = await this.conn.findAttendToChatCache(this.user);
    this.userRoles = this.storage.getJsonValue('userRoles') || [];
    this.updateAttendToChatCache(this.attendToChatCache);
    await this.findLabels();
    this.chatLabelOptions = this.labels;
    this.marketingTagsOptions = [...Object.values(ApiClientConstant.User.MarketingTags)];
  }

  autoCompleteOnSelect(event: any, field: string): void {
    const { value }: any = event.target;
    if (!value || this.attendToChatCache[field].includes(value)) {
      return;
    }
    this.attendToChatCache[field].push(value);
    this.updateUserLabels();
    this.attendToChatCacheObj.set(field, this.attendToChatCache[field]);
    this.saveAttendToChatCacheObject()
      .then(() => this.chatLabelOptions = this.filterOnlyUnselectedValues(this.labels, this.attendToChatCache[field]))
      .catch((error: any) => alert(error.message));
  }

  async onSelectMarketingTags(event: any): Promise<void> {
    const marketingTags = this.user.get('marketingTags');
    if (marketingTags.includes(event.target.value)) return;
    marketingTags.push(event.target.value);
    this.user.set('marketingTags', marketingTags);
    try {
      await this.user.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'marketingTags saved successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async updateSteroidTags(isSteroidUser: boolean): Promise<void> {
    const marketingTags = this.user.get('marketingTags');
    if (isSteroidUser) {
      marketingTags.push('STEROID');
      this.user.set('marketingTags', marketingTags);
      try {
        await this.user.save();
        this.broadcaster.broadcast('NOTIFY', { message: 'Steroid Tag saved successfully',
          type: this.appConfig.Shared.Toast.Type.SUCCESS });
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    }
    this.showSteroidQuestion = false;
  }

  async onDeleteMarketingTags(event: any, index: number): Promise<void> {
    const marketingTags = this.user.get('marketingTags');
    if (marketingTags.includes(event.target.value)) return;
    if (marketingTags[index] === this.appConfig.Shared.User.MarketingTags.STEROID) {
      this.showSteroidQuestion = true;
    }
    marketingTags.splice(index, 1);
    this.user.set('marketingTags', marketingTags);
    try {
      await this.user.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'marketingTag remove successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async findLabels(): Promise<any> {
    const labelList = JSON.parse(JSON.stringify(await this.conn.findAllTags()));
    labelList.forEach((value: any) => {
      this.labelList[value.name] = value.colorCode;
    });
    this.labels = Object.keys(this.labelList);
  }

  removeItem(index: number, field: string): void {
    this.attendToChatCache[field].splice(index, 1);
    this.updateUserLabels();
    this.attendToChatCacheObj.set(field, this.attendToChatCache[field]);
    this.saveAttendToChatCacheObject()
      .catch((error: any) => alert(error.message));
  }

  updateAttendToChatCache(attendToChatCache: any): void {
    this.attendToChatCacheObj = attendToChatCache;
    this.attendToChatCache = this.attendToChatCacheObj?.toJSON();
    ['createdAt', 'updatedAt'].forEach((key: string) => (delete this.attendToChatCache[key]));
    if (!this.attendToChatCache.teams) {
      this.attendToChatCache.teams = [];
    }
    if (!this.attendToChatCache.userLabel) {
      this.attendToChatCache.userLabel = [];
    }
    this.chatLabelOptions = this.filterOnlyUnselectedValues(this.labels, this.attendToChatCache.userLabel);
    this.updateUserLabels();
  }

  filterOnlyUnselectedValues(data: string[], selectedValues: string[]): string[] {
    return data.filter((each: any) => !selectedValues.includes(each));
  }

  updateUserLabels(): void {
    this.assignedUserLabelList = [];
    this.attendToChatCache.userLabel.forEach((label: string) => {
      this.assignedUserLabelList.push({
        name: label,
        color: this.labelList[label],
      });
    });
  }

  async saveAttendToChatCacheObject(): Promise<any> {
    await this.attendToChatCacheObj.save();
    this.updateAttendToChatCache(this.attendToChatCacheObj);
  }

  callUserThroughExotel(number: any): void {
    this.conn.callThroughExotel(number, 'chatPage')
      .then(() => this.broadcaster.broadcast('NOTIFY', { message: 'Connecting',
        type: this.appConfig.Shared.Toast.Type.SUCCESS }))
      .catch((error: any) => this.broadcaster.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR }));
  }
}
