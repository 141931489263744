import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';
import { TimeRangePopupModel } from './time-range-popup';
import { Broadcaster } from '../broadcaster';

@Component({
  selector: 'work-time',
  templateUrl: './work-time.html',
})
export class WorkTimeComponent {
  @Input('user')
  set onUpdateUser(user: any) {
    this.user = user;
    if (!this.user) {
      return;
    }
    this.fetchOrCreateWorkTime();
  }
  user: any;
  workTime: any;
  weekDays: Array<string> = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  constructor(private conn: ConnectionService,
    private dialog: MatDialog,
    private broadcasterService: Broadcaster,
    private appConfig: AppConfig) {
  }

  addTimeRange(day: string): void {
    this.dialog.open(TimeRangePopupModel).afterClosed().subscribe((result: any) => {
      if (!result) return;
      if (result.copyFromDay) {
        this.workTime[day] = this.workTime[result.copyFromDay];
        return;
      }
      const oldData = this.workTime[day] || [];
      this.workTime[day] = [...oldData, { startTime: result.startTime, endTime: result.endTime }];
    });
  }

  async saveWorkTime(): Promise<void> {
    try {
      const shortedWorkTime = {};
      this.weekDays.forEach((each: string) => {
        if (this.workTime[each]) {
          shortedWorkTime[each] = this.workTime[each];
        }
      });
      this.user.set('workTime', shortedWorkTime);
      await this.user.save();
      this.broadcasterService.broadcast('NOTIFY', { message: 'updated Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  removeTime(index: number, day: string): void {
    this.workTime[day].splice(index, 1);
  }

  private async fetchOrCreateWorkTime(): Promise<void> {
    this.workTime = this.user.get('workTime') || {};
  }
}
