import { Component, ElementRef, NgZone, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as EXIF from 'exif-js';
import { Subscription } from 'rxjs';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';
import { Broadcaster } from '../../../components/broadcaster';
import { FaviconService } from '../../../services/favicon-service';
import { TabNamingService } from '../../../services/tab-naming-service';

@Component({
  selector: 'instant-checkup-view',
  templateUrl: './view.html',
  styleUrls: ['./view.scss'],
})
export class InstantCheckupViewComponent implements OnDestroy {
  @Input() hideFullImage: boolean;
  result: any;
  instantCheckupId: string;
  instantCheckupObject: any;
  instantCheckup: any;
  displayPercentage: any;
  detectedIssuesText: any;
  image: any;
  context: any;
  orientation: any;
  subscriptions: any;
  checkupType: string;
  userInInstantCheckupScore: boolean;
  showAiResponse: boolean = false;
  internalUser: any;
  issueMarkingContext: any;
  userRoles: Array<any> = [];
  @Input('instantCheckupId')
  set value(id: string) {
    this.instantCheckupId = id;
    if (this.user) this.fetchInstantCheckup();
  }
  @Input('username') username: any;
  user: any;
  @ViewChild('canvasIC', { static: false }) canvas: ElementRef;
  @ViewChild('canvasIssueMarking', { static: false }) canvasIssueMarking: ElementRef;
  @Output('deleted') deleted: EventEmitter<void> = new EventEmitter();
  ui: any = {};
  response: any;
  sliderValue: any = {};
  static parameters: any = [
    ConnectionService,
    Router,
    AppConfig,
    WindowRefService,
    ActivatedRoute,
    NgZone,
    LocalStorage,
    Broadcaster,
    FaviconService,
    TabNamingService,
  ];
  constructor(private conn: ConnectionService,
              private router: Router,
              public appConfig: AppConfig,
              private windowRef: WindowRefService,
              private route: ActivatedRoute,
              private zone: NgZone,
              private storage: LocalStorage,
              private broadcaster: Broadcaster,
              private faviconService: FaviconService,
              private tabNaming: TabNamingService) {
  }

  async ngOnInit(): Promise<any> {
    this.reset();
    this.route.queryParams.subscribe(async (params: any) => {
      this.user = await this.conn.getUserByUserName(this.username);
      if (this.conn.getCurrentUser().get('type')) this.internalUser = this.conn.getCurrentUser();
      this.fetchInstantCheckup();
      this.broadcaster.broadcast('ChatUserUpdate', { user: this.user });
      this.faviconService.setFavicon(this.user.get('PatientName'));
      this.tabNaming.setTitle('', this.user.get('PatientName'), 'Instant checkup');
    });
    this.userRoles = this.storage.getJsonValue('userRoles');
  }

  reset(): void {
    this.result = [];
    delete this.instantCheckup;
    delete this.instantCheckupObject;
    this.orientation = -1;
    this.subscriptions = [];
    this.detectedIssuesText = [];
    this.ui = { selectedCard: -1, imageLoaded: false, popUpModal: { open: false } };
  }

  async fetchInstantCheckup(): Promise<any> {
    this.reset();
    this.instantCheckup = (await this.conn.fetchUserInstantCheckup({ userId: this.user.get('username'), id: [this.instantCheckupId] }))[0];
    this.instantCheckupObject = await this.conn.getInstantCheckupById(this.instantCheckupId);
    this.checkupType = this.instantCheckup.type;
    if (this.instantCheckup.aiResponse && this.instantCheckup.aiResponse.imageHeight && this.instantCheckup.aiResponse.imageWidth) {
      this.instantCheckup.dimension = { width: this.instantCheckup.aiResponse.imageWidth,
        height: this.instantCheckup.aiResponse.imageHeight };
    }
    this.loadData();
  }

  loadData(): any {
    this.loadImageAndRenderIssues();
    this.userInInstantCheckupScore = true;
    return 0;
  }

  toggleAiResponse(): any {
    this.showAiResponse = !this.showAiResponse;
  }

  loadImageAndRenderIssues(): any {
    this.image = new Image();
    this.image.crossOrigin = 'Anonymous';
    this.image.onload = (): void => {
      this.afterImageLoad();
    };
    let base64ImageData;
    if (base64ImageData) {
      base64ImageData = `data:image/png;base64,${base64ImageData}`;
      this.image.src = base64ImageData;
    } else {
      this.image.src = this.instantCheckup.imagePath;
    }
    if (!([
      ApiClientConstant.InstantCheckup.Type.FULL_FACE,
      ApiClientConstant.InstantCheckup.Type.FRONT_FACE,
      ApiClientConstant.InstantCheckup.Type.SIDE_FACE,
      ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE,
      ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE,
    ] as Array<string>).includes(this.checkupType)) return 0;
    let results = [];
    results.push({ ProblemName: 'All', Condition: 'Detected' });
    if (this.instantCheckup.aiResponse && this.instantCheckup.aiResponse.result) {
      results.push(...this.instantCheckup.aiResponse.result);
    } else if (this.instantCheckup.resultObject) {
      results.push(...JSON.parse(this.instantCheckup.resultObject));
    }
    this.response = JSON.stringify(this.instantCheckup.aiResponse);
    const tempResult = results.filter((each: any) => {
      if (each.showForGender && this.user.get('Gender').toLowerCase() === each.showForGender.toLowerCase()) return true;
      if (!each.showForGender) return true;
      return false;
    });
    results = tempResult;
    if (results.length > 1) {
      results.forEach((each: any) => {
        if (each.Condition !== 'Not Detected') this.result.push(each);
      });
    }
    return 0;
  }

  async afterImageLoad(): Promise<any> {
    const { src }: { src: string } = this.image;
    const rotationNeeded = this.conn.rotationNeeded();
    EXIF.getData(this.image, () => {
      const orientation = rotationNeeded ? EXIF.getTag(this.image, 'Orientation') : 0;
      this.orientation = this.instantCheckupObject.get('orientation')
        ? this.instantCheckupObject.get('orientation')
        : orientation;
      this.context = this.canvas.nativeElement.getContext('2d');
      this.issueMarkingContext = this.canvasIssueMarking.nativeElement.getContext('2d');
      this.canvas.nativeElement.width = this.windowRef.nativeWindow.innerWidth;
      if (this.instantCheckup.dimension) {
        this.canvasIssueMarking.nativeElement.width = this.instantCheckup.dimension.width;
        this.canvasIssueMarking.nativeElement.height = this.instantCheckup.dimension.height;
      } else {
        this.canvasIssueMarking.nativeElement.width = this.image.width;
        this.canvasIssueMarking.nativeElement.height = this.image.height;
      }
      if ([5, 6, 7, 8].includes(this.orientation)) {
        if (this.image.height <= this.windowRef.nativeWindow.innerWidth) {
          this.canvas.nativeElement.height = this.image.width * (this.windowRef.nativeWindow.innerWidth / this.image.height);
        } else {
          this.canvas.nativeElement.height = this.image.width / (this.image.height / this.windowRef.nativeWindow.innerWidth);
        }
        this.rotateCanvasBasedOnOrientation(this.orientation, this.context, this.canvas);
        this.context.drawImage(
          this.image,
          0,
          0,
          this.image.width,
          this.image.height,
          0,
          0,
          this.canvas.nativeElement.height,
          this.canvas.nativeElement.width);
      } else {
        if (this.image.width <= this.windowRef.nativeWindow.innerWidth) {
          this.canvas.nativeElement.height = this.image.height * (this.windowRef.nativeWindow.innerWidth / this.image.width);
        } else {
          this.canvas.nativeElement.height = this.image.height / (this.image.width / this.windowRef.nativeWindow.innerWidth);
        }
        this.rotateCanvasBasedOnOrientation(this.orientation, this.context, this.canvas);
        this.context.drawImage(
          this.image,
          0,
          0,
          this.image.width,
          this.image.height,
          0,
          0,
          this.canvas.nativeElement.width,
          this.canvas.nativeElement.height);
      }
      this.canvas.nativeElement.style.display = 'block';
      this.canvasIssueMarking.nativeElement.style.display = 'block';
      this.ui.imageLoaded = true;
      this.context.save();
      if (this.internalUser) {
        this.canvas.nativeElement.style.width = this.internalUser.get('instantCheckupWidth') || '35%';
        this.canvas.nativeElement.style.margin = 'auto';
        this.canvasIssueMarking.nativeElement.style.width = this.internalUser.get('instantCheckupWidth') || '35%';
        this.canvasIssueMarking.nativeElement.style.margin = 'auto';
      }
    });
  }

  rotateCanvasBasedOnOrientation(orientation: any, ctx: any, canvas: any): void {
    switch (orientation) {
      case 6: {
        ctx.translate(canvas.nativeElement.width, 0);
        ctx.rotate(90 * (Math.PI / 180));
        break;
      }
      case 8: {
        ctx.rotate(-0.5 * Math.PI);
        ctx.translate(-canvas.nativeElement.height, 0);
        break;
      }
      case 3: {
        ctx.rotate(Math.PI);
        ctx.translate(-canvas.nativeElement.width, -canvas.nativeElement.height);
        break;
      }
      case 5: {
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      }
      case 7: {
        ctx.transform(0, -1, -1, 0, canvas.nativeElement.width, canvas.nativeElement.height);
        break;
      }
      case 4: {
        ctx.transform(1, 0, 0, -1, 0, canvas.nativeElement.height);
        break;
      }
      case 2: {
        ctx.transform(-1, 0, 0, 1, canvas.nativeElement.width, 0);
        break;
      }
      default: break;
    }
  }

  drawProblem(problem: any, index: any, skipEventTracking?: boolean, sliderValue?: any): void {
    this.issueMarkingContext.clearRect(0, 0, this.canvasIssueMarking.nativeElement.width, this.canvasIssueMarking.nativeElement.height);
    if (this.ui.selectedCard === index && sliderValue === undefined) {
      this.ui.selectedCard = -1;
      return;
    }
    this.ui.selectedCard = index;
    this.markIssues(problem, sliderValue);
  }

  markIssues(problem: any, sliderValue?: any): void {
    const container = [];
    if (problem.ProblemName === 'All') {
      container.push(...this.result);
      container.shift();
    } else container.push(problem);
    container.forEach((each: any) => {
      const item = each;
      if (!each.showForGender || this.user.get('Gender') === each.showForGender) {
        if (item.BoundingBoxes) this.drawIssuesInCircle(item, sliderValue);
        if (item.PolygonPointsSequences) this.drawIssuesInPolygon(item, sliderValue);
      }
    });
  }

  drawIssuesInCircle(item: any, sliderValue?: any): void {
    let arr = item.BoundingBoxes;
    if (Number(sliderValue) >= 0) arr = item.BoundingBoxesActual;
    arr.forEach((each: any, index: any) => {
      if (!each) return;
      const points = each.split(',');
      const point1x = Number(points[0]);
      const point1y = Number(points[1]);
      const point2x = Number(points[2]);
      const point2y = Number(points[3]);
      if (!(Number(sliderValue) >= 0) || (item.confidencesActual && Number(item.confidencesActual[index]) >= (sliderValue / 10))) {
        this.issueMarkingContext.beginPath();
        this.issueMarkingContext.arc((point1x + point2x) / 2, (point1y + point2y) / 2, Math.abs(point1x - point2x) * 0.40, 0, 2 * Math.PI);
        this.issueMarkingContext.lineWidth = '5';
        this.issueMarkingContext.strokeStyle = '#ed0400';
        this.issueMarkingContext.stroke();
        this.issueMarkingContext.closePath();
      }
    });
  }

  drawIssuesInPolygon(item: any, sliderValue?: any): void {
    const arr = item.PolygonPointsSequences;
    arr.forEach((each: any) => {
      each.push(each[0]);
      each.forEach((cords: any, index: any) => {
        if (!cords) return;
        const points = cords.split(',');
        const pointX = Number(points[0]);
        const pointY = Number(points[1]);
        if (index === 0) {
          this.issueMarkingContext.beginPath();
          this.issueMarkingContext.moveTo(pointX, pointY);
          return;
        }
        this.issueMarkingContext.lineTo(pointX, pointY);
        this.issueMarkingContext.lineWidth = '5';
        this.issueMarkingContext.stroke();
        this.issueMarkingContext.strokeStyle = '#ed0400';
        if (index === each.length - 1) {
          this.issueMarkingContext.closePath();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  async saveSliderValue(item: any): Promise<any> {
    const score = this.instantCheckupObject.get('thresholdByDoctors') || {};
    score[item.ProblemName.split(' ').join('_').toUpperCase()] = this.sliderValue[item.ProblemName] / 10;
    this.instantCheckupObject.set('thresholdByDoctors', score);
    await this.instantCheckupObject.save();
    this.showToastMessage('Saved');
  }

  sliderChange(item: any, index: any, flag: boolean): void {
    if (!item.confidencesActual) return;
    this.drawProblem(item, index, flag, this.sliderValue[item.ProblemName]);
  }

  changeDisplay(save: boolean = false): any {
    if (this.internalUser) {
      this.canvas.nativeElement.style.width = `${this.displayPercentage}%`;
      this.canvas.nativeElement.style.margin = 'auto';
      if (save) {
        this.internalUser.set('instantCheckupWidth', `${this.displayPercentage}%`);
        this.internalUser.save();
        this.showToastMessage('Saved width');
        this.displayPercentage = null;
      }
    }
  }

  showToastMessage(message: string): void {
    this.broadcaster.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.SUCCESS });
  }
}
