import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { HelperService } from '../../../../services/helper-service';
import { AddDietCardModal } from './add-diet-card';
import { AddLifestyleModal } from './add-lifestyle';
import { AddProductModal } from './add-product';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({
  selector: 'article-edit',
  templateUrl: './article-edit.html',
  styleUrls: ['./article-edit.scss'],
})
export class ArticleEditComponent implements OnDestroy {
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  ui: { modal: { product: { open: boolean }, diet: { open: boolean }, lifestyle: { open: boolean } } };
  articleObj: any;
  id: string;
  heading: string;
  url: string;
  description: string;
  src: Array<string>;
  section: string;
  list: Array<string>;
  sections: Array<any>;
  lifestyleCard: Array<any>;
  dietCard: Array<any>;
  nutritionFact: string;
  point: any;
  lifestylePoint: any;
  question: any;
  answer: any;
  keyword: any;
  keywords: Array<string>;
  typeOptions: Array<any> = [];
  templateOptions: Array<any> = [];
  clickToActionOptions: Array<any> = [];
  classOptions: Array<any> = [];
  conditions: Array<any> = [];
  subscriptions: Array<Subscription>;
  selectedCatalog: any;
  currentAttachedCatalog: any;
  imageArticle: string[] = [];
  constructor(private router: Router, private conn: ConnectionService, private route: ActivatedRoute, private http: HttpClient,
    public appConfig: AppConfig, private helper: HelperService, private dialog: MatDialog,
    private broadcastService: Broadcaster) {
  }

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.params.id;
    this.subscriptions = [];
    this.ui = { modal: { product: { open: false }, diet: { open: false }, lifestyle: { open: false } } };
    this.articleObj = new Table.ArticleData();
    this.reset();
    this.subscribeToUrlQueryParams();
    this.heading = '';
    this.url = '';
    this.description = '';
    this.src = [];
    this.list = [];
    this.point = {};
    this.lifestylePoint = {};
    this.sections = [];
    this.lifestyleCard = [];
    this.dietCard = [];
    this.typeOptions = [
      { display: 'Default', value: 'type1' },
      { display: 'Diet Article', value: 'DIET' }];
    this.clickToActionOptions = [
      { display: 'Buy product', value: this.appConfig.Shared.ArticleData.ClickToAction.BUY_PRODUCT },
      { display: 'Buy regimen  (opens regimen page)', value: this.appConfig.Shared.ArticleData.ClickToAction.BUY_REGIMEN },
      { display: 'Share', value: this.appConfig.Shared.ArticleData.ClickToAction.SHARE },
      { display: 'Refer', value: this.appConfig.Shared.ArticleData.ClickToAction.REFER },
      { display: 'none', value: this.appConfig.Shared.ArticleData.ClickToAction.NONE },
    ];
    this.classOptions = [{ display: 'FACE', value: ApiClientConstant.Regimen.Class.FACE },
      { display: 'HAIR', value: ApiClientConstant.Regimen.Class.HAIR },
      { display: 'BODY', value: ApiClientConstant.Regimen.Class.BODY }];
    this.templateOptions = [
      { display: 'Image Article', value: 'imageArticle' },
      { display: 'Video Article', value: 'videoArticle' },
      { display: 'Product Article', value: 'productArticle' },
      { display: 'General Article', value: 'generalArticle' },
      { display: 'Diet Article', value: 'dietArticle' },
      { display: 'Html', value: 'html' },
    ];
    if (!this.articleObj.has('conditions')) {
      this.articleObj.set('conditions', []);
    }
    if (this.id) {
      [this.currentAttachedCatalog] = await this.conn.findCatalogs({
        where: {
          article: this.articleObj,
        },
        project: ['title', 'quantity', 'quantityUnit', 'type', 'mrp'],
      });
    }
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.article) {
        this.articleObj = this.route.parent.snapshot.data.article;
        this.updateDefaultValue();
      }
    }));
  }

  reset(): void {
    this.articleObj = new Table.ArticleData();
    this.updateDefaultValue();
  }

  updateDefaultValue(): void {
    const data = {
      params: { srcLanguageString: [], list: [], sections: [], lifestyle: [], diet: [] },
      template: this.appConfig.Shared.ArticleData.Template.pug,
      cssLink: 'https://assets.cureskin.com/app/css/style.min.css',
    };
    Object.keys(data).forEach((key: string) => {
      if (this.articleObj.has(key)) {
        return;
      }
      this.articleObj.set(key, data[key]);
    });
  }

  async saveArticle(): Promise<any> {
    if (this.articleObj.get('template') === this.appConfig.Shared.ArticleData.Template.pug
    && !this.articleObj.get('params').srcLanguageString?.length) {
      this.broadcastService.broadcast('NOTIFY', { message: 'HTML is missing.', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (this.currentAttachedCatalog && this.selectedCatalog) {
      await this.removeCatalog();
    }
    if (this.selectedCatalog) {
      this.selectedCatalog.set('article', this.articleObj);
      this.selectedCatalog.set('allowAsAddon', true);
      await this.selectedCatalog.save();
    }
    await this.articleObj.save();
    this.router.navigate([`marketing/article/${this.articleObj.id}`]);
  }
  async removeCatalog(): Promise<void> {
    this.currentAttachedCatalog.unset('article');
    this.currentAttachedCatalog.set('allowAsAddon', false);
    await this.currentAttachedCatalog.save();
    delete this.currentAttachedCatalog;
  }

  openAddDietCardModal(): void {
    const dialogRef = this.dialog.open(AddDietCardModal);
    this.subscriptions.push(dialogRef.afterClosed().subscribe((diet: any) => {
      if (!diet) return;
      this.articleObj.get('params').diet.push(diet);
    }));
  }

  openAddLifestyleModal(): void {
    const dialogRef = this.dialog.open(AddLifestyleModal);
    this.subscriptions.push(dialogRef.afterClosed().subscribe((lifestyle: any) => {
      if (!lifestyle) return;
      this.articleObj.get('params').lifestyle.push(lifestyle);
    }));
  }

  openAddProductModal(): void {
    const dialogRef = this.dialog.open(AddProductModal, { width: '100%' });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((product: any) => {
      if (!product) return;
      this.articleObj.set('product', product);
    }));
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: [ApiClientConstant.Catalog.InventoryStatus.AVAILABLE,
          ApiClientConstant.Catalog.InventoryStatus.UNAVAILABLE, ApiClientConstant.Catalog.InventoryStatus.RESTRICTED],
        article: { $exists: false },
      },
      project: ['title', 'quantity', 'quantityUnit', 'type', 'mrp'],
      limit: 10,
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: `${product.get('title')} [${product.get('quantity')}${product.get('quantityUnit')}] (${product.get('type')})`,
      object: product,
    }));
  }

  onProductSelection(item: { name: string; object: any }): void {
    this.selectedCatalog = item.object;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.subscriptions;
    delete this.articleObj;
    delete this.router;
    delete this.conn;
    delete this.route;
    delete this.http;
    delete this.appConfig;
    delete this.helper;
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
