
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading" #headingView>
    <div class="list-header col-xs-12"><span class="pR20">Internal Users ({{count || 0}})</span>
      <div class="pull-right"><a routerLink="new"><i class="fa fa-plus-square-o m-l-xs pointer"></i></a></div>
    </div>
  </div>
  <div class="flex-content">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content">
          <div class="flex">
            <label class="mr-3">Active</label>
            <label class="i-switch i-switch-sm bg-success">
              <input type="checkbox" [(ngModel)]="activeUser" (ngModelChange)="reset()" name="activeUser"/><i></i>
            </label>
          </div>
          <div>
            <label class="db">Search</label>
            <input class="w100" placeholder="Username" (change)="reset()" [(ngModel)]="searchKey"/>
          </div>
          <div>
            <label class="db">Type</label>
            <select class="w100" placeholder="Username" (change)="reset()" [(ngModel)]="selectedType">
              <option [value]="apiClientConstant.User.Type.OPERATOR">Operator</option>
              <option [value]="apiClientConstant.User.Type.DOCTOR">Doctor</option>
            </select>
          </div>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
