import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'add-sbar-history-popup',
  templateUrl: 'save-sbar-history.html',
})
export class SaveSbarHistoryComponent {
  constructor(
    private dialogRef: MatDialogRef<SaveSbarHistoryComponent>,
    public appConfig: AppConfig) {
  }
  hideModal(): void {
    this.dialogRef.close();
  }
  saveSBAR(event: any): void {
    this.dialogRef.close(event);
  }
}
