import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../../../services/connection-service';
import { AppConfig } from '../../../../app.config';

@Component({ selector: 'consultationSession-Info', templateUrl: './consultation-info-popup.html' })
export class ConsultationSessionInfoPopup {
  consultationSession: any;
  detectedIssues: Array<any> = [];

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private dialogRef: MatDialogRef<ConsultationSessionInfoPopup>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit(): void {
    this.consultationSession = this.data.consultationSession;
    if (!this.consultationSession.get('PrivateInstantCheckupDebug')) return;
    Object.keys(this.consultationSession.get('PrivateInstantCheckupDebug').detectedIssues).forEach((each: string) => {
      this.detectedIssues.push({ key: each, value: this.consultationSession.get('PrivateInstantCheckupDebug').detectedIssues[each] });
    });
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
