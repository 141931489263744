import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { DoctorComponent } from './doctor.component';
import { FollowUpModule } from './follow-up';
import { InternalTeamModule } from './internal-team';
import { AssignedChatsModule } from './assigned-chats';

const ROUTES: Routes = [
  {
    path: 'doctor',
    component: DoctorComponent,
    loadChildren: (): any => FollowUpModule,
  },
  {
    path: 'doctor',
    component: DoctorComponent,
    loadChildren: (): any => AssignedChatsModule,
  },
  {
    path: 'doctor/internalTeam',
    component: DoctorComponent,
    pathMatch: 'full',
    loadChildren: (): any => InternalTeamModule,
  },
];

@NgModule({ imports: [
  BrowserModule,
  RouterModule.forChild(ROUTES),
],
declarations: [DoctorComponent],
exports: [DoctorComponent] })
export class DoctorModule {}
