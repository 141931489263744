import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { AppConfig } from '../../../app/app.config';
import { ConnectionService } from '../../../services/connection-service';
import { InputType } from '../../../../typings/client/input';
import { InputHelperService } from '../../input/input-helper';

@Component({
  selector: 'language-string-tag-selection',
  templateUrl: './language-string-tag-selection.html',
})
export class LanguageStringTagSelectionComponent {
  @Input('parseObj')
  set onUpdateParseObject(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('name')
  set onUpdateName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input('required') required: boolean = false;
  @Output('onValueChange') onValueChange: EventEmitter<any> = new EventEmitter();
  @Input('prefix') prefix: string;
  @Input('title') title: string = 'Tags';

  autoCompleteController: UntypedFormControl = new UntypedFormControl();
  options: Observable<Array<InputType.SelectOption>>;
  ui: any = {};
  name: string;
  parseObj: any;
  parseValue: Array<any> = [];

  static parameters: any = [AppConfig, ConnectionService, InputHelperService];
  constructor(public appConfig: AppConfig, private conn: ConnectionService, private inputHelper: InputHelperService) {
  }

  ngOnInit(): void {
    this.options = this.autoCompleteController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getTags(token)));
  }

  private async getTags(token: string): Promise<Array<InputType.SelectOption>> {
    if (!token) {
      return [];
    }
    const tags = await this.conn.fetchLanguageStringTags({
      q: token,
      limit: 10,
      excludeIds: this.parseValue.map((item: any) => item.id),
    });
    return tags.map((tag: any): InputType.SelectOption => ({
      display: tag.get('name'),
      value: tag.get('name'),
      parseValue: tag,
    }));
  }

  autoCompleteOnSelect(item: InputType.SelectOption): void {
    this.parseValue.push(item.parseValue);
    this.onUpdateSelection();
    this.autoCompleteController.setValue('');
  }

  onUpdateSelection(): void {
    this.inputHelper.setValue(this.parseObj, this.name, this.parseValue);
    this.onValueChange.emit(this.parseValue);
  }

  removeTag(index: number): void {
    this.parseValue.splice(index, 1);
    this.onUpdateSelection();
  }

  async onInputChange(): Promise<any> {
    if (!this.parseObj || !this.name) return;
    this.parseValue = this.inputHelper.getValue(this.parseObj, this.name) || [];
    const pointerTags = this.parseValue
      .filter((value: any) => !value.get('name'))
      .map((value: any) => value.fetch());
    if (pointerTags.length) {
      await Promise.all(pointerTags);
    }
    const updateValue = this.parseValue;
    delete this.parseValue;
    setTimeout(() => {
      this.parseValue = updateValue;
      this.onValueChange.emit(this.parseValue);
    }, 0);
  }
}
