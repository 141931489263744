import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Injectable()
export class ActionResolver {
  constructor(private conn: ConnectionService, private router: Router, private appConfig: AppConfig) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.conn
      .getTree({
        name: route.params.indication,
        type: ApiClientConstant.Tree.Type.ACTION,
      })
      .then((treeObj: any): any => {
        if (treeObj) return Promise.resolve(treeObj);
        this.router.navigate(['../']);
        return false;
      });
  }
}
