
<div class="col-xs-12 p-0" *ngIf="regimens.length">
  <div class="col-xs-12 title" *ngIf="!ui.hideTitle"><em class="fas fa-prescription-bottle-alt pr-3"></em><span>Regimens</span></div>
  <div class="col-xs-12 of-auto" style="max-height:185px;">
    <div class="mb-3 col-xs-12 p-0" *ngFor="let regimen of regimens;let i = index;">
      <div class="col-xs-8"><a class="text-info" routerLink="/products/regimen/{{regimen.get('regimenId')}}" target="_blank" title="{{regimen.get('title')}} has {{regimen.get('updateVersion')}} edits"><span *ngIf="regimen.get('concernsLanguageString')">
            <language-view [languageStringObj]="regimen.get('concernsLanguageString')"></language-view></span><span class="ml-3" *ngIf="!regimen.get('concernsLanguageString')">{{regimen.get('regimenId')}}</span><span class="ml-3" *ngIf="regimen.get('updateVersion')">({{regimen.get('updateVersion')}})</span><span class="ml-3">({{regimen.get('active')? 'active': 'inActive' }})</span></a></div>
      <ng-container>
        <div class="pull-right mb-2"><i class="fa fa-send ml-4" (click)="resendRegimen(i)" title="Resend Regimen"></i><a class="fa fa-edit ml-4" *ngIf="userRoles.includes('regimenEditor')" routerLink="/products/regimen/{{regimen.get('regimenId')}}/edit" target="_blank" title="edit"></a><a class="fa fa-eye ml-4" href="{{baseUrlForWebApp}}/user?tab=regimen&amp;regimenId={{regimen.get('regimenId')}}&amp;username={{user.get('username')}}" target="_blank" title="View Regimen"></a><a class="fa fa-trash-o ml-4" *ngIf="userRoles.includes('regimenEditor') || userRoles.includes('adminOperator')" (dblclick)="deleteRegimen(regimen)"></a></div>
      </ng-container>
      <morning-night-list [regimen]="regimen"></morning-night-list>
    </div>
  </div>
</div>