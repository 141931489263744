
<div class="col-xs-12">
  <form #rosterForm="ngForm">
    <div class="col-xs-12">
      <div class="h3">Roster</div>
    </div>
    <div class="col-xs-12">
      <div class="line bg-gd-dk"></div>
    </div>
    <div class="col-xs-12">
      <div class="col-xs-6">Start Date:</div>
      <div class="col-xs-6">
        <input class="input-sm" [(ngModel)]="startDate" name="start Date" (ngModelChange)="updateDate()" required="required" placeholder="Enter startDate" type="date"/>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="col-xs-12">Week Template:<i class="fa fa-plus-square-o pointer ml-2" (click)="addWeek()"></i></div>
      <div class="col-xs-12">
        <div class="col-xs-12 mt-2 mb-2" *ngFor="let week of weeks;let i=index;"><span>Week {{i + 1}} :</span><span class="ml-1 pl-3 pr-3 pt-2 pb-2 rounded pointer" [ngClass]="{ 'bg-secondary': week[0] === 0, 'bg-danger': week[0] === 1 }" (click)="toggleWorking(i, 0)" title="Sunday">S</span><span class="ml-1 pl-3 pr-3 pt-2 pb-2 rounded pointer" [ngClass]="{ 'bg-secondary': week[1] === 0, 'bg-danger': week[1] === 1 }" (click)="toggleWorking(i, 1)" title="Monday">M</span><span class="ml-1 pl-3 pr-3 pt-2 pb-2 rounded pointer" [ngClass]="{ 'bg-secondary': week[2] === 0, 'bg-danger': week[2] === 1 }" (click)="toggleWorking(i, 2)" title="Tuesday">T</span><span class="ml-1 pl-3 pr-3 pt-2 pb-2 rounded pointer" [ngClass]="{ 'bg-secondary': week[3] === 0, 'bg-danger': week[3] === 1 }" (click)="toggleWorking(i, 3)" title="Wednesday">W</span><span class="ml-1 pl-3 pr-3 pt-2 pb-2 rounded pointer" [ngClass]="{ 'bg-secondary': week[4] === 0, 'bg-danger': week[4] === 1 }" (click)="toggleWorking(i, 4)" title="Thursday">T</span><span class="ml-1 pl-3 pr-3 pt-2 pb-2 rounded pointer" [ngClass]="{ 'bg-secondary': week[5] === 0, 'bg-danger': week[5] === 1 }" (click)="toggleWorking(i, 5)" title="Friday">F</span><span class="ml-1 pl-3 pr-3 pt-2 pb-2 rounded pointer" [ngClass]="{ 'bg-secondary': week[6] === 0, 'bg-danger': week[6] === 1 }" (click)="toggleWorking(i, 6)" title="Saturday">S</span><span class="ml-2 fa fa-trash-o pointer" (click)="removeWeek(i)"></span></div>
      </div>
    </div>
    <div class="col-xs-12">
      <button class="pull-right" [disabled]="rosterForm.invalid" (click)="saveRoster()">Save</button>
    </div>
  </form>
</div>