
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change Allocated Doctor</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body mt-0">
    <div class="col-xs-12">
      <label>Select Other Doctor</label>
      <div class="col-xs-12">
        <mat-form-field class="col-xs-5">
          <mat-select class="w100" [(ngModel)]="selectedDoctor">
            <mat-option *ngFor="let option of doctorList" [value]="option">{{option.get('DoctorDisplayName')}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xs-8 flex" *ngIf="type === appConfig.Shared.AllocatedDoctorPopup.Type.FOLLOWUP">
        <label class="col-xs-3">Label</label>
        <select [(ngModel)]="label" name="label">
          <option *ngFor="let option of allocatedTeamType" [value]="option.value">{{option.display}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="pull-right" (click)="save()" [disabled]="!selectedDoctor && !label">Save</button>
  </div>
</mat-dialog-content>
