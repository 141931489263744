import { Component } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { Broadcaster } from '../../../components/broadcaster';

declare interface OrderType {
  type: string;
  contactNumber: string;
  contactName: string;
  stage: string;
  products: Array<any>;
  services: Array<any>;
  paymentType: string;
  courierName?: string;
  trackingURL?: string;
  trackingId?: string;
  note?: string;
  regimenId: string;
  referenceOrder?: any;
  user: { displayName: string, userObj: any };
  amount?: number;
  actualPrice?: number;
  contactDetail: any;
  noMessageToUser?: boolean;
  consultationCharges: number;
  updateCashCoupon?: boolean;
  regimen?: any;
}

@Component({ selector: 'cart-edit', templateUrl: './cart-edit.html', styleUrls: ['./cart-edit.scss'] })
export class CartEditComponent {
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  cart: any;
  cartAmout: any = 0;
  user: any;

  order: OrderType;
  orderObj: any;
  orderTypes: Array<{ name: string, value: string }> = [{ name: 'PRODUCT', value: 'PRODUCT' }];
  paymentTypes: {name: string, value: string}[] = [
    { name: 'ONLINE', value: ApiClientConstant.Order.PaymentType.ONLINE },
    { name: 'COD', value: ApiClientConstant.Order.PaymentType.COD },
  ];
  updateAddress: boolean = false;
  noMessageToUser: any;
  updateCashCoupon: any;

  constructor(
    private conn: ConnectionService,
    private route: ActivatedRoute,
    private router: Router,
    public appConfig: AppConfig,
    private broadcaster: Broadcaster,
  ) {
    this.createOrderObject();
    this.orderObj = new Table.Order();
  }

  async ngOnInit(): Promise<void> {
    const { username }: { username: string } = this.route.snapshot.queryParams as { username: string };
    this.user = await this.conn.findOneUser({
      where: { $or: ['objectId', 'username', 'MobileNumber'].map((key: string) => ({ [key]: username })) },
      limit: 1,
      project: ['PatientName', 'username', 'contactDetails', 'MobileNumber'],
    });

    this.autoCompleteOnUserSelect({ object: this.user });

    await this.fetchUserCart();
    this.updateCartPrice();
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
  }

  async fetchUserCart(): Promise<void> {
    this.cart = await this.conn.findCart({
      where: { user: this.user },
    });
  }

  async autoCompleteOnProductSelect(item: { name: string; object: any }): Promise<void> {
    const productInCart = this.cart.get('lineItems').find((product: any): boolean => product.productId === item?.object.id);

    if (productInCart) {
      productInCart.quantity += 1;
      this.autoCompleteProductController.setValue('');
      return;
    }

    const newProductObject = {
      productId: item?.object.id,
      title: item.object.get('title'),
      quantity: 1,
      mrp: item.object.get('mrp'),
      price: item.object.get('price'),
      rebrandedImageWithBackground: item.object?.get('rebrandedImageWithBackground')?.[0],
      rebrandedImageWithoutBackground: item.object?.get('rebrandedImageWithoutBackground')?.[0],
      quantityUnit: item.object.get('quantityUnit'),
    };
    this.cart.get('lineItems').push(newProductObject);
    this.updateCartPrice();
    this.autoCompleteProductController.setValue('');
  }

  async removeProductFromCart(index: number): Promise<void> {
    this.cart?.get('lineItems').splice(index, 1);
    this.updateCartPrice();
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
      },
      project: ['title', 'quantity', 'quantityUnit', 'type',
        'margUnit', 'mrp', 'price', 'rebrandedImageWithBackground', 'rebrandedImageWithoutBackground'],
      ascending: 'margUnit',
      limit: 5,
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(product),
      object: product,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${(product.get('margUnit') || 1) > 1
      ? `${product.get('margUnit')} sheets, `
      : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  async addQuantity(product: any): Promise<void> {
    // eslint-disable-next-line operator-assignment, no-param-reassign
    product.quantity = product.quantity + 1;
    this.updateCartPrice();
  }

  async removeQuantity(product: any, index: number): Promise<void> {
    // eslint-disable-next-line operator-assignment, no-param-reassign
    product.quantity = product.quantity - 1;

    if (product.quantity === 0) {
      await this.removeProductFromCart(index);
    }
    this.updateCartPrice();
  }

  async update(): Promise<void> {
    await this.cart.save();
    await this.fetchUserCart();
    this.broadcaster.broadcast('NOTIFY', {
      message: 'Cart updated',
      type: this.appConfig.Shared.Toast.Type.SUCCESS,
    });
  }

  updateCartPrice(): void {
    // eslint-disable-next-line no-unsafe-optional-chaining
    this.cartAmout = this.cart?.get('lineItems').reduce((prev: number, curr: any) => (prev + curr?.quantity * curr?.price), 0);
  }

  autoCompleteOnUserSelect(item: { object: any }): void {
    this.order.user.userObj = item.object;
    this.order.contactDetail = Object.assign(
      JSON.parse(JSON.stringify(this.order.user.userObj.get('contactDetails') || {})),
      {
        contactName: this.order.user.userObj.get('PatientName'),
        contactNumber: this.order.user.userObj.get('MobileNumber'),
      });

    if (!this.orderObj.id) {
      this.orderObj.set('user', item.object);
    }
    this.updateCartPrice();
  }

  updateAddressBook(): void {
    this.orderObj.set('addressBook', this.orderObj.get('addressBook'));
  }

  async saveOrder(): Promise<void> {
    if (!this.order.paymentType) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Select a payment type',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return;
    }

    const user = new Table.User();
    user.id = this.order.user.userObj.id;

    const products = this.cart.get('lineItems')
      .map((product: any): string => (product.quantity === 1
        ? product.productId : `${product.productId},`.repeat(product.quantity).replace(/(^,)|(,$)/g, '')
      )).join(',');

    const order = {
      addressBookId: this.orderObj.get('addressBook').id,
      paymentType: this.order.paymentType || ApiClientConstant.Order.PaymentType.NOT_SELECTED,
      regimenId: '',
      type: 'PRODUCT',
      uniqueId: uuid(),
      userObjectId: this.user.id,
      products,
      services: '',
    };

    try {
      const checkoutResponse = await ApiConnector.cloudRun('checkoutAndCreateOrder', order);
      this.router.navigate(['chat', this.user.get('MobileNumber')]);
    } catch (e: any) {
      this.broadcaster.broadcast('NOTIFY', {
        message: e?.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  createOrderObject(): void {
    this.order = {
      consultationCharges: 0,
      type: ApiClientConstant.Order.Type.REGIMEN,
      contactNumber: '',
      contactName: '',
      regimenId: '',
      stage: 'INITIAL',
      paymentType: '',
      products: [],
      services: [],
      user: { displayName: '', userObj: new Table.User() },
      contactDetail: {},
    };
  }
}
