import { Component, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent {
  triggerByKeywordId: string;
  keywordTriggerTree: any;
  positiveKeywords: Array<string> = [];
  negativeKeywords: Array<string> = [];
  positiveKeywordInput: string = '';
  negativeKeywordInput: string = '';

  constructor(public appConfig: AppConfig, private router: Router, private route: ActivatedRoute,
    private conn: ConnectionService, private zone: NgZone) {
  }
  async ngOnInit(): Promise<any> {
    this.triggerByKeywordId = this.route.snapshot.params.id;
    if (this.triggerByKeywordId) {
      this.keywordTriggerTree = await this.conn.findKeywordTriggerTreeById(this.triggerByKeywordId);
      this.positiveKeywords = this.keywordTriggerTree.get('positive');
      this.negativeKeywords = this.keywordTriggerTree.get('negative');
    } else {
      this.keywordTriggerTree = new Table.KeywordTriggerTree();
    }
  }

  saveKeywordTriggerTree(): void {
    this.keywordTriggerTree.set('positive', this.positiveKeywords);
    this.keywordTriggerTree.set('negative', this.negativeKeywords);
    this.keywordTriggerTree.save();
    this.router.navigate(['/trees/trigger-by-keyword']);
  }

  removePositive(index: number): void {
    this.positiveKeywords.splice(index, 1);
  }

  removeNegative(index: number): void {
    this.negativeKeywords.splice(index, 1);
  }

  addpositive(): void {
    this.positiveKeywords.push(this.positiveKeywordInput);
    this.positiveKeywordInput = '';
  }

  addnegative(): void {
    this.negativeKeywords.push(this.negativeKeywordInput);
    this.negativeKeywordInput = '';
  }
}
