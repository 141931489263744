import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndicationComponent } from './indication.component';
import { IndicationViewModule } from './view';
import { IndicationEditModule } from './edit';
import { ActionResolver } from '../action/action-resolver';
import { TreeResolver } from '../../tree-resolver';
import { RegimenSopResolver } from '../regimenSop/regimen-sop-resolver';

const ROUTES: Routes = [
  {
    path: 'indications/new',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    pathMatch: 'full',
  },
  {
    path: 'treatment_delivery/new',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    pathMatch: 'full',
  },
  {
    path: 'regimen_sop/new',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    pathMatch: 'full',
  },
  {
    path: 'answer_dependent/new',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    pathMatch: 'full',
  },
  {
    path: 'actions/new',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    pathMatch: 'full',
  },
  {
    path: 'indication/:indication',
    component: IndicationComponent,
    loadChildren: (): any => IndicationViewModule,
    resolve: { subTree: TreeResolver },
    pathMatch: 'full',
  },
  {
    path: 'treatment_delivery/:treatmentDeliveryTreeId',
    component: IndicationComponent,
    loadChildren: (): any => IndicationViewModule,
    resolve: { subTree: TreeResolver },
    pathMatch: 'full',
  },
  {
    path: 'regimen_sop/:indication',
    component: IndicationComponent,
    loadChildren: (): any => IndicationViewModule,
    resolve: { subTree: RegimenSopResolver },
    pathMatch: 'full',
  },
  {
    path: 'answer_dependent/:answerDependentTreeId',
    component: IndicationComponent,
    loadChildren: (): any => IndicationViewModule,
    resolve: { subTree: TreeResolver },
    pathMatch: 'full',
  },
  {
    path: 'action/:indication',
    component: IndicationComponent,
    loadChildren: (): any => IndicationViewModule,
    resolve: { subTree: ActionResolver },
    pathMatch: 'full',
  },
  {
    path: 'indication/:indication/edit',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    resolve: { subTree: TreeResolver },
    pathMatch: 'full',
  },
  {
    path: 'treatment_delivery/:treatmentDeliveryTreeId/edit',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    resolve: { subTree: TreeResolver },
    pathMatch: 'full',
  },
  {
    path: 'regimen_sop/:indication/edit',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    resolve: { subTree: RegimenSopResolver },
    pathMatch: 'full',
  },
  {
    path: 'answer_dependent/:answerDependentTreeId/edit',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    resolve: { subTree: TreeResolver },
    pathMatch: 'full',
  },
  {
    path: 'action/:indication/edit',
    component: IndicationComponent,
    loadChildren: (): any => IndicationEditModule,
    resolve: { subTree: ActionResolver },
    pathMatch: 'full',
  },
];

@NgModule({
  providers: [TreeResolver, ActionResolver, RegimenSopResolver],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [IndicationComponent],
  exports: [IndicationComponent],
})
export class IndicationModule {
}
