import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DirectivesModule } from '../../../components/directives.module';
import { OrderEditComponent } from './edit.component';
import { InputSelectModule } from '../../../components/input/input-select';
import { InputCheckboxModule } from '../../../components/input/input-checkbox';
import { AddressBookModule } from './address-book-selection/address-book.module';
import { OrderNoteModule } from '../view/order-note/order-note.module';

const ROUTES: Routes = [
  { path: '', component: OrderEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    InputSelectModule,
    InputCheckboxModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
    AddressBookModule,
    OrderNoteModule,
  ],
  declarations: [OrderEditComponent],
  exports: [OrderEditComponent],
})
export class OrderEditModule {
}
