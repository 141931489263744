import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'change-password-modal',
  templateUrl: './change-password.html',
})
export class ChangePasswordModal {
  confirmPassword: string;
  newPassword: string;
  ui: { saving: boolean, error?: string };

  public subscriptions: Subscription[] = [];
  static parameters: any = [ConnectionService, Router, AppConfig, MatDialogRef];
  constructor(private conn: ConnectionService,
              private router: Router,
              private appConfig: AppConfig,
              private dialogRef: MatDialogRef<ChangePasswordModal>) {
    this.ui = { saving: false };
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  changePassword(): void {
    if (!this.newPassword || this.newPassword !== this.confirmPassword) return;
    const user = Parse.User.current();
    user.set('password', this.newPassword);
    this.ui.saving = true;
    user.save()
      .then(() => this.hideModal())
      .catch(() => {
        this.ui.saving = false;
        this.ui.error = 'Unable to save password';
      });
  }
}
