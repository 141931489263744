import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'support-ticket-history-model',
  templateUrl: './support-ticket-history.html',
})
export class SupportTicketHistoryComponent {
  user: any;
  supportTickets: Array<any> = [];
  constructor(
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private injector: Injector,
    private dialogRef: MatDialogRef<SupportTicketHistoryComponent>) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.user = data.user;
  }

  async ngOnInit(): Promise<void> {
    await this.user.fetch();
    this.supportTickets = await ApiConnector.find(Table.SupportTicket, {
      where: { user: this.user },
      include: ['supportQuestion', 'supportQuestion.questionLanguageString' as 'supportQuestion',
        'titleLanguageString', 'createdBy', 'completedBy'],
      descending: 'createdAt',
      project: ['title', 'titleLanguageString', 'status', 'supportQuestion.questionLanguageString' as 'supportQuestion',
        'createdBy.type' as 'createdBy', 'createdBy.username' as 'createdBy', 'createdAt', 'closedAt',
        'completedBy.username' as 'completedBy', 'attended', 'teams'],
    });
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
