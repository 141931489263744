import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { WareHouseComponent } from './warehouse.component';
import { ListModule } from './list';
import { EditModule } from './edit';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';

const ROUTES: Routes = [
  {
    path: '',
    component: ListComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'new',
    component: EditComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: ':id/edit',
    component: EditComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  RouterModule.forChild(ROUTES),
],
declarations: [WareHouseComponent],
exports: [WareHouseComponent],
providers: [] })
export class WareHouseModule {}
