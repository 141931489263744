import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { SimilarLanguageStringSuggestionComponent } from './similar_language_string_suggestion.component';

@NgModule({
  imports: [MatDialogModule, CommonModule],
  declarations: [SimilarLanguageStringSuggestionComponent],
  exports: [SimilarLanguageStringSuggestionComponent],
})
export class SimilarLanguageStringModule {}
