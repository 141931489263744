
<div class="comparison-slider">
  <div class="heading">{{title}}</div>
  <div class="auto-face-container-empty posR" *ngIf="instantCheckupIds?.length !== 2">
    <div class="beforeEmpty" (click)="selectBeforeImage.emit(true)">
      <p>+</p>
      <p>Add Photo</p>
    </div>
    <div class="sliderEmpty">
      <div class="slider-handle"><i> <img src="../../assets/images/handle.png"/></i></div>
    </div>
    <div class="afterEmpty" (click)="selectAfterImage.emit(true)">
      <p>+</p>
      <p>Add Photo</p>
    </div>
  </div>
  <div *ngIf="instantCheckupIds?.length === 2">
    <div class="improvement" #container>
      <div class="img-container" id="imgContainer" [ngStyle]="{ visibility: (!imageLoaded[0] && !imageLoaded[1]) ? &quot;hidden&quot; : &quot;visible&quot; }">
        <div class="img-holder-1" #imgH1>
          <canvas class="posR" #imageOne></canvas>
        </div>
        <div class="img-holder-2" #imgH2>
          <canvas class="posR" #imageTwo></canvas>
        </div>
        <div class="rebranding-slider" #slider [ngStyle]="{ visibility: (!imageLoaded[0] || !imageLoaded[1]) ? &quot;hidden&quot; : &quot;visible&quot; }"><i> <img src="../../assets/images/handle.png"/></i></div>
      </div>
    </div>
  </div>
  <p *ngIf="instantCheckupIds?.length === 1">One image selected, Please select one more for comparision.</p>
  <div class="caption-container">
    <p class="caption"><span>Before</span><span class="caption-date" *ngIf="instantCheckupIds?.length === 2">({{instantCheckups[1]?.createdAt | date: 'dd MMM,YYYY'}})</span></p>
    <p class="caption"><span>After</span><span class="caption-date" *ngIf="instantCheckupIds?.length === 2">({{instantCheckups[0]?.createdAt | date: 'dd MMM,YYYY'}})</span></p>
  </div>
</div>
