import { NgZone, Component } from '@angular/core';

import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'push-notification-model',
  templateUrl: './push-notification.html',
})
export class PushNotificationModel {
  question: string;
  pushNotificationData: any = {};
  user: any;

  public subscriptions: Subscription[] = [];
  static parameters: any = [NgZone, ConnectionService, Router, AppConfig, MatDialogRef];

  constructor(private zone: NgZone, private conn: ConnectionService,
     private router: Router, private appConfig: AppConfig, private dialogRef: MatDialogRef<PushNotificationModel>) {
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  sendPushNotification(): void {
    if (!this.pushNotificationData.message) return;
    const message: any = this.pushNotificationData.message.replace(/\n/g, '<br/>');
    const usernames: any = this.pushNotificationData.userId.split(';');
    this.hideModal();
    this.processNotificationSendingInBatch(usernames,
      this.pushNotificationData.type,
      message,
      this.pushNotificationData.openUrl,
      this.pushNotificationData.imageLink);
    this.pushNotificationData = {};
  }

  async wait(timeMs: number): Promise<any> {
    await new Promise((res: any): any => {
      setTimeout(() => {
        res();
      }, timeMs);
    });
  }

  async processNotificationSendingInBatch(usernames: Array<string>, typeName: string, message: string, openUrl?: string, imageLink?: string)
    : Promise<any> {
    if (!usernames.length) return Promise.resolve();
    const batch = usernames.splice(0, Math.min(100, usernames.length));
    batch.forEach((username: string) => this.sendNotification(username, typeName, message, openUrl, imageLink));
    await this.wait(200);
    return this.processNotificationSendingInBatch(usernames, typeName, message, openUrl, imageLink);
  }

  sendNotification(username: string, typeName: string, message: string, openUrl?: string, imageLink?: string): any {
    return this.conn
      .pushNotification({
        username,
        typeName,
        message,
        openUrl,
        imageLink,
      });
  }
}
