
<mat-dialog-content *ngIf="node">
  <div class="modal-header">
    <h4 class="modal-title pull-left" *ngIf="parentNode">Create Under {{parentNode.get('name')}}</h4>
    <h4 class="modal-title pull-left" *ngIf="!parentNode">Create Root Folder</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form #catalogForm="ngForm">
      <div class="col-xs-12">
        <label class="col-xs-12 mt-0">Type</label>
        <div class="col-xs-12">
          <input-select [parseObj]="node" name="type" [options]="types" [required]="true" (onValueChanged)="isFolder = appConfig.Shared.EmbeddedLink.Type.Folder === $event"></input-select>
        </div>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-12">Name</label>
        <div class="col-xs-12">
          <input-text [parseObj]="node" name="name" required="true"></input-text>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="!isFolder">
        <label class="col-xs-2">Link Type</label>
        <div class="col-xs-12">
          <input-select [parseObj]="node" name="linkType" [options]="linkTypes" [required]="true"></input-select>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="!isFolder">
        <label class="col-xs-12">Link</label>
        <div class="col-xs-12">
          <input-text [parseObj]="node" name="link" required="true"></input-text>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" [disabled]="catalogForm.invalid" (click)="updateOrCreateNode()">{{node.id ? "Update" : "Create" }}</button>
  </div>
</mat-dialog-content>