class TreeNode {
  static DELIMETER: string = '#';
  static nodeCount: number = 0;

  childs: { [key: string]: TreeNode } = {};
  rowCol: Array<{ row: number, col: number }> = [];

  constructor(public nodeName: string) {
    TreeNode.nodeCount += 1;
  }

  addChild(branchName: string, nodeName: string): TreeNode {
    if (!this.childs[branchName]) {
      this.childs[branchName] = new TreeNode(nodeName);
    }
    return this.childs[branchName];
  }

  addRowCol(row: number, col: number): void {
    this.rowCol.push({ row, col });
  }

  findChildNode(answer: string): TreeNode {
    return this.childs[answer];
  }

  addChildTreeNode(branchName: string, childTreeNode: TreeNode): void {
    this.childs[branchName] = childTreeNode;
  }

  get inOrderTraversal(): string {
    const childInOrderTraversal = Object.keys(this.childs).sort()
      .map((child: string) => this.childs[child].inOrderTraversal);
    if (!childInOrderTraversal.length) return this.nodeName;
    if (childInOrderTraversal.length === 1) {
      childInOrderTraversal.push('');
    }
    return childInOrderTraversal.join(`${TreeNode.DELIMETER}${this.nodeName}${TreeNode.DELIMETER}`);
  }

  get childNodes(): Array<TreeNode> {
    return Object.values(this.childs);
  }

  childAt(childNumber: number): string {
    return Object.keys(this.childs)[childNumber];
  }
}

export { TreeNode };
