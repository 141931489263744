import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OpenSupportTicketsComponent } from './open-support-tickets.component';
import { OpenSupportTicketsListModule } from './open-support-tickets-list/open-support-tickets-list.module';
import { OpenSupportTicketsEditModule } from './open-support-tickets-edit/open-support-tickets-edit.module';

const ROUTES: Routes = [
  {
    path: '',
    component: OpenSupportTicketsComponent,
    loadChildren: (): any => OpenSupportTicketsListModule,
    pathMatch: 'full',
  },
  {
    path: 'new',
    component: OpenSupportTicketsComponent,
    loadChildren: (): any => OpenSupportTicketsEditModule,
    pathMatch: 'full',
  },
  {
    path: ':id/edit',
    component: OpenSupportTicketsComponent,
    loadChildren: (): any => OpenSupportTicketsEditModule,
    pathMatch: 'full',
  },
  {
    path: 'priority',
    component: OpenSupportTicketsComponent,
    loadChildren: (): any => OpenSupportTicketsListModule,
    pathMatch: 'full',
  },
];

@NgModule({ providers: [],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [OpenSupportTicketsComponent],
  exports: [OpenSupportTicketsComponent] })
export class OpenSupportTicketsModule {}
