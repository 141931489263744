import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from '../../../../services/connection-service';

@Component({
  selector: 'tree-view-route',
  templateUrl: './tree-view-route.html',
})
export class TreeViewRouteComponent {
  filters: any;
  fields: Array<string> = ['name'];
  ui: any = {
    isFilterOpen: false,
    loading: false,
    filters: {
      question: { status: false, list: [] },
      questionWithAnswer: { status: false, list: [] },
      products: { status: false, list: [] },
    },
  };
  tempFilters: any;
  trees: any;
  groups: any = {};
  groupKeys: string[] = [];
  displayGroups: any = {};
  displayGroupKeys: string[] = [];
  regimenMap: any = {};
  questionTooltip: any = {};
  static parameters: any = [ConnectionService, HttpClient];
  constructor(private conn: ConnectionService, private http: HttpClient) {
    this.filters = [];
  }

  ngOnInit(): void {
    this.fetchTree();
  }

  fetchTree(): void {
    this.ui.loading = true;
    this.http.get(`${this.conn.getParseUrl()}/api/tree`)
      .toPromise()
      .then((treeJSON: object) => this.startProcessingOfTreeJSON(treeJSON));
  }

  reset(): void {
    this.trees = [];
  }

  private startProcessingOfTreeJSON(treeJSON: any): void {
    this.removeRedirectFromRegimenAndConsultation(treeJSON);
    const rootNodes = this.findRootNodes(treeJSON);
    const trees = this.constructTrees(rootNodes, treeJSON);
    const groups = this.groupTrees(trees);
    this.filterDuplicateEntryInGroups(groups);
    Promise.all([
      this.updateQuestionAnswers(groups),
      this.updateRegimenProducts(Object.keys(groups)),
    ])
      .then(() => (this.ui.loading = false));
    const groupKeys = this.getSortGroups(groups);
    this.addTreeInformationNode(groups, groupKeys);
    this.mergeNodesOfTrees(groups, groupKeys);
    this.groups = groups;
    this.groupKeys = groupKeys;
    this.updateDisplayList();
  }

  async updateRegimenProducts(regimenId: string[]): Promise<any> {
    const regimens = await this.conn.getRegimens({ where: { regimenId }, include: ['products'] });
    const regimenMap = {};
    const languageStringIds = [];
    regimens.forEach((regimen: any) => {
      regimenMap[regimen.get('regimenId')] = regimen.get('products');
      regimen.get('products').forEach((product: any) => {
        if (languageStringIds.includes(product.get('titleLanguageString').id)) return;
        languageStringIds.push(product.get('titleLanguageString').id);
      });
    });
    const languageStrings = await this.conn.getLanguageStrings({ id: languageStringIds });
    Object.keys(regimenMap).forEach((id: string) => {
      regimenMap[id] = regimenMap[id].map((product: any) => languageStrings
        .find((languageString: any) => (languageString.id === product.get('titleLanguageString').id)).get('en'));
    });
    this.regimenMap = regimenMap;
  }

  private removeRedirectFromRegimenAndConsultation(treeJSON_: any): void {
    const treeJSON = treeJSON_;
    Object.keys(treeJSON).forEach((nodeName: string) => {
      if (!nodeName.startsWith('regimen:')) return;
      treeJSON[nodeName].redirect = {};
    });
    treeJSON['v4_ConsultationService:'].redirect = {};
  }

  private findRootNodes(treeJSON: any): any {
    const childToParent = {};
    Object.keys(treeJSON).forEach((nodeName: string) => {
      const node = treeJSON[nodeName];
      if (!childToParent[nodeName]) {
        childToParent[nodeName] = { parents: [] };
      }
      Object.keys(node.redirect).forEach((answer: string) => {
        const redirectNodeName = node.redirect[answer];
        if (!childToParent[redirectNodeName]) {
          childToParent[redirectNodeName] = { parents: [] };
        }
        childToParent[redirectNodeName].parents.push({ answer, nodeName, fieldName: node.fieldName });
      });
    });
    const rootNodes = Object.keys(childToParent)
      .filter((nodeName: string) => !childToParent[nodeName].parents.length);
    return rootNodes;
  }

  private constructTrees(treeNames: string[], treeJSON: any): Array<any> {
    const treeName = treeNames.shift();
    if (!treeName) return [];
    const tree = this.constructTree(treeName, treeJSON);
    const trees = this.constructTrees(treeNames, treeJSON);
    trees.push(...tree);
    return trees;
  }

  private addNodeToPathWithLoopDetection(paths: any[], node: any): void {
    const visitedNodes = {};
    paths.forEach((each: any) => (visitedNodes[each.nodeName] = 1));
    if (visitedNodes[node.nodeName] || paths.length > 1000) {
      const nodeNamePath = paths.map((each: any) => each.nodeName);
      nodeNamePath.push(node.nodeName);
      // eslint-disable-next-line angular/log
      // console.log('>>>>Tree Path', nodeNamePath);
      throw new Error('Loop Detected');
    }
    paths.push(node);
  }

  private constructTree(treeName: string, treeJSON: any): any[][] {
    if (!Object.keys(treeJSON[treeName].redirect).length) {
      return [[{
        fieldName: treeJSON[treeName].fieldName || treeJSON[treeName].regimenId,
        treeName,
        answers: [],
      }]];
    }
    const paths = [];
    const stack = [];
    const relativePath = [];
    this.addNodeToPathWithLoopDetection(
      stack,
      { fieldName: treeJSON[treeName].fieldName, nodeName: treeName, answers: Object.keys(treeJSON[treeName].redirect) });
    while (stack.length) {
      const stackItem = stack.pop();
      const answer = stackItem.answers.shift();
      if (!answer) {
        const isEndNode = !Object.keys(treeJSON[stackItem.nodeName].redirect).length;
        if (isEndNode) {
          this.addNodeToPathWithLoopDetection(
            relativePath,
            { fieldName: stackItem.fieldName, answers: [], treeName, nodeName: stackItem.nodeName });
          paths.push(JSON.parse(JSON.stringify(relativePath)));
        }
        relativePath.pop();
      } else {
        const isNodeFirstVisit = Object.keys(treeJSON[stackItem.nodeName].redirect).length === (stackItem.answers.length + 1);
        const nodeName = treeJSON[stackItem.nodeName].redirect[answer];
        this.addNodeToPathWithLoopDetection(stack, stackItem);
        this.addNodeToPathWithLoopDetection(
          stack,
          { fieldName: treeJSON[nodeName].fieldName, nodeName, answers: Object.keys(treeJSON[nodeName].redirect) });
        if (!isNodeFirstVisit) {
          relativePath.pop();
        }
        this.addNodeToPathWithLoopDetection(
          relativePath,
          { fieldName: stackItem.fieldName, answers: [answer], treeName, nodeName: stackItem.nodeName });
      }
    }
    return paths;
  }

  private groupTrees(trees: any[][]): any {
    const groups = { consultation: [], evaluate: [], endNode: [] };
    trees.forEach((tree: any[]) => {
      const lastNode = tree[tree.length - 1];
      if (lastNode.nodeName && lastNode.nodeName.startsWith('regimen:')) {
        const [, regimenId]: any = lastNode.nodeName.split('regimen:');
        if (!groups[regimenId]) {
          groups[regimenId] = [];
        }
        tree.pop();
        groups[regimenId].push(tree);
        return;
      }
      if (lastNode.treeName === 'v4_ConsultationService') {
        groups.consultation.push(tree);
        return;
      }
      if (lastNode.fieldName && lastNode.fieldName.startsWith('evaluate')) {
        groups.evaluate.push(tree);
        return;
      }
      groups.endNode.push(tree);
    });
    return groups;
  }

  private filterDuplicateEntryInGroups(groups_: any): void {
    const groups = groups_;
    Object.keys(groups).forEach((group: string) => {
      groups[group].forEach((path: any[], index: number) => {
        const uniqueIds = {};
        groups[group][index] = path.filter((item: any) => {
          if (uniqueIds[item.fieldName]) return false;
          uniqueIds[item.fieldName] = 1;
          return true;
        });
      });
    });
  }

  private getSortGroups(groups: any): string[] {
    const groupKeys = Object.keys(groups);
    groupKeys.sort((item1: string, item2: string) => {
      const isItem1Regimen = item1.startsWith('v4_');
      const isItem2Regimen = item2.startsWith('v4_');
      if (isItem1Regimen && isItem2Regimen) {
        const regimen1 = Number(item1.split('_')[1]);
        const regimen2 = Number(item2.split('_')[1]);
        if (regimen1 === regimen2) return 0;
        return regimen1 > regimen2 ? 1 : -1;
      }
      if (isItem1Regimen) {
        return -1;
      }
      if (isItem2Regimen) {
        return 1;
      }
      if (!item1.localeCompare(item2)) return 0;
      return item1.localeCompare(item2) > 0 ? 1 : -1;
    });
    groupKeys.forEach((key: string) => {
      const questionCount = {};
      groups[key].forEach((row: any) => row.forEach((cel: any) => {
        if (!questionCount[cel.fieldName]) {
          questionCount[cel.fieldName] = { count: 0, answers: {} };
        }
        questionCount[cel.fieldName].count += 1;
        cel.answers.forEach((answer: string) => {
          if (!questionCount[cel.fieldName].answers[answer]) {
            questionCount[cel.fieldName].answers[answer] = 0;
          }
          questionCount[cel.fieldName].answers[answer] += 1;
        });
      }));
      groups[key].forEach((row: any) => row.sort((item1: any, item2: any) => {
        const item1QuestionCount = questionCount[item1.fieldName].count;
        const item2QuestionCount = questionCount[item2.fieldName].count;
        if (item1QuestionCount === item2QuestionCount) {
          const numberOfAnswersForItem1 = Object.keys(questionCount[item1.fieldName].answers).length;
          const numberOfAnswersForItem2 = Object.keys(questionCount[item2.fieldName].answers).length;
          if (numberOfAnswersForItem1 === 1 && numberOfAnswersForItem2 === 1) {
            const item1AnswerCount = questionCount[item1.fieldName].answers[item1.answers[0]];
            const item2AnswerCount = questionCount[item2.fieldName].answers[item2.answers[0]];
            if (item1AnswerCount === item2AnswerCount) return 0;
            if (item1AnswerCount > item2AnswerCount) return -1;
            return 1;
          }
          if (numberOfAnswersForItem1 === 1) {
            return -1;
          }
          if (numberOfAnswersForItem2 === 1) {
            return 1;
          }
          return item1.fieldName.localeCompare(item2.fieldName);
        }
        if (item1QuestionCount > item2QuestionCount) return -1;
        return 1;
      }));
      this.sortTreeInRange(groups[key], 0, groups[key].length - 1, 0);
    });
    return groupKeys;
  }

  private getAnswer(tree: any[][], row: number, col: number): string[] {
    if (!tree[row][col]) return [];
    return tree[row][col].answers;
  }

  private isArraySame(array1: any[], array2: any[]): boolean {
    if (array1.length !== array2.length) return false;
    return array1.every((item: string) => array2.includes(item));
  }

  private isAnswerSame(tree: any[][], row: number, col: number, answers: string[]): boolean {
    return this.isArraySame(this.getAnswer(tree, row, col), answers);
  }
  private getQuestion(tree: any[][], row: number, col: number): string {
    if (!tree[row][col]) return tree[row][col];
    return tree[row][col].fieldName;
  }

  private sortTreeInRangeForQuestion(tree: any[][], row1: number, row2: number, col: number): boolean {
    const questions: string[] = [];
    for (let row = row1; row <= row2; row += 1) {
      const question = this.getQuestion(tree, row, col);
      if (!questions.includes(question)) {
        questions.push(question);
      }
    }
    if (questions.length === 1) return false;
    questions.sort();
    let currentQuestion = questions[0];
    const pointsToSplit = [];
    for (let sortedTillIndex = row1; sortedTillIndex <= row2; sortedTillIndex += 1) {
      if (this.getQuestion(tree, sortedTillIndex, col) !== currentQuestion) {
        for (let checkSimilarAnswerIndex = sortedTillIndex; checkSimilarAnswerIndex <= row2; checkSimilarAnswerIndex += 1) {
          if (this.getQuestion(tree, checkSimilarAnswerIndex, col) === currentQuestion) {
            const rowToMove = tree.splice(checkSimilarAnswerIndex, 1);
            tree.splice(sortedTillIndex, 0, ...rowToMove);
            sortedTillIndex += 1;
          }
        }
        pointsToSplit.push(sortedTillIndex - 1);
        currentQuestion = this.getQuestion(tree, sortedTillIndex, col);
      }
    }
    const isNextColPresent = new Array(row2 - row1 + 1).fill(0)
      .some((zero: number, i: number) => tree[row1 + i][col + 1]);
    if (isNextColPresent) {
      pointsToSplit.push(row2);
      let subGroupPointToStart = row1;
      pointsToSplit.forEach((subGroupPointToEnd: number) => {
        this.sortTreeInRange(tree, subGroupPointToStart, subGroupPointToEnd, col);
        subGroupPointToStart = subGroupPointToEnd + 1;
      });
    }
    return true;
  }

  private sortTreeInRangeForAnswer(tree: any[][], row1: number, row2: number, col: number): void {
    const answers: string[] = [];
    for (let row = row1; row <= row2; row += 1) {
      const answer = this.getAnswer(tree, row, col);
      answer.forEach((item: string) => {
        if (!answers.includes(item)) {
          answers.push(item);
        }
      });
    }
    answers.sort();
    let currentAnswer = answers[0];
    const pointsToSplit = [];
    for (let sortedTillIndex = row1; sortedTillIndex <= row2; sortedTillIndex += 1) {
      if (!this.isAnswerSame(tree, sortedTillIndex, col, [currentAnswer])) {
        for (let checkSimilarAnswerIndex = sortedTillIndex; checkSimilarAnswerIndex <= row2; checkSimilarAnswerIndex += 1) {
          if (this.isAnswerSame(tree, checkSimilarAnswerIndex, col, [currentAnswer])) {
            const rowToMove = tree.splice(checkSimilarAnswerIndex, 1);
            tree.splice(sortedTillIndex, 0, ...rowToMove);
            sortedTillIndex += 1;
          }
        }
        pointsToSplit.push(sortedTillIndex - 1);
        currentAnswer = this.getAnswer(tree, sortedTillIndex, col)[0];
      }
    }
    const isNextColPresent = new Array(row2 - row1 + 1).fill(0)
      .some((zero: number, i: number) => tree[row1 + i][col + 1]);
    if (!isNextColPresent) return;
    pointsToSplit.push(row2);
    let subGroupPointToStart = row1;
    pointsToSplit.forEach((subGroupPointToEnd: number) => {
      this.sortTreeInRange(tree, subGroupPointToStart, subGroupPointToEnd, col + 1);
      subGroupPointToStart = subGroupPointToEnd + 1;
    });
  }

  private sortTreeInRange(tree: any[][], row1: number, row2: number, col: number): void {
    const questionRearranged = this.sortTreeInRangeForQuestion(tree, row1, row2, col);
    if (questionRearranged) return;
    this.sortTreeInRangeForAnswer(tree, row1, row2, col);
  }

  private updateDisplayFieldForTrees(groupKeys: string[], groups_: any[][]): void {
    const groups = groups_;
    groupKeys.forEach((key: string) => {
      this.updateDisplayFieldForTree(groups[key], 0, groups[key].length - 1, 0);
    });
  }

  private isSameQuestion(tree: any[][], x1: number, x2: number, col: number): boolean {
    if (!tree[x1][col] && !tree[x2][col]) return true;
    if (!tree[x1][col] || !tree[x2][col]) return false;
    return tree[x1][col].fieldName === tree[x2][col].fieldName;
  }

  private updateDisplayFieldForTree(tree_: any[][], row1: number, row2: number, col: number): void {
    const tree = tree_;
    let currentRow = row1;
    const pointsToSplit = [];
    for (let sortedTillIndex = row1; sortedTillIndex <= row2; sortedTillIndex += 1) {
      const currentAnswer: string[] = this.getAnswer(tree, currentRow, col);
      if (!this.isSameQuestion(tree, currentRow, sortedTillIndex, col)
        || !this.isAnswerSame(tree, sortedTillIndex, col, currentAnswer)) {
        pointsToSplit.push(sortedTillIndex - 1);
        currentRow = sortedTillIndex;
      }
    }
    pointsToSplit.push(row2);
    const isNextColPresent = new Array(row2 - row1 + 1).fill(0)
      .some((zero: number, i: number) => tree[row1 + i][col + 1]);
    if (!isNextColPresent) {
      let subGroupPointToStart = row1;
      pointsToSplit.forEach((subGroupPointToEnd: number) => {
        this.selectOneToShowAndHideOther(tree, subGroupPointToStart, subGroupPointToEnd, col);
        subGroupPointToStart = subGroupPointToEnd + 1;
      });
      return;
    }
    let subGroupPointToStart = row1;
    pointsToSplit.forEach((subGroupPointToEnd: number) => {
      this.selectOneToShowAndHideOther(tree, subGroupPointToStart, subGroupPointToEnd, col);
      this.updateDisplayFieldForTree(tree, subGroupPointToStart, subGroupPointToEnd, col + 1);
      subGroupPointToStart = subGroupPointToEnd + 1;
    });
  }

  private selectOneToShowAndHideOther(tree_: any[][], row1: number, row2: number, col: number): void {
    const tree = tree_;
    if (!tree[row1][col]) {
      return;
    }
    new Array(row2 - row1 + 1).fill(0)
      .forEach((zero: number, index: number) => {
        tree[row1 + index][col].hideInUI = true;
      });
    // tree[Math.floor((subGroupPointToStart + subGroupPointToEnd) / 2)][col].hideInUI = false;
    tree[row1][col].hideInUI = false;
  }

  private addTreeInformationNode(groups: any[][], groupKeys: string[]): void {
    groupKeys.forEach((groupKey: string) => {
      const tree = groups[groupKey];
      tree.forEach((route: any[]) => {
        const treeNames = [];
        route.forEach((node_: any) => {
          const node = node_;
          if (!node.answers.length) {
            node.answers = [undefined];
          }
          if (treeNames.includes(node.treeName)) return;
          treeNames.push(node.treeName);
        });
        route.push({ fieldName: treeNames.join('<>'), treeName: treeNames.join('<>'), answers: [undefined] });
      });
    });
  }

  private mergeNodesOfTrees(groups: any, groupKeys: string[]): void {
    groupKeys.forEach((group: string) => {
      const tree = groups[group];
      let isRowMerged = !!tree.length;
      let maxTry = 5000;
      while (isRowMerged && maxTry) {
        [isRowMerged] = this.mergeNodesOfTree(tree, 0, tree.length - 1, 0);
        maxTry -= 1;
        if (!maxTry) {
          alert('Max Try to merge exceed');
        }
      }
    });
  }

  private mergeNodesOfTree(tree: any, row1: number, row2: number, col: number): [boolean, string[][]] {
    if (row1 === row2) {
      const answers = tree[row1][col].answers.map((answer: string) => `${tree[row1][col].fieldName}|${answer}`);
      return [false, answers];
    }
    let currentQuestion = this.getQuestion(tree, row1, col);
    let pointsToSplit = [];
    for (let sortedTillIndex = row1; sortedTillIndex <= row2; sortedTillIndex += 1) {
      if (this.getQuestion(tree, sortedTillIndex, col) !== currentQuestion) {
        for (let checkSimilarAnswerIndex = sortedTillIndex; checkSimilarAnswerIndex <= row2; checkSimilarAnswerIndex += 1) {
          if (this.getQuestion(tree, checkSimilarAnswerIndex, col) === currentQuestion) {
            const rowToMove = tree.splice(checkSimilarAnswerIndex, 1);
            tree.splice(sortedTillIndex, 0, ...rowToMove);
            sortedTillIndex += 1;
          }
        }
        pointsToSplit.push(sortedTillIndex - 1);
        currentQuestion = this.getQuestion(tree, sortedTillIndex, col);
      }
    }
    if (pointsToSplit.length) {
      pointsToSplit.push(row2);
      let currentRow = row1;
      const subRows = [];
      for (let i = 0; i < pointsToSplit.length; i += 1) {
        const [isNodeMerged, subTreeRows]: any = this.mergeNodesOfTree(tree, currentRow, pointsToSplit[i], col);
        if (isNodeMerged) return [true, []];
        currentRow = pointsToSplit[i] + 1;
        subRows.push(...subTreeRows);
      }
      return [false, subRows];
    }
    pointsToSplit = [];
    let currentAnswer = this.getAnswer(tree, row1, col);
    for (let sortedTillIndex = row1; sortedTillIndex <= row2; sortedTillIndex += 1) {
      if (!this.isAnswerSame(tree, sortedTillIndex, col, currentAnswer)) {
        pointsToSplit.push(sortedTillIndex - 1);
        currentAnswer = this.getAnswer(tree, sortedTillIndex, col);
      }
    }
    if (!pointsToSplit.length) {
      const isNextColPresent = new Array(row2 - row1 + 1).fill(0)
        .some((zero: number, i: number) => tree[row1 + i][col + 1]);
      if (!isNextColPresent) {
        tree.splice(row1, row2 - row1);
        return [true, []];
      }
    }
    pointsToSplit.push(row2);
    let currentRow = row1;
    const groups = [];
    const subRows = [];
    for (let i = 0; i < pointsToSplit.length; i += 1) {
      const question = this.getQuestion(tree, currentRow, col);
      const answer = this.getAnswer(tree, currentRow, col);
      const prefix = `${question}|${answer}`;
      const [isNodeMerged, subTreeRows]: any = this.mergeNodesOfTree(tree, currentRow, pointsToSplit[i], col + 1);
      if (isNodeMerged) return [true, []];
      currentRow = pointsToSplit[i] + 1;
      groups.push(subTreeRows);
      subRows.push(...subTreeRows.map((item: string) => `${prefix}^${item}`));
    }
    const group = groups.shift();
    if (groups.length) {
      const isExactMatch = groups.every((groupItem: any[]) => (group.length === groupItem.length
        && groupItem.every((item: string) => group.includes(item))));
      if (isExactMatch) {
        const rowsToRemoveStart = pointsToSplit[0] + 1;
        const numberOfRowsToDelete = pointsToSplit[pointsToSplit.length - 1] - rowsToRemoveStart + 1;
        pointsToSplit.forEach((rowIndex: number, index: number) => {
          if (!index) return;
          new Array(pointsToSplit[0] - row1 + 1).fill(0)
            .forEach((zero: number, relativeIndex: number) => {
              tree[rowIndex][col].answers.forEach((answer: string) => {
                if (tree[row1 + relativeIndex][col].answers.includes(answer)) return;
                tree[row1 + relativeIndex][col].answers.push(answer);
              });
              tree[row1 + relativeIndex][col].answers.sort();
            });
        });
        tree.splice(rowsToRemoveStart, numberOfRowsToDelete);
        return [true, []];
      }
    }
    return [false, subRows];
  }

  private async updateQuestionAnswers(groups: any): Promise<any> {
    const questionIds = [];
    Object.keys(groups).forEach((group: string) => {
      const tree: any[][] = groups[group];
      tree.forEach((row: any[]) => row.forEach((cell: any) => {
        if (questionIds.includes(cell.fieldName)) return;
        questionIds.push(cell.fieldName);
      }));
    });
    const questions: any[] = await this.conn.getQuestions({ where: { uniqueIdentifier: questionIds } });
    const questionTooltip = {};
    questions.forEach((question: any) => {
      const inputs = question.get('inputs');
      if (!inputs.length) {
        questionTooltip[question.get('uniqueIdentifier')] = '_default';
        return;
      }
      questionTooltip[question.get('uniqueIdentifier')] = inputs
        .map((input: any) => (input.value || input.text || '_default'))
        .join('\n');
    });
    this.questionTooltip = questionTooltip;
  }

  private updateDisplayList(): void {
    const groups = JSON.parse(JSON.stringify(this.groups));
    if (this.ui.filters.products.list.length) {
      const groupsToDelete = Object.keys(groups).filter((group: string) => {
        if (!this.regimenMap[group]) return true;
        return this.ui.filters.products.list.some((product: string) => !this.regimenMap[group].includes(product));
      });
      groupsToDelete.forEach((key: string) => delete groups[key]);
    }
    if (this.ui.filters.question.list.length) {
      Object.keys(groups).forEach((group: string) => {
        const tree = groups[group];
        groups[group] = tree.filter((route: any[]) => {
          const routesKeys = route.map((node: any) => node.fieldName);
          return this.ui.filters.question.list.every((item: string) => routesKeys.includes(item));
        });
      });
    }
    if (this.ui.filters.questionWithAnswer.list.length) {
      Object.keys(groups).forEach((group: string) => {
        const tree = groups[group];
        groups[group] = tree.filter((route: any[]) => {
          const routesKeys = route.map((node: any) => `${node.fieldName}|${node.answers.join('<>')}`);
          return this.ui.filters.questionWithAnswer.list.every((item: string) => routesKeys.includes(item));
        });
      });
    }
    const groupKeys = Object.keys(groups).filter((key: string) => groups[key].length);
    groupKeys.forEach((group: string) => {
      groups[group].forEach((route: any[]) => route.forEach((node_: any) => {
        const node = node_;
        node.fieldNameAnswers = `${node.fieldName}|${node.answers.join('<>')}`;
      }));
    });
    this.updateDisplayFieldForTrees(groupKeys, groups);
    this.displayGroupKeys = this.groupKeys
      .filter((item: string) => groupKeys.includes(item));
    this.displayGroups = groups;
  }

  updateTreeFilter(filter: { status: boolean, list: string[] }): void {
    Object.keys(this.ui.filters)
      .map((key: string) => this.ui.filters[key])
      .filter((item: any) => (item !== filter))
      .forEach((item_: any) => {
        const item = item_;
        item.status = false;
      });
  }

  updateFilterSelection(route: any): void {
    if (this.ui.filters.question.status) {
      this.updateFilterSelectionOnFilter(`${route.fieldName}`, this.ui.filters.question);
      return;
    }
    if (this.ui.filters.questionWithAnswer.status) {
      this.updateFilterSelectionOnFilter(`${route.fieldName}|${route.answers.join('<>')}`, this.ui.filters.questionWithAnswer);
    }
  }

  updateProductFilterSelection(item: string): void {
    if (this.ui.filters.products.status) {
      this.updateFilterSelectionOnFilter(item, this.ui.filters.products);
    }
  }

  private updateFilterSelectionOnFilter(item: string, filter: { status: boolean, list: string[] }): void {
    this.ui.loading = true;
    setTimeout(() => {
      const indexOfFilterName = filter.list.indexOf(item);
      if (indexOfFilterName === -1) {
        filter.list.push(item);
      } else {
        filter.list.splice(indexOfFilterName, 1);
      }
      this.updateDisplayList();
      this.ui.loading = false;
    }, 0);
  }
}
