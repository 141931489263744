import { Component, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { debounceTime, filter, mergeMap, Observable, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { HelperService } from '../../../../services/helper-service';
import { ConnectionService } from '../../../../services/connection-service';
import { QuestionType } from '../../../../../typings';
import { ArticleImageUploadModal } from '../../../../components/navbar/articleImageUploadModal';

@Component({ selector: 'question-edit', templateUrl: './question-edit.html', styleUrls: ['./question-edit.scss'] })
export class QuestionEditComponent implements OnDestroy {
  type: string = ApiClientConstant.Question.Type.SEND_TO_USER;
  taskTree: boolean;
  table: string;
  isReminder: boolean;
  isScheduled: boolean;
  types: Array<{ display: string, value: string }>;
  treeTables: Array<{ display: string, value: string }>;
  treeTasks: Array<{ display: string, value: string }>;
  storeInTable: Array<{ display: string, value: string }>;
  questionObj: any;
  mode: any;
  delayByInHours: number = 0;
  remindByInHours: number = 0;
  questionType: number = this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR;
  regimens: Array<any>;
  actions: Array<{ key: string, value: string, hideFor?: string }>;
  inputTypes: Array<{ key: string, value: string, hideFor?: string }>;
  subscriptions: Array<Subscription>;
  showOptions: boolean = false;
  titleTags: string[] = ['question'];
  runModes: Array<{ type: string, autoValue: boolean, storeInTable?: string, table?: string, answer?: string }> = [
    { type: 'USER', autoValue: false },
    { type: 'DOCTOR', autoValue: true },
  ];
  addedQuestion: any;
  public autoCompleteQuestionController: UntypedFormControl = new UntypedFormControl();
  questionOptions: Observable<Array<{ name: string; object: any }>>;

  constructor(public appConfig: AppConfig,
    private router: Router,
    private route: ActivatedRoute,
    private helper: HelperService,
    private conn: ConnectionService,
    private dialog: MatDialog,
    private zone: NgZone,
    private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.regimens = [];
    this.subscriptions = [];
    this.questionObj = this.getDefaultQuestion();
    this.updateQuestionMode(this.questionObj.get('mode'));
    this.treeTables = Object.keys(ApiClientConstant.TreeTables).map((key: string) => ({
      display: key.replace(/_/g, ' '),
      value: ApiClientConstant.TreeTables[key],
    }));
    this.treeTasks = Object.keys(ApiClientConstant.Question.Task).map((key: string) => ({
      display: key.replace(/_/g, ' '),
      value: ApiClientConstant.Question.Task[key],
    }));
    this.types = Object.keys(ApiClientConstant.Question.Type).map((key: string) => ({
      display: ApiClientConstant.Question.Type[key].replace(/_/g, ' '),
      value: ApiClientConstant.Question.Type[key],
    }));
    this.storeInTable = [{
      display: 'Common Chat',
      value: ApiClientConstant.Question.StoreInTable.ASSISTANT,
    }, {
      display: 'User Blog',
      value: ApiClientConstant.Question.StoreInTable.USER_BLOG,
    }, {
      display: 'FollowUp Chat',
      value: ApiClientConstant.Question.StoreInTable.FOLLOW_UP_CHAT,
    }, {
      display: 'Consultation Chat',
      value: ApiClientConstant.Question.StoreInTable.CONSULTATION_CHAT,
    }, {
      display: 'Support Chat',
      value: ApiClientConstant.Question.StoreInTable.SUPPORT_CHAT,
    }, {
      display: 'No Storage',
      value: ApiClientConstant.Question.StoreInTable.NO_STORAGE,
    }];
    this.inputTypes = [
      {
        key: ApiClientConstant.Question.InputType.BUTTON,
        value: 'Button',
      },
      {
        key: ApiClientConstant.Question.InputType.TEXT,
        value: 'Text',
      },
      {
        key: ApiClientConstant.Question.InputType.IMAGE,
        value: 'Image',
      },
    ];
    this.actions = [
      {
        key: ApiClientConstant.Question.InputAction.TREE,
        value: 'Bot Reply',
        hideFor: ApiClientConstant.Question.Mode.STICKY_ACTION,
      },
      { key: ApiClientConstant.Question.InputAction.ACTION, value: 'With in app' },
    ];
    this.subscribeToUrlQueryParams();
    this.questionOptions = this.autoCompleteQuestionController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getQuestions(token)),
      );
  }

  getDefaultQuestion(question: any = new Table.Question()): any {
    const defaultQuestionValue = {
      type: ApiClientConstant.Question.Type.SEND_TO_USER,
      title: '',
      table: '',
      isScheduled: false,
      isReminder: false,
      noChatMessage: false,
      autoResponse: false,
      isPriorityMessage: false,
      isDietArticle: false,
      uniqueIdentifier: '',
      templateParams: [],
      task: ApiClientConstant.Question.Task.NONE,
      params: { keyValue: [], timeFrame: [] },
      conditions: [],
      mode: ApiClientConstant.Question.Mode.GENERIC_USER_INPUT,
      inputs: [{
        type: ApiClientConstant.Question.InputType.BUTTON,
        action: ApiClientConstant.Question.InputAction.TREE,
        params: {},
        mapping: [],
      }],
    };
    Object.keys(defaultQuestionValue).forEach((key: string) => {
      if (question.has(key)) return;
      question.set(key, defaultQuestionValue[key]);
    });
    return question;
  }

  updateQuestionMode(mode: any): void {
    this.questionObj.set('mode', mode);
    this.mode = mode;
    if (this.mode === ApiClientConstant.Question.Mode.PAYMENT) {
      this.fetchRegimens();
    }
    this.updateShowOption();
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.question) {
        this.questionObj = this.route.parent.snapshot.data.question;
        const inputs = this.questionObj.get('inputs');
        inputs.forEach((input: any) => { const inputTemp = input; inputTemp.editDisabled = true; });
        this.questionObj.set('inputs', inputs);
        if (this.questionObj.get('runModes')) {
          const runModes = this.questionObj.get('runModes');
          this.runModes = runModes;
        }
        if (this.questionObj.get('addedQuestion')) {
          this.addedQuestion = this.questionObj.get('addedQuestion');
        }
        if (this.questionObj.get('storeInTable')
          && this.questionObj.get('table')
          && this.runModes[0].autoValue === false
          && !this.runModes[0].storeInTable
          && !this.runModes[0].table) {
          this.runModes[0].storeInTable = this.questionObj?.get('storeInTable');
          this.runModes[0].table = this.questionObj?.get('table');
        }
        if (this.runModes[1].autoValue === true) {
          this.runModes[1].answer = this.questionObj?.get('inputs')[0].value;
        }
        this.splitInputTempParamsWithParams();
        this.getDefaultQuestion(this.questionObj);
        this.updateQuestionMode(this.questionObj.get('mode'));
        this.type = this.questionObj.get('type');
        // if (!this.questionObj.has('storeInTable')) {
        //   this.questionObj.set('storeInTable', ApiClientConstant.Question.StoreInTable.ASSISTANT);
        // }
        if (this.questionObj.get('params').delayBy) {
          this.delayByInHours = this.questionObj.get('params').delayBy / 60 / 60 / 1000;
        }
        if (this.questionObj.get('params').remindBy) {
          this.remindByInHours = this.questionObj.get('params').remindBy / 60 / 60 / 1000;
        }
        if (!this.questionObj.get('params').keyValue) {
          this.questionObj.get('params').keyValue = [];
        }

        switch (this.questionObj.get('inputs').length) {
          case 0:
            this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.MESSAGE;
            break;
          default:
            this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR;
        }
      }
    }));
  }

  addOption(): void {
    this.questionObj.get('inputs').push({
      type: ApiClientConstant.Question.InputType.BUTTON,
      action: ApiClientConstant.Question.InputAction.TREE,
      params: {},
      mapping: [],
      editDisabled: false,
    });
  }

  removeOption(index: number): void {
    this.questionObj.get('inputs').splice(index, 1);
  }

  splitInputTempParamsWithParams(): void {
    const inputs = this.questionObj.get('inputs') || [];
    inputs.forEach((i: QuestionType.Button) => {
      const input: QuestionType.Button = i;
      if (!input.params) {
        input.params = {};
      }
      input.keyValue = Object.keys(input.params)
        .filter((x: string) => x !== 'actionName')
        .map((key: string) => ({ key, value: input.params[key] }));
      input.params = { actionName: input.params.actionName };
    });
    this.questionObj.set('inputs', inputs);
  }

  mergeInputTempParamsWithParams(): void {
    const inputs = this.questionObj.get('inputs');
    inputs.forEach((i: QuestionType.Button) => {
      const input: QuestionType.Button = i;
      const tempParams: { [key: string]: string } = {};
      (input.keyValue || []).forEach(({ key, value }: any) => (tempParams[key] = value));
      input.params = Object.assign(tempParams, input.params || {});
      delete input.keyValue;
    });
    this.questionObj.set('inputs', inputs);
  }

  async saveQuestion(): Promise<any> {
    this.mergeInputTempParamsWithParams();
    if (this.questionObj.get('type') === ApiClientConstant.Question.Type.EVALUATE) {
      this.questionObj.set('mode', ApiClientConstant.Question.Mode.GENERIC_USER_INPUT);
      this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR;
    } else {
      this.questionObj.unset('evaluateFunction');
    }

    switch (this.questionObj.get('mode')) {
      case ApiClientConstant.Question.Mode.STICKY_ACTION:
        this.questionObj.set('params', { keyValue: [] });
        this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR;
        this.questionObj.get('inputs').forEach((i: QuestionType.Button) => {
          const input = i;
          input.type = ApiClientConstant.Question.InputType.BUTTON;
        });
        break;
      case ApiClientConstant.Question.Mode.ARTICLE:
      case ApiClientConstant.Question.Mode.RICH_TEXT:
      case ApiClientConstant.Question.Mode.REQUEST_IMAGE:
      case ApiClientConstant.Question.Mode.PAYMENT:
        this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.MESSAGE;
        break;
      default:
    }
    switch (this.questionType) {
      case this.appConfig.ChatConstants.QUESTION_TYPES.MESSAGE: {
        this.questionObj.set('inputs', []);
        break;
      }
      default:
    }
    this.runModes = this.runModes.map((mode: any) => {
      if (mode.autoValue === true) {
        return { ...mode, table: '', storeInTable: '' };
      }
      return { ...mode, answer: '' };
    });
    this.questionObj.set('runModes', this.runModes);
    if ((this.runModes[0].autoValue && this.runModes[1].autoValue) || (this.runModes[0].autoValue)) {
      this.questionObj.set('storeInTable', ApiClientConstant.Question.StoreInTable.NO_STORAGE);
      this.questionObj.set('table', ApiClientConstant.TreeTables.CONSULTATION_SESSION);
    } else {
      this.questionObj.set('storeInTable', this.runModes[0].storeInTable);
      this.questionObj.set('table', this.runModes[0].table);
    }
    if (this.addedQuestion && this.questionObj.get('type') === ApiClientConstant.Question.Type.INFERRED_QUESTION) {
      this.questionObj.set('addedQuestion', this.addedQuestion);
    }
    try {
      await this.questionObj.save();
      await this.router.navigate([`/trees/question/${this.questionObj.id}`]);
    } catch (err) {
      alert(err.message.message || err.message);
    }
  }

  setQuestionType(index: number): void {
    this.questionType = index;
    this.updateShowOption();
  }

  updateShowOption(): void {
    if (this.questionObj.get('type') === ApiClientConstant.Question.Type.EVALUATE) {
      this.showOptions = true;
      return;
    }
    if (this.questionType === this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR
      && [ApiClientConstant.Question.Mode.GENERIC_USER_INPUT, ApiClientConstant.Question.Mode.STICKY_ACTION]
        .includes(this.questionObj.get('mode'))) {
      this.showOptions = true;
      return;
    }
    this.showOptions = false;
  }

  addTemplateParam(): void {
    const templateParams = this.questionObj.get('templateParams');
    templateParams.push({ key: '', database: false });
    this.questionObj.set('templateParams', templateParams);
  }

  removeTemplateParam(index: null): void {
    const templateParams = this.questionObj.get('templateParams');
    templateParams.splice(index, 1);
    this.questionObj.set('templateParams', templateParams);
  }

  removeValueToRowValues(row: any, index: number): void {
    row.values.splice(index, 1);
  }

  editValueAtRowValues(r: any, index: number): void {
    const row: { value: string, values: Array<string> } = r;
    const value = row.values.splice(index, 1);
    row.value = value[0];
  }

  addToRowValues(event: any, r: any): void {
    if (event.charCode !== 13) return;
    event.preventDefault();
    const row: { value: string, values: Array<string> } = r;
    if (!row.value) return;
    if (!row.values) row.values = [];
    row.values.push(row.value);
    delete row.value;
  }

  removeToRowLanguagesStringValues(row: any, index: number): void {
    row.valuesLanguageString.splice(index, 1);
  }

  addToRowLanguageString(event: any, r: any): void {
    const row: any = r;
    if (!row.valuesLanguageString) row.valuesLanguageString = [];
    row.valuesLanguageString.push(event);
  }

  addEvaluateKeyValue(): void {
    const params = this.questionObj.get('params');
    const tempKeyValue = params.keyValue || [];
    params.keyValue = [];
    setTimeout(() => {
      tempKeyValue.push({});
      params.keyValue = tempKeyValue;
      this.questionObj.set('params', params);
    }, 0);
  }

  removeEvaluateKeyValue(index: number): void {
    this.questionObj.get('params').keyValue.splice(index, 1);
  }

  addInputTempParam(i: any): void {
    const input = i;
    if (!input.keyValue) input.keyValue = [];
    const temp = input.keyValue;
    input.keyValue = [];
    setTimeout(() => {
      temp.push({});
      input.keyValue = temp;
    }, 0);
  }

  removeInputTempParam(input: any, index: any): void {
    input.keyValue.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.appConfig;
    delete this.router;
    delete this.route;
    delete this.helper;
    delete this.subscriptions;
    delete this.questionObj;
  }

  addTimeFrame(): void {
    if (!this.questionObj.get('params').timeFrame) {
      this.questionObj.get('params').timeFrame = [];
    }
    this.questionObj.get('params').timeFrame.push({});
  }

  updateTitleTags(whatsAppFlagEnabled: boolean): void {
    if (whatsAppFlagEnabled) {
      if (this.titleTags.includes('whats_app')) return;
      this.titleTags = ['whats_app', ...this.titleTags];
      return;
    }
    if (!this.titleTags.includes('whats_app')) return;
    this.titleTags = this.titleTags.filter((each: string) => each !== 'whats_app');
  }

  private async fetchRegimens(): Promise<void> {
    if (this.regimens.length) {
      return;
    }
    const regimens = await ApiConnector.find(Table.Regimen, {
      where: {
        active: true,
        type: { $in: ['ALTERNATE', 'MAIN'] },
      },
      limit: 2000,
      project: ['regimenId', 'title'],
    });
    this.zone.run(() => (this.regimens = regimens.map((regimen: any) => ({
      display: `${regimen.get('regimenId')} - ${regimen.get('title')}`,
      value: regimen.get('regimenId'),
    }))));
  }

  async openFileUplaodDialog(index: number): Promise<void> {
    const dialogRef = this.dialog.open(ArticleImageUploadModal);
    dialogRef.afterClosed().subscribe((data: any): any => {
      this.questionObj.get('inputs')[index].keyValue = [];
      this.questionObj.get('inputs')[index].keyValue.push({ key: 'imageUrl', value: data });
    });
  }

  onQuestionSelect(item: { name: string; object: any }): void {
    this.addedQuestion = JSON.parse(JSON.stringify(item.object));
    let inputs = this.questionObj.get('inputs');
    inputs = inputs.map((input: any) => ({
      ...input,
      mapping: [],
    }));
    if (inputs.length > 0) {
      inputs[0].mapping = [...this.addedQuestion.inputs];
    }
    this.questionObj.set('inputs', inputs);
  }

  async getQuestions(name: string): Promise<any> {
    return this.conn.getQuestions({
      q: name,
      limit: 10,
    })
      .then((questions: any) => questions
        .map((object: any) => ({
          name: `${object.get('uniqueIdentifier')}: ${object.get('title')}`,
          object,
        })));
  }

  drop(event: CdkDragDrop<any>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.cdRef.detectChanges();
  }
  moveDown(i: number, j: number): void {
    const optionToMove = this.questionObj?.get('inputs')[i]?.mapping[j];
    if (i + 1 < this.questionObj.get('inputs').length) {
      this.questionObj?.get('inputs')[i].mapping.splice(j, 1);
      this.questionObj?.get('inputs')[i + 1].mapping.push(optionToMove);
    }
  }

  moveUp(i: number, j: number): void {
    const optionToMove = this.questionObj?.get('inputs')[i]?.mapping[j];
    if (i - 1 >= 0) {
      this.questionObj.get('inputs')[i].mapping.splice(j, 1);
      this.questionObj.get('inputs')[i - 1].mapping.push(optionToMove);
    }
  }
  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
