import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { ViewComponent } from './view.component';
import { DirectivesModule } from '../../../../components/directives.module';

const ROUTES: Routes = [
  { path: '', component: ViewComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    AgGridAngular,
  ],
  declarations: [ViewComponent],
  exports: [ViewComponent],
})
export class ViewModule {}
