import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';
import { LocalStorage } from '../../services/local-storage-service';

@Component({
  selector: 'team-filter',
  templateUrl: './team-filter.html',
})

export class TeamFilterComponent {
  @Output() updatedTeamList: EventEmitter<any> = new EventEmitter();
  @Input() set reset(status: boolean) {
    this.resetting = status;
    if (status) this.resetSelection();
  }
  @Input('excludeTeams') excludeTeams: boolean;
  userRoles: Array<string> = [];
  rows: Array<{ name: any, selected?: boolean }> = [];
  currentUser: any;
  resetting: boolean = false;
  names: Array<any> = [];
  teams: any[];
  excludedTeamNames: Array<string> = [
    ApiClientConstant.Role.Name.FOLLOWUP,
    ApiClientConstant.Role.Name.DOCTOR,
    ApiClientConstant.Role.Name.REVIEW,
    ApiClientConstant.Role.Name.PAYMENT,
    ApiClientConstant.Role.Name.INVENTORY,
    ApiClientConstant.Role.Name.BetterHair,
    ApiClientConstant.Role.Name.WAITING_FOR_IMAGE,
    this.appConfig.Shared.Role.Name.ORDER_TRACKING,
  ];
  constructor(private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    private localStorage: LocalStorage) {
  }

  async ngOnInit(): Promise<any> {
    this.userRoles = this.localStorage.getJsonValue('userRoles') || [];
    this.appConfig.Shared.Team.Name.map((name: string) => this.names.push(name));
    if (this.excludeTeams) {
      this.names = this.names.filter((each: string) => !this.excludedTeamNames.includes(each));
    }
    this.rows = this.names.map((name: any) => ({ name, selected: this.userRoles.includes(name) }));
    this.sendUpdatedList();
  }

  sendUpdatedList(): void {
    if (this.resetting) {
      return;
    }
    this.updatedTeamList.emit(this.rows.filter(({ selected }: any) => selected).map(({ name }: any) => name));
  }

  toggleSelection(r: { name: any, selected: boolean }): any {
    const row = r;
    row.selected = !row.selected;
    this.sendUpdatedList();
  }

  resetSelection(): void {
    this.zone.run(() => (this.rows = this.names
      .map((name: any) => ({ name, selected: this.currentUser.get('team').includes(name) }))));
    this.resetting = false;
    this.sendUpdatedList();
  }
}
