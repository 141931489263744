import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketProductComponent } from './market-product.component';
import { EditModule } from './edit/index';
import { ListModule } from './list/index';
import { MarketProductResolve } from './market-product-resolver';

const ROUTES: Routes = [
  {
    path: 'marketProducts',
    component: MarketProductComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'marketProduct/new',
    component: MarketProductComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: 'marketProduct/:id/edit',
    component: MarketProductComponent,
    loadChildren: (): any => EditModule,
    resolve: { marketProduct: MarketProductResolve },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [MarketProductResolve],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [MarketProductComponent],
  exports: [MarketProductComponent] })
export class MarketProductsModule {}
