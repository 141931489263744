import * as moment from 'moment';
import { Component, Inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Broadcaster } from 'src/components/broadcaster';
import { ApiClientConstant, ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

declare interface OrderProducts {
  followUpNeeded: boolean;
  id: string;
  title: string;
  type: string;
  quantity: string;
  quantityUnit: string;
  complementary: boolean;
}
@Component({ selector: 'followup-modal', templateUrl: './followup-dialog.html' })
export class FollowUpDialog {
  public autoCompleteController: UntypedFormControl = new UntypedFormControl();
  options: Observable<Array<{ name: string; object: any }>>;
  moment: any;
  minDate: Date;
  maxDate: Date;
  messageInputs: Array<string> = [];
  popUpModel: {
    orderProducts?: Array<OrderProducts>,
    orderConfirmationView?: boolean,
    instructionCallNeeded?: boolean,
    orderConfirmationMessage?: string,
    followUpReason?: string,
    nextFollowUpDate?: string,
    followUpMessage?: string,
    waitingForImageMessage?: string,
    waitingForImageTypes?: Array<string>,
    reportMissingMessage?: string,
    missingReportType?: Array<string>
    doctorCallNeeded?: boolean;
  };
  waitingForImageTypes: Array<string> = [];
  dateFormat: string = 'YYYY-MM-DD';

  userId: any;
  SelectedPopUpType: any;
  followUp: any;
  chatUser: any;
  followUpHistory: any;
  public subscriptions: Subscription[] = [];
  partialFollowupReasons: Array<{value: string, display: string}> = [];
  selectedReasonForPartialFollowup: string;
  errorMessage: any;
  partialFollowUpContext: any;
  complementProductIds: Array<string> = [];
  disableOrderButton: boolean = false;
  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private dialog: MatDialog,
    private broadcasterService: Broadcaster,
    private dialogRef: MatDialogRef<FollowUpDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.popUpModel = {};
    this.moment = moment;
    this.waitingForImageTypes.push(ApiClientConstant.InstantCheckup.Type.FRONT_FACE);
    this.waitingForImageTypes.push(ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE);
    this.waitingForImageTypes.push(ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE);
    this.waitingForImageTypes.push(ApiClientConstant.InstantCheckup.Type.HAIR_TOP);
    this.waitingForImageTypes.push(ApiClientConstant.InstantCheckup.Type.HAIR_FRONT);
    this.waitingForImageTypes.push(ApiClientConstant.InstantCheckup.Type.BODY);
    this.chatUser = this.data.chatUser;
    this.userId = this.data.userId;
    this.followUp = this.data.followUp;
    this.SelectedPopUpType = this.data.SelectedPopUpType;
    this.complementProductIds = data.complementProductIds;
  }

  async ngOnInit(): Promise<void> {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.updateMaxDate();
    this.options = this.autoCompleteController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getProducts(token)));
    this.openModal();
    this.partialFollowupReasons = [
      { value: 'photo missing', display: 'photo missing' },
    ];
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    if (!name) {
      return [];
    }
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
      },
      limit: 10,
      project: ['title', 'quantity', 'quantityUnit', 'type'],
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: `${product.get('title')} [${product.get('quantity')}${
        product.get('quantityUnit')}] (${product.get('type')})`,
      object: product,
    }));
  }

  autoCompleteSelect(item: { name: string; object: any }): void {
    this.popUpModel.orderProducts.push({
      followUpNeeded: true,
      id: item.object.id,
      title: item.object.get('title'),
      type: item.object.get('type'),
      quantity: item.object.get('quantity'),
      quantityUnit: item.object.get('quantityUnit'),
      complementary: false,
    });
    this.autoCompleteController.setValue('');
  }

  async setNewFollowUpDateBasedOnInstructionCall(): Promise<void> {
    const regimens = await Promise.all(this.followUp.get('regimens').map((regimen: any) => {
      if (regimen.has('activeFromDate')) return Promise.resolve(regimen);
      const payload: RequestQueryPayload<Table.Regimen> = {
        where: {
          objectId: regimen.id,
        },
        project: ['followUpConfig', 'activeFromDate'],
      };
      return this.conn.findOneRegimen(payload);
    }));
    const nextFollowUpInDays = Math.min(...regimens.map((regimen: any): number => {
      const followUpConfig = regimen.get('followUpConfig') || { durationInDays: 90, intervalInDays: 28 };
      if (moment().diff(regimen.get('activeFromDate'), 'days') < followUpConfig.durationInDays) {
        return followUpConfig.intervalInDays;
      }
      return 28;
    }));
    this.popUpModel.nextFollowUpDate = moment().add(nextFollowUpInDays, 'days').format(this.dateFormat);
  }

  async updateNextFollowUpDate(): Promise<void> {
    this.setNewFollowUpDateBasedOnInstructionCall();
    if (!this.followUp.get('instructionCallNeeded')) {
      return;
    }
    const activeRegimes = this.followUp.get('regimens');
    const minRegimenActiveTime = moment.min(...activeRegimes.map((regimen: any) => regimen.get('activeFromDate')));
    const diffInWeeks = moment().diff(moment(minRegimenActiveTime), 'week');
    if (diffInWeeks > 13) {
      return;
    }
    this.popUpModel.nextFollowUpDate = moment().add(Math.max(Math.min(13 - diffInWeeks, 8), 5), 'week').format(this.dateFormat);
  }

  public openModal(): void {
    setTimeout(async () => {
      this.popUpModel = {};
      if (!this.followUp) {
        this.followUp = new Table.FollowUp();
      }
      switch (this.SelectedPopUpType) {
        case this.appConfig.FollowUp.PopupType.ADD_OR_EDIT_FOLLOWUP: {
          this.popUpModel.instructionCallNeeded = !!this.followUp.get('instructionCallNeeded');
          this.popUpModel.followUpReason = this.followUp.get('followUpReason') || 'Regular';
          this.popUpModel.nextFollowUpDate = moment(this.followUp.get('nextFollowUpDate')).format(this.dateFormat);
          this.onInstructionCallChanged();
          break;
        }
        case this.appConfig.FollowUp.PopupType.SKIP:
        case this.appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP: {
          this.popUpModel.instructionCallNeeded = false;
          this.popUpModel.followUpReason = 'Regular';

          this.updateNextFollowUpDate();
          this.onInstructionCallChanged();
          break;
        }
        case this.appConfig.FollowUp.PopupType.WAITING_FOR_IMAGE: {
          this.popUpModel.waitingForImageMessage = '';
          this.popUpModel.waitingForImageTypes = [];
          break;
        }
        case this.appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP: {
          this.conn.findFollowUps({
            where: { user: this.chatUser },
            descending: 'createdAt',
            include: ['updatedBy'],
            project: ['followUpReason', 'updatedBy.username' as 'updatedBy', 'State', 'nextFollowUpDate', 'markedReadyOn',
              'effectiveFollowUpDate', 'completedOn'],
          })
            .then((followUps: any) => {
              this.followUpHistory = followUps;
            });
          break;
        }
        default:
      }
    }, 0);
  }

  async onInstructionCallChanged(): Promise<void> {
    const languageStringTags = await this.conn.getLanguageTagByName(['followup_message']);
    if (this.followUp
      && this.followUp.get('followUpMessage')
      && this.SelectedPopUpType !== this.appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP) {
      this.popUpModel.followUpMessage = this.followUp.get('followUpMessage');
      return;
    }
    const [languageString]: any = await this.conn.getLanguageStringByTag(languageStringTags);
    this.popUpModel.followUpMessage = await this.conn.getUserPreferedLanguage(this.chatUser.get('languagePreference'), languageString);
    this.popUpModel.doctorCallNeeded = this.followUp.get('doctorCallNeeded');
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  async addFollowUp(): Promise<void> {
    ['followUpMessage', 'waitingForImageMessage'].forEach((key: string) => {
      if (!this.popUpModel[key]) return;
      this.popUpModel[key] = this.popUpModel[key]
        .replace(/{{name}}/g, this.chatUser.get('PatientName'));
      this.popUpModel[key] = this.popUpModel[key]
        .replace(/{{followUpDate}}/g, moment(this.popUpModel.nextFollowUpDate).format('Do MMM YYYY'));
    });
    switch (this.SelectedPopUpType) {
      case this.appConfig.FollowUp.PopupType.WAITING_FOR_IMAGE: {
        this.followUp.set('State', this.appConfig.FollowUp.State.WAITING_FOR_IMAGE);
        this.followUp.set('waitingForImageMessage', this.popUpModel.waitingForImageMessage);
        this.followUp.set('waitingForImageTypes', this.popUpModel.waitingForImageTypes);
        const followUpReports = await ApiConnector.count(Table.FollowUpReport, {
          where: { followUp: this.followUp, user: this.chatUser },
        });
        if (followUpReports) {
          alert('Reports available for this followup');
          return;
        }
        this.followUp.save()
          .then(() => this.hideModal())
          .catch((error: any) => this.saveFollowUpErrorHandling(error));
        break;
      }
      case this.appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP: {
        const nextFollowUpDate = moment(this.popUpModel.nextFollowUpDate, this.dateFormat).add(6, 'hours').toDate();
        this.followUp.set('Action', 'Done & Create New');
        this.followUp.set('State', 'FINISHED');
        this.followUp.set('newFollowUpDate', nextFollowUpDate);
        this.followUp.set('newFollowUpReason', this.popUpModel.followUpReason);
        this.followUp.set('newFollowUpMessage', this.popUpModel.followUpMessage);
        this.followUp.set('newInstructionCallNeeded', this.popUpModel.instructionCallNeeded);
        this.followUp.set('messageInputs', this.messageInputs);
        this.followUp.set('doctorCallNeeded', this.popUpModel.doctorCallNeeded);
        this.followUp.save()
          .then(() => this.reAssignTagAsFinished('_FOLLOW_UP'))
          .then(() => this.hideModal())
          .catch((error: any) => this.saveFollowUpErrorHandling(error));
        this.messageInputs = [];
        break;
      }
      case this.appConfig.FollowUp.PopupType.SKIP: {
        const nextFollowUpDate = moment(this.popUpModel.nextFollowUpDate, this.dateFormat).add(6, 'hours').toDate();
        this.followUp.set('Action', 'Skip');
        this.followUp.set('newFollowUpDate', nextFollowUpDate);
        this.followUp.set('newInstructionCallNeeded', this.popUpModel.instructionCallNeeded);
        this.followUp.set('newFollowUpReason', this.popUpModel.followUpReason);
        this.followUp.set('newFollowUpMessage', this.popUpModel.followUpMessage);
        this.followUp.set('State', AppConfig.FollowUp.State.SKIP);
        this.followUp.set('doctorCallNeeded', this.popUpModel.doctorCallNeeded);
        this.followUp.save()
          .then(() => {
            this.reAssignTagAsFinished('_FOLLOW_UP');
            this.hideModal();
          })
          .catch((error: any) => this.saveFollowUpErrorHandling(error));
        break;
      }
      default: {
        const isNewObject = !this.followUp.id;
        this.followUp.set('Action', 'Default');
        this.followUp.set('message', this.popUpModel.followUpMessage);
        this.followUp.set('followUpReason', this.popUpModel.followUpReason);
        this.followUp.set('instructionCallNeeded', this.popUpModel.instructionCallNeeded);
        this.followUp.set('doctorCallNeeded', this.popUpModel.doctorCallNeeded);
        const nextFollowUpDate = moment(this.popUpModel.nextFollowUpDate, this.dateFormat).add(6, 'hours').toDate();
        if (!moment(nextFollowUpDate).isSame(this.followUp.get('nextFollowUpDate'))) {
          this.followUp.set('nextFollowUpDate', nextFollowUpDate);
        }
        if (!this.followUp.has('State')) {
          this.followUp.set('State', ApiClientConstant.FollowUp.State.PENDING);
        }
        // remove userId in before save and add user pointer
        if (isNewObject) {
          this.followUp.set('user', this.userId);
        }
        this.followUp.save()
          .then(() => this.hideModal())
          .catch((error: any) => this.saveFollowUpErrorHandling(error));
      }
    }
  }

  reAssignTagAsFinished(tag: string): void {
    this.conn.findTags({
      where: {
        user: this.chatUser,
        tag: { $regex: tag, $options: 'i' },
        status: [this.appConfig.Shared.Tag.Status.ASSIGNED],
      },
      project: ['createdAt'],
    })
      .then((tags: Array<any>) => tags.map((eachTag: any) => eachTag
        .save({ status: this.appConfig.Shared.Tag.Status.FINISHED })));
  }

  updateComplementaryProducts(): void {
    this.popUpModel.orderProducts = this.popUpModel.orderProducts.map((product_: OrderProducts) => {
      const product = product_;
      if (this.complementProductIds?.includes(product.id)) {
        product.complementary = true;
      }
      return product;
    });
  }

  async saveFollowUpErrorHandling(error: any): Promise<void> {
    this.errorMessage = error;
    if (error.code === 422) {
      if (error.message.data && error.message.data.products && error.message.data.products.length) {
        if (error.message.data && error.message.data.products) {
          this.popUpModel.orderProducts = error.message.data.products;
          this.updateComplementaryProducts();
        }
        this.popUpModel.orderConfirmationMessage = error.message.message;
        this.popUpModel.orderConfirmationView = true;
        return;
      }
    }
    if (error.code === 412 && error.message.acknowledgementField === 'partialFollowUpReason') {
      this.SelectedPopUpType = 'partialFollowUpReason';
      this.popUpModel.reportMissingMessage = error.message.message;
      this.popUpModel.missingReportType = error.message.required;
      return;
    }
    alert(error.message || error.toString());
  }

  removeProduct(index:number): void {
    this.popUpModel.orderProducts.splice(index, 1);
  }

  hideModal(status: boolean = true): void {
    this.dialogRef.close(status);
  }

  saveFollowUpCreatingOrder(createOrder: boolean): void {
    this.disableOrderButton = true;
    const context = { orderCreatingErrorMessage: this.errorMessage, partialFollowUpReason: undefined };
    if (this.partialFollowUpContext) {
      context.partialFollowUpReason = this.partialFollowUpContext.partialFollowUpReason;
    }
    this.followUp.set('orderConfirmation', { products: this.popUpModel.orderProducts, confirm: createOrder });
    this.followUp.save({}, { context })
      .then(() => this.hideModal())
      .catch((error: any) => this.saveFollowUpErrorHandling(error))
      .finally(() => {
        this.disableOrderButton = false;
      });
  }

  private async updateMaxDate(): Promise<void> {
    this.maxDate = new Date(new Date().getTime() + 42 * 24 * 60 * 60 * 1000); // 6 weeks
    await Promise.all(this.followUp.get('regimens').map((regimen: any) => Promise.all(regimen.get('concernsLanguageString')
      .map((concernLanguageString: any) => {
        if (concernLanguageString.get('en')) {
          return Promise.resolve();
        }
        return concernLanguageString.fetch();
      }))));
    const concerns = this.followUp.get('regimens').reduce((result: Array<string>, regimen: any) => {
      result.push(...regimen.get('concernsLanguageString').map((each: any) => each.get('en')));
      return result;
    }, []);
    if (concerns.every((each: string) => !each.toLowerCase().includes('maintenance'))) {
      return;
    }
    this.maxDate = new Date(new Date().getTime() + 70 * 24 * 60 * 60 * 1000); // 10 weeks
  }

  async sendPartialFollowupResponse(): Promise<void> {
    const nextFollowUpDate = moment(this.popUpModel.nextFollowUpDate, this.dateFormat).add(6, 'hours').toDate();
    this.followUp.set('Action', 'Done & Create New');
    this.followUp.set('State', 'FINISHED');
    this.followUp.set('newFollowUpDate', nextFollowUpDate);
    this.followUp.set('newFollowUpReason', this.popUpModel.followUpReason);
    this.followUp.set('newFollowUpMessage', this.popUpModel.followUpMessage);
    this.followUp.set('newInstructionCallNeeded', this.popUpModel.instructionCallNeeded);
    this.followUp.set('messageInputs', this.messageInputs);
    const context: any = {};
    context.partialFollowUpReason = {};
    this.popUpModel.missingReportType.forEach((each: string) => {
      context.partialFollowUpReason[each] = this.selectedReasonForPartialFollowup;
    });
    try {
      await this.followUp.save({}, { context });
      this.hideModal(true);
    } catch (error) {
      this.partialFollowUpContext = context;
      await this.saveFollowUpErrorHandling(error);
    }
  }
}
