import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MargIdInfoEditComponent } from './marg-id-info-edit.component';
import { DirectivesModule } from '../../../../components/directives.module';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputSingleImageUploadModule } from '../../../../components/input/input-single-image-upload';
import { InputTextModule } from '../../../../components/input/input-text';

const ROUTES: Routes = [
  { path: '', component: MargIdInfoEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    AgGridAngular,
    MatAutocompleteModule,
    InputSelectModule,
    InputSingleImageUploadModule,
    ReactiveFormsModule,
    InputTextModule,
  ],
  declarations: [MargIdInfoEditComponent],
  exports: [MargIdInfoEditComponent],
})
export class MargIdInfoEditModule {}
