import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { Broadcaster } from '../../../../components/broadcaster';
import { AddReviewDialog } from './add-review-dialog/add-review-dialog.component';
import { InputType } from '../../../../../typings/client/input';
import { SignatureCropPopupComponent } from '../../../../components/signature-crop-popup/signature-crop-popup.component';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent implements OnDestroy {
  userObj: any;
  doctorVideos: any;
  videos: any = {};
  options: any[];
  selectedLanguage: string;
  reviews: any;
  languages: Array<any> = [];
  show: boolean = false;
  medicalCouncilOptions: Array<InputType.SelectOption> = [];
  personalInformation: { qualification?: string; medicalCouncilState?: string; medicalCouncilRegNumber?: string; } = {};
  signImage: string = '';
  imageSrc: string = '';
  showPersonalizedInfo: boolean = false;
  public subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private appConfig: AppConfig,
    private conn: ConnectionService,
    private route: ActivatedRoute,
    private broadcast: Broadcaster,
    private dialog: MatDialog,
  ) {
  }

  async ngOnInit(): Promise<any> {
    this.userObj = await this.conn.findUserByObjectId(this.route.snapshot.params.id);
    this.fetchReview();
    this.doctorVideos = this.userObj.get('doctorVideos') || {};
    if (this.userObj.get('forDoctor')) {
      this.showPersonalizedInfo = true;
      this.personalInformation = this.userObj.get('personalInfo') || {};
    }
    this.options = Object.values(ApiClientConstant.LanguageString).filter((each:any) => !['meetYourDoctor',
      'thumbnailImage',
      'usingYourRegimenHair',
      'usingYourRegimenSkin'].includes(each));
    this.options.unshift('General');
    this.videos = this.doctorVideos;
    this.selectedLanguage = '';
    this.medicalCouncilOptions = this.appConfig.Shared.MedicalCouncils.map((each: string) => ({ display: each, value: each }));
    this.languages = [
      { value: ApiClientConstant.LanguageString.EN,
        display: 'English',
        selected: this.userObj.get('knownLanguages').includes(ApiClientConstant.LanguageString.EN) },
      { value: ApiClientConstant.LanguageString.HI,
        display: 'Hindi',
        selected: this.userObj.get('knownLanguages').includes(ApiClientConstant.LanguageString.HI) },
      { value: ApiClientConstant.LanguageString.TE,
        display: 'Telugu',
        selected: this.userObj.get('knownLanguages').includes(ApiClientConstant.LanguageString.TE) },
      { value: ApiClientConstant.LanguageString.KN,
        display: 'Kannada',
        selected: this.userObj.get('knownLanguages').includes(ApiClientConstant.LanguageString.KN) },
      { value: ApiClientConstant.LanguageString.MA,
        display: 'Malayalam',
        selected: this.userObj.get('knownLanguages').includes(ApiClientConstant.LanguageString.MA) },
      { value: ApiClientConstant.LanguageString.MR,
        display: 'Marathi',
        selected: this.userObj.get('knownLanguages').includes(ApiClientConstant.LanguageString.MR) },
      { value: ApiClientConstant.LanguageString.TA,
        display: 'Tamil',
        selected: this.userObj.get('knownLanguages').includes(ApiClientConstant.LanguageString.TA) },
      { value: ApiClientConstant.LanguageString.BN,
        display: 'Bengali',
        selected: this.userObj.get('knownLanguages').includes(ApiClientConstant.LanguageString.BN) },
    ];
    this.signImage = this.userObj.get('doctorSignature');
  }

  changeDoctorTemplate(): void {
    if (this.selectedLanguage !== 'General') {
      this.videos = this.doctorVideos[this.selectedLanguage] || {};
    } else {
      this.videos = this.doctorVideos;
    }
  }

  async fetchReview(): Promise<any> {
    // this.reviews = await this.conn.fetchDoctorReviews(this.userObj);
  }

  async updateDoctor(): Promise<any> {
    const languagesKnown = this.languages.filter((each: any) => each.selected)
      .map((each: any) => each.value);
    if (!languagesKnown.length) {
      this.broadcast.broadcast('NOTIFY', { message: 'Select any language.', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    this.userObj.set('personalInfo', this.personalInformation);
    this.userObj.set('knownLanguages', languagesKnown);
    try {
      await this.userObj.save();
      this.router.navigate(['/users/doctors']);
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  openAddReviewDialog(): void {
    const dialogRef = this.dialog.open(AddReviewDialog, { data: { user: this.userObj }, width: '600px' });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {
      this.fetchReview();
    }));
  }

  openCropPopup(): void {
    const dialogRef = this.dialog.open(SignatureCropPopupComponent, { data: { username: this.userObj.get('username') } });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.userObj.set('doctorSignature', data);
        this.signImage = data;
      }
    }));
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    delete this.userObj;
    delete this.router;
    delete this.conn;
    delete this.route;
  }

  handleValueChange(value: any): void {
    if (value?.length) {
      this.showPersonalizedInfo = true;
    } else {
      this.showPersonalizedInfo = false;
    }
  }
}
