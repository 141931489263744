import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { Broadcaster } from 'src/components/broadcaster';
import { AppConfig } from 'src/app/app.config';
import { ConnectionService } from '../../../../services/connection-service';

@Component({ selector: 'order-note', templateUrl: './order-note.html' })
export class OrderNoteComponent {
  @Input('showAddNote') showAddNote: boolean = true;
  @Input('order')
  set setOrder(x: any) {
    this.updateOrder(x);
  }
  @Input('note')
  set setNote(note: string) {
    if (!note) {
      return;
    }
    this.note = note;
    this.addOrderNote();
  }
  @Output('noteUpdated') noteUpdatedEmitter: EventEmitter<string> = new EventEmitter<string>();

  order: any;
  note: string;
  noteMessages: Array<any> = [];
  noteCategories: Array<{[key: string]: string}> = [];
  category: 'order_initial_stages' | 'address_confirmation' | 'cancellation' | 'delivery_experience'
    | 'order_not_serviceable' | 'post_delivery' | 'teenager_calls' | 'WFI' | 'others';

  constructor(private conn: ConnectionService, private broadcaster: Broadcaster, public appConfig: AppConfig) {
    this.noteCategories = [
      { display: 'Order Initial Stages', value: 'order_initial_stages' },
      { display: 'Address Confirmation', value: 'address_confirmation' },
      { display: 'Cancellation', value: 'cancellation' },
      { display: 'Delivery Experience', value: 'delivery_experience' },
      { display: 'order not serviceable', value: 'order_not_serviceable' },
      { display: 'Post Delivery', value: 'post_delivery' },
      { display: 'Teenager Calls', value: 'teenager_calls' },
      { display: 'Waiting For Image', value: 'waiting_for_image' },
      { display: 'Others', value: 'others' },
    ];
  }

  onChangeCategory(): void {
    switch (this.category) {
      case 'order_initial_stages': {
        this.noteMessages = [
          { display: 'User will upload images', value: 'user_will_upload_images' },
          { display: 'User will pay online and confirm the order', value: 'online_payment' },
          { display: 'Order placed by operator', value: 'order_placed_by_operator' },
          { display: 'Alternative products given due to stock issues - User informed',
            value: 'alternative_products_given_due_to_stock_issues_user_informed' },
        ];
        break;
      }
      case 'address_confirmation': {
        this.noteMessages = [
          { display: 'Address Verified - On call', value: 'address_verified_on_call' },
          { display: 'Address Verified - On Google', value: 'address_verified_on_google' },
          { display: 'Address Verified - Order previously delivered on same address',
            value: 'address_verified_order_previously_delivered_on_same_address' },
          { display: 'Address Verified - Address/Pincode Updated',
            value: 'address_verified_address/pincode_updated' },
        ];
        break;
      }
      case 'cancellation': {
        this.noteMessages = [
          { display: 'Customer wants to cancel', value: 'customer_wants_to_cancel' },
          { display: 'Marked RTO as per user’s cancellation request', value: 'marked_rto_as_per_user\'s_cancellation_request' },
        ];
        break;
      }
      case 'delivery_experience': {
        this.noteMessages = [
          { display: 'RNR/Call Not Connected/Disconnected by User after picking',
            value: 'rnr/call_not_connected/disconnected_by_user_after_picking' },
          { display: 'Customer wants to cancel', value: 'customer_wants_to_cancel' },
          { display: 'Escalation - Order in packed stage, yet to be shipped', value: 'escalation_order_in_packed_stage_yet_to_shipped' },
          { display: 'escalation - retry delivery', value: 'escalation_retry_delivery' },
          { display: 'escalation - customer frustrated due to delivery issues', value: 'escalation_customer_frustrated' },
          { display: 'escalation - fake attempt', value: 'escalation_fake_attempt' },
          { display: 'Escalation - No recent update in status', value: 'escalation_no_recent_update_in_status' },
          { display: 'Tracking showing delivered but actually not delivered - Need POD',
            value: 'tracking_showing_delivered_but_actually_not_delivered_need_pod' },
        ];
        break;
      }
      case 'post_delivery': {
        this.noteMessages = [
          { display: 'User informed order delivered - Product image, invoice/outer packaging pic requested',
            value: 'user_informed_order_delivered_product_image_invoice_outer_packaging_pic_requested' },
          { display: 'Missing Product - Requested user for unboxing video, invoice, image of products',
            value: 'missing_product_requested_user_for_unboxing_video_invoice_image_of_products' },
          { display: 'User received damaged product - Product image, invoice and video requested',
            value: 'user_received_damaged_products_product_image_invoice_video_requested' },
          { display: 'Reverse pick-up required - Customer wants to return',
            value: 'reverse_pickup_required_customer_wants_to_return' },
          { display: 'Reverse pick-up required - Order not cancelled on time',
            value: 'reverse_pickup_required_order_not_cancelled_on_time' },
          { display: 'User wants to return - Self-ship', value: 'user_wants_to_return_self_ship' },
        ];
        break;
      }
      case 'others': {
        this.noteMessages = [
          { display: 'RNR/Call Not Connected/Disconnected by User after picking',
            value: 'rnr/call_not_connected/disconnected_by_user_after_picking' },
          { display: 'Refund requested', value: 'refund_requested' },
        ];
        break;
      }
      default: {
        delete this.note;
        this.noteMessages = [];
        break;
      }
    }
  }

  addOrderNote(): void {
    if (this.note.includes('escalation_')) {
      const labels = this.order.get('labels') || [];
      if (!labels.includes('escalation')) {
        labels.push(this.note);
        this.order.set('labels', labels);
      }
    }
    if (!this.order.get('user')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Order note without user cannot be save',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const notes = this.order.get('notes') || [];
    notes.push({
      category: this.category,
      message: this.note,
      byUser: this.conn.getCurrentUser().get('username'),
      createdAt: moment().toISOString(),
    });
    this.order.set('notes', notes);
    this.order.save().then(() => {
      this.note = '';
      delete this.category;
      this.noteMessages = [];
      this.noteUpdatedEmitter.emit(undefined);
    }).catch((error: any) => alert(error.message));
  }

  private updateOrder(order: any): void {
    this.order = order;
  }
}
