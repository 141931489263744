import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'operator-filter',
  templateUrl: './operator-filter.html',
})
export class OperatorFilterComponent {
  @Output() updatedOperatorList: EventEmitter<any> = new EventEmitter();
  @Input('filter') filter: { [key: string]: unknown } = {};
  @Input() set reset(status: boolean) {
    this.resetting = status;
    if (status) this.resetSelection();
  }
  @Input() showSeacrhing: boolean = true;
  operators: Array<any> = [];
  resetting: boolean = false;
  rows: Array<{ operator: any, selected?: boolean }> = [];
  currentUser: any;
  searchKey: string = '';
  constructor(private conn: ConnectionService, private zone: NgZone) {
  }

  async ngOnInit(): Promise<any> {
    this.currentUser = this.conn.getCurrentUser();
    await this.fetchActiveOperators();
    this.sendUpdatedList();
  }

  async fetchActiveOperators(): Promise<void> {
    this.operators = await this.conn.fetchAllActiveOperators(this.filter, ['username']);
    this.zone.run(() => (this.rows = this.operators.map((operator: any) => ({ operator, selected: this.currentUser.id === operator.id }))));
  }

  sendUpdatedList(): void {
    if (this.resetting) {
      return;
    }
    this.updatedOperatorList.emit(this.rows.filter(({ selected }: any) => selected).map(({ operator }: any) => operator));
  }

  toggleSelection(r: { operator: any, selected: boolean }): any {
    const row = r;
    row.selected = !row.selected;
    this.sendUpdatedList();
  }

  async onInputChange(): Promise<void> {
    this.filter.username = { $regex: this.searchKey, $options: 'i' };
    await this.fetchActiveOperators();
  }

  resetSelection(): void {
    this.zone.run(() => (this.rows = this.operators.map((operator: any) => ({ operator, selected: this.currentUser.id === operator.id }))));
    this.resetting = false;
    this.sendUpdatedList();
  }
}
