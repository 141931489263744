import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})

export class EditComponent {
  wareHouseId: any;
  wareHouse: any;
  availableSKUs: string;
  constructor(private conn: ConnectionService,
    private route: ActivatedRoute,
    private appConfig: AppConfig,
    private router: Router) {
    this.wareHouseId = this.route.snapshot.params?.id;
  }

  async ngOnInit(): Promise<void> {
    this.wareHouseId = this.route.snapshot.params.id;
    if (this.wareHouseId) {
      this.wareHouse = await this.conn.findWareHouseById(this.wareHouseId);
      this.availableSKUs = this.wareHouse.get('availableSKUs').join(',');
    } else {
      this.wareHouse = new Table.Warehouse();
    }
  }
  async saveWareHouse(): Promise<any> {
    this.wareHouse.set('availableSKUs', this.availableSKUs.split(','));
    await this.wareHouse.save();
    await this.router.navigate(['/couriers/wareHouse']);
  }
}
