
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading p-2 bdr-btm bg-white" *ngIf="ticket">
      <div class="col-xs-12">
        <div class="col-xs-12 flex"><span class="bold">{{ticket.get("ticketNumber")}} : &nbsp;</span><span *ngIf="ticket.get('title')">{{ticket.get('title')}}</span>
          <language-view *ngIf="!ticket.get('title')" [languageStringObj]="ticket.get('titleLanguageString')"></language-view>
        </div>
        <div class="col-xs-12">
          <button class="pull-right btn-xxxs btn-secondary ml-3"><a class="clr-primary" routerLink="/chat/{{chatUser.get('username')}}" target="_blank">CHAT</a></button>
          <button class="pull-right btn-xxxs btn-danger ml-3" *ngIf="!ticket.get('attended')" (click)="updateTicketAttendedStatus(true)">UnAttended</button>
          <button class="pull-right btn-xxxs btn-secondary ml-3" *ngIf="ticket.get('attended')" (click)="updateTicketAttendedStatus(false)">Attended</button>
          <button class="pull-right btn-xxxs btn-danger ml-3" *ngIf="ticket.get('status') === appConfig.Shared.Ticket.Status.Pending" (click)="requestTicketClose()">Request Ticket Close</button>
          <button class="pull-right btn-xxxs ml-3" *ngIf="ticket.get('status') === appConfig.Shared.Ticket.Status.Completed" (click)="reOpenTicket()">Re-Open Ticket</button>
          <button class="pull-right btn-xxxs btn-secondary ml-3" (click)="toggleDisableChat()">{{ticket?.get('disableChat') ? "Unblock chat": "Block Chat"}}</button>
          <button class="pull-right btn-xxxs btn-secondary ml-3" *ngIf="showCaptureButton" (click)="captureTicket()">Capture</button><em class="fas fa-edit pl-3 pull-right" *ngIf="ticket.get('status') !== appConfig.Shared.Ticket.Status.Completed" (click)="editTicket(ticket)"></em><em class="fas fa-history pl-3 pull-right" (click)="openTicketHistory(ticket)"></em>
        </div>
      </div>
    </div>
    <div class="flex-content" #chatContainer>
      <iframe *ngIf="appIframeURL" #newChatView [src]="appIframeURL"></iframe>
    </div>
  </div>
</div>
