import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CannedResponseEditComponent } from './canned-response-edit.component';
import { InputTextareaModule } from '../../../components/input/input-textarea';
import { LanguageStringTagSelectionModule } from '../../../components/language-string/language-string-tag-selection';
import { LanguageStringSelectionModule } from '../../../components/language-string/language-string-selection';

const ROUTES: Routes = [
  { path: '', component: CannedResponseEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputTextareaModule,
    LanguageStringTagSelectionModule,
    RouterModule.forChild(ROUTES),
    LanguageStringSelectionModule,
  ],
  declarations: [CannedResponseEditComponent],
  exports: [CannedResponseEditComponent],
})
export class CannedResponseEditModule {
}
