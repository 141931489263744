import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

@Component({ selector: 'question-option-rearrange-modal', templateUrl: './question-option-rearrange.html' })
export class QuestionOptionRearrangeModal {
  currentAnswerSequence: string[] = [];
  newAnswerSequence: string[] = [];
  public subscriptions: Subscription[] = [];

  constructor(private dialogRef: MatDialogRef<QuestionOptionRearrangeModal>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.currentAnswerSequence = this.data.currentAnswerSequence || [];
    this.updateNewAnswerSequence();
  }

  hideModal(data: any): void {
    this.dialogRef.close(data);
  }

  confirmMapping(): void {
    this.hideModal(this.newAnswerSequence);
  }

  private updateNewAnswerSequence(): void {
    this.newAnswerSequence = this.currentAnswerSequence.map((each: string) => each);
  }

  moveOptionUp(index: number): void {
    if (index < 1) return;
    const item = this.newAnswerSequence.splice(index, 1);
    this.newAnswerSequence.splice(index - 1, 0, ...item);
  }

  moveOptionDown(index: number): void {
    if (index < 0 || index >= this.newAnswerSequence.length - 1) return;
    const item = this.newAnswerSequence.splice(index, 1);
    this.newAnswerSequence.splice(index + 1, 0, ...item);
  }
}
