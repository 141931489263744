
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/orders">Orders</a></li>
          <li>{{ orderObj?.id ? 'Edit' : 'Create' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content">
      <form class="col-xs-12 p-4 pt-0" #orderForm="ngForm" (submit)="saveOrder()">
        <div class="col-xs-12 col-md-6 pr-md-4">
          <label class="col-xs-4">User name</label>
          <div class="col-xs-12">
            <input class="form-control" type="text" [(ngModel)]="order.user.displayName" placeholder="Search User" matInput="matInput" name="username" autocomplete="off" required="required" [disabled]="orderObj.id || ui.isOrderReferenceInQuery" [formControl]="autoCompleteUserController" [matAutocomplete]="userAuto"/>
            <mat-autocomplete class="search-bar-xs" #userAuto="matAutocomplete">
              <mat-option *ngFor="let option of userOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnUserSelect(option)">{{option.name}}</mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 pr-md-4">
          <label class="col-xs-4">Type</label>
          <select class="form-control" [(ngModel)]="order.type" name="type" required="required" (change)="onSelectionChange()" [disabled]="orderObj.id || ui.isOrderReferenceInQuery || order?.trackingId">
            <option *ngFor="let type of types" [ngValue]="type.value">{{type.name}}</option>
          </select>
        </div>
        <div class="col-xs-12 col-md6 pr-md-4" *ngIf="orderObj.get('isPartialOrder') && orderObj.get('regimen')?.get('variants')?.length">
          <label class="col-xs-4">Variant</label>
          <select class="form-control" [(ngModel)]="selectedVariant" name="variant" (change)="onChangeVariant()">
            <option *ngFor="let variant of variants" [value]="variant.value">{{variant.display}}</option>
          </select>
        </div>
        <div class="col-xs-12 col-md-12" *ngIf="[apiClientConstant.Order.Type.REPLACEMENT_PRODUCT].includes(order.type)">
          <label class="col-xs-4">Reference Order</label>
          <div class="col-xs-12">
            <select class="form-control" [(ngModel)]="order.referenceOrder" name="referenceOrder" required="required" [disabled]="orderObj.id|| ui.isOrderReferenceInQuery" (change)="onChangeReferenceOrder()">
              <option *ngFor="let order of userOrders" [ngValue]="order.id">{{order.get('orderNumber') || order.id}}</option>
            </select>
          </div>
        </div>
        <div class="col-xs-12 col-md-12" *ngIf="[apiClientConstant.Order.Type.REGIMEN, this.appConfig.Shared.Order.Type.TRIAL_REGIMEN].includes(order.type)">
          <label class="col-xs-4">Regimen</label>
          <div class="col-xs-12">
            <select class="form-control" [(ngModel)]="order.regimenId" name="regimenId" required="required" [disabled]="orderObj.id">
              <option *ngFor="let regimen of userRegimens" [ngValue]="regimen.get('regimenId')">{{regimen.get('regimenId')}}</option>
            </select>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Address</label>
          <address-book-selection [parseObject]="orderObj" name="addressBook" [user]="order?.user?.userObj"></address-book-selection>
        </div>
        <div class="col-xs-12 col-md-12">
          <label class="col-xs-12">Stage</label>
          <div class="col-xs-12">
            <select class="form-control" [(ngModel)]="order.stage" name="Stage" required="required" [disabled]="disableStageEdit">
              <option *ngFor="let stage of possibleOrderStage" [ngValue]="stage.value">{{stage.name}}</option>
            </select>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get('stage') === 'OUT_OF_STOCK'">
            <label class="i-switch i-switch-sm ml-2">
              <input type="checkbox" [(ngModel)]="requestContext.skipInventoryCheck" name="skipInventoryCheck"/><i></i>
            </label><span class="mt-2 ml-2">Skip Inventory Check</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-12" *ngIf="apiClientConstant.Order.Stage.USER_CONFIRMATION_PENDING === orderObj.get('stage')">
          <label class="col-xs-12">What user needs ?</label>
          <mat-radio-group [(ngModel)]="userNeedForConfirmation" required="required">
            <mat-radio-button class="col-xs-12" [value]="appConfig.Shared.CallConfirmation.DOCTOR_CALL">User wanted a doctor call</mat-radio-button>
            <mat-radio-button class="col-xs-12" [value]="appConfig.Shared.CallConfirmation.VOICE_INSTRUCTION">User wanted Voice Instruction</mat-radio-button>
            <mat-radio-button class="col-xs-12" [value]="appConfig.Shared.CallConfirmation.NONE">None</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-xs-12 col-md-12" *ngIf="order.stage === apiClientConstant.Order.Stage.WAITING_FOR_IMAGE">
          <label class="col-xs-12">Why is the order moved to "Waiting For Image" ?</label>
          <div class="col-xs-12">
            <mat-select [(ngModel)]="selectedWaitingForImageReasons" name="selectedWaitingForImageReasons" placeholder="Select Reason(s)" style="min-width:150px;padding:4px;outline:1px solid black;" multiple="multiple">
              <mat-option *ngFor="let item of appConfig.Shared.Order.WaitingForImageReasons" [value]="item.key">{{item.key}}</mat-option>
            </mat-select>
          </div>
          <div class="col-xs-12 p-0 mt-2" *ngIf="selectedWaitingForImageReasons.includes('Missing information')">
            <textarea class="col-xs-12" [(ngModel)]="selectedWaitingForImageReasonsTextArea" rows="2" required="required" style="height:auto;width:100%;"></textarea>
          </div>
        </div>
        <ng-container *ngIf="!order?.trackingId">
          <div class="col-xs-12 col-md-6 pr-md-4" *ngIf="[apiClientConstant.Order.Stage.AWB_GENERATED, apiClientConstant.Order.Stage.QC_PASS, apiClientConstant.Order.Stage.INVOICE_GENERATED].includes(order.stage)">
            <label class="col-xs-4">Courier Name</label>
            <div class="col-xs-12">
              <select class="form-control" [(ngModel)]="order.courierName" name="courierName" required="required">
                <option *ngFor="let courier of couriers" [ngValue]="courier">{{courier}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 pr-md-4" *ngIf="[apiClientConstant.Order.Stage.AWB_GENERATED, apiClientConstant.Order.Stage.PICKUP_READY, apiClientConstant.Order.Stage.QC_PASS, apiClientConstant.Order.Stage.INVOICE_GENERATED].includes(order.stage) && ![apiClientConstant.Order.Courier.NONE].includes(order.courierName)">
            <label class="col-xs-4">Tracking Id</label>
            <div class="col-xs-12">
              <input class="form-control" [(ngModel)]="order.trackingId" name="trackingId" required="required" placeholder=""/>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 pr-md-4" *ngIf="[apiClientConstant.Order.Stage.AWB_GENERATED, apiClientConstant.Order.Stage.PICKUP_READY].includes(order.stage) && [apiClientConstant.Order.Courier.NONE].includes(order.courierName)">
            <label class="col-xs-4">Note</label>
            <div class="col-xs-12">
              <input class="form-control" [(ngModel)]="order.note" name="note" required="required" placeholder=""/>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 pr-md-4" *ngIf="[apiClientConstant.Order.Stage.AWB_GENERATED, apiClientConstant.Order.Stage.PICKUP_READY].includes(order.stage) && ![apiClientConstant.Order.Courier.NONE].includes(order.courierName)">
            <label class="col-xs-4">Tracking URL</label>
            <div class="col-xs-12">
              <input class="form-control" [(ngModel)]="order.trackingURL" name="trackingUrl" placeholder="Optional (auto fill at backend)"/>
            </div>
          </div>
        </ng-container>
        <div class="col-xs-12 col-md-6 pr-md-4" *ngIf="order.stage === apiClientConstant.Order.Stage.RETRY_DELIVERY">
          <label class="col-xs-4">Retry notes given by user:</label>
          <div class="col-xs-12">
            <input class="form-control" [(ngModel)]="order.stageChangeNote" name="reason_to_retry_delivery" required="required" placeholder="( Will not be visible to User )"/>
          </div>
        </div>
        <div class="col-xs-12 col-md-12" *ngIf="orderObj.id && !paymentPopUp">
          <label class="col-xs-4">Payment Type ({{ order.paymentType }}) (Allowed: {{ allowedPaymentGateways }})</label>
          <div class="col-xs-12">
            <select class="form-control" [(ngModel)]="order.paymentType" name="paymentType" required="required" [disabled]="!['INITIAL', 'ONLINE_PAYMENT_PENDING'].includes(orderObj.get('stage'))">
              <option *ngFor="let paymentType of paymentTypes" [ngValue]="paymentType.value">{{paymentType.name}}</option>
            </select>
          </div>
        </div>
        <div class="popup p-2" *ngIf="paymentPopUp">
          <label class="col-xs-4 m-3">Payment Type</label>
          <div class="col-xs-10 pt-5 p-3 border">
            <select class="form-control" [(ngModel)]="order.paymentType" name="paymentType" required="required" [disabled]="orderObj.id && !allowOrderEditing">
              <option *ngFor="let paymentType of paymentTypes" [ngValue]="paymentType.value">{{paymentType.name}}</option>
            </select>
          </div>
          <div class="col-xs-10 mt-4 border">
            <button class="m-2" *ngIf="!changePaymentModeInProcess" type="button" (click)="changeOrderPaymentTypeForNewOrder()">Submit</button>
            <p class="alert-warning" *ngIf="changePaymentModeInProcess">Change in Process</p>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="![apiClientConstant.Order.Type.REGIMEN].includes(order.type) || orderObj.id">
          <label class="col-xs-4" style="margin-top:5px;font-weight:bold">Products</label>
          <div class="col-xs-12 mb-2">
            <div *ngFor="let product of order.products;let i = index;" style="padding:3px 10px;background:#5a5a5a;display:inline-block;border-radius:3px;margin:2px 10px 2px 0px;color:white"><i class="fa fa-trash-o pointer" *ngIf="allowOrderEditing" (click)="removeProduct(i)"></i>
              <div style="max-width:500px;display:inline-block;">&nbsp;&nbsp; {{product.get('title')}}&nbsp;[{{product.get('quantity')}}{{product.get('quantityUnit')}}, Unit: {{product.get('margUnit')}}] {{product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</div>
            </div>
          </div>
          <div class="col-xs-12 mt-2" *ngIf="allowOrderEditing">
            <input class="form-control" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
            <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
              <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option)">{{option.name}}</mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="![apiClientConstant.Order.Type.REGIMEN].includes(order.type) || orderObj.id">
          <label class="col-xs-4" style="margin-top:5px;font-weight:bold">Services</label>
          <div class="col-xs-12">
            <div *ngFor="let service of order.services;let i = index;" style="padding:3px 10px;background:#5a5a5a;display:inline-block;border-radius:3px;margin:2px 10px 2px 0px;color:white"><i class="fa fa-trash-o pointer" *ngIf="allowOrderEditing" (click)="removeService(i)"></i>
              <div style="max-width:500px;display:inline-block;">&nbsp;&nbsp; {{service.get('title')}}&nbsp; (₹{{service.get('amount')}}) ({{service.get('type')}})</div>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="allowOrderEditing">
            <input class="form-control" type="text" placeholder="Type Service Name..." matInput="matInput" name="add_service" autocomplete="off" [formControl]="autoCompleteServiceController" [matAutocomplete]="serviceAuto"/>
            <mat-autocomplete class="search-bar-xs" #serviceAuto="matAutocomplete">
              <mat-option *ngFor="let option of serviceOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnServiceSelect(option)">{{option.name}}</mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 pr-md-4">
          <label class="col-xs-5">Consultation Charges</label>
          <div class="col-xs-12">
            <input class="form-control" [(ngModel)]="order.consultationCharges" name="consultationCharges" (change)="calculateAmount()" placeholder="" [disabled]="orderObj.id"/>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 pr-md-4" *ngIf="![apiClientConstant.Order.Type.REPLACEMENT_PRODUCT].includes(order.type)">
          <label class="col-xs-3">Amount</label>
          <div class="col-xs-12">
            <input class="form-control" [(ngModel)]="order.amount" name="amount" disabled="true" [required]="[apiClientConstant.Order.Type.PRODUCT].includes(order.type)" placeholder=""/>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 pr-md-4" *ngIf="![apiClientConstant.Order.Type.REPLACEMENT_PRODUCT].includes(order.type)">
          <label class="col-xs-4">Actual Amount</label>
          <div class="col-xs-12">
            <input class="form-control" [(ngModel)]="order.actualPrice" name="actualPrice" disabled="true" placeholder=""/>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="orderObj.get('regimen')">
          <label class="col-xs-3">Doctor Call Needed</label>
          <label class="i-switch i-switch-sm bg-success dker m-l-xs">
            <input class="ng-pristine ng-valid ng-touched" type="checkbox" (ngModelChange)="updateDoctorCallNeeded()" [(ngModel)]="doctorCallNeeded" name="doctorCallNeeded"/><i></i>
          </label>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Fetch Current Address</label>
          <label class="i-switch i-switch-sm bg-success dker m-l-xs">
            <input class="ng-pristine ng-valid ng-touched" type="checkbox" (ngModelChange)="updateAddressBook()" [(ngModel)]="updateAddress" name="updateAddress"/><i></i>
          </label>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Instruction Call Needed</label>
          <input-checkbox [parseObj]="orderObj" name="followUpNeeded"></input-checkbox>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Required for next followUp</label>
          <input-checkbox [parseObj]="orderObj" name="followUpRequired"></input-checkbox>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Send No Message To User</label>
          <label class="i-switch i-switch-sm bg-success dker m-l-xs">
            <input class="ng-pristine ng-valid ng-touched" type="checkbox" [(ngModel)]="order.noMessageToUser" name="noMessageToUser"/><i></i>
          </label>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Use Wallet Balance</label>
          <label class="i-switch i-switch-sm bg-success dker m-l-xs">
            <input class="ng-pristine ng-valid ng-touched" type="checkbox" [(ngModel)]="order.updateCashCoupon" name="updateCashCoupon"/><i></i>
          </label>
        </div>
        <div class="col-xs-12 mt-4">
          <button class="mt-3" type="submit" [disabled]="!orderForm.valid || !allowCreateOrder">{{ orderObj.id ? 'Update' : 'Create' }}</button>
        </div>
      </form>
      <div class="col-xs-12 mb-3 px-0" *ngIf="orderObj.id">
        <div class="col-xs-12 list-header">Notes</div>
        <order-note [order]="orderObj" [note]="note" (noteUpdated)="OnNoteUpdation($event)"></order-note>
      </div>
    </div>
    <div class="model_payment_mode" *ngIf="ui.dialog.Confirmation.open" (click)="ui.dialog.Confirmation.open = false">
      <div class="holder" (click)="stopPropagation($event)">
        <form #confirmationForm="ngForm">
          <div class="col-xs-12 whitespace-pre-inline">{{ui.dialog.Confirmation.data.message}}</div>
          <div class="col-xs-12 m-t-xs">
            <div class="pull-left btn btn-light-blue m-t-xs" (click)="ui.dialog.Confirmation.open = false">Don't Create Order</div>
            <div class="pull-right btn btn-success m-t-xs" (click)="saveOrder([ui.dialog.Confirmation.data.acknowledgementField])">Create Order</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
