import { Component, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { RequestQueryPayload } from 'api-client';
import { AppConfig } from 'src/app/app.config';
import { Broadcaster } from 'src/components/broadcaster';
import * as moment from 'moment';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  searchKey: string;
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  ui: any;
  count: number;
  constructor(private conn: ConnectionService,
              private zone: NgZone,
              private windowRef: WindowRefService) {
  }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'User',
      field: 'requestUser.username',
      width: 150,
    }, {
      headerName: 'Request At',
      field: 'createdAt',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.data) {
          const eDiv = this.windowRef.nativeWindow.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('DD MMM YY, HH:mm A') ?? '-';
        return eDiv;
      },
    }, {
      headerName: 'Total',
      field: 'requestCount',
      width: 100,
    }, {
      headerName: 'Failed',
      field: 'failureCount',
      width: 100,
    }, {
      headerName: 'Success',
      field: 'successCount',
      width: 100,
    }, {
      headerName: 'BatchId',
      field: 'batchId',
      pinned: 'left',
      width: 300,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const id = params.value || params.data.objectId;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='pointer' target="_blank" href='/couriers/bulkUpdate/${id}'>${id}</a>`;
        return eDiv;
      },
    }, {
      headerName: 'Stage',
      field: 'stage',
      width: 150,
    }, {
      headerName: 'Group',
      field: 'group',
      width: 150,
    }];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 10000);
    } else if (this.autoRefreshInterval) clearInterval(this.autoRefreshInterval);
  }

  async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.BatchRequest> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['requestUser'],
      project: [
        'requestCount',
        'failureCount',
        'successCount',
        'createdAt',
        'batchId',
        'requestUser.username' as 'requestUser',
        'stage',
        'group',
      ],
      descending: 'createdAt',
    };
    const searchOn = ['batchId'];
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((e: any) => (requestPayload.where[e] = { $regex: params.filterModel[e].filter, $options: 'i' }));
    }

    let data;
    [data, this.count] = await Promise.all([
      this.conn.findBatchRequest(requestPayload),
      this.conn.countBatchRequest(requestPayload),
    ]);
    return Promise.resolve(JSON.parse(JSON.stringify(data)));
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
  }
}
