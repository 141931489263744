
<div class="hbox" fillComponentParentView="fillComponentParentView">
  <div class="flex-box" *ngIf="user">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">Patient history
        <div class="fontL2 text-cap pull-right"><span class="mobile call-icon"><em class="fas fa-phone-alt" *ngIf="user.get('MobileNumber')" (click)="callUserThroughExotel(user.get('MobileNumber'))" title="Mobile"></em><em class="fas fa-phone-alt alt-2" *ngIf="user.get('alternateNumber')" (click)="callUserThroughExotel(user.get('alternateNumber'))" title="Alternate Mobile"></em></span>
          <button class="btn-xs btn-primary-outline ml-3"><a routerLink="/chat/{{user.id}}" target="_blank">Chat</a></button>
          <button *ngIf="showNewSalesUICTA" class="btn-xs btn-primary-outline ml-3"><a [routerLink]="'/newSales'" [queryParams]="{username: user.get('username')}" target="_blank">New UI</a></button>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 flex alignFS">
        <div class="col-md-6 col-xs-12 p-4">
          <div class="col-xs-12 flex">
            <label class="col-xs-4">Paid User</label>
            <user-priority [user]="user"></user-priority>
          </div>
          <form class="col-xs-12" #form="ngForm">
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Patient name</label>
              <div class="col-xs-8 ml-3 mt-0">
                <input-text [parseObj]="user" name="PatientName" required="true"></input-text>
              </div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Preferred Language</label>
              <div class="col-xs-8 ml-3">
                <input-select [parseObj]="user" name="languagePreference" required="true" [options]="languageOptions"></input-select>
              </div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Doctor</label>
              <div class="col-xs-8 ml-3">{{user.get('allocatedDoctor')?.get('username') || '-'}}</div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Age</label>
              <div class="col-xs-8 ml-3">
                <input-text [parseObj]="user" name="Age" required="true"></input-text>
              </div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">MobileNumber</label>
              <div class="col-xs-8 ml-3">{{user.get('MobileNumber')}}</div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Gender</label>
              <div class="col-xs-8 ml-3">
                <input-select [parseObj]="user" name="Gender" required="true" [options]="genderType"></input-select>
              </div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Alternate Number</label>
              <div class="col-xs-8 ml-3">
                <input-text [parseObj]="user" name="alternateNumber"></input-text>
              </div>
            </div>
            <button type="submit" [disabled]="!form.valid || !this.user.dirtyKeys().length" (click)="updateUser()">Update User</button>
          </form>
          <div class="col-xs-12 flex">
            <div class="col-xs-6 flex">
              <label class="col-xs-8 pr-3">Ticket History</label><i class="fa fa-history" (click)="openSupportTicketHistory()"></i>
            </div>
            <div class="col-xs-6 flex">
              <label class="col-xs-8 pr-3">FollowUp History</label><i class="fa fa-history" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP)"></i>
            </div>
          </div>
          <div class="col-xs-12">
            <blocked-bot-assistant-modal [user]="user" [hideChatStatus]="true" [(updateStatus)]="updateStatus"></blocked-bot-assistant-modal>
          </div>
          <div class="col-xs-12 p-0">
            <div class="col-xs-12">
              <label>Doctor Notes</label>
            </div>
            <user-notes-doctor [user]="user" [enableAddNote]="true"></user-notes-doctor>
          </div>
          <div class="col-xs-12 p-0">
            <div class="col-xs-12">
              <label>Operator Notes</label>
            </div>
            <user-notes-operator [user]="user" [enableAddNote]="true"></user-notes-operator>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="list-header"><span>Consultation Chat</span>
            <div class="col-xs-6 pull-right">
              <select class="form-control col-xs-12" [(ngModel)]="selectedConsultation" name="consultations" (ngModelChange)="updateIframe()" style="min-height:20px !important; padding: 0px !important">
                <option *ngFor="let consultation of consultations" [value]="consultation.id">{{consultation.get('PrivateMainConcernClass')}}</option>
              </select>
            </div>
          </div>
          <iframe class="hbox" [src]="webAppUrl" style="height:450px"></iframe>
        </div>
      </div>
      <div class="col-xs-12 p-0">
        <div class="col-xs-12 flex alignFS">
          <div class="col-xs-6">
            <div class="col-xs-12 list-header pl-4">Support Ticket</div>
            <div class="col-xs-12 p-4">
              <ticket-list [user]="user"></ticket-list>
            </div>
          </div>
          <div class="col-xs-6 ml-3">
            <div class="col-xs-12 list-header pl-4">Call Logs</div>
            <div class="col-xs-12 p-4">
              <call-logs [user]="user"></call-logs>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 flex alignFS">
        <div class="col-xs-6 p-0">
          <div class="col-xs-12 list-header pl-4"><em class="fas fa-cart-plus pr-3"></em><span>Orders</span><a class="fa fa-plus-square-o pl-3" routerLink="/orders/new" [queryParams]="{ username: user.get('username') }" target="_blank"></a></div>
          <div class="col-xs-12 p-4 orderList">
            <order-list [user]="user" [hideTitle]="true"></order-list>
          </div>
        </div>
        <div class="col-xs-6 p-0 ml-3">
          <div class="col-xs-12 list-header pl-4">Regimens</div>
          <div class="col-xs-12 p-4">
            <regimen-list [user]="user" [ui]="{hideTitle: true}"></regimen-list>
          </div>
        </div>
      </div>
      <div class="col-xs-12 flex alignFS">
        <div class="col-xs-6">
          <div class="col-xs-12 list-header pl-4">Tree Actions</div>
          <div class="col-xs-12 p-4">
            <tree-trigger [user]="user"></tree-trigger>
          </div>
        </div>
        <div class="col-xs-6 ml-3">
          <div class="col-xs-12 list-header pl-4">Main Concerns</div>
          <div class="col-xs-12 p-4">
            <main-concern [user]="user"></main-concern>
          </div>
        </div>
      </div>
      <div class="col-xs-12 orderList">
        <instant-checkup-modal [user]="user" [showImagesInColumn]="true"></instant-checkup-modal>
      </div>
      <div class="col-xs-12 p-0 flex alignFS">
        <div class="col-xs-6 p-0">
          <div class="col-xs-12 list-header">Send Message</div>
          <form class="col-xs-12 p-3">
            <textarea class="w100" [(ngModel)]="message" name="message" placeholder="Message For User" required="required"></textarea>
            <textarea class="w100" [(ngModel)]="openUrl" name="openUrl" placeholder="OpenUrl link for notification"></textarea>
            <button class="mt-2" type="button" [disabled]="!message" (click)="sendNotification(apiClientConstant.Notification.Type.Text)">Send</button>
          </form>
        </div>
        <div class="col-xs-6 p-0 ml-3">
          <div class="list-header">Audio Message
            <div class="pull-right fontS">{{this.user.get("languagePreference")}}</div>
          </div>
          <div class="col-xs-12 px-3">
            <audio-recorder (afterSave)="afterSaveRecording($event)" [disableVoiceToText]="true" (onChange)="onAudioRecordingStatusChange($event)"></audio-recorder>
          </div>
          <div class="col-xs-8 p-3">
            <textarea class="w100" [(ngModel)]="audioNote" placeholder="Audio Note for Internal Team (Not Visible To User)"></textarea>
          </div>
          <div class="col-xs-3">
            <button class="ml-3 mt-3" type="button" (click)="saveRecording()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
