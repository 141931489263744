import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AppConfig } from 'src/app/app.config';
import { ApiClientConstant, Table } from 'api-client';

@Component({
  selector: 'app-add-waiting-call-dialog',
  templateUrl: './add-waiting-call-dialog.html',
  styleUrls: ['./add-waiting-call-dialog.scss'],
})
export class AddWaitingCallDialogComponent implements OnInit {
  chatUser: any;
  min: string = moment().format('YYYY-MM-DDTHH:mm');

  form: UntypedFormGroup = this.fb.group({
    status: ApiClientConstant.PendingCall.Status.Requested,
    type: ApiClientConstant.PendingCall.Type.ScheduledCall,
    requestTime: ['', Validators.required],
    user: [this.data.chatUser, Validators.required],
    note: '',
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddWaitingCallDialogComponent>,
    private fb: UntypedFormBuilder,
    private appConfig: AppConfig,
  ) {
    this.chatUser = this.data.chatUser;
  }

  ngOnInit(): void {}

  async addWaitingCall(): Promise<void> {
    if (this.form.invalid) return;
    try {
      const pendingCall = new Table.PendingCall();
      const payload = {
        ...this.form.value,
        requestTime: moment(
          this.form.get('requestTime').value,
          'YYYY-MM-DDTHH:mm',
        ).toDate(),
      };
      await pendingCall.save(payload);
      this.dialogRef.close(true);
    } catch (error) {
      alert(error.message || error);
    }
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
