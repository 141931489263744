import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainConcernsComponent } from './main-concerns.component';
import { ListModule } from './list';
import { EditModule } from './edit';
import { ViewModule } from './view';

const ROUTES: Routes = [
  {
    path: '',
    component: MainConcernsComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'new',
    component: MainConcernsComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: ':id/edit',
    component: MainConcernsComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: ':id',
    component: MainConcernsComponent,
    loadChildren: (): any => ViewModule,
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [MainConcernsComponent],
  exports: [MainConcernsComponent],
})
export class MainConcernsModule { }
