import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as EXIF from 'exif-js';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';
import { Broadcaster } from '../broadcaster';
import { WindowRefService } from '../../services/window-ref-service';
import { rotateCanvasBasedOnOrientation } from '../../components/util';

interface ImageConfig {
  instantCheckup: any;
  compressedImage: boolean;
  width: string;
  height: string;
  left: number;
  top: number;
}

@Component({ selector: 'comparison-viewer',
  templateUrl: './comparison-viewer.html',
  styleUrls: ['./comparison-viewer.scss'] })
export class ComparisonViewerComponent {
  imageLoaded: boolean[] = [false, false];
  instantCheckups: Array<any> = [];
  MIN_IMAGE_WIDTH: number = 360; // the view port of comparison box is to be 360 * 360. Do not change it.

  @ViewChild('slider', { static: false }) slider: ElementRef;
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('imgH1', { static: false }) imgH1: ElementRef;
  @ViewChild('imgH2', { static: false }) imgH2: ElementRef;
  @ViewChild('imageOne', { static: false }) imageOne: ElementRef;
  @ViewChild('imageTwo', { static: false }) imageTwo: ElementRef;

  report: any = { imageConfig: { after: {}, before: {} } };
  instantCheckupIds: Array<string>;
  // @ts-ignore
  imageConfig: { after: Partial<ImageConfig>, before: Partial<ImageConfig> } = { };
  @Input('instantCheckupIds')
  set onGetInstantCheckupIds(instantCheckupIds: Array<string>) {
    if (!instantCheckupIds?.length) {
      this.instantCheckupIds = [];
      this.instantCheckups = [];
      this.report = { imageConfig: { after: {}, before: {} } };
      return;
    }
    this.instantCheckupIds = instantCheckupIds;
    this.loadData(instantCheckupIds);
  }
  @Input('title') title: string = 'Side face comparison';
  @Input('imageConfig')
  set onUpdateImageConfig(imageConfig: any) {
    if (!Object.keys(imageConfig || {}).length) return;
    this.imageConfig = imageConfig;
    this.updateDefaultImageConfig();
    this.loadData([imageConfig.beforeInstantId, imageConfig.afterInstantId]);
    this.imageConfig = imageConfig.imageConfig;
  }
  @Input('sliderLength') sliderLength: number;
  @Input('user') user: any;
  @Output() selectBeforeImage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectAfterImage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setImageConfig: EventEmitter<any> = new EventEmitter<any>();

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private window: WindowRefService,
    private broadcast: Broadcaster) {
  }

  async ngOnInit(): Promise<any> {
  }

  updateDefaultImageConfig(): void {
    if (!this.instantCheckups?.length) return;
    if (!Object.keys((this.imageConfig || {})).length) {
      this.imageConfig = {
        after: {
          compressedImage: !!this.instantCheckups[0].compressedImagePath,
          width: '360px',
        },
        before: {
          compressedImage: !!this.instantCheckups[1].compressedImagePath,
          width: '360px',
        },
      };
      this.updateParentImageConfig();
    }
  }
  async loadData(ids: Array<string>): Promise<any> {
    this.instantCheckupIds = ids;
    if (!ids?.length || !ids[0] || !ids[1]) return;
    this.instantCheckups = await this.loadInstantCheckups(this.instantCheckupIds);
    if (this.instantCheckups.length !== 2) {
      this.broadcast.broadcast('NOTIFY', { message: 'Instant CheckUps not found', type: 'ERROR' });
    }
    await this.updateDefaultImageConfig();
    this.report.imageConfig = this.imageConfig;
    this.report.afterInstantCheckup = this.instantCheckups[0];
    this.report.beforeInstantCheckup = this.instantCheckups[1];
    this.report.imageConfig.after.imageURL = this.report.imageConfig.after.compressedImage
      ? this.instantCheckups[0].compressedImagePath
      : this.instantCheckups[0].imagePath;
    this.report.imageConfig.before.imageURL = this.report.imageConfig.before.compressedImage
      ? this.instantCheckups[1].compressedImagePath
      : this.instantCheckups[1].imagePath;
    this.report.imageConfig.after.orientation = this.instantCheckups[0].orientation;
    this.report.imageConfig.before.orientation = this.instantCheckups[1].orientation;
    await this.loadImage(0, this.imageConfig.after);
  }

  async loadInstantCheckups(ids: string[]): Promise<any> {
    const username: string = this.user.get('username');
    const data = await this.conn.fetchInstantCheckup(username,
      ids,
      ['orientation', 'imagePath', 'compressedImagePath']);
    if ((new Date(data[0].createdAt)) < (new Date(data[1].createdAt))) data.reverse();
    return data;
  }

  async loadImage(index: number, config: any): Promise<any> {
    const image: any = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = async (): Promise<any> => {
      const rotationNeeded = this.conn.rotationNeeded();
      if (rotationNeeded) {
        EXIF.getData(image, async (): Promise<void> => {
          const orientation = EXIF.getTag(image, 'Orientation') || 0;
          await this.checkForOrientationAndDrawImage(index, image, config, orientation);
        });
      } else {
        await this.checkForOrientationAndDrawImage(index, image, config, -1);
      }
      this.setsEventListenersForSliders(index);
    };
    image.src = config.imageURL;
  }

  async checkForOrientationAndDrawImage(index: number, image: any, config: any, orientation_: number): Promise<void> {
    const canvas = index === 0 ? this.imageOne : this.imageTwo;
    const ctx = canvas.nativeElement.getContext('2d');
    const orientation = config.orientation || orientation_;
    if ([5, 6, 7, 8].includes(orientation)) {
      canvas.nativeElement.width = image.height;
      canvas.nativeElement.height = image.width;
      rotateCanvasBasedOnOrientation(orientation, ctx, canvas);
      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.nativeElement.height, canvas.nativeElement.width);
    } else {
      canvas.nativeElement.height = image.height;
      canvas.nativeElement.width = image.width;
      rotateCanvasBasedOnOrientation(orientation, ctx, canvas);
      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
    }

    if (config.width) {
      canvas.nativeElement.style.width = config.width;
      // getElementById is not avaialble in ngOnInit
      if (this.window.nativeWindow.document.getElementById('imgContainer')) {
        this.window.nativeWindow.document.getElementById('imgContainer').style.width = config.width;
      }
    }
    if (config.height) {
      canvas.nativeElement.style.height = config.height;
    }
    const adjustLeft = (this.MIN_IMAGE_WIDTH - this.container.nativeElement.offsetWidth) / 2;
    canvas.nativeElement.style.top = `-${config.top}px`;
    if (index === 0) {
      canvas.nativeElement.style.left = '0px';
    } else {
      canvas.nativeElement.style.left = `-${config.left || 0}px`;
    }
    if (adjustLeft > 0) canvas.nativeElement.style.left = `-${config.left + adjustLeft}px`;
    this.imageLoaded[index] = true;
  }

  /**
   * Based on the movement of event cursor pointer, we move the slide on same axis.
   * The Front image container's width is also adjusted based on the movement of cursor.
   */
  slideStart(e: any): any {
    e.preventDefault();
    const event = e.targetTouches ? e.targetTouches[0] : e;
    const slider = this.slider.nativeElement;
    const imgTop = this.imgH2.nativeElement;
    const imgBehind = this.imgH1.nativeElement.getBoundingClientRect();
    if ((imgBehind.x && event.clientX - 15 < imgBehind.x)
      || (imgBehind.right && event.clientX + 15 > imgBehind.right)) return;
    slider.style.left = `${Math.abs(event.clientX - imgBehind.left)}px`;
    imgTop.style.width = `${Math.abs(event.clientX - imgBehind.left)}px`;
  }

  /**
   * Sets the mouse and touch event listeners on slider.
   * Calls to load the second image once 1st image is done. Then finally sets the event listeners.
   * @param {number} index
   */
  setsEventListenersForSliders(index: number): void {
    if (index === 0) {
      this.loadImage(1, this.report.imageConfig.before);
      return;
    }
    this.slider.nativeElement.addEventListener('mousedown', (): void => {
      this.container.nativeElement.addEventListener('mousemove', (event: any): void => {
        this.slideStart(event);
      });
    });

    this.slider.nativeElement.addEventListener('mouseup', (): void => {
      this.container.nativeElement.removeEventListener('mousemove', (event: any): void => {
        this.slideStart(event);
      });
    });

    this.container.nativeElement.addEventListener('mouseout', (): void => {
      this.container.nativeElement.removeEventListener('mousemove', (event: any): void => {
        this.slideStart(event);
      });
    });

    this.slider.nativeElement.addEventListener('touchmove', (event: any): void => {
      this.slideStart(event);
    });
    this.slider.nativeElement.addEventListener('touchend', (): void => {
    });
  }

  updateParentImageConfig(): void {
    if (this.instantCheckups.length !== 2) return;
    this.setImageConfig.emit({
      imageConfig: this.imageConfig,
      beforeInstantId: this.instantCheckups[1].objectId,
      afterInstantId: this.instantCheckups[0].objectId,
    });
  }
}
