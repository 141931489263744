import { Component, Input, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalStorage } from 'src/services/local-storage-service';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'assign-chat', templateUrl: './assign-chat.html' })
export class AssignChatComponent {
  userRoles: Array<any>;
  @Input('user')
  set userFunction(user: any) {
    this.user = user;
    if (!this.user || !this.user.id) {
      return;
    }
    this.getWaitlistStatus();
    this.isDoctorAssignedToChat();
  }
  user: any;
  tags: any = {
    tag: this.appConfig.Shared.Tag.Tag.ASSIGNED_TO_DOCTOR,
    type: this.appConfig.Shared.Tag.Type.USER_LEVEL,
    description: '',
  };
  actionToBeTriggered: string;
  internalUser: any = this.conn.getCurrentUser();
  assignOptions: Array<any> = [];
  waitlistChat: any;
  assignToIndex: number;

  public subscriptions: Subscription[] = [];

  constructor(public appConfig: AppConfig, private conn: ConnectionService, private zone: NgZone, private storage: LocalStorage) {
  }

  async ngOnInit(): Promise<any> {
    this.assignOptions = await this.conn.getInternalUsers();
    const predictedAssignedTo = this.internalUser.get('type') === ApiClientConstant.User.Type.DOCTOR
      ? this.user.get('allocatedOperator')
      : this.user.get('allocatedDoctor');
    this.assignToIndex = [...this.assignOptions]
      .map((user: any) => user.get('username'))
      ?.indexOf(predictedAssignedTo?.get('username').split('operator')[0]);
    this.userRoles = this.storage.getJsonValue('userRoles');
  }

  async isDoctorAssignedToChat(): Promise<any> {
    [this.tags.assignedObject] = await this.conn.findTags({
      where: {
        user: this.user,
        tag: [
          this.appConfig.Shared.Tag.Tag.ASSIGNED_TO_DOCTOR,
          this.appConfig.Shared.Tag.Tag.ASSIGNED_TO_OPERATOR,
        ],
        status: [
          this.appConfig.Shared.Tag.Status.ASSIGNED,
          this.appConfig.Shared.Tag.Status.NOT_READY_TO_ATTEND,
          this.appConfig.Shared.Tag.Status.READY_TO_ATTEND,
        ],
      },
      include: ['taggedTo'],
      project: ['taggedTo.type' as 'taggedTo', 'description'],
      limit: 1,
    });
  }

  async assignTag(status: string, tag: string, tagParam?: any, description?: string): Promise<void> {
    const newTag = new Table.Tag();
    newTag.set('tag', tag);
    newTag.set('taggedBy', this.internalUser);
    newTag.set('user', this.user);
    newTag.set('type', this.appConfig.Shared.Tag.Type.USER_LEVEL);
    newTag.set('status', status);
    if (this.assignOptions[this.assignToIndex]) {
      newTag.set('taggedTo', this.assignOptions[this.assignToIndex]);
      if (this.assignOptions[this.assignToIndex].get('type') === ApiClientConstant.User.Type.OPERATOR) {
        newTag.set('tag', this.appConfig.Shared.Tag.Tag.ASSIGNED_TO_OPERATOR);
      }
    }
    if (tagParam) {
      newTag.set('tagNote', tagParam);
    }
    if (description || tag === this.appConfig.Shared.Tag.Tag.ASSIGNED_TO_DOCTOR) {
      newTag.set('description', description || this.tags.description);
    }
    await newTag.save();
    if (tag === this.appConfig.Shared.Tag.Tag.ASSIGNED_TO_DOCTOR) {
      this.tags.assignedObject = newTag;
    } else if (tag === this.appConfig.Shared.Tag.Tag.WAITLIST) {
      this.waitlistChat = newTag;
    }
    if (this.actionToBeTriggered === 'ASSIGN_TO_DOCTOR'
      && status !== this.appConfig.Shared.Tag.Status.NOT_READY_TO_ATTEND) {
      await this.changeStatusOfAssignedChat();
    }
  }

  async changeStatusOfAssignedChat(): Promise<void> {
    if (this.tags.assignedObject) {
      this.tags.assignedObject.set('status', this.appConfig.Shared.Tag.Status.NOT_READY_TO_ATTEND);
      await this.tags.assignedObject.save();
    } else {
      await this.assignTag(this.appConfig.Shared.Tag.Status.NOT_READY_TO_ATTEND, this.appConfig.Shared.Tag.Tag.ASSIGNED_TO_DOCTOR);
    }
  }

  async removeFromWaitList(): Promise<void> {
    this.waitlistChat.set('status', this.appConfig.Shared.Tag.Status.FINISHED);
    this.waitlistChat.set('completedby', this.user);
    await this.waitlistChat.save();
    this.zone.run(() => (delete this.waitlistChat));
  }

  unAssignTag(): void {
    this.reAssignTagAsFinished('_ASSIGN_TO_DOCTOR');
    this.tags.assignedObject.set('status', this.appConfig.Shared.Tag.Status.FINISHED);
    this.tags.assignedObject.set('completedby', this.user);
    this.tags.assignedObject.save()
      .then(() => this.zone.run(() => (delete this.tags.assignedObject)));
  }

  async reAssignTagAsFinished(tag: string): Promise<any> {
    const tags = await this.conn.getTags({
      user: this.user,
      tag,
      status: [this.appConfig.Shared.Tag.Status.ASSIGNED],
    });
    await Promise.all(tags.map((eachTag: any) => {
      eachTag.set('status', this.appConfig.Shared.Tag.Status.FINISHED);
      return eachTag.save();
    }));
  }

  async getWaitlistStatus(): Promise<any> {
    [this.waitlistChat] = await this.conn.findTags({
      where: {
        user: this.user,
        tag: { $regex: this.appConfig.Shared.Tag.Tag.WAITLIST, $options: 'i' },
        status: [this.appConfig.Shared.Tag.Status.ASSIGNED],
      },
      project: ['createdAt', 'description'],
    });
  }
}
