import { Component, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { differenceInDays, isAfter, startOfDay, addHours } from 'date-fns';

@Component({
  selector: 'dashboard-order',
  templateUrl: './order.html',
  styleUrls: ['../dashboard.scss'],
})
export class DashboadOrderComponent {
  user: Table.User;
  startDate: Date;
  endDate: Date;

  // order variables
  minApprovalsToDone: number = 40;
  currentOrderApprovals: number = 0;
  currentOrderApproved: number = 0;
  diffInDays: number = 0;
  dataItems: Array<{ title: string, value: number }> = [];

  @Input('userDetails')
  set updateStartDate(userDetails: { startDate: Date, endDate: Date, user: Table.User }) {
    this.startDate = addHours(startOfDay(userDetails.startDate), 6);
    this.endDate = addHours(startOfDay(userDetails.endDate), 6);
    this.user = userDetails.user;
    this.getOrderDetails();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  async getPendingOrderApprovals(): Promise<number> {
    const data = await ApiConnector.count(Table.Order, {
      where: {
        stage: [
          ApiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING,
          ApiClientConstant.Order.Stage.CONSULTATION_CREATED,
        ],
        actualCreatedAt: { $lt: this.startDate },
        allocatedDoctor: this.user,
        doctorConfirmed: true,
      },
    });
    return data;
  }

  async getWFIApprovals(): Promise<number> {
    const data = await ApiConnector.count(Table.OrderConsultationLog, {
      where: {
        stage: ApiClientConstant.Order.Stage.WAITING_FOR_IMAGE,
        createdAt: { $gte: this.startDate, $lte: this.endDate },
        createdBy: this.user,
      },
    });
    return data;
  }

  async getApprovedOrders(): Promise<number> {
    const data = await ApiConnector.count(Table.OrderConsultationLog, {
      where: {
        stage: [ApiClientConstant.Order.Stage.DR_VERIFIED, ApiClientConstant.Order.Stage.CANCELED],
        createdAt: { $gte: this.startDate, $lte: this.endDate },
        createdBy: this.user,
      },
    });
    return data;
  }

  async getCurrentOrderApprovals(): Promise<number> {
    const data = await ApiConnector.count(Table.Order, {
      where: {
        stage: [
          ApiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING,
          ApiClientConstant.Order.Stage.CONSULTATION_CREATED,
        ],
        $and: [
          { actualCreatedAt: { $gte: this.startDate } },
          { actualCreatedAt: { $lte: this.endDate } },
        ],
        allocatedDoctor: this.user,
        doctorConfirmed: true,
      },
    });
    return data;
  }

  async getOrderDetails(): Promise<void> {
    this.dataItems = [];
    if (isAfter(this.startDate, this.endDate)) {
      return;
    }
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.diffInDays = differenceInDays(this.endDate, this.startDate);
    this.currentOrderApproved = await this.getApprovedOrders();
    const pendingOrderApprovals = await this.getPendingOrderApprovals();
    const currentWaitingForImageApprovals = await this.getWFIApprovals();
    this.currentOrderApprovals = await this.getCurrentOrderApprovals();
    this.dataItems.push(
      { title: 'Old Pending', value: pendingOrderApprovals },
      { title: 'WFI', value: currentWaitingForImageApprovals },
    );
  }
}
