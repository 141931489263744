import { Component, Input } from '@angular/core';
import { ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';
import { LocalStorage } from '../../../services/local-storage-service';
import { Broadcaster } from '../../broadcaster';

@Component({
  selector: 'user-notes-ticket',
  templateUrl: './user-notes-ticket.html',
})
export class UserNotesTicketComponent {
  @Input('patient') patient: any;
  @Input() supportTicketId: string;
  ticketNotes: Array<any> = [];
  addNew: boolean;
  noteMessage: string;
  addTicketNoteFlag: boolean;
  internalUser: any;
  userRoles: Array<any> = [];

  constructor(private conn: ConnectionService,
    private appConfig: AppConfig,
    private broadcaster: Broadcaster,
    public storage: LocalStorage) {
  }

  ngOnInit(): void {
    this.internalUser = this.conn.getCurrentUser();
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.loadNotes();
  }

  private async loadNotes(): Promise<void> {
    const supportTicket = new Table.SupportTicket();
    supportTicket.id = this.supportTicketId;
    this.ticketNotes = await ApiConnector.find(Table.TicketNote, {
      where: { supportTicket, user: this.patient },
    });
  }

  async addTicketNote(): Promise<void> {
    if (!this.supportTicketId) return;
    const supportTicket = new Table.SupportTicket();
    supportTicket.id = this.supportTicketId;
    const ticketNote = new Table.TicketNote();
    ticketNote.set('createdBy', this.internalUser.get('username'));
    ticketNote.set('message', this.noteMessage);
    ticketNote.set('supportTicket', supportTicket);
    ticketNote.set('user', this.patient);
    try {
      await ticketNote.save();
      this.ticketNotes.push(ticketNote);
      this.broadcaster.broadcast('NOTIFY', { message: 'Saved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      delete this.noteMessage;
      this.addTicketNoteFlag = false;
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
