import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { CouriersComponent } from './couriers.component';
import { BulkUpdateModule } from './bulk-update';
import { WareHouseComponent } from './warehouse/warehouse.component';
import { WareHouseModule } from './warehouse';

const ROUTES: Routes = [
  {
    path: 'couriers/bulkUpdate',
    component: CouriersComponent,
    loadChildren: (): any => BulkUpdateModule,
  },
  {
    path: 'couriers/wareHouse',
    component: WareHouseComponent,
    loadChildren: (): any => WareHouseModule,
  },
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [CouriersComponent],
  exports: [CouriersComponent],
})

export class CouriersModule {}
