
<div class="flex-box">
  <div class="list-header col-xs-12"><span class="pr-3">Initial Order Calls ({{type}})</span></div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
        </div>
        <div class="content">
          <div class="fontS2 mt-3">
            <input #startDate type="date"/>
            <input class="mt-2" #endDate type="date"/>
            <button class="btn-xs mt-2" (click)="applyDateRange(startDate, endDate)">Apply</button>
            <button class="btn-xs btn-danger mt-2 ml-2" (click)="resetDateRange(startDate, endDate)" *ngIf="rangeStartDate || rangeEndDate">Reset</button>
          </div>
        </div>
      </div>
      <div class="list-content">
        <iframe class="w-full h-full" *ngIf="iframeLink" [src]="iframeLink" allowtransparency="allowtransparency"></iframe>
      </div>
    </div>
  </div>
</div>
