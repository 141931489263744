import { OnChanges, SimpleChanges, EventEmitter, Output, Component, Input } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { Broadcaster } from 'src/components/broadcaster';
import { ConnectionService } from 'src/services/connection-service';
import { WindowRefService } from 'src/services/window-ref-service';
import { ImageConfig, InstantCheckupInterface } from '../checkup';

@Component({
  selector: 'app-auto-face',
  templateUrl: './auto-face.component.html',
  styleUrls: ['./auto-face.component.scss'],
})
export class AutoFaceComponent {
  instantCheckupIds: string[];
  @Input('instantCheckupIds')
  set onUpdateInstantCheckupId(instantCheckupIds: string[]) {
    this.instantCheckupIds = instantCheckupIds;
    this.onUpdateInstantCheckupIds(this.instantCheckupIds);
  }
  @Input() username: string;
  @Output() selectBeforeImage: EventEmitter<boolean> = new EventEmitter();
  @Output() selectAfterImage: EventEmitter<boolean> = new EventEmitter();
  @Output() imageConfig: EventEmitter<{ imageConfig: { before: ImageConfig, after: ImageConfig }, beforeInstantId: string,
    afterInstantId: string }> = new EventEmitter();
  @Output() showManualEditSection: EventEmitter<boolean> = new EventEmitter();

  instantCheckupBefore: any;
  instantCheckupAfter: any;
  imageConfigObject: any = {};
  clicked: number = 0;
  listner: any;
  constructor(
    private windowRef: WindowRefService,
    private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig) { }

  async onUpdateInstantCheckupIds(instantCheckupIds_: string[]): Promise<void> {
    this.instantCheckupIds = instantCheckupIds_;
    if (this.instantCheckupIds?.length === 0) {
      this.instantCheckupBefore = null;
      this.instantCheckupAfter = null;
      return;
    }
    if (!this.username) return;
    if ((this.instantCheckupIds.length !== 2)) return;
    const instantCheckups = await this.conn.getInstantCheckUpWith(
      this.username,
      [...this.instantCheckupIds],
      ['imagePath', 'compressedImagePath', 'aiResponse', 'dimension', 'type']);
    if ((new Date(instantCheckups[0].createdAt)) < (new Date(instantCheckups[1].createdAt))) {
      instantCheckups.reverse();
    }

    if (!instantCheckups[0]?.aiResponse?.googleResponse?.faceAnnotations?.[0]
      || !instantCheckups[1]?.aiResponse?.googleResponse?.faceAnnotations?.[0]) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Image don\'t have AI detections. Select other set of images!',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.showManualEditSection.emit(true);
      return;
    }

    this.instantCheckupBefore = instantCheckups[1];
    this.instantCheckupAfter = instantCheckups[0];
  }

  slideReady(event: Event, canvas: HTMLCanvasElement, slider: HTMLDivElement): void {
    event.preventDefault();
    this.clicked = 1;
    this.listner = this.slideMove(canvas, slider);
    (<Window> this.windowRef.nativeWindow).addEventListener('mousemove', this.listner);
  }

  slideFinish(event: any): void {
    this.clicked = 0;
    (<Window> this.windowRef.nativeWindow).removeEventListener('mousemove', this.listner);
  }

  slideMove(canvas: HTMLCanvasElement, slider: HTMLDivElement): any {
    return (event: Event): void => {
      if (this.clicked === 0) return;
      const mousePosition = this.getCursorPos(event, canvas);
      // eslint-disable-next-line no-param-reassign
      canvas.style.clip = `rect(0px, ${mousePosition}px, 360px, 0px)`;
      // eslint-disable-next-line no-param-reassign
      slider.style.left = `${mousePosition - 2}px`;
    };
  }

  getCursorPos(event: any, canvas: HTMLCanvasElement): number {
    let x = 0;
    const eventParam = (event.changedTouches) ? event.changedTouches[0] : event;
    const boundingRect = canvas.getBoundingClientRect();
    x = eventParam.pageX - boundingRect.left;
    x -= this.windowRef.nativeWindow.pageXOffset;
    return x;
  }

  setImageConfig(config: any, key: 'before' | 'after'): void {
    this.imageConfigObject[key] = config;
    if (this.imageConfigObject?.before && this.imageConfigObject?.after) {
      this.imageConfig.emit({
        imageConfig: this.imageConfigObject,
        beforeInstantId: this.instantCheckupBefore.objectId,
        afterInstantId: this.instantCheckupAfter.objectId,
      });
    }
  }
}
