import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CannedResponseComponent } from './canned-response.component';
import { CannedResponseEditModule } from './canned-response-edit';
import { ListModule } from './canned-response-list';
import { CannedResponseResolve } from './canned-response-resolver';

const ROUTES: Routes = [
  {
    path: 'cannedResponses',
    component: CannedResponseComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'cannedResponses/new',
    component: CannedResponseComponent,
    loadChildren: (): any => CannedResponseEditModule,
    pathMatch: 'full',
  },
  {
    path: 'cannedResponses/:id/edit',
    component: CannedResponseComponent,
    loadChildren: (): any => CannedResponseEditModule,
    resolve: { cannedResponse: CannedResponseResolve },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [CannedResponseResolve],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [CannedResponseComponent],
  exports: [CannedResponseComponent] })
export class CannedResponseModule {}
