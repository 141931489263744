import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { RouterModule, Routes } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../../components/directives.module';
import { ShipNowComponent } from './ship-now.component';

const ROUTES: Routes = [
  {
    path: '',
    component: ShipNowComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FileUploadModule,
    DirectivesModule,
    MomentModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [ShipNowComponent],
  exports: [ShipNowComponent],
})
export class OrderShipNowModule {}
