import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostRegimenComponent } from './post-regimen.component';
import { PostRegimenEditModule } from './edit';
import { TreeResolver } from '../../tree-resolver';

const ROUTES: Routes = [
  {
    path: 'postRegimens/new',
    component: PostRegimenComponent,
    loadChildren: (): any => PostRegimenEditModule,
    pathMatch: 'full',
  },
  {
    path: 'postRegimen/:postRegimenId/edit',
    component: PostRegimenComponent,
    loadChildren: (): any => PostRegimenEditModule,
    resolve: { subTree: TreeResolver },
    pathMatch: 'full',
  },
  {
    path: 'answer_independent/:answerIndependentTreeId/edit',
    component: PostRegimenComponent,
    loadChildren: (): any => PostRegimenEditModule,
    resolve: { subTree: TreeResolver },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [TreeResolver],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [PostRegimenComponent],
  exports: [PostRegimenComponent] })
export class PostRegimenModule {}
