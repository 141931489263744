import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectionService } from 'src/services/connection-service';
import { Broadcaster } from 'src/components/broadcaster';
import { WindowRefService } from 'src/services/window-ref-service';
import { AppConfig } from 'src/app/app.config';
import { VariantCardComponent } from './components/variant-card/variant-card.component';

@Component({
  selector: 'sales-regimen-variants',
  standalone: true,
  imports: [VariantCardComponent, CommonModule],
  templateUrl: './sales-regimen-variants.component.html',
  styleUrl: './sales-regimen-variants.component.scss',
})
export class SalesRegimenVariantsComponent {
  @Input('regimen') regimen: any;
  @Input('order') order: any;
  selectedIndex: number = 0;
  regimenData: any = [{
    price: 1500,
    noOfProducts: 4,
    products: '',
    otherInfo: ['Improvement starts in in 4-6 weeks'],
  },
  {
    price: 2000,
    noOfProducts: 5,
    products: '',
    otherInfo: ['Improvement starts in in 3-4 weeks'],
  },
  {
    price: 3000,
    noOfProducts: 5,
    products: '',
    otherInfo: ['Improvement starts in in 3-4 weeks', '1:1 doctor consultation'],
  }];

  constructor(private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
    private window: WindowRefService,
  ) { }

  ngOnChanges(): void {
    if (this.regimen) {
      this.setupRegimenInfo(this.regimen.variants);
      this.selectedIndex = this.checkForSelectedRegimenVariant();
    }
  }

  setupRegimenInfo(variants: any): void {
    for (let i = 0; i < variants.length - 1; i += 1) {
      this.regimenData[i].price = variants[i].fixedPrice;
      this.regimenData[i].noOfProducts = variants[i].products.length;
      this.regimenData[i].products = variants[i].products.map((product: any): any => ` ${product.title}`);
    }
  }

  markCardSelection(index: any): void {
    this.selectedIndex = index;
  }

  checkForSelectedRegimenVariant(): number {
    const { variants, fixedPrice }: { variants: any, fixedPrice: any } = this.regimen;
    return variants.findIndex((variant: any): boolean => variant.fixedPrice === fixedPrice);
  }

  async onChangeVariant(): Promise<void> {
    const selectedVariant = this.regimen.variants[this.selectedIndex];
    if (confirm('Are you sure to change variant')) {
      try {
        await this.conn.changeRegimenVariant(this.order.regimen.regimenId, selectedVariant.variantId);
        this.broadcaster.broadcast('NOTIFY', {
          message: 'variant updated successfully',
          type: this.appConfig.Shared.Toast.Type.SUCCESS,
        });
        this.window.nativeWindow.location.reload();
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', {
          message: error.message,
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
      }
    } else {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'variant not updated',
        type: this.appConfig.Shared.Toast.Type.WARNING,
      });
    }
  }
}
