import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { Broadcaster } from '../broadcaster';

@Component({ selector: 'image-comparison-popup', templateUrl: './image-comparison-popup.html' })
export class ImageComparisonPopup {
  imageConfig: any;

  constructor(public dialogRef: MatDialogRef<ImageComparisonPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appConfig: AppConfig,
    private conn: ConnectionService,
    private broadcast: Broadcaster) {
  }

  onImagePositionChange(config: any): void {
    this.imageConfig = config;
  }

  changePhoto(instantCheckup: any): void {
    this.data.params.id2 = instantCheckup.objectId;
  }

  async hideModal(): Promise<any> {
    this.dialogRef.close();
  }

  updateImageConfig(): void {
    this.dialogRef.close({ config: this.imageConfig, params: this.data.params });
  }
}
