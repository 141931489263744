import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Table } from 'api-client';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionService } from '../../../../../services/connection-service';
import { Broadcaster } from '../../../../../components/broadcaster';
import { AppConfig } from '../../../../app.config';
import {
  PendingCallRecentHistoryComponent,
} from '../../../../calls/pending-calls/pending-call-recent-history/pending-call-recent-history.component';
import { PendingCallCompleteComponent } from '../../../../calls/pending-calls/pending-call-complete/pending-call-complete.component';

@Component({
  selector: 'waiting-call-action',
  templateUrl: './waiting-call-action.html',
})
export class WaitingCallsActionComponent implements ICellRendererAngularComp {
  private id: string;
  params: ICellRendererParams;
  primaryNumber: string;
  secondaryNumber: string;
  completed: boolean = false;

  constructor(
    private conn: ConnectionService,
    private appConfig: AppConfig,
    private broadcaster: Broadcaster,
    private dialog: MatDialog,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (!this.params.data) {
      return;
    }
    this.id = params.value;
    this.primaryNumber = this.params.data.user?.MobileNumber || this.params.data.referenceId;
    this.secondaryNumber = this.params.data.user?.alternateNumber;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  async callUserThroughExotel(number: any): Promise<void> {
    try {
      const pendingCall = new Table.PendingCall();
      pendingCall.id = this.id;
      await this.conn.callThroughExotel(number, 'chatPage', pendingCall);
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Connecting',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Unable to connect to exotel',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  openRecentPendingCallHistory(): void {
    this.dialog.open(PendingCallRecentHistoryComponent, {
      width: '95%',
      data: { userId: this.params.data.user.objectId },
    });
  }

  markCallCompleted(): void {
    this.dialog
      .open(PendingCallCompleteComponent, {
        width: '50%',
        data: {
          objectId: this.params.value,
          type: this.params.data.type,
          referenceId: this.params.data.referenceId,
        },
      })
      .afterClosed()
      .subscribe(async (result: boolean) => {
        if (!result) {
          return;
        }
        this.completed = true;
      });
  }
}
