import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from '../../../../components/directives.module';
import { OpenSupportTicketsEditComponent } from './open-support-tickets-edit.component';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { InputSelectModule } from '../../../../components/input/input-select';
import { SaveSbarHistoryComponent } from './save-sbar-history/save-sbar-history.component';
import { SBARModule } from '../../view/SBAR';

const ROUTES: Routes = [
  { path: '', component: OpenSupportTicketsEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    LanguageStringSelectionModule,
    InputTextModule,
    InputCheckboxModule,
    InputSelectModule,
    RouterModule.forChild(ROUTES),
    SBARModule,
    MatDialogModule,
  ],
  declarations: [OpenSupportTicketsEditComponent, SaveSbarHistoryComponent],
  exports: [OpenSupportTicketsEditComponent],
})
export class OpenSupportTicketsEditModule {
}
