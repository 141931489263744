import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConsultationSessionsList } from 'src/app/chat/view/consultation-dialog';
import { Broadcaster } from 'src/components/broadcaster';
import { ApiClientConstant, Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';

@Component({
  selector: 'question-view',
  templateUrl: './question-view.html',
  styleUrls: ['./question-view.scss'],
})
export class QuestionViewComponent implements OnDestroy {
  questionObj: any;
  userId: string = '';
  questionType: number = 1;
  subscriptions: Array<Subscription>;
  private trees: Array<any> = [];
  constructor(
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private conn: ConnectionService,
    public dialog: MatDialog,
    private broadcaster: Broadcaster) {
  }

  ngOnInit(): void {
    this.subscriptions = [];
    this.questionObj = new Table.Question();
    this.subscribeToUrlQueryParams();
  }

  async subscribeToUrlQueryParams(): Promise<void> {
    this.subscriptions.push(this.route.parent.params.subscribe(async () => {
      if (this.route.parent.snapshot.data.question) {
        this.questionObj = this.route.parent.snapshot.data.question;
        switch (this.questionObj.get('inputs').length) {
          case 0:
            this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR;
            break;
          case 1:
            if (this.questionObj.get('inputs')[0].type === ApiClientConstant.Question.InputType.TEXT) {
              this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.TEXT_INPUT;
            } else this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR;
            break;
          default:
            this.questionType = this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR;
        }
        await this.fetchTrees();
      }
    }));
  }

  async fetchTrees(): Promise<void> {
    this.trees = await this.conn.fetchTreeHavingQuestion(this.questionObj);
  }

  getQuestionType(): string {
    switch (this.questionType) {
      case this.appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR: return 'Single Selector';
      case this.appConfig.ChatConstants.QUESTION_TYPES.MESSAGE: return 'Message';
      default: return 'Text Input';
    }
  }

  async sendToUser(): Promise<any> {
    const user = await this.conn.getUserByUserName(this.userId);
    if (!user) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Invalid Username',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    this.broadcaster.broadcast('ChatUserUpdate', { user });
    const userConsultationsSessions = await this.conn.fetchConsultationSessions({
      where: { user, archive: false },
      project: ['PrivateMainConcernClass'],
    });
    if (!userConsultationsSessions.length) {
      this.broadcaster.broadcast('NOTIFY', { message: 'No ConsultationSessions Found',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const dialogRef = this.dialog.open(ConsultationSessionsList, { data: { consultationsList: userConsultationsSessions } });
    dialogRef.afterClosed().subscribe(async (selectedConsultation: string) => {
      if (!selectedConsultation) return;
      const context = { consultationSessionId: selectedConsultation };
      await this.conn.sendQuestionTo(this.questionObj.id, this.userId, context);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.route;
    delete this.appConfig;
    delete this.subscriptions;
    delete this.questionObj;
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
