import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'user-list-dialog', templateUrl: './userList.html' })
export class UserListDialog {
  userList: Array<any>;

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private dialogRef: MatDialogRef<UserListDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit(): void {
    this.userList = this.data.user;
  }

  selectUser(index: number): void {
    this.dialogRef.close(this.userList[index]);
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
