import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TeamSearchComponent } from './team-search.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule, MatAutocompleteModule],
  declarations: [TeamSearchComponent],
  exports: [TeamSearchComponent],
})
export class TeamSearchModule {}
