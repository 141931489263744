import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConnectionService } from '../../../services/connection-service';

@Injectable()
export class ServiceResolve {
  constructor(private conn: ConnectionService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.conn.getService(route.params.id)
      .then((serviceObj: any): any => {
        if (serviceObj) return Promise.resolve(serviceObj);
        this.router.navigate(['/products/catalogs']);
        return false;
      });
  }
}
