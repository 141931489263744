/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { Broadcaster } from '../broadcaster';
import { AppConfig } from '../../app/app.config';
import { WindowRefService } from '../../services/window-ref-service';
import { UploadFileService } from '../../services/uploadFileService/uploadFile.service';
import { HelperService } from '../../services/helper-service';

@Component({
  selector: 'file-picker',
  templateUrl: './file-picker.html',
  styleUrls: ['./file-picker.scss'],
})
export class FilePickerComponent {
  @Output() onFileChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUploadingFile: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onRemoveFile: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set data(data: any) {
    if (!data || !data.type) return;
    this.payload = { ...data };
    if (data.type === ApiClientConstant.Feed.Type.Video) {
      this.videoSrc = data.url || data.src;
      this.thumbnailSrc = data.thumbnailUrl || data.thumbnailSrc;
    } else if (this.payload.type === ApiClientConstant.Feed.Type.Image) {
      this.imageSrc = data.url || data.src;
    }
  }
  accept: string = '.gif,.jpeg,.png,.jpg,.mp4';
  imageSrc: any;
  videoSrc: any;
  thumbnailSrc: any;
  MAX_IMAGE_FILE_SIZE_IN_MB: number = 5 * 1024 * 1024;
  MAX_VIDEO_FILE_SIZE_IN_MB: number = 20 * 1024 * 1024;
  username: string;
  payload: any = {};
  file: File;
  id: string = uuid();
  mediaElement: HTMLImageElement | HTMLVideoElement;
  constructor(private conn: ConnectionService,
    private broadcasterService: Broadcaster,
    private appConfig: AppConfig,
    private window: WindowRefService,
    private uploadFileService: UploadFileService,
    private helperService: HelperService) {
    this.username = this.conn.getCurrentUser().get('username');
  }

  async onFileUpload(event: any): Promise<void> {
    this.file = event.target.files[0];
    if (!this.file) return;
    const reader = new FileReader();
    reader.onload = async (data: any): Promise<void> => {
      this.payload.src = data.target.result;
      if (this.file.type.includes('image') || this.file.type.includes('gif')) {
        this.imageSrc = data.target.result;
        this.payload.type = ApiClientConstant.Feed.Type.Image;
      } else {
        this.videoSrc = data.target.result;
        this.payload.type = ApiClientConstant.Feed.Type.Video;
      }
      const MAX_FILE_SIZE: number = this.payload.type === ApiClientConstant.Feed.Type.Video
        ? this.MAX_VIDEO_FILE_SIZE_IN_MB
        : this.MAX_IMAGE_FILE_SIZE_IN_MB;
      if (this.file.size > MAX_FILE_SIZE) {
        this.broadcasterService.broadcast('NOTIFY', {
          message: 'File size should be less than 5MB',
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
        this.reset();
      } else if (this.file.type.includes('image') && this.file.size > 200000) {
        this.broadcasterService.broadcast('NOTIFY', {
          message: 'Image file size should be less than 200 KB',
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
        this.reset();
      }
    };
    reader.readAsDataURL(this.file);
  }

  async isValidAspectRatio(element: HTMLImageElement | HTMLVideoElement): Promise<void> {
    this.mediaElement = element;
    const fileWidth = element.width || (<HTMLVideoElement>element).videoWidth;
    const fileHeight = element.height || (<HTMLVideoElement>element).videoHeight;
    const ratioOfHeightToWidth = (fileHeight / fileWidth);

    if (ratioOfHeightToWidth !== 1 && ratioOfHeightToWidth !== 1.25 && ratioOfHeightToWidth !== 0.5625) {
      this.reset();
      this.broadcasterService.broadcast('NOTIFY', {
        message: 'Ratio of file should be either 1:1 / 4:5 / 16:9.',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return;
    }

    this.payload.aspectRatio = `1:${ratioOfHeightToWidth}`;
    if (this.file && !this.payload.url) {
      await this.uploadFeedDisplayContent('PRODUCT');
      this.payload.file = this.file;
      this.onFileChange.emit(this.payload);
    }
  }

  async uploadFeedDisplayContent(bucket: string): Promise<void> {
    this.onUploadingFile.emit(true);
    try {
      const result = await this.uploadFileService.uploadFileViaSignedURL({
        file: this.file,
        bucket,
        username: this.username,
        source: 'doctor_feeds',
      });
      this.payload.url = result.publicURL;
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', {
        message: 'Upload failed. Retry',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      this.reset();
    }
    this.onUploadingFile.emit(false);
  }

  async captureThumbnail(): Promise<void> {
    const canvas = this.window.nativeWindow.document.createElement('canvas');
    canvas.width = (<HTMLVideoElement> this.mediaElement).videoWidth;
    canvas.height = (<HTMLVideoElement> this.mediaElement).videoHeight;
    canvas.getContext('2d').drawImage(
      this.mediaElement,
      0,
      0,
      canvas.width,
      canvas.height);
    this.thumbnailSrc = canvas.toDataURL('image/jpeg');
    this.payload.thumbnailSrc = this.thumbnailSrc;
  }

  async uploadThumbnail(): Promise<void> {
    if (this.payload.file) this.onUploadingFile.emit(true);
    try {
      const blob = this.helperService.convertDataURLtoBlob(this.thumbnailSrc);
      const file = new File([blob], `${this.conn.getUniqueId()}.jpeg`);
      const result = await this.uploadFileService.uploadFileViaSignedURL({
        file,
        bucket: 'OTHER',
        username: this.username,
        source: 'doctor_feeds',
      });
      this.payload.thumbnailUrl = result.publicURL;
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', {
        message: error.toString(),
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      delete this.thumbnailSrc;
      delete this.payload.thumbnailSrc;
    }
    this.onFileChange.emit(this.payload);
    if (this.payload.file) this.onUploadingFile.emit(false);
  }

  removeFile(): void {
    this.onRemoveFile.emit(true);
  }

  reset(): void {
    delete this.file;
    delete this.videoSrc;
    delete this.imageSrc;
    delete this.thumbnailSrc;
    this.payload = {};
  }
}
