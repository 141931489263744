import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'type-filter',
  templateUrl: './type-filter.html',
})

export class TypeFilterComponent {
  @Output() updatedTypeList: EventEmitter<any> = new EventEmitter();
  @Input() set reset(status: boolean) {
    this.resetting = status;
    if (status) this.resetSelection();
  }
  @Input('teams') teams:Array<string> = [];
  userRoles: Array<string> = [];
  types: Array<string> = [];
  rows: Array<{ name: any, selected?: boolean }> = [];
  currentUser: any;
  resetting: boolean = false;
  roleTypeMapping: { [key: string]: string[] } = {
    sales: [
      ApiClientConstant.PendingCall.Type.CanceledOrder,
      ApiClientConstant.PendingCall.Type.InitialOrder,
      ApiClientConstant.PendingCall.Type.MissedCall,
      ApiClientConstant.PendingCall.Type.OnlinePaymentPending,
      ApiClientConstant.PendingCall.Type.ConsultationOrder,
    ],
    chatSupport: [
      ApiClientConstant.PendingCall.Type.MissedCall,
      ApiClientConstant.PendingCall.Type.OrderConfirmation,
    ],
    shipping: [
      ApiClientConstant.PendingCall.Type.AddressConfirmation,
      ApiClientConstant.PendingCall.Type.MissedCall,
    ],
    checkIn: [
      ApiClientConstant.PendingCall.Type.MissedCall,
      ApiClientConstant.PendingCall.Type.CheckIn,
    ],
    diet: [
      ApiClientConstant.PendingCall.Type.DietCall,
    ],
    initialTeam: [
      ApiClientConstant.PendingCall.Type.InitialOrder,
      ApiClientConstant.PendingCall.Type.DoctorOrder,
      ApiClientConstant.PendingCall.Type.ReacquisitionCall,
      ApiClientConstant.PendingCall.Type.MissedCall,
      ApiClientConstant.PendingCall.Type.UserRequestedCall,
    ],
    intern: [
      ApiClientConstant.PendingCall.Type.OrderConfirmation,
      ApiClientConstant.PendingCall.Type.TeenagerConsentConfirmation,
    ],
    waitingForImage: [
      ApiClientConstant.PendingCall.Type.MissedCall,
    ],
    followUp: [],
    doctor: [],
  };

  constructor(private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.updateFilters();
    this.sendUpdatedList();
  }

  updateFilters(): void {
    const filteredTypes = this.getValuesForMappedTeams();
    if (filteredTypes.length === 0) {
      this.types = Object.keys(ApiClientConstant.PendingCall.Type).map((key: string) => ApiClientConstant.PendingCall.Type[key]);
    } else {
      this.types = filteredTypes;
    }
    this.rows = [];
    this.types.forEach((value: string): void => {
      this.rows.push({
        name: value,
        selected: false,
      });
    });
  }

  ngOnChanges(): void {
    this.updateFilters();
  }

  private getMappedTeamsTypes(): string[][] {
    return this.teams
      .filter((team: any) => Object.prototype.hasOwnProperty.call(this.roleTypeMapping, team))
      .map((team: any) => this.roleTypeMapping[team]);
  }

  private hasEmptyTypes(values: string[][]): boolean {
    return values.some((types: any) => types.length === 0);
  }

  getValuesForMappedTeams(): string[] {
    const values = this.getMappedTeamsTypes();
    if (this.hasEmptyTypes(values)) {
      return [];
    }
    // converts [[1,2], [2]] into [1,2,2]
    const flatList = values.flat();
    const uniqueValuesList = new Set(flatList);
    return [...uniqueValuesList];
  }

  updateTypes(): void {
    this.rows = [];
    if (this.types.length) {
      this.types.forEach((value: string): void => {
        this.rows.push({
          name: value,
          selected: false,
        });
      });
    }
  }

  sendUpdatedList(): void {
    if (this.resetting) {
      return;
    }
    this.updatedTypeList.emit(this.rows.filter(({ selected }: any) => selected).map(({ name }: any) => name));
  }

  toggleSelection(r: { name: any, selected: boolean }): any {
    const row = r;
    row.selected = !row.selected;
    this.sendUpdatedList();
  }

  resetSelection(): void {
    this.types.forEach((value: string): void => {
      this.rows.push({
        name: value,
        selected: false,
      });
    });
    this.resetting = false;
    this.sendUpdatedList();
  }
}
