
<mat-dialog-content class="mat-dialog-content max-height-75vh">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Comparison</h4>
    <div class="pull-right"><i class="fas fa-times fontL3" (click)="dialogRef.close()"></i></div>
  </div>
  <div class="modal-body mb-0 max-height-75vh">
    <comparison-slider [params]="data.params" (onZoomChange)="onImagePositionChange($event)" (onChangePhoto)="changePhoto($event)"></comparison-slider>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" (click)="updateImageConfig()">Save</button>
  </div>
</mat-dialog-content>