
<mat-dialog-content class="new-mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Changes in regimen</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body mt-0">
    <div class="col-xs-12 bdr-btm" *ngIf="actionLog && actionLog.length">
      <div class="col-xs-12 px-4 mb-2" *ngFor="let item of actionLog;">
        <div class="col-xs-4">{{item.get('Action')}}</div>
        <div class="col-xs-4">{{item.get("createdAt")}}</div>
        <div class="col-xs-4">{{item.get('ByUser') ? item.get('ByUser').get('username') : ''}}</div>
      </div>
    </div>
  </div>
</mat-dialog-content>
