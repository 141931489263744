import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ConnectionService } from '../../services/connection-service';

@Component({ selector: 'catalog-selection-modal', templateUrl: './catalog-selection.html' })
export class CatalogSelectionComponent {
  autoCompleteController: UntypedFormControl = new UntypedFormControl();
  options: Observable<Array<{ name: string; object: any }>>;
  image: string = '';

  constructor(private conn: ConnectionService, private dialogRef: MatDialogRef<CatalogSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.autoCompleteController.setValue('');
    this.options = this.autoCompleteController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getCatalogs(token)));
  }

  async getCatalogs(name: string): Promise<any> {
    return this.conn.findCatalogs({
      where: { title: { $regex: name, $options: 'i' }, type: 'main' },
      limit: 10,
    })
      .then((catalogs: any) => catalogs
        .map((object: any) => ({ name: this.getProductDisplayName(object), object })));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${product.get('quantity')}${product.get('quantityUnit')} ]`;
  }

  hideModal(data: any): void {
    this.dialogRef.close(data);
  }

  autoCompleteOnSelect(item: { name: string, object: any }): void {
    this.hideModal(item.object);
  }
}
