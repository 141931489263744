import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({ selector: 'team-search', templateUrl: './team-search.html' })
export class TeamSearchComponent {
  autoCompleteController: UntypedFormControl = new UntypedFormControl();
  options: Observable<Array<{ name: string; object: any }>>;
  image: string = '';
  excludeTeams: Array<string>;
  teams: Array<string> = [];

  constructor(
    private appConfig: AppConfig,
    private conn: ConnectionService,
    private dialogRef: MatDialogRef<TeamSearchComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.autoCompleteController.setValue('');
    this.excludeTeams = this.data.excludeTeams || [];
    this.appConfig.Shared.Team.Name
      .filter((each: string) => !this.excludeTeams.includes(each))
      .map((name: string) => this.teams.push(name));
    this.options = this.autoCompleteController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getTeams(token)));
  }

  async getTeams(name: string): Promise<any> {
    if (!name) {
      return [];
    }
    return this.teams
      .filter((team: string) => new RegExp(name, 'i').exec(team))
      .map((team: string) => ({ name: team, value: team }));
  }

  hideModal(data: any): void {
    this.dialogRef.close(data);
  }

  autoCompleteOnSelect(item: { name: string, value: string }): void {
    this.hideModal(item.value);
  }
}
