
<mat-dialog-content class="mat-dialog-content" *ngIf="ticketHistory">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Ticket History</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body mt-0">
    <div class="col-xs-12 mt-3 text-center" *ngIf="!ticketHistory.length">No History found</div>
    <div class="col-xs-12 bdr-btm pb-3 bold" *ngIf="ticketHistory.length">
      <div class="col-xs-2">Status</div>
      <div class="col-xs-2">CreatedBy</div>
      <div class="col-xs-2">AssignedAt</div>
      <div class="col-xs-2">Team</div>
      <div class="col-xs-2">CompletedBy</div>
      <div class="col-xs-2">AttendedAt</div>
    </div>
    <div class="col-xs-12 mt-3" *ngFor="let ticket of ticketHistory">
      <div class="col-xs-2">{{ticket.get('status')|| '-'}}</div>
      <div class="col-xs-2">{{ticket.get('createdBy')?.get('username') || '-'}}</div>
      <div class="col-xs-2">{{ticket.get('createdAt') | amDateFormat: 'MMM DD, YYYY hh:mm a'}}</div>
      <div class="col-xs-2">{{ticket.get('teams').join(',') || '-'}}</div>
      <div class="col-xs-2">{{ticket.get('completedBy')?.get('username') || '-'}}</div>
      <div class="col-xs-2">{{ticket.get('completedAt') | amDateFormat: 'MMM DD, YYYY hh:mm a'}}</div>
    </div>
  </div>
</mat-dialog-content>