import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DoctorComponent } from './doctor.component';
import { DoctorEditModule } from './edit';
import { ListModule } from './list';
import { EditModule } from '../internal-user/edit';

const ROUTES: Routes = [
  {
    path: 'doctors',
    component: DoctorComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'doctor/:id/edit',
    component: DoctorComponent,
    loadChildren: (): any => DoctorEditModule,
    pathMatch: 'full',
  },
  {
    path: 'doctors/new',
    component: DoctorComponent,
    loadChildren: ():any => EditModule,
    pathMatch: 'full',
  },
];

@NgModule({ providers: [],
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [DoctorComponent],
  exports: [DoctorComponent] })
export class DoctorModule {}
