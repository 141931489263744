
<div class="col-xs-12 p-0 m-0" *ngIf="orderRefundObj">
  <form #refundForm="ngForm" (submit)="updateRefundStatus()">
    <div class="col xs-12 font-20 bold pl-0 py-1">Refund Status Update</div>
    <div class="col-xs-12 flex m-t-sm">
      <input-select class="col-xs-4" [parseObj]="orderRefundObj" name="status" [options]="options" [required]="true" [class]="'p-1 input-sm'"></input-select>
      <input-text class="ml-2" *ngIf="['REFUND_INITIATED', 'REFUND_PROCESSED'].includes(orderRefundObj.get('status'))" [parseObj]="orderRefundObj" name="amount" type="number" [required]="true" [class]="'p-1 input-sm'"></input-text>
      <button class="btn-xxs m-l-sm" type="submit" [disabled]="!refundForm.valid">Update</button>
    </div>
  </form>
</div>