
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading" #headingView>
    <div class="col-xs-12 list-header pl-4">
      <div class="pull-left"><span class="pR10">Media Link ({{count || 0}})</span></div>
      <div class="pull-right"><i class="fa fa-plus-square-o mr-3 pointer" routerLink="/products/medialink/new"></i></div>
    </div>
  </div>
  <div class="flex-content"></div>
  <div class="list-view">
    <div class="list-sidebar">
      <div class="header">
        <div class="title">Filters</div>
        <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
      </div>
      <div class="content p-0">
        <div class="mb-3 px-3 flex" *ngIf="products.length">
          <label class="col-xs-2">Products
            <div class="col-xs-12 m-31 flex" *ngFor="let product of products;let i = index;"><a class="inline product-label night fontS3" routerLink="/products/catalog/{{product.id}}">{{product.get('title')}}</a><i class="fa fa-trash-o pointer" (click)="removeProduct(i)"></i></div>
          </label>
        </div>
        <div class="mb-3 px-3">
          <label class="db">Search
            <input class="form-control" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto" required="required" (ngModelChange)="reset()"/>
            <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
              <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option)">{{option.name}}</mat-option>
            </mat-autocomplete>
          </label>
        </div>
        <section class="list-color-holder"><span class="list-colors-circle list-label-bg-red" data-tooltip-text="Doctor call needed" data-tooltip-pos="right"></span><span class="list-colors-circle list-label-bg-yellow" data-tooltip-text="Voice note needed" data-tooltip-pos="right"></span></section>
      </div>
    </div>
    <div class="list-content">
      <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
    </div>
  </div>
</div>
