import { Component, OnInit } from '@angular/core';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';

@Component({
  selector: 'FAQ',
  template: '<router-outlet></router-outlet>',
})

export class FAQComponent implements OnInit {
  newFAQCategory: string;
  FAQIds: Array<any>;
  addId: any;
  editId: boolean = false;
  keywords: Array<any>;
  FAQId: any;
  keyword: any;
  question: any;
  answer: any;
  faqSections: Array<any> = [];
  faqCategories: Array<any> = [];
  editFaq: any;
  faqs: any;
  faqQAndA: Array<any>;
  constructor(private conn: ConnectionService, private document: WindowRefService) {}
  async ngOnInit(): Promise<any> {
    this.FAQIds = [];
    this.addId = false;
    this.editId = false;
    this.loadFAQIds();
    let [sectionTag]: any[] = await this.conn.getLanguageTagByName(['faq_section']);
    this.faqSections = await this.conn.getLanguageStringByTag(sectionTag);
    [sectionTag] = await this.conn.getLanguageTagByName(['faq']);
    this.faqQAndA = await this.conn.getLanguageStringByTag(sectionTag);
  }

  addNewId(): boolean {
    return this.addId = !this.addId;
  }

  addNewFAQCategory(): void {
    const duplicateCategory = this.faqCategories.find((each: any) => each.get('titleLanguageString').id === this.newFAQCategory);
    if (duplicateCategory) {
      alert('Category Already Exists.');
      this.addId = !this.addId;
      return;
    }
    const langString = this.faqSections.find((each: any) => each.id === this.newFAQCategory);
    this.conn.saveFAQCategory({ title: langString.get('en'), titleLanguageString: langString })
      .then(() => {
        this.reset();
      });
  }

  loadFAQIds(): void {
    this.conn.getFAQCategories()
      .then((ids: any) => {
        this.faqCategories = ids;
        ids.forEach((item: any) => {
          this.FAQIds.push(item);
        });
      });
    this.conn.getFAQS()
      .then((ids: any) => {
        this.faqs = ids;
      });
  }

  async addQuestionInFAQ(): Promise<any> {
    if (typeof this.keyword === 'string') this.keywords = this.keyword.split(',');
    else this.keywords = this.keyword;
    const id = this.FAQIds.find((each: any) => each.id === this.FAQId);
    const questionId = this.faqQAndA.find((each: any) => each.id === this.question);
    const answerId = this.faqQAndA.find((each: any) => each.id === this.answer);
    if (this.editId) {
      this.editFaq.set('question', questionId.get('en'));
      this.editFaq.set('answer', answerId.get('en'));
      this.editFaq.set('questionLanguageString', questionId);
      this.editFaq.set('answerLanguageString', answerId);
      this.editFaq.set('keywords', this.keywords);
      this.editFaq.set('FAQId', id);
      await this.editFaq.save();
      this.reset();
      return;
    }
    await this.conn.saveQuestionInFAQ({ FAQId: id,
      question: questionId.get('en'),
      answer: answerId.get('en'),
      keywords: this.keywords,
      answerLanguageString: answerId,
      questionLanguageString: questionId });
    this.reset();
  }

  edit(item: any): void {
    this.editFaq = item;
    this.editId = true;
    this.FAQId = item.get('FAQId').id;
    this.question = item.get('questionLanguageString').id;
    this.answer = item.get('answerLanguageString').id;
    this.keyword = item.get('keywords');
  }

  async delete(item: any): Promise<any> {
    await item.destroy();
    this.reset();
  }

  reset(): any {
    setTimeout(() => this.document.nativeWindow.location.reload(), 1500);
  }
}
