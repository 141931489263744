import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { TreeComponent } from './tree.component';
import { QuestionModule } from './question';
import { TreeListModule } from './list';
import { TreeViewModule } from './view';
import { TreeEditModule } from './edit';
import { TreeResolver } from './tree-resolver';
import { MainConcernsModule } from './main-concerns';
import { TriggerByKeywordModule } from './trigger-by-keyword';

const ROUTES: Routes = [
  {
    path: 'trees',
    component: TreeComponent,
    loadChildren: (): any => TreeListModule,
    pathMatch: 'full',
  },
  {
    path: 'trees/new',
    component: TreeComponent,
    loadChildren: (): any => TreeEditModule,
  },
  {
    path: 'trees',
    component: TreeComponent,
    loadChildren: (): any => QuestionModule,
  },
  {
    path: 'tree/:name',
    component: TreeComponent,
    loadChildren: (): any => TreeViewModule,
    resolve: { tree: TreeResolver },
  },
  {
    path: 'tree/:name/edit',
    component: TreeComponent,
    loadChildren: (): any => TreeEditModule,
    resolve: { tree: TreeResolver },
  },
  {
    path: 'trees/main-concerns',
    component: TreeComponent,
    loadChildren: (): any => MainConcernsModule,
  },
  {
    path: 'trees/trigger-by-keyword',
    component: TreeComponent,
    loadChildren: (): any => TriggerByKeywordModule,
  },
];

@NgModule({ providers: [TreeResolver],
  imports: [
    BrowserModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [TreeComponent],
  exports: [TreeComponent] })
export class TreeModule {}
