import { Component, Input } from '@angular/core';
import { ApiClientConstant } from '@cureskin/api-client';
import moment from 'moment';
import { ServerAPIModels } from 'src/app/server-api.constants';
import { ConnectionService } from 'src/services/connection-service';

@Component({
  selector: 'login-logout-calendar',
  templateUrl: './login-logout-calendar.html',
  styleUrls: ['./login-logout-calendar.scss'],
})

export class LoginLogoutCalendarComponent {
  user: Table.User;
  @Input('user')
  set updateUser(user: Table.User) {
    this.user = user;
    this.updateWeeks();
    this.getStartAndEndDate();
    this.getWeekData();
    this.getExpectedLoginTime(this.user?.get('workTime') as typeof ApiClientConstant.User.WorkTime);
  }
  months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];
  weeks: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedWeek: number = 1;
  startDate: Date | null = null;
  endDate: Date | null = null;
  weekData: Array<{ login: string, logout: string }> = [];
  selectedDates: Date[] = [];
  today: Date = new Date();
  expectedLoginTime: string = '';

  constructor(private connectionService: ConnectionService) {}

  getExpectedLoginTime(workTime: typeof ApiClientConstant.User.WorkTime): void {
    if (workTime) {
      const workingDays = Object.keys(workTime);
      this.expectedLoginTime = workTime[workingDays[0]][0].startTime;
    }
  }

  async onMonthChange(): Promise<void> {
    this.updateWeeks();
    this.getStartAndEndDate();
    await this.getWeekData();
  }

  async onWeekChange(): Promise<void> {
    if (this.selectedMonth && this.selectedWeek) {
      this.getStartAndEndDate();
      await this.getWeekData();
    }
  }

  updateWeeks(): void {
    const monthIndex = this.months.indexOf(this.selectedMonth);
    const daysInMonth = new Date(new Date().getFullYear(), monthIndex + 1, 0).getDate();

    this.weeks = [];
    for (let i = 1; i <= Math.ceil(daysInMonth / 7); i += 1) {
      this.weeks.push(i);
    }
    this.selectedWeek = 1;
  }

  getStartAndEndDate(): void {
    const monthIndex = this.months.indexOf(this.selectedMonth);
    const year = new Date().getFullYear();
    const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
    const weekStartDay = (this.selectedWeek - 1) * 7 + 1;
    this.selectedDates = [];
    for (let i = 0; i < 7; i += 1) {
      const currentDay = weekStartDay + i;
      if (currentDay <= daysInMonth) {
        this.selectedDates.push(new Date(year, monthIndex, currentDay));
      }
    }
    this.startDate = this.selectedDates[0];
    this.endDate = this.selectedDates[this.selectedDates.length - 1];
  }

  async getWeekData(): Promise<void> {
    this.weekData = [];
    const response = await this.connectionService.getUserActivityData(
      this.user.id,
      this.startDate.toDateString(),
      this.endDate.toDateString(),
    );
    const activityData = response.data;
    activityData.result.forEach((item: ServerAPIModels['MonitoringDataInnerResponse']) => {
      this.weekData.push({
        login: item?.startOfTheDay ? moment(item.startOfTheDay).utcOffset('+05:30').format('HH:mm') : '',
        logout: item?.endOfTheDay ? moment(item.endOfTheDay).utcOffset('+05:30').format('HH:mm') : '',
      });
    });
  }

  hasLoggedInLate(loginTime: string): boolean {
    if (!this.expectedLoginTime) {
      return false;
    }
    const actualLoginTime = moment(loginTime, 'HH:mm').utcOffset('+05:30');
    const expectedLoginTime = moment(this.expectedLoginTime, 'HH:mm').utcOffset('+05:30');
    return actualLoginTime.diff(expectedLoginTime, 'minutes') > 15;
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }
}
