import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({ selector: 'media-link-edit', templateUrl: './marg-id-info-edit.html' })
export class MargIdInfoEditComponent implements OnDestroy {
  margIdInfoObj: any;

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private router: Router,
    private route: ActivatedRoute, private broadcaster: Broadcaster) {
  }

  async ngOnInit(): Promise<void> {
    this.margIdInfoObj = new Table.MargIdInfo();
    if (this.route.snapshot.params.id) {
      this.margIdInfoObj = await this.conn.findOneMargIdInfo({ where: { objectId: this.route.snapshot.params.id } });
    }
  }

  async saveMargIdInfo(): Promise<void> {
    if (!this.margIdInfoObj.get('genericName') || !this.margIdInfoObj.get('margId')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'data missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    try {
      this.margIdInfoObj.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'saved successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      await this.router.navigate(
        ['/products/margId'],
        { relativeTo: this.route });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.SUCCESS });
    }
  }

  ngOnDestroy(): void {
    delete this.router;
    delete this.appConfig;
    delete this.conn;
    delete this.route;
    delete this.margIdInfoObj;
  }
}
