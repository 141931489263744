
<section class="section w100" *ngIf="showFinasterideConsentForm">
  <div class="hbox">
    <div class="consent-form-padding">
      <div class="col-xs-12 title">Consent form</div>
      <div class="col-xs-12 ml-2"></div>
      <div class="col-xs-12 flex" style="align-items: baseline !important">
        <label class="db">Finasteride Consent Form</label>
        <button class="btn-xs btn-consent-form my-3 m-3" *ngIf="!isFinasterideConsent" (click)="sendConsentFormHandler(false)">SEND</button>
      </div>
      <div class="col-xs-12 mt-2 mb-5" *ngIf="isFinasterideConsent">
        <button class="btn-xs btn-danger m-3" (click)="cancelConsentFormSendingHandler()">Cancel</button>
        <p class="ml-4 alert-danger">*Finasteride Consent Form is Ready it will send to user along with FollowUp Report.</p>
        <div class="col-xs-3"></div>
      </div>
    </div>
  </div>
</section>
<section class="section w100" *ngIf="showIsotretConsentForm">
  <div class="hbox">
    <div class="consent-form-padding">
      <div class="col-xs-12 title">Consent form</div>
      <div class="col-xs-12 ml-2"></div>
      <div class="col-xs-12 flex" style="align-items: baseline !important">
        <label class="db">Isotret Consent Form</label>
        <button class="btn-xs btn-consent-form my-3 m-3" *ngIf="!isIsotretConsent" (click)="sendConsentFormHandler(true)">SEND</button>
      </div>
      <div class="col-xs-12 mt-2 mb-5" *ngIf="isIsotretConsent">
        <button class="btn-xs btn-danger m-3" (click)="cancelConsentFormSendingHandler()">Cancel</button>
        <p class="ml-4 alert-danger">*Isotret Consent Form is Ready it will send to user along with FollowUp Report.</p>
        <div class="col-xs-3"></div>
      </div>
    </div>
  </div>
</section>