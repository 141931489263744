import { Component, NgZone, OnDestroy } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { RequestQueryPayload, Table } from 'api-client';
import * as moment from 'moment';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  ui: any;
  count: number;
  constructor(private conn: ConnectionService,
              private zone: NgZone,
              private windowRef: WindowRefService) {
  }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 70,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const id = params.value || params.data.objectId;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pointer' target="_blank" href='/cannedResponses/${id}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'Created By',
      field: 'user.username',
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value ?? '-';
        return eDiv;
      },
      editable: true,
    }, {
      headerName: 'Title',
      field: 'title',
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value ?? '-';
        return eDiv;
      },
      editable: true,
    }, {
      headerName: 'Created On',
      field: 'createdAt',
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('DD MMM YY, HH:mm A') ?? '-';
        return eDiv;
      },
      editable: true,
    }, {
      headerName: 'Updated On',
      field: 'updatedAt',
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('DD MMM YY, HH:mm A') ?? '-';
        return eDiv;
      },
      editable: true,
      flex: 1,
    }];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.CannedResponse> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['user'],
      project: ['user.username' as 'user', 'title'],
      descending: 'createdAt',
    };
    let data;
    [data, this.count] = await Promise.all([
      this.conn.findCannedResponses(requestPayload).then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      this.conn.countCannedResponses(requestPayload),
    ]);
    return data;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
  }
}
