
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{productObj.get('title')}} ( {{productObj.get('type')}} )</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form class="col-xs-12" #instructionForm="ngForm">
      <div class="div" style="display: flex; flex-direction: column; border: 1px solid rgba(0, 0, 0, 0.3); padding: 15px;">
        <div class="div">
          <div class="col-xs-6" style="margin-bottom: 20px;">
            <label class="col-xs-12">Title (Not visible to user)</label>
            <div class="col-xs-12">
              <input class="form-control" [(ngModel)]="instruction.title" name="title" required="required"/>
            </div>
          </div>
        </div>
        <div class="div" style="display: flex;">
          <div class="div col-xs-4" style="display: flex; flex-direction: column;">
            <label class="col-xs-12" style="margin-top: 20px !important;">Quantity Image</label>
            <div class="col-xs-12">
              <input-image-upload [parseObj]="instruction" name="quantityImage" [required]="false"></input-image-upload>
            </div>
            <div class="col-xs-12">
              <label>Video Link - Paid User<i class="fa fa-plus-square-o ml-3" (click)="updatepaidVideo()"></i></label>
            </div>
            <div class="col-xs-12">
              <label>Video Link - Unpaid User<i class="fa fa-plus-square-o ml-3" (click)="updateUnpaidVideo()"></i></label>
            </div>
          </div>
          <div class="div col-xs-8">
            <div class="col-xs-12">
              <label class="col-xs-12">Part Of Day</label>
              <select class="form-control" [(ngModel)]="instruction.partOfDay" name="partOfDay" required="required">
                <option [value]="appConfig.Shared.Catalog.PART_OF_DAY.MORNING">{{appConfig.Shared.Catalog.PART_OF_DAY.MORNING}}</option>
                <option [value]="appConfig.Shared.Catalog.PART_OF_DAY.NIGHT">{{appConfig.Shared.Catalog.PART_OF_DAY.NIGHT}}</option>
              </select>
            </div>
            <div class="col-xs-12 flex">
              <label>Default</label>
              <label class="i-switch i-switch-sm bg-success dker m-l-xs ml-3">
                <input class="ng-pristine ng-valid ng-touched" type="checkbox" [(ngModel)]="instruction.default" name="default"/><i></i>
              </label>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">Quantity</label>
              <div class="col-xs-12 flex justifySB">
                <input class="form-control w-auto flex-grow" [(ngModel)]="instruction.quantity" name="quantity" required="required"/>
                <language-string-selection class="m-l-xs ml-3 flex-grow" [parseObj]="instruction" name="quantityUnitLanguageString" [tags]="['quantity unit']" required="required"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">Area Of Application</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="instruction" name="areaOfApplicationLanguageString" [tags]="['area of application']" required="required"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">Frequency Of Usage</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="instruction" name="frequencyOfApplicationLanguageString" [tags]="['frequency of application']" required="required"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">Duration of application</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="instruction" name="durationOfApplicationLanguageString" [tags]="['duration of application']" required="required"></language-string-selection>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-12">Duration of product</label>
        <div class="col-xs-12">
          <language-string-selection [parseObj]="instruction" name="durationOfProductLanguageString" [tags]="['duration of product']" required="required"></language-string-selection>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12">
          <language-string-multiple-selection-with-tag [parseObj]="instruction" [productObj]="productObj" name="notesLanguageString" title="Notes "></language-string-multiple-selection-with-tag>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12">
          <language-string-multiple-selection-with-tag [parseObj]="instruction" [productObj]="productObj" name="warningsLanguageString" title="Warnings "></language-string-multiple-selection-with-tag>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12">
          <language-string-multiple-selection-with-tag [parseObj]="instruction" [productObj]="productObj" name="cautionsLanguageString" title="Caution "></language-string-multiple-selection-with-tag>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12">
          <language-string-multiple-selection-with-tag [parseObj]="instruction" [productObj]="productObj" name="weathersLanguageString" title="Suitable in weather "></language-string-multiple-selection-with-tag>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" [disabled]="!instructionForm.valid" (click)="saveInstruction()">Save</button>
  </div>
</mat-dialog-content>