import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { EditComponent } from './edit.component';
import { DirectivesModule } from '../../../../components/directives.module';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';

const ROUTES: Routes = [
  { path: '', component: EditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatProgressBarModule,
    InputTextModule,
    InputSelectModule,
    InputCheckboxModule,
    LanguageStringSelectionModule,
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
})
export class EditModule {}
