import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'language-string-selection-with-tag',
  templateUrl: './language-string-selection-with-tag.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class LanguageStringSelectionWithTagComponent {
  @Input('parseObj') parseObj: any;
  @Input('name') name: string;
  @Input('tags') tags: Array<string>;
  @Input('required') required: boolean = false;

  constructor(public appConfig: AppConfig) {
  }

  ngOnInit(): void {
  }

  onTagSelectionChange(newTags: Array<any>): void {
    this.tags = newTags.map((tag: any) => tag.get('name'));
  }
}
