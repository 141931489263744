
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="list-header col-xs-12"><span class="pR25">Incoming/Outgoing ({{queueCount}})</span></div>
  </div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content p-0">
          <div class="px-3 bdr-top">
            <div class="flex mb-3">
              <label class="mr-3">Auto Refresh</label>
              <label class="i-switch i-switch-sm bg-success">
                <input type="checkbox" [(ngModel)]="autoRefresh" (ngModelChange)="toggleAutoRefresh()" name="autoRefresh"/><i></i>
              </label>
            </div>
            <div class="flex mb-3">
              <label class="mr-3">Incoming Calls</label>
              <label class="i-switch i-switch-sm bg-success">
                <input type="checkbox" [(ngModel)]="incomingSwitch" (ngModelChange)="reset()" name="incomingSwitch"/><i></i>
              </label>
            </div>
            <div class="flex mb-3">
              <label class="mr-3">Outgoing Calls</label>
              <label class="i-switch i-switch-sm bg-success">
                <input type="checkbox" [(ngModel)]="outgoingSwitch" (ngModelChange)="reset()" name="outgoingSwitch"/><i></i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>