import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { RouterModule, Routes } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { DirectivesModule } from '../../../components/directives.module';
import { RefundComponent } from './refund.component';

const ROUTES: Routes = [{ path: '', component: RefundComponent, pathMatch: 'full' }];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FileUploadModule,
    DirectivesModule,
    MomentModule,
    RouterModule.forChild(ROUTES),
    AgGridAngular,
  ],
  declarations: [RefundComponent],
  exports: [RefundComponent],
})
export class RefundModule {
}
