
<label class="mt-0">{{title}}</label>
<div class="col-xs-12 p-0 pb-2" *ngIf="parseValue?.length">
  <div class="badge m-2 ml-0" *ngFor="let tag of parseValue; let i = index;"><span class="pr-2">{{tag.get('name')}}</span><i class="fa fa-trash-o pointer" (click)="removeTag(i)"></i></div>
</div>
<div class="col-xs-12 p-0 mt-0">
  <input class="col-xs-12" type="text" placeholder="Type Tag Name..." aria-label="Number" name="{{name}}" autocomplete="off" matInput="matInput" [formControl]="autoCompleteController" [matAutocomplete]="auto"/>
  <mat-autocomplete class="search-bar-xs" #auto="matAutocomplete">
    <mat-option *ngFor="let option of options | async" [value]="option.value" (onSelectionChange)="autoCompleteOnSelect(option)">{{option.display}}</mat-option>
  </mat-autocomplete>
</div>