import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[keyboardShortcuts]' })
export class KeyboardShortcuts {
  @Input() onEnter: Function = () => {};
  @Input() onUp: Function = () => {};
  @Input() onDown: Function = () => {};
  el: ElementRef;
  window: any;
  static parameters: any = [ElementRef];
  constructor(el: ElementRef) {
    this.el = el;
  }

  @HostListener('window:keydown', ['$event'])
  onWindowKeyDown(event: any): void {
    switch (event.keyCode) {
      case 13: // Enter Key
        this.onEnter();
        event.preventDefault();
        return;
      case 38: // Up Key
        this.onUp();
        event.preventDefault();
        break;
      case 40: // Down Key
        this.onDown();
        event.preventDefault();
        break;
      default:
    }
  }
}
