import { v4 as uuid } from 'uuid';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, UntypedFormControl, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'multi-regimen-selection',
  templateUrl: './multi-regimen-selection.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MultiRegimenSelectionComponent {
  @Input('regimenIds')
  set onUpdateRegimenIds(regimenIds: Array<string>) {
    this.updateRegimenByRegimenIds(regimenIds);
  }
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
  }
  @Input('type') type: Array<string> = [ApiClientConstant.Regimen.Type.MAIN, ApiClientConstant.Regimen.Type.ALTERNATE];
  @Input('required') required: boolean = false;
  @Input('placeholder') placeholder: string = '';
  @Input('filter') filter: Record<string, unknown> = {};
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }
  disabled: boolean = false;
  @Output('onValueChange') onValueChange: EventEmitter<any> = new EventEmitter();

  inputText: string;
  importantVariableForInputRequired: string;
  skipUpdate: boolean = false;
  name: string = uuid();
  autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  regimenOptions: Observable<Array<{ name: string; object: any }>>;
  regimens: Array<any> = [];
  regimenIds: Array<string> = [];

  constructor(private conn: ConnectionService, private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.regimenOptions = this.autoCompleteProductController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getRegimens(token)));
  }

  async getRegimens(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findRegimens({
      where: {
        regimenId: { $regex: name, $options: 'i' },
        type: this.type,
        ...this.filter,
      },
      limit: 10,
      project: ['regimenId'],
      ascending: 'regimenId',
    });
    return products.map((object: any): { name: string; object: any } => ({
      name: object.get('regimenId').split('v4_')[1],
      object,
    }));
  }

  async autoCompleteRegimenOnSelect(item: { name: string; object: any }): Promise<any> {
    setTimeout(() => (this.inputText = ''), 100);
    this.regimens.push(item.object);
    this.setRegimens(this.regimens);
    this.notifyChange();
  }

  private async updateRegimenByRegimenIds(regimenIds: Array<string> = []): Promise<void> {
    if (regimenIds.length === this.regimenIds.length && regimenIds.every((each: string) => this.regimenIds.includes(each))) {
      return;
    }
    const regimens = await this.conn.findRegimens({ where: { regimenId: regimenIds }, project: ['regimenId'] });
    this.skipUpdate = true;
    this.setRegimens(regimens);
  }

  private setRegimens(regimens: Array<any>): void {
    this.regimens = regimens;
    this.regimenIds = regimens.map((each: any) => each.get('regimenId'));
    this.notifyChange();
  }

  removeRegimen(index: number): void {
    this.regimens.splice(index, 1);
    this.setRegimens(this.regimens);
    this.notifyChange();
  }

  private notifyChange(): void {
    if (!this.skipUpdate) {
      this.onValueChange.emit(this.regimens);
    }
    delete this.skipUpdate;
  }
}
