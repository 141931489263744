
<mat-dialog-content class="mat-dialog-content">
  <form #followupForm="ngForm" (submit)="addFollowUp()">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Follow up</h4><i class="fas fa-close font16 pull-right" (click)="hideModal(false)"></i>
    </div>
    <div class="modal-body" *ngIf="!popUpModel.orderConfirmationView  && SelectedPopUpType != 'partialFollowUpReason'">
      <div class="col-xs-12 p-0">
        <div class="col-xs-12" *ngIf="SelectedPopUpType === appConfig.FollowUp.PopupType.SKIP">
          <label class="col-xs-12">skipping the followup ! Are you sure ?</label>
        </div>
        <div class="col-xs-12" *ngIf="[appConfig.FollowUp.PopupType.ADD_OR_EDIT_FOLLOWUP, appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP, appConfig.FollowUp.PopupType.SKIP].includes(SelectedPopUpType)">
          <div class="col-xs-12">
            <div class="col-xs-6">
              <label class="col-xs-12 mt-0">Next FollowUp</label>
              <div class="col-xs-12 flex">
                <input matInput="matInput" [(ngModel)]="popUpModel.nextFollowUpDate" [min]="minDate" [max]="maxDate" [matDatepicker]="nextFollowUpDate" required="required" name="nextFollowUpDate"/>
                <mat-datepicker-toggle class="mat-datepicker-p-0 ml-1" matSuffix="matSuffix" [for]="nextFollowUpDate"></mat-datepicker-toggle>
                <mat-datepicker #nextFollowUpDate></mat-datepicker>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="flex mb-3 px-3">
                <label class="mr-3">Doctor Call Needed</label>
                <label class="i-switch i-switch-sm bg-success">
                  <input type="checkbox" [(ngModel)]="popUpModel.doctorCallNeeded" name="doctorCallNeeded"/><i></i>
                </label>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">FollowUp Reason</label>
            <div class="col-xs-12">
              <input class="w100" [(ngModel)]="popUpModel.followUpReason" name="reason" required="required" type="note"/>
            </div>
          </div>
        </div>
        <div class="col-xs-12 p-0" *ngIf="[appConfig.FollowUp.PopupType.ADD_OR_EDIT_FOLLOWUP, appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP].includes(SelectedPopUpType)">
          <div class="flex col-xs-12">
            <label>Message Sent To User</label><i class="fa fa-spin fa-spinner mt-2 ml-3" *ngIf="!popUpModel.followUpMessage"></i>
          </div>
          <div class="col-xs-12">
            <textarea class="text-area form-control" [(ngModel)]="popUpModel.followUpMessage" name="message" required="required" type="message" disabled="disabled"></textarea>
          </div>
        </div>
        <div class="col-xs-12 p-0" *ngIf="[appConfig.FollowUp.PopupType.WAITING_FOR_IMAGE].includes(SelectedPopUpType)">
          <label>Images required</label>
          <mat-form-field class="col-xs-12">
            <mat-select [(ngModel)]="popUpModel.waitingForImageTypes" name="waitingForImageTypes" multiple="multiple" required="required">
              <mat-option *ngFor="let type of waitingForImageTypes" [value]="type">{{type}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-xs-12 text-center" *ngIf="SelectedPopUpType === appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP && !followUpHistory?.length">No followups found.</div>
      <div class="col-xs-12" *ngIf="SelectedPopUpType === appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP && followUpHistory?.length">
        <div class="col-xs-12 bdr-btm flex">
          <label class="col-xs-2">Reason</label>
          <label class="col-xs-2">Updated By</label>
          <label class="col-xs-1">State</label>
          <label class="col-xs-2">Scheduled At</label>
          <label class="col-xs-2">Effective Date</label>
          <label class="col-xs-2">Ready On</label>
          <label class="col-xs-2">Completed On</label>
        </div>
        <div class="col-xs-12 mt-3 flex" *ngFor="let item of followUpHistory">
          <div class="col-xs-2">{{item.get('followUpReason')}}</div>
          <div class="col-xs-2">{{item.get('updatedBy')?.get('username')}}</div>
          <div class="col-xs-1">{{item.get('State') || ''}}</div>
          <div class="col-xs-2">{{item.get('nextFollowUpDate') | amDateFormat: "MMM DD, YYYY hh:mm a"}}</div>
          <div class="col-xs-2">{{item.get('effectiveFollowUpDate') | amDateFormat: "MMM DD, YYYY hh:mm a"}}</div>
          <div class="col-xs-2">{{item.get('markedReadyOn') ? (item.get('markedReadyOn') | amDateFormat: "MMM DD, YYYY hh:mm a") : '-'}}</div>
          <div class="col-xs-2">{{item.get('completedOn') | amDateFormat: "MMM DD, YYYY hh:mm a"}}</div>
        </div>
      </div>
    </div>
    <div class="modal-footer" *ngIf="!popUpModel.orderConfirmationView && SelectedPopUpType != 'partialFollowUpReason'">
      <button class="btn-xs pull-right ml-3" *ngIf="[appConfig.FollowUp.PopupType.ADD_OR_EDIT_FOLLOWUP, appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP, appConfig.FollowUp.PopupType.WAITING_FOR_IMAGE, appConfig.FollowUp.PopupType.SKIP].includes(SelectedPopUpType)" type="submit" [disabled]="!followupForm.valid">{{ (followUp && followUp.id) ? "Update" : "Create" }}</button>
      <button class="btn-xs pull-right" *ngIf="[appConfig.FollowUp.PopupType.ADD_OR_EDIT_FOLLOWUP, appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP, appConfig.FollowUp.PopupType.SKIP].includes(SelectedPopUpType)" type="submit" [disabled]="!followupForm.valid || !popUpModel.followUpMessage">Make new Followup</button>
    </div>
    <div class="modal-body" *ngIf="popUpModel.orderConfirmationView">
      <div class="col-xs-12">{{popUpModel.orderConfirmationMessage}}</div>
      <div class="col-xs-12 mt-2">
        <table class="table">
          <thead>
            <td>#</td>
            <td>Name</td>
            <td>Instruction Call Needed</td>
            <td>Complementary</td>
            <td></td>
          </thead>
          <tr *ngFor="let product of popUpModel.orderProducts;let i = index;">
            <td>{{i + 1}}</td>
            <td class="font-bold">{{product.title}} [{{product.quantity}}{{product.quantityUnit}}] {{product.type === appConfig.Shared.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</td>
            <td>
              <label class="i-switch i-switch-sm bg-success mt-2">
                <input type="checkbox" [(ngModel)]="product.followUpNeeded" name="{{i}}_auto_product"/><i></i>
              </label>
            </td>
            <td>
              <label class="i-switch i-switch-sm bg-success mt-2">
                <input type="checkbox" [(ngModel)]="product.complementary" name="{{i}}_complementary"/><i></i>
              </label>
            </td>
            <td><i class="fa fas-trash-o font16" (click)="removeProduct(i)">delete</i></td>
          </tr>
        </table>
        <div class="col-xs-12">
          <input class="w100" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_product" autocomplete="off" [formControl]="autoCompleteController" [matAutocomplete]="productAuto"/>
          <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
            <mat-option *ngFor="let option of options | async" [value]="option.name" (onSelectionChange)="autoCompleteSelect(option)">{{option.name}}</mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>
    <div class="modal-footer" *ngIf="popUpModel.orderConfirmationView">
      <button class="pull-right btn-secondary ml-3" (click)="saveFollowUpCreatingOrder(false)">Don't Create Order</button>
      <button class="pull-right" (click)="saveFollowUpCreatingOrder(true)">Create Order</button>
    </div>
    <div class="modal-body" *ngIf="popUpModel.reportMissingMessage && SelectedPopUpType == 'partialFollowUpReason'">
      <label class="col-xs-12 mt-0">{{popUpModel.reportMissingMessage}}</label>
      <label class="col-xs-12">Select Reason for Partial Followup</label>
      <div class="col-xs-8">
        <select class="col-xs-4" [(ngModel)]="selectedReasonForPartialFollowup" [required]="required">
          <option *ngFor="let option of partialFollowupReasons;" [value]="option.value">{{option.display}}</option>
        </select>
      </div>
    </div>
    <div class="modal-footer" *ngIf="popUpModel.reportMissingMessage && SelectedPopUpType == 'partialFollowUpReason'">
      <button class="pull-right btn-secondary ml-3" type="button" (click)="sendPartialFollowupResponse()" [disabled]="!selectedReasonForPartialFollowup">Make New Followup</button>
    </div>
  </form>
</mat-dialog-content>
