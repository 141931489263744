import { Component, NgZone, OnDestroy } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  ui: any;
  count: number;
  resetDoctorFilter: boolean;
  resetOperatorFilter: boolean;
  doctorFilter: Array<string> = [];
  operatorFilter: Array<string> = [];
  approvedLeavesList: Array<any> = [];
  currentUser: any;
  productNames: Array<string>;
  constructor(private conn: ConnectionService,
              private zone: NgZone,
              private windowRef: WindowRefService,
              public appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<void> {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.currentUser = this.conn.getCurrentUser();
    await this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Unique Identifier',
      field: 'uniqueIdentifier',
      width: 250,
      editable: true,
    }, {
      headerName: 'Products',
      field: 'productNames',
      width: 200,
      editable: true,
    }, {
      headerName: 'Is Rx',
      field: 'isRx',
      width: 200,
      editable: true,
    }, {
      headerName: 'Main Category',
      field: 'mainCategory',
      width: 200,
      editable: true,
    }, {
      headerName: 'Sub Category',
      field: 'subCategory',
      width: 200,
      editable: true,
    }];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<Array<any>> {
    const data = JSON.parse(JSON.stringify(await this.conn.findSKUs()));
    data.forEach((sku: any) => {
      const skuCopy = sku;
      skuCopy.productNames = skuCopy.products.map((product: any) => product.title);
    });
    return data;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
  }
}
