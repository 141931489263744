import { Component, OnDestroy } from '@angular/core';
import Parse from 'parse';
import { ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'list',
  templateUrl: './list.html',
  styleUrls: ['./list.scss'],
})
export class ListComponent implements OnDestroy {
  links: Array<any>;
  addNew: boolean;
  newLink: any;
  editFlag: boolean;
  editLinkObj: any;
  ui: any;
  static parameters: any = [ConnectionService, AppConfig];
  constructor(private conn: ConnectionService, private appConfig: AppConfig) { }

  ngOnInit(): void {
    this.links = [];
    this.ui = { loading: true };
    this.addNew = false;
    this.editFlag = false;
    this.newLink = {};
    this.editLinkObj = {};
    this.conn.getLinks()
      .then((data: any) => {
        this.links = data;
        this.ui.loading = false;
      });
  }
  editLink(index: number): void {
    this.editLinkObj.description = this.links[index].get('description');
    this.editLinkObj.link = this.links[index].get('link');
    this.editLinkObj.index = index;
    this.editFlag = true;
  }
  deleteLink(index: number): void {
    this.links[index].destroy().then(() => this.links.splice(index, 1));
  }
  updateLink(): void {
    this.links[this.editLinkObj.index].set('description', this.editLinkObj.description);
    this.links[this.editLinkObj.index].set('link', this.editLinkObj.link);
    this.links[this.editLinkObj.index].save().then((data: any) => {
      this.links[this.editLinkObj.index] = data;
      this.editFlag = !this.editFlag;
      this.editLinkObj = {};
    }).catch((err: any) => {
      alert(err.message.message || 'Link Not Updated');
    });
  }
  saveLink(): void {
    const newLink = new Table.EasyAccess();
    newLink.set('description', this.newLink.description);
    newLink.set('userId', ApiConnector.getCurrentUser());
    newLink.set('link', this.newLink.link);
    newLink.save().then((data: any) => {
      this.links.push(data);
      this.addNew = !this.addNew;
      this.newLink = {};
    }).catch((err: any) => {
      alert(err.message.message || 'Link Not Saved');
    });
  }
  ngOnDestroy(): void {
    delete this.links;
    delete this.addNew;
    delete this.newLink;
    delete this.editFlag;
    delete this.editLinkObj;
  }
}
