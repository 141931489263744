
<div class="col-xs-12 title"><em class="fas fa-file-medical-alt pr-3"></em><span class="fw-700 fontL2">Medical Notes</span><em class="fa fa-plus-square-o pl-3" (click)="addNew = !addNew" title="Add Medical note"></em></div>
<div class="col-xs-12 mt-2" *ngIf="addNew">
  <textarea class="form-control text-area" [(ngModel)]="noteMessage" style="min-height:75px;"></textarea>
  <button class="small mt-2" [disabled]="!noteMessage" (click)="addNote()">Save</button>
  <button class="btn-xs fontS3 btn-danger mt-2 ml-1" (click)="cancelNote()">Cancel</button>
</div>
<div class="col-xs-12" style="max-height: 150px;overflow-y: auto;">
  <div class="col-xs-12 p-2" *ngFor="let note of notes;let i = index; first as isFirst" [ngClass]="{'oddRow': i%2 !==0, 'evenRow': i%2 ===0 }">
    <div class="col-xs-3 text-sm no-padder">{{note.get('createdAt') | amDateFormat: 'DD MMM YY, hh:mm A'}}</div>
    <div class="col-xs-3 pr-1" style="overflow-wrap: break-word;">{{note.get('Name')}}</div>
    <div class="col-xs-5 no-padder" style="overflow-wrap: break-word;"> {{note.get('message')}}</div>
    <div class="col-xs-1" *ngIf="userRoles.includes('adminDoctor') || userRoles.includes('admin') || userRoles.includes('doctor') || userRoles.includes('operator')" style="display: flex; justify-content: flex-end;"><i class="fa fa-edit" *ngIf="editAllowed && isFirst" (click)="editSelectedNote(i)"></i><i class="far fa-trash-alt ml-1" (click)="deleteNote(i)"></i></div>
  </div>
</div>
