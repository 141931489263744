<div class="order-history-container">
  <div class="flex-box">
    <div class="heading">Order history</div>
    <img class="pointer" src="/assets/images/add.svg" alt="Add" (click)="redirectToOrderCreation()">
  </div>

  <div class="orderRow" *ngFor="let order of orders;"
    [ngClass]="{ 'mac' : isMacDevice, 'bg-warning': order.partialProductOrder }">
    <a class="text-info" href="/order/{{order.objectId}}" target="_blank">
      <span class="pr-2" *ngIf="order.regimen">({{
        order.regimen.class}})</span>
      <span class="pr-2" title="Rs {{order.amount}}">{{order.regimenId ? "REGIMEN" :
        "PRODUCT" }} {{order.regimenId}}</span>
      <span class="pr-2">(</span><span class="pr-2">{{order.stage}}</span>
      <span class="pr-2" *ngIf="order.deliveredOn">{{order.deliveredOn | amDateFormat: "MMM DD, YYYY"}}</span>
      <span class="pr-2">)</span>
    </a>
    <a class="fa fa-pencil-square-o mx-3" href="/order/{{order.objectId}}/edit" target="_blank"></a>
    <div class="dib fontS2" *ngFor="let service of order.services;">
      <div class="dib mr-2 mt-2 product-label night" *ngIf="service.type !== 'COD'">{{service.title}}</div>
    </div>
    <div class="dib"><a class="dib mr-2 mt-2 product-label" *ngFor="let product of order.productInfo;"
        routerLink="/products/catalog/{{product.objectId}}" [ngClass]="product.allowAsAddon ? 'addon' : 'night'"
        title="{{product.allowAsAddon ? '[add on]': ''}} {{product.quantity}}{{product.quantityUnit}} Rs{{product.price}}">{{product.name}}
        &nbsp; {{product.type === appConfig.Shared.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</a></div>
  </div>
</div>