
<mat-dialog-content>
  <div class="modal-body no-padder w-full">
    <div class="col-xs-12 no-padder full-width-typeahead">
      <input class="form-control input-lg search-bar" type="text" placeholder="Search Product" matInput="matInput" style="border-radius:30px" name="add_products" [formControl]="autoCompleteController" [matAutocomplete]="auto"/>
      <mat-autocomplete class="search-bar-xs" #auto="matAutocomplete">
        <mat-option class="mat-option-xs" *ngFor="let option of options | async" [value]="option.name" (onSelectionChange)="autoCompleteOnSelect(option)">{{option.name}}</mat-option>
      </mat-autocomplete>
    </div>
  </div>
</mat-dialog-content>