
<div class="col-xs-12 title"><em class="fas fa-file-medical-alt pr-3"></em><span class="fw-700 fontL2">Ticket Notes</span><em class="fa fa-plus-square-o pl-3" (click)="addTicketNoteFlag = !addTicketNoteFlag" title="Add Ticket note"></em></div>
<div class="col-xs-12">
  <div class="col-xs-12 p-0 my-3" *ngIf="addTicketNoteFlag">
    <div class="col-xs-8 mr-2">
      <textarea class="form-control" [(ngModel)]="noteMessage" autofocus="autofocus" style="height:100px;"></textarea>
    </div>
    <button class="btn-xs" (click)="addTicketNote()" [disabled]="!noteMessage">Add</button>
  </div>
</div>
<div class="col-xs-12 mb-2 fontL my-1" *ngFor="let note of ticketNotes">
  <div class="col-xs-2 fontS">{{note.get('createdAt') | amDateFormat: 'MMM DD,YY'}}</div>
  <div class="col-xs-3 text-break pl-2">{{note.get('createdBy')}}</div>
  <div class="col-xs-7 pl-2">{{note.get('message')}}</div>
</div>