import { Component, Directive, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild, ViewChildren } from '@angular/core';
import { ConnectionService } from '../../services/connection-service';
import { WindowRefService } from '../../services/window-ref-service';

@Directive({
  selector: '[popup]',
})

export class PopupDirective {
  show: boolean = false;
  heading: any;
  content: any;
  @Output('onHide') onHide: EventEmitter<any> = new EventEmitter();
  constructor(private el: ElementRef, private conn: ConnectionService, private window: WindowRefService) {
  }

  async ngOnInit(): Promise<any> {
    setTimeout(() => {
      this.window.nativeWindow.document.onclick = (event: any) => this.showHidePopup(event, false);
    });
    this.heading = this.el.nativeElement.querySelector('[popupheading]');
    this.content = this.el.nativeElement.querySelector('[popupcontent]');
    this.heading.addEventListener('click', (event: any) => this.showHidePopup(event, !this.show));
    this.content.addEventListener('click', (event: any) => this.stopPropagation(event));
    this.showHidePopup(null, false);
  }

  stopPropagation(event: any): void {
    if (event) event.stopPropagation();
  }

  showHidePopup(event: any, status: boolean): void {
    this.show = status;
    this.stopPropagation(event);
    if (this.show) {
      this.content.style.display = 'block';
    } else {
      this.content.style.display = 'none';
    }
  }
}
