
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Found more than one user with same number select any of these</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
    <div class="col-xs-12 mt-5 p-0 bold">
      <div class="col-xs-4">Name</div>
      <div class="col-xs-4">Primary Number</div>
      <div class="col-xs-4">Alternate Number</div>
    </div>
  </div>
  <div class="modal-body mt-0"><a class="col-xs-12 flex bdr-btm py-3" *ngFor="let user of userList; let i = index" (click)="selectUser(i)">
      <div class="col-xs-4">{{user.get('PatientName')}}</div>
      <div class="col-xs-4">{{user.get('MobileNumber')}}</div>
      <div class="col-xs-4">{{user.get('alternateNumber')}}</div></a></div>
</mat-dialog-content>