
<div class="row z-ind-5" *ngIf="onCallMembers && onCallThisWeek && hideBox" style="margin-bottom:5px; width: 230px">
  <div class="col-xs-12 card bdr p-3 shadow" *ngIf="!editOnCallFlag && onCallThisWeek">
    <div class="col-xs-12 bdr-btm textC mb-2 px-0">
      <div class="col-xs-10 bold fontS2 pb-2">ON CALL ({{this.currentUserName}})</div>
      <div class="fas fa-times pull-right fontS2" (click)="hideBox = !hideBox"></div>
    </div>
    <div class="col-xs-12 p-0 fontS">
      <div class="col-xs-10 p-0 pt-2">{{onCallThisWeek.get('PatientName')}} {{onCallThisWeek.get('exotelNumber')}}</div><i class="col-xs-1 fa fa-pencil-square-o pull-right" *ngIf="isRootUser" (click)="editOnCallFlag = !editOnCallFlag" style="margin-right: -4px; margin-top: 2px"></i>
    </div>
  </div>
  <select class="col-xs-8 no-padder-left wrapper-sm" *ngIf="editOnCallFlag" [(ngModel)]="editedOnCallMember">
    <option *ngFor="let member of onCallMembers" value="{{member.id}}">{{member.get('PatientName')}}</option>
  </select>
  <button class="btn btn-primary-outline pull-right" (click)="saveOnCall()" *ngIf="editOnCallFlag">Save</button>
</div>
