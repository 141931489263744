import { Component, NgZone, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { ApiClientConstant, ParseKeys, RequestQueryPayload, Table } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { WindowRefService } from '../../../services/window-ref-service';

@Component({ selector: 'language-string-approval', templateUrl: './language-string-approval.html' })
export class LanguageStringApprovalComponent implements OnDestroy {
  window: any;

  // Local Variables
  data: Array<any> = [];
  filter: any = {};
  count: any;
  languageFilter: ParseKeys<Table.LanguageString>;
  languageStrings: Array<any> = [];
  languageKeys: any = [];
  gridApi: GridApi;
  dataSource: any;
  ui: any;
  columnDefs: any;
  components: any;
  @ViewChild('agGrid') agGrid: AgGridAngular;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private conn: ConnectionService,
              private zone: NgZone,
              public appConfig: AppConfig,
              public windowRef: WindowRefService,
              private renderer: Renderer2) {
    this.window = windowRef.nativeWindow;
  }

  async ngOnInit(): Promise<any> {
    this.ui = { isFilterOpen: false, grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
    this.filter.tags = [];
    await this.route.queryParams.subscribe(async (queryParams: Params) => {
      if (queryParams.tagId) {
        const tagObj = await this.conn.getLanguageTagByObjectId(queryParams.tagId);
        this.filter.tags.push(tagObj);
      }
      this.reset();
    });
    const visibleColumns: any[] = Object.values(ApiClientConstant.LanguageString)
      .filter((each: ValueOf<typeof ApiClientConstant.LanguageString>) => !([ApiClientConstant.LanguageString.EN,
        ApiClientConstant.LanguageString.HI] as Array<string>).includes(each),
      );
    this.agGrid.columnApi.setColumnsVisible(visibleColumns, false);
    this.languageFilter = ApiClientConstant.LanguageString.HI;
    this.languageKeys = Object.values(ApiClientConstant.LanguageString)
      .filter((each: string) => each !== ApiClientConstant.LanguageString.EN);
  }

  async onTagUpdate(): Promise<any> {
    this.reset();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const data = await this.loadData(params);
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 70,
      cellRenderer: (params: any): any => {
        let eDiv;
        if (!params.data) {
          eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const { data }: any = params;
        eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pR10' target="_blank" href='../languageString/${
          data.objectId}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'Approval',
      width: 105,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.style['line-height'] = '5px';
        eDiv.style['margin-top'] = '5px';
        const button = this.renderer.createElement('button');
        button.style.padding = '5px';
        this.renderer.setProperty(button, 'textContent', 'Approve');
        this.renderer.listen(button, 'click', (event: any) => { this.onClickApprove(data.objectId); });
        this.renderer.appendChild(eDiv, button);
        return eDiv;
      },
    }, {
      headerName: 'En',
      field: 'en',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }, {
      headerName: 'Hi',
      field: 'hi',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }, {
      headerName: 'Te',
      field: 'te',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }, {
      headerName: 'Kn',
      field: 'kn',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }, {
      headerName: 'Ta',
      field: 'ta',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }];
  }

  async changeLanguage(language: ValueOf<typeof ApiClientConstant.LanguageString>): Promise<any> {
    this.languageFilter = language;
    const languageArray = this.languageKeys.filter((languageString: string) => languageString !== this.languageFilter);
    this.agGrid.columnApi.setColumnsVisible([this.languageFilter], true);
    this.agGrid.columnApi.setColumnsVisible(languageArray, false);
    this.reset();
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  async loadData(params: any): Promise <Array<any>> {
    const requestPayload: RequestQueryPayload<Table.LanguageString> = {
      where: { [`${this.languageFilter}Approved`]: false },
      project: ['en', this.languageFilter],
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
    };
    if (this.filter.tags.length) {
      requestPayload.where.tags = { $in: this.filter.tags };
    }
    let data;
    [data, this.count] = await Promise.all([
      this.conn.findLanguageStrings(requestPayload)
        .then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      this.conn.countLanguageStrings(requestPayload),
    ]);
    this.languageStrings = await this.conn.findLanguageStrings(requestPayload);
    return data;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async onClickApprove(languageStringId: any): Promise<void> {
    try {
      const languageString = await this.conn.getLanguageString(languageStringId);
      languageString.set(`${this.languageFilter}Approved`, true);
      await languageString.save();
      this.reset();
    } catch (err) {
      alert(err.message);
    }
  }

  resetFilters(): void {
    this.filter.tags.splice(0, this.filter.tags.length);
    this.languageFilter = ApiClientConstant.LanguageString.HI;
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.data;
  }
}
