import { v4 as uuid } from 'uuid';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ControlContainer, NgForm } from '@angular/forms';
import { InputHelperService } from '../input-helper';
import { ConnectionService } from '../../../services/connection-service';
import { UploadFileService } from '../../../services/uploadFileService/uploadFile.service';

@Component({
  selector: 'input-image-upload',
  templateUrl: './input-image-upload.html',
  styleUrls: ['./input-image-upload.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class InputImageUploadComponent {
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input('required')
  set onUpdateRequired(required: boolean) {
    this.required = required;
    this.onInputChange();
  }
  required: boolean = false;
  @Input('placeholder') placeholder: string = '';
  @Input('type')
  set onUpdateType(type: string) {
    this.type = type;
    this.valueUpdated();
  }
  type: string = 'image';
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }

  @Input('accept') accept: string;
  @Input() constraints: { size?: number, aspectRadio?: number[] } = { size: 153600 };
  disabled: boolean = false;
  @Output() fileChanged: EventEmitter<any> = new EventEmitter();

  @Output('onValueChange') onValueChange: EventEmitter<any> = new EventEmitter();

  ui: any = {};
  imageFiles: Array<string> = [];
  parseValue: any;
  parseObj: any;
  name: string = uuid();

  static parameters: any = [InputHelperService, ConnectionService, HttpClient, UploadFileService];
  constructor(private inputHelper: InputHelperService,
              private conn: ConnectionService,
              private http: HttpClient,
              private upload: UploadFileService) {
  }

  ngOnInit(): void {
  }

  onInputChange(): void {
    if (!this.parseObj || !this.name) return;
    this.imageFiles = this.inputHelper.getValue(this.parseObj, this.name) || [];
    this.valueUpdated();
  }

  async onFileChange(files: any): Promise<any> {
    const file = files[0];
    if (!file) return;
    try {
      const src = await this.checkSize(file);
      if (file?.type !== 'video/mp4') {
        await this.checkAspectRatio(src);
      }
      await this.uploadImage(file);
    } catch (e: any) {
      alert(e);
    }
  }

  checkSize(file: File): Promise<void> {
    return new Promise((resolve: Function, rejct: Function): void => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any): void => {
        const src = e.target.result;
        if (this.constraints?.size && file?.size > this.constraints?.size) {
          rejct('Size should be less than 150KB');
        }
        resolve(src);
      };
    });
  }

  checkAspectRatio(imageSrc: any): Promise<boolean> {
    return new Promise((resolve: Function, reject: Function): void => {
      const image = new Image();
      image.onload = (): void => {
        if (this.constraints.aspectRadio
          && this.constraints.aspectRadio.length
          && !this.constraints.aspectRadio.some((ratio: number) => image.width * ratio === image.height)) {
          reject(`Aspect ration should be ${this.constraints.aspectRadio.join(', ')}`);
        }
        resolve(true);
      };
      image.src = imageSrc;
    });
  }

  async uploadImage(imageFile: any): Promise<void> {
    const url = await this.upload.uploadFile({ file: imageFile,
      bucket: 'PRODUCT',
      source: 'doctor_product',
      username: this.conn.getCurrentUser().get('username') });
    this.imageFiles.push(url);
    this.valueUpdated();
  }

  removeFile(index: number): void {
    this.imageFiles.splice(index, 1);
    this.valueUpdated();
  }

  valueUpdated(): void {
    this.inputHelper.setValue(this.parseObj, this.name, this.imageFiles);
    this.onValueChange.emit(this.parseValue);
  }
}
