import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ListComponent } from './list.component';
import { DirectivesModule } from '../../../components/directives.module';
import { NodeModal } from './node-modal';
import { InputSelectModule } from '../../../components/input/input-select';
import { InputTextModule } from '../../../components/input/input-text';

const ROUTES: Routes = [
  {
    path: '',
    component: ListComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatTreeModule,
    MatIconModule,
    MatProgressBarModule,
    MatDialogModule,
    InputTextModule,
    InputSelectModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [ListComponent, NodeModal],
  exports: [ListComponent, NodeModal],
})
export class ListModule {}
