
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/marketing/quiz-questions">Question</a></li>
          <li>{{editId? 'Edit' : 'New' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content p-4">
      <form class="col-xs-12 col-md-6" #quizForm="ngForm">
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Question</label>
          <div class="col-xs-7">
            <language-string-selection [parseObj]="quizQuestion" name="questionLanguageString" [required]="true" [tags]="['quiz_questions']"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Options List<i class="fas fa-plus-square ml-3 pointer" (click)="addResponseKeys()"></i></label>
          <div class="col-xs-12">
            <div class="col-xs-12 p-0" *ngIf="responseJson.length">
              <label class="col-xs-4 mt-0">Option</label>
            </div>
            <div class="col-xs-12 p-0 flex mb-3" *ngFor="let response of responseJson; let i = index;">
              <div class="col-xs-4">
                <language-string-selection [parseObj]="quizQuestion.get('optionsArray')" name="{{i}}" [required]="true" [tags]="['quiz_answers']"></language-string-selection>
              </div><i class="fas fa-trash pointer ml-3" (click)="removeResponseKeys(i)"></i>
            </div>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Answer</label>
          <div class="col-xs-7">
            <language-string-selection [parseObj]="quizQuestion" name="answer" [required]="true" [tags]="['quiz_answers']"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-4">Wrong Answer</label>
          <div class="col-xs-7">
            <language-string-selection [parseObj]="quizQuestion" name="wrongAnswer" [required]="true" [tags]="['quiz_wrong_answer']"></language-string-selection>
          </div>
        </div>
      </form>
    </div>
    <div class="flex-footer p-4">
      <button (click)="saveQuestion()" [disabled]="!quizForm.valid">{{ !loading ? "Save Question" : "Saving ..." }}</button>
    </div>
  </div>
</div>