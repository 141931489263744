import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, filter, mergeMap } from 'rxjs';
import { ConnectionService } from 'src/services/connection-service';
import { RequestQueryPayload } from 'api-client';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'input-internal-user-select',
  templateUrl: './input-internal-user-select.html',
})
export class InputInternalUserSelectComponent implements OnInit {
  public autoCompleteInternalUserController: FormControl = new FormControl();
  @Output() onSelectInternalUser: EventEmitter<any> = new EventEmitter();
  @Input('userType') userType: string;
  @Input('type') type: Array<string> = ['doctor', 'operator'];
  @Input('languages') languages: Array<string> = [];
  internalUserOptions: Observable<Array<{ name: string; object: any }>>;
  constructor(private conn: ConnectionService, public appConfig: AppConfig) { }

  ngOnInit(): void {
    this.internalUserOptions = this.autoCompleteInternalUserController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getDoctors(token)));
  }

  getInternalUsersDisplayName(doctor: any): string {
    return `${(doctor.get('DoctorDisplayName') || doctor?.get('username'))}`;
  }
  async getDoctors(name: string): Promise<Array<{ name: string; object: any }>> {
    const payload: RequestQueryPayload<Table.User> = {
      where: {
        $or: [
          {
            username: { $regex: name, $options: 'i' },
          },
          {
            DoctorDisplayName: { $regex: name, $options: 'i' },
          },
        ],
        type: { $in: this.type },
        inactive: false,
      },
      project: ['username', 'DoctorDisplayName', 'knownLanguages'],
      limit: 5,
    };
    if (this.userType) {
      payload.where.userType = this.userType;
    }
    if (this.languages?.length) {
      payload.where.knownLanguages = { $in: this.languages };
    }
    const internalUsers = await this.conn.findUsers(payload);
    const formattedInternalUsers = internalUsers.map((doctor: any): { name: string; object: any } => ({
      name: this.getInternalUsersDisplayName(doctor),
      object: doctor,
    }));
    return formattedInternalUsers;
  }

  autoCompleteOnInternalUserSelect(item: { name: string; object: any }): void {
    this.onSelectInternalUser.emit(item);
    this.autoCompleteInternalUserController.setValue('');
  }
}
