import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConnectionService } from '../../services/connection-service';

@Injectable()
export class CannedResponseResolve {
  static parameters: any = [ConnectionService, Router];
  constructor(private conn: ConnectionService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.conn.getCannedResponse(route.params.id, ['user']);
  }
}
