import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from '../../services/connection-service';

@Component({ selector: 'follow-up-report', templateUrl: './follow-up-report.html' })
export class FollowUpReportComponent {
  username: any;
  webAppUrl: any;
  report: any;

  constructor(private conn: ConnectionService, private route: ActivatedRoute, private dom: DomSanitizer) {}

  async ngOnInit(): Promise<any> {
    this.username = this.route.snapshot.queryParams.username;
    this.report = await this.conn.getFollowUpReportsById(this.route.snapshot.params.id);
    this.findReportURL();
  }
  findReportURL(): void {
    this.webAppUrl = this.dom.bypassSecurityTrustResourceUrl(
      `${this.conn.getWebAppUrl()}/user/report/${this.route.snapshot.params.id}?username=${this.username}&v2=true`);
  }
}
