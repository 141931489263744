import { state } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { AppConfig } from 'src/app/app.config';
import { Broadcaster } from 'src/components/broadcaster';
import { ConnectionService } from 'src/services/connection-service';
import { WindowRefService } from 'src/services/window-ref-service';

@Component({
  selector: 'main-concern-modal',
  templateUrl: './main-concern-modal.html',
  styleUrls: ['./main-concern-modal.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogContent],
})

export class MainConcernModal {
  mainConcernOptions: Array<any> = [];
  userConcern: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MainConcernModal>,
    private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
    private windowRef: WindowRefService) {
  }

  async ngOnInit(): Promise<void> {
    if (this.data?.mainConcernOptions) {
      this.mainConcernOptions = this.data.mainConcernOptions;
      this.userConcern = this.data.userConcern;
    }
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  updateMainConcern(concern: any): void {
    this.userConcern = concern?.get('value');
  }

  async updateTree(): Promise<void> {
    if (this.userConcern !== this.data.userConcern) {
      try {
        await this.conn.updateUserData({ id: this.data.user.id, PrivateMainConcern: this.userConcern });
        await this.retriggerTree();
        this.broadcaster.broadcast('NOTIFY', {
          message: 'Main Concern updated',
          type: this.appConfig.Shared.Toast.Type.SUCCESS,
        });
        this.windowRef.nativeWindow.location.reload();
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    } else {
      await this.retriggerTree();
    }
    this.hideModal();
  }

  async retriggerTree(): Promise<void> {
    const context = { consultationSessionId: this.data.selectedConsultation?.id };
    const stateId = 'v5';
    try {
      await this.conn.activateState({ username: this.data.user?.get('username'), stateId, context });
      this.broadcaster.broadcast('NOTIFY', { message: 'Tree Triggered Successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
