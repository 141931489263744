import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../components/directives.module';
import { TreeViewComponent } from './view.component';
import { TreeIndicationComponent } from './indication.component';
import { PostRegimenComponent } from './post-regimen.component';
import { IndicationModule } from './indication';
import { PostRegimenModule } from './post-regimen';
import { TreeViewRouteModule } from './routes';

const ROUTES: Routes = [
  {
    path: '',
    component: TreeViewComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: TreeIndicationComponent,
    loadChildren: (): any => IndicationModule,
  },
  {
    path: '',
    component: PostRegimenComponent,
    loadChildren: (): any => PostRegimenModule,
  },
  {
    path: '',
    component: PostRegimenComponent,
    loadChildren: (): any => TreeViewRouteModule,
  },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  DirectivesModule,
  RouterModule.forChild(ROUTES),
],
declarations: [TreeViewComponent, TreeIndicationComponent, PostRegimenComponent],
exports: [TreeViewComponent, TreeIndicationComponent, PostRegimenComponent] })
export class TreeViewModule {
}
