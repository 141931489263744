<div class="user-data-container">
  <div class="flex">
    <div class="title">Name</div>
    <div class="value">{{orderObj?.addressBook?.contactName || user.get('PatientName')}}</div>
  </div>
  <hr>
  <div class="flex">
    <div class="title">Type</div>
    <div class="value">{{orderObj.type}}</div>
  </div>
  <hr>
  <div class="flex">
    <div class="title">Mobile No.</div>
    <div class="value">
      <span>{{orderObj?.addressBook?.mobileNumber || user.get('MobileNumber')}}</span>
      <span *ngIf="orderObj.activeContactNumber !== orderObj.contactNumber" class="mark-active pointer" (click)="activeContactNumber()">Mark Active</span>
      <img src="/assets/images/data-call.svg" alt="" class="icon pointer" (click)="callUserThroughExotel()">
      <img src="/assets/images/data-copy.svg" alt="" class="icon pointer" (click)="copyToClipboard()">
    </div>
  </div>
  <hr>
  <div class="flex">
    <div class="title">Address</div>
    <div class="value">{{orderObj?.addressBook?.mobileNumber || orderObj?.deliveryAddress}}</div>
  </div>
  <div class="mt-15"></div>
  <div class="flex">
    <div class="title">Order Object ID</div>
    <div class="value">{{orderObj?.objectId}}</div>
  </div>
  <hr>
  <div class="flex">
    <div class="title">User Object ID</div>
    <div class="value">{{user?.id}}</div>
  </div>
  <hr>
  <div class="flex">
    <div class="title">Created by</div>
    <div class="value fw-700">
      <span>{{ orderObj?.createdBy?.username  }} ({{orderObj.createdAt | amDateFormat: 'MMM DD YYYY,hh:mm a'}})</span>
      <br>
      <span>Updated ({{orderObj.updatedAt| amDateFormat: 'MMM DD YYYY,hh:mm a'}})</span>
    </div>
  </div>
  <div class="mt-4"></div>
  <div class="flex">
    <div class="title">Placed By</div>
    <div class="value fw-700" *ngIf="orderObj.stage === 'INITIAL'">Not placed</div>
    <div class="value fw-700" *ngIf="orderObj.stage !== 'INITIAL'">{{orderObj?.createdBy?.username}}</div>
  </div>
</div>