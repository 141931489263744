import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MomentModule } from 'ngx-moment';
import { DirectivesModule } from '../../../components/directives.module';
import { InputSelectModule } from '../../../components/input/input-select';
import { InputTextModule } from '../../../components/input/input-text';
import { AudioRecorderModule } from '../../../components/audio-recorder/audio-recorder.module';
import { PatientEditComponent } from './patient-edit.component';
import { TicketListModule } from '../../chat/view/ticket-list';
import { UserNoteModule } from '../../../components/user-notes';
import { InstantCheckupModule } from '../../chat/view/instant-checkup/instant-checkup.module';
import { TreeTriggerModule } from '../../chat/view/tree-trigger';
import { MainConcernModule } from '../../chat/view/mainConcern';
import { CallLogModule } from '../../chat/view/call-logs';

const ROUTES: Routes = [
  { path: 'patientEdit', component: PatientEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatDialogModule,
    ReactiveFormsModule,
    MomentModule,
    RouterModule,
    TicketListModule,
    UserNoteModule,
    InstantCheckupModule,
    TreeTriggerModule,
    MainConcernModule,
    CallLogModule,
    InputSelectModule,
    InputTextModule,
    AudioRecorderModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [PatientEditComponent],
  exports: [PatientEditComponent],
})
export class PatientEditModule {
}
