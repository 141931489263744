import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../../../app.config';
import { ConnectionService } from '../../../../../services/connection-service';

@Component({ selector: 'regimen-change-history', templateUrl: './regimen-product-change-history.html' })
export class RegimenProductChangeHistoryComponent {
  regimen: any;
  loading: boolean;
  actionLogs: Array<{ [key: string]: unknown }> = [];

  constructor(
    public dialogRef: MatDialogRef<RegimenProductChangeHistoryComponent>,
    public injector: Injector,
    public appConfig: AppConfig,
    private conn: ConnectionService) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.regimen = data.regimen;
    this.loading = true;
    this.loadProductChangeHistory();
  }

  async hideModal(): Promise<any> {
    this.dialogRef.close();
  }

  async loadProductChangeHistory(): Promise<void> {
    const actionLogs = await this.conn.findActionLog({
      where: { regimen: this.regimen, type: ['REGIMEN_CHANGE', 'INSTRUCTION_CHANGE'] },
      descending: 'createdAt',
      project: ['Action'],
    });
    this.actionLogs = actionLogs.map((each: any) => each.toJSON());
    this.loading = false;
  }
}
