import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { ConnectionService } from 'src/services/connection-service';
import { WindowRefService } from 'src/services/window-ref-service';

@Component({
  selector: 'sales-order-history',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sales-order-history.component.html',
  styleUrl: './sales-order-history.component.scss',
})
export class SalesOrderHistoryComponent {
  @Input('user') user: any;
  @Input('orders') orders: any = [];

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private router: Router,
    private window: WindowRefService,
  ) { }

  redirectToOrderCreation(): void {
    const url = `${this.conn.getBaseUrl()}/orders/new?username=${this.user.get('username')}`;
    this.window.nativeWindow.open(url, '_blank');
  }
}
