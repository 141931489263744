import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';
import { LocalStorage } from '../../services/local-storage-service';

@Component({
  selector: 'change-ticket-allocation',
  templateUrl: './change-ticket-allocation.html',
})
export class ChangeTicketAllocationComponent {
  allocatedOperatorId: string;
  allocatedDoctorId: string;
  doctors: Array<any>;
  operators: Array<any>;
  teams: string[];
  selectedTeams: string[];
  userRoles: Array<string>;
  currentDoctor:any;
  currentOperator:any;
  public autoCompleteDoctorController: UntypedFormControl = new UntypedFormControl();
  public autoCompleteOperatorController: UntypedFormControl = new UntypedFormControl();
  doctorsOptions: Observable<Array<{ name: string; object: any }>>;
  operatorOptions: Observable<Array<{ name: string; object: any }>>;
  skipReAllocateDoctor: boolean = false;
  constructor(private dialogRef: MatDialogRef<ChangeTicketAllocationComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private conn: ConnectionService,
              private localStorage: LocalStorage,
              private appConfig: AppConfig) { }

  async ngOnInit(): Promise<void> {
    this.allocatedDoctorId = this.data.allocatedDoctor?.id;
    this.allocatedOperatorId = this.data.allocatedOperator?.id;
    this.skipReAllocateDoctor = this.data.skipReAllocateDoctor;
    this.selectedTeams = this.data.teams;
    this.teams = Object.values(ApiClientConstant.Role.Name);
    this.userRoles = this.localStorage.getJsonValue('userRoles') || [];
    this.getCurrentDoctorDetails(this.data.allocatedDoctor?.id);
    this.getCurrentOperatorDetails(this.data.allocatedOperator?.id);
    this.doctorsOptions = this.autoCompleteDoctorController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getDoctors(token)));
    this.operatorOptions = this.autoCompleteOperatorController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getOperators(token)));
  }

  async getCurrentDoctorDetails(allocatedOperatorId: string):Promise<void> {
    this.currentDoctor = await ApiConnector.find(Table.User, {
      where: {
        objectId: allocatedOperatorId,
      },
      limit: 1,
    });
    if (this.currentDoctor.length) {
      this.currentDoctor = this.currentDoctor[0];
    }
  }

  getDoctorsDisplayName(doctor: any): string {
    return `${doctor?.get('DoctorDisplayName')} (${doctor.get('username')})`;
  }
  async getDoctors(name: string): Promise<Array<{ name: string; object: any }>> {
    const doctors = await this.conn.findUsers({
      where: {
        DoctorDisplayName: { $regex: name, $options: 'i' },
        type: 'doctor',
        inactive: { $ne: true },
      },
      limit: 5,
    });
    return doctors.map((doctor: any): { name: string; object: any } => ({
      name: this.getDoctorsDisplayName(doctor),
      object: doctor,
    }));
  }

  autoCompleteOnOperatorSelect(item: { name: string; object: any }): void {
    this.currentOperator = item.object;
    this.allocatedOperatorId = item.object.id;
    this.autoCompleteDoctorController.setValue('');
  }

  async getCurrentOperatorDetails(allocatedOperatorId: string):Promise<void> {
    this.currentOperator = await ApiConnector.find(Table.User, {
      where: {
        objectId: allocatedOperatorId,
      },
      limit: 1,
    });
    if (this.currentOperator.length) {
      this.currentOperator = this.currentOperator[0];
    }
  }

  getOperatorsDisplayName(operator: any): string {
    return `${operator.get('username')}`;
  }

  async getOperators(name: string): Promise<Array<{ name: string; object: any }>> {
    const operators = await this.conn.findUsers({
      where: {
        username: { $regex: name, $options: 'i' },
        type: 'operator',
      },
      limit: 5,
    });
    return operators.map((operator: any): { name: string; object: any } => ({
      name: this.getOperatorsDisplayName(operator),
      object: operator,
    }));
  }

  autoCompleteOnDoctorSelect(item: { name: string; object: any }): void {
    this.currentDoctor = item.object;
    this.allocatedDoctorId = item.object.id;
    this.autoCompleteDoctorController.setValue('');
  }

  async save(): Promise<void> {
    let selectedDoctor;
    let selectedOperator;
    if (this.data.allocatedDoctor?.id !== this.allocatedDoctorId) {
      selectedDoctor = new Table.User();
      selectedDoctor.id = this.allocatedDoctorId;
    }
    if (this.data.allocatedOperator?.id !== this.allocatedOperatorId) {
      selectedOperator = new Table.User();
      selectedOperator.id = this.allocatedOperatorId;
    }
    this.dialogRef.close({ selectedDoctor, selectedOperator, teams: this.selectedTeams, skipReAllocateDoctor: this.skipReAllocateDoctor });
  }

  reset():void {}

  hideModal(): void {
    this.dialogRef.close();
  }
}
