import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguageVoiceComponent } from './language-voice.component';
import { LanguageVoiceListModule } from './language-voice-list';

const ROUTES: Routes = [
  {
    path: 'languageVoices',
    component: LanguageVoiceComponent,
    loadChildren: (): any => LanguageVoiceListModule,
    pathMatch: 'full',
  },
];

@NgModule({
  providers: [],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [LanguageVoiceComponent],
  exports: [LanguageVoiceComponent],
})
export class LanguageVoiceModule {
}
