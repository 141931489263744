import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './chat.component';
import { ChatViewModule } from './view';
import { OutgoingModule } from './outgoing';
import { WaitlistModule } from './waitlist';
import { InstantCheckupModule } from './instant-checkup';
import { CallListModule } from './call-list';
import { AssignedListModule } from './operator-assigned-chats';
import { InitialCallModule } from './initial-calls';
import { OpenSupportTicketsModule } from './open-support-tickets';

const ROUTES: Routes = [
  {
    path: 'chats',
    redirectTo: '/chats/open-tickets',
    pathMatch: 'full',
  },
  {
    path: 'chats/InstantCheckups',
    loadChildren: (): any => InstantCheckupModule,
    pathMatch: 'full',
  },
  {
    path: 'chats/open-tickets',
    component: ChatComponent,
    loadChildren: (): any => OpenSupportTicketsModule,
  },
  {
    path: 'chats/outgoing',
    component: ChatComponent,
    loadChildren: (): any => OutgoingModule,
    pathMatch: 'full',
  },
  {
    path: 'chats/waitlist',
    component: ChatComponent,
    loadChildren: (): any => WaitlistModule,
    pathMatch: 'full',
  },
  {
    path: 'chat/:id',
    component: ChatComponent,
    loadChildren: (): any => ChatViewModule,
    pathMatch: 'full',
  },
  {
    path: 'chats/callList',
    component: ChatComponent,
    loadChildren: (): any => CallListModule,
  },
  {
    path: 'chats/assignedList',
    component: ChatComponent,
    loadChildren: (): any => AssignedListModule,
  },
  {
    path: 'chats/initial-calls',
    component: ChatComponent,
    loadChildren: (): any => InitialCallModule,
  },
  {
    path: 'ticket/:id',
    component: ChatComponent,
    loadChildren: (): any => ChatViewModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  BrowserModule,
  RouterModule.forChild(ROUTES),
],
declarations: [ChatComponent],
exports: [ChatComponent] })
export class ChatModule {}
