import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallsComponent } from './calls-log.component';
import { KioskCallsComponent } from './kiosk-calls/kiosk-calls.component';
import { IncomingOutgoingModule } from './incoming-outgoing';
import { PendingCallsModule } from './pending-calls';
import { KioskCallsModule } from './kiosk-calls/kiosk-calls.module';
import { OldPendingCallsModule } from './old-pending-calls';

const ROUTES: Routes = [{
  path: 'calls/incoming-outgoing',
  component: CallsComponent,
  loadChildren: (): any => IncomingOutgoingModule,
  pathMatch: 'full',
}, {
  path: 'calls/pending-calls',
  component: CallsComponent,
  loadChildren: (): any => PendingCallsModule,
  pathMatch: 'full',
}, {
  path: 'calls/kiosk-calls',
  component: KioskCallsComponent,
  loadChildren: (): any => KioskCallsModule,
  pathMatch: 'full',
}, {
  path: 'calls/old-pending-calls',
  component: CallsComponent,
  loadChildren: (): any => OldPendingCallsModule,
  pathMatch: 'full',
}];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [CallsComponent],
  exports: [CallsComponent],
})
export class CallsLogModule {}
