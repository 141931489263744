import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { MainConcernComponent } from './mainConcern.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    MomentModule,
    RouterModule,
  ],
  declarations: [MainConcernComponent],
  exports: [MainConcernComponent],
})
export class MainConcernModule {
}
