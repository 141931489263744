import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiClientConstant } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Shared } from '../../../../shared';

@Component({
  selector: 'history-view',
  templateUrl: './history-view.html',
  styleUrls: ['./history-view.scss'],
})
export class HistoryViewComponent implements OnDestroy {
  regimenObj: any;
  user: any;
  regimenHistory: Array<any> = [];
  products: Array<any> = [];
  userRoles: Array<string> = [];
  constructor(private router: Router, private route: ActivatedRoute, public appConfig: AppConfig, public conn: ConnectionService,
     private storage: LocalStorage) {
  }

  async ngOnInit(): Promise<any> {
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.user = this.conn.getCurrentUser();
    await this.getRegimenHistory();
  }

  async getRegimenHistory(): Promise<any> {
    this.regimenObj = await this.conn.getRegimenHistoryById(this.route.snapshot.params.id);
    this.products = await []
      .concat(this.regimenObj.get('morning'))
      .concat(this.regimenObj.get('night'))
      .reduce((result: Array<any>, { product }: any): Array<any> => {
        if (!result.filter((x: any) => x.id === product.id).length) {
          result.push(product);
        }
        return result;
      }, []);
  }

  regimenPrice(): number {
    const uniqueProducts = {};
    return [].concat(this.regimenObj.get('morning'))
      .concat(this.regimenObj.get('night'))
      .reduce((total: number, item: any) => {
        if (uniqueProducts[item.product.id]) return total;
        uniqueProducts[item.product.id] = item.product.get('price');
        return total + item.product.get('price');
      }, 0);
  }

  ngOnDestroy(): void {
    delete this.router;
    delete this.route;
    delete this.appConfig;
    delete this.regimenObj;
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
