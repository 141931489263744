
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Reminder</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form role="form" name="messageNoteForm" #reminderForm="ngForm">
      <div class="col-xs-12 mb-3">
        <label class="col-xs-12">Name</label>
        <div class="col-xs-7">
          <input-text [parseObj]="reminder" name="name"></input-text>
        </div>
      </div>
      <div class="col-xs-12 col-md-10"> 
        <label class="col-xs-12 mt-0">Choose File</label>
        <input class="col-xs-8" type="file" (change)="file = $event.target.files[0]" required="required" placeholder="Article Images Upload"/>
      </div>
      <div class="col-xs-12 mb-3">
        <label class="col-xs-12">Type</label>
        <div class="col-xs-7">
          <input-select [parseObj]="reminder" name="type" [options]="reminderTypes" [required]="true"></input-select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" [disabled]="!reminderForm.valid" name="submit" (click)="saveReminder()">Save</button>
  </div>
</mat-dialog-content>