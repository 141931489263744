
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header"><span class="pr-3">Trigger By Keyword ({{length}})</span>
        <div class="pull-right"><i class="fa fa-plus-square-o m-l-xs pointer" routerLink="new"></i></div>
      </div>
    </div>
    <div class="flex-content">
      <div class="list-view">
        <div class="list-content">
          <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>