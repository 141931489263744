import { Component, NgZone } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'api-client';
import { ListDataSource } from './list.datasource';
import { ListNode } from './list.node';
import { AppConfig } from '../../app.config';
import { ConnectionService } from '../../../services/connection-service';
import { NodeModal } from './node-modal';

@Component({ selector: 'list', templateUrl: './list.html' })
export class ListComponent {
  ui: { modal: { node: { open: boolean, parentNode?: any, node?: any, parentListNode?: ListNode } } };
  treeControl: FlatTreeControl<ListNode>;
  dataSource: ListDataSource;
  static parameters: any = [AppConfig, ConnectionService, MatDialog, NgZone];
  constructor(private appConfig: AppConfig, private conn: ConnectionService, private dialog: MatDialog) {
    this.treeControl = new FlatTreeControl<ListNode>(this.getLevel, this.isExpandable);
    this.dataSource = new ListDataSource(this.treeControl, this.conn);
    this.ui = { modal: { node: { open: false } } };
  }

  ngOnInit(): void {
  }

  getLevel = (node: ListNode): number => node.level;

  isExpandable = (node: ListNode): boolean => node.expandable;

  hasChild = (_: number, _nodeData: ListNode): boolean => _nodeData.expandable;

  createNode(parentNode?: ListNode): void {
    this.ui.modal.node.parentListNode = parentNode;
    if (parentNode) {
      this.ui.modal.node.parentNode = parentNode.item;
    }
    this.ui.modal.node.node = new Table.EmbeddedLink();
    const dialogRef = this.dialog.open(NodeModal, { data: { node: this.ui.modal.node.node, parentNode: this.ui.modal.node.parentNode } });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) {
        return;
      }
      if (result.get('createdAt').getTime() !== result.get('updatedAt').getTime()) {
        return;
      }
      this.dataSource.updateRootNodes(this.ui.modal.node.parentListNode);
    });
  }

  editNode(node: ListNode): void {
    this.ui.modal.node.parentListNode = undefined;
    this.ui.modal.node.parentNode = node.item.get('parentNode');
    this.ui.modal.node.node = node.item;
    const dialogRef = this.dialog.open(NodeModal, { data: { node: this.ui.modal.node.node, parentNode: this.ui.modal.node.parentNode } });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) {
        return;
      }
      if (result.get('createdAt').getTime() !== result.get('updatedAt').getTime()) {
        return;
      }
      this.dataSource.updateRootNodes(this.ui.modal.node.parentListNode);
    });
  }
}
