import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { AgGridAngular } from 'ag-grid-angular';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { InputTextModule } from 'src/components/input/input-text';
import { InputCheckboxModule } from 'src/components/input/input-checkbox';
import { DirectivesModule } from '../../components/directives.module';
import { ExperimentComponent } from './experiment.component';
import { ExperimentEditComponent } from './experiment-edit/experiment-edit.component';

const ROUTES: Routes = [
  { path: 'experiment', component: ExperimentComponent, pathMatch: 'full' },
  { path: 'experiment/new', component: ExperimentEditComponent, pathMatch: 'full' },
  { path: 'experiment/:id/edit', component: ExperimentEditComponent, pathMatch: 'full' },

];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    DirectivesModule,
    MomentModule,
    MatDialogModule,
    InputTextModule,
    InputCheckboxModule,
    AgGridAngular,
  ],
  declarations: [ExperimentComponent, ExperimentEditComponent],
  exports: [ExperimentComponent],
})
export class ExperimentModule {}
