import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({ selector: 'regimenEditReason', templateUrl: './editReason.html' })

export class EditReasonPopupModal {
  updatedText: string;
  constructor(private dialogRef: MatDialogRef<EditReasonPopupModal>, @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  addEditReason(): void {
    this.dialogRef.close(this.updatedText);
  }
}
