import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeavesAndHolidaysComponent } from './leaves-and-holidays.component';
import { ListModule } from './list';
import { LeavesAndHolidaysResolve } from './leaves-and-holidays-resolver';
import { EditModule } from './edit';

const ROUTES: Routes = [
  {
    path: 'leaves',
    component: LeavesAndHolidaysComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'leaves/new',
    component: LeavesAndHolidaysComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: 'leaves/:id/edit',
    component: LeavesAndHolidaysComponent,
    loadChildren: (): any => EditModule,
    resolve: { leavesAndHolidays: LeavesAndHolidaysResolve },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [LeavesAndHolidaysResolve],
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [LeavesAndHolidaysComponent],
  exports: [LeavesAndHolidaysComponent] })
export class LeavesAndHolidaysModule {}
