
<div class="hbox" *ngIf="keywordTriggerTree">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/trees/trigger-by-keyword">Trigger By Keyword</a></li>
          <li> <span>{{keywordTriggerTree.get('treeName')}}</span></li>
        </ul>
      </div>
    </div>
    <div class="flex-content p-4">
      <div class="col-xs-12 flex">
        <label class="col-xs-2 mt-0">Tree Name</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{keywordTriggerTree.get('treeName')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Priority</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{keywordTriggerTree.get('priority')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Positive Keywords</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{keywordTriggerTree.get('positive')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Negative Keywords</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{keywordTriggerTree.get('negative')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Enable for paid user</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{keywordTriggerTree.get('paidUser')}}</div>
      </div>
    </div>
  </div>
</div>