import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ApiConnector, RequestQueryPayload } from 'api-client';
import moment from 'moment';
import { ConnectionService } from 'src/services/connection-service';

@Component({
  selector: 'dashboard-stats',
  templateUrl: './stats.html',
  styleUrls: ['./stats.scss'],
})

export class StatsComponent {
  callsConnected: number = 20;
  conversions: number = 4;
  @Input('startDate') startDate: Date;
  @Input('endDate') endDate: Date;
  percentage: number = 0;
  dashArray: string = '0, 100';
  pathLength: number = 2 * Math.PI * 15;
  arcLength: number = 0;
  teams: any;
  uniqueUsers: any;
  constructor(private conn: ConnectionService) {}

  async ngOnInit(): Promise<void> {
    const payload: RequestQueryPayload<Table.InternalTeam> = {
      where: { active: true },
      include: ['members', 'extraMembers'],
      project: [
        'extraMembers.username' as 'extraMembers',
        'extraMembers.userType' as 'extraMembers',
        'members.username' as 'members',
        'members.userType' as 'members',
        'name',
        'extraMembers.DoctorDisplayName' as 'extraMembers',
        'members.DoctorDisplayName' as 'members',
        'lead',
      ],
    };
    const teams = await this.conn.findInternalTeam(payload);
    await this.calculatePercentage();
  }

  ngOnChanges(): void {
    this.calculatePercentage();
  }

  async getNumberOfCallsConnected(): Promise<void> {
    const payload: RequestQueryPayload<Table.CallLog> = {
      where: {
        $and: [
          { createdAt: { $gte: moment(this.startDate).toDate() } },
          { createdAt: { $lte: moment(this.endDate).toDate() } },
        ],
        duration: { $gte: 40 },
        respondingTeam: 'SALES',
      },
      include: ['user'],
      project: ['user'],
    };
    const query = await ApiConnector.find(Table.CallLog, payload);
    this.uniqueUsers = new Set(query.map((data: any) => data.user));
    this.callsConnected = this.uniqueUsers.size;
  }

  calculatePercentage(): void {
    if (this.callsConnected > 0) {
      this.percentage = (this.conversions / this.callsConnected) * 100;
      const dashArrayValue = `${this.percentage}, 100`;
      this.arcLength = (this.percentage / 100) * this.pathLength;
      this.dashArray = dashArrayValue;
    }
  }
  get strokeDashoffset(): number {
    return -this.pathLength / 4;
  }
}
