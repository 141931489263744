import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'add-lifestyle-modal',
  templateUrl: './add-lifestyle.html',
})
export class AddLifestyleModal {
  lifestyle: any = {};

  public subscriptions: Subscription[] = [];

  constructor(private dialogRef: MatDialogRef<AddLifestyleModal>) {
  }

  hideModal(data?: any): void {
    this.dialogRef.close(data);
  }
}
