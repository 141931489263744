import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConnectionService } from '../../../services/connection-service';

@Component({
  selector: 'user-chat-view-human-interaction',
  templateUrl: './user-chat-view-human-interaction.html',
  styleUrls: ['./user-chat-view-human-interaction.scss'],
})
export class UserChatViewHumanInteractionComponent {
  @ViewChild('chatBox', { static: false }) chatBox: ElementRef;
  @Input('user')
  set setUser(user: any) {
    if (!user) {
      return;
    }
    this.user = user;
    this.userDisplayName = this.user.get('PatientName');
    this.findConsultationChatURL();
  }
  @Input() height: '500px';
  @Input() privateMainConcernClass: string;
  user: any;
  userDisplayName: string;
  chats: Array<any> = [];
  loading: boolean;
  hasMore: boolean = true;
  webAppUrl: SafeResourceUrl;
  constructor(private conn: ConnectionService, private dom: DomSanitizer) {
  }

  async findConsultationChatURL(): Promise<void> {
    const [session]: any = await this.conn.fetchConsultationSessions({
      where: { user: this.user, PrivateMainConcernClass: this.privateMainConcernClass },
      descending: 'createdAt',
    });
    this.webAppUrl = this.dom.bypassSecurityTrustResourceUrl(
      `${this.conn.getWebAppUrl()}/chatV2/${session.id}?userId=${this.user.id}&type=consultationSession&loginType=token`);
  }
}
