import { Component, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'continue-tab-popup',
  templateUrl: './continue-tab-popup.html',
})
export class ContinueTabPopupModal implements OnInit {
  products: any;
  TabletsId: Array<string> = ['UMyVJbm3Jq', 'ed9jLtJyqr'];
  neverOrderedProducts: Array<{ id: string, noOfDays: string, title: string} > = [];
  OrderedProducts: Array<{ id: string, noOfDays: string, title: string}> = [];

  constructor(private injector: Injector,
    private dialogRef: MatDialogRef<ContinueTabPopupModal>) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.products = data.products;
  }

  async ngOnInit(): Promise<void> {
    this.products.forEach((product: any) => {
      if (+product.noOfDays.split(' ')[0] > 0) {
        this.OrderedProducts.push(product);
      } else {
        this.neverOrderedProducts.push(product);
      }
    });
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
