import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';

@Component({ selector: 'instant-checkup-image-view', templateUrl: './instant-checkup-image-view.html' })
export class InstantCheckupImageView {
  instantCheckUpImageList: Array<any> = [];
  selectedInstantCheckUpImageIndex: number;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;

  constructor(public dialogRef: MatDialogRef<InstantCheckupImageView>,
    private connectionService: ConnectionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<void> {
    this.fetchInstsantCheckUpImageList();
  }

  async fetchInstsantCheckUpImageList(): Promise<any> {
    const query = {
      userId: this.data.userId,
      descending: 'createdAt',
      project: ['imagePath', 'type', 'compressedImagePath'],
      limit: 15,
      skip: this.instantCheckUpImageList.length,
      dateRange: {},
    };
    query.dateRange = this.data.dateRange;
    const instantCheckupImages = await this.connectionService.fetchUserInstantCheckup(query);
    this.instantCheckUpImageList = [...this.instantCheckUpImageList, ...instantCheckupImages];
  }
  editPhotoType(): void {
  }

  async selectImage(index: number): Promise<any> {
    this.selectedInstantCheckUpImageIndex = index;
  }
  async onScroll(event: Event): Promise<void> {
    const target = event.target as HTMLElement;
    const { scrollHeight, scrollTop, clientHeight }: { scrollHeight: number; scrollTop: number; clientHeight: number } = target;
    if (scrollTop + clientHeight >= scrollHeight) {
      await this.fetchInstsantCheckUpImageList();
    }
  }

  closePopup(): void {
    this.dialogRef.close({ instantCheckUp: this.instantCheckUpImageList[this.selectedInstantCheckUpImageIndex] });
  }
}
