
<div class="col-xs-12 mb-3 flex" *ngIf="tags.assignedObject">
  <div class="col-xs-2 bold">Assigned to</div>
  <div class="pull-left pr-2">:</div>
  <div class="col-xs-6">{{tags.assignedObject?.get('taggedTo')?.get('type')}}</div>
</div>
<!--.col-xs-12.p-0.mb-3(*ngIf= '!tags.assignedObject')-->
<!--  .col-xs-6-->
<!--    textarea.form-control([(ngModel)]='tags.description', required, name='tag', placeholder='Write a Description before assigning the chat')-->
<!--  .col-xs-2.ml-3-->
<!--    select.form-control([(ngModel)]='assignToIndex', name='assignToIndex')-->
<!--      option(*ngFor='let option of assignOptions, let i = index', value="{{i}}") {{ option.get('username').split('operator')[0] }}-->
<!--  button.btn-xs.ml-3((click)="assignTag(appConfig.Shared.Tag.Status.ASSIGNED, tags.tag)") Assign chat-->
<div class="col-xs-12 mb-3 flex" *ngIf="tags.assignedObject">
  <div class="col-xs-2 bold">Description</div>
  <div class="pull-left pr-2">:</div>
  <div class="col-xs-6">{{tags.assignedObject.get('description') || '-'}}</div>
  <div class="col-xs-2 ml-3" *ngIf="tags.assignedObject && ((tags.assignedObject?.get('taggedTo')?.id === internalUser.id) || userRoles?.includes('adminOperator'))">
    <button class="btn-xs" (click)="unAssignTag()">UnAssign Chat</button>
  </div>
</div>
