import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from '../../../components/directives.module';
import { StageComponent } from './stage.component';
import { StagePopupModal } from './stagePopup';
import { AddStagePopupModal } from './addStagePopup';
import { LanguageStringSelectionModule } from '../../../components/language-string/language-string-selection';
import { InputTextModule } from '../../../components/input/input-text';
import { InputCheckboxModule } from '../../../components/input/input-checkbox';

const ROUTES: Routes = [
  { path: '', component: StageComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MomentModule,
    RouterModule.forChild(ROUTES),
    LanguageStringSelectionModule,
    InputTextModule,
    InputCheckboxModule,
    MatDialogModule,
  ],
  declarations: [StageComponent, StagePopupModal, AddStagePopupModal],
  exports: [StageComponent],
})
export class StageModule {}
