import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './cart.component';
import { CartEditModule } from './edit';

const ROUTES: Routes = [
  {
    path: 'cart',
    component: CartComponent,
    loadChildren: (): any => CartEditModule,
  },
];

@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [CartComponent],
exports: [CartComponent] })
export class CartModule {}
