
<div class="hbox" activityTracker="activityTracker">
  <div class="flex-box">
    <div class="flex-heading">
      <navbar *ngIf="user && user.id && user.get('username') != appConfig.Shared.ExternalUser.username && !this.hideNavigator"></navbar>
    </div>
    <div class="flex-content">
      <router-outlet></router-outlet>
    </div>
    <div class="flex-footer"></div>
  </div>
</div>
<on-call *ngIf="user && user.id" style="position:fixed; right:30px; bottom:10px;"></on-call>
<app-update *ngIf="showAppUpdate"></app-update>
<toast-message [title]="toast.type" [message]="toast.message" [type]="toast.type"></toast-message>
<app-exotel-softphone *ngIf="user && user.get('callerType') === appConfig.Shared.User.CallerType.UNLIMITED_SOFTPHONE" [user]="user"></app-exotel-softphone>
