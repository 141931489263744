
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Instruction video upload</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form role="form" name="messageNoteForm" #imageUploadForm="ngForm">
      <div class="col-xs-12 col-md-10" *ngFor="let language of languages">
        <label class="col-xs-12 mt-0">{{language}}</label>
        <div class="col-xs-12">
          <input class="col-xs-6" type="file" (change)="uploadFiles($event.target.files[0], language)"/>
          <button class="btn-xs ml-3" (click)="uploadToAws(language)" [disabled]="!files[language]">upload</button>
          <button class="btn-xs ml-3" [disabled]="!instructionVideoUrl[language]"><a [href]="instructionVideoUrl[language]" target="_blank">play</a></button>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" (click)="hideModal(true)">Save</button>
  </div>
</mat-dialog-content>