<div class="info-tags-container">
  <div class="flex child" *ngFor="let tag of tags; let i = index;">
    <div class="tag flex" *ngIf="tag !== 'add'">
      <div class="title">{{tag}}</div>
      <img src="/assets/images/delete.svg" alt="" class="icon pointer" (click)="removeTags(i)">
    </div>
  </div>
  <div class="mt-2" (click)="addTags()">
    <img src="/assets/images/add-tags.svg" alt="" class="icon pointer">
  </div>
</div>