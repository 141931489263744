
<form [formGroup]="form" novalidate="novalidate" (ngSubmit)="saveFeed()">
  <div class="flex-box">
    <div class="flex-heading list-header"><span>{{feed?.id ? 'Edit' : 'New' }} Post</span>
      <div class="floatR mr-3">
        <div class="inline-flex"><span class="pr-2 fontS2">Active</span>
          <label class="i-switch i-switch-sm mr-5 my-0 dib">
            <input type="checkbox" formControlName="active"/><i></i>
          </label>
        </div>
        <button class="btn-xs" [disabled]="!form.valid" type="submit"><span class="pr-2">{{feed?.id ? 'Update' : 'Create'}}</span><i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i></button>
      </div>
    </div>
    <div class="flex-content">
      <mat-progress-bar *ngIf="isFileUploading" mode="indeterminate"></mat-progress-bar>
      <div class="languages">
        <div class="item" *ngFor="let item of languages" (click)="form.dirty ? openLanguageCofirmDialog(confirmDialog) : onSelectLanguage(item)" [ngClass]="{ 'active': currentLanguage === item }">{{item}}</div>
      </div>
      <div class="col-xs-12 col-lg-6 form-content">
        <file-picker *ngFor="let asset of mediaArray.controls; index as i" [data]="asset.value" (onRemoveFile)="mediaArray.removeAt(i)" (onFileChange)="onFileChange($event, i)" (onUploadingFile)="onUploadingFile($event)"></file-picker>
        <button class="btn-xs" (click)="addMedia({})" type="button"><span class="pr-2">+ Add more</span></button>
        <label>Caption</label>
        <textarea class="db" formControlName="caption" placeholder="Max of 200 characters"></textarea>
      </div>
      <div class="col-xs-12 form-content col-lg-5 ml-lg-0">
        <label class="my-0">CTA</label>
        <mat-form-field>
          <mat-select formControlName="cta" name="clickToAction">
            <mat-option *ngFor="let cta of ctaList;let i = index" [value]="i">{{cta.key}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-xs-12 mb-4" *ngIf="form.get('cta').value === 8">
          <label class="col-xs-4" style="font-weight:bold">Product</label>
          <div class="col-xs-12 mb-2" *ngIf="product">
            <div style="padding:3px 10px;background:#5a5a5a;display:inline-block;border-radius:3px;margin:2px 10px 2px 0px;color:white"><i class="fa fa-trash-o pointer" (click)="product = null"></i>
              <div style="max-width:500px;display:inline-block;">&nbsp;&nbsp; {{product.get('title')}}&nbsp;[{{product.get('quantity')}}{{product.get('quantityUnit')}}, Unit: {{product.get('margUnit')}}] {{product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</div>
            </div>
          </div>
          <div class="col-xs-12">
            <input class="form-control" type="text" placeholder="Type Product Name..." matInput="matInput" name="product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
            <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
              <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option)">{{option.name}}</mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <label class="my-0">CLASS</label>
        <mat-form-field>
          <mat-select [(ngModel)]="classTag" required="true" name="classTag">
            <mat-option *ngFor="let class of classList" [value]="class">{{class}}</mat-option>
          </mat-select>
        </mat-form-field>
        <label class="my-0">CATEGORY</label>
        <mat-form-field>
          <mat-select [(ngModel)]="categoryTag" required="true" name="categoryTag">
            <mat-option *ngFor="let category of categoryList" [value]="category">{{category}}</mat-option>
          </mat-select>
        </mat-form-field>
        <label>Targeting conditions</label>
        <label class="mb-0 clr-secondary">GENDER</label>
        <mat-radio-group formControlName="targetGender" name="targetGender">
          <mat-radio-button class="mr-3" [value]="genderValues.male">Male</mat-radio-button>
          <mat-radio-button class="mr-3" [value]="genderValues.female">Female</mat-radio-button>
          <mat-radio-button [value]="genderValues.both">Both</mat-radio-button>
        </mat-radio-group>
        <label class="mb-0 clr-secondary">USER TYPE</label>
        <mat-radio-group formControlName="userPaidState" name="targetUserType">
          <mat-radio-button class="mr-3" [value]="userStates.paid">Paid</mat-radio-button>
          <mat-radio-button class="mr-3" [value]="userStates.unpaid">Unpaid</mat-radio-button>
          <mat-radio-button [value]="userStates.both">Both</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</form>
<ng-template #confirmDialog>
  <mat-dialog-content class="mat-dialog-content">
    <div class="modal-body">
      <div class="col-xs-12 mb-4">You have unsaved changes, Please save or discard!</div>
      <div class="col-xs-12">
        <button class="btn-xs" type="button" (click)="saveFeed()" style="margin-right: 12px;"><span class="pr-2">Save</span></button>
        <button class="btn-xs btn-danger" type="button" (click)="onSelectLanguage()" style="margin-right: 12px;"><span class="pr-2">Discard</span></button>
        <button class="btn-xs btn-default" type="button" (click)="closeLanguageCofirmDialog()"><span class="pr-2">Close</span></button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
