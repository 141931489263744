import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Component, NgZone, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({ selector: 'list', templateUrl: './list.html' })
export class ListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  searchKey: string;

  // Local Variables
  data: Array<any> = [];
  tableCount: number = 0;
  queryCode: string;
  active: boolean = true;
  year: number;
  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.listViewSetup();
    this.route.queryParams.subscribe((params: any) => {
      this.queryCode = params.code;
      this.reset();
    });
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Edit',
        width: 50,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) return '';
          const id = params.value || params.data.objectId;
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<a class='fa fa-pencil-square-o', href='${url.join('/')}/coupons/${id}/edit'></a>`;
          return eDiv;
        },
      }, {
        headerName: 'Code',
        width: 200,
        field: 'code',
        filter: true,
        editable: true,
      },
      {
        headerName: 'Name',
        width: 200,
        field: 'name',
        filter: true,
      },
      {
        headerName: 'Percentage',
        field: 'percentage',
        width: 130,
        sortable: true,
        filter: true,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `${params.value || 0}%`;
          return eDiv;
        },
      },
      {
        headerName: 'Amount',
        field: 'amount',
        width: 100,
        sortable: true,
        filter: true,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `₹ ${params.value || 0}`;
          return eDiv;
        },
      },
      {
        headerName: 'Active',
        field: 'active',
        width: 100,
      },
      {
        headerName: 'Max Usage Limit',
        field: 'maxUsageLimit',
        width: 150,
      },
      {
        headerName: 'Per.User Max Usage Limit',
        field: 'perUserMaxUsageLimit',
        width: 150,
      },
      {
        headerName: 'CreatedBy',
        field: 'createdBy.username',
        width: 150,
      },
      {
        headerName: 'CreatedAt',
        field: 'createdAt',
        cellRenderer: (params: any): any => {
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = moment(params.value).format('MMM DD YYYY');
          return eDiv;
        },
        width: 120,
        sortable: true,
      },
    ];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadData(params: any): Promise<any> {
    const searchOn = ['code', 'amount', 'percentage', 'name'];
    const payload: RequestQueryPayload<Table.Coupon> = {
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      where: {
        active: this.active,
        createdBy: { $exists: true },
      },
      include: ['createdBy'],
      descending: 'createdAt',
    };
    if (this.queryCode) payload.where.code = this.queryCode;
    if (this.year) {
      const startOfYear = moment().set('year', this.year).startOf('year').toDate();
      const endOfYear = moment().set('year', this.year).endOf('year').toDate();
      payload.where.$and = [
        { createdAt: { $gte: startOfYear } },
        { createdAt: { $lte: endOfYear } },
      ];
    }
    if (this.searchKey) {
      payload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    this.tableCount = await this.conn.countCoupons(payload);
    const data = JSON.parse(JSON.stringify(await this.conn.findCoupons(payload)));
    return data;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.data;
    delete this.ui;
  }
}
