import { NgZone, Component, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';

@Component({
  selector: 'calls-incoming-outgoing',
  templateUrl: './incoming-outgoing.html',
  styleUrls: ['./incoming-outgoing.scss'],
})
export class IncomingOutgoingComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  searchKey: string;

  // Local Variables
  data: Array<string> = [];
  teams: Array<string>;
  currentUser: any;
  autoRefresh: boolean = false;
  incomingSwitch: boolean = false;
  outgoingSwitch: boolean = false;
  autoRefreshInterval: any;
  queueCount: number = 0;

  constructor(
    private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.listViewSetup();
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Direction',
        field: 'direction',
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) {
            const eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = 'Loading ...';
            return eDiv;
          }
          const eDiv = this.window.document.createElement('div');
          let highlightedClass = '';
          if (params.data.order?.regimenId) {
            highlightedClass = 'list-label list-label-bg-red';
          }
          eDiv.innerHTML = `<div class='${highlightedClass}'>${params.value}</div>`;
          return eDiv;
        },
        width: 110,
        filter: true,
      }, {
        headerName: 'Called At',
        field: 'calledAt',
        width: 200,
      }, {
        headerName: 'From',
        field: 'from',
        width: 150,
      }, {
        headerName: 'To',
        field: 'to',
        width: 150,
      }, {
        headerName: 'Patient Name',
        field: 'user.PatientName',
        width: 150,
      }, {
        headerName: 'username',
        field: 'user.username',
        cellRenderer: (params: any): any => {
          if (!params.value) {
            const eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = '';
            return eDiv;
          }
          let url = `chat/${params.value}`;
          if (this.currentUser.get('userType')?.includes('SALES')) {
            url = `/marketing/patientEdit?username=${params.value}`;
          }
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<a class='no-padder ellipsis mLR5' target="_blank" href='${url}'>${
            params.value}</a>`;
          return eDiv;
        },
        width: 220,
      }, {
        headerName: 'Recent Order',
        field: 'order.orderNumber',
        cellRenderer: (params: any): any => {
          if (!params.value) {
            const eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = '';
            return eDiv;
          }
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<a class='no-padder ellipsis mLR5' target="_blank" href='/order/${params.value}'>${
            params.value}</a>`;
          return eDiv;
        },
        width: 110,
      },
    ];
  }

  reset(): void {
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadData(params: any = {}): Promise<any> {
    const where: any = {};
    if (this.incomingSwitch && !this.outgoingSwitch) where.direction = 'incoming';
    if (this.outgoingSwitch && !this.incomingSwitch) where.direction = 'outbound-api';
    const callLogs: any[] = await this.conn.getCalls({
      where,
      limit: this.ui.grid.pageSize,
      offset: params.startRow,
    });
    this.queueCount = callLogs.length;
    return callLogs.map((callLog: any) => callLog.toJSON());
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.data;
    delete this.ui;
  }
}
