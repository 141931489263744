import { Component, ViewChild, ElementRef } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { ApiClientConstant, Table, RequestQueryPayload } from 'api-client';
import { Broadcaster } from 'src/components/broadcaster';
import { AppConfig } from '../../app.config';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { ListActionCellComponent } from './list-action-cell/list-action-cell.component';

@Component({
  selector: 'language-string-tag',
  templateUrl: './language-string-tag.html',
  styleUrls: ['./language-string-tag.scss'],
})
export class LanguageStringTagComponent {
  @ViewChild('toast', { static: false }) toast: ElementRef;
  @ViewChild('agGrid') agGrid: AgGridAngular;
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  searchOn: string;
  count: number = 0;
  ui: any = { create: { show: false, tagName: '' }, grid: { rowModelType: 'infinite', pageSize: 100 } };
  message: string;
  searchKey: string;
  window: any;
  languageStringTag: any;
  editing: boolean = true;

  constructor(private appConfig: AppConfig,
    private conn: ConnectionService,
    private windowRefService: WindowRefService,
    private broadcaster: Broadcaster) {
    this.window = windowRefService.nativeWindow;
  }

  async ngOnInit(): Promise<any> {
    this.setUpGrid();
  }

  showToastMessage(message: string): void {
    this.message = message;
    this.toast.nativeElement.style.visibility = 'visible';
    setTimeout(() => {
      this.toast.nativeElement.style.visibility = 'hidden';
    }, 3000);
  }

  async createNewTag(): Promise<any> {
    const languageStringTag = new Table.LanguageStringTag();
    languageStringTag.set('name', this.ui.create.tagName);
    try {
      await languageStringTag.save();
      this.ui.create.show = false;
      this.reset();
    } catch (err) {
      this.showToastMessage('Tag already Exists!!');
      return;
    }
    delete this.ui.create.tagName;
  }

  async reset(): Promise<any> {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const data = await this.loadMore(params);
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Actions',
      field: 'objectId',
      width: 100,
      cellRenderer: ListActionCellComponent,
    }, {
      headerName: 'Edit',
      field: 'objectId',
      width: 100,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        if (this.editing) {
          eDiv.innerHTML = '<span class="fa fa-pencil-square-o pointer ml-3"></span>';
          eDiv.querySelector('span').addEventListener('click', () => {
            this.editing = false;
            this.gridApi.refreshCells({ force: true });
            this.editCell(params.node.rowIndex);
          });
        } else {
          eDiv.innerHTML = '<span class="fas fa-save pointer ml-3"></span>';
          eDiv.querySelector('span').addEventListener('click', () => {
            this.editing = true;
            this.gridApi.refreshCells({ force: true });
            this.stopEditingAndSave(params.data);
          });
        }
        return eDiv;
      },
    },
    {
      headerName: 'name',
      field: 'name',
      flex: 1,
      editable: true,
      cellEditor: 'agCellEditor',
    }];
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async editCell(rowIndex: any): Promise<void> {
    this.gridApi.startEditingCell({
      rowIndex,
      colKey: 'name',
    });
  }

  async stopEditingAndSave(editedData: any): Promise<void> {
    this.gridApi.stopEditing();
    if (editedData && editedData.name) {
      const tag = new Table.LanguageStringTag();
      tag.id = editedData.objectId;
      tag.set('name', editedData.name);
      try {
        await tag.save();
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: 'Error saving tag: ',
          type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    } else {
      this.broadcaster.broadcast('NOTIFY', { message: 'tag name cannot be empty',
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  resetFilters(): void {
    this.searchOn = ApiClientConstant.LanguageString.EN;
    delete this.searchKey;
    this.reset();
  }

  async loadMore(params: any): Promise<Array<unknown>> {
    const requestPayload: RequestQueryPayload<Table.LanguageStringTag> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      project: ['name'],
      descending: 'createdAt',
    };

    if (this.searchKey) {
      requestPayload.where.name = { $regex: this.searchKey };
    }
    let data;
    [data, this.count] = await Promise.all([
      this.conn.findLanguageStringTags(requestPayload)
        .then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      this.conn.countLanguageStringTags(requestPayload),
    ]);
    return data;
  }

  getRowNodeId(data: any): string {
    return data.objectId;
  }
}
