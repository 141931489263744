import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Mp3Encoder, WavHeader } from 'lamejs';
import { DomSanitizer } from '@angular/platform-browser';
import * as RecordRTC from 'recordrtc';
import { WindowRefService } from '../../services/window-ref-service';
import { UploadFileService } from '../../services/uploadFileService/uploadFile.service';
import { VoiceRecognitionService } from '../../services/voice-recognition-service/voice-recognition.service';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'voice-recording',
  templateUrl: './voice-recording.html',
  styleUrls: ['./voice-recording.scss'],
})
export class VoiceRecordingComponent {
  @Output('afterSave') afterSave: EventEmitter<string> = new EventEmitter();
  @Output('onChange') onChange: EventEmitter<any[]> = new EventEmitter();
  @Output('onVoiceRecognitionChange') onVoiceRecognitionChange: EventEmitter<string> = new EventEmitter();
  @ViewChild('audioPlayer', { static: false }) audioPlayer: ElementRef;
  @Input('disableVoiceToText') disableVoiceToText: boolean;
  @Input('orderApprovalPage') orderApprovalPage: boolean;
  @Input('btnClass') btnClass: string = '';
  @Input('questionForDoc') questionForDoc: Array<string> = [];
  mimeType: string = 'audio/wav';
  window: any;
  recordRTC: RecordRTC;
  error: string;
  stream: MediaStream;
  recordingInterval: any;
  blob: Blob;
  saveInProcess: boolean;
  ui: any = { recordingTimeInMinutes: '00:00' };
  isMobileBrowser: boolean = false;
  isVoiceToTextSupported: boolean = false;
  audioConfig: {
    bitRate: number;
    sampleRate: number;
    channels: number;
  } = { bitRate: 64, sampleRate: 44100, channels: 1 };
  blobCollection: Array<Blob> = [];
  voiceText: string = '';
  voiceTextCollection: string[] = [];
  newBlob: Blob;
  enableManualRecording: boolean = true;
  sourceURL: string;
  constructor(private windowRef: WindowRefService,
    private sanitizer: DomSanitizer,
    private upload: UploadFileService,
    private connectionService: ConnectionService,
    private voiceRecognitionService: VoiceRecognitionService) {
    this.window = windowRef.nativeWindow;
    this.isMobileBrowser = this.connectionService.isMobileBrowser;
    this.isVoiceToTextSupported = this.voiceRecognitionService.isSupported();
  }

  ngOnInit(): void {
    this.reset();
    this.error = '';
  }

  private initRecording(): void {
    this.startRecordingTimer();
    delete this.error;
  }

  async startRecording(): Promise<any> {
    try {
      if (!this.blobCollection?.length) {
        this.reset();
      }
      if (!this.stream) {
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const options = {
          type: 'audio',
          recorderType: RecordRTC.StereoAudioRecorder,
          mimeType: this.mimeType,
          audio: true,
          bitsPerSecond: this.audioConfig.bitRate * 1000,
          sampleRate: this.audioConfig.sampleRate,
          numberOfAudioChannels: this.audioConfig.channels,
        };
        this.recordRTC = new RecordRTC(this.stream, options);
      }
      this.initRecording();

      if (!this.disableVoiceToText && !this.isMobileBrowser && this.isVoiceToTextSupported) {
        this.voiceRecognitionService.startVoiceRecognition((transcript: string) => {
          this.onVoiceRecognitionChange.emit(transcript);
          this.voiceText = transcript;
        });
      }
      this.recordRTC.startRecording();
    } catch (error) {
      const err = error.message === 'Permission denied' ? 'Please allow permission to access microphone.' : error.message;
      alert(err);
      this.reset();
    }
  }

  cancelRecordingTimer(): void {
    clearInterval(this.recordingInterval);
    delete this.recordingInterval;
  }

  startRecordingTimer(): void {
    this.cancelRecordingTimer();
    if (!this.ui.recordingTime) {
      this.ui.recordingTime = 0;
      this.ui.recordingTimeInMinutes = '00:00';
    }
    this.ui.recording = true;
    this.recordingInterval = setInterval(() => {
      this.ui.recordingTime += 1;
      this.ui.recordingTimeInMinutes = this.updateAudioDuration(this.ui.recordingTime);
    }, 1000);
  }

  updateAudioDuration(duration: number): string {
    let durationInMinute;
    if (duration < 10) {
      durationInMinute = `00:0${duration}`;
    } else if (duration > 9 && duration < 60) {
      durationInMinute = `00:${duration}`;
    } else {
      let minutes: any = Math.floor(duration / 60);
      let seconds: any = duration % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      durationInMinute = `${minutes}:${seconds}`;
    }
    return durationInMinute;
  }

  stopRecording(): void {
    const { recordingTime, recordingTimeInMinutes }: any = this.ui;
    this.ui = { loading: false, recording: false, uploading: false, recordingTime, recordingTimeInMinutes };
    this.cancelRecordingTimer();
    this.voiceRecognitionService.stopVoiceRecognition();
    this.addVoiceText();
    if (this.recordRTC) {
      this.recordRTC.stopRecording(async () => {
        const blob = await this.recordRTC.getBlob();
        this.blob = await this.wavBlobToMp3Blob(blob);
        this.blobCollection.push(this.blob);
        this.closeMediaRecorder();
        this.mergeAudio();
        this.onChange.emit([this.blob]);
      });
    }
  }

  addAudioPlayer(): void {
    this.audioPlayer.nativeElement.innerHTML = '';
    this.blobCollection.forEach((blob: Blob, index: number) => {
      const audio = this.windowRef.nativeWindow.document.createElement('audio');
      audio.setAttribute('controls', true);
      audio.setAttribute('class', 'w100 audio mB20');
      audio.setAttribute('type', blob.type);
      audio.innerHTML = `<source src = ${URL.createObjectURL(blob)}>`;
      const div = this.windowRef.nativeWindow.document.createElement('div');
      div.setAttribute('class', 'flex');
      div.appendChild(audio);
      this.audioPlayer.nativeElement.appendChild(div);
    });
  }

  setAudioSourceURL(sourceURL: string): void {
    if (!sourceURL) {
      this.enableManualRecording = true;
      this.sourceURL = '';
      this.windowRef.nativeWindow.document.getElementsByTagName('audio')[0]?.remove();
      return;
    }
    this.sourceURL = sourceURL;
    this.audioPlayer.nativeElement.innerHTML = '';
    const audio = this.windowRef.nativeWindow.document.createElement('audio');
    audio.setAttribute('controls', true);
    audio.setAttribute('class', 'w100 audio mB20');
    audio.innerHTML = `<source src = ${sourceURL}>`;
    const div = this.windowRef.nativeWindow.document.createElement('div');
    div.setAttribute('class', 'flex');
    div.appendChild(audio);
    this.audioPlayer.nativeElement.appendChild(div);
    this.enableManualRecording = false;
  }

  addVoiceText():void {
    if (!this.voiceText) {
      return;
    }
    this.voiceTextCollection.push(this.voiceText);
    let allTranscript = '';
    allTranscript = this.voiceTextCollection.join(' ');
    this.onVoiceRecognitionChange.emit(allTranscript);
    this.voiceText = '';
  }

  mergeAudio(): void {
    this.newBlob = new Blob(this.blobCollection, { type: this.blobCollection[0].type });
    const allTranscript = this.voiceTextCollection.join(' ');
    this.blobCollection = [];
    this.voiceTextCollection = [];
    this.onVoiceRecognitionChange.emit(allTranscript);
    this.voiceTextCollection.push(allTranscript);
    this.blobCollection.push(this.newBlob);
    this.addAudioPlayer();
  }

  resetAudio():void {
    this.blobCollection = [];
    this.voiceTextCollection = [];
    this.voiceText = '';
    this.addAudioPlayer();
    this.onVoiceRecognitionChange.emit('');
    this.reset();
  }

  closeMediaRecorder(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track: any) => track.stop());
      delete this.stream;
    }
  }

  isRecordingAvailable(): boolean {
    return !!this.blobCollection.length || !!this.sourceURL;
  }

  async saveRecording(): Promise<string> {
    if (!this.enableManualRecording) {
      const actualSourceURL = this.sourceURL.split('?')[0];
      this.afterSave.emit(actualSourceURL);
      return actualSourceURL;
    }
    if (!this.newBlob && this.blobCollection.length) this.mergeAudio();
    if (!this.newBlob) {
      alert('No Audio Found');
      this.afterSave.emit('');
      return '';
    }
    this.saveInProcess = true;
    try {
      const { publicURL: unSignedURL, signedGetURL: signedURL }: { publicURL: string; signedGetURL: string; } = await this
        .uploadBlobToServer(this.newBlob);
      this.afterSave.emit(unSignedURL);
      this.saveInProcess = false;
      return unSignedURL;
    } catch (error) {
      alert(error.toString());
      this.saveInProcess = false;
      this.afterSave.emit('');
    }
    return '';
  }

  encodeSamplesToMp3(samples: Int16Array, opts: any): Blob {
    const defaultEncodeOptions = {
      channels: this.audioConfig.channels,
      sampleRate: this.audioConfig.sampleRate,
      kbps: this.audioConfig.bitRate,
      maxSamples: 1152,
    };

    const options = {
      ...defaultEncodeOptions,
      ...opts,
    };

    const { maxSamples }: any = options;
    let remaining = samples.length;
    const mp3encoder = new Mp3Encoder(options.channels, options.sampleRate, options.kbps);
    const mp3Data = [];
    let mp3buffer;
    let index = 0;

    while (remaining > 0) {
      const sample = samples.slice(index, index + maxSamples);
      mp3buffer = mp3encoder.encodeBuffer(sample);

      if (mp3buffer.length > 0) {
        mp3Data.push(new Int8Array(mp3buffer));
      }

      index += maxSamples;
      remaining -= maxSamples;
    }
    mp3buffer = mp3encoder.flush();
    mp3Data.push(mp3buffer);

    return new Blob(mp3Data, { type: 'audio/mp3' });
  }

  async uploadBlobToServer(blob: Blob): Promise<{ publicURL: string; signedGetURL: string; }> {
    const file = new File([blob], `audio.${this.fileExtension(blob.type)}`, {
      type: blob.type,
      lastModified: Date.now(),
    });
    return this.upload.uploadFileViaSignedURL({ file, bucket: 'AUDIO' });
  }

  fileExtension(type: string): string {
    switch (type) {
      case 'audio/mpeg': return 'mp3';
      case 'audio/mp3': return 'mp3';
      default: return 'wav';
    }
  }

  reset(): void {
    this.saveInProcess = false;
    delete this.recordRTC;
    this.stopRecording();
    this.ui.recordingTime = 0;
    this.ui.recordingTimeInMinutes = '00:00';
    this.closeMediaRecorder();
    if (this.audioPlayer) this.audioPlayer.nativeElement.src = undefined;
    delete this.recordRTC;
    delete this.blob;
    delete this.newBlob;
    delete this.error;
    this.onChange.emit(this.blobCollection);
  }

  private async wavBlobToMp3Blob(blob: Blob): Promise<Blob> {
    const fileReader = new FileReader();
    return new Promise((resolve: Function) => {
      fileReader.onload = async (event: any): Promise<void> => {
        const blobResult = event.currentTarget.result as ArrayBuffer;
        const samples = new Int16Array(blobResult);
        const wav = WavHeader.readHeader(new DataView(blobResult));

        const mp3Blob = this.encodeSamplesToMp3(samples, {
          channels: wav.channels,
          sampleRate: wav.sampleRate,
        });
        resolve(mp3Blob);
      };
      if (!blob) {
        return;
      }
      fileReader.readAsArrayBuffer(blob);
    });
  }
}
