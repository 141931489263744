import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'investigation',
  templateUrl: './investigation.html',
})
export class InvestigationModule {
  selectedInvestigation: { tests: string[], name?: string, index?: number } = { tests: [] };
  investigationList: any;
  additionalTestInput: string;
  constructor(private conn: ConnectionService,
    private appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<InvestigationModule>) { }

  async ngOnInit(): Promise<void> {
    this.investigationList = await this.conn.fetchInvestigations();
    this.selectedInvestigation.index = this.investigationList
      .findIndex((each: any) => each.get('name') === this.selectedInvestigation.name);
  }

  changeInvestigation(event: any): void {
    const selectedIndex = Number(this.selectedInvestigation.index);
    if (selectedIndex >= 0) {
      this.selectedInvestigation.name = this.investigationList[selectedIndex].get('name');
      this.selectedInvestigation.tests = this.investigationList[selectedIndex].get('tests');
    } else {
      this.selectedInvestigation.tests = [];
      delete this.selectedInvestigation.name;
    }
  }

  addAdditionalTestToList(): void {
    if (this.additionalTestInput) {
      this.selectedInvestigation.tests.push(this.additionalTestInput);
      delete this.additionalTestInput;
    }
  }

  removeTestFromSelection(index: any): void {
    if (index >= 0) this.selectedInvestigation.tests.splice(index, 1);
  }

  update(): void {
    if (!this.selectedInvestigation) return;
    this.dialogRef.close(this.selectedInvestigation);
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
