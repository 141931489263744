
<div class="hbox">
  <div class="tickets">
    <div class="title">Support Ticket</div>
    <div class="ticket-list" style="overflow-x: hidden">
      <div class="ticket justifySB bdr-btm" *ngFor="let ticket of supportTicket">
        <div class="flex justifySB" style="gap: 1rem">
          <div class="date">{{ticket.get('createdAt') | amDateFormat: 'DD MMM, YY'}}</div>
          <div class="ticket-title"><a target="_blank" href="/chat/{{ticket.get('user').id}}?ticketId={{ticket.id}}" [ngClass]="{'clr-orange': ticket.get('status') == 'Pending'}">{{ticket.get('supportQuestion').get('question')}}</a></div>
        </div>
        <div class="flex pr-5" style="justify-content: space-between">
          <div class="sbar">{{ticket?.get('SBAR')?.get('situation')}}</div>
        </div>
      </div>
    </div>
  </div>
</div>