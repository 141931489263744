import { Component, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { RequestQueryPayload } from 'api-client';
import { AppConfig } from 'src/app/app.config';
import { Broadcaster } from 'src/components/broadcaster';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  ui: any;
  count: number;
  constructor(private conn: ConnectionService,
    private zone: NgZone,
    private windowRef: WindowRefService,
    private renderer: Renderer2,
    private appConfig: AppConfig,
    private broadcast: Broadcaster) {
  }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 50,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const id = params.value || params.data.objectId;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pointer' target="_blank" href='/marketing/support/${id}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'Question',
      field: 'questionLanguageString',
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value.en;
        return eDiv;
      },
      width: 300,
    },
    {
      headerName: 'Output',
      field: '',
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.data.outputType === 'COMPONENT' ? params.data.outputComponent : params.data.outputUrl;
        return eDiv;
      },
      width: 300,
    }];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  async delete(value: any): Promise<void> {
    try {
      const deleteObj = new Table.FAQ();
      deleteObj.id = value;
      await deleteObj.destroy();
      await this.reset();
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.SupportQuestion> = {
      where: {},
      include: ['questionLanguageString'],
      descending: 'createdAt',
    };
    const data = await this.conn.fetchSupportQuestions(requestPayload);
    this.count = data.length;
    return Promise.resolve(JSON.parse(JSON.stringify(data)));
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
  }
}
