import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ApiConnector, Table, RequestQueryPayload } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { Broadcaster } from '../../../../components/broadcaster';
import { ImageUploadModal } from '../../../../components/modal/imageUploadModal';
import { InvestigationModule } from '../../../compare-progress/investigation';
import { LocalStorage } from '../../../../services/local-storage-service';
import { AudioRecorderComponent } from '../../../../components/audio-recorder/audio-recorder.component';
import { ChatType } from '../../../../../typings/client/chat';

@Component({ selector: 'send-message-section', templateUrl: './send-message-section.html', styleUrls: ['./send-message-section.scss'] })
export class SendMessageSectionComponent {
  @Input() patient: any;
  @Output() reloadChat: EventEmitter<void> = new EventEmitter<void>();
  attendToChatCache: any;
  supportTicketId: string;
  pendingOrders: Array<any> = [];
  activeMainRegimens: Array<any> = [];
  data: any = {};
  languageStringIdMap: { [key: string]: string };
  baseUrlForWebApp: string;
  internalUserRoles: Array<string> = [];
  blockInputAfterMessage: boolean;
  internalUser: any;
  clevertapSupportedEvents: Array<string> = ['ringingNoResponse', 'sendPaymentQR', 'sendProductInstructions', 'blurPhoto'];
  modes: Array<{ [key: string]: string }>;
  selectedOrder: any;
  moment: any;
  mostRecentMessage: any;
  ui: any;
  userActiveRegimensAvailableCount: number;
  callNeeded: boolean;
  placeHolder: string = 'Type message...';

  @ViewChild(AudioRecorderComponent) audioRecorderComponent: AudioRecorderComponent;
  constructor(public connectionService: ConnectionService,
    public broadcaster: Broadcaster,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private storage: LocalStorage,
    public appConfig: AppConfig) {
    this.moment = moment;
  }

  async ngOnInit(): Promise<void> {
    this.internalUser = this.connectionService.getCurrentUser();
    this.supportTicketId = this.route.parent.snapshot.queryParams.ticketId;
    this.languageStringIdMap = this.appConfig.Shared.LanguageStringId;
    this.baseUrlForWebApp = this.connectionService.getWebAppUrl();
    this.internalUserRoles = this.storage.getJsonValue('userRoles');
    this.userActiveRegimensAvailableCount = await ApiConnector.count(Table.Regimen, {
      where: { forUser: this.patient, active: true },
    });
    this.initModes();
    this.reset();
    try {
      await Promise.all([
        this.findMostRecentMessage(),
        this.connectionService.findAttendToChatCache(this.patient).then((data: any) => this.attendToChatCache = data),
        this.updateCallStatus(),
      ]);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async updateCallStatus(): Promise<void> {
    if (!this.supportTicketId) return;
    const supportTicket = new Table.SupportTicket();
    supportTicket.id = this.supportTicketId;
    const latestSBAR = await ApiConnector.findOne(Table.SBARHistory, {
      where: {
        supportTicket,
      },
      descending: 'createdAt',
      project: ['callNeeded'],
    });
    if (latestSBAR.get('callNeeded')) {
      this.callNeeded = true;
    }
  }

  async findMostRecentMessage(): Promise<any> {
    if (this.supportTicketId) {
      if (!this.patient.id) {
        return;
      }
      const supportTicket = new Table.SupportTicket();
      supportTicket.id = this.route.snapshot.queryParams.ticketId;
      [this.mostRecentMessage] = await this.connectionService.findSupportChat({
        where: { user: this.patient, supportTicket },
        descending: 'createdAt',
        limit: 1,
        project: ['context'],
      });
    }
  }

  reset(): void {
    this.ui = {
      page: 0,
      lazyload: true,
      loading: false,
      limit: 100,
      savingMessage: false,
    };
  }

  async onMessageModeChange(): Promise<void> {
    switch (this.data.mode) {
      case this.appConfig.Shared.Assistant.Mode.GENERIC_USER_INPUT:
      case this.appConfig.Shared.Assistant.Mode.PAYMENT: {
        await this.fetchUserPendingOrders();
        break;
      }
      case this.appConfig.Shared.Assistant.Mode.REGIMEN: {
        await this.getRegimens();
        break;
      }
      case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_SIDE_FACE: {
        await this.onImageRequest(this.languageStringIdMap.REQUEST_YOUR_SIDE_FACE_IMAGE, this.languageStringIdMap.TAKE_PHOTO);
        break;
      }
      case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_FRONT_FACE: {
        await this.onImageRequest(this.languageStringIdMap.REQUEST_YOUR_FRONT_FACE_IMAGE, this.languageStringIdMap.TAKE_PHOTO);
        break;
      }
      case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_HAIR: {
        await this.onImageRequest(this.languageStringIdMap.REQUEST_YOUR_HAIR_IMAGE, this.languageStringIdMap.TAKE_PHOTO);
        break;
      }
      case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_BODY: {
        await this.onImageRequest(this.languageStringIdMap.REQUEST_YOUR_BODY_IMAGE, this.languageStringIdMap.TAKE_PHOTO);
        break;
      }
      case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_REPORT: {
        await this.onImageRequest(this.languageStringIdMap.REQUEST_IMAGE_REPORT, this.languageStringIdMap.UPLOAD);
        break;
      }
      case this.appConfig.Shared.Assistant.Mode.REQUEST_REPORT: {
        const dialogRef = this.dialog.open(InvestigationModule);
        dialogRef.afterClosed().subscribe((test: any) => {
          if (!test) return;
          if (this.data.message) this.data.message += '<br> <br>';
          if (test.name) {
            this.data.message += test.name;
            this.data.message += '<br> <br>';
          }
          test.tests.forEach((value: any, index: number) => {
            this.data.message += `${index + 1}. ${value}`;
            this.data.message += '<br>';
          });
        });
        await this.onImageRequest(this.languageStringIdMap.REQUEST_REPORT, this.languageStringIdMap.UPLOAD);
        break;
      }
      case this.appConfig.Shared.Assistant.Mode.AUDIO: {
        this.placeHolder = 'Type message before start recording';
        break;
      }
      default:
        this.placeHolder = 'Type message...';
    }
  }

  async onImageRequest(messageStringId: string, buttonStringId: string): Promise<void> {
    const [languageString, takePhotoLanguageString]: any = await Promise.all([
      this.connectionService.getLanguageString(messageStringId),
      this.connectionService.getLanguageString(buttonStringId),
    ]);
    const [message, buttonText]: any = await Promise.all([
      this.connectionService.getTranslatedStringOfUserLanguage(this.patient, languageString),
      this.connectionService.getTranslatedStringOfUserLanguage(this.patient, takePhotoLanguageString),
    ]);
    this.data.message = message;
    this.data.buttonText = buttonText;
  }

  async getRegimens(): Promise<void> {
    if (this.activeMainRegimens?.length) {
      return;
    }
    const payLoad: RequestQueryPayload<Table.Regimen> = {
      where: { active: true, type: this.appConfig.Shared.Regimen.Type.MAIN },
      project: ['title', 'regimenId'],
      ascending: 'regimenId',
      limit: 2000,
    };
    this.activeMainRegimens = await this.connectionService.findRegimens(payLoad);
  }

  async fetchUserPendingOrders(): Promise<void> {
    if (this.pendingOrders.length) {
      return;
    }
    const orders = await this.connectionService.findOrders({
      where: {
        user: this.patient,
        stage: {
          $nin: [
            this.appConfig.Shared.Order.Stage.CANCELED,
            this.appConfig.Shared.Order.Stage.DELIVERED,
            this.appConfig.Shared.Order.Stage.INITIAL,
            this.appConfig.Shared.Order.Stage.RETURNED,
          ],
        },
      },
      project: ['orderNumber', 'type', 'amount'],
    });
    this.pendingOrders.push(...orders);
  }

  openImageUploadDialog(): any {
    if (!this.supportTicketId) {
      this.broadcaster.broadcast('NOTIFY', { message: 'supportTicketId missing',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const dialogRef = this.dialog.open(ImageUploadModal, { data: { title: 'Upload other image/pdf',
      userObj: this.patient,
      context: { supportTicketId: this.supportTicketId } } });
    dialogRef.afterClosed().subscribe(() => this.reloadChat.emit());
  }

  async sendCleverTapEvent(selectedCleverTapEvent: string): Promise<void> {
    if (!selectedCleverTapEvent) return;
    try {
      await this.connectionService.trackEventInCleverTap(selectedCleverTapEvent, this.patient.get('username'));
      this.broadcaster.broadcast('NOTIFY', { message: 'Event Sent',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  isConfirmationNeeded(): void {
    // this.confirmationNeededFlag = !this.confirmationNeededFlag;
  }

  async sendFeedbackForm(): Promise<void> {
    try {
      await this.connectionService.createNPSFeedback({
        userId: this.patient.id,
        createdByUserId: this.internalUser.id,
      });
      this.broadcaster.broadcast('NOTIFY', { message: 'Sent Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async deleteLastOperatorMessage(): Promise<void> {
    const supportTicket = new Table.SupportTicket();
    supportTicket.id = this.supportTicketId;
    const [message]: Array<any> = await this.connectionService.findSupportChat({
      where: { user: this.patient, supportTicket },
      descending: 'createdAt',
      limit: 1,
    });
    try {
      message.destroy();
      this.broadcaster.broadcast('NOTIFY', { message: 'Deleted last operator message', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
    this.reloadChat.emit();
  }

  changeSelectedOrder(index: any): any {
    const localIndex = +index;
    if (localIndex === -1) {
      this.selectedOrder = '';
      return;
    }
    this.selectedOrder = this.pendingOrders[localIndex];
  }

  afterSaveAudioFile(url: string): void {
    this.data.metadata = `audioPath=${url}`;
  }

  onAudioChange(audioChunks: any[] = []): void {
    if (audioChunks.length) {
      // this.data.message = this.audioNote;
    } else {
      this.data.message = '';
    }
  }

  initModes(): void {
    if (this.supportTicketId) {
      this.modes = [
        { key: this.appConfig.Shared.Assistant.Mode.WITH_TEXT_REPLY, value: 'Text input' },
        { key: this.appConfig.Shared.Assistant.Mode.AUDIO, value: 'Voice' },
        { key: this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_FRONT_FACE, value: 'Request front face image' },
        { key: this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_SIDE_FACE, value: 'Request side face image' },
        { key: this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_HAIR, value: 'Request hair image' },
        { key: this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_BODY, value: 'Request body image' },
        { key: this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_REPORT, value: 'Request report image' },
        { key: this.appConfig.Shared.Assistant.Mode.REQUEST_REPORT, value: 'Request report (Investigations / Tests)' },
        { key: this.appConfig.Shared.Assistant.Mode.GENERIC_USER_INPUT, value: 'Order status' },
        { key: this.appConfig.Shared.Assistant.Mode.CUSTOM_FORM, value: 'Consent Form' },
        { key: this.appConfig.Shared.Assistant.Mode.REGIMEN, value: 'Regimen' },
        { key: this.appConfig.Shared.Assistant.Mode.PAYMENT, value: 'Payment' },
        { key: this.appConfig.Shared.Assistant.Mode.CUSTOM_DIET, value: 'Send Custom Diet link' },
      ];
      return;
    }
    this.modes = [
      { key: this.appConfig.Shared.Assistant.Mode.REGIMEN, value: 'Regimen' },
    ];
  }

  async insertMessage(): Promise<any> {
    if (this.ui.savingMessage) return Promise.resolve();
    try {
      this.ui.savingMessage = true;
      if (this.internalUser.get('type') === 'doctor' && this.data.mode === 'WITH_TEXT_REPLY') {
        const imgPath = this.internalUser.get('DoctorImageThumbnail');
        const thumbnail = imgPath;
        const content = `${this.internalUser.get('DoctorDisplayName')} has sent you a message`;
        const url = `${this.baseUrlForWebApp}/userConfirmation?title=${content}&message=${this.data.message}
      &drImage=${imgPath}&time=${moment().format('LT')}`;
        this.data.metadata = `contentUrl:${url};thumbnail:${thumbnail};content:${content};`;
      }
      switch (this.data.mode) {
        case this.appConfig.Shared.Assistant.Mode.AUDIO: {
          if (!this.data.metadata) {
            await this.audioRecorderComponent.saveRecording();
            if (!this.data.metadata) return this.showErrorPopUp('Audio url is not present/saved.');
          }
          if (!this.data.message) {
            this.data.message = 'No AudioNote Available';
          }
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.data.mode,
            metadata: this.data.metadata,
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.GENERIC_USER_INPUT: {
          if (!this.selectedOrder) {
            this.showErrorPopUp('choose an order');
            return 0;
          }
          return this.connectionService.sendUserOrderStatusMessage(this.patient.get('username'), this.selectedOrder.id);
        }
        case this.appConfig.Shared.Assistant.Mode.CUSTOM_DIET: {
          if (!this.data.dietLink) {
            this.showErrorPopUp('Enter Diet Link');
            return 0;
          }
          this.blockInputAfterMessage = false;
          const Inputs = [{
            type: this.appConfig.Shared.Question.InputType.BUTTON,
            action: this.appConfig.Shared.Question.InputAction.ACTION,
            params: {
              actionName: 'OpenInCustomTab',
              url: this.data.dietLink,
            },
            text: 'View Diet',
          }];
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.appConfig.Shared.Assistant.Mode.STICKY_ACTION,
            Inputs,
            params: {},
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.REGIMEN: {
          return this.addOrResendRegimen(this.data.regimenObj);
        }
        case this.appConfig.Shared.Assistant.Mode.CUSTOM_FORM: {
          let questionId: string;
          switch (this.patient.get('Gender')) {
            case 'Male': {
              questionId = this.patient.get('languagePreference') === 'hi'
                ? 'consentFormIsotretinoinMaleHi'
                : 'consentFormIsotretinoinMaleEn';
              break;
            }
            case 'Female': {
              questionId = this.patient.get('languagePreference') === 'hi'
                ? 'consentFormIsotretinoinFemaleHi'
                : 'consentFormIsotretinoinFemaleEn';
              break;
            }
            default: {
              this.broadcaster.broadcast('NOTIFY', { message: 'Gender is not present',
                type: this.appConfig.Shared.Toast.Type.ERROR });
              return Promise.resolve();
            }
          }
          const context = { supportTicketId: this.supportTicketId };
          await this.connectionService.sendQuestionTo(questionId, this.patient.get('username'), context);
          await this.connectionService.sendQuestionTo('consentFormIsotretinoinAcknowledgement', this.patient.get('username'), context);
          this.showMessageSentToastAndResetChatInput();
          return Promise.resolve();
        }
        case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_FRONT_FACE: {
          const Inputs = [{
            type: this.appConfig.Shared.Question.InputType.BUTTON,
            action: this.appConfig.Shared.Question.InputAction.ACTION,
            params: {
              actionName: 'InstantCheckup',
              queryParams: JSON.stringify({ tag: this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE }),
            },
            text: this.data.buttonText,
          }];
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.appConfig.Shared.Assistant.Mode.STICKY_ACTION,
            Inputs,
            params: {},
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_SIDE_FACE: {
          const Inputs = [{
            type: this.appConfig.Shared.Question.InputType.BUTTON,
            action: this.appConfig.Shared.Question.InputAction.ACTION,
            params: {
              actionName: 'InstantCheckup',
              queryParams: JSON.stringify({ tag: this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE }),
            },
            text: this.data.buttonText,
          }];
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.appConfig.Shared.Assistant.Mode.STICKY_ACTION,
            Inputs,
            params: {},
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_HAIR: {
          const Inputs = [{
            type: this.appConfig.Shared.Question.InputType.BUTTON,
            action: this.appConfig.Shared.Question.InputAction.ACTION,
            params: {
              actionName: 'InstantCheckup',
              queryParams: JSON.stringify({ tag: this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT }),
            },
            text: this.data.buttonText,
          }];
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.appConfig.Shared.Assistant.Mode.STICKY_ACTION,
            Inputs,
            params: {},
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_BODY: {
          const Inputs = [{
            type: this.appConfig.Shared.Question.InputType.BUTTON,
            action: this.appConfig.Shared.Question.InputAction.ACTION,
            params: {
              actionName: 'InstantCheckup',
              queryParams: JSON.stringify({ tag: this.appConfig.Shared.InstantCheckup.Type.BODY }),
            },
            text: this.data.buttonText,
          }];
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.appConfig.Shared.Assistant.Mode.STICKY_ACTION,
            Inputs,
            params: {},
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_REPORT: {
          const Inputs = [{
            type: this.appConfig.Shared.Question.InputType.BUTTON,
            action: this.appConfig.Shared.Question.InputAction.ACTION,
            params: {
              actionName: 'InstantCheckup',
              queryParams: JSON.stringify({ tag: this.appConfig.Shared.InstantCheckup.Type.REPORT }),
            },
            text: this.data.buttonText,
          }];
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.appConfig.Shared.Assistant.Mode.STICKY_ACTION,
            Inputs,
            params: {},
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.REQUEST_REPORT: {
          const Inputs = [{
            type: this.appConfig.Shared.Question.InputType.BUTTON,
            action: this.appConfig.Shared.Question.InputAction.ACTION,
            params: {
              actionName: 'UploadReport',
            },
            text: this.data.buttonText,
          }];
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.appConfig.Shared.Assistant.Mode.STICKY_ACTION,
            Inputs,
            params: {},
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.PAYMENT: {
          const params: { notes: String, orderId: any } = {
            notes: this.data.notes,
            orderId: this.data.orderId,
          };
          const order = this.pendingOrders.find((o: any) => (o.id === this.data.orderId));
          const Inputs = [{
            type: this.appConfig.Shared.Question.InputType.BUTTON,
            action: this.appConfig.Shared.Question.InputAction.ACTION,
            params: {
              actionName: 'Checkout',
              url: `/user/order/${this.data.orderId}/payment`,
            },
            text: `Pay (Rs. ${order.get('amount')})`,
          }];
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.appConfig.Shared.Assistant.Mode.STICKY_ACTION,
            params,
            Inputs,
            context: this.mostRecentMessage?.get('context'),
          });
        }
        case this.appConfig.Shared.Assistant.Mode.SINGLE_SELECTOR:
        case this.appConfig.Shared.Assistant.Mode.MULTI_SELECTOR:
        default: {
          return this.sendMessageToUserAndUpdateChatWindow({
            msg: this.data.message,
            mode: this.data.mode,
            metadata: this.data.metadata,
            context: this.mostRecentMessage?.get('context'),
          });
        }
      }
    } catch (err) {
      this.broadcaster.broadcast('NOTIFY', { message: err.message.toString(),
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.ui.savingMessage = false;
    }
    return Promise.resolve();
  }

  async sendMessageToUserAndUpdateChatWindow({ msg, mode, metadata, metadataAction, params, Inputs, context }: ChatType.MessageType)
    : Promise<any> {
    this.saveMessage({ msg, mode, metadata, metadataAction, params, Inputs, context });
  }

  async saveMessage({ msg, mode, metadata, metadataAction, params, Inputs, imageCompareLinkFlag }: ChatType.MessageType): Promise<void> {
    if (this.supportTicketId) {
      const ticket = new Table.SupportTicket();
      ticket.id = this.route.snapshot.queryParams.ticketId;
      try {
        await this.connectionService.replyToUserTicket({
          ticket,
          user: this.patient,
          userId: this.patient.get('username'),
          msg,
          mode,
          metadata,
          Inputs,
          context: { supportTicketId: this.supportTicketId },
        });
        this.showSuccessPopUp('Message Sent');
        this.data = {};
        this.reset();
        await this.findMostRecentMessage();
        this.reloadChat.emit();
      } catch (error) {
        this.ui.savingMessage = false;
        this.showErrorPopUp(error.message);
      }
      return;
    }
    this.broadcaster.broadcast('NOTIFY', { message: 'message cannot be sent from here',
      type: this.appConfig.Shared.Toast.Type.ERROR });
  }

  async showMessageSentToastAndResetChatInput(): Promise<void> {
    this.showSuccessPopUp('Message sent');
    this.reloadChat.emit();
  }

  async addOrResendRegimen(currentObj: any): Promise<any> {
    try {
      this.ui.savingMessage = true;
      const regimenId: string = currentObj.get('regimenId');

      await this.connectionService.sendOrCreatePersonalizedRegimen(
        regimenId,
        this.patient.get('username'),
        { supportTicketId: this.route.snapshot.queryParams.ticketId });
      // todo update regimen view page
      this.ui.savingMessage = false;
    } catch (err) {
      this.ui.savingMessage = false;
      this.showErrorPopUp(err.message);
    }
  }

  showErrorPopUp(message: string): void {
    this.broadcaster.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.ERROR });
  }

  showSuccessPopUp(message: string): void {
    this.broadcaster.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.SUCCESS });
  }
}
