import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'add-checkup-concern',
  templateUrl: './add-concern.html',
})
export class AddConcernModal {
  selectedConcern: any;
  mainConcernList: Array<any>;
  class: string;
  constructor(private conn: ConnectionService,
    private router: Router,
    private appConfig: AppConfig,
    private injector: Injector,
    private dialogRef: MatDialogRef<AddConcernModal>) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.mainConcernList = data.mainConcernList;
  }

  async ngOnInit(): Promise<void> {
  }

  updateConcern(): void {
    this.dialogRef.close({ issue: this.selectedConcern.get('displayNameLanguageString').get('en'),
      concernLanguageString: this.selectedConcern });
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
