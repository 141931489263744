import { Component, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WindowRefService } from 'src/services/window-ref-service';
import { Table } from 'api-client';
import { InputHelperService } from '../../input/input-helper';
import { AppConfig } from '../../../app/app.config';
import { ConnectionService } from '../../../services/connection-service';
import { ArticleImageUploadModal } from '../../navbar/articleImageUploadModal';
import { LanguageStringSelectionComponent } from '../language-string-selection/language-string-selection.component';

@Component({
  selector: 'language-string-multiple-selection-with-tag',
  templateUrl: './language-string-multiple-selection-with-tag.html',
  styleUrls: ['./language-string-multiple-selection-with-tag.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class LanguageStringMultipleSelectionWithTagComponent {
  imageUrl: string;

  @Input('productObj') productObj: any;
  @Input('title') title: string;
  @Input('showUploadButton') showUploadButton: boolean;
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    if (!parseObj) return;
    this.parseObj = parseObj;
    this.onInputUpdate();
  }

  @Input('name')
  set onOnUpdateName(name: string) {
    this.name = name;
    this.onInputUpdate();
  }

  @Input('tags')
  set onOnUpdateDefaultTags(tags: Array<string>) {
    this.conn.findLanguageStringTags({
      where: {
        name: tags,
      },
    }).then((tagsPointer: Array<any>) => {
      this.defaultTags = tagsPointer?.map((each: any) => each?.get('name'));
      this.tagsString = this.defaultTags;
    });
  }

  list: Array<any> = [];
  name: string;
  parseObj: any;
  tagsString: Array<string> = [];
  showPopup: boolean;
  editLanguageStringParams: { index?: number } = {};
  defaultTags: Array<string> = [];
  @ViewChild(LanguageStringSelectionComponent) languageStringSelectionComponent: LanguageStringSelectionComponent;
  constructor(public inputHelper: InputHelperService,
    private appConfig: AppConfig,
    private dialog: MatDialog,
    private router: Router,
    private conn: ConnectionService,
    private windowService: WindowRefService) {
  }

  ngOnInit(): void {
    this.tagsString = this.defaultTags;
  }
  onTagUpdate(tags: Array<any>): void {
    if (tags.length) this.tagsString = tags.map((tag: any) => tag.get('name'));
    else this.tagsString = this.defaultTags;
  }

  addNewItem(): void {
    const prevArticle = JSON.parse(JSON.stringify(this.parseObj));
    let popupValue: boolean;
    if (prevArticle?.article) {
      popupValue = confirm('Article already present. Do you want to change it?');
    }
    if (popupValue || !prevArticle?.article) {
      this.list.push(new Table.LanguageString());
      this.editLanguageStringParams = { index: this.list.length - 1 };
      this.showModal();
    }
  }

  moveUp(index: number): void {
    let temp: any;
    if (index === 0) {
      temp = this.list[0];
      this.list[0] = this.list[this.list.length - 1];
      this.list[this.list.length - 1] = temp;
    } else {
      temp = this.list[index];
      this.list[index] = this.list[index - 1];
      this.list[index - 1] = temp;
    }
  }

  private onInputUpdate(): void {
    if (!this.name || !this.parseObj) return;
    this.list = this.inputHelper.getValue(this.parseObj, this.name) || [];
  }

  private onResultUpdate(): void {
    this.inputHelper.setValue(this.parseObj, this.name, this.list);
  }

  selectionUpdate(item: any): void {
    if (this.isImage(item)) {
      this.imageUrl = item.get('en');
    } else delete this.imageUrl;
    this.list[this.editLanguageStringParams.index] = item;
    this.onResultUpdate();
  }

  isImage(languageString: any): boolean {
    return (languageString.get('en')?.endsWith('.jpeg')
      || languageString.get('en')?.endsWith('.png')
      || languageString.get('en')?.endsWith('.jpg'));
  }

  showModal(): void {
    this.showPopup = true;
  }

  hideModal(): void {
    this.showPopup = false;
  }

  selectionRemove(index: any): void {
    this.list.splice(index, 1);
    this.onResultUpdate();
  }

  openUploadArticleModel(): void {
    const dialogRef = this.dialog.open(ArticleImageUploadModal);
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (!result) return;
      const languageStringObj = this.list[this.editLanguageStringParams.index];
      languageStringObj.set('en', result);
      languageStringObj.set('createdBy', this.conn.getCurrentUser());
      languageStringObj.set('tags', this.tagsString);
      await languageStringObj.save();
      this.languageStringSelectionComponent.languageStringObj = languageStringObj;
      this.languageStringSelectionComponent.showSearch = false;
      await this.languageStringSelectionComponent.updateOptions();
      this.showPopup = true;
    });
  }

  editArticle(index: any): void {
    if (this.parseObj) {
      const articleLink = this.parseObj.articleImagesLanguageString[index];
      this.windowService.nativeWindow.open([`/languageString/${articleLink?.id}/edit`]);
    }
  }
}
