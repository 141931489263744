import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import EditorJS, { API, OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import { AppConfig } from 'src/app/app.config';
import { InputHelperService } from '../input/input-helper';

@Directive({
  selector: '[appWysiwyg]',
})
export class WysiwygDirective implements OnChanges {
  editor: EditorJS;
  @Input() parseObj: any;
  @Input() name: string;
  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() disabled: boolean = false;

  constructor(
    private elementRef: ElementRef,
    public appConfig: AppConfig,
    private inputHelper: InputHelperService,
  ) {
    this.init();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.editor.isReady;
    if (!this.name || !this.parseObj || !this.editor || !this.placeholder) return;

    this.inputHelper.setValue(this.parseObj, 'isRichText', true);
    const patchValue = this.inputHelper.getValue(this.parseObj, this.name);
    if (!patchValue) return;
    this.editor.blocks.renderFromHTML(patchValue);
  }

  init(): void {
    this.editor = new EditorJS({
      holder: this.elementRef.nativeElement,
      tools: {
        header: Header,
        list: List,
      },
      onChange: async (api: API) => {
        const json: OutputData = await api.saver.save();
        const html = this.toHtml(json);
        this.inputHelper.setValue(this.parseObj, this.name, html);
      },
    });
  }

  /* eslint-disable no-param-reassign */
  toHtml(json: OutputData): string {
    return json.blocks.reduce((html: string, block: any) => {
      switch (block.type) {
        case 'paragraph': html += `<p>${block.data?.text}</p>`;
          break;
        case 'header': html += `<h${block?.data?.level}>${block.data?.text}</h${block?.data?.level}>`;
          break;
        case 'list': {
          const list: string = block?.data?.style === 'ordered' ? 'ol' : 'ul';
          const items: string = block?.data?.items.reduce((h: string, item: string) => `${h}<li>${item}</li>`, '');
          html += `<${list}>${items}</${list}>`;
          break;
        }
        default: html += '';
      }
      return html;
    }, '');
  }
  /* eslint-enable no-param-reassign */
}
