import * as moment from 'moment';
import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Table } from 'api-client';
import { AppConfig } from '../../../../app.config';
import { ConnectionService } from '../../../../../services/connection-service';

@Component({ selector: 'assign-chat-history', templateUrl: './assign-chat-history.html' })
export class AssignChatHistoryComponent {
  user: any;
  assignedChats: Array<any> = [];

  constructor(
    public dialogRef: MatDialogRef<AssignChatHistoryComponent>,
    public injector: Injector,
    public appConfig: AppConfig,
    private conn: ConnectionService) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.user = data.user;
    this.loadMore()
      .catch((error: any) => alert(error.message || error));
  }

  async hideModal(): Promise<any> {
    this.dialogRef.close();
  }

  async changeAssignedDoctor(item: any): Promise<void> {
    const assignedChat = new Table.AssignedChat();
    assignedChat.id = item.objectId;
    await assignedChat.save({}, { context: { reAllocateDoctor: true } });
    await this.loadMore();
  }

  async loadMore(): Promise<void> {
    const assignedChats = await this.conn.findAssignedChats({
      where: { user: this.user },
      include: ['assignedBy', 'completedBy', 'allocatedDoctor', 'allocatedOperator'],
      project: [
        'assignedBy.username' as 'assignedBy',
        'completedBy.username' as 'completedBy',
        'allocatedDoctor.username' as 'allocatedDoctor',
        'allocatedOperator.username' as 'allocatedOperator',
        'message',
        'status',
        'completedAt',
        'responseMessage',
        'team',
      ],
    });
    this.assignedChats = assignedChats.map((each: any) => {
      const json = each.toJSON();
      json.createdAt = moment(json.createdAt).format('DD MMM YY, HH:mm A');
      if (json.completedAt) {
        json.completedAt = moment(json.completedAt.iso).format('DD MMM YY, HH:mm A');
      }
      json.assignedDoctor = json.allocatedDoctor?.username || '-';
      json.assignedOperator = json.allocatedOperator?.username.replace('operator', '') || '-';
      json.assignedByName = json.assignedBy?.username
        ? json.assignedBy.username.replace('operator', '')
        : 'BOT';
      json.completedByName = json.completedBy?.username
        ? json.completedBy.username.replace('operator', '')
        : '-';
      return json;
    });
  }
}
