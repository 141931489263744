import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { ApiClientConstant, ApiConnector, ParseKeys, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { InputType } from '../../../../../typings/client/input';
import { ValidationService } from '../../../../services/validation-service';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({
  selector: 'catalog-edit',
  templateUrl: './catalog-edit.html',
  styleUrls: ['./catalog-edit.scss'],
})
export class CatalogEditComponent implements OnDestroy {
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  Name: string;
  isValidImageFile: boolean = true;
  productObj: any;
  articles: Array<any> = [];
  articleId: string;
  articleWithTagId: string;
  articleObj: any;
  articleWithTagObj: any;
  userTagForArticle: string;
  disabled: boolean;
  safteyProfileOptions: Array<InputType.SelectOption>;
  userTagList: string[] = [
    'MALE',
    'FEMALE',
    'AGE_BETWEEN_20_TO_40',
    'AGE_BELOW_20',
    'AGE_ABOVE_40',
    'ACNE_OR_PIMPLES',
    'FACE_PIGMENTATION',
    'HAIR_FALL',
    'DANDRUFF',
    'SMOKER',
    'POST_ACNE',
    'ACNE_DETECTED',
    'HORMONAL_IMBALANCE',
    'PREGNANT',
    'STEROID',
    'NEW_MOM',
    'BODY',
    'COMPLEX_ARTICLE',
  ];
  initialArticleId: string;
  initialArticleWithTagId: string;
  mainConcerns: any;
  imageSrc: any;
  imageFiles: Array<any> = [];
  ui: { minEffectivePrice: number, maxSellingPrice: number, cgst: number, sgst: number } = {
    minEffectivePrice: 0,
    maxSellingPrice: 0,
    cgst: 0,
    sgst: 0,
  };
  GST_SLABS: Array<number> = [5, 12, 18];
  gstSelectedSlab: number;
  productTags: Array<string> = [];
  inventoryStatus: Array<InputType.SelectOption>;
  paymentOptions: Array<InputType.SelectOption>;
  trackingCategory: Array<InputType.SelectOption>;
  gstnOptions: Array<InputType.SelectOption>;
  categories: Array<InputType.SelectOption>;
  subCategories: Array<InputType.SelectOption>;
  quantityUnits: Array<InputType.SelectOption>;
  subscriptions: Array<Subscription>;
  shopTags: Array<any> = [];
  excludeBoostProductsTags: Array<any> = [];
  boostYourRegimenTags: Array<any> = [];
  currentAttachedCatalog: any;
  MAX_IMAGE_SIZE_IN_KB: number = 150000;
  keyActive: string;
  supportingActive: string;
  baseIngredients: string;
  extraImages: any[] = [];
  currentUser: any;
  searchKeywordInput: string = '';
  searchKeywords: string[] = [];
  isInventoryStatusDiscontinued:boolean = false;
  constructor(private router: Router,
    private conn: ConnectionService,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private broadcasterService: Broadcaster,
    private validationService: ValidationService) {
    // this.dataSourceSeller = Observable
    //   .create((observer: any) => observer.next(this.Name))
    //   .pipe(mergeMap((token: string) => this.getSellers(token)));
  }

  async ngOnInit(): Promise<any> {
    this.currentUser = this.conn.getCurrentUser();
    this.broadcasterService.broadcast('ChatUserUpdate', { user: this.currentUser });
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
    this.subscriptions = [];
    this.shopTags = Object.keys(ApiClientConstant.User.MarketingTags).map((key: string) => ({
      value: ApiClientConstant.User.MarketingTags[key],
      display: ApiClientConstant.User.MarketingTags[key],
    }));
    this.excludeBoostProductsTags = Object.keys(this.appConfig.Shared.ExcludeBoostProductsTags).map((key: string) => ({
      value: this.appConfig.Shared.ExcludeBoostProductsTags[key],
      display: this.appConfig.Shared.ExcludeBoostProductsTags[key],
    }));
    this.boostYourRegimenTags = Object.entries(this.appConfig.Shared.boostYourRegimenTags).map(([key, value]: [string, string]) => ({
      value: key,
      display: value,
    }));
    this.gstnOptions = [
      { display: 'EssentialAesthetics', value: '29AUOPS1203M2Z5' },
      { display: 'CureSkin', value: '29AAKFH3537K1ZY' },
      { display: 'Cure and Care Wellness Private Limited', value: '29AAJCC8379A1ZD' },
      { display: 'Dr. Gomez\'s Skin Innovations', value: '29CDXPG3751L1ZR' },
    ];
    this.paymentOptions = [
      { display: 'Indefinite', value: ApiClientConstant.Catalog.REORDER_TYPE.INDEFINITE },
      { display: 'Limited', value: ApiClientConstant.Catalog.REORDER_TYPE.LIMITED },
      { display: 'Never', value: ApiClientConstant.Catalog.REORDER_TYPE.NEVER },
    ];
    this.trackingCategory = [
      { display: 'Sunscreen', value: ApiClientConstant.Catalog.TRACKING_CATEGORY_TYPE.SUNCSCREEN },
      { display: 'Facewash', value: ApiClientConstant.Catalog.TRACKING_CATEGORY_TYPE.FACEWASH },
      { display: 'Repair', value: ApiClientConstant.Catalog.TRACKING_CATEGORY_TYPE.REPAIR },
      { display: 'Moisturiser', value: ApiClientConstant.Catalog.TRACKING_CATEGORY_TYPE.MOISTURISER },
      { display: 'Protectors', value: ApiClientConstant.Catalog.TRACKING_CATEGORY_TYPE.PROTECTORS },
    ];
    this.categories = Object.keys(ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories)
      .map((category: keyof typeof ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories) => ({
        display: category,
        value: category,
      }));
    this.subCategories = [
      { display: 'ANTI_ACNE', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.FACE[0] },
      { display: 'DARK_CIRCLES', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.FACE[1] },
      { display: 'GLOW_RADIANCE_MAINTENANCE', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.FACE[2] },
      { display: 'HYPER_PIGMENTATION', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.FACE[3] },
      { display: 'SCARRING', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.FACE[4] },
      { display: 'OTHER_FACE', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.FACE[5] },
      { display: 'FRIZZY_HAIR', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.HAIR[1] },
      { display: 'GREY_HAIR', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.HAIR[2] },
      { display: 'DANDRUFF', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.HAIR[0] },
      { display: 'HAIRFALL', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.HAIR[3] },
      { display: 'OTHER_HAIR', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.HAIR[4] },
      { display: 'BODY_DRYNESS', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.BODY[0] },
      { display: 'OTHER_BODY', value: ApiClientConstant.Catalog.CategoriesAndAllowedSubCategories.BODY[1] },
    ];
    this.safteyProfileOptions = [
      { display: 'Pregnancy And Breastfeeding Safe',
        value: ApiClientConstant.Catalog.SAFTEY_PROFILE_OPTION.PREGNANCY_AND_BREASTFEEDING_SAFE },
      { display: 'Pregnancy Category B',
        value: ApiClientConstant.Catalog.SAFTEY_PROFILE_OPTION.PREGNANCY_CATEGORY_B },
      { display: 'Limited Data On Saftey',
        value: ApiClientConstant.Catalog.SAFTEY_PROFILE_OPTION.LIMITED_DATA_ON_SAFTEY },
      { display: 'Contraindicated During Pregnancy and Breastfeeding',
        value: ApiClientConstant.Catalog.SAFTEY_PROFILE_OPTION.CONTRAINDICATED_DURING_PREGNANCY_AND_BREASTFEEDING },
    ];
    this.inventoryStatus = Object.keys(ApiClientConstant.Catalog.InventoryStatus).map((key: string) => ({
      display: ApiClientConstant.Catalog.InventoryStatus[key].replace(/_/g, ' '),
      value: ApiClientConstant.Catalog.InventoryStatus[key],
    }));
    this.quantityUnits = [
      { display: 'mg', value: 'mg' },
      { display: 'gms', value: 'gms' },
      { display: 'ml', value: 'ml' },
      { display: 'units', value: 'units' },
    ];
    this.productObj = new Table.Catalog();
    this.productObj.set('images', []);
    this.productObj.set('tax', {});
    this.subscribeToUrlQueryParams();
    this.mainConcerns = await this.conn.findMainConcernsOptions();
    this.articles = await this.conn.getArticleData({
      descending: 'createdAt',
    });
    const stringKeywords = this.productObj.get('searchKeywords');
    if (stringKeywords) {
      this.searchKeywords = stringKeywords.split(',');
    }

    if (this.productObj.get('articleWithTag')) {
      this.articleWithTagObj = this.productObj.get('articleWithTag');
      const pointer: {id: string}[] = Object.values(this.articleWithTagObj);
      this.userTagForArticle = <string>Object.keys(this.articleWithTagObj)[0];
      this.articleWithTagObj = await this.conn.getArticle(pointer[0].id, ['product']);
    } else {
      this.articleWithTagObj = new Table.ArticleData();
      this.updateDefaultValue(this.articleWithTagObj);
    }
    if (!this.productObj.get('article')) {
      this.articleObj = new Table.ArticleData();
      this.updateDefaultValue(this.articleObj);
    } else {
      this.articleObj = this.productObj.get('article');
      this.currentAttachedCatalog = await this.conn.getArticle(this.articleObj.id, ['product']);
      this.articleObj = this.currentAttachedCatalog;
    }
    if (this.productObj?.id
      && this.productObj.get('inventoryStatus') === 'DISCONTINUED') {
      this.isInventoryStatusDiscontinued = true;
    }
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
      },
      project: ['title', 'quantity', 'quantityUnit', 'type', 'margUnit', 'mrp', 'price'],
      ascending: 'margUnit',
      limit: 10,
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(product),
      object: product,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  autoCompleteOnProductSelect(item: { name: string; object: any }): void {
    this.productObj.set('alternateProduct', item.object);
    this.autoCompleteProductController.setValue('');
  }

  removeAlternateProduct(): void {
    this.productObj.unset('alternateProduct');
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.catalog) {
        this.productObj = this.route.parent.snapshot.data.catalog;
        if (!this.productObj.has('conditions')) {
          this.productObj.set('conditions', []);
        }
        if (this.productObj.get('article')) {
          this.articleId = this.productObj.get('article').id;
          this.initialArticleId = this.productObj.get('article').id;
        }
        if (this.productObj.get('articleWithTag')) {
          this.articleWithTagId = this.productObj.get('articleWithTag').id;
          this.initialArticleWithTagId = this.productObj.get('articleWithTag').id;
        }
        if (this.productObj.get('ingredients')) {
          const ingredients = this.productObj.get('ingredients');
          this.keyActive = ingredients.keyActive;
          this.baseIngredients = ingredients.baseIngredients;
          this.supportingActive = ingredients.supportingActive;
        }
        this.gstSelectedSlab = this.productObj.get('tax').cgst * 2;

        if (this.productObj.get('extraImages')) {
          const extraImages = this.productObj.get('extraImages');
          this.extraImages = Array.from({ length: extraImages.length || 0 }, (_: any, i: number): boolean => {
            this.productObj.set(`extraImages_${i}`, extraImages[i]);
            return true;
          });
        }
      }
    }));
  }

  updateDefaultValue(articleObj: any): void {
    const data = {
      params: { srcLanguageString: [], list: [], sections: [], lifestyle: [], diet: [] },
      template: 'imageArticle',
      clickToAction: 'BUY_PRODUCT',
      cssLink: 'https://assets.cureskin.com/app/css/style.min.css',
    };
    articleObj.set({ ...data, params: { ...data.params } });
  }

  // getSellers(name: string): Promise<any> {
  //   return this.conn.getSellers({ limit: 10, offset: 0 })
  //     .then((sellers: any) => sellers.map((object: any) => ({ name: object.get('Name'), object })));
  // }

  async saveProduct(): Promise<void> {
    if (this.productObj.get('inventoryStatus') === ApiClientConstant.Catalog.InventoryStatus.DISCONTINUED
      && !this.productObj.get('alternateProduct')) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'Alternate Product is missing',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    // if (this.productObj.get('prescriptionRequired') && !this.productObj.get('genericName')) {
    //   this.broadcasterService.broadcast('NOTIFY', { message: 'Generic Name missing for a rx-product',
    //     type: this.appConfig.Shared.Toast.Type.ERROR });
    //   return;
    // }
    if (this.productObj.get('ingredients')?.keyActive !== this.keyActive
      || this.productObj.get('ingredients')?.supportingActive !== this.supportingActive
      || this.productObj.get('ingredients')?.baseIngredients !== this.baseIngredients) {
      const ingredients = {
        keyActive: this.keyActive,
        supportingActive: this.supportingActive,
        baseIngredients: this.baseIngredients,
      };
      this.productObj.set('ingredients', ingredients);
    }
    if (this.productObj.get('effectiveCostPrice') <= 0) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'effective cost price can;t be zero',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (this.articleId && this.articleId !== this.initialArticleId) {
      const articleObj = this.articles.filter((article: any) => article.id === this.articleId)[0];
      this.productObj.set('article', articleObj);
    }
    if (this.articleWithTagId && this.articleWithTagId !== this.initialArticleWithTagId) {
      const articleObj = this.articles.filter((article: any) => article.id === this.articleId)[0];
      this.productObj.set('articleWithTag', articleObj);
    }
    if (this.productObj.dirtyKeys().includes('inventoryStatus')
        && [ApiClientConstant.Catalog.InventoryStatus.DISCONTINUED]
          .includes(this.productObj.get('inventoryStatus'))) {
      const regimensHavingThisProduct = await ApiConnector.count(Table.Regimen, {
        where: {
          type: ApiClientConstant.Regimen.Type.MAIN,
          active: true,
          products: { $in: [this.productObj] },
        },
      });
      if (regimensHavingThisProduct) {
        this.broadcasterService.broadcast('NOTIFY', { message: 'products is used in active main regimens',
          type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
      const variantsHavingThisProduct = await ApiConnector.find(Table.Regimen, {
        where: {
          type: this.appConfig.Shared.Regimen.Type.MAIN,
          active: true,
          $or: [{ products: { $in: [this.productObj] } }, { 'variants.products': { $in: [this.productObj] } }],
        },
        project: ['regimenId'],
      });
      if (variantsHavingThisProduct?.length) {
        this.broadcasterService.broadcast('NOTIFY', { message: `Regimen or Regimen Varaints have this product,
        ${variantsHavingThisProduct.map((regimen:any):string => regimen.get('regimenId'))}`,
        type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
    }
    if (isNaN(this.productObj.get('tax').cgst)) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'select GST slab',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (!this.validationService.validateMargId(this.productObj.get('margId'))) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'MargId must be greater than 0',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (!this.productObj.get('subCategory') || !this.productObj.get('category')) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'category or subCategory is not available',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (this.extraImages.length) {
      const images: any[] = [];
      this.extraImages.forEach((isVisible: boolean, index: number): void => {
        if (isVisible) {
          images.push(this.productObj.get(`extraImages_${index}`));
        }
        this.productObj.unset(`extraImages_${index}`);
      });
      this.productObj.set('extraImages', images);
    }
    const imageStr = this.articleObj?.get('params');
    if (imageStr?.articleImagesLanguageString?.length) {
      this.articleObj.set('product', this.productObj);
      await this.articleObj.save();
      this.productObj.set('article', this.articleObj);
    } else {
      this.productObj.unset('article');
    }

    const imageStrForArticleWithTag = this.articleWithTagObj?.get('params');
    if (imageStrForArticleWithTag?.articleImagesLanguageString?.length && this.userTagForArticle) {
      this.articleWithTagObj.set('product', this.productObj);
      await this.articleWithTagObj.save();
      this.productObj.set('articleWithTag', { [this.userTagForArticle]: this.articleWithTagObj });
    } else {
      this.productObj.unset('articleWithTag');
    }

    if (!this.isValidImageFile) return;
    if (this.isPricesInDecimal()) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'No decimals in MRP or price.',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (this.searchKeywords?.toString() !== this.productObj.get('searchKeywords')) {
      this.productObj.set('searchKeywords', this.searchKeywords?.toString());
    }
    try {
      this.disabled = true;
      await this.productObj.save();
      this.router.navigate([`/products/catalog/${this.productObj.id}`]);
    } catch (error) {
      this.disabled = false;
      this.broadcasterService.broadcast('NOTIFY', { message: error.message || error,
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  updateTaxDetails(): void {
    this.productObj.get('tax').cgst = (this.gstSelectedSlab / 2);
    this.productObj.get('tax').sgst = (this.gstSelectedSlab / 2);
    const price = this.productObj.get('price') || 0;
    const cgst = this.productObj.get('tax').cgst || 0;
    const sgst = this.productObj.get('tax').sgst || 0;
    this.ui.cgst = (price * (cgst / (100 + sgst + cgst)));
    this.ui.sgst = (price * (sgst / (100 + sgst + cgst)));
  }

  onLanguageStringTagsUpdate(tags: Array<any>): void {
    this.productTags = tags.map((tag: any) => tag.get('name'));
  }

  isPricesInDecimal(): boolean {
    return this.isDecimal(this.productObj.get('mrp')) || this.isDecimal(this.productObj.get('price'));
  }

  isDecimal(value: number): boolean { return (value % 1 !== 0); }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.subscriptions;
    delete this.productObj;
    delete this.router;
    delete this.conn;
    delete this.route;
    delete this.appConfig;
    delete this.validationService;
  }

  addExtraImages(): void {
    this.extraImages.push(true);
  }
  removeExtraImages(i: number): void {
    this.extraImages[i] = false;
  }

  addSearchKeywords(): void {
    if (this.searchKeywordInput) {
      this.searchKeywords?.push(this.searchKeywordInput.toString());
    }
    this.searchKeywordInput = '';
  }
  removeSearchKeyword(index: number): void {
    this.searchKeywords?.splice(index, 1);
  }

  async addPregAndBfTags(event: any): Promise<any> {
    const languageStringTag = await this.conn.getLanguageTagByName(
      [ApiClientConstant.Catalog.SAFTEY_PROFILE_OPTION.PREGNANCY_AND_BREASTFEEDING_SAFE]);
    const tags = this.productObj.get('tags') || [];
    if (event === ApiClientConstant.Catalog.SAFTEY_PROFILE_OPTION.PREGNANCY_AND_BREASTFEEDING_SAFE) {
      tags.push(languageStringTag[0]);
      this.productObj.set('tags', tags);
    } else {
      const index = tags.findIndex((obj: any) => obj.id === languageStringTag[0].id);
      if (index >= 1) {
        tags.splice(index, 1);
        this.productObj.set('tags', tags);
      }
    }
  }
  protected apiClientConstant: ApiClientConstant = ApiClientConstant;
}
