import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MargIdInfoComponent } from './marg-id-info.component';
import { MargIdListModule } from './list/index';
import { MargIdInfoEditModule } from './edit/index';

const ROUTES: Routes = [
  {
    path: 'margId',
    component: MargIdInfoComponent,
    loadChildren: (): any => MargIdListModule,
    pathMatch: 'full',
  },
  {
    path: 'margId/new',
    component: MargIdInfoComponent,
    loadChildren: (): any => MargIdInfoEditModule,
    pathMatch: 'full',
  },
  {
    path: 'margId/:id/edit',
    component: MargIdInfoComponent,
    loadChildren: (): any => MargIdInfoEditModule,
    pathMatch: 'full',
  },
];
@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [MargIdInfoComponent],
exports: [MargIdInfoComponent] })
export class MargIdInfoModule {}
