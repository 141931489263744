import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { ProductComponent } from './product.component';
import { RegimenModule } from './regimen';
import { ServiceModule } from './services';
import { CatalogModule } from './catalog';
import { MarketProductsModule } from './market-product';
import { RegimeSopModule } from './regimen-sop';
import { FeaturedProductsModule } from './featured-products/featured-products.module';
import { MediaLinkModule } from './media-link';
import { MargIdInfoModule } from './marg-id-info';
import { OfflineRegimenModule } from './offlineRegimen';
import { AudioTemplateModule } from './audio-template';

const ROUTES: Routes = [
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => RegimenModule,
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => OfflineRegimenModule,
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => RegimeSopModule,
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => CatalogModule,
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => ServiceModule,
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => MarketProductsModule,
  },
  {
    path: 'products/featuredProducts',
    component: ProductComponent,
    pathMatch: 'full',
    loadChildren: (): any => FeaturedProductsModule,
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => MargIdInfoModule,
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => MediaLinkModule,
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: (): any => AudioTemplateModule,
  },
];

@NgModule({ imports: [
  BrowserModule,
  RouterModule.forChild(ROUTES),
],
declarations: [ProductComponent],
exports: [ProductComponent] })
export class ProductModule {}
