
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Diet Card</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form class="col-xs-12" #dietForm="ngForm">
      <div class="col-xs-12">
        <label class="col-xs-12 mt-0">URL</label>
        <input class="col-xs-12" [(ngModel)]="diet.url" pattern="https.*" name="cardImage" type="text" placeholder="HTTPS image url only" required="required"/>
      </div>
      <div class="col-xs-12">
        <label>Card Heading </label>
        <input class="col-xs-12" [(ngModel)]="diet.heading" name="cardHeading" type="text" placeholder="card heading" required="required"/>
      </div>
      <div class="col-xs-12"> 
        <label class="col-xs-12">Card Text</label>
        <input class="col-xs-12" [(ngModel)]="diet.text" name="cardText" type="text" placeholder="card text" required="required"/>
      </div>
      <div class="col-xs-12"> 
        <label class="col-xs-12">Card Subheading </label>
        <input class="col-xs-12" [(ngModel)]="diet.subheading" name="cardSubheading" type="text" placeholder="card Subheading" required="required"/>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-12">Card subtext</label>
        <input class="col-xs-12" [(ngModel)]="diet.subtext" name="cardSubtext" type="text" placeholder="card subtext" required="required"/>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="submit" [disabled]="!dietForm.valid" (click)="hideModal(diet)">Save</button>
  </div>
</mat-dialog-content>