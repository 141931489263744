import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { HelperService } from '../../../../services/helper-service';

@Component({
  selector: 'service-edit',
  templateUrl: './service-edit.html',
  styleUrls: ['./service-edit.scss'],
})
export class ServiceEditComponent implements OnDestroy {
  imageFiles: any;
  public dataSourceSeller: Observable<any>;
  service: {
    hsnCode: string;
    title: string,
    type: string,
    duration: number,
    visibility: boolean,
    amount: number,
  };
  serviceObj: any;
  subscriptions: Array<Subscription>;
  constructor(private router: Router, private conn: ConnectionService, private route: ActivatedRoute, private http: HttpClient,
     public appConfig: AppConfig, private helper: HelperService) {
  }

  ngOnInit(): void {
    this.subscriptions = [];
    this.serviceObj = new Table.Service();
    this.reset();
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.params.id) {
        this.conn.getService(this.route.parent.snapshot.params.id)
          .then((data: any) => {
            this.serviceObj = data;
            this.helper.convertParseToDictionary(this.serviceObj, this.service);
          });
      }
    }));
  }

  reset(): void {
    this.service = {
      hsnCode: '',
      title: '',
      type: '',
      duration: 0,
      visibility: false,
      amount: 0,
    };
  }

  saveService(): void {
    this.helper.convertDictionaryToParse(this.service, this.serviceObj);
    return this.serviceObj.save()
      .then(() => this.router.navigate([`/products/service/${this.serviceObj.id}`]));
  }

  trackByIndex(index: any): any {
    return index;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.subscriptions;
    delete this.serviceObj;
    delete this.router;
    delete this.conn;
    delete this.route;
    delete this.http;
    delete this.appConfig;
    delete this.helper;
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
