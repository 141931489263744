
<mat-dialog-content class="mat-dialog-content" *ngIf="investigationList">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Investigation</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="col-xs-12 flex">
      <input class="dib col-xs-10" [(ngModel)]="additionalTestInput" placeholder="Add Custom Test"/>
      <button class="ml-3" (click)="addAdditionalTestToList()">ADD</button>
    </div>
    <div class="col-xs-12">
      <label class="db">Choose Test</label>
    </div>
    <div class="col-xs-12">
      <mat-form-field>
        <mat-select class="w100" [(ngModel)]="selectedInvestigation.index" (ngModelChange)="changeInvestigation($event)">
          <mat-option value="-1">No Test</mat-option>
          <mat-option *ngFor="let item of investigationList; let i = index" [value]="i">{{item.get('name')}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-12">
      <div class="pB10" *ngFor="let item of selectedInvestigation?.tests; let i = index"><span>{{item}}</span><span class="pL10"></span>
        <div class="fas fa-trash" (click)="removeTestFromSelection(i)"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="pull-right" (click)="update()" [disabled]="!selectedInvestigation.tests.length && !selectedInvestigation.name">Send</button>
  </div>
</mat-dialog-content>
