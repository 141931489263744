import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'service-view',
  templateUrl: './service-view.html',
  styleUrls: ['./service-view.scss'],
})
export class ServiceViewComponent implements OnDestroy {
  serviceObj: any;
  ui: any;
  subscriptions: Array<Subscription>;
  constructor(private route: ActivatedRoute, private conn: ConnectionService, private appConfig: AppConfig) {
  }

  ngOnInit(): void {
    this.subscriptions = [];
    this.subscriptions = [];
    this.ui = { editModalOpenFlag: false, editProduct: undefined };
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.params.id) {
        const mainService = this.route.parent.snapshot.params.id;
        this.conn.getService(mainService)
          .then((data: any) => {
            this.serviceObj = data;
          });
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.route;
    delete this.conn;
    delete this.subscriptions;
  }
}
