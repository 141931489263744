import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiClientConstant, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'list',
  templateUrl: './list.html',
})
export class ListComponent {
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  languageString: Array<any> = [];
  filter: any = { tags: [] };
  ui: any;
  count: any;
  searchKey: any;
  activeUser: boolean = true;
  selectedType: string = '';
  popUpModel: { loading: boolean };
  public subscriptions: Subscription[] = [];
  constructor(private route: ActivatedRoute,
    private conn: ConnectionService,
    private windowRef: WindowRefService,
    public appConfig: AppConfig,
    public dialog: MatDialog) {
    this.ui = { isFilterOpen: false, grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.popUpModel = { loading: false };
  }

  async ngOnInit(): Promise<any> {
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const data = await this.loadMore(params);
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Edit',
        width: 50,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const { data }: any = params;
          const id = params.value || params.data.objectId;
          const eDiv = this.windowRef.nativeWindow.document.createElement('div');
          eDiv.innerHTML = `<a class='fa fa-pencil-square-o pointer' href='/users/internal-user/${id}/edit'></a>`;
          return eDiv;
        },
      },
      {
        headerName: 'Users',
        field: 'username',
        flex: 1,
      }, {
        headerName: 'Doctor Allocation Factor',
        field: 'doctorAllocationFactor',
        flex: 1,
      }, {
        headerName: 'Operator Allocation Factor',
        field: 'operatorAllocationFactor',
        flex: 1,
      }, {
        headerName: 'team',
        field: 'userType',
        flex: 1,
      }, {
        headerName: 'known Languages',
        field: 'knownLanguages',
        flex: 1,
      }];
  }

  async reset(): Promise<any> {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  private async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.User> = {
      where: { type: ['operator', 'doctor'], inactive: !this.activeUser },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      descending: 'createdAt',
      project: ['username', 'doctorAllocationFactor', 'operatorAllocationFactor', 'userType', 'knownLanguages'],
    };
    if (this.searchKey) {
      requestPayload.where.username = { $regex: this.searchKey, $options: 'i' };
    }
    if (this.selectedType) {
      requestPayload.where.type = this.selectedType;
    }
    let data: Array<any>;
    [data, this.count] = await Promise.all([
      this.conn.findUsers(requestPayload).then((items: any) => items.map((i: any) => i.toJSON())),
      this.conn.countUsers(requestPayload),
    ]);
    return data;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  resetFilters(): void {
    delete this.searchKey;
    delete this.selectedType;
    this.reset();
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
