import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { WindowRefService } from '../../../services/window-ref-service';
import { LocalStorage } from '../../../services/local-storage-service';
import { StagePopupModal } from './stagePopup';
import { Broadcaster } from '../../../components/broadcaster';
import { AddStagePopupModal } from './addStagePopup';

@Component({
  selector: 'stage',
  templateUrl: './stage.html',
  styleUrls: ['./stage.scss'],
})
export class StageComponent implements OnDestroy {
  list: any;
  keysOfDropDown: Array<any>;
  newStage: { name: string, to: Array<any>, open: boolean, display: string, visibleToUser: boolean, object?: any, imageLink: string };
  dropdown: { selectedRow: number, selectedStage: string, dropdownList: { [key: string]: string } };
  constructor(private router: Router, private conn: ConnectionService, private zone: NgZone, private appConfig: AppConfig,
    windowRef: WindowRefService, private storage: LocalStorage, private dialog: MatDialog, private broadcaster: Broadcaster) {
  }

  ngOnInit(): void {
    const roles = this.storage.getJsonValue('userRoles') || [];
    if (!roles.includes('orderStageManager')) {
      this.router.navigate(['/orders']);
      return;
    }
    this.list = [];
    this.dropdown = { selectedStage: 'Select_any_Stage', selectedRow: -1, dropdownList: {} };
    this.reset();
    this.conn.getOrderStages()
      .then((orderStage: any) => {
        this.list = orderStage;
        this.list.map((item: any) => this.dropdown.dropdownList[item.get('name')] = item);
        this.keysOfDropDown = Object.keys(this.dropdown.dropdownList);
      });
  }

  createNewStage(updatedOrderStage: any): void {
    const orderStageTable = updatedOrderStage;
    orderStageTable.save()
      .then(() => location.reload());
  }

  editStage(orderStage: any): void {
    this.openpopup(orderStage);
  }

  openpopup(updatedOrderStage: any): void {
    const dialogRef = this.dialog.open(StagePopupModal, { data: updatedOrderStage });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.createNewStage(result);
        this.broadcaster.broadcast('NOTIFY', { message: 'Stage List Updated', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      }
    });
  }

  showAddStageView(): void {
    const newStage = new Table.OrderStage();
    this.openpopup(newStage);
  }

  addNewStage(): void {
    if (this.dropdown.selectedRow !== -1 && this.isValid(this.dropdown.dropdownList[this.dropdown.selectedStage])) {
      const currList = this.list[this.dropdown.selectedRow].get('possibleStageChange');
      currList.push(this.dropdown.dropdownList[this.dropdown.selectedStage]);
      this.list[this.dropdown.selectedRow].set('possibleStageChange', currList);
      this.list[this.dropdown.selectedRow].save();
      this.reset();
    } else {
      this.broadcaster.broadcast('NOTIFY', { message: 'Already Added', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    }
  }

  openAddStagePopup(): void {
    const dialogRef = this.dialog.open(AddStagePopupModal, { data: { keysOfDropDown: this.keysOfDropDown, dropdown: this.dropdown } });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dropdown = result;
        this.addNewStage();
        this.broadcaster.broadcast('Notify', { message: 'Stage Added Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      }
    });
  }

  isValid(checkItem: any): boolean {
    return !this.list[this.dropdown.selectedRow].get('possibleStageChange')
      .some((item: any) => item.id === checkItem.id);
  }

  removeStage(stage: any, indexToBeRemoved: any): void {
    const beforeRemoveStage = [];
    const afterRemoveStage = [];
    beforeRemoveStage.push(...stage.get('possibleStageChange'));
    afterRemoveStage.push(...stage.get('possibleStageChange'));
    afterRemoveStage.splice(indexToBeRemoved, 1);
    stage.set('possibleStageChange', afterRemoveStage);
    stage.save()
      .then((data: any) => 0)
      .catch((err: any) => {
        stage.set('possibleStageChange', beforeRemoveStage);
        alert(err.message);
      });
  }

  reset(): void {
    this.newStage = { name: undefined, to: [], open: false, visibleToUser: false, display: '', imageLink: '' };
    this.dropdown.selectedStage = 'SELECT_STAGE';
    this.dropdown.selectedRow = -1;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
  }
}
