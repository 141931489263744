
<mat-dialog-content>
  <div class="w-full" *ngIf="newQuestion && oldQuestion">
    <div class="col-xs-12 no-padder">
      <div class="col-xs-12">
        <div class="text-md font-bold">Map new question option to pld question option to preserve tree.</div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-5 font-bold">{{newQuestion.get('uniqueIdentifier')}} (new question)</div>
        <div class="col-xs-1 font-bold">:</div>
        <div class="col-xs-5 font-bold">{{oldQuestion.get('uniqueIdentifier')}} (old question)</div>
      </div>
      <div class="line bg-light-gray"></div>
      <div class="col-xs-12 m-t-xs" *ngFor="let optionMap of mappingArray;let i = index;">
        <div class="col-xs-5">{{optionMap.key}}</div>
        <div class="col-xs-1">:</div>
        <div class="col-xs-5">
          <select [(ngModel)]="optionMap.value" name="{{i}}_value">
            <option value="">>>>>>>>>>No Option To Map<<<<<<<<<<<</option>
            <option *ngFor="let option of oldQuestionOptions" value="{{option}}">{{option}}</option>
          </select>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="text-danger" *ngIf="errorMessage">{{errorMessage}}</div>
      </div>
      <div class="col-xs-12">
        <button class="btn btn-sm btn-success pull-right" (click)="confirmMapping()">Confirm</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
