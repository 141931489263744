import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConnectionService } from '../../../../services/connection-service/index';

@Component({ selector: 'follow-up-report-modal', templateUrl: './follow-up-report.html', styleUrls: ['./follow-up-report.scss'] })
export class FollowupReport {
  beforeBottomCallback: Function = () => this.getFollowUpReport();
  chatUser: any;
  followUpReports: Array<any> = [];

  @Input() set user(userObj: any) {
    if (userObj.id) {
      this.chatUser = userObj;
      this.getFollowUpReport();
    }
  }

  constructor(private conn: ConnectionService) {
  }

  ngOnInit(): void {
  }

  async getFollowUpReport(): Promise<void> {
    this.followUpReports = await this.conn.getFollowUpReports(this.chatUser);
  }
}
