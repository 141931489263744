import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent, GridOptions } from 'ag-grid-community';
import { ApiClientConstant, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'list',
  templateUrl: './list.html',
})
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  searchKey: string;
  window: any;
  components: any;
  columnDefs: any;
  ui: any;
  count: any;
  constructor(private router: Router,
    private conn: ConnectionService,
    private windowRef: WindowRefService,
    public appConfig: AppConfig,
    private renderer: Renderer2) {
    this.window = windowRef.nativeWindow;
  }

  async ngOnInit(): Promise<any> {
    this.ui = { isFilterOpen: false, grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const data = await this.loadMore(params);
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 50,
      cellRenderer: (params: any): any => {
        const url = this.router.url.split('/');
        url.splice(url.length - 1, 1);
        if (!params.data) return '';
        const id = params.value || params.data.objectId;
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o', href='${url.join('/')}/doctor/${id}/edit'></a>`;
        return eDiv;
      },
    },
    {
      headerName: 'Name',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const displayName = params.data.DoctorDisplayName || params.data.username;
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = displayName;
        return eDiv;
      },
    },
    {
      headerName: 'Patients Treated',
      field: 'patientsTreated',
      width: 150,
    },
    {
      headerName: 'Qualifications',
      field: 'doctorQualification',
      width: 400,
    },
    {
      headerName: 'ProfessionalArea',
      field: 'doctorProfessionalArea',
      width: 400,
    },
    ];
  }

  async reset(): Promise<any> {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  private async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.User> = {
      where: { type: 'doctor', inactive: false },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      descending: 'createdAt',
      include: ['doctorProfessionalAreaLanguageString'],
      option: { context: { translate: true } },
      project: ['DoctorDisplayName', 'patientsTreated', 'doctorQualification', 'doctorProfessionalAreaLanguageString'],
    };
    if (this.searchKey) {
      requestPayload.where.username = { $regex: this.searchKey, $options: 'i' };
    }
    let doctors;
    [doctors, this.count] = await Promise.all([
      this.conn.findUsers(requestPayload),
      this.conn.countUsers(requestPayload),
    ]);
    return doctors.map((each: any) => each.toJSON());
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  resetFilters(): void {
    this.searchKey = '';
    this.reset();
  }

  ngOnDestroy(): void {
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
