import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'similar-language-string-suggestion',
  templateUrl: './similar-language-string-suggestion.html',
})
export class SimilarLanguageStringSuggestionComponent {
  selectedTags: any;

  constructor(private dialogRef: MatDialogRef<SimilarLanguageStringSuggestionComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private appConfig: AppConfig) { }

  selectTag(selectedTags: any): void {
    this.selectedTags = selectedTags;
    this.dialogRef.close(this.selectedTags);
  }

  save(): void {
    this.dialogRef.close();
  }
  hideModal(): void {
    this.dialogRef.close(this.appConfig.Shared.Actions.close);
  }
}
