
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header" *ngIf="!hideHeader">
        <ul class="breadcrumb">
          <li><a routerLink="/products/regimens" href="/products/regimens">Regimens</a></li>
          <li>{{regimenObj?.id ? 'Edit' : 'New' }}</li>
        </ul>
        <div class="pull-right flex">
          <button class="done-finish-button btn-xs ml-3" *ngIf="showAsOfflineRegimen" [disabled]="!canDoctorApproveRegimen" (click)="approveRegimen()">Approve Regimen</button>
          <div class="flex px-3" *ngIf="!showAsOfflineRegimen">
            <button class="btn-xs" (click)="changeVariant(this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499)" [ngClass]="{'btn-primary': currentVariant == this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499, 'btn-primary-outline': currentVariant !== this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499 }">variant1</button>
            <button class="btn-xs ml-3" (click)="changeVariant(this.appConfig.Shared.Regimen.Variants.VariantId.variant_1999)" [ngClass]="{'btn-primary': currentVariant == this.appConfig.Shared.Regimen.Variants.VariantId.variant_1999, 'btn-primary-outline': currentVariant !== this.appConfig.Shared.Regimen.Variants.VariantId.variant_1999 }">variant2</button>
            <button class="btn-xs ml-3" (click)="changeVariant(this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999)" [ngClass]="{'btn-primary': currentVariant == this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999, 'btn-primary-outline': currentVariant !== this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999 }">variant3</button>
            <button class="btn-xs ml-3" (click)="changeVariant(this.appConfig.Shared.Regimen.Variants.VariantId.variant_999)" [ngClass]="{'btn-primary': currentVariant == this.appConfig.Shared.Regimen.Variants.VariantId.variant_999, 'btn-primary-outline': currentVariant !== this.appConfig.Shared.Regimen.Variants.VariantId.variant_999 }">variant4</button>
          </div>
          <button class="btn-xs btn-primary-outline ml-2" *ngIf="!showAsOfflineRegimen" (click)="openRegimenProductChangeHistory()">Product History</button>
        </div>
      </div>
    </div>
    <div class="flex-content col-xs-12 p-0" [ngClass]="{'opacity': ui.makeBlur }">
      <div class="col-xs-12 flex" *ngIf="currentVariant">
        <label class="col-xs-2">Disable</label>
        <div class="col-xs-1">:</div>
        <div class="flex">
          <label class="i-switch i-switch-sm ml-2">
            <input type="checkbox" [(ngModel)]="disableVariant" name="disableVariant" (ngModelChange)="updateVariantIsDisable()"/><i></i>
          </label>
          <div innerHTML="disable"></div>
        </div>
      </div>
      <form class="col-xs-12 p-4" #regimenForm="ngForm">
        <div class="form-group col-xs-12" *ngIf="regimenObj && !disableVariant">
          <div class="col-xs-12 text-danger text-center p-2" *ngIf="![apiClientConstant.Regimen.Type.PERSONALIZED].includes(regimenObj.get('type'))">This is regimen template, editing this will change regimen for all users.</div>
          <div class="col-xs-12 flex alignFS" *ngIf="showAsOfflineRegimen">
            <div class="col-xs-4">
              <div class="heading bg-white">
                <div class="flex">
                  <label>chat view</label>
                  <label class="i-switch i-switch-sm bg-success ml-3">
                    <input type="checkbox" [(ngModel)]="selectChat" (ngModelChange)="changeSelectedChatType(selectChat)" name="selectedChatType"/><i></i>
                  </label>
                </div>
              </div>
              <user-chat-view-human-interaction *ngIf="selectedChatType === 'userChat'" [privateMainConcernClass]="regimenObj.get('class')" [user]="regimenObj.get('forUser')" [height]="'100%'"></user-chat-view-human-interaction>
              <user-chat-view-summary [user]="regimenObj.get('forUser')" *ngIf="selectedChatType === 'chatSummary'" [regimenClass]="regimenObj?.get('class')" [fromNewOrderApprovalPage]="true" [isProductOrder]="false" [isOfflineRegimen]="showAsOfflineRegimen" [patientName]="patientName"></user-chat-view-summary>
            </div>
            <div class="col-xs-6 flex-grow">
              <instant-checkup-magnifier [user]="regimenObj.get('forUser')" (needHelpButtonClicked)="markOrderWaitingForImageNew()"></instant-checkup-magnifier>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <div class="col-xs-5 mr-3 flex-grow">
              <label class="col-xs-4">Regimen Id</label>
              <div class="col-xs-12">
                <input-text [parseObj]="regimenObj" name="regimenId" [required]="true" placeholder="{{regimenObj.get('regimenId') || 'Id'}}" [disabled]="regimenObj?.id || regimenObj.get('type') === apiClientConstant.Regimen.Type.ALTERNATE"></input-text>
              </div>
              <div class="col-xs-12" *ngIf="[apiClientConstant.Regimen.Type.PERSONALIZED].includes(regimenObj.get('type'))">
                <div class="col-xs-10">
                  <input class="form-control" [(ngModel)]="copyFromRegimenId" name="copyFromRegimenId" placeholder="Copy regimen from id"/>
                </div>
                <div class="col-xs-2"><i class="fa fa-copy pointer m-t-xs" style="font-size:1.5em;" (click)="copyRegimen()"></i></div>
              </div>
            </div>
            <div class="col-xs-6">
              <language-string-multiple-selection [parseObj]="regimenObj" name="concernsLanguageString" title="Display Concern" (onChangeEditReasonRegimenSOP)="updateRegimenEditReason()" [required]="true" [tags]="['concern','display']"></language-string-multiple-selection>
            </div>
          </div>
          <div class="col-xs-12 p0" *ngIf="!fromOrderApproval">
            <div class="col-xs-12">
              <label class="col-xs-4">Title</label>
              <div class="col-xs-12">
                <input-text [parseObj]="regimenObj" name="title" placeholder="Title"></input-text>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-4">Class</label>
              <div class="col-xs-12">
                <input-select [parseObj]="regimenObj" name="class" [required]="true" [options]="regimenClasses"></input-select>
              </div>
            </div>
            <div class="col-xs-12 mt-4">
              <language-string-tag-selection [parseObj]="regimenObj" name="tags"></language-string-tag-selection>
            </div>
            <div class="col-xs-12 flex" *ngIf="regimenObj?.id">
              <div class="col-xs-4">
                <label class="col-xs-4">Update Regimen Cover image</label>
                <div class="col-xs-12 mt-2" *ngIf="!!regimenObj.get('regimenCoverImage')"><img style="padding-left:15px" height="150px" widht="150px" src="{{regimenObj.get('regimenCoverImage')}}"/></div>
                <div class="col-xs-12 m-4">
                  <button (click)="openMediaLinkEditForCurrentProducts()">update</button>
                </div>
              </div>
            </div>
            <div class="col-xs-12" *ngIf="currentVariant && currentVariant !== this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499">
              <button (click)="copyRegimenFromRegimen(regimenObjVariant1)">copy from variant1</button>
              <button class="ml-3" *ngIf="currentVariant === this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999" (click)="copyRegimenFromRegimen(regimenObjVariant2)">copy from variant2</button>
              <button class="ml-3" *ngIf="currentVariant !== this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499" (click)="copyRegimenFromRegimen(regimenObjVariant3)">copy from variant3</button>
            </div>
          </div>
          <div class="col-xs-12 p-0 mt-3 flex alignFS">
            <div class="col-xs-6 product-morning-view">
              <regimen-edit-part-of-day [parseObj]="regimenObj" name="morning" title="Morning" (onProductUpdateRegimenEditReason)="updateRegimenEditReason()" (onProductUpdate)="onProductUpdate()" [hideNotNecessaryThings]="hideNotNecessaryThings" [reset]="changeMorningProducts" [variant]="currentVariant"></regimen-edit-part-of-day>
            </div>
            <div class="col-xs-6 product-night-view mr-0">
              <regimen-edit-part-of-day [parseObj]="regimenObj" name="night" title="Night" (onProductUpdateRegimenEditReason)="updateRegimenEditReason()" (onProductUpdate)="onProductUpdate()" [hideNotNecessaryThings]="hideNotNecessaryThings" [reset]="changeNightProducts" [variant]="currentVariant"></regimen-edit-part-of-day>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-4">Diet And Lifestyle</label>
            <div class="col-xs-12">
              <input-select [parseObj]="regimenObj" name="dietAndLifestyleQuestion" [options]="dietAndLifestyleList"></input-select>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-4">Regimen edit reason</label>
            <div class="col-xs-12">
              <select [(ngModel)]="editReason" required="required" name="editReason">
                <option *ngFor="let reason of reasonsToEdit" value="{{reason.value}}">{{reason.display}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="antibioticsReasonRequired">
            <label class="col-xs-4">Reason For Continue Antibiotics</label>
            <div class="col-xs-12">
              <input-text [parseObj]="regimenObj" name="continueAntibioticsReason" type="string" minLength="15" required="required"></input-text>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="editReason === 'OTHER'">
            <label class="col-xs-4">Regimen edit details</label>
            <div class="col-xs-8">
              <input class="form-control" type="text" [(ngModel)]="editDetails" name="editDetails" placeholder="Add details of editing reason" required="required"/>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="!hideNotNecessaryThings">
            <label class="col-xs-4">Note (Not Visible To User)</label>
            <div class="col-xs-12">
              <input-textarea [parseObj]="regimenObj" name="note"></input-textarea>
            </div>
          </div>
          <div class="col-xs-12 mt-3 flex" *ngIf="!hideNotNecessaryThings">
            <div class="col-xs-6">
              <label class="col-xs-4">FixedPrice*</label>
              <div class="col-xs-7">
                <input-text [parseObj]="regimenObj" name="fixedPrice" type="number" (onValueChange)="invoiceTitleRequired = $event > 0" required="required"></input-text>
              </div>
            </div>
            <div class="col-xs-6" *ngIf="invoiceTitleRequired || currentVariant">
              <label class="col-xs-4">Fixed Price MRP*</label>
              <div class="col-xs-7">
                <input-text [parseObj]="regimenObj" name="fixedPriceMRP" type="number" required="required"></input-text>
              </div>
            </div>
          </div>
          <div class="col-xs-12 mt-3" *ngIf="invoiceTitleRequired || currentVariant">
            <label class="col-xs-4">FixedPrice Invoice Title</label>
            <div class="col-xs-8">
              <input-text [parseObj]="regimenObj" name="invoiceTitle" required="true"></input-text>
            </div>
          </div>
          <div class="col-xs-12 mt-3">
            <div class="col-xs-6 flex">
              <label class="col-xs-6">Total Price Before Editing Regimen</label>
              <div class="col-xs-4 flex"><span>{{priceBeforeEditing}}</span></div>
            </div>
            <div class="col-xs-6 flex">
              <label class="col-xs-6">Total Price</label>
              <div class="col-xs-4 flex"><span>{{totalPrice}}</span></div>
            </div>
          </div>
          <ng-container *ngIf="!hideNotNecessaryThings">
            <div class="col-xs-12 mt-3">
              <div class="col-xs-4">
                <label class="col-xs-6">Sample Available</label>
                <div class="col-xs-4">
                  <input-checkbox [parseObj]="regimenObj" name="sampleAvailable"></input-checkbox>
                </div>
              </div>
              <div class="col-xs-4">
                <label class="col-xs-6">Active</label>
                <div class="col-xs-4">
                  <input-checkbox [parseObj]="regimenObj" name="active"></input-checkbox>
                </div>
              </div>
              <div class="col-xs-4">
                <label class="col-xs-6">Dr. Approved</label>
                <div class="col-xs-4">
                  <input-checkbox [parseObj]="regimenObj" name="doctorVerified"></input-checkbox>
                </div>
              </div>
            </div>
            <div class="col-xs-12 mt-3">
              <div class="col-xs-4">
                <label class="col-xs-6">Approval Percentage*</label>
                <div class="col-xs-4">
                  <input-select [parseObj]="regimenObj" type="number" name="approvalPercentage" [options]="approvalPercentageOption"></input-select>
                </div>
              </div>
              <div class="col-xs-4">
                <label class="col-xs-6">Show Summary View</label>
                <div class="col-xs-4">
                  <input-checkbox [parseObj]="regimenObj" name="showSummaryView"></input-checkbox>
                </div>
              </div>
              <div class="col-xs-4">
                <label class="col-xs-6">Send No Message To User</label>
                <div class="col-xs-4">
                  <input-checkbox [parseObj]="regimenObj" name="noMessageToUser"></input-checkbox>
                </div>
              </div>
            </div>
            <div class="col-xs-12 mt-3" *ngIf="userRoles.includes('adminDoctor') || userRoles.includes('regimenEditor')">
              <div class="col-xs-4 flex">
                <label class="mt-0 col-xs-6">Maximum Number of Products*</label>
                <div class="col-xs-4">
                  <input-text [parseObj]="regimenObj" name="numberOfProductsLimit" type="number"></input-text>
                </div>
              </div>
              <div class="col-xs-8">
                <div class="col-xs-4 flex">
                  <label class="mt-0 col-xs-6">interval In Days*</label>
                  <div class="col-xs-4">
                    <input [(ngModel)]="followUpConfig.intervalInDays" name="intervalInDays" type="number" (ngModelChange)="updateVariantFollowUpConfig()"/>
                  </div>
                </div>
                <div class="col-xs-4 flex">
                  <label class="mt-0 col-xs-6">duration In Days*</label>
                  <div class="col-xs-4">
                    <input [(ngModel)]="followUpConfig.durationInDays" name="durationInDays" type="number" (ngModelChange)="updateVariantFollowUpConfig()"/>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="regimenObj.get('type') == appConfig.Shared.Regimen.Type.MAIN || regimenObj.get('type') == appConfig.Shared.Regimen.Type.ALTERNATE">
            <div class="col-xs-12">
              <label>Followup SOP<i class="fa fa-plus-square-o ml-3" (click)="addFollowUp()"></i></label>
              <div *ngFor="let node of followUpSop;let i = index;">
                <followup-sop class="col-xs-12" [node]="node" (delete)="deleteFollowUp(i)" prefix="{{i}}" [regimenId]="regimenObj.get('regimenId')"></followup-sop>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
      <div class="col-xs-12 p-0" *ngIf="actionLog.length && !disableVariant">
        <div class="col-xs-12 font-bold list-header mb-2 pl-4">Change in Regimen</div>
        <div class="max-height100">
          <div class="col-xs-12 px-4 mb-2" *ngFor="let item of actionLog;">
            <div class="col-xs-4">{{item.get('Action')}}</div>
            <div class="col-xs-4">{{item.get("createdAt")}}</div>
            <div class="col-xs-4">{{item.get('ByUser') ? item.get('ByUser').get('username') : ''}}</div>
          </div>
        </div>
      </div>
      <button class="m-4" *ngIf="!showAsOfflineRegimen" (click)="saveRegimen()" [disabled]="!regimenForm.valid">{{ regimenObj?.id ? 'Update ' : 'Create' }}</button>
    </div>
    <div class="model_payment_mode" *ngIf="ui.makeBlur" (click)="ui.makeBlur = false">
      <div class="holder" (click)="stopPropagation($event)">
        <form #confirmationForm="ngForm">
          <div class="col-xs-12">
            <div class="col-xs-12 whitespace-pre-inline">{{orderConfirmationMessage}}</div>
            <div class="col-xs-12">
              <div class="m-2" *ngIf="changedProductsWhichAreAlreadyInOrdersInTransit?.length">
                <label class="alert-warning col-xs-12 m-3 breadcrumb">* Newly added products are already purchased by user and are in transit, do you still want to create order for them?</label>
                <div class="p-3" *ngIf="changedProductsWhichAreAlreadyInOrdersInTransit?.length">
                  <ul *ngFor="let product of changedProductsWhichAreAlreadyInOrdersInTransit">
                    <li>{{product.get('title')}}</li>
                  </ul>
                </div>
              </div>
              <div class="m-2" *ngIf="changedProductsWhichAreAlreadyInOrdersInDelivered?.length">
                <label class="alert-warning col-xs-12 m-3 breadcrumb">* Newly added product are already delivered to users in last 15 days, do you still want to create order for them?</label>
                <div class="p-3" *ngIf="changedProductsWhichAreAlreadyInOrdersInDelivered?.length">
                  <ul *ngFor="let product of changedProductsWhichAreAlreadyInOrdersInDelivered">
                    <li>{{product.get('title')}}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12" style="margin-top:5px;font-weight:bold">Products</label>
              <div class="col-xs-12 no-padder">
                <div *ngFor="let product of followUpProducts;let i = index;" style="padding:3px 10px;background:#5a5a5a;display:inline-block;border-radius:3px;margin:2px 10px 2px 0px;color:white"><i class="fa fa-trash-o pointer" (click)="removeProduct(i)"></i>
                  <div style="max-width:500px;display:inline-block;">&nbsp;&nbsp; {{product.get('title')}}&nbsp;[{{product.get('quantity')}}{{product.get('quantityUnit')}}] {{product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</div>
                </div>
              </div>
              <div class="col-xs-12 no-padder">
                <input type="text" placeholder="Type Product Name..." aria-label="Number" name="add_product" autocomplete="off" matInput="matInput" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
                <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
                  <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteProductOnSelect(option)">{{option.name}}</mat-option>
                </mat-autocomplete>
              </div>
            </div>
            <div class="col-xs-12 no-padder m-t-xs">
              <div class="col-xs-6 wrapper-sm font-bold">Select Payment Type</div>
              <div class="col-xs-6">
                <input-select [parseObj]="regimenObj" name="paymentType" [options]="paymentTypeList"></input-select>
              </div>
            </div>
            <div class="col-xs-6"><br/>
              <input-checkbox [parseObj]="regimenObj" name="followUpNeeded" title="&amp;nbsp; Instruction Call Needed"></input-checkbox>
            </div>
            <div class="col-xs-6"><br/>
              <input-checkbox [parseObj]="regimenObj" name="followUpRequired" title="&amp;nbsp; Required for next followUp"></input-checkbox>
            </div>
            <div class="col-xs-12 m-t-xs">
              <div class="pull-left btn btn-light-blue m-t-xs" (click)="saveRegimenCreatingOrder(false)">Don't Create Order</div>
              <div class="pull-right btn btn-success m-t-xs" (click)="saveRegimenCreatingOrder(true)">Create Order</div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="popup m-2 p-2 scrollable" *ngIf="!isProductExistedWarningIgnored && (changedProductsWhichAreAlreadyInOrdersInTransit?.length || changedProductsWhichAreAlreadyInOrdersInDelivered?.length)">
      <div class="m-2" *ngIf="changedProductsWhichAreAlreadyInOrdersInTransit?.length">
        <label class="alert-warning col-xs-12 m-3 breadcrumb">* Newly added products are already purchased by user and are in transit, do you still want to create order for them?</label>
        <div class="p-3" *ngIf="changedProductsWhichAreAlreadyInOrdersInTransit?.length">
          <ul *ngFor="let product of changedProductsWhichAreAlreadyInOrdersInTransit">
            <li>{{product.get('title')}}</li>
          </ul>
        </div>
      </div>
      <div class="m-2" *ngIf="changedProductsWhichAreAlreadyInOrdersInDelivered?.length">
        <label class="alert-warning col-xs-12 m-3 breadcrumb">* Newly added product are already delivered to users in last 15 days, do you still want to create order for them?</label>
        <div class="p-3" *ngIf="changedProductsWhichAreAlreadyInOrdersInDelivered?.length">
          <ul *ngFor="let product of changedProductsWhichAreAlreadyInOrdersInDelivered">
            <li>{{product.get('title')}}</li>
          </ul>
        </div>
      </div>
      <div class="col-xs-10 mt-4 border flex justify-content-center">
        <button class="m-5" type="button" (click)="productExistedInPreviousOrder()">Agree to Proceed</button>
        <button class="m-5" type="button" (click)="resetPopUp()">Cancel</button>
      </div>
    </div>
  </div>
</div>
