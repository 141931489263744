import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { DirectivesModule } from '../../../../components/directives.module';
import { QuestionListComponent } from './question-list.component';

const ROUTES: Routes = [
  { path: '', component: QuestionListComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    AgGridAngular,
  ],
  declarations: [QuestionListComponent],
  exports: [QuestionListComponent],
})
export class QuestionListModule {}
