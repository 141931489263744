import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FollowUpReportComponent } from './follow-up-report.component';

const ROUTES: Routes = [
  { path: 'user/follow-up-report/:id', component: FollowUpReportComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [FollowUpReportComponent],
  exports: [FollowUpReportComponent],
})
export class UserFollowUpReportModule { }
