
<div class="col-xs-12" *ngIf="workTime">
  <form #workTimeForm="ngForm">
    <div class="col-xs-12">
      <div class="h3">Work Timing</div>
    </div>
    <div class="col-xs-12">
      <div class="col-xs-12">
        <div class="col-xs-12 flex mt-3" *ngFor="let day of weekDays"><span class="ml-1 pl-3 pr-3 pt-2 pb-2 rounded pointer bg-secondary" [title]="day">{{day.substring(0,1).toUpperCase()}}</span>
          <div class="ml-3" *ngFor="let time of workTime[day];let i = index;" style="padding:3px 10px;background:#5a5a5a;display:inline-block;border-radius:3px;margin:2px 10px 2px 0px;color:white"><span>{{time.startTime}}-{{time.endTime}}</span><i class="fa fa-trash-o pointer ml-3" (click)="removeTime(i, day)"></i></div><i class="fas fa-plus ml-3" (click)="addTimeRange(day)"></i>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <button class="pull-right" [disabled]="workTimeForm.invalid" (click)="saveWorkTime()">Save</button>
    </div>
  </form>
</div>