import { Component, Input } from '@angular/core';
import * as moment from 'moment';

import { ApiClientConstant } from 'api-client';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'user-priority',
  templateUrl: './user-priority.html',
})
export class UserPriorityComponent {
  UserRupeeCategory: { ACTIVE: number, IN_ACTIVE: number, NEVER_ORDER: number, CANCELED: number } = {
    ACTIVE: 0,
    IN_ACTIVE: 1,
    NEVER_ORDER: 2,
    CANCELED: 3,
  };
  userRupeeCategory: number = this.UserRupeeCategory.NEVER_ORDER;
  userObj: any;
  @Input()
  set user(userObj: any) {
    this.userObj = userObj;
    this.updateRupeeCategory();
  }

  updateRupeeCategory(): void {
    const orderState = this.userObj.get('orderState') || ApiClientConstant.User.OrderState.NEW_USER;
    if (orderState === ApiClientConstant.User.OrderState.NEW_USER) {
      this.userRupeeCategory = this.UserRupeeCategory.NEVER_ORDER;
      return;
    }
    if (orderState === ApiClientConstant.User.OrderState.PROCESSING) {
      this.userRupeeCategory = this.UserRupeeCategory.ACTIVE;
      return;
    }
    if (orderState === ApiClientConstant.User.OrderState.CANCELED) {
      this.userRupeeCategory = this.UserRupeeCategory.CANCELED;
      return;
    }
    if (orderState === ApiClientConstant.User.OrderState.DELIVERED) {
      this.userRupeeCategory = moment(this.userObj.get('refreshDate')).isAfter(moment().subtract(4, 'month'))
        ? this.UserRupeeCategory.ACTIVE
        : this.UserRupeeCategory.IN_ACTIVE;
    }
  }

  constructor(public appConfig: AppConfig) {
  }
}
