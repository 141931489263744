import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AddQuestionModal } from './add-question.modal';

@NgModule({
  imports: [
    MatDialogModule,
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
  declarations: [AddQuestionModal],
  exports: [AddQuestionModal],
})
export class AddQuestionModule {}
