import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionComponent } from './tree.component';
import { QuestionViewModule } from './view';
import { QuestionListModule } from './list';
import { QuestionEditModule } from './edit';
import { QuestionResolve } from './question-resolver';

const ROUTES: Routes = [
  {
    path: 'questions',
    component: QuestionComponent,
    loadChildren: (): any => QuestionListModule,
    pathMatch: 'full',
  },
  {
    path: 'questions/new',
    component: QuestionComponent,
    loadChildren: (): any => QuestionEditModule,
    pathMatch: 'full',
  },
  {
    path: 'question/:id',
    component: QuestionComponent,
    loadChildren: (): any => QuestionViewModule,
    resolve: { question: QuestionResolve },
    pathMatch: 'full',
  },
  {
    path: 'question/:id/edit',
    component: QuestionComponent,
    loadChildren: (): any => QuestionEditModule,
    resolve: { question: QuestionResolve },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [QuestionResolve],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [QuestionComponent],
  exports: [QuestionComponent] })
export class QuestionModule {}
