import { NgZone, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';
import { UploadFileService } from '../../../services/uploadFileService/uploadFile.service';

@Component({
  selector: 'bulk-article-model',
  templateUrl: './bulk-article.html',
})
export class BulkArticleModel {
  question: any;
  user: any;
  jobId: string;
  existingImageLink: string;
  existingThumbnailLink: string;
  existingQuestionId: string;
  articleImage: File;
  thumbnailImage: File;
  xlsxFile: File;
  notificationText: string;
  success: Array<string>;
  failures: Array<string>;

  public subscriptions: Subscription[] = [];
  static parameters: any = [NgZone,
    ConnectionService,
    Router,
    AppConfig,
    UploadFileService,
    HttpClient,
    MatDialogRef];

  constructor(private zone: NgZone,
              private conn: ConnectionService,
              private router: Router,
              private appConfig: AppConfig,
              private upload: UploadFileService,
              private http: HttpClient,
              private dialogRef: MatDialogRef<BulkArticleModel>) {
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  async verifyJobId(): Promise<any> {
    const result = await this.conn.verifyJobId(this.jobId);
    if (result) {
      this.question = result;
      this.existingQuestionId = result.id;
      this.existingImageLink = result.get('params').url;
      this.existingThumbnailLink = result.get('params').thumbnail;
      return 0;
    }
    this.existingQuestionId = undefined;
    this.existingImageLink = undefined;
    this.existingThumbnailLink = undefined;
    return 0;
  }

  async onSubmit(): Promise<any> {
    this.user = this.conn.getCurrentUser();
    if (!this.existingQuestionId) {
      const username = this.user.get('username');
      const articleImageUrl = await this.upload
        .uploadFile({ file: this.articleImage, bucket: 'OTHER', username, source: 'doctor_bulkArticle' });
      const thumbnailImageUrl = await this.upload
        .uploadFile({ file: this.thumbnailImage, bucket: 'OTHER', username, source: 'doctor_bulkArticle' });
      const params = {
        keyValue: [],
        content: this.notificationText,
        thumbnail: thumbnailImageUrl,
        url: articleImageUrl,
      };

      const emptyInputsArr = [];
      const question = new Table.Question();
      question.set('uniqueIdentifier', this.jobId);
      question.set('mode', ApiClientConstant.ChatMessage.Mode.IMAGE);
      question.set('params', params);
      question.set('table', '_User');
      question.set('type', 'SEND_TO_USER');
      question.set('isReminder', false);
      question.set('isPriorityMessage', false);
      question.set('title', null);
      question.set('autoResponse', false);
      question.set('inputs', emptyInputsArr);
      question.set('noChatMessage', false);
      question.set('isScheduled', false);
      this.question = await question.save();
    }

    const serverURl = this.conn.getParseUrl();
    const formData = new FormData();
    formData.append('file', this.xlsxFile);
    formData.append('questionId', this.question.id);
    formData.append('sessionToken', this.user.getSessionToken());
    formData.append('jobId', this.jobId);
    const result: any = await this.http.post(`${serverURl}/api/file/marketing/notification`, formData).toPromise();
    this.failures = result.filter((item: any) => item.status === 'error').map((each: any) => each.message);
    this.success = result.map((item: any) => item.status).filter((status: any) => status === 'success');
  }
}
