import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { InputType } from '../../../../../typings/client/input';
import { Broadcaster } from '../../../../components/broadcaster';
import { SaveSbarHistoryComponent } from './save-sbar-history/save-sbar-history.component';

@Component({
  selector: 'open-support-tickets-edit',
  templateUrl: './open-support-tickets-edit.html',
  styleUrls: ['./open-support-tickets-edit.scss'],
})
export class OpenSupportTicketsEditComponent implements OnDestroy {
  currentUser: any;
  supportTicketId: string;
  supportTicketObject: any;
  userId: any;
  orderStateOptions: Array<InputType.SelectOption> = [];
  languageOptions: Array<InputType.SelectOption> = [];
  supportQuestionOptions: Array<InputType.SelectOption> = [];
  loading: boolean = true;
  team: string;
  teams: Array<any> = [];
  user: any;
  languagePreference: string;
  waitForResponse: boolean = false;
  sbarHistory: any;

  constructor(
    private router: Router,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private broadcasterService: Broadcaster,
    private route: ActivatedRoute,
    private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.conn.getCurrentUser();
    await this.fetchOptions();
    this.supportTicketId = this.route.snapshot.params.id;
    this.supportTicketObject = new Table.SupportTicket();
    if (this.supportTicketId) {
      this.supportTicketObject = await ApiConnector.findOne(Table.SupportTicket, {
        where: { objectId: this.supportTicketId },
        include: ['supportQuestion', 'supportQuestion.questionLanguageString' as 'supportQuestion'],
      });
      this.user = this.supportTicketObject.get('user');
      this.userId = this.supportTicketObject.get('user').id;
      this.user = this.supportTicketObject.get('user');
      [this.team] = this.supportTicketObject.get('teams');
      this.languagePreference = this.supportTicketObject.get('languagePreference');
    } else {
      this.userId = this.route.snapshot.queryParams?.userId;
      await this.onUpdateUser();
    }
    this.loading = false;
  }

  async fetchOptions(): Promise<void> {
    const orderoptions = Object.values(ApiClientConstant.User.OrderState);
    orderoptions.forEach((each: string) => this.orderStateOptions.push({ display: each, value: each }));
    const langoptions = Object.values(this.appConfig.Shared.Languages);
    langoptions.forEach((each: string) => this.languageOptions.push({ display: each, value: each }));
    const supportQuestion = await ApiConnector.find(Table.SupportQuestion, {
      where: {},
      include: ['questionLanguageString'],
    });
    supportQuestion.forEach((each: any) => this.supportQuestionOptions.push({ display: each.get('questionLanguageString').get('en'),
      value: each.get('questionLanguageString').get('en'),
      parseValue: each }));
    this.teams = this.appConfig.Shared.Team.Name;
  }

  allocateDoctorAndOperator(): void {
    if (this.supportTicketId) return;
    if (this.currentUser.get('type') === ApiClientConstant.User.Type.DOCTOR) {
      this.supportTicketObject.set('allocatedDoctor', this.currentUser);
    } else {
      this.supportTicketObject.set('allocatedOperator', this.currentUser);
    }
  }

  onUpdateTeam(): void {
    this.supportTicketObject.set('teams', [this.team]);
  }

  async onUpdateUser(): Promise<void> {
    if (!this.userId || this.userId?.length < 10) return;
    const user = new Table.User();
    user.id = this.userId;
    try {
      this.user = await user.fetch();
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'Invalid User',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    this.supportTicketObject.set('languagePreference', this.user.get('languagePreference'));
    this.languagePreference = this.user.get('languagePreference');
  }

  async saveSupportTicket(): Promise<void> {
    // if (!this.sbarHistory) {
    //   const dialogRef = this.dialog.open(SaveSbarHistoryComponent, {
    //     data: { supportTicketId: this.supportTicketId },
    //   });
    //   dialogRef.afterClosed().subscribe((sbar: any) => {
    //     if (!sbar) {
    //       this.broadcasterService.broadcast('NOTIFY', {
    //         message: 'Filling SBAR is compulsary',
    //         type: this.appConfig.Shared.Toast.Type.ERROR,
    //       });
    //       return;
    //     }
    //     this.sbarHistory = sbar;
    //   });
    //   return;
    // }
    if (!this.userId || this.userId?.length < 10) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'Invalid User',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const user = new Table.User();
    user.id = this.userId;
    this.supportTicketObject.set('user', user);
    this.supportTicketObject.set('status', ApiClientConstant.SupportTicket.Status.Pending);
    this.supportTicketObject.set('languagePreference', this.languagePreference);
    this.allocateDoctorAndOperator();
    try {
      this.waitForResponse = true;
      const supportTicket = await this.supportTicketObject.save();
      // this.sbarHistory.set('supportTicket', supportTicket);
      // await this.sbarHistory.save();
      // supportTicket.set('SBAR', this.sbarHistory);
      // await supportTicket.save();
      // await this.supportTicketObject.save();
      this.broadcasterService.broadcast('NOTIFY', { message: 'Ticket Created Successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.router.navigate([`/ticket/${this.userId}`],
        { queryParams: { ticketId: this.supportTicketObject.id } });
    } catch (error) {
      this.waitForResponse = false;
      this.broadcasterService.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.supportTicketObject;
  }
}
