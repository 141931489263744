
<div class="col-xs-12" *ngIf="SBARHistory">
  <div class="col-xs-12 title" *ngIf="!openedAsPopup"><em class="fas fa-comment pr-3"></em><span class="fontL1 bold fw-600">SBAR</span><em class="fas fa-edit ml-3" (click)="editSBAR()"></em><em class="fas fa-history ml-3" (click)="openSbarHistory()"></em></div>
  <div class="col-xs-12" *ngIf="!editMode">
    <div class="col-xs-12 flex">
      <label class="col-xs-4">situation</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{SBARHistory.get('situation')}}</div>
    </div>
    <div class="col-xs-12 flex">
      <label class="col-xs-4">background</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{SBARHistory.get('background')}}</div>
    </div>
    <div class="col-xs-12 flex">
      <label class="col-xs-4">assessment</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{SBARHistory.get('assessment')}}</div>
    </div>
    <div class="col-xs-12 flex">
      <label class="col-xs-4">recommendation</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{SBARHistory.get('recommendation')}}</div>
    </div>
    <div class="col-xs-12 flex" *ngIf="!llmResponse.category">
      <label class="col-xs-4">category</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{SBARHistory.get('category')}}</div>
    </div>
    <div class="col-xs-12 flex" *ngIf="llmResponse.category">
      <label class="col-xs-4">category</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{llmResponse.category}}</div>
    </div>
    <div class="col-xs-12 flex" *ngIf="llmResponse.category">
      <label class="col-xs-4">subCategory</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{llmResponse.subcategory}}</div>
    </div>
    <div class="col-xs-12 flex" *ngIf="llmResponse.category">
      <label class="col-xs-4">Operator Edited Category</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{operatorChangedResponse.category}}</div>
    </div>
    <div class="col-xs-12 flex" *ngIf="llmResponse.category">
      <label class="col-xs-4">Operator Edited subCategory</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{operatorChangedResponse.subcategory}}</div>
    </div>
    <div class="col-xs-12 flex">
      <label class="col-xs-4">call needed</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">{{SBARHistory.get('callNeeded')}}</div>
    </div>
  </div>
  <form class="col-xs-12" *ngIf="editMode" #SBAR="ngForm">
    <div class="col-xs-12 flex" *ngIf="category !== 'Non_Medical'">
      <label class="col-xs-4">situation</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6" *ngIf="SBARHistory">
        <input-text class="w100" [parseObj]="SBARHistory" name="situation" required="required" placeholder="situation"></input-text>
      </div>
    </div>
    <div class="col-xs-12 flex" *ngIf="category !== 'Non_Medical'">
      <label class="col-xs-4">background</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">
        <input-text class="w100" [parseObj]="SBARHistory" name="background" [required]="true" placeholder="background"></input-text>
      </div>
    </div>
    <div class="col-xs-12 flex" *ngIf="category !== 'Non_Medical'">
      <label class="col-xs-4">assessment</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">
        <input-text class="w100" [parseObj]="SBARHistory" name="assessment" [required]="true" placeholder="assessment"></input-text>
      </div>
    </div>
    <div class="col-xs-12 flex" *ngIf="category !== 'Non_Medical'">
      <label class="col-xs-4">recommendation</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">
        <input-text class="w100" [parseObj]="SBARHistory" name="recommendation" [required]="true" placeholder="recommendation"></input-text>
      </div>
    </div>
    <div class="col-xs-12 flex" *ngIf="llmResponse.category">
      <label class="col-xs-4">category</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">
        <div class="col-xs-12">
          <span class="fw-700">{{llmResponse.category}}</span>
        </div>
      </div>
    </div>
    <div class="col-xs-12 flex" *ngIf="llmResponse.subcategory">
      <label class="col-xs-4">subCategory</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">
        <div class="col-xs-12">
          <span class="fw-700">{{llmResponse.subcategory}}</span>
        </div>
        <div class="col-xs-12" *ngIf="category == 'Others'">
          <input class="col-xs-12" type="text" name="categoryTxt" [(ngModel)]="categoryText" required="required"/>
        </div>
      </div>
    </div>
    <div class="col-xs-12" *ngIf="llmResponse.category">
      <label class="col-xs-4">Operator Edited Category</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">
        <div class="col-xs-12">
          <select class="col-xs-12" [(ngModel)]="operatorChangedResponse.category" name="operatorChangedCategory" (change)="onChangeCategoryByOperator()">
            <option value="Order">Order</option>
            <option value="Treatment">Treatment</option>
            <option value="Instructions">Instructions</option>
            <option value="Discomfort">Discomfort</option>
            <option value="Others">Others</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-xs-12`" *ngIf="llmResponse.category && operatorChangedResponse.category">
      <label class="col-xs-4">Operator Edited SubCategory</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">
        <div class="col-xs-12">
          <select class="col-xs-12" [(ngModel)]="operatorChangedResponse.subcategory" name="operatorChangedSubCategory" (change)="onChangeSubCategoryByOperator()">
            <option *ngFor="let item of subCategoryOptions; let i = index;" [value]="item">{{item}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-xs-12 flex" *ngIf="!llmResponse.category">
      <label class="col-xs-4">category</label>
      <div class="col-xs-1">:</div>
      <div class="col-xs-6">
        <div class="col-xs-12">
          <select class="col-xs-12" [(ngModel)]="category" name="category" (change)="onChangeCategory()" required="required">
            <option value="Burning">Burning</option>
            <option value="Itching">Itching</option>
            <option value="Dryness">Dryness</option>
            <option value="Redness">Redness</option>
            <option value="Non_Medical">Non_Medical</option>
            <option value="Duplicate">Duplicate</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div class="col-xs-12" *ngIf="category == 'Others'">
          <input class="col-xs-12" type="text" name="categoryTxt" [(ngModel)]="categoryText" required="required"/>
        </div>
      </div>
    </div>
    <div class="col-xs-12 flex" *ngIf="category !== 'Non_Medical'">
      <label class="col-xs-4">call needed</label>
      <div class="col-xs-1">:</div>
      <div>
        <input-checkbox [parseObj]="SBARHistory" name="callNeeded" [required]="true"></input-checkbox>
      </div>
    </div>
    <div class="col-xs-12">
      <button type="submit" [disabled]="!SBAR.valid" (click)="SaveSBARHistory()">Save</button>
    </div>
  </form>
</div>
