import { Component, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'bulk-update-order-modal',
  templateUrl: './bulk-update-order.html',
})
export class BulkUpdateOrderModal {
  action: string = '';
  question: string;
  errorMessage: string;
  ui: { error?: string } = {};
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  csvText: string;
  updateOrderList: Array<Array<string>> = [];
  actions: Array<{ name: string, value: string }> = [];
  public subscriptions: Subscription[] = [];
  static parameters: any = [ConnectionService, AppConfig, MatDialogRef];

  constructor(private conn: ConnectionService,
              private appConfig: AppConfig,
              private dialogRef: MatDialogRef<BulkUpdateOrderModal>) {
    this.actions = [
      ApiClientConstant.Order.Stage.AWB_GENERATED,
      ApiClientConstant.Order.Stage.PACKED,
      ApiClientConstant.Order.Stage.QC_PASS,
      ApiClientConstant.Order.Stage.RETURNED,
      ApiClientConstant.Order.Stage.DELIVERY_FAIL,
      'ADD_NOTE',
    ].map((each: string) => ({ name: each, value: each }));
    this.actions.unshift({ name: '------ Select ------', value: '' });
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  addDataToProcess(): void {
    this.csvText.split('\n')
      .forEach((row: string) => {
        if (!row.trim()) {
          return;
        }
        this.updateOrderList.push(row.split(',')
          .map((each: string) => each.trim())
          .filter((each: string) => each));
      });
    delete this.csvText;
    this.validateList();
  }

  readMargFileForPackedAction(): Promise<any> {
    return new Promise((resolve: Function): void => {
      const fileReader = new FileReader();
      fileReader.onload = (): void => {
        const text: string = fileReader.result as string;
        const orderProductMap = {};
        text.replace(/"/g, '').split('\n')
          .filter((row: any, index: number) => row && index)
          .map((each: string) => each.split(','))
          .forEach((row: Array<string>) => {
            const orderNumber = row[9];
            if (!orderProductMap[orderNumber]) {
              orderProductMap[orderNumber] = [];
            }
            orderProductMap[orderNumber].push(...new Array(Number(row[5])).fill(row[3]));
          });
        Object.keys(orderProductMap).forEach((key: string) => orderProductMap[key] = orderProductMap[key].join(','));
        resolve(orderProductMap);
      };
      fileReader.readAsText(this.fileInput.nativeElement.files[0]);
    });
  }

  async updateOrder(): Promise<any> {
    try {
      if (this.action === 'QC_PASS') {
        const orderProductMap = await this.readMargFileForPackedAction();
        this.updateOrderList.forEach((each: any) => {
          if (orderProductMap[each[0]]) {
            each.push(orderProductMap[each[0]]);
          }
        });
      }
      const params = {
        stage: this.action,
        rows: this.updateOrderList,
        batchId: `${this.action}_${moment().toISOString()}`,
      };
      const results = await this.conn.bulkUpdateOrder(params);
      alert(`Request successful. RequestId: ${results.batchId
      }, BatchRequestId: ${results.batchRequestId}`);
      this.hideModal();
    } catch (error) {
      alert(error.message);
    }
  }

  removeEntry(row: number): void {
    this.updateOrderList.splice(row, 1);
    this.validateList();
  }

  validateList(): void {
    delete this.errorMessage;
    if (!this.updateOrderList.length) {
      return;
    }
    const { length }: any = this.updateOrderList[0];
    if (this.updateOrderList.some((row: Array<string>) => row.length !== length)) {
      this.errorMessage = 'Given list is not valid';
    }
  }
}
