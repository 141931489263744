
<mat-dialog-content class="mat-dialog-content">
  <form role="form" name="messageNoteForm" #dataUploadForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ data.title }}</h4><i class="fas fa-times pull-right fontL3" style="position: relative; right: -15px; bottom:23px" (click)="dialogRef.close()"></i>
    </div>
    <div class="modal-body">
      <input class="w100" type="text" [(ngModel)]="response" required="required" placeholder="{{ data.title }}" [minlength]="data.minCharLength ? data.minCharLength : null"/>
      <p class="clr-red" *ngIf="data.minCharLength">min {{data.minCharLength}} chars required</p>
    </div>
    <div class="modal-footer">
      <button class="pull-right" type="button" name="submit" (click)="assignTag(response)" [disabled]="!dataUploadForm.valid">Send</button>
    </div>
  </form>
</mat-dialog-content>