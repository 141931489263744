import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InputTextareaComponent } from './input-textarea.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    MomentModule,
    MatDatepickerModule,
  ],
  declarations: [InputTextareaComponent],
  exports: [InputTextareaComponent],
})
export class InputTextareaModule {
}
