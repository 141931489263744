import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref-service';
import { ConnectionService } from '../connection-service';

@Injectable()
export class VoiceRecognitionService {
  recognition: any;
  public text: string = '';
  tempWords: string = '';
  onTextUpdateChange: (text: string) => void;

  constructor(private windowRefService: WindowRefService, private conn: ConnectionService) {
  }

  getSpeechRecognitionClass(): new () => typeof SpeechRecognition {
    const { webkitSpeechRecognition, SpeechRecognition }: any = this.windowRefService.nativeWindow;
    return webkitSpeechRecognition || SpeechRecognition;
  }

  isSupported(): boolean {
    return !this.conn.isMobileBrowser && !!this.getSpeechRecognitionClass();
  }

  reset(): void {
    const SpeechRecognition = this.getSpeechRecognitionClass();
    this.recognition = new SpeechRecognition();
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-IN';

    // @ts-ignore
    this.recognition.addEventListener('result', (e: SpeechRecognitionEvent) => {
      this.tempWords = Array.from(e.results)
        .map((result: SpeechRecognitionResult) => result[0])
        .map((result: SpeechRecognitionAlternative) => result.transcript)
        .join('');
      this.updatedText(this.concatTextAndTempWords());
    });

    this.recognition.addEventListener('end', () => {
      this.formParagraph();
      if (this.recognition) {
        this.recognition.start();
      }
    });
  }

  startVoiceRecognition(onTextUpdateChange: (text: string) => void = () => 0): void {
    if (!this.isSupported()) {
      return;
    }
    if (this.recognition) {
      return;
    }
    this.onTextUpdateChange = onTextUpdateChange;
    this.reset();
    this.recognition.start();
  }

  stopVoiceRecognition(): void {
    this.formParagraph();
    const { recognition }: any = this;
    delete this.recognition;
    if (recognition) {
      recognition.stop();
    }
    delete this.onTextUpdateChange;
    this.text = '';
    this.tempWords = '';
    this.formParagraph();
  }

  formParagraph(): void {
    this.text = this.concatTextAndTempWords();
    this.tempWords = '';
    this.updatedText(this.text);
  }

  private concatTextAndTempWords(): string {
    return `${this.text || ''} ${this.tempWords}`.trim();
  }

  private updatedText(paragraph: string): void {
    if (!this.onTextUpdateChange) {
      return;
    }
    this.onTextUpdateChange(paragraph);
  }
}
