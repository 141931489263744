import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { CallLogsComponent } from './call-logs.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSelectModule,
    MomentModule,
    RouterModule,
  ],
  declarations: [CallLogsComponent],
  exports: [CallLogsComponent],
})
export class CallLogModule {
}
