import { v4 as uuid } from 'uuid';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ControlContainer, UntypedFormControl, NgForm } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ApiClientConstant, ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { AppConfig } from '../../../app/app.config';
import { ConnectionService } from '../../../services/connection-service';
import { InputType } from '../../../../typings/client/input';
import { InputHelperService } from '../../input/input-helper';
import {
  SimilarLanguageStringSuggestionComponent,
} from '../similar-language-string-suggestion/similar_language_string_suggestion.component';
import { WindowRefService } from '../../../services/window-ref-service';
import { Broadcaster } from '../../broadcaster';

@Component({
  selector: 'language-string-selection',
  templateUrl: './language-string-selection.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class LanguageStringSelectionComponent {
  @Input('parseObj')
  set onUpdateParseObject(parseObj: any) {
    this.parseObj = parseObj;
    if (this.parseObj) {
      this.fetchIfObjectIfPointer();
    }
  }
  @Input('name')
  set onUpdateName(name: string) {
    this.name = name;
    this.fetchIfObjectIfPointer();
  }
  @Input('tags')
  set onUpdateTags(tags: any) {
    if (!tags) {
      this.fetchTags([]);
      return;
    }
    this.fetchTags(tags instanceof Array ? tags : [tags]);
  }
  @Input() showSearch:boolean;
  @Input('languageStringObj')
  set onUpdateLanguageStringObj(languageStringObj: any) {
    if (!languageStringObj) return;
    this.languageStringObj = languageStringObj;
    this.updateSelectedValue();
  }
  @Output('languageStringObjChange') languageStringObjChange: EventEmitter<any> = new EventEmitter();
  @Input('required') required: boolean = false;
  @Input('prefix') prefix: string;

  searchControl: UntypedFormControl = new UntypedFormControl();
  defaultValue: string = '';
  placeholder: string = '';
  ui: any = {};
  name: string = uuid();
  languageStringObj: any;
  parseObj: any;
  parseValue: any;
  options: Array<InputType.SelectOption> = [];
  tags: Array<any> = [];
  similarLanguageStrings: Array<any> = [];
  currentUser: any;

  constructor(public appConfig: AppConfig,
    private conn: ConnectionService,
    private inputHelper: InputHelperService,
    private dialog: MatDialog,
    private broadcaster: Broadcaster,
    private windowService: WindowRefService) {
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.searchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((newValue: string) => this.updateOptions());
  }

  onUpdateSelection(): void {
    const selectedValue: InputType.SelectOption = this.options
      .find((option: InputType.SelectOption) => (this.parseValue === option.value));
    this.languageStringObjChange.emit(selectedValue.parseValue);
    if (!this.parseObj || !this.name) return;
    this.inputHelper.setValue(this.parseObj, this.name, selectedValue.parseValue || selectedValue.value);
  }

  private async fetchIfObjectIfPointer(): Promise<any> {
    if (!this.parseObj || !this.name) return;
    this.languageStringObj = this.inputHelper.getValue(this.parseObj, this.name);
    await this.updateSelectedValue();
  }

  private async updateSelectedValue(): Promise<void> {
    if (this.languageStringObj && this.languageStringObj.id) {
      if ((this.languageStringObj instanceof (ApiConnector as unknown as {
        getMongoToParseQueryBase: () => ({ getParse: () => ({ Object: any }) })
      }).getMongoToParseQueryBase().getParse().Object) && !this.languageStringObj.get('en')) {
        await this.languageStringObj.fetch();
      }
      this.parseValue = this.languageStringObj.id;
    }
    this.parseValue = this.parseValue || this.defaultValue;
  }

  private async fetchTags(tags: Array<string>): Promise<any> {
    this.tags = await this.conn.fetchLanguageStringTags({ tags });
    this.placeholder = tags.map((x: string) => `"${x}"`).join(', ');
    await this.updateOptions();
  }

  public async updateOptions(): Promise<void> {
    const payload: RequestQueryPayload<Table.LanguageString> = {
      where: {},
      project: ['en'],
      limit: 100,
    };
    if (this.tags?.length) {
      payload.where.tags = this.tags;
    }
    if (this.searchControl.value || this.languageStringObj?.get('en')) {
      payload.where.en = { $regex: this.searchControl.value || this.languageStringObj.get('en'), $options: 'i' };
    }
    const languageStrings = await this.conn.findLanguageStrings(payload);
    this.options = languageStrings.map((languageString: any): InputType.SelectOption => ({
      display: languageString.get(ApiClientConstant.LanguageString.EN),
      value: languageString.id,
      parseValue: languageString,
    }));
    if (this.languageStringObj?.id
      && this.options.every((each: InputType.SelectOption) => each.value !== this.languageStringObj.id)) {
      this.options.unshift({
        display: this.languageStringObj.get(ApiClientConstant.LanguageString.EN),
        value: this.languageStringObj.id,
        parseValue: this.languageStringObj,
      });
    }
  }

  async findSimilarLanguageString(): Promise<any> {
    let regex = `^${this.languageStringObj.get('en')}$`;
    if (['!', '?', '.'].includes(this.languageStringObj.get('en').charAt(this.languageStringObj.get('en').length - 1))) {
      regex = `^${this.languageStringObj.get('en')}`;
    }
    const payload: RequestQueryPayload<Table.LanguageString> = {
      where: {},
      include: ['tags'],
    };
    if (this.languageStringObj.get('en')) {
      payload.where.en = { $regex: regex, $options: 'i' };
    }
    try {
      this.similarLanguageStrings = await this.conn.findLanguageStrings(payload);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', {
        message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  async addLanguageString(): Promise<any> {
    if (!this.searchControl.value) return;
    this.languageStringObj = new Table.LanguageString();
    this.languageStringObj.set('tags', this.tags);
    this.languageStringObj.set('en', this.searchControl.value);

    await this.findSimilarLanguageString();
    if (this.similarLanguageStrings.length) {
      const dialogRef = this.dialog
        .open(SimilarLanguageStringSuggestionComponent, { data: { languageStrings: this.similarLanguageStrings } });
      dialogRef.afterClosed().subscribe(async (similarLanguageString: any): Promise<any> => {
        if (!similarLanguageString) return this.createLanguageString();
        if (similarLanguageString === this.appConfig.Shared.Actions.close) return '';
        this.languageStringObj.get('tags').forEach((tag: any): void => {
          const isTagPresent = similarLanguageString.get('tags').find((value: any): any => tag.get('name') === value.get('name'));
          if (!isTagPresent) {
            similarLanguageString.get('tags').push(tag);
          }
        });
        await similarLanguageString.save();
        this.showSearch = false;
        return similarLanguageString;
      });
    } else {
      this.createLanguageString();
    }
  }
  async createLanguageString(): Promise<void> {
    if (!this.languageStringObj.get('createdBy')) {
      this.languageStringObj.set('createdBy', this.currentUser);
    }
    await this.languageStringObj.save();
    await this.updateOptions();
    this.showSearch = false;
  }

  edit(languageStringId: string): void {
    this.windowService.nativeWindow.open(`${this.conn.getBaseUrl()}/languageString/${languageStringId}/edit`);
  }
}
