<span>(</span><span class="m-l-xs m-r-xs" *ngFor="let condition of conditions; let i = index;"><span class="flex-inline" [ngClass]="{'btn btn-sm rounded bg-light': [apiClientConstant.Question.Conditions.Type.INSTANT_CHECKUP].includes(condition.type) }">
    <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.type" name="{{prefix}}_{{i}}_condition" required="required" (change)="updateComparator(condition.type)">
      <option disabled="disabled" [value]="undefined" selected="selected">Type</option>
      <option *ngFor="let keyValue of types" value="{{keyValue.value}}">{{keyValue.key}}</option>
    </select><span class="m-l-xs m-r-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.AND, apiClientConstant.Question.Conditions.Type.OR].includes(condition.type)">
      <question-condition [conditions]="condition.conditions" [type]="condition.type" prefix="{{prefix}}_{{i}}"></question-condition></span><span class="m-l-xs m-r-xs" *ngIf="apiClientConstant.Question.Conditions.Type.INSTANT_CHECKUP === condition.type" style="display: inline-flex;">
      <input class="input-xs" [(ngModel)]="condition.recentInstantCheckupCount" name="{{prefix}}_{{i}}_recent_instant_checkup_count" type="number" placeholder="Check for # recent"/>
      <mat-select class="m-l-xs m-r-xs bg-white" [(ngModel)]="condition.instantCheckupType" name="{{prefix}}_{{i}}_instant_checkup_type" required="true" [formControl]="formController" style="min-width:150px;" multiple="multiple">
        <mat-option *ngFor="let item of instantCheckupTypes" [value]="item.value">{{item.key}}</mat-option>
      </mat-select>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.field" name="{{prefix}}_{{i}}_instant_checkup_issue" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Issue To Check</option>
        <option *ngFor="let keyValue of instantCheckupIssues" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <input class="input-xs m-l-xs m-r-xs" [(ngModel)]="condition.value" name="{{prefix}}_{{i}}_value" placeholder="Value" required="required"/></span><span class="m-l-xs m-r-xs m-t-xs m-b-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.ANY_ORDER_IN_STAGE_GROUP].includes(condition.type)" style="display: inline-flex;">
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.field" name="{{prefix}}_{{i}}any_order_in_stage_group" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Stage</option>
        <option *ngFor="let keyValue of orderStages" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select></span><span class="m-l-xs m-r-xs m-t-xs m-b-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.MATCH_WITH_CONTEXT].includes(condition.type)" style="display: inline-flex;">
      <select [(ngModel)]="condition.field" name="{{prefix}}_{{i}}_input_field" require="require">
        <option *ngFor="let value of inputVariables" [value]="value">{{value}}</option>
      </select>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <input [(ngModel)]="condition.value" name="{{prefix}}_{{i}}_input_value" required="required" placeholder="Value to be compared"/></span><span class="m-l-xs m-r-xs m-t-xs m-b-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.ACTIVE_REGIMEN].includes(condition.type)" style="display: inline-flex;">
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.field" name="{{prefix}}_{{i}}_active_regimen_type" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Regimen Class</option>
        <option *ngFor="let keyValue of activeRegimenClasses" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select></span><span class="m-l-xs m-r-xs m-t-xs m-b-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.ACTIVE_REGIMEN_HAVING_CONCERN].includes(condition.type)" style="display: inline-flex;">
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.field" name="{{prefix}}_{{i}}_active_regimen_having_concern_type">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Regimen Class</option>
        <option *ngFor="let keyValue of activeRegimenClasses" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <input class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.value2" name="{{prefix}}_{{i}}_value2" required="required" placeholder=", seperated concerns"/>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.value" name="{{prefix}}_{{i}}_value" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Value</option>
        <option value="true">Concern Present</option>
        <option value="false">Concern Not Present</option>
      </select></span><span class="m-l-xs m-r-xs m-t-xs m-b-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.QUESTION_ANSWER_MATCH].includes(condition.type)" style="display: inline-flex;">
      <input class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.field" name="{{prefix}}_{{i}}_question_answer_match" required="required" placeholder=", seperated questionIds"/>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <input class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.value" name="{{prefix}}_{{i}}_input_value" required="required" placeholder="value"/></span><span class="m-l-xs m-r-xs m-t-xs m-b-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.NUMBER_OF_STEROID_USING].includes(condition.type)" style="display: inline-flex;">
      <input class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.field" name="{{prefix}}_{{i}}_number_of_steroid_using" required="required" placeholder=", seperated questionIds"/>
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <input class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.value" name="{{prefix}}_{{i}}_input_value" required="required" placeholder="value" pattern="[0-9]*"/></span><span class="m-l-xs m-r-xs m-t-xs m-b-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.FOLLOWUP_STATE].includes(condition.type)" style="display: inline-flex;">
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select>
      <input class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.value" name="{{prefix}}_{{i}}_input_value" required="required" placeholder="value"/></span><span class="m-l-xs m-r-xs m-t-xs m-b-xs" *ngIf="[apiClientConstant.Question.Conditions.Type.ALLOCATED_DOCTOR].includes(condition.type)" style="display: inline-flex;">
      <select class="m-l-xs m-r-xs input-xs" [(ngModel)]="condition.comparator" name="{{prefix}}_{{i}}_comparator" required="required">
        <option disabled="disabled" [value]="undefined" selected="selected">Select Comparator</option>
        <option *ngFor="let keyValue of comparators" value="{{keyValue.value}}">{{keyValue.key}}</option>
      </select></span><i class="fa fa-trash-o pointer m-l-xs m-r-xs pt-3" (click)="removeCondition(i)"></i></span><span class="m-l-xs m-r-xs" *ngIf="i < (conditions.length - 1)">{{operand}}</span></span><i class="fa fa-plus-square-o pointer m-l-xs m-r-xs" (click)="addNewCondition()"></i><span>)</span>