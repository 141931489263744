import { Component, Inject, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../../../../services/connection-service';
import { AppConfig } from '../../../../app.config';
import { WindowRefService } from '../../../../../services/window-ref-service';
import { HelperService } from '../../../../../services/helper-service';
import { Broadcaster } from '../../../../../components/broadcaster';

@Component({ selector: 'edit-instant-checkup', templateUrl: './edit-instant-checkup.html' })
export class EditInstantCheckup {
  types: Array<any>;
  instantCheckup: any;
  imageType: string;
  imageUrl: string;
  file: any;
  loading: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<EditInstantCheckup>,
    private windowRef: WindowRefService,
    private conn: ConnectionService,
    private http: HttpClient,
    private helperService: HelperService,
    private appConfig: AppConfig,
    private broadcastService: Broadcaster) {
  }

  async ngOnInit(): Promise<void> {
    this.types = Object.values(ApiClientConstant.InstantCheckup.Type);
    this.instantCheckup = await this.conn.getInstantCheckupById(this.data.id);
    this.imageUrl = this.data.imageUrl;
    this.imageType = this.instantCheckup.get('type');
    const urlArray = this.imageUrl.split('/');
    let fileName = urlArray[urlArray.length - 1].split('?')[0];
    const fileExtension = fileName.split('.')[1];
    fileName = fileName.split('.')[0];
    this.file = await this.helperService.getFileByImageUrl(this.imageUrl, fileName, fileExtension);
  }

  async updateInstantCheckup(): Promise<void> {
    this.loading = true;
    try {
      if (!this.file) throw new Error('File not fetched. Retry.');
      const aiResponse: any = await this.conn.getAiResponse(this.file, this.imageType);
      const previousInstantChecupType = this.instantCheckup.get('type');
      this.instantCheckup.set('type', this.imageType);
      this.instantCheckup.set('aiResponse', aiResponse);
      await this.instantCheckup.save();
      const actionLog = new Table.ActionLog();
      actionLog.set('user', this.instantCheckup.get('user'));
      actionLog.set('ByUser', this.conn.getCurrentUser());
      actionLog.set('STATUS', ApiClientConstant.ActionLog.STATUS.COMPLETE);
      actionLog.set('type', ApiClientConstant.ActionLog.Type.INSTANT_CHECKUP_TYPE_CHANGED);
      actionLog.set('Action', `InstantCheckup type changed from ${previousInstantChecupType} to ${this.imageType}`);
      actionLog.set('instantCheckup', this.instantCheckup);
      actionLog.set('actionQuestionUniqueId', 'instantCheckupTypeChanged');
      await actionLog.save();
    } catch (error) {
      this.broadcastService.broadcast('NOTIFY', { message: error.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
    }
    this.loading = false;
    this.dialogRef.close(this.instantCheckup);
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
