import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { format, parseISO, isAfter, isBefore, startOfDay, endOfDay, addHours } from 'date-fns';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'dashboard-ticket',
  templateUrl: 'ticket-information.html',
  styleUrls: ['../dashboard.scss'],
})
export class DashboardTicketComponent {
  user: Table.User;
  startDate: Date;
  endDate: Date;

  // ticket variables
  totalUnAttendedTickets: number = 0;
  dataItems: Array<{ title: string, value: number }> = [];

  @Input('userDetails')
  set updateStartDate(userDetails: { startDate: Date, endDate: Date, user: Table.User }) {
    this.startDate = addHours(startOfDay(userDetails.startDate), 6);
    this.endDate = addHours(startOfDay(userDetails.endDate), 6);
    this.user = userDetails.user;
    this.getTicketDetails();
  }

  constructor(private conn: ConnectionService) {}

  async getTicketsCreatedByUser(userType: string): Promise<number> {
    return ApiConnector.count(Table.SupportTicket, {
      where: {
        $and: [
          { createdAt: { $gte: startOfDay(this.startDate) } },
          { createdAt: { $lte: endOfDay(this.endDate) } },
        ],
        createdBy: this.user,
        teams: { $in: userType },
      },
    });
  }

  async getTicketsClosedByUser(userType: string): Promise<number> {
    return ApiConnector.count(Table.SupportTicket, {
      where: {
        $and: [
          { createdAt: { $gte: startOfDay(this.startDate) } },
          { createdAt: { $lte: endOfDay(this.endDate) } },
        ],
        completedBy: this.user,
        teams: { $in: userType },
      },
    });
  }

  async getPendingUnAttendedTickets(): Promise<number> {
    return ApiConnector.count(Table.SupportTicket, {
      where: {
        attended: false,
        status: ApiClientConstant.SupportTicket.Status.Pending,
        createdAt: { $lt: startOfDay(this.startDate) },
        allocatedDoctor: this.user,
        teams: { $in: ['doctor'] },
      },
    });
  }

  async getCurrentUnAttendedTickets(): Promise<number> {
    return ApiConnector.count(Table.SupportTicket, {
      where: {
        attended: false,
        status: ApiClientConstant.SupportTicket.Status.Pending,
        $and: [
          { createdAt: { $gte: startOfDay(this.startDate) } },
          { createdAt: { $lte: endOfDay(this.endDate) } },
        ],
        allocatedDoctor: this.user,
        teams: { $in: ['doctor'] },
      },
    });
  }

  async getTicketDetails(): Promise<void> {
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.dataItems = [];
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.dataItems = [];
    if (isAfter(this.startDate, this.endDate)) {
      return;
    }
    const pendingUnAttendedTickets = await this.getPendingUnAttendedTickets();
    const currentUnAttendedTickets = await this.getCurrentUnAttendedTickets();
    let todaysAllAttendedTickets = await this.conn.findTotalRespondedTickets(this.startDate, this.endDate, this.user.get('username'));
    todaysAllAttendedTickets = todaysAllAttendedTickets.respondedTickets;
    this.totalUnAttendedTickets = pendingUnAttendedTickets + currentUnAttendedTickets;
    this.dataItems.push(
      { title: 'Todays Pending', value: currentUnAttendedTickets },
      { title: 'Yesterday Pending', value: pendingUnAttendedTickets },
      { title: 'Completed Today', value: todaysAllAttendedTickets },
    );
  }
}
