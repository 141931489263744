import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputImageUploadComponent } from './input-image-upload.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [InputImageUploadComponent],
  exports: [InputImageUploadComponent],
})
export class InputImageUploadModule {
}
