
<mat-dialog-content *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Address Book</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form class="col-xs-12" #addressEdit="ngForm">
      <div class="col-xs-12 flex">
        <div class="col-xs-4">
          <label class="col-xs-12 mt-0">Contact Name</label>
          <input-text [parseObj]="addressBook" name="contactName" placeholder="Contact Name" required="required"></input-text>
        </div>
        <div class="col-xs-4 ml-3">
          <label class="col-xs-12 mt-0">Building Details</label>
          <input-text [parseObj]="addressBook" name="buildingDetails" placeholder="Building Name"></input-text>
        </div>
        <div class="col-xs-4 ml-3">
          <label class="col-xs-12 mt-0">City</label>
          <input-text #cityInput [parseObj]="addressBook" name="city" placeholder="City" required="required" ></input-text>
        </div>
      </div>
      <div class="col-xs-12 flex">
        <div class="col-xs-4">
          <label class="col-xs-12">State</label>
          <input-select #stateInput [parseObj]="addressBook" name="state" [options]="stateOptions" placeholder="State" required="required"></input-select>
        </div>
        <div class="col-xs-4 ml-3">
          <label class="col-xs-12">Landmark</label>
          <input-text [parseObj]="addressBook" name="landmark" placeholder="Landmark"></input-text>
        </div>
        <div class="col-xs-4 ml-3">
          <label class="col-xs-12">ZipCode</label>
          <input-text [parseObj]="addressBook" name="zipCode" placeholder="ZipCode" required="required" (change)="setStateAndCity($event)"></input-text>
        </div>
      </div>
      <div class="col-xs-12 flex">
        <div class="col-xs-4">
          <label class="col-xs-12">Alternate Number</label>
          <input-text [parseObj]="addressBook" name="alternateNumber" placeholder="Alternate Number"></input-text>
        </div>
        <div class="col-xs-4 ml-3">
          <label class="col-xs-12">Mobile Number</label>
          <input-text [parseObj]="addressBook" required="required" name="mobileNumber" placeholder="Mobile Number"></input-text>
        </div>
        <div class="col-xs-4 ml-3">
          <label class="col-xs-12">Tag</label>
          <input-select [parseObj]="addressBook" required="required" name="tag" [options]="tagsOptions" placeholder="Tag"></input-select>
        </div>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-3">Default Address</label>
        <input-checkbox [parseObj]="addressBook" name="default"></input-checkbox>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-3">Verified</label>
        <input-checkbox [parseObj]="addressBook" name="verified"></input-checkbox>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" (click)="saveAddressBook()" [disabled]="!addressEdit.valid">Save</button>
  </div>
</mat-dialog-content>