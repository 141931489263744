import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { WindowRefService } from 'src/services/window-ref-service';
import { ConnectionService } from 'src/services/connection-service';
import { Table } from 'api-client';

@Component({
  selector: 'sales-support-ticket',
  standalone: true,
  imports: [CommonModule, MomentModule, RouterModule],
  templateUrl: './sales-support-ticket.component.html',
  styleUrl: './sales-support-ticket.component.scss',
})
export class SalesSupportTicketComponent {
  @Input('username') username: string;
  @Input('user') user: any;
  tickets: any = [];

  constructor(private conn: ConnectionService,
    private window: WindowRefService,
  ) { }

  async ngOnInit(): Promise<void> {
    const user = await this.conn.getUserByUserName(this.username);
    this.tickets = await this.conn.fetchSupportTickets(
      {
        where: { user },
        descending: 'createdAt',
        include: ['supportQuestion', 'supportQuestion.questionLanguageString' as 'supportQuestion',
          'titleLanguageString', 'createdBy', 'completedBy'],
      });
  }

  redirectToTicketPage(ticket: Table.SupportTicket): void {
    if (!ticket) return;
    const url = `${this.conn.getBaseUrl()}/ticket/${ticket.get('user').id}?ticketId=${ticket.id}`;
    this.window.nativeWindow.open(url, '_blank');
  }
}
