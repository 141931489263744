import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssignedChatsComponent } from './assigned-chats.component';
import { ListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'assignedchats',
    component: AssignedChatsComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [AssignedChatsComponent],
exports: [AssignedChatsComponent] })
export class AssignedChatsModule {}
