import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../app/app.config';
import { ConnectionService } from '../../../services/connection-service';

@Component({
  selector: 'product-instruction-selection',
  templateUrl: './product-instruction-selection.html',
})
export class ProductInstructionSelectionModal {
  product: any;
  partOfDay: string;
  instructions: Array<any>;
  selectedInstructionCreationId: string;
  productTitle: string;
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;

  constructor(private conn: ConnectionService,
    private router: Router,
    private appConfig: AppConfig,
    private injector: Injector,
    private dialogRef: MatDialogRef<ProductInstructionSelectionModal>) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.product = data.product;
    this.partOfDay = data.partOfDay;
    this.selectedInstructionCreationId = data.selectedInstructionCreationId;
    this.productTitle = this.getProductDisplayName(this.product);
    this.autoCompleteProductController?.setValue(this.productTitle);
  }

  async ngOnInit(): Promise<void> {
    let instructions = this.product.get('instructions') || [];
    let mainProduct = this.product.get('mainProduct');
    if (!instructions?.length && !mainProduct?.get('instructions')) {
      await this.product.get('mainProduct').fetch();
      mainProduct = this.product.get('mainProduct');
      instructions = mainProduct.get('instructions');
    }
    if (this.partOfDay) {
      instructions = instructions
        .filter((instruction: any): boolean => (instruction?.partOfDay === this.partOfDay?.toUpperCase() || !instruction.partOfDay));
    }
    this.instructions = instructions;
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
      },
      include: ['instructions.frequencyOfApplicationLanguageString' as 'instructions',
        'instructions.frequencyOfApplicationLanguageString' as 'instructions',
        'instructions.areaOfApplicationLanguageString' as 'instructions',
        'instructions.areaOfApplicationLanguageString' as 'instructions',
        'instructions.quantityUnitLanguageString' as 'instructions',
        'instructions.quantityUnitLanguageString' as 'instructions'],
      project: ['title', 'quantity', 'quantityUnit', 'type', 'margUnit', 'mrp', 'price', 'instructions',
        'purposeDescriptionLanguageString', 'isRepair'],
      ascending: 'margUnit',
      limit: 10,
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(product),
      object: product,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  autoCompleteOnProductSelect(item: { name: string; object: any }): void {
    this.product = item.object;
    this.instructions = this.product.get('instructions');
    if (this.partOfDay) {
      this.instructions = this.instructions
        .filter((instruction: any): boolean => (instruction?.partOfDay === this.partOfDay?.toUpperCase() || !instruction.partOfDay));
    }
    delete this.selectedInstructionCreationId;
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  changeInstruction(index: number): void {
    this.selectedInstructionCreationId = this.instructions[index].creationId;
    this.dialogRef.close({ selectedInstructionCreationId: this.selectedInstructionCreationId, product: this.product });
  }
}
