import { Component, NgZone, OnDestroy } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { ApiConnector, RequestQueryPayload, Table } from 'api-client';
import * as moment from 'moment';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  searchKey: string;
  dataSource: any;
  components: any;
  columnDefs: any;
  ui: any;
  count: number;
  constructor(private conn: ConnectionService,
    private zone: NgZone,
    private windowRef: WindowRefService,
    private appConfig: AppConfig) {
  }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 50,
      cellRenderer: (params: any): any => {
        if (!params.data) {
          const eDiv = this.windowRef.nativeWindow.document.createElement('div');
          eDiv.innerHTML = 'Loading...';
          return eDiv;
        }
        const id = params.value || params.data.objectId;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pointer' href='/marketing/explore/${id}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'Caption',
      field: 'captionLanguageJson',
      width: 300,
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value?.en ?? '-';
        return eDiv;
      },
    }, {
      headerName: 'Type',
      width: 100,
      field: 'type',
    }, {
      headerName: 'Active',
      width: 100,
      field: 'active',
    }, {
      headerName: 'CTA',
      field: 'ctaUrl',
    }];
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<Array<any>> {
    const searchOn = ['captionLanguageJson.en', 'captionLanguageJson.hi'];
    const requestPayload: RequestQueryPayload<Table.Feed> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      descending: 'createdAt',
    };
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    let data;
    [data, this.count] = await Promise.all([
      ApiConnector.find(Table.Feed, requestPayload)
        .then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      ApiConnector.count(Table.Feed, { where: requestPayload.where }),
    ]);
    return data;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
  }
}
