import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Table } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';
import { Broadcaster } from '../broadcaster';

@Component({
  selector: 'change-leave-status',
  templateUrl: './change-leave-status.html',
})
export class ChangeLeaveStatus {
  selectedStatus: any;
  selectedLeaves: any;
  leaveStatusList: Array<any> = [];
  constructor(
    private dialogRef: MatDialogRef<ChangeLeaveStatus>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig) { }

  async ngOnInit(): Promise<void> {
    this.leaveStatusList = ['APPROVED', 'REJECTED'];
  }

  async save(): Promise<void> {
    this.selectedLeaves = this.data.leavesList;
    try {
      await Promise.all(this.selectedLeaves.map((id: string) => {
        const leave = new Table.LeaveAndHoliday();
        leave.id = id;
        if (this.selectedStatus) {
          leave.set('status', this.selectedStatus);
        }
        return leave.save();
      }));
      this.broadcaster.broadcast('NOTIFY',
        { message: 'Leave Status Changed Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.dialogRef.close(this.selectedStatus);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
