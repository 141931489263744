import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { QuestionViewComponent } from './question-view.component';

const ROUTES: Routes = [
  { path: '', component: QuestionViewComponent, pathMatch: 'full' },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  DirectivesModule,
  RouterModule.forChild(ROUTES),
],
declarations: [QuestionViewComponent],
exports: [QuestionViewComponent] })
export class QuestionViewModule {
}
