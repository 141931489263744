import { Component, Input } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { InputType } from '../../../../../typings/client/input';

@Component({ selector: 'order-refund-tracking', templateUrl: './order-refund-tracking.html' })
export class OrderRefundTrackingComponent {
  @Input('order')
  set setOrder(x: any) {
    this.updateOrder(x);
  }
  options: Array<InputType.SelectOption> = [];
  orderRefundObj: any;

  constructor(private conn: ConnectionService, public appConfig: AppConfig) {
    this.options = Object.keys(ApiClientConstant.Refund.Status).map((key: string): InputType.SelectOption => ({
      display: ApiClientConstant.Refund.Status[key],
      value: ApiClientConstant.Refund.Status[key],
    }));
  }

  private async updateOrder(order: any): Promise<void> {
    if (!order) {
      delete this.orderRefundObj;
      return;
    }
    const [orderRefund]: Array<any> = await this.conn.findRefund({
      where: { order, status: ApiClientConstant.Refund.Status.REFUND_REQUESTED },
    });
    this.orderRefundObj = orderRefund;
  }

  async updateRefundStatus(): Promise<void> {
    await this.orderRefundObj.save();
    await this.updateOrder(this.orderRefundObj);
  }
}
