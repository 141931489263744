import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../../app/app.config';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({
  selector: 'view',
  templateUrl: './view.html',
  styleUrls: ['./view.scss'],
})
export class ViewComponent implements OnInit {
  mainConcernId: string;
  mainConcern: any = '';
  keywords: any = '';
  descriptionLanguageString: any = '-';

  constructor(private route: ActivatedRoute,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    windowRef: WindowRefService) { }

  async ngOnInit(): Promise<any> {
    this.mainConcernId = this.route.snapshot.params.id;
    this.mainConcern = await this.conn.findMainConcernsById(this.mainConcernId);
    this.keywords = this.mainConcern.get('keywords');
    if (this.mainConcern.get('descriptionLanguageString')) {
      this.descriptionLanguageString = this.mainConcern.get('descriptionLanguageString').get('en');
    }
  }
}
