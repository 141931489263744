import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { format, parseISO, isAfter, isBefore, startOfDay, endOfDay } from 'date-fns';
import { ApiConnector, Table } from 'api-client';
import { ApiClientConstant } from '@cureskin/api-client';

@Component({
  selector: 'dashboard-callLog',
  templateUrl: './call-information.html',
  styleUrls: ['../dashboard.scss'],
})
export class DashboardCallComponent {
  user: any;
  startDate: Date;
  endDate: Date;

  // call variables
  totalCall: number = 0;
  incompleteCall: number = 0;
  completedCall: number = 0;
  dataItems: Array<{ title: string, value: number }> = [];

  @Input('userDetails')
  set updateStartDate(userDetails: { startDate: Date, endDate: Date, user: Table.User }) {
    this.startDate = userDetails.startDate;
    this.endDate = userDetails.endDate;
    this.user = userDetails.user;
    this.getCallDetails();
  }

  constructor() {}

  async getCallsDoneByDoctors(): Promise<number> {
    const allCallDoneByDoctor = await ApiConnector.find(Table.CallLog, {
      where: {
        direction: 'outgoing',
        from: `0${this.user.get('exotelNumber')}`,
        createdAt: { $gte: startOfDay(this.startDate), $lte: endOfDay(this.endDate) },
      },
      project: ['status'],
    });
    const completedCall = allCallDoneByDoctor.filter(
      (each: Table.CallLog) => each.get('status') === ApiClientConstant.CallLogs.Status.COMPLETED,
    );
    this.completedCall = completedCall.length;
    return allCallDoneByDoctor.length;
  }

  async getCallDetails(): Promise<void> {
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.dataItems = [];
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.dataItems = [];
    if (isAfter(this.startDate, this.endDate)) {
      return;
    }
    this.totalCall = await this.getCallsDoneByDoctors();
    this.incompleteCall = this.totalCall - this.completedCall;
    this.dataItems.push(
      { title: 'Completed Calls', value: this.completedCall },
      { title: 'Incomplete Calls', value: this.incompleteCall },
    );
  }
}
