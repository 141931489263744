import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({ selector: 'question-option-mapping-modal', templateUrl: './question-option-mapping.html' })
export class QuestionOptionMappingModal {
  oldQuestion: any;
  oldQuestionOptions: string[] = [];
  newQuestion: any;

  newQuestionOptions: Array<string> = [];
  mappingArray: Array<any> = [];
  errorMessage: string;
  constructor(private dialogRef: MatDialogRef<QuestionOptionMappingModal>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.oldQuestion = this.data.oldQuestion;
    this.oldQuestionOptions = this.data.oldQuestionOptions;
    this.newQuestion = this.data.newQuestion;
  }

  ngOnInit(): void {
    this.initialize();
  }

  hideModal(data: any): void {
    this.dialogRef.close(data);
  }

  private generateOptionsForQuestion(question: any): Array<string> {
    if (!question.get('inputs').length) {
      return ['_default'];
    }
    return question.get('inputs').map((input: any) => (input.value || input.text || '_default'));
  }

  private initialize(): void {
    this.newQuestionOptions = this.generateOptionsForQuestion(this.newQuestion);
    this.mappingArray = this.newQuestionOptions.map((option: string) => ({ key: option, value: '' }));
  }

  confirmMapping(): void {
    const oldToNewQuestionMapping = {};
    const errorValues = [];
    this.mappingArray.filter((option: any) => !!option.value).forEach((option: any) => {
      if (Object.keys(oldToNewQuestionMapping).includes(option.value)
        && !errorValues.includes(option.value)) {
        errorValues.push(option.value);
      }
      oldToNewQuestionMapping[option.value] = option.key;
    });
    if (errorValues.length) {
      this.errorMessage = `Multiple time selection of same value is not allowed for "${errorValues.join(',')}"`;
      return;
    }
    this.errorMessage = '';
    this.hideModal(oldToNewQuestionMapping);
  }
}
