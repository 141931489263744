
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/products/margId">MargIds</a></li>
          <li>{{margIdInfoObj && margIdInfoObj.id ? 'Edit' : 'New' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content" *ngIf="margIdInfoObj">
      <form class="col-xs-12 p-4" #margIdForm="ngForm">
        <div class="col-xs-12">
          <div class="col-xs-4">
            <label>MargId</label>
            <input-text [parseObj]="margIdInfoObj" name="margId"></input-text>
          </div>
          <div class="col-xs-4 pl-4">
            <label>Generic Name</label>
            <input-text [parseObj]="margIdInfoObj" name="genericName"></input-text>
          </div>
        </div>
        <div class="col-xs-12 mt-5">
          <button type="submit" [disabled]="!margIdForm.valid" (click)="saveMargIdInfo()">{{ margIdInfoObj.id ? 'Update' : 'Create' }}</button>
        </div>
      </form>
    </div>
  </div>
</div>