import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { LanguageStringTagComponent } from './language-string-tag.component';
import { ListActionCellComponent } from './list-action-cell/list-action-cell.component';

const ROUTES: Routes = [
  {
    path: '',
    component: LanguageStringTagComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    AgGridAngular,
  ],
  declarations: [LanguageStringTagComponent, ListActionCellComponent],
  exports: [LanguageStringTagComponent],
})
export class LanguageStringTagModule {
}
