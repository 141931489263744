import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LanguageStringEditComponent } from './language-string-edit.component';
import { InputTextareaModule } from '../../../components/input/input-textarea';
import { LanguageStringTagSelectionModule } from '../../../components/language-string/language-string-tag-selection';
import {
  SimilarLanguageStringModule,
} from '../../../components/language-string/similar-language-string-suggestion/similar-language-string.module';
import {
  WysiwygModule,
} from '../../../components/wysiwyg/wysiwyg.module';

const ROUTES: Routes = [
  { path: '', component: LanguageStringEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    WysiwygModule,
    FormsModule,
    InputTextareaModule,
    LanguageStringTagSelectionModule,
    SimilarLanguageStringModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [LanguageStringEditComponent],
  exports: [LanguageStringEditComponent],
})
export class LanguageStringEditModule {
}
