import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { UserNotesDoctorComponent } from './user-notes-doctor/user-notes-doctor.component';
import { UserNotesOperatorComponent } from './user-notes-operator/user-notes-operator.component';
import { UserNotesTicketComponent } from './user-notes-ticket/user-notes-ticket.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MomentModule,
  ],
  declarations: [UserNotesDoctorComponent, UserNotesOperatorComponent, UserNotesTicketComponent],
  exports: [UserNotesDoctorComponent, UserNotesOperatorComponent, UserNotesTicketComponent],
})
export class UserNoteModule {
}
