import { Component, Input, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataUploadModal } from 'src/components/modal/dataUploadModal';
import { Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';

@Component({ selector: 'waitlist-chat', templateUrl: './wait-list.html', styleUrls: ['./wait-list.scss'] })
export class WaitlistChatComponent {
  @Input('user')
  set userFunction(user: any) {
    this.user = user;
    this.getWaitlistStatus();
  }
  user: any;
  waitlistChat: any;
  assignOptions: Array<any> = [];

  constructor(
    private appConfig: AppConfig,
    private dialog: MatDialog,
    private conn: ConnectionService,
    private zone: NgZone,
  ) {
  }

  async ngOnInit(): Promise<any> {
    this.assignOptions = await this.conn.getInternalUsers();
  }

  openDataUploadDialog(): any {
    const dialogRef = this.dialog.open(DataUploadModal, { data: { title: 'Waitlist Message' } });
    dialogRef.afterClosed().subscribe((data: string) => {
      this.assignTag(this.appConfig.Shared.Tag.Status.ASSIGNED, this.appConfig.Shared.Tag.Tag.WAITLIST, undefined, data);
    });
  }

  async getWaitlistStatus(): Promise<any> {
    [this.waitlistChat] = await this.conn.findTags({
      where: {
        user: this.user,
        tag: { $regex: this.appConfig.Shared.Tag.Tag.WAITLIST, $options: 'i' },
        status: [this.appConfig.Shared.Tag.Status.ASSIGNED],
      },
      project: ['createdAt', 'description'],
    });
  }

  async assignTag(status: string, tag: string, tagParam?: any, description?: string): Promise<void> {
    const newTag = new Table.Tag();
    newTag.set('tag', tag);
    newTag.set('taggedBy', this.conn.getCurrentUser());
    newTag.set('user', this.user);
    newTag.set('type', this.appConfig.Shared.Tag.Type.USER_LEVEL);
    newTag.set('status', status);
    if (tagParam) {
      newTag.set('tagNote', tagParam);
    }
    if (description) {
      newTag.set('description', description);
    }
    await newTag.save();
    this.waitlistChat = newTag;
  }

  async removeFromWaitList(): Promise<void> {
    this.waitlistChat.set('status', this.appConfig.Shared.Tag.Status.FINISHED);
    this.waitlistChat.set('completedby', this.user);
    await this.waitlistChat.save();
    this.zone.run(() => (delete this.waitlistChat));
  }
}
