import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { LocalStorage } from '../../../../services/local-storage-service';

@Component({ selector: 'regimen-sop-list', templateUrl: './list.html' })
export class RegimenSopListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  activeRegimen: boolean = true;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;

  // Local Variables
  filterByTag: any = { tags: [] };
  searchKey: string;
  user: any;
  userRoles: Array<any> = [];
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.user = this.conn.getCurrentUser();
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.listViewSetup();
    this.route.queryParams.subscribe(() => {
      this.reset();
    });
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Edit',
        width: 50,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<a class='fa fa-pencil-square-o', href='tree/${params.data.version}/regimen_sop/${
            params.data.name}/edit', target="_blank"></a>`;
          return eDiv;
        },
      },
      { headerName: 'type', field: 'type' },
      { headerName: 'name', field: 'name' },
      { headerName: 'Class', field: 'class' },
    ];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.filterByTag = [];
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  toggleActiveRegimen(): void {
    this.reset();
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  async loadMore(params: any): Promise<any> {
    const requestPayload: RequestQueryPayload<Table.Tree> = {
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      where: {
        type: 'REGIMEN_SOP',
      },
      include: ['questions'],
    };
    return JSON.parse(JSON.stringify(await ApiConnector.find(Table.Tree, requestPayload)));
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.ui;
  }
}
