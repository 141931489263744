import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MediaLinkComponent } from './media-link.component';
import { ListModule } from './list/index';
import { ViewModule } from './view/index';
import { EditModule } from './edit/index';

const ROUTES: Routes = [
  {
    path: 'medialink',
    component: MediaLinkComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'medialink/view',
    component: MediaLinkComponent,
    loadChildren: (): any => ViewModule,
    pathMatch: 'full',
  },
  {
    path: 'medialink/new',
    component: MediaLinkComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: 'medialink/:id/edit',
    component: MediaLinkComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
];
@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [MediaLinkComponent],
exports: [MediaLinkComponent] })
export class MediaLinkModule {}
