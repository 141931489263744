import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { TimeService } from '../../../../services/time-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { HelperService } from '../../../../services/helper-service';
import { ConsultationSessionInfoPopup } from './consultation-info-popup';

@Component({ selector: 'consultation-list', templateUrl: './consultation-list.html', styleUrls: ['./consultation-list.scss'] })
export class ConsultatioListComponent {
  @Output() updatedConsultationId: EventEmitter<any> = new EventEmitter();
  @Input('user')
  set userFunction(user: any) {
    this.user = user;
    this.fetchUserConsultations();
  }
  user: any;
  webAppUrl: any;
  consultationSessions: Array<any> = [];

  constructor(public appConfig: AppConfig,
    private conn: ConnectionService,
    private time: TimeService,
    private window: WindowRefService,
    private router: Router,
    public helper: HelperService,
    private dialog: MatDialog,
    private dom: DomSanitizer) {
  }

  async ngOnInit(): Promise<any> {
  }

  openConsultationPopup(consultationSession: any): void {
    this.dialog.open(ConsultationSessionInfoPopup, { width: '50%', data: { consultationSession } });
  }

  async fetchUserConsultations(): Promise<any> {
    this.consultationSessions = await ApiConnector.find(Table.ConsultationSession, {
      where: { user: this.user },
      include: ['PrivateRegimenGivenPointer'],
      project: [
        'PrivateMainConcernClass',
        'archive',
        'PrivateMainConcern',
        'PrivateInstantCheckupDebug',
        'PrivateRegimenGiven',
        'username',
      ],
      descending: 'createdAt',
    });
  }

  loadConsultation(id: string): void {
    this.updatedConsultationId.emit(id);
  }
}
