import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../../components/directives.module';
import { DashboadComponent } from './dashboad.component';
import { DashboardFollowupComponent } from './followup-information/followup.component';
import { DashboadLeaveComponent } from './leave-information/leave.component';
import { DashboardOrderCreatedComponent } from './order-created-information/order-created-information.component';
import { DashboadOrderComponent } from './order-information/order.component';
import { DashboardActivityComponent } from './activity-information/activity.component';
import { DashboardTicketComponent } from './tickets-information/ticket-information.component';
import { DashboardCallComponent } from './call-information/call-information.component';
import { StatsComponent } from './stats/stats.component';
import { CardComponent } from './card-component/card.component';
import { MonthlyGrowthComponent } from './monthly-growth/monthly-growth.component';
import { LoginLogoutCalendarComponent } from './login-logout-calendar/login-logout-calendar.component';

const ROUTES: Routes = [
  { path: 'dashboard', component: DashboadComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    DirectivesModule,
    MomentModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatTooltipModule,
  ],
  declarations: [
    DashboadComponent,
    DashboardFollowupComponent,
    DashboadLeaveComponent,
    DashboadOrderComponent,
    DashboardActivityComponent,
    DashboardTicketComponent,
    DashboardCallComponent,
    DashboardOrderCreatedComponent,
    CardComponent,
    StatsComponent,
    MonthlyGrowthComponent,
    LoginLogoutCalendarComponent,
  ],
  exports: [
    DashboadComponent,
    DashboardFollowupComponent,
    DashboadLeaveComponent,
    DashboadOrderComponent,
    DashboardActivityComponent,
    DashboardTicketComponent,
    DashboardCallComponent,
    DashboardOrderCreatedComponent,
    CardComponent,
    StatsComponent,
    MonthlyGrowthComponent,
    LoginLogoutCalendarComponent,
  ],
})
export class DashboadModule {}
