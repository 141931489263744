import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { VoiceRecordingComponent } from './voice-recording.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
  ],
  declarations: [VoiceRecordingComponent],
  exports: [VoiceRecordingComponent],
})
export class VoiceRecordingModule {
}
