import { Injectable } from '@angular/core';

@Injectable()
export class SBARService {
  async checkIfSBARIsFilledCompletely(sbar: any): Promise<boolean> {
    if (sbar.id) {
      await sbar.fetch();
    }
    if (sbar.get('category') !== 'Non_Medical') {
      const requiredFields = ['situation', 'background', 'assessment', 'recommendation', 'category'];
      if (requiredFields.some((each: string) => !sbar.get(each))) {
        return false;
      }
    }
    return true;
  }
}
