import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({ selector: 'main-concern', templateUrl: './mainConcern.html', styleUrls: ['./mainConcern.scss'] })
export class MainConcernComponent {
  @Input('user')
  set userFunction(user: any) {
    if (!user || !user.id) {
      this.user = undefined;
      return;
    }
    this.user = user;
    this.fetchAllMainConcernsForUser();
  }
  moment: any;
  user: any;
  editConcernFlag: boolean;
  mainConcernConsultationSession: any;
  selectedConcernIndex: any;
  mainConcernOptions: Array<string> = [];
  showRefreshDate: boolean;
  mainConcerns: Array<string> = [];

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private broadcaster: Broadcaster) {
    this.moment = moment;
  }

  ngOnInit(): void {
    if (this.moment(this.user.get('refreshDate')).isAfter('2000-01-01', 'year')) {
      this.showRefreshDate = true;
    }
  }

  async fetchAllMainConcernsForUser(): Promise<any> {
    const mainConcerns = await this.conn.fetchAllMainConcernsForUser(this.user.get('username'));
    this.mainConcernConsultationSession = mainConcerns;
    this.mainConcerns = mainConcerns.map((mainConcern: any) => mainConcern.get('PrivateMainConcern'));
    this.mainConcernOptions = await this.conn.findMainConcernsOptions();
  }

  async saveSelectedMainConcern(): Promise<void> {
    const selectedConcern: any = this.mainConcernOptions[this.selectedConcernIndex];
    this.user.set('PrivateMainConcern', selectedConcern.get('value'));
    await this.user.save();
    this.editConcernFlag = false;
    await this.fetchAllMainConcernsForUser();
  }
}
