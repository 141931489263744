import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { ChangeTicketAllocationComponent } from './change-ticket-allocation.component';
import { InputInternalUserSelectModule } from '../input/input-internal-user-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    InputInternalUserSelectModule,
    MomentModule,
    RouterModule,
  ],
  declarations: [ChangeTicketAllocationComponent],
  exports: [ChangeTicketAllocationComponent],
})
export class ChangeTicketAllocationModule {
}
