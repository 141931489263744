import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ConnectionService } from '../../../../../../services/connection-service';

@Component({ selector: 'add-question-modal', templateUrl: './add-question.html' })
export class AddQuestionModal {
  autoCompleteController: UntypedFormControl = new UntypedFormControl();
  options: Observable<Array<{ name: string; object: any }>>;
  image: string = '';
  excludeIds: Array<string>;

  constructor(private conn: ConnectionService, private dialogRef: MatDialogRef<AddQuestionModal>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.autoCompleteController.setValue('');
    this.excludeIds = this.data.excludeIds;
    this.options = this.autoCompleteController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getQuestions(token)));
  }

  async getQuestions(name: string): Promise<any> {
    return this.conn.getQuestions({ q: name, limit: 10, excludeIds: this.excludeIds })
      .then((questions: any) => questions
        .map((object: any) => ({ name: `${object.get('uniqueIdentifier')}: ${object.get('title')}`, object })));
  }

  hideModal(data: any): void {
    this.dialogRef.close(data);
  }

  autoCompleteOnSelect(item: { name: string, object: any }): void {
    this.hideModal(item.object);
  }
}
