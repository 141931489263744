
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/tree/questions">Tree</a></li>
          <li>{{ indicationTreeObj.id ? 'Edit' : 'New' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content col-xs-12 p-0" #scrollContainer>
      <form class="col-xs-12 p-4" #treeForm="ngForm" (submit)="saveTree()">
        <div class="form-group col-xs-12">
          <div class="col-xs-12 flex mt-0">
            <label class="col-xs-4 col-md-2 mt-3">Name</label>
            <div class="col-xs-7 col-md-5">
              <input class="form-control" [(ngModel)]="tree.name" name="name" placeholder="" required="required" pattern="[A-Za-z]*" [disabled]="indicationTreeObj.id"/>
            </div>
          </div>
          <div class="col-xs-12 flex mt-3">
            <label class="col-xs-4 col-md-2">Class</label>
            <div class="col-xs-7 col-md-5">
              <select class="form-control mt-1" [(ngModel)]="tree.class" name="treeClass" required="required">
                <option *ngFor="let className of treeClasses;" value="{{className}}">{{className}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12 mt-3 flex">
            <label class="col-xs-4 col-md-2">Tables</label>
            <div class="col-xs-7 col-md-5">
              <select class="form-control mt-1" (change)="autoCompleteOnSelect($event)">
                <option selected="selected" hidden="hidden">Choose a Table</option>
                <option *ngFor="let option of treeTablesOptions" [value]="option.value">{{option.display}}</option>
              </select>
              <div class="col-xs-12 mt-2">
                <div class="badge mr-2 mb-2" *ngFor="let team of tree.treeTables; let i = index;">{{team}}<i class="fa fa-trash-o ml-2" (click)="removeItem(i)"></i></div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 mt-3 flex">
            <label class="col-xs-2 mr-3">Active</label>
            <label class="i-switch i-switch-sm bg-success">
              <input type="checkbox" [(ngModel)]="tree.active" (ngModelChange)="toggleTreeStatus()" name="active"/><i></i>
            </label>
          </div>
          <div class="col-xs-12" #treeBoard>
            <div class="col-xs-12" #treeBoardHead>
              <div class="col-xs-12">
                <label class="col-xs-4 col-md-2">Questions</label>
                <div class="col-xs-8 col-md-10 flex mt-3">
                  <button class="btn-xs mr-2 mb-sm-2" type="button" (click)="startTreeCompress()">Compress Tree</button>
                  <button class="btn-xs mr-2 mb-sm-2" type="button" (click)="startTreeDecompress()">De-Compress Tree</button>
                  <button class="btn-xs mr-2 mb-sm-2" type="button" (click)="generateMergeNodeArray()">Merge Nodes</button>
                  <button class="btn-xs mr-2 mb-sm-2" type="button" (click)="validateQuestions()">Update Question Responses</button>
                </div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-4 col-md-2">Edit features</label>
                <div class="col-xs-8 col-md-10 flex mt-3">
                  <button class="btn-info btn-dark-blue btn-xxs mr-2 flex mb-sm-2" type="button"><span>Insert Node In between</span>
                    <label class="i-switch i-switch-sm bg-success dker ml-2 my-0 flex">
                      <input type="checkbox" (change)="forceUpdateLine()" [(ngModel)]="insertNode" name="insertNode"/><i></i>
                    </label>
                  </button>
                  <button class="btn-info btn-dark-blue btn-xxs mr-2 flex mb-sm-2" type="button"><span>Replace Question</span>
                    <label class="i-switch i-switch-sm bg-success dker ml-2 my-0 flex">
                      <input type="checkbox" (change)="forceUpdateLine()" [(ngModel)]="enableReplaceQuestion" name="enableReplaceQuestion"/><i></i>
                    </label>
                  </button>
                  <button class="btn-info btn-dark-blue btn-xxs mr-2 flex mb-sm-2" type="button"><span>Re Arrange Option Order</span>
                    <label class="i-switch i-switch-sm bg-success dker ml-2 my-0 flex">
                      <input type="checkbox" (change)="forceUpdateLine()" [(ngModel)]="enableReArrangeOption" name="enableReArrangeOption"/><i></i>
                    </label>
                  </button>
                  <button class="btn-info btn-dark-blue btn-xxs mr-2 flex mb-sm-2" type="button"><span>Multi-Regimen {{tree.multiRegimen}}</span>
                    <label class="i-switch i-switch-sm bg-success dker ml-2 my-0 flex">
                      <input type="checkbox" [(ngModel)]="tree.multiRegimen" name="enableMultiRegimen"/><i></i>
                    </label>
                  </button><span class="fa fa-refresh ml-2" (click)="$event.preventDefault();forceUpdateRegimenQuestionMap();"></span>
                </div>
              </div>
            </div>
            <div class="col-xs-12" *ngFor="let regimenQuestion of regimenQuestionMap;let i = index;">
              <div class="col-xs-12 flex mt-3" *ngIf="!(mergeNodeToHideMapping[i] && mergeNodeToHideMapping[i].hide)">
                <div class="col-xs-4 col-md-2">
                  <div class="col-xs-1 mr-2 bold">{{i}}</div>
                  <div class="col-xs-3 mr-2">
                    <div class="col-xs-12" *ngIf="tree.type !== apiClientConstant.Tree.Type.TREATMENT_DELIVERY && !regimenQuestion.toRow && !regimenQuestion.isTreeJump && !regimenQuestion.isEndNode && regimenQuestion.questions.length"><i class="fa fa-rupee text-success"></i>
                      <label class="i-switch i-switch-sm bg-success dker ml-2 my-0">
                        <input type="checkbox" [(ngModel)]="regimenQuestion.isConsultation" name="is_consultation_{{i}}"/><i></i>
                      </label>
                    </div>
                    <div class="col-xs-12 flex" *ngIf="tree.type !== apiClientConstant.Tree.Type.TREATMENT_DELIVERY && !regimenQuestion.toRow && !regimenQuestion.isTreeJump && !regimenQuestion.isConsultation && regimenQuestion.questions.length"><i class="fa fa-ban text-danger"></i>
                      <label class="i-switch i-switch-sm bg-success dker ml-2 my-0">
                        <input type="checkbox" [(ngModel)]="regimenQuestion.isEndNode" name="is_consultation_{{i}}"/><i></i>
                      </label>
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <multi-regimen-selection [regimenIds]="regimenQuestion.regimens" [type]="[apiClientConstant.Regimen.Type.MAIN]" [required]="true" [filter]="{ class: tree.class }" (onValueChange)="updateMultiRegimens(regimenQuestion, $event)" *ngIf="tree.multiRegimen && !regimenQuestion.toRow && !regimenQuestion.isConsultation && !regimenQuestion.isTreeJump && !regimenQuestion.isEndNode && regimenQuestion.questions.length"></multi-regimen-selection>
                    <regimen-selection [regimenId]="regimenQuestion.regimen" [type]="[apiClientConstant.Regimen.Type.MAIN]" [filter]="{ class: tree.class }" [required]="true" (onValueChange)="regimenQuestion.regimen = $event?.get('regimenId')" *ngIf="!tree.multiRegimen && !regimenQuestion.toRow && !regimenQuestion.isConsultation && !regimenQuestion.isTreeJump && !regimenQuestion.isEndNode && regimenQuestion.questions.length"></regimen-selection>
                  </div>
                </div>
                <div class="col-xs-8 col-md-10">
                  <div class="dib" *ngFor="let question of regimenQuestion.questions;let y = index;"><span class="fa fa-plus-square-o mx-2" *ngIf="insertNode" (click)="addQuestionBeforeNode(i, y)"></span><span *ngIf="mergeNodeToHideMapping[i] && mergeNodeToHideMapping[i][y]">
                      <div class="badge bg-info clr-white m-2 dnd_{{i}}_{{y}}"><span title="{{question.title}}">{{question.uniqueIdentifier}}</span><span title="{{mergeNodeToHideMapping[i][y].answer}}">( Any Answer )</span><span class="fa fa-expand ml-1" (click)="expandNode(i, y)"></span></div></span><span *ngIf="!(mergeNodeToHideMapping[i] && mergeNodeToHideMapping[i][y])">
                      <div class="badge m-2 dnd_{{i}}_{{y}}" title="{{question.title}}" (drop)="dragDrop($event, i, y)" (dragover)="allowDrop($event)" [ngClass]="{&quot;bg-yellow&quot;: question.defaultAnswer }">{{question.uniqueIdentifier}} {{question.answer ? ':' : ''}} {{question.answer}}<span class="fa fa-retweet ml-1" *ngIf="enableReplaceQuestion" (click)="replaceQuestion(i, y)"></span><span class="fa fa-sort ml-1" *ngIf="enableReArrangeOption" (click)="reArrangeOptions(i, y)"></span><span class="fa fa-times ml-3" (click)="removeQuestionWhileKeepingOnePartOfTree(i, y)"></span><span class="fa fa-edit ml-3" *ngIf="question.defaultAnswer" (click)="editDefaultAnswer(i,y)"></span></div></span></div>
                  <div class="dib inline-flex"><i class="fa fa-plus-square-o m-2" *ngIf="!regimenQuestion.toRow" (click)="searchQuestion(i, AddQuestionType.END_NODE)"></i><i class="fa m-2 fa-trash-o" *ngIf="regimenQuestion.toRow" (click)="removeDropJump(regimenQuestion, i)"></i><i class="fa fa-map-marker m-2" *ngIf="tree.type !== apiClientConstant.Tree.Type.TREATMENT_DELIVERY && !regimenQuestion.toRow && !regimenQuestion.isTreeJump && !regimenQuestion.isEndNode && !regimenQuestion.isConsultation && regimenQuestion.questions.length" draggable="true" (dragstart)="dragStart($event, i)"></i>
                    <div class="flex rounded" [ngClass]="{&quot;bg-success&quot;: regimenQuestion.isTreeJump}" *ngIf="!regimenQuestion.toRow && !regimenQuestion.isEndNode && !regimenQuestion.isConsultation && regimenQuestion.questions.length">
                      <select class="form-control input-sm" style="max-width:200px; padding: 0px !important" [(ngModel)]="regimenQuestion.jumpToTree" required="required" name="jump_to_tree_{{i}}" *ngIf="regimenQuestion.isTreeJump">
                        <option *ngFor="let item of trees" value="{{item.get('name')}}:">{{item.get("name")}}</option>
                      </select>
                      <label class="i-switch i-switch-sm bg-success dker mx-2 my-0">
                        <input type="checkbox" [(ngModel)]="regimenQuestion.isTreeJump" name="is_tree_jump_{{i}}" *ngIf="!regimenQuestion.isEndNode"/><i></i>
                      </label><i class="fa fa-plane m-2"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 bg-black my-2" style="height:1px" *ngIf="!(mergeNodeToHideMapping[i] && mergeNodeToHideMapping[i].hide) && i < regimenQuestionMap.length - 1"></div>
            </div>
            <arrow-line *ngFor="let line of treeLines;" [scrollY]="scrollContainer.scrollTop" [errorY]="treeTopOffset" [to]="treeBoard.getElementsByClassName('dnd_' + line.toRow + '_' + line.toCol)[0]" [from]="treeBoard.getElementsByClassName('dnd_' + line.fromRow + '_' + (regimenQuestionMap[line.fromRow].questions.length - 1))[0]" [width]="treeBoard.offsetWidth" [height]="treeBoard.offsetHeight + treeBoardHead.offsetHeight"></arrow-line>
          </div>
          <div class="col-xs-12 mt-3">
            <button class="btn-xs" type="submit" [disabled]="!treeForm.valid">{{ indicationTreeObj.id ? 'Update' : 'Create' }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
