
<mat-dialog-content class="mat-dialog-content">
  <form role="form" name="messageNoteForm" #imageUploadForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ data.title }}</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
    </div>
    <div class="modal-body">
      <input class="w100" type="file" (change)="file = $event.target.files[0]" required="required" placeholder="{{ data.title }}" accept="image/jpeg,application/pdf"/>
    </div>
    <div class="modal-footer">
      <button class="pull-right" type="button" name="submit" (click)="uploadToAws()" [disabled]="!imageUploadForm.valid">Send</button>
    </div>
  </form>
</mat-dialog-content>