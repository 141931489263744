
<mat-dialog-content class="new-mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <div class="col-xs-12">
        <input class="form-control product-select" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_product" [value]="productTitle" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
        <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete" [(ngModel)]="productTitle">
          <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option)">{{option.name}}</mat-option>
        </mat-autocomplete>
      </div>
    </h4>
  </div>
  <div class="modal-body mt-0">
    <div class="instruction" *ngFor="let instruction of instructions; let i = index;" [ngClass]="{'selectedInstruction': instruction.creationId == selectedInstructionCreationId}" (click)="changeInstruction(i)">
      <div class="radio-button"><i class="fa-regular fa-circle-dot"></i></div>
      <div>
        <div class="title">{{instruction.title}}</div>
        <div class="instruction-details"><span>{{instruction.quantity}} {{instruction.quantityUnitLanguageString?.get('en')}} &nbsp;</span><span>| {{instruction.areaOfApplicationLanguageString?.get('en')}} &nbsp;</span><span>| {{instruction.frequencyOfApplicationLanguageString?.get('en')}} &nbsp;</span><span>| {{instruction.durationOfApplicationLanguageString?.get('en')}} &nbsp;</span></div>
      </div>
    </div>
  </div>
</mat-dialog-content>