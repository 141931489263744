<mat-dialog-content>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Doctor</h4><i class="fas fa-times pull-right fontL3" (click)="closeModal()"></i>
    </div>
    <div>
        <label class="w100">Select Doctor </label>
        <mat-form-field>
            <mat-select class="w100" [(ngModel)]="selectedDoctorId">
               <mat-option *ngFor="let doctor of doctorsList" [value]="doctor.objectId">{{doctor.DoctorDisplayName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <p>Selected Start Time: {{selectedStartTime}}</p>
            <p>Selected End Time: {{selectedEndTime}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn-xs pull-right" (click)="addDoctor()">Save</button>
    </div>

</mat-dialog-content>