import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({ selector: 'date-selector', templateUrl: './date-selector.html' })
export class DateSelectorComponent {
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() set value(date: Date) {
    this.inputValue = date;
  }
  @Input() editClass: boolean = false;
  inputValue: Date;
  open: boolean = false;
  search: string = '';
  @Output() emitter: EventEmitter<any> = new EventEmitter();

  static parameters: any = [];
  constructor() {
  }

  ngOnInit(): void {
  }

  toggle(): void {
    this.open = !this.open;
  }

  onValueChange(date: Date): void {
    this.valueChange.emit(date);
  }
}
