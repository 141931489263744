
<div class="hbox">
  <loading class="posF vh-center" *ngIf="loading" [scale]="1.5"></loading>
  <div class="flex-box" *ngIf="!loading">
    <div class="flex-heading"></div>
    <div class="flex-content" *ngIf="currentInstantCheckup" [ngClass]="{'fontS3': useAsSubComponent}">
      <div class="col-xs-12 h100 p-0">
        <div class="imageViewer" #userZoomedImage></div>
        <div class="photoView" [ngClass]="showList ? 'col-xs-8' : 'col-xs-12'" [ngStyle]="{ &quot;height&quot;: useAsSubComponent ? &quot;400px&quot;: &quot;&quot; }">
          <div class="col-xs-12 text-center" *ngIf="showList">
            <div class="col-xs-3 justify-content-start" (click)="navigate(-1)"><em class="fas fa-arrow-circle-left pr-3"></em><span class="bold">Previous Photo</span></div>
            <div class="col-xs-6 justify-content-center"><span class="bold">{{currentInstantCheckup?.type}}</span><span class="pl-4">{{ currentInstantCheckup?.createdAt | amDateFormat: "DD, MMM, YYYY hh:mm a" }}</span></div>
            <div class="col-xs-3 justify-content-end" (click)="navigate(1)"><span class="bold">Next Photo</span><em class="fas fa-arrow-circle-right pl-3"></em></div>
          </div>
          <div class="col-xs-12">
            <div class="col-xs-3 filter-option p-3 textC" *ngIf="showList">
              <label class="mt-0">Tags</label>
              <button class="mt-2 bdr-rad-5 w100 db" *ngFor="let tag of tags" [disabled]="(tag == 'GOOD' && currentInstantCheckup.tags?.includes('BAD')) || (tag == 'BAD' && currentInstantCheckup.tags?.includes('GOOD'))" [ngClass]="currentInstantCheckup.tags?.includes(tag) ? 'btn-primary' : 'btn-primary-outline'" (click)="addTag(currentInstantCheckup, tag, $event)">{{tag}}</button>
            </div>
            <div class="col-xs-9" *ngIf="!useAsSubComponent">
              <instant-checkup-view class="w100" [instantCheckupId]="currentInstantCheckup?.objectId" [username]="username" [hideFullImage]="hideFullImage" [useAsSubComponent]="useAsSubComponent" (deleted)="instantCheckupDeleted()"></instant-checkup-view>
            </div>
            <div class="col-xs-9" *ngIf="useAsSubComponent">
              <div class="col-xs-12" *ngIf="currentInstantCheckup.imagePath">
                <lib-ngx-image-zoom class="col-xs-12" #imageLense [thumbImage]="currentInstantCheckup.imagePath" [fullImage]="currentInstantCheckup.imagePath" [magnification]="0" [enableScrollZoom]="true" [enableLens]="true" [lensHeight]="600" [lensWidth]="400"></lib-ngx-image-zoom>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-4 col-md-4 photoList bg-light" *ngIf="showList">
          <div class="bold pb-2 fontL1"><span>CHECKUP LIST</span>
            <button class="btn-secondary pull-right small" *ngIf="username && !useAsSubComponent"><a href="/chat/{{username}}" target="_blank">View Chat</a></button>
          </div>
          <div class="pb-4"><span class="dropdown pl-1">
              <div class="dropdown-toggle inline-flex"><em class="fas fa-filter"></em><span class="badge bg-secondary fontS3 ml-1 clr-white">{{filter.TYPE}}</span></div>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a class="dropdown-item" *ngFor="let item of photoTypes" (click)="filterPhotoType(item)">{{item}}</a></li>
              </ul></span><span class="dropdown pl-3">
              <div class="dropdown-toggle inline-flex"><em class="fas fa-tags"></em><span class="badge bg-secondary fontS3 ml-1 clr-white">{{filter.TAG}}</span></div>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="min-width:60px;">
                <li><a class="dropdown-item" *ngFor="let tag of tagsInFilter" (click)="filterTag(tag)">{{tag}}</a></li>
              </ul></span></div>
          <div class="viewComparison card" *ngIf="selectedComparisonIdsLength > 0" (click)="openComparison()" [ngClass]="{ 'disabled': selectedComparisonIdsLength === 1 }">View Comparison</div>
          <div class="resetComparison card" *ngIf="selectedComparisonIdsLength > 0" (click)="resetComparison()">Reset Comparison</div>
          <div class="h100 of-auto">
            <div class="text-center py-5" *ngIf="!list.length">No Checkups found.</div>
            <div class="items" *ngFor="let item of list; let i = index" [ngClass]="{ 'selectedForComp': selectedComparisonIds[item.objectId], 'active': item.objectId === currentInstantCheckup?.objectId }" (click)="changeImage(item.objectId)"><img [src]="item.thumbnailImagePath || item.imagePath"/>
              <div class="flex-grow p-3">
                <div><span class="bold underline" (click)="openInNewTab(i, $event)">{{item.type }}</span><span class="ml-3" *ngIf="item.consultationSessionImage"><i class="fas text-danger fa-star-of-life"></i></span><span class="pl-3">{{item.createdAt | amDateFormat: "DD, MMM, YYYY hh:mm a" }}</span></div>
                <div class="actions"><span class="comparison clr-link" (click)="addOrRemoveFromComparison(i, $event)">
                    <ng-container *ngIf="!selectedComparisonIds[item.objectId]"><em class="fas fa-plus mb-1"></em><span class="pl-2">ADD</span></ng-container>
                    <ng-container *ngIf="selectedComparisonIds[item.objectId]"><em class="fas fa-minus mb-1"></em><span class="pl-2">REMOVE</span></ng-container></span><span class="pl-3"><em class="far fa-trash-alt" (click)="stopPropagation($event)" (dblclick)="deleteUserInstantCheckup(item, $event)"></em></span></div>
              </div>
            </div>
            <button *ngIf="!endOfList" (click)="loadMoreImages()">Load more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
