import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './logout.component';

const ROUTES: Routes = [
  { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
];

@NgModule({ imports: [
  BrowserModule,
  RouterModule.forChild(ROUTES),
],
declarations: [LogoutComponent],
exports: [LogoutComponent] })
export class LogoutModule {}
