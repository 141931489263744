

<div class="header">
    <div class="timings-header">
        <p>Timings</p>
    </div>
    <div class="days">
        <div class="day" *ngFor="let day of days">
          <p>{{ day }}</p>
        </div>
    </div>
</div>

<div class="main-container">
    <div class="time-line-parent">
        <div class="time-line-div" *ngFor="let data of doctorsScheduleWithDays.sunday">
            <div class="time-line">
                <span>{{ convertMinutesToHours(data.start) }}</span> - <span>{{ convertMinutesToHours(data.end) }}</span>
            </div>
        </div>
    </div>
    <div class="total-data">
        <div class="dayData">
            <div class="hourData" *ngFor="let data of doctorsScheduleWithDays.sunday; let i = index">
                <div>
                    <div *ngFor="let doctorId of data.doctorIds">
                        <p class="badge badge-tag">
                            <span> {{ getDoctorNameFromObjectId(doctorId) }} </span>  
                            <i class="fa fa-trash-o pl-2 pointer" (click)="removeDoctor(i,doctorId,'saturday')"></i>
                        </p>
                    </div>
                </div>
                <i class="fa fa-plus-square-o pointer" (click)="addDoctorToSchedule(data, 'sunday')"></i>
            </div>
        </div>
        <div class="dayData">
            <div class="hourData" *ngFor="let data of doctorsScheduleWithDays.monday; let i = index">
                <div>
                    <div *ngFor="let doctorId of data.doctorIds">
                        <p class="badge badge-tag">{{ getDoctorNameFromObjectId(doctorId) }} <span><i class="fa fa-trash-o pl-2 pointer" (click)="removeDoctor(i,doctorId,'monday')"></i></span> </p>
                    </div>
                </div>
                <i class="fa fa-plus-square-o pointer" (click)="addDoctorToSchedule(data, 'monday')"></i>
            </div>
        </div>
        <div class="dayData">
            <div class="hourData" *ngFor="let data of doctorsScheduleWithDays.tuesday; let i = index">
                <div>
                    <div *ngFor="let doctorId of data.doctorIds">
                        <p class="badge badge-tags">{{ getDoctorNameFromObjectId(doctorId) }} <span><i class="fa fa-trash-o pl-2 pointer" (click)="removeDoctor(i,doctorId,'tuesday')"></i></span> </p>
                    </div>
                </div>
                <i class="fa fa-plus-square-o pointer" (click)="addDoctorToSchedule(data, 'tuesday')"></i>
            </div>
        </div>
        <div class="dayData">
            <div class="hourData" *ngFor="let data of doctorsScheduleWithDays.wednesday; let i = index">
                <div>
                    <div *ngFor="let doctorId of data.doctorIds">
                        <p class="badge badge-tags">{{ getDoctorNameFromObjectId(doctorId) }} <span><i class="fa fa-trash-o pl-2 pointer" (click)="removeDoctor(i,doctorId,'wednesday')"></i></span> </p>
                    </div>
                </div>
                <i class="fa fa-plus-square-o pointer" (click)="addDoctorToSchedule(data, 'wednesday')"></i>
            </div>
        </div>
        <div class="dayData">
            <div class="hourData" *ngFor="let data of doctorsScheduleWithDays.thursday; let i = index">
                <div>
                    <div *ngFor="let doctorId of data.doctorIds">
                        <p class="badge badge-tag">{{ getDoctorNameFromObjectId(doctorId) }} <span><i class="fa fa-trash-o pl-2 pointer" (click)="removeDoctor(i,doctorId,'thursday')"></i></span> </p>
                    </div>
                </div>
                <i class="fa fa-plus-square-o pointer" (click)="addDoctorToSchedule(data, 'thursday')"></i>
            </div>
        </div>
        <div class="dayData">
            <div class="hourData" *ngFor="let data of doctorsScheduleWithDays.friday; let i = index">
                <div>
                    <div *ngFor="let doctorId of data.doctorIds">
                        <p class="badge badge-tag">{{ getDoctorNameFromObjectId(doctorId) }} <span><i class="fa fa-trash-o pl-2 pointer" (click)="removeDoctor(i,doctorId,'friday')"></i></span> </p>
                    </div>
                </div>
                <i class="fa fa-plus-square-o pointer" (click)="addDoctorToSchedule(data, 'friday')"></i>
            </div>
        </div>
        <div class="dayData">
            <div class="hourData" *ngFor="let data of doctorsScheduleWithDays.saturday; let i = index">
                <div>
                    <div *ngFor="let doctorId of data.doctorIds">
                        <p class="badge badge-tag">
                            <span> {{ getDoctorNameFromObjectId(doctorId) }} </span>  
                            <i class="fa fa-trash-o pl-2 pointer" (click)="removeDoctor(i,doctorId,'saturday')"></i>
                        </p>
                    </div>
                </div>
                <i class="fa fa-plus-square-o pointer" (click)="addDoctorToSchedule(data, 'saturday')"></i>
            </div>
        </div>
    </div>
</div>
<div class="update-btn">
    <button (click)="handleUpdate()">Update</button>
</div>