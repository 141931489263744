import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputSingleImageUploadComponent } from './input-single-image-upload.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [InputSingleImageUploadComponent],
  exports: [InputSingleImageUploadComponent],
})
export class InputSingleImageUploadModule {
}
