import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { Subscription } from 'rxjs';
import { AddPaymentModal } from 'src/app/order/view/add-payment';
import { ConnectionService } from 'src/services/connection-service';
import { WindowRefService } from 'src/services/window-ref-service';

@Component({
  selector: 'sales-payments',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sales-payments.component.html',
  styleUrl: './sales-payments.component.scss',
})
export class SalesPaymentsComponent {
  subscriptions: Array<Subscription> = [];
  @Input('orderObj') orderObj: any;
  @Input('parseOrderObj') parseOrderObj: any;
  transferPaymentAllowed: boolean;
  payments: any = [];

  constructor(private dialog: MatDialog,
    private conn: ConnectionService,
    private window: WindowRefService,
  ) { }

  async ngOnChanges(): Promise<void> {
    if (!this.parseOrderObj?.id) return;
    this.payments = await this.conn.getOrderPayments({ order: this.parseOrderObj });
    this.transferPaymentAllowed = [
      ApiClientConstant.Order.Stage.REFUND_REQUESTED,
      ApiClientConstant.Order.Stage.STUCK_IN_TRANSIT,
      ApiClientConstant.Order.Stage.RETURN_INITIATED,
      ApiClientConstant.Order.Stage.RETURNED,
      ApiClientConstant.Order.Stage.LOST_IN_TRANSIT,
      ApiClientConstant.Order.Stage.CANCELED,
    ].includes(this.parseOrderObj.get('stage'));
  }

  addPayment(payment: any): void {
    if (!payment) return;
    this.payments.unshift(payment);
  }

  async movePayment(): Promise<void> {
    const unpaidOrders = await this.conn.getOrders({
      where: { stage: [ApiClientConstant.Order.Stage.ONLINE_PAYMENT_PENDING], user: this.orderObj.get('user') },
      include: ['payment'],
    });
    const orderId = this.window.nativeWindow.document.getElementById('transferPayment').value;
    const order = unpaidOrders.find((each: any) => each.id === orderId);
    if (!order || !orderId) return alert('no order found');
    const [payment]: any = await this.conn.getOrderPayments({ order: this.parseOrderObj });
    if (!payment) return alert('no payment found');
    const notes = payment.get('notes') ? `${payment.get('notes')}. ` : '';
    payment.set('order', order);
    payment.set('notes', `${notes} Payment transferred from order ${this.parseOrderObj.id}`);
    try {
      await payment.save();
      alert('Payment transferred successfully.');
    } catch (err) {
      alert(err.toString());
    }
    return Promise.resolve();
  }

  openAddPaymentModal(): void {
    const dialogRef = this.dialog.open(AddPaymentModal, { data: { order: this.orderObj } });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result: any) => {
      this.addPayment(result);
      this.window.nativeWindow.location.reload();
    }));
  }
}
