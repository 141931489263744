import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'SBAR-history-model',
  templateUrl: './sbar-history.html',
})
export class SbarHistoryComponent {
  user: any;
  supportTicket: any;
  sbarHistory: Array<any>;
  constructor(
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private injector: Injector,
    private dialogRef: MatDialogRef<SbarHistoryComponent>) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.supportTicket = data.supportTicket;
  }

  async ngOnInit(): Promise<void> {
    this.sbarHistory = await this.conn.findSbarHistory({
      where: {
        supportTicket: this.supportTicket,
      },
      include: ['createdBy'],
      project: ['situation', 'background', 'assessment', 'recommendation', 'category', 'createdBy.username' as 'createdBy'],
      descending: 'createdAt',
    });
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
