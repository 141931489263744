import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestQueryPayload, Table } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { Broadcaster } from '../../../../components/broadcaster';
import { LocalStorage } from '../../../../services/local-storage-service';
import { FollowUpDialog } from '../follow-up';

@Component({ selector: 'followup-section', templateUrl: './followup-section.html', styleUrls: ['./followup-section.scss'] })
export class FollowupSectionComponent {
  // inputs
  @Input() patient: any;

  // variables
  internalUser: any;
  internalUserRoles: Array<string>;
  followUp: any;
  attendToChatCache: any;
  SelectedPopUpType: any;
  constructor(private connectionService: ConnectionService,
    public appConfig: AppConfig,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private storage: LocalStorage,
    private broadcaster: Broadcaster) {
  }

  async ngOnInit(): Promise<void> {
    this.internalUser = this.connectionService.getCurrentUser();
    this.internalUserRoles = this.storage.getJsonValue('userRoles');
    await Promise.all([
      this.loadFollowUp(),
      this.loadAttendToChatCache(),
    ]);
  }

  async loadFollowUp(): Promise<void> {
    const requestPayload: RequestQueryPayload<Table.FollowUp> = {
      where: {
        user: this.patient,
        State: [
          this.appConfig.Shared.FollowUp.State.PENDING,
          this.appConfig.Shared.FollowUp.State.WAITING_FOR_IMAGE,
        ],
      },
      project: [
        'State',
        'followUpMessage',
        'followUpReason',
        'nextFollowUpDate',
        'firstFollowup',
        'regimens.activeFromDate' as 'regimens',
        'regimens.concernsLanguageString' as 'regimens',
        'effectiveFollowUpDate',
        'nextFollowUpDate',
        'markedReadyOn',
        'followUpCount',
        'ready',
        'products',
        'instructionCallNeeded',
        'allocatedDoctor.username' as 'allocatedDoctor',
        'doctorCallNeeded',
      ],
      include: ['allocatedDoctor', 'regimens'],
      descending: 'createdAt',
      limit: 1,
    };
    [this.followUp] = await this.connectionService.findFollowUps(requestPayload);
  }
  async loadAttendToChatCache(): Promise<void> {
    this.attendToChatCache = await this.connectionService.findAttendToChatCache(this.patient);
  }

  async removeFollowUpProduct(followUp: any, index: number): Promise<void> {
    const products: Array<any> = followUp.get('products');
    products.splice(index, 1);
    await followUp.save({ products });
  }

  async toggleReadyFlag(): Promise<void> {
    this.followUp.set('ready', !this.followUp.get('ready'));
    await this.followUp.save();
  }

  openFollowUpModal(type: string): void {
    this.SelectedPopUpType = type;
    const dialogRef = this.dialog.open(FollowUpDialog, {
      data: {
        SelectedPopUpType: this.SelectedPopUpType,
        followUp: this.followUp,
        userId: this.patient.get('username'),
        chatUser: this.patient,
      },
    });
    dialogRef.afterClosed().subscribe((updateStatus: boolean) => this.loadFollowUp());
  }

  async createImmediateDoctorFollowup(): Promise<any> {
    try {
      await this.connectionService.createFollowUp(this.patient.id);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async deleteFollowup(): Promise<void> {
    if (this.internalUserRoles.includes('admin') || this.internalUserRoles.includes('adminDoctor')) {
      try {
        await this.followUp.destroy();
        this.broadcaster.broadcast('NOTIFY', { message: 'Successfully Updated', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
      return;
    }
    this.broadcaster.broadcast('NOTIFY', { message: 'Permission Denied', type: this.appConfig.Shared.Toast.Type.ERROR });
  }
}
