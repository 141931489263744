
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a *ngIf="pageTitle == 'Internal Users'" routerLink="/users/internal-users">Internal Users</a><a *ngIf="pageTitle == 'doctor'" routerLink="/users/doctors">Doctor</a></li>
          <li *ngIf="userObj?.id">Edit</li>
          <li *ngIf="!userObj?.id">New</li>
        </ul>
      </div>
    </div>
    <div class="flex-content p-4" *ngIf="!loading">
      <form class="col-xs-12" #form="ngForm">
        <div class="col-xs-12 col-md-6">
          <div class="col-xs-12">
            <label class="mt-0">UserName</label>
            <input-text [parseObj]="userObj" name="username" [pattern]="'^[a-zA-Z0-9_]*$'" [required]="true" [placeholder]="'only character, digit and underscore is valid'" [disabled]="userObj?.id"></input-text>
          </div>
          <div class="col-xs-12">
            <label>Password</label>
            <input-text [parseObj]="userObj" name="password" [required]="!(userObj && userObj.id)" [placeholder]="'password'"></input-text>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Registered Mobile Number</label>
            <input class="col-xs-12" type="text" name="mobileNumber" [(ngModel)]="registeredMobileNumber" placeholder="Exotel Registered Number" (keyup)="checkNumberValidity($event)" required="required" maxlength="10" minlength="10"/>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Caller Id</label>
            <input class="col-xs-12" type="text" name="callerId" [(ngModel)]="callerId" placeholder="Exotel Caller Id" (keyup)="checkCallerIdValidity($event)" required="required" maxlength="11" minlenght="11"/>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Medical Counsil Reg Number</label>
            <div class="col-xs-10">
              <input-text [parseObj]="userObj" name="mciNumber" placeholder="Medical Counsil Reg Number"></input-text>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-2">Gender</label>
            <input-select [parseObj]="userObj" name="Gender" [options]="genderType" required="true"></input-select>
          </div>
          <div class="col-xs-12">
            <label>Email</label>
            <input-text [parseObj]="userObj" name="PatientEmail" [placeholder]="'Email'" type="email" required="true"></input-text>
          </div>
          <div class="col-xs-12">
            <label>Type</label>
            <input-select [parseObj]="userObj" [defaultSelect]="userType" name="type" required="true" [options]="userTypes" [placeholder]="'Select Type'"></input-select>
          </div>
          <div class="col-xs-12" *ngIf="(userObj && userObj.get('type') === 'doctor')">
            <label>Doctor Name</label>
            <input-text [parseObj]="userObj" name="DoctorDisplayName" [placeholder]="'Display Name'" required="true"></input-text>
            <label>Allocation Factor</label>
            <input-text [parseObj]="userObj" name="doctorAllocationFactor" [placeholder]="'Doctor Allocation (0 , 0.5, 1, 2)'" type="number" [disabled]="(!currentUserRoles.includes('adminDoctor'))"></input-text>
          </div>
          <div class="col-xs-12" *ngIf="(userObj && userObj.get('type') === 'operator')">
            <label>Allocation Factor</label>
            <input-text [parseObj]="userObj" name="operatorAllocationFactor" [placeholder]="'Operator Allocation (0 , 0.5, 1, 2)'" type="number" [disabled]="(!currentUserRoles.includes('adminOperator'))"></input-text>
          </div>
          <div class="col-xs-12 mb-3 p-0">
            <label class="mb-2">Service Start Date</label>
            <date-selector class="col-xs-12 mb-2" [(value)]="serviceStartDate"></date-selector>
          </div>
          <div class="col-xs-12 flex mt-2">
            <label class="col-xs-3"> Inactive</label><span>
              <input-checkbox class="bg-danger" [parseObj]="userObj" name="inactive" [disabled]="(userObj && userObj.get('type') === apiClientConstant.User.Type.OPERATOR && (!currentUserRoles.includes('adminOperator'))) || (userObj && userObj.get('type') === apiClientConstant.User.Type.DOCTOR && (!currentUserRoles.includes('adminDoctor')))"></input-checkbox></span>
          </div>
          <div class="col-xs-12 mb-3 p-0">
            <label class="mb-2">Caller type</label>
            <input-select [parseObj]="userObj" name="callerType" required="true" [options]="callerTypeOptions" [placeholder]="'Select Caller Type'"></input-select>
          </div>
          <div class="col-xs-12 mb-3 p-0">
            <label class="mb-2">User Type</label>
            <input-multi-select [parseObj]="userObj" name="userType" [options]="userTypeOptions"></input-multi-select>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Doctor Load Config</label>
            <div class="col-xs-6">
              <label class="mr-2">Min FollowUp</label>
              <input [(ngModel)]="doctorLoadConfig.minFollowUpAllocation" name="minFollowUpAllocation" type="number" [disabled]="(!currentUserRoles.includes('adminDoctor'))" (change)="userConfigChange()"/>
            </div>
            <div class="col-xs-6">
              <label class="mr-2">Min Order</label>
              <input [(ngModel)]="doctorLoadConfig.minOrderAllocation" name="minOrderAllocation" type="number" [disabled]="(!currentUserRoles.includes('adminDoctor'))" (change)="userConfigChange()"/>
            </div>
          </div>
          <div class="col-xs-12 p-0">
            <label>Preferred Known Languages Order</label>
            <div class="col-xs-12 pl-0" cdkDropList="cdkDropList" [cdkDropListData]="preferredKnownLanguage" (cdkDropListDropped)="drop($event)"><span class="badge mr-5 mb-2" *ngFor="let each of preferredKnownLanguage; let i = index" cdkDrag="cdkDrag" [cdkDragData]="each">{{each}}</span></div>
          </div>
          <div class="col-xs-12 p-0">
            <label>Languages Known</label>
            <div class="col-xs-12 flex flex-wrap"><span class="flex mr-2" *ngFor="let each of languages; let i = index">
                <input (click)="updateLanguage(each)" [checked]="each.selected" type="checkbox" name="knownLanguages" [value]="each.value" id="{{i}}lang" (change)="each.selected = !each.selected"/>
                <label class="mt-3 ml-3" for="{{i}}lang">{{each.display}}</label></span></div>
          </div>
          <div class="col-xs-12" *ngIf="type === 'EDIT_USER'">
            <label class="mb-0">Permissions</label>
            <div class="col xs-12">
              <label class="col-xs-12">ADD</label>
              <div class="dib mb-2" *ngFor="let each of rolesThatCanBeAdded; let i = index"><span class="badge mr-2 mb-2" [ngClass]="{ 'label-danger':(beforeSaveUserRoles && beforeSaveUserRoles.includes(each) )}">{{each}}<i class="fa fa-plus-square-o pointer ml-2" (click)="addRole(i)"></i></span></div>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">REMOVE</label>
              <div class="dib mb-2" *ngFor="let each of newUserRoles; let i = index"><span class="label badge mr-2 mb-2" [ngClass]="{ 'label-success': ( beforeSaveUserRoles && !beforeSaveUserRoles.includes(each) )}">{{each}}<i class="fa fa-trash-o pointer ml-2" (click)="removeRole(i)"></i></span></div>
            </div>
          </div>
          <div class="col-xs-12 mt-4">
            <button (click)="updateUser(userObj)" [disabled]="!form.valid">{{ type === 'CREATE_USER' ? 'Create' : 'Update' }}</button>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 wrapper-sm">
          <div class="col-xs-12 col-md-10 pull-right">
            <roster [user]="userObj"></roster>
            <work-time [user]="userObj"></work-time>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
