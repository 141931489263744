import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FollowUpComponent } from './follow-up.component';
import { ListReadyModule } from './list-ready';

const ROUTES: Routes = [
  {
    path: 'followUps/ready',
    component: FollowUpComponent,
    loadChildren: (): any => ListReadyModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [FollowUpComponent],
exports: [FollowUpComponent] })
export class FollowUpModule {}
