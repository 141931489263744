
<mat-dialog-content>
  <div class="col-xs-12 no-padder">
    <div class="col-xs-12">
      <div class="text-md font-bold">Rearrange question options.</div>
    </div>
    <div class="col-xs-12">
      <div class="col-xs-5 font-bold">Current Sequence</div>
      <div class="col-xs-1 font-bold">:</div>
      <div class="col-xs-5 font-bold">New Sequence</div>
    </div>
    <div class="line bg-light-gray"></div>
    <div class="col-xs-12 m-t-xs" *ngFor="let option of currentAnswerSequence;let i = index;">
      <div class="col-xs-5">{{option}}</div>
      <div class="col-xs-1">:</div>
      <div class="col-xs-5 flex"><span style="min-width: 20px"><i class="fa fa-long-arrow-up pointer m-l-xs" (click)="moveOptionUp(i)" *ngIf="i !== 0"></i></span><span style="min-width: 20px"><i class="fa fa-long-arrow-down pointer m-l-xs" (click)="moveOptionDown(i)" *ngIf="i !== newAnswerSequence.length - 1"></i></span><span>{{newAnswerSequence[i]}}</span></div>
    </div>
    <div class="col-xs-12">
      <button class="btn btn-sm btn-success pull-right" (click)="confirmMapping()">Confirm</button>
    </div>
  </div>
</mat-dialog-content>