import { Component, Inject, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../../../services/connection-service';
import { AppConfig } from '../../../../app.config';
import { LocalStorage } from '../../../../../services/local-storage-service';
import { WindowRefService } from '../../../../../services/window-ref-service';

@Component({ selector: 'image-upload-dialog', templateUrl: './image-upload-dialog.html' })
export class ImageUploadDialog {
  file: File;
  chatUser: any;
  popUpModel: { loading: boolean };
  imageType: string;
  types: Array<any>;
  result: any;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ImageUploadDialog>,
    private windowRef: WindowRefService,
    private conn: ConnectionService,
    private appConfig: AppConfig,
    private zone: NgZone,
    private storage: LocalStorage,
    private http: HttpClient) {
    this.chatUser = this.data.chatUser;
    this.imageType = this.data.imageType;
    this.types = this.data.types;
    this.file = this.data.file;
    this.popUpModel = { loading: false };
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  async uploadInstantCheckup(): Promise<any> {
    const formData: FormData = new FormData();
    this.popUpModel.loading = true;
    formData.append('file', this.file);
    formData.append('userId', this.chatUser.get('username'));
    formData.append('instantCheckup', `{ "type": "${this.imageType}" }`);
    formData.append('sessionToken', this.conn.getCurrentUser().getSessionToken());
    if (this.windowRef.nativeWindow.location.hostname !== 'doctor.cureskin.com') {
      formData.append('testEnv', 'true');
    }
    const name = this.file.name.replace(/ /g, '_');
    this.http.post(
      `https://analyze.cureskin.com/uploadImageInstantCheckup?userId=${this.chatUser.get('username')}&imageName=${name}`,
      formData)
      .toPromise()
      .then(() => (this.popUpModel.loading = false))
      .then(() => {
        this.popUpModel.loading = false;
        this.dialogRef.close({ file: this.file, imageType: this.imageType });
      })
      .catch((err: any) => {
        this.popUpModel.loading = false;
        alert(err.message || err);
      });
  }
}
