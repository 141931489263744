import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { InputType } from '../../../../../../typings/client/input';
import { AppConfig } from '../../../../app.config';
import { ValidationService } from '../../../../../services/validation-service';

@Component({
  selector: 'catalog-variant',
  templateUrl: './catalog-variant.html',
})
export class CatalogVariantModal {
  productObj: any;
  inventoryStatus: Array<InputType.SelectOption>;
  quantityUnits: Array<InputType.SelectOption>;
  constructor(private dialogRef: MatDialogRef<CatalogVariantModal>, @Inject(MAT_DIALOG_DATA) private data: any,
    private appConfig: AppConfig, private validationService: ValidationService) {
    this.productObj = this.data.product;
    this.quantityUnits = [
      { display: 'mg', value: 'mg' },
      { display: 'gms', value: 'gms' },
      { display: 'ml', value: 'ml' },
    ];
    this.inventoryStatus = Object.keys(ApiClientConstant.Catalog.InventoryStatus).map((key: string) => ({
      display: ApiClientConstant.Catalog.InventoryStatus[key].replace(/_/g, ' '),
      value: ApiClientConstant.Catalog.InventoryStatus[key],
    }));
  }

  public saveCatalog(): void {
    if (!this.validationService.validateMargId(this.productObj.get('margId'))) {
      alert('MargId must be greater than 0');
      return;
    }
    this.saveProduct();
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  saveProduct(): void {
    this.productObj.save()
      .then((product: any) => this.dialogRef.close({ info: 'SAVED', product }))
      .catch((err: any) => this.dialogRef.close({ info: 'NOT_SAVED', err }));
  }

  ngOnDestroy(): void {
    delete this.appConfig;
    delete this.validationService;
    delete this.data;
    delete this.dialogRef;
  }
}
