import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { AppConfig } from '../../../../../app.config';

@Component({
  selector: 'catalog-instruction-view',
  templateUrl: './catalog-instruction-view.html',
  styleUrls: ['./catalog-instruction-view.scss'],
})
export class CatalogInstructionViewComponent {
  @Input('instruction') instruction: any;
  @Input('cssClasses') cssClasses: string;
  @Output('onDelete') onDelete: EventEmitter<any> = new EventEmitter();
  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  @Output('onUseAsTemplate') onUseAsTemplate: EventEmitter<any> = new EventEmitter();
  @Input('showAction') showAction: boolean = false;
  @Input('type') type: string;

  multipleValueFields: Array<{ title: string, fieldName: string }>;
  constructor(public appConfig: AppConfig) {
    this.multipleValueFields = [
      { title: 'Notes', fieldName: 'notesLanguageString' },
      { title: 'Restrictions', fieldName: 'restrictionsLanguageString' },
      { title: 'Warnings', fieldName: 'warningsLanguageString' },
      { title: 'Indication Restrictions (Not Visible to User)', fieldName: 'indicationRestrictionsLanguageString' },
      { title: 'Caution', fieldName: 'cautionsLanguageString' },
      { title: 'Side Effects', fieldName: 'sideEffectsLanguageString' },
      { title: 'Suitable in weather', fieldName: 'weathersLanguageString' },
    ];
  }

  ngOnInit(): any {
    if (this.type === ApiClientConstant.Order.Type.REGIMEN) {
      this.cssClasses = 'regimen';
    } else if (this.instruction.partOfDay === this.appConfig.Shared.Catalog.PART_OF_DAY.NIGHT
      || this.instruction.title.toLowerCase().includes('night')) {
      this.cssClasses = 'product-night-view';
    } else if (this.instruction.partOfDay === this.appConfig.Shared.Catalog.PART_OF_DAY.MORNING
      || this.instruction.title.toLowerCase().includes('morning')) {
      this.cssClasses = 'product-morning-view';
    } else {
      this.cssClasses = 'no-type';
    }
  }
}
