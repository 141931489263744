import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SalesService } from 'src/app/sales/services/sales.service';
import { LocalStorage } from 'src/services/local-storage-service';
import { Subscription } from 'rxjs';
import { AddOrderLabelModal } from 'src/app/order/view/add-order-label';
import { Broadcaster } from 'src/components/broadcaster';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'order-info-tags',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-info-tags.component.html',
  styleUrl: './order-info-tags.component.scss',
})
export class OrderInfoTagsComponent {
  @Input('orderObj') orderObj: any;
  @Input('parseOrderObj') parseOrderObj: any;
  subscriptions: Array<Subscription> = [];
  tags: any = [];
  services: any = [];
  InternalUserRoles: Array<string> = [];
  hasAccessToDeleteLabels: boolean;

  constructor(private dialog: MatDialog,
    private storage: LocalStorage,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
    private salesService: SalesService,
  ) { }

  ngOnInit(): void {
    this.InternalUserRoles = this.storage.getJsonValue('userRoles');
    if (this.InternalUserRoles.some((role: string) => [this.appConfig.Shared.Role.Name.ADMIN,
      'adminDoctor',
      this.appConfig.Shared.Role.Name.ADMIN_OPERATOR].includes(role))) {
      this.hasAccessToDeleteLabels = true;
    }
  }

  ngOnChanges(): void {
    if (this.orderObj) {
      this.services = this.orderObj.services.map((service: any): any => service.title);
      this.tags = [...this.orderObj.labels, ...this.services];
    }
  }

  private updateOrder(updatedLabels?: Array<any>): void {
    const labels = updatedLabels || this.orderObj.labels || [];
    this.tags = [...labels, ...this.services];
  }

  addTags(): void {
    const dialogRef = this.dialog.open(AddOrderLabelModal, { width: '95%', data: { order: this.parseOrderObj } });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((labels: any): void => {
      this.updateOrder(labels);
    }));
  }

  removeTags(index: number): void {
    const label = this.tags[index];
    if (label.toLowerCase() === 'gokwik' && !this.hasAccessToDeleteLabels) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'User Doesn\'t have access to remove this value',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return;
    }
    const orderNote = prompt(`Please enter "${label}" removal note`);
    if (!orderNote) {
      return;
    }
    const labels = this.parseOrderObj.get('labels');
    labels.splice(index, 1);
    this.parseOrderObj.set('labels', labels);
    this.salesService.emitEvent({ orderObj: this.parseOrderObj, note: orderNote });
    this.orderObj.labels = labels;
    this.updateOrder();
  }
}
