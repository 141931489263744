import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparisonViewerComponent } from './comparison-viewer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ComparisonViewerComponent],
  exports: [ComparisonViewerComponent],
})
export class ComparisonViewerModule {}
