import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogComponent } from './catalog.component';
import { CatalogViewModule } from './view';
import { ListModule } from './list';
import { CatalogEditModule } from './edit';
import { CatalogResolve } from './catalog-resolver';

const ROUTES: Routes = [
  {
    path: 'catalogs',
    component: CatalogComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'catalogs/new',
    component: CatalogComponent,
    loadChildren: (): any => CatalogEditModule,
    pathMatch: 'full',
  },
  {
    path: 'catalog/:id',
    component: CatalogComponent,
    loadChildren: (): any => CatalogViewModule,
    resolve: { catalog: CatalogResolve },
    pathMatch: 'full',
  },
  {
    path: 'catalog/:id/edit',
    component: CatalogComponent,
    loadChildren: (): any => CatalogEditModule,
    resolve: { catalog: CatalogResolve },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [CatalogResolve],
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [CatalogComponent],
  exports: [CatalogComponent] })
export class CatalogModule {}
