<div style="border: 1px solid grey; position: absolute; z-index: 1000; background: white; bottom: 0;">
  <div *ngIf="exotelService.isDeviceRegistered">
    <div class="PhoneControls">
      <!-- Accept call button -->
      <div
        class="user-number"
        *ngIf="(exotelService.callActive || exotelService.isIncomingCall) && exotelService.incomingCallNumber">
        Number: {{exotelService.incomingCallNumber}}
      </div>
      <button
        class="accept"
        *ngIf="exotelService.callActive || exotelService.isIncomingCall"
        (click)="exotelService.acceptCall()"
        [disabled]="exotelService.callAccepted && !exotelService.isIncomingCall">
        Accept
      </button>
      <!-- Hangup button -->
      <button
        class="hangup"
        *ngIf="exotelService.callActive || exotelService.isIncomingCall"
        (click)="exotelService.hangup()"
        [disabled]="!exotelService.callActive && !exotelService.isIncomingCall">
        Hangup
      </button>
      <!-- Mute/Unmute button -->
      <button
        class="mute-unmute" 
        *ngIf="exotelService.callActive && exotelService.callAccepted"
        (click)="exotelService.onClickToggleMute()">
        {{ exotelService.isMuted ? 'Unmute' : 'Mute' }}
      </button>
      <!-- Hold/Unhold button -->
      <button
        *ngIf="exotelService.callActive && exotelService.callAccepted"
        [class]="exotelService.isOnHold ? 'unhold' : 'hold'" 
        (click)="exotelService.onClickToggleHold()">
        {{ exotelService.isOnHold ? 'Unhold' : 'Hold' }}
      </button>
    </div>
  </div>
</div>