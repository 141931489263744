import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';
import { Broadcaster } from '../../../components/broadcaster';

@Component({
  selector: 'featured-products',
  templateUrl: './featured-products.html',
  styleUrls: ['./featured-products.scss'],
})
export class FeaturedProductsComponent {
  window: any;
  roles: Array<string> = [];
  currentUser: any;
  rxProducts: Array<any> = [];
  nonRxProducts: Array<any> = [];
  languageStringTag: any;
  catalogs: Array<any> = [];
  searchText: string;

  constructor(
    private router: Router,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private broadcaster: Broadcaster,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.conn.getCurrentUser();
    this.roles = this.storage.getJsonValue('userRoles') || [];
    this.languageStringTag = await this.conn.findLanguageStringTags({ where: { name: 'featured' } });
    this.catalogs = JSON.parse(JSON.stringify(await this.conn.findCatalogs({
      where: {
        tags: this.languageStringTag,
        type: ApiClientConstant.Catalog.Type.MAIN,
        inventoryStatus: ApiClientConstant.Catalog.InventoryStatus.AVAILABLE,
      },
      include: ['tags'],
      project: ['title', 'tags', 'prescriptionRequired'],
    })));
    this.rxProducts = this.catalogs.filter((each: any) => each.prescriptionRequired);
    this.nonRxProducts = this.catalogs.filter((each: any) => !each.prescriptionRequired);
  }

  onChangeText(): void {
    const filteredCatalogs = this.catalogs.filter((each: any) => each.title.toLowerCase().includes(this.searchText.toLowerCase()));
    this.rxProducts = filteredCatalogs.filter((each: any) => each.prescriptionRequired);
    this.nonRxProducts = filteredCatalogs.filter((each: any) => !each.prescriptionRequired);
  }
}
