import * as moment from 'moment';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientConstant, Table, RequestQueryPayload, ApiConnector } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../app.config';
import { ClipboardService } from '../../services/copyToClipboard-service';
import { WindowRefService } from '../../services/window-ref-service';
import { Broadcaster } from '../../components/broadcaster';
import { AudioRecorderComponent } from '../../components/audio-recorder/audio-recorder.component';
import { FaviconService } from '../../services/favicon-service';
import { TabNamingService } from '../../services/tab-naming-service';
import { HelperService } from '../../services/helper-service';
import { RegimenEditComponent } from '../product/regimen/edit/regimen-edit.component';

@Component({
  selector: 'compare-progress',
  templateUrl: './compare-image-progress.html',
  styleUrls: ['./compare-image-progress.scss'],
})
export class CompareImageProgressComponent implements OnInit {
  @ViewChild('firstImage', { static: false }) canvas1: ElementRef;
  @ViewChild('secondImage', { static: false }) canvas2: ElementRef;
  @ViewChild('firstImageContainer', { static: false }) img1Container: ElementRef;
  @ViewChild('secondImageContainer', { static: false }) img2Container: ElementRef;
  @ViewChild('toast', { static: false }) toast: ElementRef;

  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('imgH1', { static: false }) imgH1: ElementRef;
  @ViewChild('imgH2', { static: false }) imgH2: ElementRef;
  @ViewChild('sliderCanvas1', { static: false }) sliderCanvas1: ElementRef;
  @ViewChild('sliderCanvas2', { static: false }) sliderCanvas2: ElementRef;
  @ViewChild('audioPlayer', { static: false }) audioPlayer: ElementRef;
  @ViewChild(AudioRecorderComponent) audioRecorderComponent: AudioRecorderComponent;
  @ViewChild(RegimenEditComponent, { static: false }) regimenEditComponent: RegimenEditComponent;

  MIN_CANVAS_WIDTH: number = 360;
  MIN_CANVAS_HEIGHT: number = 360;
  imageURL: Array<any> = [];
  instantCheckups: any[] = [];
  audioNote: string;
  audioNoteFromComponent: string;
  userId: string;
  chatUser: any;
  class: ValueOf<typeof ApiClientConstant.Regimen.Class>;
  disableSendButton: boolean = false;
  message: any;
  timeInterval: any;
  imageData:any = [];
  imageConfig: { after: any, before: any } = { after: {}, before: {} };
  concernsMap: Map<string, { aiClassName: string[], languageStringId: string }> = new Map<
    string, { aiClassName: string[], languageStringId: string }>();
  concernList: any[];
  mainConcernList: any[];
  concernsSelected: any[] = [];
  editReportId: string;
  audioRecordedLink: string;
  investigationList: any[] = [];
  selectedInvestigation: { tests: string[], name?: string, index?: number } = { tests: [] };
  tipsList: any[] = [];
  tips: any[] = [];
  selectedTip: any;
  selectedConcern: any;
  selectedIssue: any;
  concerns: any;
  additionalTestInput: string;
  activateFullSizeImageIndex: number = -1;
  reportCompletionStatus: { audioMessage?: boolean,
    imageComparison?: boolean,
    treatmentProgress?: boolean,
    investigation?: boolean,
    treatmentOutcome?: boolean,
    tip?: boolean,
    regimenEdit?: boolean} = {};
  editFollowupReport: any;
  showInstantCheckupList: boolean;
  comparisonSliderConfig: any[] = [];
  currentUser: any;
  regimen: any;
  showProductChangeAlert: boolean = false;
  userUnderExperiment: boolean = false;
  drObservation: any = {};
  enableDrObservationExperiment: boolean;
  supportTicketObject: any;
  isIsotretConsent: boolean = false;
  treatmentOutcomeOptions: Array<{ display: string, value: string }> = [];
  subTreatmentOutcomeOptions: Array<{ display: string, value: string }> = [];
  treatmentOutcome: any = {};
  followUp:any;
  isFinasterideConsent: boolean = false;
  discontinuedProductsInRegimen: Array<any> = [];
  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private conn: ConnectionService,
    private broadcasterService: Broadcaster,
    public appConfig: AppConfig,
    private copyToClipBoardService: ClipboardService,
    private windowService: WindowRefService,
    private broadcaster: Broadcaster,
    private faviconService: FaviconService,
    private tabNaming: TabNamingService,
    private helperService: HelperService,
  ) {}

  async ngOnInit(): Promise<any> {
    if (!this.windowService.isCurrentDeviceIsLaptop) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'This Page is not supported in Mobile or Tab',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return;
    }
    const { params }: any = this.route.snapshot;
    if (this.route.snapshot.queryParams.followUpId) {
      const { followUpId }:any = this.route.snapshot.queryParams;
      this.followUp = await ApiConnector.findOne(Table.FollowUp, { where: { objectId: followUpId } });
    }
    this.userId = params.username;
    this.chatUser = await this.conn.getUserByUserName(this.userId);
    this.currentUser = this.conn.getCurrentUser();
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.currentUser });
    this.followUp = await this.conn.findOneFollowUp({
      where: {
        user: this.chatUser,
        State: [
          this.appConfig.Shared.FollowUp.State.PENDING,
          this.appConfig.Shared.FollowUp.State.WAITING_FOR_IMAGE,
        ],
      },
      descending: 'followUpCount',
      project: ['State'],
    });
    this.comparisonSliderConfig.push({ ...params, index: 0 });
    this.supportTicketObject = new Table.SupportTicket();
    this.instantCheckups = await this.conn.getInstantCheckUpWith(params.username, [params.id1, params.id2]);
    this.imageURL.push({
      firstImage: this.instantCheckups[0].imagePath,
      secondImage: this.instantCheckups[1].imagePath,
    });
    if (this.route.snapshot.queryParams.ids) {
      const quaryParamsIds = this.route.snapshot.queryParams.ids.split(',') || [];
      for (let i = 0; i < quaryParamsIds.length; i += 2) {
        this.addToComparison([quaryParamsIds[i], quaryParamsIds[i + 1]]);
      }
    }
    if (this.helperService.getInstantCheckupClassByType(this.instantCheckups[0].type)
    !== this.helperService.getInstantCheckupClassByType(this.instantCheckups[1].type)) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Choose Image Of Same Type', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    this.class = this.helperService.getInstantCheckupClassByType(this.instantCheckups[0].type);
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.chatUser });
    this.faviconService.setFavicon(this.chatUser.get('PatientName'));
    this.tabNaming.setTitle('', this.chatUser.get('PatientName'), 'compare progress');
    this.editReportId = this.route.snapshot.queryParams.edit;
    [this.editFollowupReport] = await this.conn.findFollowUpReports({ where: { objectId: this.editReportId } });
    this.findDetectedConcerns();
    this.tipsList = await this.conn.fetchTipsByRegimenDisplayConcerns(
      this.helperService.getInstantCheckupClassByType(this.instantCheckups[0].type));
    const experiment = await this.conn.findExperiment({ where: { name: 'take_dr_observation' } });
    if (experiment) {
      const experimentVariant = JSON.parse(experiment.get('variant')) || {};
      if (experimentVariant[this.currentUser.get('username')] && !this.editReportId) {
        this.enableDrObservationExperiment = true;
      }
    }
    await this.filterUniqueTipConcerns();
    await this.fetchInvestigations();
    await this.fetchUserRegimen();
    await this.checkForRegimenProductChange();
    await this.checkIfTreatmentUnderExperiment();
    this.treatmentOutcomeOptions = [
      { display: 'Main Concern Reducing ', value: 'MAIN_CONCERN_REDUCING' },
      { display: 'Condition remains same', value: 'CONDITION_REMAINS_SAME' },
      { display: 'Main Concern Increasing', value: 'MAIN_CONCERN_INCREASING' },
    ];
  }

  private async checkForRegimenProductChange(): Promise<void> {
    if (!this.regimen) return;
    const durationToEditRegimenInWeeks = 12;
    if (moment().subtract(durationToEditRegimenInWeeks, 'week').isSameOrBefore(this.regimen.get('createdAt'))) {
      return;
    }
    const numberOfProductsAdded = await ApiConnector.count(Table.ActionLog, {
      where: {
        type: ApiClientConstant.ActionLog.Type.REGIMEN_CHANGE,
        regimen: this.regimen,
        actionQuestionUniqueId: 'productAdded',
        createdAt: { $gte: moment().subtract(durationToEditRegimenInWeeks, 'week').toDate() },
      },
    });
    if (!numberOfProductsAdded) {
      this.showProductChangeAlert = true;
    }
  }

  private async checkIfTreatmentUnderExperiment(): Promise<void> {
    const attendToChatCache = JSON.parse(JSON.stringify(await this.conn.getAttendToChatUser({
      where: {
        user: this.chatUser,
      },
      project: ['userLabel'],
    })));
    if (!attendToChatCache?.length) return;
    this.userUnderExperiment = !!attendToChatCache.find((each: any) => each.userLabel?.includes('Npd Experiment'));
  }

  async fetchUserRegimen(): Promise<void> {
    const concernClass = this.helperService.getInstantCheckupClassByType(this.instantCheckups[0].type);
    [this.regimen] = await this.conn.findRegimens({ where: { forUser: this.chatUser, class: concernClass }, include: ['products'] });
    this.regimen.get('products').forEach((product: any) => {
      if (product.get('inventoryStatus') === ApiClientConstant.Catalog.InventoryStatus.DISCONTINUED) {
        this.discontinuedProductsInRegimen.push(product);
      }
    });
  }

  afterSaveRegimen(event: any): void {
    this.reportCompletionStatus.regimenEdit = true;
  }

  async fetchTips(): Promise<void> {
    this.tips = this.tipsList.filter((each: any) => each.get('concerns').includes(this.selectedConcern));
    if (this.editFollowupReport?.get('tipsLanguageString')) {
      this.selectedTip = this.tips.findIndex(
        (each: any) => this.editFollowupReport.get('tipsLanguageString').objectId === each.get('tipLanguageString').id);
    }
  }

  async filterUniqueTipConcerns(): Promise<any> {
    const allConcerns = this.tipsList.map((each: any) => each.get('concerns')).flat();
    this.concerns = Array.from(new Set(allConcerns));
  }

  async fetchInvestigations(): Promise<void> {
    this.investigationList = await this.conn.fetchInvestigations();
    if (this.editFollowupReport) {
      this.selectedInvestigation = JSON.parse(JSON.stringify(this.editFollowupReport.get('investigations') || {}));
      this.selectedInvestigation.index = this.investigationList
        .findIndex((each: any) => each.get('name') === this.selectedInvestigation.name);
    }
  }

  async findDetectedConcerns(): Promise<any> {
    const requestPayload: RequestQueryPayload<Table.MainConcern> = {
      where: { class: this.class, section: 'TREATMENT' },
      include: ['displayNameLanguageString', 'keywords.keyLanguageString' as 'keywords'],
    };
    this.mainConcernList = JSON.parse(JSON.stringify(await this.conn.fetchMainConcerns(requestPayload)));
    this.mainConcernList.forEach((each: any) => {
      this.concernsMap.set(each.displayNameLanguageString.en, { languageStringId: each.displayNameLanguageString.objectId,
        aiClassName: each.aiClassName });
    });
    this.concernList = Object.keys(Object.fromEntries(this.concernsMap));

    const concernsDetected = [];
    this.instantCheckups.forEach((each: any) => {
      if (each.aiResponse) {
        const aiResponseResult = each.aiResponse.result;
        concernsDetected.push(...(aiResponseResult.filter((each_: any) => each_.Condition === 'Detected')));
      }
    });
    if (this.editFollowupReport) {
      this.editFollowupReport.get('treatmentProgress')?.forEach((each: any) => {
        this.concernsSelected.push({ concern: each.issue, score: each.score });
      });
      return;
    }
    if (this.class === ApiClientConstant.Regimen.Class.FACE) {
      const aiClassList: string[] = concernsDetected.map((each: any) => each.class);
      this.mainConcernList.forEach((each: any) => {
        if (!each.aiClassName) return;
        each.aiClassName.forEach((className: string) => {
          if (aiClassList.includes(className)) this.concernsSelected.push({ concern: each.displayNameLanguageString.en, score: 2 });
        });
      });
    }
  }

  addConcernToTreatmentProgress(): void {
    if (this.selectedIssue) {
      this.reportCompletionStatus.treatmentProgress = true;
      this.concernsSelected.push({ concern: this.selectedIssue, score: 2 });
    }
  }

  removeConcernFromTreatmentProgress(issue: string, index: number): void {
    this.concernsSelected.splice(index, 1);
    if (!Object.keys(this.concernsSelected).length) this.reportCompletionStatus.treatmentProgress = false;
  }

  isConcernSelected(concern: string): boolean {
    return !!(this.concernsSelected.find((each: any) => each.concern === concern));
  }

  async saveFollowupReport(): Promise<any> {
    this.disableSendButton = true;
    if (!this.editFollowupReport) {
      if (!this.reportCompletionStatus.imageComparison
          || !this.reportCompletionStatus.audioMessage
          || !this.reportCompletionStatus.treatmentProgress
          || !this.reportCompletionStatus.treatmentOutcome
          || !this.reportCompletionStatus.tip) {
        return this.showToastMessage('Complete first 5 mandatory steps.');
      }
    }

    if (this.enableDrObservationExperiment) {
      if (!this.drObservation.addressedPatientQueries
        || !this.drObservation.drObservations
        || !this.drObservation.prognosis
        || !this.drObservation.timeFrameForExpectations
        || !this.drObservation.changedProductBenefits
        || !this.drObservation.treatmentPlan
        || !this.drObservation.reassurance
        || !this.drObservation.counselling) {
        return this.showToastMessage('Filling Changes Done in Regimen is Compulsory for this user');
      }
    }

    if (this.reportCompletionStatus.audioMessage) {
      if (!this.audioRecordedLink && this.audioRecorderComponent.isRecordingAvailable()) {
        await this.audioRecorderComponent.saveRecording();
      }
    }

    const regimens = await this.conn.findRegimens({ where: { forUser: this.chatUser, active: true }, project: ['class'] });
    const regimenOfReportType = regimens.find((each: any) => each.get('class') === this.class);
    const followupReport = this.editFollowupReport || (new Table.FollowUpReport());
    if (this.enableDrObservationExperiment) {
      followupReport.set('drObservation', this.drObservation);
    }

    if (this.isIsotretConsent) {
      await this.triggerIsotretConsentForm();
      if (this.supportTicketObject?.id) {
        followupReport.set('consentFormAsk', true);
        followupReport.set('supportTicket', this.supportTicketObject);
      }
    }
    if (this.isFinasterideConsent) {
      await this.triggerFinasterideConsentForm();
      if (this.supportTicketObject?.id) {
        followupReport.set('consentFormAsk', true);
        followupReport.set('supportTicket', this.supportTicketObject);
      }
    }
    followupReport.set('treatmentOutcome', this.treatmentOutcome);

    const treatmentProgress = [];
    this.concernsSelected.forEach((each: any) => {
      const { score, concern }: { score: string, concern: string } = each;
      const concernLanguageString = new Table.LanguageString();
      concernLanguageString.id = this.concernsMap.get(concern).languageStringId;
      treatmentProgress.push({ issue: each.concern, concernLanguageString, score });
    });

    this.imageConfig = this.comparisonSliderConfig[0].imageConfig;
    if (this.reportCompletionStatus.imageComparison) followupReport.set('imageConfig', this.imageConfig);

    if (this.reportCompletionStatus.audioMessage && this.audioRecordedLink) {
      followupReport.set('audioURL', this.audioRecordedLink);
      followupReport.set('audioNote', this.audioNote);
    }

    followupReport.set('treatmentProgress', treatmentProgress);
    if (this.reportCompletionStatus.investigation && this.selectedInvestigation.tests?.length) {
      delete this.selectedInvestigation.index;
      followupReport.set('investigations', this.selectedInvestigation);
    }

    if (this.reportCompletionStatus.tip && this.selectedTip >= 0) {
      followupReport.set('tipsLanguageString', this.tips[this.selectedTip].get('tipLanguageString'));
    }

    if (!this.editFollowupReport) {
      followupReport.set('isImageOnlyReport', false);
      if (regimenOfReportType) followupReport.set('concernLanguageStringId', regimenOfReportType.get('concernLanguageStringId'));
      let instantCheckupObject = new Table.InstantCheckup();
      instantCheckupObject.id = this.instantCheckups[1].objectId;
      followupReport.set('beforeInstantCheckup', instantCheckupObject);
      instantCheckupObject = new Table.InstantCheckup();
      instantCheckupObject.id = this.instantCheckups[0].objectId;
      followupReport.set('afterInstantCheckup', instantCheckupObject);
      followupReport.set('doctor', this.currentUser.get('type') === ApiClientConstant.User.Type.DOCTOR
        ? this.currentUser
        : this.chatUser.get('allocatedDoctor'));
      followupReport.set('type', this.class);
      followupReport.set('user', this.chatUser);
      const savedSecondaryConcerns: any [] = await this.updateSecondaryComparisons(followupReport);
      followupReport.set('secondaryComparisons', savedSecondaryConcerns);
      followupReport.set('isSecondaryComparison', false);
    }
    if (this.followUp) {
      followupReport.set('followUp', this.followUp);
    }
    try {
      if (this.reportCompletionStatus.imageComparison) this.saveOrientation();
      await followupReport.save();
      this.showToastMessage(this.editFollowupReport ? 'Updated successfully' : 'Sent successfully',
        this.appConfig.Shared.Toast.Type.SUCCESS);
      this.windowService.nativeWindow.close();
      this.router.navigate([`/chat/${this.chatUser.id}`]);
    } catch (err) {
      this.showToastMessage(err.message);
    } finally {
      this.disableSendButton = false;
    }
    this.disableSendButton = false;
    return 0;
  }

  async updateSecondaryComparisons(primaryReport: any): Promise<any[]> {
    const secondaryComparisons = this.comparisonSliderConfig.slice(1);
    const promises: Promise<any>[] = [];
    secondaryComparisons.forEach((each: any) => {
      const followupReport = new Table.FollowUpReport();
      let instantCheckupObject = new Table.InstantCheckup();
      instantCheckupObject.id = each.id2;
      followupReport.set('beforeInstantCheckup', instantCheckupObject);
      instantCheckupObject = new Table.InstantCheckup();
      instantCheckupObject.id = each.id1;
      followupReport.set('afterInstantCheckup', instantCheckupObject);
      followupReport.set('imageConfig', each.imageConfig);
      followupReport.set('type', this.class);
      followupReport.set('doctor', primaryReport.get('doctor'));
      followupReport.set('user', primaryReport.get('user'));
      followupReport.set('isSecondaryComparison', true);
      followupReport.set('isImageOnlyReport', true);
      if (this.followUp) {
        followupReport.set('followUp', this.followUp);
      }
      promises.push(followupReport.save());
    });
    return Promise.all(promises);
  }

  saveOrientation(): any {
    this.conn.saveOrientation(this.instantCheckups[0], this.instantCheckups[1]);
  }

  onAudioRecordingStatusChange(audioChunks: any[] = []): void {
    if (audioChunks.length) {
      this.reportCompletionStatus.audioMessage = true;
      this.audioNote = this.audioNoteFromComponent;
    } else {
      this.audioRecordedLink = '';
      this.audioNote = '';
      this.reportCompletionStatus.audioMessage = false;
    }
  }

  afterSaveRecording(link: string): void {
    if (link) {
      this.reportCompletionStatus.audioMessage = true;
      this.audioRecordedLink = link;
    }
  }

  changeInvestigation(): void {
    const selectedIndex = Number(this.selectedInvestigation.index);
    this.reportCompletionStatus.investigation = true;
    if (selectedIndex >= 0) this.selectedInvestigation.name = this.investigationList[selectedIndex].get('name');
    if (selectedIndex >= 0) {
      this.selectedInvestigation.tests = this.investigationList[selectedIndex].get('tests');
    } else {
      this.selectedInvestigation.tests = [];
      delete this.selectedInvestigation.name;
    }
  }

  addAdditionalTestToList(): void {
    this.reportCompletionStatus.investigation = true;
    if (this.additionalTestInput) {
      this.selectedInvestigation.tests.push(this.additionalTestInput);
      delete this.additionalTestInput;
    }
  }

  removeTestFromSelection(index: any): void {
    if (index >= 0) this.selectedInvestigation.tests.splice(index, 1);
    if (!this.selectedInvestigation.tests.length) this.reportCompletionStatus.investigation = false;
  }

  addMoreComparisonReport(): void {
    this.reportCompletionStatus.imageComparison = false;
    this.showInstantCheckupList = true;
  }

  async addToComparison(checkupIds: string[]): Promise<void> {
    this.comparisonSliderConfig.push({ username: this.userId,
      id1: checkupIds[0],
      id2: checkupIds[1],
      index: this.comparisonSliderConfig.length });
    this.showInstantCheckupList = false;
    const instantCheckups = await this.conn.getInstantCheckUpWith(this.userId, [checkupIds[0], checkupIds[1]]);
    this.imageURL.push({
      firstImage: instantCheckups[0].imagePath,
      secondImage: instantCheckups[1].imagePath,
    });
  }

  onRemoveComparison(index: number): void {
    this.comparisonSliderConfig.splice(index, 1);
    this.imageURL.splice(index, 1);
  }

  onImagePositionChange(config: any, index: number): void {
    this.comparisonSliderConfig[index].imageConfig = config;
    this.reportCompletionStatus.imageComparison = true;
    this.comparisonSliderConfig.forEach((value: any) => {
      if (!value.imageConfig) {
        this.reportCompletionStatus.imageComparison = false;
      }
    });
  }
  wantsToTriggerConsentForm(isIsotretConsent: boolean):void {
    if (isIsotretConsent) {
      this.isIsotretConsent = true;
    } else {
      this.isFinasterideConsent = true;
    }
  }
  async triggerIsotretConsentForm(): Promise<void> {
    try {
      if (this.currentUser.get('type') !== ApiClientConstant.User.Type.DOCTOR) {
        this.broadcasterService.broadcast('NOTIFY', { message: 'Only Doctor Can Sent Isotret Consent Form',
          type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
      let user = await this.conn.getUser({ userId: this.userId });
      user = user[0];
      const supportQuestion = await ApiConnector.findOne(Table.SupportQuestion, {
        where: { question: 'Others' },
        include: ['questionLanguageString'],
      });
      const languageString = await ApiConnector.findOne(Table.LanguageString, {
        where: { en: 'Isotret Consent' },
      });
      if (!user || !supportQuestion) {
        return;
      }
      this.supportTicketObject.set('title', languageString?.get(user?.get('languagePreference')));
      this.supportTicketObject.set('user', user);
      this.supportTicketObject.set('status', ApiClientConstant.SupportTicket.Status.Pending);
      this.supportTicketObject.set('languagePreference', user?.get('languagePreference'));
      this.supportTicketObject.set('teams', ['doctor']);
      this.supportTicketObject.set('supportQuestion', supportQuestion);
      this.supportTicketObject.set('allocatedDoctor', this.currentUser);
      this.supportTicketObject.set('titleLanguageString', languageString);
      this.supportTicketObject.set('skipReAllocateDoctor', true);
      let questionId: string;
      switch (this.chatUser.get('Gender')) {
        case 'Male': {
          questionId = this.chatUser.get('languagePreference') === 'hi'
            ? 'consentFormIsotretinoinMaleHi'
            : 'consentFormIsotretinoinMaleEn';
          break;
        }
        case 'Female': {
          questionId = this.chatUser.get('languagePreference') === 'hi'
            ? 'consentFormIsotretinoinFemaleHi'
            : 'consentFormIsotretinoinFemaleEn';
          break;
        }
        default: {
          this.broadcaster.broadcast('NOTIFY', { message: 'Gender is not present',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
      }
      this.supportTicketObject = await this.supportTicketObject.save();
      const context = { supportTicketId: this.supportTicketObject.id };
      await this.conn.sendQuestionTo(questionId, this.chatUser.get('username'), context);
      await this.conn.sendQuestionTo('consentFormIsotretinoinAcknowledgement', this.chatUser.get('username'), context);
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'Some Problem in Sending Isotret Consent Form',
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
  async triggerFinasterideConsentForm(): Promise<void> {
    try {
      if (this.currentUser.get('type') !== ApiClientConstant.User.Type.DOCTOR) {
        this.broadcasterService.broadcast('NOTIFY', { message: 'Only Doctor Can Sent Finasteride Consent Form',
          type: this.appConfig.Shared.Toast.Type.ERROR });
      }
      let user = await this.conn.getUser({ userId: this.userId });
      user = user[0];
      const supportQuestion = await ApiConnector.findOne(Table.SupportQuestion, {
        where: { question: 'Finasteride Consent Form' },
        include: ['questionLanguageString'],
      });
      const languageString = await ApiConnector.findOne(Table.LanguageString, {
        where: { en: 'Finasteride Consent Form' },
      });
      if (!user) {
        return;
      }
      this.supportTicketObject.set('title', languageString?.get(user?.get('languagePreference')) || 'Finasteride Consent Form');
      this.supportTicketObject.set('user', user);
      this.supportTicketObject.set('status', ApiClientConstant.SupportTicket.Status.Pending);
      this.supportTicketObject.set('languagePreference', user?.get('languagePreference'));
      this.supportTicketObject.set('teams', ['doctor']);
      this.supportTicketObject.set('supportQuestion', supportQuestion);
      this.supportTicketObject.set('allocatedDoctor', this.currentUser);
      this.supportTicketObject.set('titleLanguageString', languageString);
      this.supportTicketObject.set('skipReAllocateDoctor', true);
      this.supportTicketObject = await this.supportTicketObject.save();
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'Some Problem in Sending Finasteride Consent Form',
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
    const context = { supportTicketId: this.supportTicketObject.id };
    try {
      const questionId = `consentFormFinasterideMale${this.chatUser.get('languagePreference')
        .charAt(0).toUpperCase() + this.chatUser.get('languagePreference').slice(1)}`;
      await this.conn.sendQuestionTo(questionId, this.chatUser.get('username'), context);
    } catch (error) {
      const questionId = 'consentFormFinasterideMaleEn';
      await this.conn.sendQuestionTo(questionId, this.chatUser.get('username'), context);
    }
    try {
      await this.conn.sendQuestionTo('consentFormFinasterideAcknowledgement', this.chatUser.get('username'), context);
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'Some Problem in Sending Finasteride Consent Form',
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  changePhoto(instantCheckup: any, index: number): void {
    if (index === 0) {
      this.instantCheckups.splice(1, 1, instantCheckup);
    }
    this.comparisonSliderConfig[index].id2 = instantCheckup.objectId;
  }

  showFullSizeImage(index: number): void {
    if (this.activateFullSizeImageIndex === index) this.activateFullSizeImageIndex = -1;
    else this.activateFullSizeImageIndex = index;
  }

  showToastMessage(message: string, type: string = this.appConfig.Shared.Toast.Type.ERROR): void {
    this.disableSendButton = false;
    this.broadcaster.broadcast('NOTIFY', { message, type });
  }

  onChangeTreatmentOutcome(): void {
    delete this.treatmentOutcome.subSelectedTreatmentOutcome;
    switch (this.treatmentOutcome.selectedTreatmentOutcome) {
      case 'MAIN_CONCERN_REDUCING': {
        this.reportCompletionStatus.treatmentOutcome = false;
        this.subTreatmentOutcomeOptions = [
          { display: 'Well as expected', value: 'WELL_AS_EXPECTED' },
          { display: 'Shifting to secondary concern', value: 'SHIFTING_TO_SECONDARY_CONCERN' },
          { display: 'Shifting to maintenance', value: 'SHIFTING_TO_MAINTENANCE' },
          { display: 'Slower than expected ', value: 'SLOWER_THAN_EXPECTED' },
        ];
        break;
      }
      case 'CONDITION_REMAINS_SAME': {
        this.reportCompletionStatus.treatmentOutcome = true;
        this.subTreatmentOutcomeOptions = [];
        break;
      }
      case 'MAIN_CONCERN_INCREASING': {
        this.reportCompletionStatus.treatmentOutcome = false;
        this.subTreatmentOutcomeOptions = [
          { display: 'Slightly increasing', value: 'SLIGHTLY_INCREASING' },
          { display: 'Flare up', value: 'FLARE_UP' },
        ];
        break;
      }
      default:
    }
  }

  onChangeSubtreatmentOutcome(): void {
    this.reportCompletionStatus.treatmentOutcome = true;
  }
}
