import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../../components/directives.module';
import { TreeListComponent } from './tree-list.component';

const ROUTES: Routes = [
  { path: '', component: TreeListComponent, pathMatch: 'full' },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  DirectivesModule,
  RouterModule.forChild(ROUTES),
],
declarations: [TreeListComponent],
exports: [TreeListComponent] })
export class TreeListModule {}
