import { Component, NgZone, OnDestroy } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { ApiClientConstant, ParseKeys, RequestQueryPayload, Table } from 'api-client';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';
import { ChangeLeaveStatus } from '../../../../components/change-leave-status';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  ui: any;
  count: number;
  resetDoctorFilter: boolean;
  resetOperatorFilter: boolean;
  resetLeavesFilter: boolean;
  doctorFilter: Array<string> = [];
  operatorFilter: Array<string> = [];
  leavesFilter: Array<string> = [];
  approvedLeavesList: Array<any> = [];
  currentUser: any;
  changingLeaveStatusList: Array<any> = [];
  constructor(private conn: ConnectionService,
    private zone: NgZone,
    private windowRef: WindowRefService,
    private dialog: MatDialog,
    public appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<void> {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.currentUser = this.conn.getCurrentUser();
    await this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Name',
      field: 'user.username',
      width: 250,
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        let className;
        if (params.data?.status === ApiClientConstant.LeaveAndHoliday.STATUS.APPROVED) className = 'fa fa-circle mLR5 text-success';
        else if (params.data?.status === ApiClientConstant.LeaveAndHoliday.STATUS.REJECTED) className = 'fa fa-circle mLR5 text-danger';
        else className = 'fa fa-circle mLR5 text-warning';
        eDiv.innerHTML = `<span class='${className}'></span><span>${params.value ?? 'Public Holiday'}</span>`;
        return eDiv;
      },
      editable: true,
    }, {
      headerName: 'Edit',
      width: 70,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const id = params.value || params.data.objectId;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.style = 'display: flex; align-items: center; justify-content: space-around; gap : 4px; margin-top: 5px;  ';
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pointer' href='/users/leaves/${id}/edit'></a>`;
        if (params.data?.status === this.appConfig.Shared.LeaveAndHoliday.STATUS.PENDING_APPROVAL) {
          eDiv.innerHTML += '<input style="min-height: unset !important; margin:unset !important" type="checkbox"'
            + '[checked]=true>';
          eDiv.addEventListener('click', async () => {
            const index = this.changingLeaveStatusList.indexOf(params.data.objectId);
            if (index === -1) {
              this.changingLeaveStatusList.push(params.data.objectId);
            } else {
              this.changingLeaveStatusList.splice(index, 1);
            }
          });
        }
        return eDiv;
      },
    }, {
      headerName: 'Holiday/Leave',
      field: 'holidayType',
      width: 200,
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value ?? 'Leave';
        return eDiv;
      },
      editable: true,
    }, {
      headerName: 'Start Time',
      field: 'startTime',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value.iso).format('MMM DD,YYYY');
        return eDiv;
      },
      sortable: true,
      editable: true,
    }, {
      headerName: 'End Time',
      field: 'endTime',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value.iso).format('MMM DD,YYYY');
        return eDiv;
      },
    }, {
      headerName: 'Remaining Leaves',
      field: 'user.username',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.value) return '-';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        const user = this.approvedLeavesList.find((value: any) => value.username === params.value);
        if (!user) {
          this.conn.getNumberOfApprovedLeavesForUserDuringLastYear(params.value).then((data: number) => {
            this.approvedLeavesList.push({ username: params.value, approvedLeaves: data });
            eDiv.innerHTML = (params.data.user.type === ApiClientConstant.User.Type.OPERATOR
              ? this.appConfig.Shared.LeaveAndHoliday.MAX_OPERATOR_NO_OF_LEAVES
              : this.appConfig.Shared.LeaveAndHoliday.MAX_DOCTOR_NO_OF_LEAVES) - data;
          });
        } else {
          eDiv.innerHTML = (params.data.user.type === ApiClientConstant.User.Type.OPERATOR
            ? this.appConfig.Shared.LeaveAndHoliday.MAX_OPERATOR_NO_OF_LEAVES
            : this.appConfig.Shared.LeaveAndHoliday.MAX_DOCTOR_NO_OF_LEAVES) - user.approvedLeaves;
        }
        return eDiv;
      },
      sortable: true,
    }, {
      headerName: 'Request Time',
      field: 'createdAt',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('MMM DD,YYYY');
        return eDiv;
      },
      sortable: true,
    }];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.LeaveAndHoliday> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['user'],
      project: ['startTime', 'endTime', 'user.DoctorDisplayName' as 'user', 'holidayType', 'status',
        'user.username' as 'user', 'user.type' as 'user'],
      descending: 'createdAt',
    };
    const { colId, sort }: { colId: ParseKeys<Table.LeaveAndHoliday>, sort: 'asc' | 'desc' } = params.sortModel[0]
      || { colId: 'createdAt', sort: 'desc' };
    if (sort === 'desc') {
      requestPayload.descending = colId;
    } else {
      requestPayload.ascending = colId;
    }
    if (this.doctorFilter.length || this.operatorFilter.length) {
      requestPayload.where.user = { $in: [...this.doctorFilter, ...this.operatorFilter] };
    }
    if (this.leavesFilter.length) {
      requestPayload.where.status = { $in: [...this.leavesFilter] };
    }
    let data;
    [data, this.count] = await Promise.all([
      this.conn.findLeavesAndHolidays(requestPayload).then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      this.conn.countLeavesAndHolidays(requestPayload),
    ]);
    return data;
  }

  updateDoctorFilter(doctorFilter: Array<any>): void {
    this.doctorFilter = doctorFilter;
    this.reset();
  }

  updateOperatorFilter(operatorFilter: Array<any>): void {
    this.operatorFilter = operatorFilter;
    this.reset();
  }

  updateLeavesFilter(leavesFilter: Array<any>): void {
    this.leavesFilter = leavesFilter;
    this.reset();
  }
  resetFilters(): void {
    this.resetDoctorFilter = true;
    this.resetOperatorFilter = true;
    this.resetLeavesFilter = true;
  }
  openStatusDialog(): void {
    const dialogRef = this.dialog.open(ChangeLeaveStatus, {
      data: { leavesList: this.changingLeaveStatusList },
    });
    dialogRef.afterClosed().subscribe(async (data: any): Promise<any> => {
      if (!data) return;
      this.clearAllSelectedLeaves();
    });
  }
  clearAllSelectedLeaves(): void {
    this.changingLeaveStatusList = [];
    this.reset();
  }
  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
  }
}
