import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiClientConstant, Table } from 'api-client';
import { AppConfig } from '../../app.config';
import { ConnectionService } from '../../../services/connection-service';

@Component({
  selector: 'tree-view',
  templateUrl: './view.html',
  styleUrls: ['./view.scss'],
})
export class TreeViewComponent implements OnDestroy {
  treeObj: any;
  answerDependentTree: any;
  answerIndependentTree: any;
  regimenObjTree: any;
  indicationTrees: Array<any>;
  treatmentDeliveryTrees: Array<any>;
  actionTrees: Array<any>;
  subscriptions: Array<Subscription>;
  treeVersion: string;
  constructor(private route: ActivatedRoute, private appConfig: AppConfig, private conn: ConnectionService) {
  }

  ngOnInit(): void {
    this.answerIndependentTree = [];
    this.answerDependentTree = [];
    this.subscriptions = [];
    this.actionTrees = [];
    this.indicationTrees = [];
    this.treatmentDeliveryTrees = [];
    this.treeObj = new Table.Tree();
    this.regimenObjTree = new Table.Tree();
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe((params: any) => {
      if (this.route.parent.snapshot.data.tree) {
        this.treeObj = this.route.parent.snapshot.data.tree;
        this.treeVersion = params.name;
        this.fetchIndicationTrees(params.name);
        this.fetchActionTrees(params.name);
        this.fetchPostRegimenTree(params.name);
        this.fetchAnswerDependentTree(params.name);
        this.fetchAnswerIndependentTree(params.name);
        this.fetchTreatmentDeliveryTree(params.name);
      }
    }));
  }

  fetchIndicationTrees(primaryTreeName: string): void {
    this.conn.findTree({
      where: {
        type: ApiClientConstant.Tree.Type.INDICATION,
        name: { $regex: `${primaryTreeName}_`, $options: 'i' },
      },
      project: ['name'],
    }).then((indicationTrees: Array<any>) => (this.indicationTrees = indicationTrees));
  }

  fetchActionTrees(primaryTreeName: string): void {
    this.conn.findTree({
      where: {
        type: ApiClientConstant.Tree.Type.ACTION,
        name: { $regex: `${primaryTreeName}_`, $options: 'i' },
      },
      project: ['name'],
    })
      .then((actionTrees: Array<any>) => (this.actionTrees = actionTrees));
  }

  fetchPostRegimenTree(primaryTreeName: string): void {
    this.conn.findOneTree({
      where: {
        name: `${primaryTreeName}_regimen`,
        type: ApiClientConstant.Tree.Type.POST_REGIMEN,
      },
      include: ['questions'],
      project: ['name', 'questions.uniqueIdentifier' as 'questions', 'questions.title' as 'questions'],
    }).then((regimenTree: Array<any>) => {
      if (!regimenTree) return;
      this.regimenObjTree = regimenTree;
    });
  }
  fetchAnswerIndependentTree(primaryTreeName: string): void {
    this.conn.findTree({
      where: {
        type: ApiClientConstant.Tree.Type.ANSWER_INDEPENDENT,
        name: { $regex: `${primaryTreeName}_`, $options: 'i' },
      },
      project: ['name'],
    })
      .then((tree: Array<any>) => {
        if (!tree) return;
        this.answerIndependentTree = tree;
      });
  }
  fetchAnswerDependentTree(primaryTreeName: string): void {
    this.conn.findTree({
      where: {
        type: ApiClientConstant.Tree.Type.ANSWER_DEPENDENT,
        name: { $regex: `${primaryTreeName}_`, $options: 'i' },
      },
      project: ['name'],
    })
      .then((tree: Array<any>) => {
        if (!tree) return;
        this.answerDependentTree = tree;
      });
  }
  fetchTreatmentDeliveryTree(primaryTreeName: string): void {
    this.conn.findTree({
      where: {
        type: ApiClientConstant.Tree.Type.TREATMENT_DELIVERY,
        name: { $regex: `${primaryTreeName}_`, $options: 'i' },
      },
      project: ['name'],
    })
      .then((treatmentDeliveryTrees: Array<any>) => (this.treatmentDeliveryTrees = treatmentDeliveryTrees));
  }
  createTree(dependentStatus: boolean): void {
    const tree = new Table.Tree();
    const person = prompt('Enter the tree name');
    if (person != null) {
      if (this.checkTreeAlreadyHasPrefix(person)) {
        tree.set('name', person);
      } else {
        tree.set('name', `${this.treeVersion}_${person}`);
      }
      tree.set('nodeMap', []);
      if (dependentStatus) {
        tree.set('type', ApiClientConstant.Tree.Type.ANSWER_DEPENDENT);
        tree.save()
          .then((treeObj: any) => this.answerDependentTree.push(treeObj))
          .catch((err: any) => alert('duplicate tree name'));
      } else {
        tree.set('type', ApiClientConstant.Tree.Type.ANSWER_INDEPENDENT);
        tree.save()
          .then((treeObj: any) => this.answerIndependentTree.push(treeObj))
          .catch((err: any) => alert('duplicate tree name'));
      }
    }
  }

  checkTreeAlreadyHasPrefix(treeName: string): boolean {
    const pattern = /^v\d+_/;
    return pattern.test(treeName);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.route;
    delete this.appConfig;
    delete this.conn;
    delete this.subscriptions;
    delete this.treeObj;
  }
}
