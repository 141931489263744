
<div class="flex-box">
  <div class="flex-heading">
    <div class="list-header col-xs-12"><span class="pR20">Regimens  ({{count || 0}})</span>
      <div class="pull-right"><i class="fa fa-plus-square-o pointer" routerLink="/products/regimens/new"></i></div>
    </div>
  </div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-primary-outline" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content p-0">
          <div class="flex px-3 mb-3">
            <label class="mr-3">Auto Refresh</label>
            <label class="i-switch i-switch-sm bg-success">
              <input type="checkbox" [(ngModel)]="autoRefresh" (ngModelChange)="toggleAutoRefresh()" name="autoRefresh"/><i></i>
            </label>
          </div>
          <div class="flex px-3 bdr-top mb-3">
            <label class="mr-3">Active</label>
            <label class="i-switch i-switch-sm bg-success">
              <input type="checkbox" [(ngModel)]="activeRegimen" (ngModelChange)="toggleActiveRegimen()" name="autoRefresh"/><i></i>
            </label>
          </div>
          <div class="col-xs-12 px-3">
            <language-string-tag-selection [parseObj]="filterByTag" name="tags" (onValueChange)="reset()"></language-string-tag-selection>
          </div>
          <div class="px-3">
            <label class="db">Search</label>
            <input class="w100" placeholder="Title, Id, Class, SkinType" (change)="reset()" [(ngModel)]="searchKey"/>
          </div>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>