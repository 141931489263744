import { Component, NgZone, OnDestroy } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  searchKey: string;
  dataSource: any;
  components: any;
  columnDefs: any;
  articles: any;
  ui: any;
  count: number;
  constructor(private conn: ConnectionService,
              private zone: NgZone,
              private windowRef: WindowRefService) {
  }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
    this.resetFilters();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 70,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const id = params.value || params.data.objectId;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pointer' target="_blank" href='/marketing/article/${id}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'Title',
      field: 'title',
      filter: true,
      cellRenderer: (params: any): any => {
        if (!params.value) {
          const eDiv = this.windowRef.nativeWindow.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='no-padder ellipsis' target="_blank" href='/marketing/article/${
          params.data.objectId}'>${params.value}</a>`;
        return eDiv;
      },
      width: 450,
    }, {
      headerName: 'Template',
      field: 'template',
      filter: true,
      editable: true,
    }, {
      headerName: 'Action',
      field: 'clickToAction',
      filter: true,
      editable: true,
    }];
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<Array<any>> {
    const searchOn = ['title'];
    const requestPayload: RequestQueryPayload<Table.ArticleData> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      project: ['title', 'clickToAction', 'titleLanguageString', 'template'],
      ascending: 'createdAt',
    };
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((key: any) => {
        if (key === 'title') {
          requestPayload.where[key] = { $regex: params.filterModel[key].filter, $options: 'i' };
        } else {
          requestPayload.where[key] = params.filterModel[key].filter;
        }
      });
    }
    let data;
    [data, this.count] = await Promise.all([
      this.conn.findArticleData(requestPayload).then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      this.conn.countArticleData(requestPayload),
    ]);
    return data;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.articles;
  }
}
