
<div class="hbox" *ngIf="!loading">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/chats/open-tickets">Support Tickets</a></li>
          <li>{{supportTicketId ? 'Edit' : 'New'}}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content">
      <form class="col-xs-12 p-4" #supportTicketForm="ngForm">
        <div class="col-xs-12 flex flex-wrap justifySB">
          <div class="col-xs-5">
            <label class="col-xs-12 mt-0">title</label>
            <div class="col-xs-12">
              <language-string-selection class="col-xs-12" [parseObj]="supportTicketObject" [tags]="['support_question', 'support_category']" name="titleLanguageString"></language-string-selection>
            </div>
          </div>
          <div class="col-xs-5">
            <label class="col-xs-12 mt-0">Title</label>
            <div class="col-xs-12">
              <input-text class="col-xs-12" [parseObj]="supportTicketObject" name="title" placeholder="title" [required]="supportTicketObject?.get('supportQuestion')?.get('questionLanguageString')?.get('en') == 'Others'"></input-text>
            </div>
          </div>
          <div class="col-xs-5">
            <label class="col-xs-12">User Id</label>
            <div class="col-xs-12">
              <input class="col-xs-12" [(ngModel)]="userId" name="User" placeholder="User ObjectId" (ngModelChange)="onUpdateUser()" required="required"/>
            </div>
          </div>
          <div class="col-xs-5" *ngIf="languagePreference">
            <label class="col-xs-12">Language Preference</label>
            <div class="col-xs-12">
              <select class="col-xs-12" [(ngModel)]="languagePreference" name="languagePreference">
                <option *ngFor="let option of languageOptions" [selected]="option.value == languagePreference" [value]="option.value">{{option.display}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-5">
            <label class="col-xs-12">Support Question</label>
            <div class="col-xs-12">
              <input-select [parseObj]="supportTicketObject" name="supportQuestion" [defaultSelect]="supportTicketObject?.get('supportQuestion')?.get('questionLanguageString')?.get('en')" [options]="supportQuestionOptions" [required]="true"></input-select>
            </div>
          </div>
          <div class="col-xs-5" *ngIf="(team && supportTicketId) || !supportTicketId">
            <label class="col-xs-12">Teams</label>
            <div class="col-xs-12">
              <select class="col-xs-12" [(ngModel)]="team" (ngModelChange)="onUpdateTeam()" name="teams">
                <option *ngFor="let option of teams" [selected]="option == team" [value]="option">{{option}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-5 flex justifyFS">
            <label class="mr-2">Disable Chat :</label>
            <input-checkbox [parseObj]="supportTicketId" name="disableChat"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <button class="db" [disabled]="!supportTicketForm.valid" (click)="saveSupportTicket()" *ngIf="!waitForResponse">{{supportTicketId ? 'Update' : 'Create'}}</button>
          <p class="alert-warning w-half mt-3" *ngIf="waitForResponse">* Wait Support Ticket Create/Update is In Process</p>
        </div>
      </form>
    </div>
  </div>
</div>
