import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'order-info-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-info-header.component.html',
  styleUrl: './order-info-header.component.scss',
})
export class OrderInfoHeaderComponent {
  @Input('orderObj') orderObj: any;
}
