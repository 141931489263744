import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApiClientConstant, ApiConnector, RequestQueryPayload, Table } from 'api-client';
import moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'dashboard-monthly-growth',
  templateUrl: './monthly-growth.html',
  styleUrls: ['./monthly-growth.scss'],
  providers: [DatePipe],
})
export class MonthlyGrowthComponent implements OnChanges, OnInit {
  @Input() startDate: Date;
  @Input() endDate: Date;
  ordersGroupedByDate: { [date: string]: { date: string, count: number } } = {};
  maxOrder: number = 30;
  scale: number = 0;

  constructor(private datePipe: DatePipe) {}

  async ngOnInit(): Promise<void> {
    await this.getMonthlyOrderGrowth();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.startDate && changes.endDate) {
      if (this.startDate && this.endDate) {
        await this.getMonthlyOrderGrowth();
      }
    }
  }

  async getMonthlyOrderGrowth(): Promise<void> {
    const payload: RequestQueryPayload<Table.Order> = {
      where: {
        isPartialOrder: true,
        type: ApiClientConstant.Order.Type.REGIMEN,
        $and: [
          { createdAt: { $gte: moment(this.startDate).toDate() } },
          { createdAt: { $lte: moment(this.endDate).toDate() } },
        ],
      },
      include: ['createdAt'],
      project: ['createdAt'],
    };
    const query = await ApiConnector.find(Table.Order, payload);
    this.ordersGroupedByDate = {};
    query.forEach((order: any) => {
      const date = moment(order.createdAt).format('YYYY-MM-DD');
      if (!this.ordersGroupedByDate[date]) {
        this.ordersGroupedByDate[date] = { date, count: 0 };
      }
      this.ordersGroupedByDate[date].count += 1;
    });
  }

  getYAxisLabels(): number[] {
    const labels = [];
    const yLabelsCount = 10;
    for (let i = 0; i <= yLabelsCount; i + 1) {
      labels.push(Math.round((this.maxOrder / yLabelsCount) * i));
      this.scale = Math.round((this.maxOrder) / yLabelsCount);
    }
    return labels;
  }

  getDateKeys(): string[] {
    return Object.keys(this.ordersGroupedByDate);
  }

  getFormattedDate(date: string): string {
    return this.datePipe.transform(date, 'dd MMM');
  }
}
