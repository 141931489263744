import { Component, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { ApiClientConstant, Table, RequestQueryPayload } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { ConnectionService } from '../../../services/connection-service';
import { Broadcaster } from '../../../components/broadcaster';
import { AppConfig } from '../../app.config';
import { WindowRefService } from '../../../services/window-ref-service';
import { LocalStorage } from '../../../services/local-storage-service';

@Component({ selector: 'language-string-list', templateUrl: './language-string-list.html' })
export class LanguageStringListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  filter: any = { tags: [] };
  ui: any;
  count: any;
  searchKey: any;
  window: any;
  languageKeys: string[];
  userRoles: string[];
  @ViewChild('agGrid') agGrid: AgGridAngular;
  searchOn: ValueOf<typeof ApiClientConstant.LanguageString> = ApiClientConstant.LanguageString.EN;
  constructor(private route: ActivatedRoute,
              private conn: ConnectionService,
              private appConfig: AppConfig,
              private broadcast: Broadcaster,
              private router: Router,
              private windowRef: WindowRefService,
              private renderer: Renderer2,
              private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  async ngOnInit(): Promise<any> {
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.ui = { isFilterOpen: false, grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
    await this.route.queryParams.subscribe(async (queryParams: Params) => {
      if (queryParams.tagId) {
        const tagObj = await this.conn.getLanguageTagByObjectId(queryParams.tagId);
        this.filter.tags.push(tagObj);
      }
      this.reset();
    });
    this.languageKeys = Object.values(ApiClientConstant.LanguageString);
    this.agGrid.columnApi.setColumnsVisible([...this.languageKeys], false);
    this.agGrid.columnApi.setColumnsVisible([this.searchOn], true);
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const data = await this.loadMore(params);
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 70,
      cellRenderer: (params: any): any => {
        let eDiv;
        if (!params.data) {
          eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const { data }: any = params;
        eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pR10' target="_blank" href='../languageString/${
          data.objectId}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'Updated At',
      field: 'updatedAt',
      width: 150,
      filter: true,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('MMM DD, YYYY');
        return eDiv;
      },
    }, {
      headerName: 'Created By',
      field: 'createdBy',
      width: 150,
      filter: true,
      cellRenderer: (params: any): any => {
        if (!params.value) return 'old data';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value.username;
        return eDiv;
      },
    }, {
      headerName: 'EN',
      field: 'en',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${this.escape(params.value)}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }, {
      headerName: 'HI',
      field: 'hi',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }, {
      headerName: 'TE',
      field: 'te',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }, {
      headerName: 'KN',
      field: 'kn',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a target="_blank" href='../languageString/${data.objectId}'>${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }];
  }

  async onTagUpdate(): Promise<any> {
    await this.reset();
  }

  ngOnDestroy(): void {
  }

  changeSearchLanguage(): void {
    this.agGrid.columnApi.setColumnsVisible(this.languageKeys, false);
    this.agGrid.columnApi.setColumnsVisible([this.searchOn], true);
    this.agGrid.columnApi.setColumnsVisible([ApiClientConstant.LanguageString.EN], true);
    this.reset();
  }

  async deleteLanguageString(value: any): Promise<void> {
    try {
      const deleteObj = new Table.LanguageString();
      deleteObj.id = value;
      await deleteObj.destroy();
      await this.reset();
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async reset(): Promise<any> {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  private async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.LanguageString> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      project: [ApiClientConstant.LanguageString.EN, this.searchOn, 'createdBy.username' as 'createdBy'],
      include: ['createdBy'],
      descending: 'createdAt',
    };

    if (this.searchKey) {
      requestPayload.where.$or = [this.searchOn].map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    if (this.filter.tags.length) {
      requestPayload.where.tags = { $in: this.filter.tags };
    }
    let data;
    [data, this.count] = await Promise.all([
      this.conn.findLanguageStrings(requestPayload)
        .then((result: Array<any>) => result.map((each: any) => each.toJSON())),
      this.conn.countLanguageStrings(requestPayload),
    ]);
    return data;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  resetFilters(): void {
    this.searchOn = ApiClientConstant.LanguageString.EN;
    this.agGrid.columnApi.setColumnsVisible(this.languageKeys, false);
    this.agGrid.columnApi.setColumnsVisible([this.searchOn], true);
    this.filter.tags.splice(0, this.filter.tags.length);
    delete this.searchKey;
    this.reset();
  }

  escape(htmlStr: string): string {
    return htmlStr.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
  }
}
