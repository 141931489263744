
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading" #headingView>
    <div class="list-header col-xs-12"><span class="mr-2">Scheduled Events  ({{count || 0}})</span><span class="fa fa-refresh mr-2" (click)="reset()"></span>
      <language-filter *ngIf="ui.enableLanguageFilter" (updatedLanguageList)="updateLanguageFilter($event)"></language-filter>
      <operator-filter *ngIf="ui.enableOperatorFilter" (updatedOperatorList)="updateOperatorFilter($event)" [filter]="{ operatorAllocationFactor: { $gt: 0 } }"></operator-filter>
    </div>
  </div>
  <div class="flex-content">
    <div class="list-view">
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" #agGrid style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
