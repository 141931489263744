import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MomentModule } from 'ngx-moment';
import { DirectivesModule } from '../directives.module';
import { FollowupChatViewComponent } from './followup-chat-view.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MomentModule,
    DirectivesModule,
    MatExpansionModule,
  ],
  declarations: [
    FollowupChatViewComponent,
  ],
  exports: [
    FollowupChatViewComponent,
  ],
})
export class FollowupChatViewModule {
}
