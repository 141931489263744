<div class="notes-container">
  <div class="flex-box fixed">
    <div class="heading">Notes for doctor</div>
    <img src="../../../../assets/images/add.svg" alt="" class="icon pointer" (click)="addNew = true;">
  </div>

  <div class="mt-2" *ngIf="addNew">
    <textarea class="form-control text-area" [(ngModel)]="noteMessage" style="min-height:75px;"></textarea>
    <button class="small mt-2" [disabled]="!noteMessage" (click)="addNote()">Save</button>
    <button class="btn-xs fontS3 btn-danger mt-2 ml-1" (click)="addNew = false">Cancel</button>
  </div>
  <ng-container *ngFor="let note of notes">
    <div class="notes-cell-container">
      <div class="flex-box">
        <div class="date">{{note.get('createdAt') | amDateFormat: 'DD MMM YY, hh:mm A'}}</div>
        <div class="flex">
          <div class="dr-name">{{note.get('Name')}}</div>
          <img src="/assets/images/steth.svg" alt="" class="icon">
        </div>
        <div class="concern-txt">{{note.get('message')}}</div>
      </div>
      <hr>
    </div>
  </ng-container>
</div>