import { Component, Inject } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../../../../app.config';

@Component({
  selector: 'catalog-select-instruction-to-copy-view',
  templateUrl: './catalog-select-instruction-to-copy.html',
})
export class CatalogSelectInstructionToCopyComponent {
  instructions: Array<any> = [];

  constructor(private dialogRef: MatDialogRef<CatalogSelectInstructionToCopyComponent>,
    public appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.instructions = this.data.catalog.get('instructions');
    this.instructions.forEach((each_: any) => {
      const each = each_;
      each.selected = true;
    });
  }

  copyInstructions(): void {
    const selectedInstructions = this.instructions.filter((each: any) => each.selected);
    selectedInstructions.forEach((each_: any) => {
      const each = each_;
      each.id = uuid();
      delete each.selected;
    });
    this.dialogRef.close(selectedInstructions);
  }
}
