import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({
  selector: 'list',
  templateUrl: './list.html',
})
export class ListComponent implements OnDestroy {
  // Local Variables
  data: any = [];

  // List View Variables
  length: number = 0;
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  searchKey: string;

  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    public windowRef: WindowRefService,
    private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.listViewSetup();
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Edit',
        width: 50,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) return '';
          const id = params.value || params.data.objectId;
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<a class='fa fa-pencil-square-o', href='${url.join('/')}/trigger-by-keyword/${id}/edit'></a>`;
          return eDiv;
        },
      },
      {
        headerName: 'TreeName',
        width: 150,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) return '';
          const id = params.value || params.data.objectId;
          const data = params.value || params.data.treeName;
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<a  href='${url.join('/')}/trigger-by-keyword/${id}'>${data}</a>`;
          return eDiv;
        },
      },
      {
        headerName: 'Priority',
        width: 100,
        field: 'priority',
      },
      {
        headerName: 'Positive Keywords',
        width: 600,
        field: 'positive',
      },
      {
        headerName: 'Negative Keywords',
        width: 600,
        field: 'negative',
      },
      {
        headerName: 'Paid User',
        width: 100,
        field: 'paidUser',
      },
    ];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadData(params: any): Promise<any> {
    const data: Array<any> = JSON.parse(JSON.stringify(await this.conn.fetchTriggerByKeyword()));
    this.length = data.length;
    return data;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.data;
    delete this.ui;
  }
}
