import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConnectionService } from '../../../services/connection-service';
import { MetabaseServices } from '../../../services/metabaseServices';
import { AppConfig } from '../../app.config';
import { Broadcaster } from '../../../components/broadcaster';

@Component({
  selector: 'initial-calls',
  templateUrl: './initial-calls.html',
})

export class InitialCallsComponent {
  iframeLink: SafeResourceUrl;
  type: string;
  subscriptions: Array<Subscription> = [];
  user: any;
  rangeStartDate: Date;
  rangeEndDate: Date;
  params: { [key: string]: string | Date } = {};
  constructor(private route: ActivatedRoute,
    private conn: ConnectionService,
    private appConfig: AppConfig,
    private broadcaster: Broadcaster,
    private domSanitizer: DomSanitizer,
    private metabaseServices: MetabaseServices) {
    this.user = this.conn.getCurrentUser();
  }

  async ngOnInit(): Promise<void> {
    this.type = this.route.snapshot.queryParams.type;
    if (this.type) {
      this.getMetabaseQuestion({ linkName: this.type });
    }
  }

  async applyDateRange(startDate: HTMLInputElement, endDate: HTMLInputElement): Promise<void> {
    this.rangeStartDate = startDate.valueAsDate;
    this.rangeEndDate = endDate.valueAsDate;
    this.params = {
      linkName: this.type,
      from: this.rangeStartDate,
      to: this.rangeEndDate,
      operatorId: this.user.id,
    };
    await this.getMetabaseQuestion(this.params);
  }

  async getMetabaseQuestion(params: { [key: string]: string | Date }): Promise<void> {
    try {
      const newUrl = String(await this.metabaseServices.fetchMetabaseQuestion(params));
      this.iframeLink = this.domSanitizer.bypassSecurityTrustUrl(newUrl);
    } catch (error: any) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  resetDateRange(_startDate: HTMLInputElement, _endDate: HTMLInputElement): void {
    const startDate = _startDate;
    const endDate = _endDate;
    startDate.value = '';
    endDate.value = '';
    this.applyDateRange(startDate, endDate);
  }
}
