import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleComponent } from './article.component';
import { ArticleViewModule } from './view';
import { ListModule } from './list';
import { ArticleEditModule } from './edit';
import { ArticleResolve } from './article-resolver';

const ROUTES: Routes = [
  {
    path: 'article',
    component: ArticleComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'article/new',
    component: ArticleComponent,
    loadChildren: (): any => ArticleEditModule,
    pathMatch: 'full',
  },
  {
    path: 'article/:id',
    component: ArticleComponent,
    loadChildren: (): any => ArticleViewModule,
    resolve: { article: ArticleResolve },
    pathMatch: 'full',
  },
  {
    path: 'article/:id/edit',
    component: ArticleComponent,
    loadChildren: (): any => ArticleEditModule,
    resolve: { article: ArticleResolve },
    pathMatch: 'full',
  },
];

@NgModule({ providers: [ArticleResolve],
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [ArticleComponent],
  exports: [ArticleComponent] })
export class ArticleModule {}
