import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AppConfig } from 'src/app/app.config';
import { MetabaseServices } from '../../../services/metabaseServices';
import { Broadcaster } from '../../../components/broadcaster';

@Component({ selector: 'order-ship-now', templateUrl: './ship-now.html' })
export class ShipNowComponent {
  type: string;
  iframeLink: SafeResourceUrl;
  subscriptions: Array<Subscription> = [];

  constructor(
    private route: ActivatedRoute,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
    private metabaseServices: MetabaseServices) {
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(this.route.queryParams.subscribe(async (queryParams: Params) => {
      try {
        this.iframeLink = await this.metabaseServices.fetchMetabaseQuestion({ linkName: queryParams.type });
      } catch (error: any) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    }));
  }
}
