import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Table } from 'api-client';
import { MomentModule } from 'ngx-moment';
import { AppConfig } from 'src/app/app.config';
import { Broadcaster } from 'src/components/broadcaster';
import { ConnectionService } from 'src/services/connection-service';

@Component({
  selector: 'sales-doctor-notes',
  standalone: true,
  imports: [CommonModule, MomentModule, FormsModule],
  templateUrl: './doctor-notes.component.html',
  styleUrl: './doctor-notes.component.scss',
})
export class SalesDoctorNotesComponent {
  @Input('username') username: string;
  @Input('user') user: any;
  addNew: boolean = false;
  notes: any = [];
  noteMessage: string;
  newNote: any = new Table.Note();

  constructor(private conn: ConnectionService,
    private appConfig: AppConfig,
    private broadcaster: Broadcaster,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.loadNotes();
  }

  async loadNotes(): Promise<void> {
    this.notes = await this.conn.findNotes({
      where: { userId: this.username },
      project: ['Name', 'message'],
      descending: 'createdAt',
    });
  }

  async addNote(): Promise<void> {
    const currentUser = await this.conn.getCurrentUser();
    if (!this.newNote.id) {
      this.newNote.set('userId', this.username);
      this.newNote.set('AddedBy', currentUser);
      this.newNote.set('Name', currentUser.get('username'));
      this.newNote.set('type', this.appConfig.Notes.Type.MEDICAL);
    }
    try {
      this.newNote.set('message', this.noteMessage);
      await this.newNote.save();
      delete this.noteMessage;
      this.addNew = false;
      this.newNote = new Table.Note();
      await this.loadNotes();
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', {
        message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }
}
