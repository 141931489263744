
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Voice Note</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal(false)"></i>
  </div>
  <div class="modal-body">
    <form role="form" name="voiceNoteForm" #voiceNoteForm="ngForm">
      <div class="col-xs-12 flex">
        <label class="mr-3 mb-0 mt-0">Upload By URL
          <label class="i-switch i-switch-sm bg-success m-0 ml-3">
            <input type="checkbox" [(ngModel)]="updateAudioTemplateByURL" name="updateByURL"/><i></i>
          </label>
        </label>
      </div>
      <div class="col-xs-12 col-md-10" *ngFor="let language of languages">
        <label class="col-xs-12 mt-0">{{language}}</label>
        <ng-container *ngIf="updateAudioTemplateByURL">
          <button class="btn-xs ml-3" [disabled]="!voiceNoteUrl[language]"><a [href]="getSignedUrl(language)" target="_blank">play</a></button>
          <input class="col-xs-8" type="text" [(ngModel)]="voiceNoteUrl[language]"/>
        </ng-container>
        <ng-container *ngIf="!updateAudioTemplateByURL">
          <div class="col-xs-12">
            <input class="col-xs-6" type="file" (change)="uploadFiles($event.target.files[0], language)"/>
            <button class="btn-xs ml-3" (click)="uploadToAws(language)" [disabled]="!files[language]">upload</button>
            <button class="btn-xs ml-3" [disabled]="!voiceNoteUrl[language]"><a [href]="getSignedUrl(language)" target="_blank">play</a></button><i class="fa fa-trash-o ml-3" (click)="removeLanguage(language)"></i>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" (click)="hideModal(true)">update</button>
  </div>
</mat-dialog-content>