import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CartEditComponent } from './cart-edit.component';
import { DirectivesModule } from '../../../components/directives.module';
import { OrderEditModule } from '../../order/edit';

const ROUTES: Routes = [
  {
    path: 'edit',
    component: CartEditComponent,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  DirectivesModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  RouterModule.forChild(ROUTES),
  OrderEditModule,
],
declarations: [CartEditComponent],
exports: [CartEditComponent] })
export class CartEditModule {}
