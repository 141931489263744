import { NgZone, Component, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
// import { RequestQueryPayload } from 'mongo-to-parse/src/transform/mongo-to-parse-query-base';
import { ApiClientConstant, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';
import { LocalStorage } from '../../../../services/local-storage-service';

@Component({
  selector: 'instant-checkup-list',
  templateUrl: './instant-checkup-list.html',
  styleUrls: ['./instant-checkup-list.scss'],
})
export class InstantCheckupListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  searchKey: string;

  // Local Variables
  data: Array<any> = [];
  operatorFilter: Array<any> = [];
  languageFilter: Array<any> = [];
  doctorFilter: Array<any> = [];
  teams: Array<string> = [];
  currentUser: any;
  resetOperatorFilter: boolean = false;
  resetDoctorFilter: boolean = false;
  resetLanguageFilter: boolean = false;
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  queueCount: number = 0;

  constructor(
    private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.listViewSetup();
    const roles = this.storage.getJsonValue('userRoles') || [];
    const teams = Object.keys(ApiClientConstant.Role.Name)
      .map((each: string) => ApiClientConstant.Role.Name[each]);
    this.teams = roles.filter((each: any) => teams.includes(each));
    this.route.queryParams.subscribe(() => this.reset());
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  findUserPriorityClasses(user: any): string {
    if (!user) {
      return '';
    }
    const orderState = user.orderState || ApiClientConstant.User.OrderState.NEW_USER;
    if (orderState === ApiClientConstant.User.OrderState.NEW_USER) {
      return '';
    }
    if (orderState === ApiClientConstant.User.OrderState.PROCESSING) {
      return 'fa fa-rupee mLR5 text-success';
    }
    if (orderState === ApiClientConstant.User.OrderState.CANCELED) {
      return 'fa fa-rupee mLR5 text-danger';
    }
    if (orderState === ApiClientConstant.User.OrderState.DELIVERED) {
      return moment(user.refreshDate.iso).isAfter(moment().subtract(4, 'month'))
        ? 'fa fa-rupee mLR5 text-success'
        : 'fa fa-rupee mLR5 text-warning';
    }
    return '';
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'UserId',
      field: 'userId',
      pinned: 'left',
      editable: true,
      cellRenderer: (params: any): any => {
        const url = this.router.url.split('/');
        let eDiv;
        url.splice(url.length - 1, 1);
        if (!params.data) {
          eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv = this.window.document.createElement('div');
        const className = this.findUserPriorityClasses(params.data.user);
        const id = params.value;
        eDiv.innerHTML = `<a href='/chat/${params.data.userId
          || params.data.user.username}' target='_blank'> <span class='${className}'></span>${id} (${this.conn.getUserHash(id)})</a>`;
        return eDiv;
      },
      width: 370,
    }, {
      headerName: 'Created At',
      field: 'createdAt',
      width: 200,
    }, {
      headerName: 'Issue Detected',
      field: 'type',
      width: 120,
      cellRenderer: (params: any): any => {
        const url = this.router.url.split('/');
        let eDiv;
        url.splice(url.length - 1, 1);
        if (!params.data) {
          eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv = this.window.document.createElement('div');
        const className = this.findUserPriorityClasses(params.data.user);
        const id = params.value;
        eDiv.innerHTML = `<a href='/user/instantCheckup/${params.data.objectId}?username=${params.data.userId}'
        target='_blank'> <span class='${className}'></span>${id}</a>`;
        return eDiv;
      },
      sortable: true,
    }];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.resetDoctorFilter = this.teams.includes(ApiClientConstant.Role.Name.DOCTOR);
    this.resetOperatorFilter = this.teams.includes(ApiClientConstant.Role.Name.CHAT_SUPPORT);
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadData(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.InstantCheckup> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: [],
      project: ['userId', 'type'],
      descending: 'createdAt',
    };
    const data = await this.conn.getInstantCheckup(requestPayload);
    return Promise.resolve(JSON.parse(JSON.stringify(data)));
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.data;
    delete this.ui;
  }
}
