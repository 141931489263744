
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/tree/questions">Tree</a></li>
          <li>{{ treeObj.id ? 'Edit' : 'New' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content col-xs-12 p-0">
      <form class="col-xs-12 p-4" #treeForm="ngForm" (submit)="saveTree()">
        <div class="form-group col-xs-12">
          <div class="col-xs-12">
            <label class="col-xs-4 mt-0">Name</label>
            <div class="col-xs-12">
              <input class="form-control" [(ngModel)]="tree.name" name="name" placeholder="" required="required" [disabled]="treeObj.id"/>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Class</label>
            <div class="col-xs-3">
              <select class="w100" [(ngModel)]="tree.class" name="treeClass" required="required">
                <option *ngFor="let className of treeClasses;" value="{{className}}">{{className}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-4">Questions<i class="fa fa-plus-square-o pointer ml-3" (click)="addQuestion()"></i></label>
            <div class="col-xs-12 my-2 flex" *ngFor="let item of tree.questions; let i = index;">
              <div class="col-xs-1">{{(i + 1)}}<i class="fa fa-trash-o pointer m-l-xs" (click)="removeQuestion(i)"></i><i class="fa fa-long-arrow-up pointer m-l-xs" (click)="moveQuestionUp(i)" *ngIf="i !== 0"></i><i class="fa fa-long-arrow-down pointer m-l-xs" (click)="moveQuestionDown(i)" *ngIf="i !== tree.questions.length - 1"></i></div>
              <div class="col-xs-11 no-padder">
                <input class="form-control" type="text" placeholder="Select Question..." aria-label="Number" name="question_{{i}}" autocomplete="off" matInput="matInput" required="true" [formControl]="item.autoComplete.controller" [matAutocomplete]="auto"/>
                <mat-autocomplete class="search-bar-xs" #auto="matAutocomplete">
                  <mat-option *ngFor="let option of item.autoComplete.options | async" [value]="option.name" (onSelectionChange)="autoCompleteOnSelect(option, item)">{{option.name}}</mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </div>
          <div class="col-xs-12 my-3">
            <button class="btn btn-sm" type="submit" [disabled]="!treeForm.valid">{{ treeObj.id ? 'Update' : 'Create' }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>