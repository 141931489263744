import { v4 as uuid } from 'uuid';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AppConfig } from '../../../app/app.config';
import { InputHelperService } from '../input-helper';

@Component({
  selector: 'input-text',
  templateUrl: './input-text.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class InputTextComponent {
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input('required') required: boolean = false;
  @Input('placeholder') placeholder: string = '';
  @Input('class') class: string;
  @Input('min')
  set onUpdateMin(min: number) {
    this.min = min;
    this.valueUpdated();
  }
  min: number;
  @Input('max')
  set onUpdateMax(max: number) {
    this.max = max;
    this.valueUpdated();
  }
  max: number;
  @Input('type')
  set onUpdateType(type: string) {
    this.type = type || 'text';
    this.valueUpdated();
  }
  type: string = 'text';
  @Input('minLength')
  set onUpdateMinLength(minLength: number) {
    this.minLength = minLength || 0;
  }
  minLength: number = 0;
  @Input('pattern') pattern: string;
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }
  disabled: boolean = false;
  @Output('onValueChange') onValueChange: EventEmitter<any> = new EventEmitter();

  ui: any = {};
  parseValue: any;
  parseObj: any;
  name: string = uuid();
  tempPattern: string;

  constructor(public appConfig: AppConfig, private inputHelper: InputHelperService) {
  }

  ngOnInit(): void {
  }

  onInputChange(): void {
    if (!this.parseObj || !this.name) return;
    this.parseValue = this.inputHelper.getValue(this.parseObj, this.name);
    if (this.name === 'perUserMaxUsageLimit') { this.parseValue = 1; }
    this.valueUpdated();
  }

  valueValidityCheck(): boolean {
    if (this.type !== 'number') return true;
    if (typeof this.min !== 'undefined' && this.min > Number(this.parseValue)) {
      return false;
    }
    if (typeof this.max !== 'undefined' && this.max < Number(this.parseValue)) {
      return false;
    }
    return true;
  }

  valueUpdated(): void {
    if (!this.valueValidityCheck()) {
      this.tempPattern = '^#$';
      return;
    }
    delete this.tempPattern;
    const value = this.type === 'number' ? Number(this.parseValue) : this.parseValue;
    if (this.inputHelper.getValue(this.parseObj, this.name) !== value) {
      this.inputHelper.setValue(this.parseObj, this.name, value);
    }
    this.onValueChange.emit(this.parseValue);
  }
}
