import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from '../../../../components/directives.module';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputTextareaModule } from '../../../../components/input/input-textarea';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { AddDietCardModal } from './add-diet-card';
import { AddLifestyleModal } from './add-lifestyle';
import { AddProductModal } from './add-product';
import { ArticleEditComponent } from './article-edit.component';
import { LanguageStringMultipleSelectionWithTagModule } from
  '../../../../components/language-string/language-string-multiple-selection-with-tag/language-string-multiple-selection-with-tag.module';
import { QuestionConditionModule } from '../../../tree/question/edit/question-condition';

const ROUTES: Routes = [
  { path: '', component: ArticleEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatDialogModule,
    ReactiveFormsModule,
    InputSelectModule,
    InputTextModule,
    InputTextareaModule,
    LanguageStringSelectionModule,
    LanguageStringMultipleSelectionWithTagModule,
    MatAutocompleteModule,
    QuestionConditionModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [ArticleEditComponent, AddProductModal, AddLifestyleModal, AddDietCardModal],
  exports: [ArticleEditComponent, AddProductModal, AddLifestyleModal, AddDietCardModal],
})
export class ArticleEditModule {
}
