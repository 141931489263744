import { v4 as uuid } from 'uuid';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { InputHelperService } from '../input-helper';
import { ConnectionService } from '../../../services/connection-service';
import { UploadFileService } from '../../../services/uploadFileService/uploadFile.service';

@Component({
  selector: 'input-single-image-upload',
  templateUrl: './input-single-image-upload.html',
  styleUrls: ['./input-single-image-upload.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class InputSingleImageUploadComponent {
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input('required')
  set onUpdateRequired(required: boolean) {
    this.required = required;
    this.onInputChange();
  }
  required: boolean = false;
  @Input('placeholder') placeholder: string = '';
  @Input('username') username: string;
  @Input('accept') accept: string;
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }
  @Input('params') params: Record<string, unknown> = {};
  disabled: boolean = false;
  @Output() fileChanged: EventEmitter<any> = new EventEmitter();
  @Output('onValueChange') onValueChange: EventEmitter<any> = new EventEmitter();

  type: string;
  ui: any = {};
  imageFile: string;
  parseValue: any;
  parseObj: any;
  name: string = uuid();

  constructor(private inputHelper: InputHelperService,
              private conn: ConnectionService,
              private uploadFileService: UploadFileService) {
  }

  onInputChange(): void {
    if (!this.parseObj || !this.name) return;
    this.imageFile = this.inputHelper.getValue(this.parseObj, this.name) || '';
    this.valueUpdated();
  }

  async uploadFile(file: any): Promise<any> {
    if (!this.username) {
      throw Error('Username missing.');
    }
    const { publicURL }: { publicURL: string } = await this.uploadFileService.uploadFileViaSignedURL({
      file,
      bucket: 'OTHER',
      username: this.username,
      source: 'doctor',
    });
    return publicURL;
  }

  async onFileChange(file: any): Promise<any> {
    const imageFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    this.imageFile = await Promise.resolve(this.uploadFile(imageFile));
    this.valueUpdated();
    delete this.parseValue;
    this.fileChanged.emit(imageFile);
  }

  valueUpdated(): void {
    this.inputHelper.setValue(this.parseObj, this.name, this.imageFile);
    this.onValueChange.emit(this.parseValue);
  }
}
