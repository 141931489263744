import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageModule } from './image/image.module';
import { PatientComponent } from './patient.component';
import { DirectivesModule } from '../../components/directives.module';
import { SupportTicketHistoryComponent } from './support-ticket-history/support-ticket-history.component';
import { UserNoteModule } from '../../components/user-notes';
import { InputMultiSelectModule } from '../../components/input/input-multi-select';
import { TreeTriggerModule } from '../../app/chat/view/tree-trigger';
import { FollowupChatViewModule } from '../../components/followup-chat-view-summary';
import { InputSelectTextModule } from '../../components/input/input-select-text';
import { InstantCheckupMagnifierModule } from '../../components/instant-checkup-magnifier';
import { RegimenProductsViewModule } from '../../components/regimen-products-view';
import { DoctorNotesModule } from '../../components/doctor-notes';
import { SupportTicketListModule } from '../../components/ticket-list';
import { VoiceRecordingModule } from '../../components/voice-recording';
import { RegimenProductsEditModule } from '../../components/regimen-products-edit';
import { FollowUpReportCreationModule } from '../../components/followUp-report-creation';
import { ConsentFormModule } from '../../components/consent-form/index';

const ROUTES: Routes = [
  { path: 'patient', component: PatientComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageModule,
    RouterModule.forChild(ROUTES),
    DirectivesModule,
    MomentModule,
    MatDialogModule,
    UserNoteModule,
    InputMultiSelectModule,
    TreeTriggerModule,
    FollowupChatViewModule,
    InputSelectTextModule,
    InstantCheckupMagnifierModule,
    RegimenProductsViewModule,
    DoctorNotesModule,
    SupportTicketListModule,
    VoiceRecordingModule,
    RegimenProductsEditModule,
    FollowUpReportCreationModule,
    ConsentFormModule,
  ],
  declarations: [PatientComponent, SupportTicketHistoryComponent],
  exports: [PatientComponent],
})
export class PatientModule {}
