import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Broadcaster } from 'src/components/broadcaster';
import { Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent {
  editId: string;
  quizQuestion: any;
  responseJson: any[] = [];
  loading: boolean;

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private broadcaster: Broadcaster,
    private router: Router,
    private route: ActivatedRoute) {}

  async ngOnInit(): Promise<any> {
    this.quizQuestion = new Table.QuizQuestion();
    this.quizQuestion.set('optionsArray', []);
    await this.findOrCreateQuestion();
  }

  async findOrCreateQuestion(): Promise<void> {
    const quizQuestionId = this.route.snapshot.params.id;
    this.editId = quizQuestionId;
    if (!quizQuestionId) {
      return;
    }
    [this.quizQuestion] = await this.conn.fetchQuizQuestions({ where: { objectId: quizQuestionId },
      include: ['optionsArray'] });
    this.responseJson = this.quizQuestion.get('optionsArray');
  }

  addResponseKeys(): void {
    this.responseJson.push([]);
  }

  removeResponseKeys(index: number): void {
    this.responseJson.splice(index, 1);
  }

  async saveQuestion(): Promise<void> {
    this.loading = true;
    const isAnswerValid = this.quizQuestion.get('optionsArray').find((o: any) => (o.id === this.quizQuestion.get('answer').id));
    if (!isAnswerValid) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Invalid answer! Selected answer is not present in options list',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.loading = false;
      return;
    }
    try {
      await this.quizQuestion.save();
      this.router.navigate(['/marketing/quiz-questions']);
    } catch (error) {
      alert(error.message);
      this.loading = false;
    } finally {
      this.loading = false;
    }
    this.router.navigate(['/marketing/quiz-questions']);
  }
}
