import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MomentModule } from 'ngx-moment';
import { Subscription } from 'rxjs';
import { ImageUploadDialog } from 'src/app/chat/view/instant-checkup/image-upload-dialog';
import { ConnectionService } from 'src/services/connection-service';
import { EditInstantCheckup } from 'src/app/chat/view/instant-checkup/edit-instant-checkup';

@Component({
  selector: 'sales-user-photos',
  standalone: true,
  imports: [CommonModule, MomentModule],
  templateUrl: './user-photos.component.html',
  styleUrl: './user-photos.component.scss',
})
export class UserPhotosComponent {
  @Input('user') user: any;
  @Input('orderObj') orderObj: any;
  @Input('instantCheckups') instantCheckups: any = [];
  file: File;
  imageType: string;
  types: Array<any> = ['FRONT_FACE', 'LEFT_SIDE_FACE',
    'RIGHT_SIDE_FACE', 'SIDE_FACE', 'HAIR_TOP', 'HAIR_FRONT', 'BODY', 'REPORT', 'OTHER'];
  public subscriptions: Subscription[] = [];
  instantCheckupFetchLimit: number = 100;
  instantCheckupToSkipNumber: number = 0;
  endOfList: boolean = false;

  constructor(private conn: ConnectionService,
    private dialog: MatDialog,
  ) { }

  async ngOnChanges(): Promise<void> {
    await this.getInstantCheckups();
    this.filterCheckups();
  }

  filterCheckups(): void {
    this.instantCheckups = this.instantCheckups.filter((checkup: any): boolean => {
      const regimenClass = this.orderObj?.regimen?.class;
      return checkup.type.includes(regimenClass);
    });
  }

  async getInstantCheckups(): Promise<void> {
    const imagesBatch = await this.conn.fetchUserInstantCheckup({
      skip: this.instantCheckupToSkipNumber,
      limit: this.instantCheckupFetchLimit,
      userId: this.user?.get('username'),
      project: ['type', 'imagePath', 'compressedImagePath', 'thumbnailImagePath', 'tags'],
    });
    this.instantCheckups = imagesBatch;
    if (imagesBatch.length < this.instantCheckupFetchLimit) {
      this.endOfList = true;
    }
  }

  uploadPhoto(): void {
    const dialogRef = this.dialog.open(ImageUploadDialog, {
      data: {
        file: this.file,
        imageType: this.imageType,
        types: this.types,
        chatUser: this.user,
      },
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) return;
      this.file = result.file;
      this.imageType = result.imageType;
      this.getInstantCheckups();
    }));
  }

  editType(id: string, imageUrl: string): void {
    const dialogRef = this.dialog.open(EditInstantCheckup, { data: { id, imageUrl } });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) return;
      this.instantCheckups = [];
      this.getInstantCheckups();
    });
  }

  async loadMoreImages(): Promise<void> {
    if (this.endOfList) {
      return;
    }
    this.instantCheckupToSkipNumber += this.instantCheckupFetchLimit;
    if (this.instantCheckupFetchLimit !== 50) {
      this.instantCheckupFetchLimit = 50;
    }
    await this.getInstantCheckups();
  }

  getDayDifference(instantCheckup: any): any {
    const createdAt = moment(instantCheckup.createdAt).startOf('day');
    const today = moment().startOf('day');
    const diffDays = today.diff(createdAt, 'days');
    return diffDays;
  }
}
