
<div fillComponentParentView="fillComponentParentView">
  <div class="hbox of-auto">
    <div class="col-xs-12 list-header">
      <ul class="breadcrumb">
        <li><a routerLink="/couriers/wareHouse/">WareHouse</a></li>
        <li>{{wareHouseId ? 'Edit' : 'New'}}</li>
      </ul>
    </div>
    <div class="col-xs-12 mb-3">
      <label class="col-xs-12">availableSKUs</label>
      <input [(ngModel)]="availableSKUs" type="text" name="availableSKUs" style="width: 100%" required="required"/>
    </div>
    <div class="col-xs-12">
      <button class="btn-xs" type="submit" (click)="saveWareHouse()">{{ wareHouseId ? 'Update' : 'Create' }}</button>
    </div>
  </div>
</div>