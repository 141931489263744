import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'api-client';
import { AppConfig } from '../../app.config';
import { ConnectionService } from '../../../services/connection-service';

@Component({
  selector: 'canned-response-edit',
  templateUrl: './canned-response-edit.html',
})
export class CannedResponseEditComponent {
  cannedResponseObj: any;
  data: any = { mode: this.appConfig.Shared.Assistant.Mode.WITH_TEXT_REPLY };
  modes: Array<any> = [{ key: this.appConfig.Shared.Assistant.Mode.WITH_TEXT_REPLY, value: 'Text input' }];
  isTeamsChanged: boolean = false;
  currentTags: Array<string> = [];
  currentTeams: Array<string> = [];
  tagsThatCanBeAdded: Array<string>;
  teamsThatCanBeAdded: Array<string>;
  constructor(private appConfig: AppConfig,
    private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.cannedResponseObj = new Table.CannedResponse();
    this.subscribeToUrlQueryParams();
  }

  subscribeToUrlQueryParams(): void {
    this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.cannedResponse) {
        this.cannedResponseObj = this.route.parent.snapshot.data.cannedResponse;
        this.data.mode = this.cannedResponseObj.get('mode');
        this.data.title = this.cannedResponseObj.get('title');
        this.currentTags = this.cannedResponseObj.get('tags') || [];
        this.currentTeams = this.cannedResponseObj.get('teams') || [];
      } else {
        this.cannedResponseObj.set('values', {});
      }
      this.tagsThatCanBeAdded = this.appConfig.Shared.CannedResponse.PossibleTags
        .filter((key: string) => (!this.currentTags.includes(key)));
      this.teamsThatCanBeAdded = this.appConfig.Shared.Team.Name
        .filter((key: string) => (!this.currentTeams.includes(key)));
    });
  }

  addTag(index: number): void {
    this.currentTags.push(...this.tagsThatCanBeAdded.splice(index, 1));
  }

  removeTag(index: number): void {
    this.tagsThatCanBeAdded.push(...this.currentTags.splice(index, 1));
  }

  addTeam(index: number): void {
    this.isTeamsChanged = true;
    this.currentTeams.push(...this.teamsThatCanBeAdded.splice(index, 1));
  }

  removeTeam(index: number): void {
    this.isTeamsChanged = true;
    this.teamsThatCanBeAdded.push(...this.currentTeams.splice(index, 1));
  }

  async saveCannedResponse(): Promise<any> {
    try {
      if (this.isTeamsChanged) this.cannedResponseObj.set('teams', this.currentTeams);
      this.cannedResponseObj.set('mode', this.data.mode);
      this.cannedResponseObj.set('title', this.data.title);
      this.cannedResponseObj.set('tags', this.currentTags);
      await this.cannedResponseObj.save();
      this.router.navigate(['/cannedResponses']);
    } catch (err) {
      alert(err.message);
    }
  }
}
