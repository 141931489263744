
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading" #headingView>
    <div class="list-header col-xs-12"><span class="pR20">Language Strings ({{count || 0}})</span>
      <div class="pull-right"><i class="fa fa-plus-square-o m-l-xs pointer" routerLink="new"></i></div>
    </div>
  </div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content">
          <div class="col-xs-12 mt-4">
            <button class="btn-xxs mr-2 mb-2 p10" *ngFor="let item of languageKeys" [ngClass]="{ 'btn-primary-outline': languageFilter !== item, 'btn-primary': languageFilter === item }" (click)="changeLanguage(item)">{{item}}</button>
          </div>
          <div class="col-xs-12 mt-4">
            <language-string-tag-selection [parseObj]="filter" name="tags" (onValueChange)="onTagUpdate()"></language-string-tag-selection>
          </div>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" #agGrid style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
