
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Order Labels</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="col-xs-12">
      <div class="col-xs-12">
        <label class="col-xs-3 mt-0">Existing Labels</label>
        <div class="col-xs-9">
          <div class="label label-danger m-l-xs" *ngFor="let label of existingLabels">{{label}}</div>
        </div>
      </div>
      <div class="line-dashed"></div>
      <div class="col-xs-12">
        <label class="col-xs-3 mt-0">New Labels</label>
        <div class="col-xs-9 d-flex" style="flex-wrap: wrap;">
          <div class="label label-warning m-l-xs mt-1 pointer" *ngFor="let label of addedLabels" (click)="removeLabel(label)">{{label}}</div>
        </div>
      </div>
      <div class="line-dashed"></div>
      <div class="col-xs-12">
        <label class="col-xs-3 mt-0">Suggested Labels<i class="fa fa-plus-square-o" (click)="addSuggestion()"></i></label>
        <div class="col-xs-9 d-flex" style="flex-wrap: wrap;">
          <div class="label label-primary m-l-xs mt-1 pointer" *ngFor="let label of suggestedLabels" (click)="addLabel(label)">{{label}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="pull-right" (click)="updateOrderLabels()">Update</button>
  </div>
</mat-dialog-content>