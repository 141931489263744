import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { v4 as uuid } from 'uuid';
import { ConnectionService } from '../connection-service';
import { LocalStorage } from '../../services/local-storage-service';
import { Broadcaster } from '../../components/broadcaster';
import { AppConfig } from '../../app/app.config';

@Injectable()
export class UploadFileService {
  constructor(
    private conn: ConnectionService,
    private http: HttpClient,
    private storage: LocalStorage,
    private appConfig: AppConfig,
    private broadcasterService: Broadcaster) {}

  async uploadFileViaSignedURL({ file, bucket, username, source, filename }
    : { file: any, bucket: string, username?: string, source?: string, filename?: string })
    : Promise<{ publicURL: string; signedGetURL: string; }> {
    const extension: string = file.name.split('.').pop();
    const name: string = `${uuid()}.${extension}`;
    const payload = await this.conn.createFileUploadUrl({ name, bucket, username, source, extension, filename });
    const formData = new FormData();
    Object.keys(payload.fields).forEach((field: string) => formData.append(field, payload.fields[field]));

    formData.append('file', file);
    const headers = new HttpHeaders();
    try {
      await this.http.post(payload.url, formData, { headers, responseType: 'text' }).toPromise();
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', {
        message: 'File size should be less than 5MB',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      throw error;
    }
    return {
      publicURL: payload.publicURL,
      signedGetURL: payload.getSignedURL,
    };
  }

  async uploadFile({ file, bucket, username, source, needSignedURL }
    : { file: any, bucket: string, username: string, source?: string, needSignedURL?: boolean }): Promise<string> {
    const name: string = `${uuid()}.${file.name.split('.').pop()}`;
    const formData = new FormData();
    formData.append('bucket', bucket);
    formData.append('source', source);
    if (needSignedURL) formData.append('signed', needSignedURL.toString());
    formData.append('extension', file.name.split('.').pop());
    formData.append('username', username);
    formData.append('name', name.replace(/ /g, '_').toLowerCase());
    formData.append('sessionToken', this.storage.getJsonValue('Parse/myAppId/currentUser').sessionToken);
    formData.append('file', file);
    const url: string = await this.http
      .post(`${this.conn.getParseUrl()}/api/file/upload/file`, formData, { responseType: 'text' }).toPromise();
    return url;
  }
}
