
<mat-dialog-content>
  <div class="modal-dialog modal-md">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Add support category</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
    </div>
    <div class="modal-body">
      <form class="col-xs-12">
        <language-string-selection [parseObj]="supportCategory" name="nameLanguageString" [required]="true" [tags]="['support_category']"></language-string-selection>
        <div class="col-xs-12">
          <label class="col-xs-4">Is Primary Category</label>
          <input-checkbox [parseObj]="supportCategory" name="isPrimary"></input-checkbox>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn-xs pull-right" (click)="addCategory()">ADD</button>
    </div>
  </div>
</mat-dialog-content>