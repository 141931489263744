
<div class="col-xs-12 p-0" *ngIf="user">
  <div class="col-xs-12 title"><em class="fas fa-portrait pr-3"></em><span>User Actions</span>
    <div class="pull-right">
      <div class="mobile call-icon"><em class="fas fa-phone-alt" (click)="callUserThroughExotel(user.get('MobileNumber'))" title="Mobile"></em><em class="fas fa-phone-alt alt-2" *ngIf="user.get('alternateNumber')" (click)="callUserThroughExotel(user.get('alternateNumber'))" title="Alternate Mobile"></em></div>
    </div>
  </div>
  <div class="col-xs-12"></div>
  <div class="col-xs-12 flex mb-3">
    <div class="col-xs-3">
      <div class="bold">Label</div>
    </div>
    <div class="pull-left pr-3">:</div>
    <div class="col-xs-8 text-nowrap flex flex-wrap">
      <select (change)="autoCompleteOnSelect($event, 'userLabel')">
        <option selected="selected" hidden="hidden">Choose a label</option>
        <option *ngFor="let option of chatLabelOptions" [value]="option">{{option}}</option>
      </select>
      <div class="label label-primary ml-2 mt-2 px-3 py-2" *ngFor="let label of assignedUserLabelList; let i = index;" [style]="{ backgroundColor: label.color }">{{label.name}}<i class="fa fa-trash-o ml-2" (click)="removeItem(i, 'userLabel')"></i></div>
    </div>
  </div>
  <div class="col-xs-12 flex mb-3">
    <div class="col-xs-3">
      <div class="bold">User Tags</div>
    </div>
    <div class="pull-left pr-3">:</div>
    <div class="col-xs-8 text-nowrap flex flex-wrap">{{user.get('tags')}}</div>
  </div>
  <div class="col-xs-12 flex mb-3" *ngIf="!user.get('marketingTags')?.includes('STEROID') && showSteroidQuestion">
    <div class="col-xs-3">
      <div class="bold">Steroid User </div>
    </div>
    <div class="pull-left pr-3">:</div>
    <div class="btn bdr ml-3" style="width: 60px" (click)="updateSteroidTags(true)">Yes</div>
    <div class="btn bdr ml-3" style="width: 60px" (click)="updateSteroidTags(false)">No</div>
  </div>
  <div class="col-xs-12 flex mb-3">
    <div class="col-xs-3">
      <div class="bold">MarketingTags</div>
    </div>
    <div class="pull-left pr-3">:</div>
    <div class="col-xs-12 text-nowrap flex flex-wrap">
      <div class="label label-primary ml-2 mt-2 px-3 py-2" *ngFor="let tag of user.get('marketingTags'); let i = index;">{{tag}}<i class="fa fa-trash-o ml-2" *ngIf="userRoles?.includes('admin')" (click)="onDeleteMarketingTags($event, i)"></i></div>
    </div>
  </div>
  <div class="col-xs-12 ml-5" *ngIf="userRoles?.includes('admin')">
    <select (change)="onSelectMarketingTags($event)">
      <option *ngFor="let option of marketingTagsOptions" [value]="option">{{option}}</option>
    </select>
  </div>
</div>
