import { Component, NgZone, OnDestroy } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConnectionService } from '../../../../services/connection-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { FilterConstants } from '../../../../components/filter-dropdown/filter-constants';

@Component({ selector: 'media-link-list', templateUrl: './list.html' })
export class ListComponent {
  window: any;
  gridApi: GridApi;
  user: any;
  userRoles: Array<any> = [];
  ui: any = {};
  startDate: Date;
  endDate: Date;
  columnDefs: any;
  dataSource: any;
  components:any;
  count:number;
  filters: any = [{}];
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  products: Array<any> = [];

  constructor(private conn: ConnectionService,
              public appConfig: AppConfig,
              windowRef: WindowRefService,
              private storage: LocalStorage,
              private router: Router,
              private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.user = this.conn.getCurrentUser();
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.ui = { isFilterOpen: false, grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
    this.listViewSetup();
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
        type: 'main',
      },
      project: ['title', 'quantity', 'quantityUnit', 'type', 'margUnit', 'mrp', 'price', 'mainProduct'],
      ascending: 'margUnit',
      limit: 10,
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(product),
      object: product,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };

    this.columnDefs = [
      {
        headerName: 'Media Link Id',
        field: 'objectId',
      },
      {
        headerName: 'Action',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) return '';
          const { data }: any = params;
          const eDiv = this.window.document.createElement('div');
          let html = '';
          const combinedProductId = data.uniqueId;
          const mediaLinkObjectId = data.objectId;
          html += `<a class='fa fa-pencil-square-o pR10' target="_blank" href='/products/medialink/${mediaLinkObjectId}/edit'></a>`;
          html += `<a class='fa fa-eye' href='${url.join('/')}/medialink/view/?combinedProductId=${combinedProductId}'`
            + 'target=\'_blank\'></a>';
          eDiv.innerHTML = html;
          return eDiv;
        },
      },
      {
        headerName: 'uniqueId',
        field: 'uniqueId',
        width: 400,
        filter: true,
      },
      {
        headerName: 'Title',
        field: 'title',
        width: 350,
        filter: true,
      },
      {
        headerName: 'Media Photo Type',
        field: 'mediaSubType',
        width: 150,
        filter: true,
      },
      {
        headerName: 'Media Type',
        field: 'mediaType',
        width: 150,
        filter: true,
      },
    ];
  }

  async loadMore(params:any): Promise<any> {
    const requestPayload: RequestQueryPayload<Table.MediaLink> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
    };
    if (this.products.length) {
      let mainProductIds: Array<string> = [];
      this.products.map((product:any) => mainProductIds.push(product.get('mainProduct').id));
      mainProductIds = this.setProductsIdsWithProductType(mainProductIds);
      requestPayload.where.reference = { $all: mainProductIds };
    }
    const [data, count]: [Array<unknown>, number] = await Promise.all([
      ApiConnector.find(Table.MediaLink, requestPayload)
        .then((items: any) => items.map((i: any) => JSON.parse(JSON.stringify(i)))),
      ApiConnector.count(Table.MediaLink,
        requestPayload),
    ]);
    this.count = count;
    return data;
  }

  setProductsIdsWithProductType(productIds: Array<string>): Array<string> {
    if (!productIds || productIds.length === 0) {
      return [];
    }
    return productIds.map((id:any) => `${id}_catalog`);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.products = [];
      this.filters = Object.keys(params)
        .filter((key: string) => ['stage', 'paymentType', 'type'].includes(key))
        .map((key: string) => ({ key, filterType: FilterConstants.FILTER_TYPE.EQUAL_TO, value: params[key].split(',') }));
      this.reset();
    });
  }

  removeProduct(index: number): void {
    this.products.splice(index, 1);
  }
  autoCompleteOnProductSelect(item: { name: string; object: any }): void {
    if (!this.products) this.products = [];
    this.products.push(item.object);
    this.autoCompleteProductController.setValue('');
  }
}
