import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AudioRecorderComponent } from './audio-recorder.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
  ],
  declarations: [AudioRecorderComponent],
  exports: [AudioRecorderComponent],
})
export class AudioRecorderModule {}
