import { Component, Input } from '@angular/core';

@Component({ selector: 'support-question-name', templateUrl: './support-question-name.html' })
export class SupportQuestionNameComponent {
  @Input('supportQuestion')
  set supportQuestion(supportQuestion: any) {
    if (!supportQuestion) {
      this.name = '';
      return;
    }
    this.updateName(supportQuestion);
  }
  name: string;

  private async updateName(supportQuestion: any): Promise<void> {
    if (!supportQuestion.get('question')) {
      await supportQuestion.fetch();
    }
    this.name = supportQuestion.get('question');
  }
}
