
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="list-header col-xs-12"><span class="pR25">{{cannedResponseObj?.id ? 'Edit' : 'New'}} Canned Response</span></div>
  </div>
  <div class="flex justifyC p-2">
    <form class="form" #cannedResponseForm="ngForm">
      <div class="col-xs-12 wrapper-sm">
        <input class="form-control" [(ngModel)]="data.title" name="cannedResponseTitle" required="required" placeholder="Canned Response Title"/>
      </div>
      <div class="col-xs-12 wrapper-sm">
        <language-string-selection [parseObj]="cannedResponseObj" name="responseLanguageString" [required]="true" [tags]="['canned_response']"></language-string-selection>
      </div>
      <div class="col-xs-12 wrapper-sm">
        <h4 class="fontL3">Tags</h4>
        <div class="pb-3 bold fontS2">ADD</div>
        <div class="flex flex-wrap"><span class="label label-default mr-2 mb-2 p-2" *ngFor="let each of tagsThatCanBeAdded; let i = index">{{each}}<i class="fa fa-plus-square-o pointer ml-2" (click)="addTag(i)"></i></span></div>
        <div class="py-3 bold fontS2">REMOVE</div>
        <div class="flex flex-wrap"><span class="label label-success mr-2 mb-2 p-2" *ngFor="let each of currentTags; let i = index">{{each}}<i class="fa fa-trash-o pointer ml-2" (click)="removeTag(i)"></i></span></div>
      </div>
      <div class="col-xs-12 wrapper-sm">
        <h4 class="fontL3 mt-0">Teams</h4>
        <div class="pb-3 bold fontS2">ADD</div>
        <div class="flex flex-wrap"><span class="label label-default mr-2 mb-2 p-2" *ngFor="let each of teamsThatCanBeAdded; let i = index">{{each}}<i class="fa fa-plus-square-o pointer ml-2" (click)="addTeam(i)"></i></span></div>
        <div class="py-3 bold fontS2">REMOVE</div>
        <div class="flex flex-wrap"><span class="label label-success mr-2 mb-2 p-2" *ngFor="let each of currentTeams; let i = index">{{each}}<i class="fa fa-trash-o pointer ml-2" (click)="removeTeam(i)"></i></span></div>
      </div>
      <div class="col-xs-12 wrapper-sm bold flex justifyC"><br/>
        <button (click)="saveCannedResponse()" [disabled]="!cannedResponseForm.valid">{{cannedResponseObj?.id ? 'Update' : 'Create' }}</button>
      </div>
    </form>
  </div>
</div>