
<div fillComponentParentView="fillComponentParentView">
  <div class="hbox of-auto">
    <div class="col-xs-12 list-header">
      <ul class="breadcrumb">
        <li>Update BulkActions</li>
      </ul>
    </div>
    <div class="col-xs-12">
      <form class="form-horizontal col-xs-12" #bulkUploadForm="ngForm">
        <div class="form-group p-2">
          <div class="col-xs-12 p-0">
            <div class="col-xs-12">
              <div class="col-xs-12 mt-2 mb-3 scroll">
                <div class="rounded wrapper-xs w-fit-content mb-2" *ngFor="let row of bulkActionList; let i = index;"><span class="label-default rounded mr-2 fontS2 text-white px-5 py-1" *ngFor="let col of row">{{col}}</span><i class="fa fa-trash-o pointer" (click)="removeEntry(i)"></i></div>
              </div>
              <textarea class="col-xs-12 text-area mb-3" [(ngModel)]="csvText" placeholder="Paste CSV here." name="csvText"></textarea>
              <div class="col-xs-12 mb-3 p-0" *ngIf="showProgressBar">
                <label class="mb-2">Progress bar ({{progress}} / {{total}})</label>
                <mat-progress-bar class="mb-2" [value]="(progress * 100) / total"></mat-progress-bar>
              </div>
              <div class="col-xs-12 mb-3 p-0">
                <button class="mb-3 db pull-left" [disabled]="!csvText" (click)="addDataToProcess()">Add</button>
              </div>
              <div class="col-xs-12 mb-3 p-0">
                <select [(ngModel)]="category" required="required" name="category">
                  <option selected="true" hidden="hidden">Choose Category</option>
                  <option *ngFor="let each of categories;" [value]="each" [disabled]="!each">{{each}}</option>
                </select>
              </div>
              <div class="col-xs-12 mb-3 p-0" *ngIf="category === 'Notification' || category === 'AddOnProduct'">
                <div class="flex mb-2">
                  <input class="input" placeholder="Date Time:" [required]="[required]" [(ngModel)]="eventTime" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1"/>
                  <owl-date-time #dt1></owl-date-time>
                </div>
              </div>
              <div *ngIf="category === 'Notification'">
                <div class="col-xs-12 mb-3 p-0">
                  <select [(ngModel)]="typeName" name="typeName">
                    <option *ngFor="let each of typeNames;" [value]="each" [disabled]="!each">{{each}}</option>
                  </select>
                </div>
                <div class="col-xs-12 mb-3 p-0 col-md-6">
                  <label class="mb-2">OpenUrl</label>
                  <input-text class="col-xs-12 mb-2" required="required" type="text" (onValueChange)="openUrl=$event"></input-text>
                </div>
                <div class="col-xs-12 mb-3 p-0">
                  <label class="mb-2">Notification-Message</label>
                  <language-string-selection [required]="true" [tags]="['notification_message']" (languageStringObjChange)="messageLanguageString = $event"></language-string-selection>
                </div>
                <div class="col-xs-12 mb-3 p-0">
                  <label class="mb-2">Notification-Image</label>
                  <language-string-selection [tags]="['notification_image']" (languageStringObjChange)="imageLinkLanguageString=$event"></language-string-selection>
                </div>
              </div>
              <div class="col-xs-12 mb-3 p-0 col-md-6" *ngIf="category === 'AddOnProduct'">
                <label class="mb-2">Product</label>
                <select class="col-xs-12 mb-2" [(ngModel)]="selectedProductIndex" required="true" name="productList">
                  <option selected="selected" hidden="hidden">Choose Product</option>
                  <option *ngFor="let each of productList;let currentIndex=index" value="{{currentIndex}}">{{ each.get('title') }}</option>
                </select>
              </div>
              <div *ngIf="category === 'Order'">
                <div class="col-xs-12 mb-3 p-0 col-md-2">
                  <select class="col-xs-12 mb-2" [(ngModel)]="action" required="true" name="action">
                    <option selected="selected" hidden="hidden">Choose an action</option>
                    <option *ngFor="let each of actions;" [value]="each.value" [disabled]="!each.value">{{each.name}}</option>
                  </select>
                </div>
                <div class="col-xs-12 mb-3 p-0">
                  <input class="file" #fileInput *ngIf="action === &quot;QC_PASS&quot;" [(ngModel)]="file" type="file" required="true" name="file"/>
                  <div class="col-xs-12 mt-2" *ngIf="errorMessage">
                    <div class="text-danger">{{errorMessage}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="mt-3 db" [disabled]="!bulkUploadForm.valid || !bulkActionList.length" (click)="processBulkAction()">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>
