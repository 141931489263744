
<div class="col-xs-12" *ngIf="product" [ngStyle]="{'border-radius': regimenProducts ? '' : '10px'}" [ngClass]="{'p-2': !isFollowUp, 'mb-2': !isFollowUp, 'b-a': !regimenProducts}">
  <div class="col-xs-12 p-0 flex">
    <div class="col-xs-6 p-0">
      <input class="form-control" type="text" [id]="name" placeholder="Select Product." aria-label="Number" name="{{name}}_product" autocomplete="off" matInput="matInput" required="required" [formControl]="autoCompleteProductController" title="Product Name" [matAutocomplete]="productAuto"/>
      <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
        <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteProductOnSelect(option)">{{option.name}}</mat-option>
      </mat-autocomplete>
    </div>
    <div class="col-xs-3 ml-2 flex-grow" *ngIf="type!=='removeProduct'">
      <input-select [parseObj]="product" id="id" title="Instruction" name="instructionSet" prefix="{{product?.instructionSet?.id}}_{{name}}" [options]="productInstructions" required="required" (onValueChanged)="onProductUpdate.emit()"></input-select>
    </div>
  </div>
  <div class="col-xs-12 p-0 mt-2" *ngIf="!hideNotNecessaryThings && !onProductChange">
    <div class="col-xs-12 pr-0">
      <div class="r-2x wrapper-xs">
        <language-view *ngIf="product && product.purposeDescriptionLanguageString" [languageStringObj]="product.purposeDescriptionLanguageString"></language-view>
      </div>
    </div>
  </div>
  <div class="col-xs-12 mt-3" *ngIf="!onProductChange">
    <catalog-instruction-view [instruction]="product.instructionSet" [type]="apiClientConstant.Order.Type.REGIMEN"></catalog-instruction-view>
  </div>
  <div class="col-xs-12 bold fontS1" *ngIf="!onProductChange">
    <div class="inline-flex pl-4">
      <label class="bold">OnHold</label>
      <label class="i-switch i-switch-sm bg-secondary ml-2">
        <input type="checkbox" [(ngModel)]="product.onHold" name="{{name}}onHold" (change)="showAlertToChangeInstruction()"/><i></i>
      </label>
    </div>
  </div>
</div>
