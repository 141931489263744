const userFieldsToSkip: Array<string> = [
  'ACL',
  'username',
  'MobileNumber',
  'objectId',
  'createdAt',
  'updatedAt',
  'signUpSource',
  'androidAdvertisingId',
  'mainConcernMandatory',
  'signUp',
  'mod11',
  'mod20',
  'orderState',
  'allocatedOperator',
  'allocatedDoctor',
  'contactDetails',
  'alternateNumber',
  'ratedApp',
  'appDeleted',
  'refreshDate',
  'loginSource',
  'Notification',
  'deviceId',
  'disableCOD',
  'branchBeforeLoginData',
  'branchLastAttributeTouchData',
  'branchLatestParams',
  'branchFirstInstallParams',
  'branchFirstInstallParams',
  'InstantCheckupTaken',
  'phoneDetails',
  'enableSelfCare',
  'ACTIVE_TREE',
  'PrivateMainConcernClass',
  'mainConcernChangeDetected',
  'afterSaveUpdateAcl',
  'appsFlyer',
  'pushAmplifyDeviceId',
  'operatorModifiedFlag',
  'firstMessageFlag',
  'serviceEndDate',
  'serviceEndDateArray',
  'serviceEndDateModified',
  'serviceStartDate',
  'discountPercentage',
  'pushyDeviceToken',
  'userOnlineStatusTimeStamp',
  'isOnline',
  'advertisingId',
  'seenBrandUpdate',
  'referralLink',
  'experimentLogs',
  'disableChat',
  'whatsappOptInStatus',
  'aiDetections',
  'concernList',
  'lastLocation',
  'pagesVisited',
  'disablePaymentGateway',
  'latestOrderId',
  'firstPlayStoreReferrerURL',
  'latestPlayStoreReferrerURL',
  'languagePreference',
  'Gender',
  'PatientName',
  'Age',
  'ageUpdatedAt',
  'firstOrderDeliveredDate',
  'reAcquisitionResponse',
  'neverBoughtRetention',
  'totalAmountSpent',
  'hasUserOptedForCall',
  'appDeletedTime',
  'totalAvailableCash',
  'callerType',
  'lastOrderDeliveredDate',
  'acqMonth',
  'boostProductTag',
  'lastLocationCity',
  'lastLocationState',
  'PrivateMainConcern',
  'config',
  'dbArchive',
  'termsOfServiceAcceptedDates',
  'boostProductSelectedDate',
];

const consultationFieldsToSkip: Array<string> = [
  'ACL',
  'objectId',
  'username',
  'user',
  'createdAt',
  'updatedAt',
  'archive',
  'PrivateTreeCompleted',
  'PrivateMainConcernClass',
  'TakeInstantCheckSideFace',
  'PrivateRegimenGivenPointer',
  'PrivateInstantCheckupDebug',
  'TakeInstantCheckup',
  'disableChat',
  'HairfallPhotoTop',
  'hairPhotos',
  'TakePhotoOrNot',
  'BodyPhoto',
  'HairfallPhoto',
  'facePhotos',
  'concernList',
  'attended',
  'dbArchive',
  'configSecondaryConcern',
  'configPrimaryConcern',
  'configPrimaryConcernSeverity',
];

export { userFieldsToSkip, consultationFieldsToSkip };
