import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, UntypedFormControl, NgForm } from '@angular/forms';
import { RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';

@Component({
  selector: 'language-string-auto-complete',
  templateUrl: './language-string-auto-complete.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class LanguageStringAutoCompleteComponent {
  @Input('tags')
  set searchTags(tags: Array<string>) {
    this.updateSearchHint(tags);
  }
  @Input('name') name: string = '';
  @Input('prefix') prefix: string;
  @Input('pattern') pattern: string;
  @Input('required') required: boolean = false;

  @Output('onSelection') onSelection: EventEmitter<any> = new EventEmitter();

  private async updateSearchHint(tags: Array<any> = []): Promise<any> {
    this.tags = await this.conn.fetchLanguageStringTags({ tags });
    const tagsName = this.tags.map((x: any) => `"${x.get('name')}"`).join(', ');
    this.searchHintText = 'Search Here';
    if (tagsName) {
      this.searchHintText = `${this.searchHintText} (Tags: ${tagsName})`;
    }
  }

  tags: Array<any> = [];
  searchHintText: string = 'Search Here';
  autoCompleteController: UntypedFormControl = new UntypedFormControl();
  options: Observable<Array<{ name: string; object: any }>>;

  constructor(private conn: ConnectionService) {}

  ngOnInit(): void {
    this.options = this.autoCompleteController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => token && !!token.length),
      mergeMap((token: string) => this.getLanguageString(token)));
  }

  async getLanguageString(token: string): Promise<Array<{ name: string; object: any }>> {
    const payload: RequestQueryPayload<Table.LanguageString> = {
      where: {
        $or: ['uniqueId', 'en'].map((key: string) => ({ [key]: { $regex: token, $options: 'i' } })),
        tags: this.tags,
      },
      project: ['en'],
      limit: 10,
    };
    const languageStrings = await this.conn.findLanguageStrings(payload);
    return languageStrings.map((languageString: any) => ({
      name: languageString.get('en'),
      object: languageString,
    }));
  }

  async autoCompleteOnSelect(item: { name: string; object: any }): Promise<any> {
    this.onSelection.emit(item.object);
    this.autoCompleteController.setValue('');
  }
}
