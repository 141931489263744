import { Component, ViewChild, ElementRef, OnInit, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as EXIF from 'exif-js';
import { ApiClientConstant } from 'api-client';
import * as moment from 'moment';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { ClipboardService } from '../../../services/copyToClipboard-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { rotateCanvasBasedOnOrientation } from '../../../components/util';
import { Broadcaster } from '../../../components/broadcaster';

@Component({
  selector: 'comparison-slider',
  templateUrl: './comparison-slider.html',
  styleUrls: ['./comparison-slider.scss'],
})
export class ComparisonSliderComponent implements OnInit, AfterViewChecked {
  @ViewChild('firstImage', { static: false }) canvas1: ElementRef;
  @ViewChild('secondImage', { static: false }) canvas2: ElementRef;
  @ViewChild('firstImageContainer', { static: false }) img1Container: ElementRef;
  @ViewChild('secondImageContainer', { static: false }) img2Container: ElementRef;

  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('imgH1', { static: false }) imgH1: ElementRef;
  @ViewChild('imgH2', { static: false }) imgH2: ElementRef;
  @ViewChild('sliderCanvas1', { static: false }) sliderCanvas1: ElementRef;
  @ViewChild('sliderCanvas2', { static: false }) sliderCanvas2: ElementRef;

  @Input('params') params: any;
  @Output('onRemoveComparison') onRemoveComparison: EventEmitter<any> = new EventEmitter<any>();
  @Output('onZoomChange') onZoomChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('onChangePhoto') onChangePhoto: EventEmitter<any> = new EventEmitter<any>();

  MIN_CANVAS_WIDTH: number = 360;
  MIN_CANVAS_HEIGHT: number = 360;
  imageURL: string[] = [];
  instantCheckups: any[] = [];
  imageData:any = [];
  imageConfig: { after: any, before: any } = { after: {}, before: {} };
  timeInterval: any;
  photoType: string;
  frontFacePhotos: Array<any> = [];
  sideFacePhotos: Array<any> = [];
  hairPhotos: Array<any> = [];
  bodyPhotos: Array<any> = [];
  user: any;
  type: Array<string> = [];
  moment: any;

  constructor(private route: ActivatedRoute,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private copyToClipBoardService: ClipboardService,
    private windowService: WindowRefService,
    private broadcaster: Broadcaster,
  ) {
    this.moment = moment;
  }

  async ngOnInit(): Promise<any> {
    this.instantCheckups = await this.conn.getInstantCheckUpWith(
      this.params.username,
      [this.params.id1, this.params.id2],
      ['imagePath', 'compressedImagePath', 'aiResponse', 'dimension', 'type']);
    this.photoType = this.instantCheckups[0].type;
    this.user = await this.conn.getUserByUserName(this.params.username);
    if ((new Date(this.instantCheckups[0].createdAt)) < (new Date(this.instantCheckups[1].createdAt))) {
      this.instantCheckups.reverse();
    }
    this.instantCheckups.forEach((each: any, index: number) => {
      this.imageURL[index] = this.instantCheckups[index].compressedImagePath || this.instantCheckups[index].imagePath;
      this.imgLoaded(index);
    });
    this.params.type = this.findReportClass();
    await this.getType();
    await this.fetchUserOtherPhotos();
  }

  scrollImageToCentre(index: number): void {
    let canvas;
    let imgContainer;
    let gapAroundPhoto = 0;
    const { aiResponse }: any = this.instantCheckups[index];
    if (index === 0) {
      canvas = this.canvas1;
      imgContainer = this.img1Container;
    } else {
      canvas = this.canvas2;
      imgContainer = this.img2Container;
    }
    let reducedPropositionWidth = aiResponse.imageWidth / canvas.nativeElement.getBoundingClientRect().width;
    const reducedProposition = aiResponse.imageHeight / canvas.nativeElement.getBoundingClientRect().height;
    if (((aiResponse.faceBox[2] - aiResponse.faceBox[0]) / reducedPropositionWidth) < 400) {
      while (((aiResponse.faceBox[2] - aiResponse.faceBox[0]) / reducedPropositionWidth) >= 400) {
        reducedPropositionWidth = aiResponse.imageWidth / canvas.nativeElement.getBoundingClientRect().width;
        this.loop(canvas.nativeElement, 20);
      }
    }
    if (((aiResponse.faceBox[3] - aiResponse.faceBox[1]) / reducedProposition) < 360) {
      gapAroundPhoto = (aiResponse.faceBox[3] - aiResponse.faceBox[1]) / (2 * reducedProposition);
    }
    const scrollableHeight = (aiResponse.faceBox[1] / reducedProposition) - gapAroundPhoto;
    imgContainer.nativeElement.scrollBy(0, scrollableHeight);
  }

  async getType(): Promise<void> {
    switch (this.photoType) {
      case ApiClientConstant.InstantCheckup.Type.FRONT_FACE: {
        this.type = [ApiClientConstant.InstantCheckup.Type.FRONT_FACE];
        break;
      }
      case ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE:
      case ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE:
      case ApiClientConstant.InstantCheckup.Type.SIDE_FACE: {
        this.type = [ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE,
          ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE,
          ApiClientConstant.InstantCheckup.Type.SIDE_FACE];
        break;
      }
      case ApiClientConstant.InstantCheckup.Type.HAIR:
      case ApiClientConstant.InstantCheckup.Type.HAIR_FRONT:
      case ApiClientConstant.InstantCheckup.Type.HAIR_TOP: {
        this.type = [ApiClientConstant.InstantCheckup.Type.HAIR,
          ApiClientConstant.InstantCheckup.Type.HAIR_FRONT,
          ApiClientConstant.InstantCheckup.Type.HAIR_TOP];
        break;
      }
      case ApiClientConstant.InstantCheckup.Type.BODY: {
        this.type = [ApiClientConstant.InstantCheckup.Type.BODY];
        break;
      }
      default:
    }
  }

  async fetchUserOtherPhotos(): Promise<void> {
    const allUserPhotos: Array<any> = await this.conn.fetchUserInstantCheckup({
      userId: this.params.username,
      type: this.type,
      project: ['imagePath', 'compressedImagePath', 'aiResponse', 'dimension', 'type'],
    });
    allUserPhotos.forEach((each: any): any => {
      switch (each.type) {
        case ApiClientConstant.InstantCheckup.Type.FRONT_FACE: {
          this.frontFacePhotos.push(each);
          break;
        }
        case ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE:
        case ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE:
        case ApiClientConstant.InstantCheckup.Type.SIDE_FACE: {
          this.sideFacePhotos.push(each);
          break;
        }
        case ApiClientConstant.InstantCheckup.Type.HAIR:
        case ApiClientConstant.InstantCheckup.Type.HAIR_FRONT:
        case ApiClientConstant.InstantCheckup.Type.HAIR_TOP: {
          this.hairPhotos.push(each);
          break;
        }
        case ApiClientConstant.InstantCheckup.Type.BODY: {
          this.bodyPhotos.push(each);
          break;
        }
        default:
      }
    });
  }

  ngAfterViewChecked(): void {
    this.img1Container.nativeElement.style.width = `${this.MIN_CANVAS_WIDTH}px`;
    this.img1Container.nativeElement.style.height = `${this.MIN_CANVAS_HEIGHT}px`;
    this.img2Container.nativeElement.style.width = `${this.MIN_CANVAS_WIDTH}px`;
    this.img2Container.nativeElement.style.height = `${this.MIN_CANVAS_HEIGHT}px`;
  }

  findReportClass(): string {
    let type;
    switch (this.photoType) {
      case ApiClientConstant.InstantCheckup.Type.FULL_FACE:
      case ApiClientConstant.InstantCheckup.Type.FRONT_FACE:
      case ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE:
      case ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE:
      case ApiClientConstant.InstantCheckup.Type.SIDE_FACE: {
        type = ApiClientConstant.Regimen.Class.FACE;
        break;
      }
      case ApiClientConstant.InstantCheckup.Type.HAIR:
      case ApiClientConstant.InstantCheckup.Type.HAIR_FRONT:
      case ApiClientConstant.InstantCheckup.Type.HAIR_TOP: {
        type = ApiClientConstant.Regimen.Class.HAIR;
        break;
      }
      default: {
        type = ApiClientConstant.Regimen.Class.BODY;
      }
    }
    return type;
  }

  imgLoaded(index: number): void {
    const image: any = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = (): any => {
      const canvas = index === 0 ? this.windowService.nativeWindow.document.getElementById(`canvasOne${this.params.index}`)
        : this.windowService.nativeWindow.document.getElementById(`canvasTwo${this.params.index}`);
      const sliderCanvas = index === 0 ? this.sliderCanvas1.nativeElement : this.sliderCanvas2.nativeElement;
      EXIF.getData(image, () => {
        this.imageData[index] = image;
        this.drawImageOnCanvas(index);
        canvas.style.width = `${this.MIN_CANVAS_WIDTH}px`;
        sliderCanvas.style.width = `${this.MIN_CANVAS_WIDTH}px`;
        this.calculateCoordinates(false);
        setTimeout(() => {
          this.scrollImageToCentre(index);
        }, 500);
      });
    };
    image.src = this.imageURL[index];
  }

  drawImageOnCanvas(index: number, orientation_?: number): void {
    const image = this.imageData[index];
    const canvas = index === 0 ? this.canvas1.nativeElement : this.canvas2.nativeElement;
    const sliderCanvas = index === 0 ? this.sliderCanvas1.nativeElement : this.sliderCanvas2.nativeElement;
    const sliderCtx = sliderCanvas.getContext('2d');
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, image.width, image.height);
    sliderCtx.clearRect(0, 0, image.width, image.height);

    const ic = this.instantCheckups[index];
    const rotationNeeded = this.conn.rotationNeeded();
    const orientation = orientation_ || ic.orientation || (rotationNeeded ? EXIF.getTag(image, 'Orientation') : 0);

    if ([5, 6, 7, 8].includes(orientation)) {
      // noinspection JSSuspiciousNameCombination
      canvas.width = image.height;
      // noinspection JSSuspiciousNameCombination
      canvas.height = image.width;
      rotateCanvasBasedOnOrientation(orientation, ctx, canvas);
      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.height, canvas.width);

      // noinspection JSSuspiciousNameCombination
      sliderCanvas.width = image.height;
      // noinspection JSSuspiciousNameCombination
      sliderCanvas.height = image.width;
      rotateCanvasBasedOnOrientation(orientation, sliderCtx, sliderCanvas);
      sliderCtx.drawImage(image, 0, 0, image.width, image.height, 0, 0, sliderCanvas.height, sliderCanvas.width);
    } else {
      canvas.height = image.height;
      canvas.width = image.width;
      rotateCanvasBasedOnOrientation(orientation, ctx, canvas);
      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

      sliderCanvas.height = image.height;
      sliderCanvas.width = image.width;
      rotateCanvasBasedOnOrientation(orientation, sliderCtx, sliderCanvas);
      sliderCtx.drawImage(image, 0, 0, image.width, image.height, 0, 0, sliderCanvas.width, sliderCanvas.height);
    }
  }

  rotateLeftImage(index: number): void {
    this.drawImageOnCanvas(index, 8);
    this.instantCheckups[index].orientation = 8;
    this.calculateCoordinates();
  }

  rotateRightImage(index: number): void {
    this.drawImageOnCanvas(index, 6);
    this.instantCheckups[index].orientation = 6;
    this.calculateCoordinates();
  }

  resetOrientation(index: number): void {
    this.instantCheckups[index].orientation = -1;
    this.drawImageOnCanvas(index);
    this.calculateCoordinates();
  }

  startZoomingIn(firstImage: boolean): void {
    const curImage: HTMLImageElement = firstImage ? this.canvas1.nativeElement : this.canvas2.nativeElement;
    this.timeInterval = setInterval(() => this.loop(curImage, 20), 50);
  }

  startZoomingOut(firstImage: boolean): void {
    const curImage: HTMLImageElement = firstImage ? this.canvas1.nativeElement : this.canvas2.nativeElement;
    this.timeInterval = setInterval(() => this.loop(curImage, -20), 50);
  }

  stopZooming(): void {
    this.calculateCoordinates();
    clearInterval(this.timeInterval);
    this.timeInterval = null;
  }

  loop(curImage: HTMLImageElement, step: number): void {
    const localImage = curImage;
    const { width }: any = curImage.getBoundingClientRect();
    if ((width + step) <= this.MIN_CANVAS_WIDTH) return;
    localImage.style.width = `${(curImage.clientWidth + step)}px`;
  }

  updateComparisonOutput(): void {
    Object.keys(this.imageConfig).forEach((each: any, index: number) => {
      const imageConfig = this.imageConfig[each];
      const image = index === 0 ? this.sliderCanvas1 : this.sliderCanvas2;
      if (imageConfig.width) {
        image.nativeElement.style.width = imageConfig.width;
      }
      if (imageConfig.height) {
        image.nativeElement.style.height = imageConfig.height;
      }
      const adjustLeft = (this.MIN_CANVAS_WIDTH - this.container.nativeElement.offsetWidth) / 2;
      image.nativeElement.style.top = `-${imageConfig.top}px`;
      image.nativeElement.style.left = `-${imageConfig.left}px`;
      if (adjustLeft > 0) {
        image.nativeElement.style.left = `-${imageConfig.left + adjustLeft}px`;
      }
    });
  }

  calculateCoordinates(skipUpdate?: boolean): void {
    const container1BoundingRectangle: DOMRect = this.img1Container.nativeElement.getBoundingClientRect();
    const container2BoundingRectangle: DOMRect = this.img2Container.nativeElement.getBoundingClientRect();
    const img1BoundingRectangle: DOMRect = this.canvas1.nativeElement.getBoundingClientRect();
    const img2BoundingRectangle: DOMRect = this.canvas2.nativeElement.getBoundingClientRect();
    this.instantCheckups.forEach((each: any, index: number) => {
      const [canvas, container, image]: any = index === 0
        ? [this.canvas1.nativeElement, container1BoundingRectangle, img1BoundingRectangle]
        : [this.canvas2.nativeElement, container2BoundingRectangle, img2BoundingRectangle];
      this.imageConfig[index === 0 ? 'after' : 'before'] = {
        compressedImage: !!this.instantCheckups[index].compressedImagePath,
        width: `${canvas.clientWidth}px`,
        height: `${canvas.clientHeight}px`,
        left: (container.left - image.left),
        top: (container.top - image.top),
      };
    });
    if (!skipUpdate) {
      this.onZoomChange.emit(this.imageConfig);
    }
    this.updateComparisonOutput();
  }

  saveOrientation(): any {
    this.conn.saveOrientation(this.instantCheckups[0], this.instantCheckups[1]);
  }

  changePhoto(direction: number): void {
    switch (this.photoType) {
      case ApiClientConstant.InstantCheckup.Type.FRONT_FACE: {
        const frontFace = this.frontFacePhotos.findIndex((each: any): any => each.objectId === this.instantCheckups[1].objectId);
        if ((direction === -1 && frontFace <= 0)
          || (direction === 1 && frontFace >= this.frontFacePhotos.length - 1)
          || this.moment(this.frontFacePhotos[frontFace + direction].createdAt).isSameOrAfter(this.instantCheckups[0].createdAt, 'day')) {
          this.broadcaster.broadcast('NOTIFY', { message: 'No More Photos To Show',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.instantCheckups[1] = this.frontFacePhotos[frontFace + direction];
        this.imageURL[1] = this.instantCheckups[1].compressedImagePath || this.instantCheckups[1].imagePath;
        this.imgLoaded(1);
        break;
      }
      case ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE:
      case ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE:
      case ApiClientConstant.InstantCheckup.Type.SIDE_FACE: {
        const sideFace = this.sideFacePhotos.findIndex((each: any): any => each.objectId === this.instantCheckups[1].objectId);
        if ((direction === -1 && sideFace <= 0)
        || (direction === 1 && sideFace >= this.sideFacePhotos.length - 1)
        || this.moment(this.sideFacePhotos[sideFace + direction].createdAt).isSameOrAfter(this.instantCheckups[0].createdAt, 'day')) {
          this.broadcaster.broadcast('NOTIFY', { message: 'No More Photos To Show',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.instantCheckups[1] = this.sideFacePhotos[sideFace + direction];
        this.imageURL[1] = this.instantCheckups[1].compressedImagePath || this.instantCheckups[1].imagePath;
        this.imgLoaded(1);
        break;
      }
      case ApiClientConstant.InstantCheckup.Type.HAIR:
      case ApiClientConstant.InstantCheckup.Type.HAIR_FRONT:
      case ApiClientConstant.InstantCheckup.Type.HAIR_TOP: {
        const hairPhoto = this.hairPhotos.findIndex((each: any): any => each.objectId === this.instantCheckups[1].objectId);
        if ((direction === -1 && hairPhoto <= 0)
        || (direction === 1 && hairPhoto >= this.hairPhotos.length - 1)
        || this.moment(this.hairPhotos[hairPhoto + direction].createdAt).isSameOrAfter(this.instantCheckups[0].createdAt, 'day')) {
          this.broadcaster.broadcast('NOTIFY', { message: 'No More Photos To Show',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.instantCheckups[1] = this.hairPhotos[hairPhoto + direction];
        this.imageURL[1] = this.instantCheckups[1].compressedImagePath || this.instantCheckups[1].imagePath;
        this.imgLoaded(1);
        break;
      }
      case ApiClientConstant.InstantCheckup.Type.BODY: {
        const bodyPhoto = this.bodyPhotos.findIndex((each: any): any => each.objectId === this.instantCheckups[1].objectId);
        if ((direction === -1 && bodyPhoto <= 0)
        || (direction === 1 && bodyPhoto >= this.bodyPhotos.length - 1)
        || this.moment(this.bodyPhotos[bodyPhoto + direction].createdAt).isSameOrAfter(this.instantCheckups[0].createdAt, 'day')) {
          this.broadcaster.broadcast('NOTIFY', { message: 'No More Photos To Show',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.instantCheckups[1] = this.bodyPhotos[bodyPhoto - 1];
        this.imageURL[1] = this.instantCheckups[1].compressedImagePath || this.instantCheckups[1].imagePath;
        this.imgLoaded(1);
        break;
      }
      default:
    }
    this.onChangePhoto.emit(this.instantCheckups[1]);
  }
}
