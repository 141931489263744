
<div class="badge bg-black d-flex text-white" *ngIf="regimen">
  <div>{{regimenId}}</div><i class="fa fa-times pl-2 pr-2 pointer" (click)="reset()"></i>
</div>
<div class="d-flex" *ngIf="!regimen">
  <input class="form-control p-0" [(ngModel)]="regimenId" required="required" type="text" name="{{name}}_verify" style="width:1px;"/>
  <input class="form-control" type="text" placeholder="Select REGIMEN." name="{{name}}_select" autocomplete="off" matInput="matInput" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
  <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
    <mat-option *ngFor="let option of regimenOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteRegimenOnSelect(option)">{{option.name}}</mat-option>
  </mat-autocomplete>
</div>