import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { AppConfig } from '../../../../app.config';

@Component({ selector: 'assign-chat-confirmation', templateUrl: './assign-chat-confirmation.html' })
export class AssignChatConfirmationComponent {
  assignedTeams: Array<string>;
  message: string;
  type: string;
  team: string;
  attendToChatCache: any;
  skipConfirmation: boolean;

  static parameters: any = [MatDialogRef, Injector, AppConfig];
  constructor(
    public dialogRef: MatDialogRef<AssignChatConfirmationComponent>,
    public injector: Injector,
    public appConfig: AppConfig) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.assignedTeams = data.assignedTeams;
    this.attendToChatCache = data.attendToChatCache;
    this.team = data.team;
    this.type = data.type;
    this.skipConfirmation = data.skipConfirmation;
    if (this.skipConfirmation) {
      this.removeTeam();
    }
  }

  async removeTeam(context: Record<string, string> = {}): Promise<void> {
    try {
      const indexOfTeam = this.assignedTeams.indexOf(this.team);
      this.assignedTeams.splice(indexOfTeam, 1);
      await this.attendToChatCache.save({ teams: this.assignedTeams }, { context: { ...context, message: this.message } });
      this.dialogRef.close(true);
    } catch (error) {
      if (error.message?.acknowledgementField === 'orderCreatedConfirmed') {
        this.type = 'orderCreated';
        return;
      }
      alert(error.message || error);
    }
  }

  async addTeam(): Promise<void> {
    try {
      const assignToChatSupport: boolean = this.assignedTeams.length === 1
        && this.assignedTeams.includes(this.team)
        && this.team === ApiClientConstant.Role.Name.CHAT_SUPPORT;
      if (!this.assignedTeams.includes(this.team)) {
        this.assignedTeams.push(this.team);
      }
      await this.attendToChatCache.save({ teams: this.assignedTeams }, { context: { message: this.message, assignToChatSupport } });
      this.dialogRef.close(true);
    } catch (error) {
      alert(error.message || error);
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
