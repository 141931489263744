import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from '../../../components/directives.module';
import { TreeEditComponent } from './tree-edit.component';

const ROUTES: Routes = [
  { path: '', component: TreeEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [TreeEditComponent],
  exports: [TreeEditComponent],
})
export class TreeEditModule {
}
