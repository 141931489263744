import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { RegimenType } from '../../../../../../typings/client/regimen';

@Component({
  selector: 'regimen-edit-part-of-day',
  templateUrl: './regimen-edit-part-of-day.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class RegimenEditPartOfDayComponent {
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input('title') title: string;
  @Input('variant') variant: string;
  @Input('reset')
  set onUpdateReset(reset: boolean) {
    this.onInputChange();
  }
  @Input('hideNotNecessaryThings') hideNotNecessaryThings: boolean;
  @Output('onProductUpdate') onProductUpdate: EventEmitter<any> = new EventEmitter();
  @Output('onProductUpdateRegimenEditReason') onProductUpdateRegimenEditReason: EventEmitter<any> = new EventEmitter();

  products: Array<RegimenType.Product>;
  parseObj: any;
  name: string;

  onInputChange(): void {
    if (!this.parseObj || !this.name) return;
    this.products = this.parseObj.get(this.name) || [];
  }

  addProduct(): void {
    this.products.push({});
    this.updateProducts();
    this.onProductUpdateRegimenEditReason.emit();
    this.onProductUpdate.emit();
  }

  updateProducts(): void {
    this.parseObj.set(this.name, this.products);
  }

  productChanged(): void {
    this.updateProducts();
    this.onProductUpdateRegimenEditReason.emit();
    this.onProductUpdate.emit();
  }

  moveProductDown(index: any): void {
    if (index >= this.products.length - 1) return;
    [this.products[index], this.products[index + 1]] = [this.products[index + 1], this.products[index]];
    this.updateProducts();
  }

  moveProductUp(index: any): void {
    if (index === 0) return;
    [this.products[index], this.products[index - 1]] = [this.products[index - 1], this.products[index]];
    this.updateProducts();
  }

  deleteProduct(product: RegimenType.Product): void {
    this.products = this.products.filter((item: RegimenType.Product) => (item !== product));
    this.updateProducts();
    this.onProductUpdateRegimenEditReason.emit();
    this.onProductUpdate.emit();
  }
}
