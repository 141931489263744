import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { DirectivesModule } from '../../../../components/directives.module';
import { ListComponent } from './list.component';

const ROUTES: Routes = [
  {
    path: '',
    component: ListComponent,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  DirectivesModule,
  MomentModule,
  RouterModule.forChild(ROUTES),
  AgGridAngular,
],
declarations: [ListComponent],
exports: [ListComponent] })
export class ListModule {}
