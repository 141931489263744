
<div class="hbox" *ngIf="questionObj">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/trees/questions">Question</a></li>
          <li>{{questionObj.get('uniqueIdentifier')}}</li>
        </ul>
        <div class="pull-right">
          <button class="btn-xs btn-primary-outline" routerLink="edit">Edit</button>
          <button class="btn-xs btn-primary-outline ml-3" routerLink="../../questions/new">New</button>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 p-4">
        <div class="col-xs-12 flex">
          <label class="col-xs-2 mt-0">Id</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{questionObj.get('uniqueIdentifier')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Title</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{questionObj.get('title')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Type</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{questionObj.get('type') || apiClientConstant.Question.Type.SEND_TO_USER}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Evaluate Function</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{questionObj.get('evaluateFunction')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Table</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{questionObj.get('table')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Question Type</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{getQuestionType()}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="questionType === 1">
          <label class="col-xs-2">Options</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">
            <div class="badge mr-3" *ngFor="let button of questionObj.get('inputs')">{{button.text}}</div>
          </div>
        </div>
        <div class="col-xs-12 flex mt-3">
          <div class="col-xs-2">
            <input class="w100" [(ngModel)]="userId" placeholder="Enter userId (username))"/>
          </div>
          <button class="ml-3" (click)="sendToUser()">send to user</button>
        </div>
      </div>
      <div class="col-xs-12 no-padder mt-3" *ngIf="trees.length">
        <div class="col-xs-12 list-header mb-2 pl-4">All Trees Using This Question</div>
        <div class="col-xs-12 list-body fontL">
          <div class="col-xs-12 pl-4 mb-3" *ngFor="let tree of trees"><a class="text-info pointer" routerLink="../../../tree/{{tree.get('name').split('_')[0]}}/{{tree.get('type').toLowerCase()}}/{{tree.get('name')}}" target="_blank">{{tree.get('name')}}</a></div>
        </div>
      </div>
    </div>
  </div>
</div>