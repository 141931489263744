import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TriggerByKeywordComponent } from './trigger-by-keyword.component';
import { ListModule } from './list';
import { EditModule } from './edit';
import { ViewModule } from './view';

const ROUTES: Routes = [
  {
    path: '',
    component: TriggerByKeywordComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'new',
    component: TriggerByKeywordComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: ':id/edit',
    component: TriggerByKeywordComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: ':id',
    component: TriggerByKeywordComponent,
    loadChildren: (): any => ViewModule,
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [TriggerByKeywordComponent],
  exports: [TriggerByKeywordComponent],
})
export class TriggerByKeywordModule { }
