import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MomentModule } from 'ngx-moment';
import { DoctorNotesModule } from 'src/components/doctor-notes';
import { FollowUpReportCreationModule } from 'src/components/followUp-report-creation';
import { FollowupChatViewModule } from 'src/components/followup-chat-view-summary';
import { InputSelectTextModule } from 'src/components/input/input-select-text';
import { InstantCheckupMagnifierModule } from 'src/components/instant-checkup-magnifier';
import { RegimenProductsEditModule } from 'src/components/regimen-products-edit';
import { RegimenProductsViewModule } from 'src/components/regimen-products-view';
import { SupportTicketListModule } from 'src/components/ticket-list';
import { VoiceRecordingModule } from 'src/components/voice-recording';
import { InputTextModule } from 'src/components/input/input-text';
import { DirectivesModule } from '../../../components/directives.module';
import { OrderApprovalComponent } from './order-approval.component';
import { InputSelectModule } from '../../../components/input/input-select';
import { InputCheckboxModule } from '../../../components/input/input-checkbox';
import { UserChatViewModule } from '../../../components/user-chat-view';
import { UserNoteModule } from '../../../components/user-notes';
import { LanguageStringMultipleSelectionModule } from '../../../components/language-string/language-string-multiple-selection';
import { CannedResponseSelectionModule } from '../../../components/canned-response/canned-response-selection';
import { RegimenEditWithoutRouteModule } from '../../product/regimen/edit/regimen-edit-without-route.module';
import { EditReasonPopupModal } from '../approval/edit-reason';
import { OrderNoteModule } from '../view/order-note/order-note.module';
import { InstantCheckupModule } from '../../instant-checkup';
import { AudioRecorderModule } from '../../../components/audio-recorder/audio-recorder.module';
import { ConsentFormModule } from '../../../components/consent-form/index';

const ROUTES: Routes = [
  { path: '', component: OrderApprovalComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    InputSelectModule,
    AudioRecorderModule,
    InputCheckboxModule,
    MatAutocompleteModule,
    MatDialogModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
    MatSelectModule,
    UserChatViewModule,
    UserNoteModule,
    MomentModule,
    RegimenEditWithoutRouteModule,
    LanguageStringMultipleSelectionModule,
    CannedResponseSelectionModule,
    OrderNoteModule,
    InstantCheckupModule,
    FollowupChatViewModule,
    InputSelectTextModule,
    InstantCheckupMagnifierModule,
    RegimenProductsViewModule,
    DoctorNotesModule,
    SupportTicketListModule,
    VoiceRecordingModule,
    InputTextModule,
    RegimenProductsEditModule,
    FollowUpReportCreationModule,
    ConsentFormModule,
  ],
  declarations: [OrderApprovalComponent, EditReasonPopupModal],
  exports: [OrderApprovalComponent],
})
export class OrderApprovalModule {
}
