<div class="invoice-container">
  <div class="flex space-between">
    <div class="title">Amount</div>
    <div class="value">₹{{orderObj?.actualPrice}}</div>
  </div>
  <div class="flex space-between" *ngIf="showWalletDiscount">
    <div class="title">Wallet Balance</div>
    <div class="value">-₹{{ walletDiscount }} </div>
  </div>
  <div class="flex space-between">
    <div class="title">Discount</div>
    <div class="value">-{{discount}}% (₹{{orderObj?.actualPrice - orderObj?.amount}})</div>
  </div>
  <hr>
  <div class="flex space-between fw-700">
    <div class="title">Total</div>
    <div class="value">₹{{orderObj?.amount}}</div>
  </div>

  <div class="coupon" *ngIf="!coupon">
    <input type="text" class="coupon-field" [(ngModel)]="couponCode" placeholder="ENTER COUPON CODE" [disabled]="orderObj.stage !== 'INITIAL' && orderObj.stage !== 'ONLINE_PAYMENT_PENDING'">
    <div class="apply pointer" (click)="applyCoupon()">Apply</div>
  </div>

  <div class="col-xs-12" *ngIf="coupon">
    <div class="col-xs-12 no-padder no-margin"><span class="pR15">Coupon Applied :</span><a
        class="btn btn-success btn-sm">{{coupon.code}}</a></div>
  </div>

  <!-- <div class="address pointer">+ Add address</div> -->
  <div class="address payment-container">
    <ng-container>
      <select name="" id="" [(ngModel)]="selectedPaymentOption" [disabled]="orderObj.stage !== 'INITIAL' && orderObj.stage !== 'ONLINE_PAYMENT_PENDING'">
        <ng-container *ngFor="let option of paymentOptions">
          <option [value]="option">Payment Mode: {{option}}</option>
        </ng-container>
      </select>
      <hr>
      <ng-container *ngIf="orderObj.stage === 'INITIAL' || orderObj.stage === 'ONLINE_PAYMENT_PENDING'">
        <div class="flex mt-2">
          <div class="title">
            <span class="no-bold">Place {{selectedPaymentOption}} order of {{orderObj.amount}}</span>
          </div>
          <div class="action pointer ml-2" (click)="placeOrder()">click here</div>
        </div>
      </ng-container>
      <ng-container *ngIf="orderObj.stage !== 'INITIAL' && false">
        <div class="flex mt-2 space-between">
          <div class="title">
            <span class="no-bold">Order Status:</span>
            <span class="ml-2 canceled">{{orderObj.stage}}</span>
          </div>
          <div class="action pointer ml-2">Reorder</div>
          <div class="action pointer ml-2">Cancel Order</div>
        </div>
        <div class="last-updated">Placed by Lalita, 2:30 pm, 24 feb</div>
        <div class="last-updated">cancelled by Lalita, 4 pm, 28 feb</div>
      </ng-container>
      <ng-container *ngIf="orderObj.stage !== 'INITIAL' && orderObj.stage !== 'ONLINE_PAYMENT_PENDING'">
        <div class="flex mt-8 space-between">
          <div class="cod-order">{{orderObj.paymentType}} order of ₹{{orderObj.amount}} </div>
          <div *ngIf="orderObj.stage !== 'CANCELED' && orderObj.stage !== 'DELIVERED'" class="cancel-order pointer" (click)="cancelOrder()">Cancel order</div>
          <div *ngIf="orderObj.stage === 'CANCELED'" class="action pointer ml-2" (click)="reorder()">Reorder</div>
        </div>
        <hr>
        <div class="flex mt-2">
          <div class="title">Order Status: {{orderObj.stage}}</div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <!-- Pending -->
  <div class="flex mt-4" *ngIf="false">
    <div class="title pending">Payment Status:</div>
    <div class="action status pending">CCOD (₹20 pending)</div>
    <div class="action edit pointer">Edit</div>
  </div>
  <!-- CCOD Paid -->
  <ng-container *ngIf="false">
    <div class="flex mt-4">
      <div class="title">Payment Status:</div>
      <div class="action status paid">₹20 paid</div>
      <div class="action status pending ml-2">₹1480 cash due at delivery</div>
      <img src="/assets/images/refresh.svg" alt="" class="icon pointer ml-2">
      <div class="action edit pointer">Edit</div>
    </div>
    <div class="last-updated">Last updated 24 may, 3 pm </div>
  </ng-container>
  <!-- COD Paid -->
  <ng-container *ngIf="false">
    <div class="flex mt-4">
      <div class="title">Payment Status:</div>
      <div class="action status paid">₹1500 paid</div>
      <img src="/assets/images/refresh.svg" alt="" class="icon pointer ml-2">
      <div class="action edit pointer">Edit</div>
    </div>
    <div class="last-updated">Last updated 24 may, 3 pm </div>
  </ng-container>
  <!-- COD Selected -->
  <ng-container *ngIf="false">
    <div class="flex mt-4">
      <div class="title">Payment Status:</div>
      <div class="action status">NA</div>
      <img src="/assets/images/refresh.svg" alt="" class="icon pointer ml-2">
      <div class="action edit pointer">Edit</div>
    </div>
    <div class="last-updated">Last updated 24 may, 3 pm </div>
  </ng-container>
  <!-- payment pending -->
  <div class="flex mt-4" *ngIf="false">
    <div class="title">Payment Status:</div>
    <div class="action status pending">₹1500 cash due at delivery</div>
    <img src="/assets/images/refresh.svg" alt="" class="icon pointer ml-2">
    <div class="action edit pointer">Edit</div>
  </div>
</div>