import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserComponent } from './user.component';
import { ListModule } from './list';
import { LeavesAndHolidaysModule } from './leaves-and-holidays';
import { InternalUserModule } from './internal-user';
import { DoctorModule } from './doctor';
import { SignatureCropPopupModule } from '../../components/signature-crop-popup/index';

const ROUTES: Routes = [
  {
    path: 'users',
    component: UserComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  }, {
    path: 'users',
    component: UserComponent,
    loadChildren: (): any => DoctorModule,
  }, {
    path: 'users',
    component: UserComponent,
    loadChildren: (): any => LeavesAndHolidaysModule,
  }, {
    path: 'users',
    component: UserComponent,
    loadChildren: (): any => InternalUserModule,
  },
];

@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [UserComponent],
exports: [UserComponent] })
export class UserModule {}
