import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, UntypedFormControl, NgForm } from '@angular/forms';
import { ApiClientConstant } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'question-condition',
  templateUrl: './question-condition.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class QuestionConditionComponent {
  @Input('inputVariables') inputVariables: Array<string> = [];
  @Input('conditions') conditions: Array<any> = [];
  @Input('type')
  set typeAndOperand(type: string) {
    if (!type) return;
    this.type = type;
    switch (this.type) {
      case ApiClientConstant.Question.Conditions.Type.AND: {
        this.operand = ApiClientConstant.Question.Conditions.Operand.AND;
        break;
      }
      case ApiClientConstant.Question.Conditions.Type.OR: {
        this.operand = ApiClientConstant.Question.Conditions.Operand.OR;
        break;
      }
      default:
        this.operand = ApiClientConstant.Question.Conditions.Operand.AND;
    }
  }
  @Input('prefix') prefix: string = '0';
  @Output('conditionUpdated') conditionUpdated: EventEmitter<void> = new EventEmitter<void>();

  formController: UntypedFormControl = new UntypedFormControl();
  type: string = ApiClientConstant.Question.Conditions.Type.AND;
  operand: string = ApiClientConstant.Question.Conditions.Operand.AND;

  types: Array<{ key: string, value: string}>;
  operands: Array<{ key: string, value: string}>;
  instantCheckupTypes: Array<{ key: string, value: string}>;
  comparators: Array<{ key: string, value: string}>;
  instantCheckupIssues: Array<{ key: string, value: string}>;
  activeRegimenClasses: Array<{ key: string, value: string}>;
  comparatorConfig: Array<{ key: string, comparator: Array<string>}>;
  orderStages: Array<Record<string, any>>;

  constructor(public appConfig: AppConfig) {
    this.types = Object.keys(ApiClientConstant.Question.Conditions.Type)
      .map((key: any) => ({ key, value: ApiClientConstant.Question.Conditions.Type[key] }));
    this.operands = Object.keys(ApiClientConstant.Question.Conditions.Operand)
      .map((key: any) => ({ key, value: ApiClientConstant.Question.Conditions.Operand[key] }));
    this.instantCheckupTypes = Object.keys(ApiClientConstant.InstantCheckup.Type)
      .map((key: any) => ({ key, value: ApiClientConstant.InstantCheckup.Type[key] }));
    this.instantCheckupIssues = Object.keys(this.appConfig.Shared.InstantCheckup.Concern)
      .map((key: any) => ({ key, value: this.appConfig.Shared.InstantCheckup.Concern[key] }));
    this.activeRegimenClasses = Object.keys(ApiClientConstant.Regimen.Class)
      .map((key: any) => ({ key, value: ApiClientConstant.Regimen.Class[key] }));
    this.orderStages = [
      ApiClientConstant.Order.Stage.BEFORE_TRANSIT,
      ApiClientConstant.Order.Stage.FORWARD_TRANSIT,
      ApiClientConstant.Order.Stage.DELIVERED,
      ApiClientConstant.Order.Stage.COMPLETED,
      ApiClientConstant.Order.Stage.ANY_NON_PARTIAL,
    ].map((key: any) => ({ key, value: key }));
    this.comparators = Object.keys(ApiClientConstant.Question.Conditions.Comparator)
      .map((key: any) => ({ key, value: ApiClientConstant.Question.Conditions.Comparator[key] }));
    const conditionConstants = ApiClientConstant.Question.Conditions;
    this.comparatorConfig = [
      {
        key: conditionConstants.Type.INSTANT_CHECKUP,
        comparator: [
          conditionConstants.Comparator.EQUAL,
          conditionConstants.Comparator.NOT_EQUAL,
          conditionConstants.Comparator.GREATER_THAN,
          conditionConstants.Comparator.GREATER_THAN_OR_EQUAL,
          conditionConstants.Comparator.LESS_THAN,
          conditionConstants.Comparator.LESS_THAN_OR_EQUAL,
        ],
      },
      {
        key: conditionConstants.Type.MATCH_WITH_CONTEXT,
        comparator: [
          conditionConstants.Comparator.EQUAL,
          conditionConstants.Comparator.NOT_EQUAL,
          conditionConstants.Comparator.GREATER_THAN,
          conditionConstants.Comparator.GREATER_THAN_OR_EQUAL,
          conditionConstants.Comparator.LESS_THAN,
          conditionConstants.Comparator.LESS_THAN_OR_EQUAL,
          conditionConstants.Comparator.EXISTS,
          conditionConstants.Comparator.NOT_EXISTS,
        ],
      },
      {
        key: conditionConstants.Type.ACTIVE_REGIMEN,
        comparator: [
          conditionConstants.Comparator.EXISTS,
          conditionConstants.Comparator.NOT_EXISTS,
        ],
      },
      {
        key: conditionConstants.Type.ACTIVE_REGIMEN_HAVING_CONCERN,
        comparator: [
          conditionConstants.Comparator.EQUAL,
          conditionConstants.Comparator.NOT_EQUAL,
        ],
      },
      {
        key: conditionConstants.Type.ALLOCATED_DOCTOR,
        comparator: [
          conditionConstants.Comparator.EXISTS,
          conditionConstants.Comparator.NOT_EXISTS,
        ],
      },
      {
        key: conditionConstants.Type.FOLLOWUP_STATE,
        comparator: [
          conditionConstants.Comparator.EQUAL,
          conditionConstants.Comparator.NOT_EQUAL,
        ],
      },
      {
        key: conditionConstants.Type.ANY_ORDER_IN_STAGE_GROUP,
        comparator: [
          conditionConstants.Comparator.EXISTS,
          conditionConstants.Comparator.NOT_EXISTS,
        ],
      },
      {
        key: conditionConstants.Type.QUESTION_ANSWER_MATCH,
        comparator: [
          conditionConstants.Comparator.EQUAL,
          conditionConstants.Comparator.NOT_EQUAL,
          conditionConstants.Comparator.GREATER_THAN,
          conditionConstants.Comparator.GREATER_THAN_OR_EQUAL,
          conditionConstants.Comparator.LESS_THAN,
          conditionConstants.Comparator.LESS_THAN_OR_EQUAL,
          conditionConstants.Comparator.EXISTS,
          conditionConstants.Comparator.NOT_EXISTS,
        ],
      },
      {
        key: conditionConstants.Type.NUMBER_OF_STEROID_USING,
        comparator: [
          conditionConstants.Comparator.EQUAL,
          conditionConstants.Comparator.NOT_EQUAL,
          conditionConstants.Comparator.GREATER_THAN,
          conditionConstants.Comparator.GREATER_THAN_OR_EQUAL,
          conditionConstants.Comparator.LESS_THAN,
          conditionConstants.Comparator.LESS_THAN_OR_EQUAL,
        ],
      },
    ];
  }

  addNewCondition(): void {
    this.conditions.push({ conditions: [] });
    this.conditionUpdated.emit();
  }

  removeCondition(index: number): void {
    this.conditions.splice(index, 1);
    this.conditionUpdated.emit();
  }

  updateComparator(type: ValueOf<typeof ApiClientConstant.Question.Conditions.Operand>): void {
    if ([ApiClientConstant.Question.Conditions.Type.AND, ApiClientConstant.Question.Conditions.Type.OR].includes(type)) {
      return;
    }
    const filteredComparator: any = this.comparatorConfig
      .filter((comparator: { key: string, comparator: Array<string> }) => comparator.key === type)[0];
    this.comparators = Object.keys(ApiClientConstant.Question.Conditions.Comparator)
      .filter((key: string) => filteredComparator.comparator.includes(ApiClientConstant.Question.Conditions.Comparator[key]))
      .map((key: any) => ({ key, value: ApiClientConstant.Question.Conditions.Comparator[key] }));
  }

  protected apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
