import { Component } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MetabaseServices } from '../../../../services/metabaseServices';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({ selector: 'event-list', templateUrl: './event-list.html', styleUrls: ['./event-list.scss'] })
export class EventListComponent {
  iframeLink: SafeResourceUrl;
  batchId: string;
  constructor(private broadcaster: Broadcaster,
              private appConfig: AppConfig,
              private metabaseServices: MetabaseServices) {
  }

  async applyFilter(): Promise<void> {
    try {
      this.iframeLink = await this.metabaseServices.fetchMetabaseQuestion({ linkName: 'BATCH_ID', batchId: this.batchId });
    } catch (error: any) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
