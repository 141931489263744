import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FAQComponent } from './faq.component';
import { ListModule } from './list';
import { EditModule } from './edit';
import { FAQResolve } from './faq-resolver';

const ROUTES: Routes = [
  {
    path: 'FAQ',
    component: FAQComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'FAQ/new',
    component: FAQComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: 'FAQ/:id/edit',
    component: FAQComponent,
    loadChildren: (): any => EditModule,
    resolve: { FAQ: FAQResolve },
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  RouterModule.forChild(ROUTES),
],
declarations: [FAQComponent],
exports: [FAQComponent],
providers: [FAQResolve] })
export class FAQModule {}
