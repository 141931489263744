import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'language-voice-view',
  templateUrl: './language-voice-view.html',
})
export class LanguageVoiceViewComponent {
  @Input('languageVoice')
  set updateLanguageVoice(languageVoice: any) {
    this.languageVoice = languageVoice ? languageVoice.toJSON() : {};
  }
  @Output('clickEdit') onClickEditEmitter: EventEmitter<any> = new EventEmitter();
  languageVoice: any;
}
