import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ConnectionService } from '../../services/connection-service';
import { WindowRefService } from '../../services/window-ref-service';

@Component({
  selector: 'popup-wrapper',
  templateUrl: './popup-wrapper.html',
  styleUrls: ['./popup-wrapper.scss'],
})
export class PopupWrapperComponent {
  heading: any;
  content: any;
  show: boolean = true;
  @Input('params') params: any = {};
  @Output('onHide') onHide: EventEmitter<any> = new EventEmitter();
  constructor(private el: ElementRef, private conn: ConnectionService, private window: WindowRefService) {
  }

  async ngOnInit(): Promise<any> {
  }

  hide(): void {
    this.show = !this.show;
    this.onHide.emit();
  }

  stopPropagation(event: any): void {
    if (event) event.stopPropagation();
  }
}
