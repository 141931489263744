import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectionService } from '../connection-service';
import { environment } from '../../environments/environment';

@Injectable()
export class ElasticService {
  headers: HttpHeaders;

  constructor(private http: HttpClient, private conn: ConnectionService) {
    const parseURL = this.conn.getParseUrl();
    if (['test', 'localhost'].some((each: string) => parseURL.includes(each))) {
      this.headers = new HttpHeaders({ Authorization: 'token test' });
    } else {
      this.headers = new HttpHeaders({ Authorization: 'token prod' });
    }
  }

  log(d: any): void {
    if (!environment.production) {
      return;
    }
    const data = d;
    data.production = !this.conn.isDevelopmentEnvironment;
    data.analytics = 'elastic-search';
    data.logSource = 'DOCTOR_WEB_APP';
    if (this.conn.isUserLoggedIn) {
      if (!data.params) data.params = {};
      data.params.username = this.conn.getCurrentUser().get('username');
    }
    this.http.post(`${this.conn.analyticsUrl}/api/web/log`, data, { headers: this.headers })
      .toPromise()
      .catch(() => {});
  }

  async logActivity(data: Array<any>): Promise<void> {
    if (!environment.production) {
      return;
    }
    await this.http.post(
      `${this.conn.analyticsUrl}/api/metric/doctor_website/withId`,
      data,
      { headers: this.headers }).toPromise();
  }
}
