
<mat-dialog-content>
  <div class="flex space-between">
    <h1>Select main concern</h1>
    <div class="flex space-between">
      <button class="btn-secondary" (click)="hideModal()">cancel</button>
      <button class="ml-2" (click)="updateTree()">Save</button>
    </div>
  </div>
  <div class="flex flex-container">
    <p class="pill" [ngClass]="{'selected': mainConcern?.get('value') === userConcern}" (click)="updateMainConcern(mainConcern)" *ngFor="let mainConcern of mainConcernOptions">{{mainConcern.get('value')}}</p>
  </div>
</mat-dialog-content>