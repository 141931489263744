import { Component, OnInit } from '@angular/core';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';

@Component({
  selector: 'support',
  template: '<router-outlet></router-outlet>',
})

export class SupportComponent implements OnInit {
  constructor(private conn: ConnectionService, private document: WindowRefService) {}
  async ngOnInit(): Promise<any> {
  }
}
