import { Component, Input } from '@angular/core';
import { ConnectionService } from 'src/services/connection-service';
import { OrderInfoHeaderComponent } from './components/order-info-header/order-info-header.component';
import { OrderInfoTagsComponent } from './components/order-info-tags/order-info-tags.component';
import { OrderInfoInvoiceComponent } from './components/order-info-invoice/order-info-invoice.component';
import { OrderInfoUserDataComponent } from './components/order-info-user-data/order-info-user-data.component';

@Component({
  selector: 'sales-order-info',
  standalone: true,
  imports: [
    OrderInfoHeaderComponent,
    OrderInfoTagsComponent,
    OrderInfoInvoiceComponent,
    OrderInfoUserDataComponent,
  ],
  templateUrl: './sales-order-info.component.html',
  styleUrl: './sales-order-info.component.scss',
})
export class SalesOrderInfoComponent {
  @Input('orderObj') orderObj: any;
  @Input('parseOrderObj') parseOrderObj: any;
  @Input('user') user: any;

  constructor(private conn: ConnectionService) { }
}
