import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TabNamingService {
  static parameters: any = [Title];
  constructor(private titleService: Title) {}

  setTitle(url: string, name?: string, pageName?: string): void {
    if (name) {
      this.titleService.setTitle(`${name.toUpperCase()} - ${pageName.toUpperCase()}`);
      return;
    }
    const title = this.getTitle(url);
    this.titleService.setTitle(title.toUpperCase());
  }

  private getTitle(urlString: string): string {
    if (urlString.substring(1).split('/').length === 1) {
      return (urlString.substring(1).split('?')[0]);
    }
    const section = urlString.substring(1).split('/')[0];
    const subSection = urlString.substring(1).split('/')[1];
    return `${subSection} - ${section}`;
  }
}
