
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Select Time Range</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="col-xs-12 flex"></div>
    <form class="col-xs-12 flex" role="form" name="bulkArticle" #timeForm="ngForm">
      <div class="col-xs-6">
        <label class="col-xs-12">start Time</label>
        <input class="col-xs-6" type="number" [(ngModel)]="startTimeHr" min="0" max="24" placeholder="Hr"/>
        <input class="col-xs-6" type="number" [(ngModel)]="startTimeMin" min="0" max="60" placeholder="Min"/>
      </div>
      <div class="col-xs-6 ml-5">
        <label class="col-xs-12">end Time</label>
        <input class="col-xs-6" type="number" [(ngModel)]="endTimeHr" min="0" max="24" placeholder="Hr"/>
        <input class="col-xs-6" type="number" [(ngModel)]="endTimeMin" min="0" max="60" placeholder="Min"/>
      </div>
    </form>
    <div class="col-xs-12">
      <label class="col-xs-12">copy from day</label>
      <select class="col-xs-6" [(ngModel)]="copyFromDay" name="text" (change)="copyTimeFromDay()">
        <option *ngFor="let day of weekDays" value="{{day}}">{{day}}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" [disabled]="!startTimeHr || !endTimeHr " (click)="saveTimeRange()">Save</button>
  </div>
</mat-dialog-content>