
<div class="div chart-wrapper horizontal" *ngIf="webActivityTime.length === 1">
  <div class="div">
    <label class="fw700 fontL3 bold">Activity Time Data</label>
  </div>
  <div class="div chart-container horizontal" id="chartContainer">
    <div *ngFor="let data of webActivityTime; let i = index">
      <div class="bar horizontal">
        <div class="fill horizontal" [style.width.%]="(data.productiveTime / maxWorkingHour) * 100" style="background-color: #5FA653" matTooltip="Productive Time: {{ convertToHoursAndMinutes(data.productiveTime)  }}">
        </div>
        <div class="fill horizontal" [style.width.%]="((data.activeTime - data.productiveTime) / maxWorkingHour) * 100" matTooltip="Active Time: {{ convertToHoursAndMinutes(data.activeTime) }}">
        </div>
      </div>
    </div>
  </div>
  <div class="div">
    <div class="div flex justifyFS gap-12" style="width: 150px;">
      <div class="div small-rect" style="background-color: #EBF1CE"></div><span class="bold">Active Time</span>
    </div>
    <div class="div flex justifyFS gap-12" style="width: 150px;">
      <div class="div small-rect" style="background-color: #5FA653"></div><span class="bold">Productive Time</span>
    </div>
  </div>
  <div class="div mT40">
    <div class="div"><span class="bold">First Login Time: {{firstLoginTime}}</span></div>
    <div class="div mT10"><span class="bold">Number of Breaks: {{numberOfBreaks}}</span></div>
  </div>
</div>
<div class="div chart-wrapper" *ngIf="webActivityTime.length > 1">
  <div class="div y-axis">
    <div class="label" *ngFor="let hour of getYAxisLabels()">{{ hour }} hrs</div>
  </div>
  <div class="div chart-container" id="chartContainer">
    <div class="barContainer" *ngFor="let data of webActivityTime; let i = index">
      <div class="bar">
        <div class="fill" [style.height.%]="(data.productiveTime / maxWorkingHour) * 100" [style.backgroundColor]="'#5FA653'" matTooltip="Productive Time: {{ convertToHoursAndMinutes(data.productiveTime) }}">
        </div>
        <div class="fill" [style.borderRadius.px]="0" [style.height.%]="((data.activeTime - data.productiveTime) / maxWorkingHour) * 100" matTooltip="Active Time: {{ convertToHoursAndMinutes(data.activeTime) }}">
        </div>
      </div>
      <div class="label">{{getFormattedDate(labels[i])}}</div>
    </div>
  </div>
</div>
<div class="div chart-wrapper horizontal">
  <label class="div fw700 fontL3 bold">{{user.get('DoctorDisplayName') || user.get('username')}}'s Stats</label>
  <div class="div flex mT30 alignFS flex-dir-col">
    <span class="bold">Average approval Time: {{convertToMinutesAndSecond(avgApprovalTime)}}</span>
    <span class="bold mT10">Average Checkup Time: {{convertToMinutesAndSecond(avgCheckupTime)}}</span>
    <span class="bold mT10">Avergae Ticket Time: {{convertToMinutesAndSecond(avgTicketTime)}}</span>
  </div>
</div>
