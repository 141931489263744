import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({ selector: 'response-title', templateUrl: './response-title.html' })
export class ResponseTitleComponent {
  title: string;
  public data: string;
  static parameters: any = [MatDialogRef, Injector];
  constructor(
    public dialogRef: MatDialogRef<ResponseTitleComponent>, public injector: Injector) {
    this.data = this.injector.get(MAT_DIALOG_DATA);
    this.title = this.data;
  }
}
