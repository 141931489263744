import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';

@Component({ selector: 'list-action-cell', templateUrl: './list-action-cell.html' })
export class ListActionCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  id: string;
  regimenId: string;
  type: string;
  ui: { regimenVoice: boolean; loading: boolean } = { regimenVoice: false, loading: false };

  constructor(private appConfig: AppConfig, private conn: ConnectionService) {}

  agInit(params: ICellRendererParams): void {
    this.id = params.value;
    this.params = params;
    if (!this.params.data) {
      return;
    }
    this.type = this.params.data.MessageBody;
    this.regimenId = this.params.data.MessageAttribute?.regimenId;
    this.ui.regimenVoice = this.type === this.appConfig.Shared.EventScheduler.Type.SEND_REGIMEN_INSTRUCTION;
  }

  async resetAttempt(): Promise<void> {
    try {
      this.ui.loading = true;
      await this.conn.resetFailedEvent(this.id);
      this.params.data.state = 'PENDING';
      this.params.api.refreshCells();
      this.ui.loading = false;
    } catch (error) {
      alert(error.message);
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
