import { Axios, AxiosResponse } from 'axios';
import { Models } from 'api-client';

export declare interface ServerAPIResponse {
  ExotelAuthToken: AxiosResponse<ServerAPIModels['ExotelAuthTokenResponse']>;
  MonitoringData: AxiosResponse<ServerAPIModels['MonitoringDataResponse']>;
  MonitoringDataInner: AxiosResponse<ServerAPIModels['MonitoringDataInnerResponse']>;
}

export declare interface ServerAPIModels {
  ExotelAuthTokenResponse: Models.GetExotelAuthToken200Response;
  MonitoringDataResponse: Models.GetData200Response;
  MonitoringDataInnerResponse: Models.GetData200ResponseResultInner;
}

export declare interface ServerAPIRequest {
  ExotelLogRequest: Models.TrackExotelCallLogRequest;
}
