import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { WindowRefService } from 'src/services/window-ref-service';
import { ApiClientConstant, ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from 'src/services/connection-service';
import { AppConfig } from 'src/app/app.config';
import { WaitingCallsActionComponent } from './waiting-call-action/waiting-call-action';

@Component({
  selector: 'waiting-call',
  templateUrl: './waiting-call.html',
  styleUrls: ['./waiting-call.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WaitingCallComponent implements OnInit {
  @Input() user: any;
  @Input() set refresh(refresh: boolean) {
    if (refresh) this.reset();
  }

  columnDefs: any;
  components: any;
  ui: any = {};
  gridApi: GridApi;
  dataSource: any;
  isRegimenConatainsTablet: any = {};
  count: number = 0;

  constructor(
    private windowRefService: WindowRefService,
    private connectionService: ConnectionService,
    public appConfig: AppConfig,
  ) {}

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params).then((data: Array<any>) => {
          if (params.startRow === 0 && !data.length) {
            this.gridApi.showNoRowsOverlay();
          } else {
            this.gridApi.hideOverlay();
          }
          params.successCallback(
            data,
            data.length === this.ui.grid.pageSize
              ? -1
              : params.startRow + data.length,
          );
        });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Action',
        field: 'objectId',
        width: 150,
        cellRenderer: WaitingCallsActionComponent,
        pinned: 'left',
      },
      {
        headerName: 'Name',
        field: 'user.PatientName',
        cellRenderer: (params: any): any => {
          let eDiv;
          if (!params.data) {
            eDiv = this.windowRefService.nativeWindow.document.createElement('div');
            eDiv.innerHTML = 'Loading ...';
            return eDiv;
          }
          eDiv = this.windowRefService.nativeWindow.document.createElement('div');
          let highlightedClass = '';
          if (this.isRegimenConatainsTablet[params.data.referenceId]) {
            highlightedClass = 'list-label list-label-bg-red';
          }
          eDiv.innerHTML = `<div class='${highlightedClass}'>${params.value}</div>`;
          return eDiv;
        },
        width: 200,
      },
      {
        headerName: 'Type',
        field: 'type',
        width: 100,
      },
      {
        headerName: 'Time',
        field: 'requestTime',
        width: 120,
        sortable: true,
        cellRenderer: (params: any): any => {
          if (!params.value) return '';
          const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
          eDiv.innerHTML = moment(params.value.iso).format('MMM DD,hh:mm a');
          return eDiv;
        },
      },
      {
        headerName: 'Message',
        field: 'note',
        flex: 1,
      },
    ];
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  async loadData(params: any): Promise<Array<{ [key: string]: unknown }>> {
    const requestPayload: RequestQueryPayload<Table.PendingCall> = {
      where: {
        status: ApiClientConstant.PendingCall.Status.Requested,
        user: this.user,
      },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['user'],
      project: [
        'type',
        'referenceId',
        'referenceMessage',
        'user.MobileNumber' as 'user',
        'user.alternateNumber' as 'user',
        'user.PatientName' as 'user',
        'requestTime',
        'note',
      ],
      descending: 'requestTime',
    };

    let data: Array<any>;
    try {
      [data, this.count] = await Promise.all([
        ApiConnector
          .find(Table.PendingCall, requestPayload)
          .then((items: Array<any>) => items.map((item: any) => item.toJSON())),
        ApiConnector.count(
          Table.PendingCall,
          requestPayload,
        ),
      ]);
    } catch (e) {
      return [];
    }
    return data;
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }
}
