<div class="variants-container" *ngIf="regimen?.variants">
  <div class="flex-box">
    <div class="heading">Regimen selected</div>
    <button (click)="onChangeVariant()"> Select Regimen Variant </button>
  </div>
  <div class="flex">
    <ng-container *ngFor="let data of regimenData; let i = index;">
      <variant-card [selectedIndex]="selectedIndex" [regimenInfo]="data" [index]="i"
        (variantSelection)="markCardSelection($event)"></variant-card>
    </ng-container>
  </div>
</div>