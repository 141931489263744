import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguageStringComponent } from './language-string.component';
import { LanguageStringListModule } from './language-string-list';
import { LanguageStringEditModule } from './language-string-edit';
import { LanguageStringViewModule } from './language-string-view';
import { LanguageStringTagModule } from './language-string-tag';
import { LanguageStringApprovalModule } from './language-string-approval';

const ROUTES: Routes = [
  {
    path: 'languageStrings',
    component: LanguageStringComponent,
    loadChildren: (): any => LanguageStringListModule,
    pathMatch: 'full',
  },
  {
    path: 'languageStrings/new',
    component: LanguageStringComponent,
    loadChildren: (): any => LanguageStringEditModule,
    pathMatch: 'full',
  },
  {
    path: 'languageStrings/approval',
    component: LanguageStringComponent,
    loadChildren: (): any => LanguageStringApprovalModule,
    pathMatch: 'full',
  },
  {
    path: 'languageStrings/tags',
    component: LanguageStringComponent,
    loadChildren: (): any => LanguageStringTagModule,
    pathMatch: 'full',
  },
  {
    path: 'languageString/:id',
    component: LanguageStringComponent,
    loadChildren: (): any => LanguageStringViewModule,
    pathMatch: 'full',
  },
  {
    path: 'languageString/:id/edit',
    component: LanguageStringComponent,
    loadChildren: (): any => LanguageStringEditModule,
    pathMatch: 'full',
  },
];

@NgModule({ providers: [],
  imports: [
    RouterModule.forChild(ROUTES),
  ],
  declarations: [LanguageStringComponent],
  exports: [LanguageStringComponent] })
export class LanguageStringModule {}
