import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({ selector: 'article-view', templateUrl: './article-view.html', styleUrls: ['./article-view.scss'] })
export class ArticleViewComponent implements OnDestroy {
  articleObj: any;
  articleId: any;
  webAppUrl: string;
  pug: string;
  id: string;
  ui: any;
  title: string;
  html: string;
  selectedLanguage: string;
  subscriptions: Array<Subscription>;
  constructor(private route: ActivatedRoute,
    private conn: ConnectionService,
    private appConfig: AppConfig,
    private windowRefService: WindowRefService) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.subscriptions = [];
    this.subscriptions = [];
    this.ui = { editModalOpenFlag: false, editProduct: undefined };
    this.subscribeToUrlQueryParams();
    this.title = this.articleObj.get('title');
    this.html = this.articleObj.get('html');
    this.webAppUrl = this.conn.getWebAppUrl();
  }

  async createOrUpdateQuestion(): Promise<any> {
    try {
      if (!this.articleObj.get('thumbnail')) {
        alert('thumbnail missing');
        return;
      }
      const questionUniqueId = this.articleObj.get('question')
        ? this.articleObj.get('question').get('uniqueIdentifier')
        : prompt('Question UniqueId');
      await this.articleObj.save({ questionUniqueId });
      alert('Request for creating question accepted.');
      this.windowRefService.nativeWindow.location.reload();
    } catch (error) {
      alert(error.message);
    }
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.params.id) {
        this.articleObj = this.route.parent.snapshot.data.article;
        this.articleId = this.route.parent.snapshot.params.id;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.route;
    delete this.conn;
    delete this.subscriptions;
  }
}
