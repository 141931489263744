import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from '../../../../components/directives.module';
import { EditComponent } from './edit.component';
import { InputTextModule } from '../../../../components/input/input-text';
import { LanguageViewModule } from '../../../../components/language-string/language-view';
import { InputSingleImageUploadModule } from '../../../../components/input/input-single-image-upload';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { InputTextareaModule } from '../../../../components/input/input-textarea';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { LanguageStringMultipleSelectionModule } from '../../../../components/language-string/language-string-multiple-selection';
import { AddReviewDialog } from './add-review-dialog/add-review-dialog.component';
import { InputSelectModule } from '../../../../components/input/input-select';

const ROUTES: Routes = [
  { path: '', component: EditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    InputTextModule,
    InputTextareaModule,
    InputSelectModule,
    InputSingleImageUploadModule,
    LanguageStringSelectionModule,
    MatCheckboxModule,
    InputCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    LanguageViewModule,
    LanguageStringMultipleSelectionModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [EditComponent, AddReviewDialog],
  exports: [EditComponent],
})
export class DoctorEditModule {
}
