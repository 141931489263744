import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiConnector, Table } from 'api-client';
import { UploadFileService } from '../../services/uploadFileService/uploadFile.service';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { Broadcaster } from '../broadcaster';

@Component({ selector: 'voice-note-add', templateUrl: './voice-note-add.html' })
export class VoiceNoteAddDialog {
  data: { audioTemplateName?: string } = {};
  addedAudioTemplate: any;
  languages: Array<string> = ['en', 'hi', 'te', 'ta', 'mr', 'ma', 'kn', 'bn'];
  files: { en?: File, hi?: File, te?: File, ta?: File, mr?: File, ma?: File, kn?: File, bn?: File } = {};
  signedURLs: { enSignedUrl?: string, hiSignedUrl?: string, teSignedUrl?: string, taSignedUrl?: string, mrSignedUrl?: string,
    maSignedUrl?: string, knSignedUrl?: string, bnSignedUrl?: string } = {};
  voiceNoteUrl: { en?: string, hi?: string, te?: string, ta?: string, mr?: string, ma?: string, kn?: string, bn?: string } = {};
  updateAudioTemplateByURL: boolean;
  constructor(private upload: UploadFileService,
    private appConfig: AppConfig,
    public dialogRef: MatDialogRef<VoiceNoteAddDialog>,
    public injector: Injector,
    private broadcaster: Broadcaster,
    private conn: ConnectionService) {
    this.data = this.injector.get(MAT_DIALOG_DATA);
  }

  async ngOnInit(): Promise<void> {
    this.addedAudioTemplate = await ApiConnector.findOne(Table.AudioTemplate, {
      where: {
        name: this.data.audioTemplateName,
      },
      include: ['urlLanguageString'],
      project: ['urlLanguageString', 'name'],
      option: { context: { translate: true } },
    });
    if (!this.addedAudioTemplate) {
      this.addedAudioTemplate = new Table.AudioTemplate();
      this.addedAudioTemplate.set('name', this.data.audioTemplateName);
    }

    if (this.addedAudioTemplate.get('urlLanguageString')) {
      this.languages.forEach((language: string) => {
        if (this.addedAudioTemplate.get('urlLanguageString')?.get(language)) {
          this.voiceNoteUrl[language] = this.addedAudioTemplate.get('urlLanguageString')?.get(language);
          const signedUrlName = `${language}SignedUrl`;
          this.signedURLs[signedUrlName] = this.addedAudioTemplate.get('urlLanguageString').get(signedUrlName);
        }
      });
    }
  }

  uploadFiles(file: any, lang: string): void {
    this.files[lang] = file;
  }

  public async uploadToAws(lang: string): Promise<void> {
    this.upload.uploadFile({
      file: this.files[lang],
      bucket: 'AUDIO',
      username: this.conn.getCurrentUser().get('username'),
      source: 'voice_note_followUpsSop',
    })
      .then((data: any) => {
        this.voiceNoteUrl[lang] = data;
      });
  }

  public removeLanguage(language: string): void {
    delete this.voiceNoteUrl[language];
    const signedUrlName = `${language}SignedUrl`;
    delete this.signedURLs[signedUrlName];
  }

  public getSignedUrl(language: string): string {
    const signedUrlName = `${language}SignedUrl`;
    if (this.signedURLs[signedUrlName]) {
      return this.signedURLs[signedUrlName];
    }
    if (this.voiceNoteUrl[language]) {
      return this.voiceNoteUrl[signedUrlName];
    }
    return '';
  }

  public async hideModal(isSave: boolean): Promise<void> {
    if (!isSave) {
      this.dialogRef.close();
    }
    let urlLanguageString = this.addedAudioTemplate.get('urlLanguageString');

    // if want to delete all the urls then delete languageString and audioTemplate
    if (!Object.values(this.voiceNoteUrl)?.length && urlLanguageString?.id) {
      try {
        await urlLanguageString.destroy();
        this.addedAudioTemplate.unset('urlLanguageString');
        await this.addedAudioTemplate.destroy();
        this.broadcaster.broadcast('NOTIFY', { message: 'Audio Uploaded', type: this.appConfig.Shared.Toast.Type.SUCCESS });
        this.dialogRef.close('');
        return;
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
    }

    // if urlLanguageString doesn't exists;
    if (!urlLanguageString) {
      urlLanguageString = new Table.LanguageString();
    }

    // update languageString
    this.languages.forEach((language: string) => {
      urlLanguageString.unset(language);
    });
    Object.keys(this.voiceNoteUrl).forEach((language: string) => {
      urlLanguageString.set(language, this.voiceNoteUrl[language]);
    });

    // save the audioTemplate
    try {
      await urlLanguageString.save();
      this.addedAudioTemplate.set('urlLanguageString', urlLanguageString);
      if (!this.addedAudioTemplate.id) await this.addedAudioTemplate.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'Audio Uploaded', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.dialogRef.close(this.data.audioTemplateName);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
