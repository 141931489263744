import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Component } from '@angular/core';
import { ApiClientConstant, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({ selector: 'audio-template-list', templateUrl: './list.html' })
export class ListComponent {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  count: number;

  // Local Variables
  searchKey: string;
  constructor(private router: Router,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.listViewSetup();
    this.route.queryParams.subscribe((params: any) => {
      this.reset();
    });
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'name',
        field: 'name',
        pinned: 'left',
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) {
            const eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = 'Loading ...';
            return eDiv;
          }
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = params.value;
          return eDiv;
        },
        width: 200,
      },
      {
        headerName: 'type',
        width: 100,
        field: 'type',
      },
      {
        headerName: 'En',
        field: 'urlLanguageString.en',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', () => {
            this.openSignedUrlInNewTab(ApiClientConstant.LanguageString.EN, params.data);
          });
          eDiv.innerHTML = '<button class="btn-xs">play</button>';
          return eDiv;
        },
        filter: true,
      },
      {
        headerName: 'Hi',
        field: 'urlLanguageString.hi',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', () => {
            this.openSignedUrlInNewTab(ApiClientConstant.LanguageString.HI, params.data);
          });
          eDiv.innerHTML = '<button class="btn-xs">play</button>';
          return eDiv;
        },
      },
      {
        headerName: 'Kn',
        field: 'urlLanguageString.kn',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', () => {
            this.openSignedUrlInNewTab(ApiClientConstant.LanguageString.KN, params.data);
          });
          eDiv.innerHTML = '<button class="btn-xs">play</button>';
          return eDiv;
        },
      },
      {
        headerName: 'Te',
        field: 'urlLanguageString.te',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', () => {
            this.openSignedUrlInNewTab(ApiClientConstant.LanguageString.TE, params.data);
          });
          eDiv.innerHTML = '<button class="btn-xs">play</button>';
          return eDiv;
        },
      },
      {
        headerName: 'Ta',
        field: 'urlLanguageString.ta',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', () => {
            this.openSignedUrlInNewTab(ApiClientConstant.LanguageString.TA, params.data);
          });
          eDiv.innerHTML = '<button class="btn-xs">play</button>';
          return eDiv;
        },
      },
      {
        headerName: 'Bn',
        field: 'urlLanguageString.bn',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', () => {
            this.openSignedUrlInNewTab(ApiClientConstant.LanguageString.BN, params.data);
          });
          eDiv.innerHTML = '<button class="btn-xs">play</button>';
          return eDiv;
        },
      },
      {
        headerName: 'Mr',
        field: 'urlLanguageString.mr',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', () => {
            this.openSignedUrlInNewTab(ApiClientConstant.LanguageString.MR, params.data);
          });
          eDiv.innerHTML = '<button class="btn-xs">play</button>';
          return eDiv;
        },
      },
      {
        headerName: 'Ma',
        field: 'urlLanguageString.ma',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.value) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.addEventListener('click', () => {
            this.openSignedUrlInNewTab(ApiClientConstant.LanguageString.MA, params.data);
          });
          eDiv.innerHTML = '<button class="btn-xs">play</button>';
          return eDiv;
        },
      },
    ];
    this.columnDefs.forEach((e: any) => {
      const each = e;
      each.sortable = true;
    });
  }

  async openSignedUrlInNewTab(inLanguage: string, data: any): Promise<void> {
    let url = data.urlLanguageString[`${inLanguage}SignedUrl`];
    if (!url) {
      const audioTemplate = await this.conn.findOneAudioTemplates({
        where: { objectId: data.objectId },
        include: ['urlLanguageString'],
        project: ['urlLanguageString'],
        option: { context: { inLanguage, translate: true } },
      });
      // @ts-ignore
      url = audioTemplate.get('urlLanguageString').get(`${inLanguage}SignedUrl`);
    }
    this.window.open(`${url}`);
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<any> {
    const searchOn = ['name'];
    const requestPayload: RequestQueryPayload<Table.AudioTemplate> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['urlLanguageString'],
      project: ['name', 'urlLanguageString', 'type'],
      descending: 'createdAt',
      option: { context: { translate: true, inLanguage: ApiClientConstant.LanguageString.EN } },
    };
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    let data: Array<any>;
    [data, this.count] = await Promise.all([
      this.conn.findAudioTemplates(requestPayload)
        .then((items: any) => items.map((i: any) => JSON.parse(JSON.stringify(i)))),
      this.conn.countAudioTemplates(requestPayload),
    ]);
    return data;
  }
}
