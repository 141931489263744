
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="col-xs-12 list-header"><span class="pR10">{{marketProductObj?.id ? 'Edit' : 'New'}} Market Product</span></div>
  </div>
  <div class="flex-content p-4">
    <form class="col-xs-6" #marketProductForm="ngForm">
      <div class="col-xs-12">
        <label class="mt-0">Name</label>
        <input class="w100" [(ngModel)]="name" name="marketProductName" required="required" placeholder="Name"/>
      </div>
      <div class="col-xs-12">
        <label>Aliases</label>
        <input class="w100" [(ngModel)]="alias"/>
        <div class="col-xs-12">
          <button class="mt-2 dib mr-2 btn-xs" *ngIf="alias" (click)="addAlias()">ADD</button>
          <div class="fontS2 bg-light-gray inline mr-2 mt-2 p-2 px-3 rounded" *ngFor="let alias of nameAliases;let i = index;"><i class="fa fa-trash-o pointer" (click)="removeAlias(i)"></i>
            <div class="d-inline-block m-1">{{alias}}</div>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <label>Substring Search</label>
        <input class="w100" [(ngModel)]="subString"/>
        <div class="col-xs-12">
          <button class="mt-2 dib mr-2 btn-xs" *ngIf="subString" (click)="addSubStrings()">ADD</button>
          <div class="fontS2 bg-light-gray inline mr-2 mt-2 p-2 px-3 rounded" *ngFor="let subString of nameSubStrings;let i = index;"><i class="fa fa-trash-o pointer" (click)="removeSubStrings(i)"></i>
            <div class="d-inline-block m-1">{{subString}}</div>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <label>Labels</label>
        <input class="w100" [(ngModel)]="label"/>
        <div class="col-xs-12">
          <button class="mt-2 dib mr-2 btn-xs" *ngIf="label" (click)="addLabel()">ADD</button>
          <div class="fontS2 bg-light-gray inline mr-2 mt-2 p-2 px-3 rounded" *ngFor="let label of labels;let i = index;"><i class="fa fa-trash-o pointer" (click)="removeLabel(i)"></i>
            <div class="d-inline-block m-1">{{label}}</div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 mt-4">
        <button [disabled]="!marketProductForm.valid" (click)="saveMarketProduct()">{{marketProductObj?.id ? 'Update' : 'Create'}}</button>
      </div>
    </form>
  </div>
</div>