import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { MarketingComponent } from './marketing.component';
import { ArticleModule } from './article';
import { QuizGameModule } from './quiz-game';
import { ExploreModule } from './explore';
import { FAQModule } from './FAQ';
import { SupportModule } from './support';
import { PatientEditModule } from './patient-edit';

const ROUTES: Routes = [
  {
    path: 'marketing',
    component: MarketingComponent,
    loadChildren: (): any => ArticleModule,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
    loadChildren: (): any => FAQModule,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
    loadChildren: (): any => ExploreModule,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
    loadChildren: (): any => SupportModule,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
    loadChildren: (): any => PatientEditModule,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
    loadChildren: (): any => QuizGameModule,
  },
];

@NgModule({ imports: [
  BrowserModule,
  RouterModule.forChild(ROUTES),
],
declarations: [MarketingComponent],
exports: [MarketingComponent] })
export class MarketingModule {}
