import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'doctor-filter',
  templateUrl: './doctor-filter.html',
})
export class DoctorFilterComponent {
  @Output() updatedDoctorList: EventEmitter<any> = new EventEmitter();
  @Input() set reset(status: boolean) {
    this.resetting = status;
    if (status) this.resetSelection();
  }
  @Input('filter') filter: { [key: string]: unknown } = { inactive: false };
  @Input() showSeacrhing: boolean = true;
  resetting: boolean = false;
  rows: Array<{ doctor: any, selected?: boolean }> = [];
  currentUser: any;
  doctors: Array<any> = [];
  searchKey: string = '';
  constructor(private conn: ConnectionService, private zone: NgZone) {
  }

  async ngOnInit(): Promise<any> {
    this.currentUser = this.conn.getCurrentUser();
    await this.fetchActiveDoctors();
    this.sendUpdatedList();
  }

  async fetchActiveDoctors(): Promise<void> {
    this.doctors = await this.conn.fetchDoctors(this.filter, ['username']);
    this.zone.run(() => (this.rows = this.doctors.map((doctor: any) => ({ doctor, selected: this.currentUser.id === doctor.id }))));
  }

  sendUpdatedList(): void {
    if (this.resetting) {
      return;
    }
    this.updatedDoctorList.emit(this.rows.filter(({ selected }: any) => selected).map(({ doctor }: any) => doctor));
  }

  toggleSelection(r: { doctor: any, selected: boolean }): any {
    const row = r;
    row.selected = !row.selected;
    this.sendUpdatedList();
  }

  resetSelection(): void {
    this.zone.run(() => (this.rows = this.doctors.map((doctor: any) => ({ doctor, selected: this.currentUser.id === doctor.id }))));
    this.resetting = false;
    this.sendUpdatedList();
  }

  async onInputChange(): Promise<void> {
    this.filter.username = { $regex: this.searchKey, $options: 'i' };
    await this.fetchActiveDoctors();
  }
}
