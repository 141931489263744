import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { RegimenLogsViewModal } from './regimen-logs-view';

@Component({
  selector: 'regimen-product-view',
  templateUrl: './regimen-products-view.html',
  styleUrls: ['./regimen-products-view.scss'],
})
export class RegimenProductsViewComponent {
  @Input('regimen')
  set onUpdateRegimen(regimen: any) {
    if (!regimen) {
      return;
    }
    this.reset();
    this.regimen = regimen;
    this.updateRegimenDetails();
  }
  regimen: any;
  slicedData: any;
  todayDate: Date = new Date();
  actionLog: any;
  @Input('user') user: any;
  concerns: Array<string> = [];
  regimenDetails: Array<{ id: string, title: string, partOfDay: Array<string>, startDate?: Date, endDate?: Date,
    differenceBtwStartAndEnd?: string, onHold: boolean, activeProduct: boolean, orders?: Array<any>, startDates?: Array<any>,
    endDates?: Array<any>}> = [];
  uniqueProductIds: Array<string> = [];
  addOnProducts: Array<{ id: string, title: string, orders?: Array<any> }> = [];
  lastDeliveredRegimen: any;
  @Output() emitRegimen: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appConfig: AppConfig, private connectionService: ConnectionService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
  }

  reset(): void {
    this.uniqueProductIds = [];
    this.concerns = [];
    this.regimenDetails = [];
    this.addOnProducts = [];
  }

  async updateRegimenDetails(): Promise<void> {
    this.regimen = JSON.parse(JSON.stringify(await this.connectionService.findOneRegimen({
      where: { regimenId: this.regimen.get('regimenId') },
      include: ['products', 'concernsLanguageString'],
      project: ['products.title' as 'products', 'products.quantity' as 'products',
        'products.quantityUnit' as 'products', 'morning', 'night',
        'concernsLanguageString.en' as 'concernsLanguageString',
        'active', 'regimenId'],
    })));
    this.concerns = this.regimen.concernsLanguageString.map((concern: any) => concern.en);
    this.regimen.products.forEach((product: any) => {
      const partOfDay = this.getPartOfDay(product, this.regimen);
      const onHold = this.isProductOnHold(product, this.regimen);
      const title = this.getProductDisplayName(product);
      this.regimenDetails.push({
        id: product.objectId,
        partOfDay,
        title,
        onHold,
        activeProduct: true,
        endDate: moment().toDate(),
      });
    });
    this.uniqueProductIds = this.regimen.products.map((each: any) => each.objectId);
    await this.updateRegimenDetailsFromRegimenHistory();
    await this.updateProductStartDate();
    await this.updateDifferenceBtwStartDateAndEndDate();
    await this.fetchAllDeliveredAddOnProducts();
    await this.fetchLastDeliveredRegimen();
    this.emitRegimen.emit(this.regimenDetails);
  }

  getProductDisplayName(product: any): string {
    return `${product.title}`;
  }

  async updateRegimenDetailsFromRegimenHistory(): Promise<void> {
    if (!this.regimen?.products?.length) return;
    const regimenHistory = JSON.parse(JSON.stringify(await this.connectionService.findRegimenHistory({
      where: { regimenId: this.regimen.regimenId, active: true },
      include: ['products'],
      project: ['products.title' as 'products', 'products.quantity' as 'products',
        'products.quantityUnit' as 'products', 'morning', 'night'],
      descending: 'createdAt',
    })));
    regimenHistory.forEach((regimen: any) => {
      regimen.products.forEach((product: any) => {
        const partOfDay = this.getPartOfDay(product, regimen);
        const onHold = this.isProductOnHold(product, regimen);
        if (!this.uniqueProductIds.includes(product.objectId)) {
          this.uniqueProductIds.push(product.objectId);
          const title = this.getProductDisplayName(product);
          this.regimenDetails.push({
            id: product.objectId,
            partOfDay,
            title,
            onHold,
            activeProduct: false,
            endDate: regimen.createdAt,
          });
        }
      });
    });
  }

  async fetchLastDeliveredRegimen(): Promise<void> {
    const regimenObj = new Table.Regimen();
    regimenObj.id = this.regimen.objectId;
    const isCurrentRegimenEdited = await ApiConnector.count(Table.ActionLog, {
      where: { regimen: regimenObj },
    });
    if (isCurrentRegimenEdited) {
      this.lastDeliveredRegimen = JSON.parse(JSON.stringify(await this.connectionService.findOneOrder({
        where: { stage: ApiClientConstant.Order.Stage.DELIVERED, regimen: regimenObj, user: this.user },
        ascending: 'deliveredOn',
        include: ['products'],
        project: ['products.title' as 'products', 'deliveredOn', 'products.quantity' as 'products',
          'products.quantityUnit' as 'products'],
      })));
    } else {
      this.lastDeliveredRegimen = JSON.parse(JSON.stringify(await this.connectionService.findOneOrder({
        where: { stage: ApiClientConstant.Order.Stage.DELIVERED, user: this.user },
        ascending: 'deliveredOn',
        include: ['products'],
        project: ['products.title' as 'products', 'deliveredOn', 'products.quantity' as 'products',
          'products.quantityUnit' as 'products'],
      })));
    }
  }

  async updateProductStartDate(): Promise<void> {
    const uniqueProducts = [];
    this.uniqueProductIds.forEach((productId: string) => {
      const product = new Table.Catalog();
      product.id = productId;
      uniqueProducts.push(product);
    });
    const orders: Array<any> = JSON.parse(JSON.stringify(await this.connectionService.findOrders({
      where: {
        products: uniqueProducts,
        user: this.user,
        stage: { $nin: ApiClientConstant.Order.CancelledStages },
      },
      descending: 'deliveredOn',
      project: ['products', 'stage', 'deliveredOn'],
    })));
    orders.forEach((order: any) => {
      const orderProductsId = order.products.map((product: any) => product.objectId);
      this.regimenDetails.forEach((detail: { id: string, title: string, partOfDay: Array<string>, startDate?: Date, endDate?: Date,
        differenceBtwStartAndEnd?: string, onHold: boolean, activeProduct: boolean, orders: Array<any> }) => {
        if (orderProductsId.includes(detail.id)) {
          if (!detail.orders) {
            // eslint-disable-next-line no-param-reassign
            detail.orders = [];
          }
          detail.orders.push({
            stage: order.stage,
            deliveredDate: order.deliveredOn,
            createdDate: order.createdAt,
          });
        }
        if (!detail.startDate && orderProductsId.includes(detail.id)) {
          // eslint-disable-next-line no-param-reassign
          detail.startDate = moment(order.deliveredOn).toDate();
        }
      });
    });
  }

  updateDifferenceBtwStartDateAndEndDate(): void {
    this.regimenDetails.forEach((detail_: { id: string, title: string, partOfDay: Array<string>, startDate?: Date, endDate?: Date,
      differenceBtwStartAndEnd?: string, onHold: boolean, activeProduct: boolean, changes: Array<any>}) => {
      const detail = detail_;
      const diffInDays = moment(detail.endDate).diff(detail.startDate, 'days');
      if (diffInDays > 30) {
        detail.differenceBtwStartAndEnd = `${moment(detail.endDate).diff(detail.startDate, 'months')} months`;
      } else {
        detail.differenceBtwStartAndEnd = `${moment(detail.endDate).diff(detail.startDate, 'days')} days`;
      }
    });
  }

  async fetchAllDeliveredAddOnProducts(): Promise<void> {
    const allUserProductOrders = JSON.parse(JSON.stringify(await this.connectionService.findOrders({
      where: { user: this.user, stage: { $nin: ApiClientConstant.Order.CancelledStages } },
      include: ['products'],
      project: ['products.isCommonAddon' as 'products', 'products.title' as 'products', 'deliveredOn', 'stage'],
      descending: 'createdAt',
    })));
    const uniqueAddonProductIds = [];
    allUserProductOrders.forEach((order: any) => {
      order.products.forEach((product: any) => {
        if (product.isCommonAddon && !this.uniqueProductIds.includes(product.objectId)) {
          if (uniqueAddonProductIds.includes(product.objectId)) {
            const commonAddonProduct = this.addOnProducts.find((each: any) => each.id === product.objectId);
            commonAddonProduct.orders.push({ stage: order.stage, deliveredDate: order.deliveredOn });
          } else {
            uniqueAddonProductIds.push(product.objectId);
            this.addOnProducts.push({ id: product.objectId,
              title: product.title,
              orders: [{ stage: order.stage, deliveredDate: order.deliveredOn }] });
          }
        }
        if (!product.isCommonAddon && !this.uniqueProductIds.includes(product.objectId)) {
          if (uniqueAddonProductIds.includes(product.objectId)) {
            const commonAddonProduct = this.addOnProducts.find((each: any) => each.id === product.objectId);
            commonAddonProduct.orders.push({ stage: order.stage, deliveredDate: order.deliveredOn });
          } else {
            uniqueAddonProductIds.push(product.objectId);
            this.addOnProducts.push({ id: product.objectId,
              title: product.title,
              orders: [{ stage: order.stage, deliveredDate: order.deliveredOn }] });
          }
        }
      });
    });
  }

  async findRegimenChangeLog(): Promise<void> {
    const regimen = new Table.Regimen();
    regimen.id = this.regimen.objectId;
    this.actionLog = await this.connectionService.getActionLog({ regimen });
  }

  async openChangesPopop(): Promise<void> {
    await this.findRegimenChangeLog();
    this.dialog.open(RegimenLogsViewModal, {
      width: '95%',
      data: { actionLog: this.actionLog },
    });
  }

  getPartOfDay(product: any, regimen: any): Array<string> {
    const partOfDay = [];
    if (regimen.morning.filter((each: any) => each.product.objectId === product.objectId).length) {
      partOfDay.push('morning');
    }
    if (regimen.night.filter((each: any) => each.product.objectId === product.objectId).length) {
      partOfDay.push('night');
    }
    return partOfDay;
  }

  isProductOnHold(product: any, regimen: any): boolean {
    if (this.regimen.morning.filter((each: any) => each.product.objectId === product.objectId && each.product.onHold).length) {
      return true;
    }
    if (this.regimen.night.filter((each: any) => each.product.objectId === product.objectId && each.product.onHold).length) {
      return true;
    }
    return false;
  }

  protected apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
