import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';

@Component({
  selector: 'time-range-model',
  templateUrl: './time-range-popup.html',
})
export class TimeRangePopupModel {
  startTimeHr: number = undefined;
  startTimeMin: number = 0;
  endTimeHr: number = undefined;
  endTimeMin: number = 0;
  weekDays: Array<string> = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  copyFromDay: string;

  constructor(private conn: ConnectionService,
    private router: Router,
    private appConfig: AppConfig,
    private dialogRef: MatDialogRef<TimeRangePopupModel>) {
  }

  saveTimeRange(): void {
    this.dialogRef.close({ startTime: `${this.startTimeHr}:${this.startTimeMin}`, endTime: `${this.endTimeHr}:${this.endTimeMin}` });
  }

  copyTimeFromDay(): void {
    this.dialogRef.close({ copyFromDay: this.copyFromDay });
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
