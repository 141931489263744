
<div class="box-followup mb-2" *ngIf="node" style="background:{{bgColor}}">
  <collapse [title]="node.name" [shortName]="node.shortName" [fromFollowUp]="true">
    <div class="flex mb-2">
      <label class="mt-1 mb-1">Follow Up</label><i class="fa fa-trash-o pointer ml-2" (click)="removeFollowUp()"></i>
    </div>
    <div class="d-flex justify-content-space-between align-item-center col-xs-12">
      <div class="col-xs-3 flex">
        <label>Unique Title</label>
        <input class="ml-3" [(ngModel)]="node.name" type="text" name="{{prefix}}-name" required="required" pattern="^F[0-100][A-Z].*"/>
      </div>
      <div class="col-xs-3 flex">
        <label>Short Name</label>
        <select class="col-xs-8" [(ngModel)]="node.shortName" type="text" name="{{prefix}}-shortName" required="required" (change)="onChangeShortName()">
          <option [value]="node.shortName">{{node.shortName}}</option>
          <option *ngFor="let option of shortNameOptions" [value]="option.value">{{option.display}}</option>
        </select>
        <input class="ml-3" *ngIf="showShortNameTextInput" type="text" [(ngModel)]="node.shortName"/>
      </div>
      <div class="col-xs-1 flex">
        <div class="inline-flex pl-2">
          <label class="mt-0 mb-0">Disabled</label>
          <label class="i-switch i-switch-sm bg-secondary ml-2 mt-0 mb-0">
            <input [(ngModel)]="node.disabled" type="checkbox" name="{{prefix}}-disabled"/><i></i>
          </label>
        </div>
      </div>
      <div class="col-xs-2 flex ml-3">
        <label class="mt-0 mb-0">Voice Note</label><i class="fa fa-edit ml-3" (click)="updateVoiceNote()"></i>
      </div>
      <div class="col-xs-2 flex">
        <label class="mt-0 mb-0">Treat Progress</label><i class="fa fa-edit ml-3" (click)="treatmentProgressEdit()"></i>
      </div>
      <div class="col-xs-3 flex">
        <label class="mt-0 mb-0">Choose Test</label>
        <select class="ml-3 form-control w40" [(ngModel)]="selectedInvestigation.index" (ngModelChange)="changeInvestigation()" name="{{prefix}}-test">
          <option *ngFor="let val of testsList; let i = index" [value]="i">{{val?.get('name')}}</option>
        </select>
      </div>
    </div>
    <div class="col-xs-12">
      <label>Regimen changes<i class="fa fa-plus-square-o ml-3" (click)="addRegimenChanges()"></i>
        <div class="flex box-followup" *ngFor="let change of node.changes; let changeIndex=index">
          <collapse [fromFollowUp]="true">
            <div class="conatiner">
              <div class="d-flex justify-content-space-between align-item-center"><i class="fa fa-trash-o pointer" (click)="removeRegimenChange(changeIndex)"></i>
                <div class="col-xs-3">
                  <label>Type of change</label>
                  <select class="form-control" [(ngModel)]="change.type" name="{{prefix}}{{changeIndex}}-type">
                    <option *ngFor="let val of operationChange" [value]="val">{{val}}</option>
                  </select>
                </div>
                <div class="col-xs-2">
                  <label>Part of day</label>
                  <select class="form-control" [(ngModel)]="change.partOfDay" name="{{prefix}}{{changeIndex}}-partOfDay">
                    <option value="morning">morning</option>
                    <option value="night">night</option>
                  </select>
                </div>
                <div class="col-xs-2" *ngIf="change.type === 'addProduct'">
                  <label>Position</label>
                  <input class="form-control" [(ngModel)]="change.position" name="{{prefix}}{{changeIndex}}-position" type="number" placeholder="0, 1, 2..."/>
                </div>
                <div class="inline-flex pl-4" *ngIf="change.type === 'addProduct' || change.type === 'changeInstruction'">
                  <label class="bold">OnHold</label>
                  <label class="i-switch i-switch-sm bg-secondary ml-2">
                    <input type="checkbox" [(ngModel)]="change.onHold" name="{{changeIndex}}{{prefix}}onHold"/><i></i>
                  </label>
                </div>
                <div class="inline-flex pl-4" *ngIf="change.type === 'addProduct'">
                  <label class="bold">Complementory</label>
                  <label class="i-switch i-switch-sm bg-secondary ml-2">
                    <input type="checkbox" [(ngModel)]="change.isComplementory" name="{{changeIndex}}{{prefix}}isComplementory"/><i></i>
                  </label>
                </div>
                <div class="col-xs-5 pr-0 pl-0">
                  <label class="ml-3">Product details</label>
                  <regimen-edit-product [product]="change.productInfo" name="productInfo_{{changeIndex}}{{prefix}}" (onProductUpdate)="regimenChange(changeIndex)" [onProductChange]="true" [regimenProducts]="regimenProducts" [type]="change.type" [partOfDay]="change.partOfDay" [isFollowUp]="'true'"></regimen-edit-product>
                </div>
              </div>
            </div>
          </collapse>
        </div>
      </label>
    </div>
    <div class="pl-4" *ngFor="let childNode of node.nextFollowUps; let i = index;">
      <followup-sop class="col-xs-12" [node]="childNode" (delete)="deleteFollowUp(i)" prefix="{{prefix}}-{{i}}" [level]="level + 1" [prevLevelRegimen]="prevLevelRegimen"></followup-sop>
    </div>
    <button (click)="addFollowUpForm()">Next FollowUp</button>
  </collapse>
</div>