import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MomentModule } from 'ngx-moment';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from '../../../components/directives.module';
import { PendingCallsComponent } from './pending-calls.component';
import { PendingCallsActionComponent } from './pending-calls-action/pending-calls-action.component';
import { PendingCallCompleteComponent } from './pending-call-complete/pending-call-complete.component';
import { PendingCallRecentHistoryComponent } from './pending-call-recent-history/pending-call-recent-history.component';
import { AudioRecorderModule } from '../../../components/audio-recorder/audio-recorder.module';

const ROUTES: Routes = [
  { path: '', component: PendingCallsComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MomentModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatRadioModule,
    MatSelectModule,
    RouterModule.forChild(ROUTES),
    AgGridAngular,
    AudioRecorderModule,
  ],
  declarations: [PendingCallsComponent, PendingCallsActionComponent, PendingCallCompleteComponent, PendingCallRecentHistoryComponent],
  exports: [PendingCallsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PendingCallsModule {}
