
<div class="col-xs-12 p-0">
  <label><span>{{title}}</span><i class="fa fa-plus-square-o pl-3" (click)="addNewItem()"></i></label>
  <div class="col-xs-12 pb-3 bdr-btm" *ngIf="list[0]">
    <div class="col-xs-12 flex mb-2" *ngFor="let item of list; let i = index;">
      <ng-container *ngIf="item.id">
        <div class="col-xs-9">
          <language-view *ngIf="!isImage(item)" [languageStringObj]="item"></language-view><img class="list" *ngIf="isImage(item)" [src]="item.get('en')"/>
        </div>
        <div class="col-xs-3 pl-1 alignC"><i class="fa fa-edit pr-3" (click)="editArticle(i)"></i><i class="fa fa-long-arrow-up pr-3" (click)="moveUp(i)"></i><i class="fa fa-trash-o pointer" (click)="selectionRemove(i)"></i></div>
      </ng-container>
    </div>
  </div>
  <div class="col-xs-12 mt-2">
    <language-string-tag-selection [parseObj]="parseObj" name="{{name}}Tags" (onValueChange)="onTagUpdate($event)"></language-string-tag-selection>
  </div>
</div>
<div class="popup" *ngIf="showPopup" (click)="hideModal()">
  <mat-dialog-content class="mat-dialog-content modal-lg posR vh-center bg-white" (click)="$event.stopPropagation()">
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{title}}</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
    </div>
    <div class="modal-body">
      <language-string-selection [languageStringObj]="list[editLanguageStringParams.index]" [showSearch]="true" name="{{title}}" [tags]="tagsString" [required]="true" (languageStringObjChange)="selectionUpdate($event)"></language-string-selection><img *ngIf="imageUrl" [src]="imageUrl"/>
    </div>
    <div class="modal-footer">
      <button class="pull-right ml-3" *ngIf="showUploadButton" type="button" (click)="openUploadArticleModel()">upload Article</button>
      <button class="pull-right" type="button" (click)="hideModal()">update</button>
    </div>
  </mat-dialog-content>
</div>