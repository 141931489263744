import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RegimenProductsEditComponent } from './regimen-products-edit.component';
import { DirectivesModule } from '../directives.module';
import { ProductInstructionSelectionModal } from './product-instruction-selection-popup';
import { LanguageStringMultipleSelectionModule } from '../language-string/language-string-multiple-selection';
import { InputCheckboxModule } from '../input/input-checkbox';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    DirectivesModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSelectModule,
    DragDropModule,
    LanguageStringMultipleSelectionModule,
    InputCheckboxModule,
  ],
  declarations: [RegimenProductsEditComponent, ProductInstructionSelectionModal],
  exports: [RegimenProductsEditComponent],
})
export class RegimenProductsEditModule {
}
