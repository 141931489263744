import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TreeViewRouteComponent } from './tree-view-route.component';
import { DirectivesModule } from '../../../../components/directives.module';

const ROUTES: Routes = [
  { path: 'routes', component: TreeViewRouteComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [TreeViewRouteComponent],
  exports: [TreeViewRouteComponent],
})
export class TreeViewRouteModule {}
