import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { Broadcaster } from 'src/components/broadcaster';

import { ConnectionService } from 'src/services/connection-service';
import { UploadFileService } from 'src/services/uploadFileService/uploadFile.service';
import { ApiClientConstant, Table } from 'api-client';

@Component({
  selector: 'app-reminder-form',
  templateUrl: './reminder-form.component.html',
  styleUrls: ['./reminder-form.component.scss'],
})
export class ReminderFormComponent {
  reminder: any;
  file: File;
  reminderTypes: any[];
  @ViewChild('url', { static: false }) url: ElementRef;
  subscriptions: Array<any> = [];
  constructor(private upload: UploadFileService,
              private appConfig: AppConfig,
              private conn: ConnectionService,
              private snackbar: MatSnackBar,
              private broadcast: Broadcaster,
              private dialogRef: MatDialogRef<ReminderFormComponent>) {
    this.reminder = new Table.Reminder();
    this.reminderTypes = [{
      value: ApiClientConstant.Reminder.Type?.POPUP,
      display: ApiClientConstant.Reminder.Type?.POPUP,
    }, {
      value: ApiClientConstant.Reminder.Type?.FULL_PAGE_POPUP,
      display: ApiClientConstant.Reminder.Type?.FULL_PAGE_POPUP,
    }];
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  public uploadToAws(): Promise<string> {
    return this.upload.uploadFile({
      file: this.file,
      bucket: 'PRODUCT',
      username: this.conn.getCurrentUser().get('username'),
      source: 'reminder_image',
    });
  }

  public hideModal(): void {
    this.dialogRef.close();
  }

  async saveReminder(): Promise<any> {
    try {
      const url = await this.uploadToAws();
      this.reminder.set('image', url);
      await this.reminder.save();
      this.snackbar.open('Reminder created', null, { duration: 5000 });
      this.broadcast.broadcast('NOTIFY', { message: 'Reminder created', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.dialogRef.close(true);
    } catch (e) {
      alert('Unble to create reminder');
    }
  }
}
