import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LocalStorage } from 'src/services/local-storage-service';
import { Broadcaster } from 'src/components/broadcaster';
import { AppConfig } from 'src/app/app.config';
import { AddOrderLabelModal } from '../add-order-label';

@Component({ selector: 'order-escalation-modal', templateUrl: './order-escalation.html' })
export class OrderEscalationModal implements OnInit {
  @Input('order')
  set setOrder(x: any) {
    this.updateOrder(x);
  }
  @Output('addNote') addNote: EventEmitter<any> = new EventEmitter();
  order: any;
  labels: Array<string>;
  subscriptions: Array<Subscription> = [];
  InternalUserRoles: Array<string> = [];
  hasAccessToDeleteLabels: boolean;

  constructor(private dialog: MatDialog,
    private storage: LocalStorage,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig) {
  }
  ngOnInit(): void {
    this.InternalUserRoles = this.storage.getJsonValue('userRoles');
    if (this.InternalUserRoles.some((role: string) => ['admin', 'adminDoctor', 'adminOperator'].includes(role))) {
      this.hasAccessToDeleteLabels = true;
    }
  }

  private updateOrder(order: any): void {
    this.order = order;
    this.labels = this.order.get('labels') || [];
  }

  addLabel(): void {
    const dialogRef = this.dialog.open(AddOrderLabelModal, { width: '95%', data: { order: this.order } });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {
      this.updateOrder(this.order);
    }));
  }

  removeItem(index: number): void {
    const label = this.labels[index];
    if (label.toLowerCase() === 'gokwik' && !this.hasAccessToDeleteLabels) {
      this.broadcaster.broadcast('NOTIFY', { message: 'User Doesn\'t have access to remove this value',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const orderNote = prompt(`Please enter "${label}" removal note`);
    if (!orderNote) {
      return;
    }
    const labels = this.order.get('labels');
    labels.splice(index, 1);
    this.order.set('labels', labels);
    this.addNote.emit(orderNote);
    this.updateOrder(this.order);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
