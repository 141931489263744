import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../services/connection-service/index';
import { AppConfig } from '../../app/app.config';
import { LocalStorage } from '../../services/local-storage-service';

@Component({
  selector: 'ticket-history',
  templateUrl: './ticket-history.html',
})
export class TicketHistoryComponent {
  ticket: any;
  ticketHistory: Array<any>;
  constructor(private dialogRef: MatDialogRef<TicketHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private conn: ConnectionService,
    private localStorage: LocalStorage,
    private appConfig: AppConfig) { }

  async ngOnInit(): Promise<void> {
    this.ticket = this.data.ticket;
    this.ticketHistory = await ApiConnector.find(Table.SupportTicketLog, {
      where: { supportTicket: this.ticket },
      include: ['completedBy', 'createdBy'],
      descending: 'createdAt',
    });
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
