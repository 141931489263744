import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { BlockedBotAssistantDialog } from 'src/app/chat/view/blocked-bot-assistant';
import { ContinueTabPopupModule } from 'src/app/patient/continue-tab-popup/index';
import { NavbarComponent } from './navbar/navbar.component';
import { KeyboardShortcuts } from './keyboard-shortcuts';
import { SearchBoxComponent } from './search-box';
import { ChangePasswordModal } from './navbar/change-password';
import { FooterComponent } from './footer/footer.component';
import { BeforeBottomView } from './before-bottom-view';
import { BeforeTopView } from './before-top-view';
import { FillComponentParentView } from './fill-component-parent-view';
import { BulkArticleModel } from './navbar/bulk-article';
import { PushNotificationModel } from './navbar/push-notification';
import { SMSDialog } from './SMS';
import { ImageUploadModal } from './modal/imageUploadModal';
import { DataUploadModal } from './modal/dataUploadModal';
import { DataSelectModal } from './modal/dataSelectModal';
import { FileUploaderModel } from './navbar/pincode-file-uploader';
import { ToastMessage } from './toast-message';
import { DateSelectorComponent } from './date-selector';
import { UserPriorityComponent } from './user-priority';
import { ArrowLine } from './arrow-line';
import { AllocatedDoctorComponent } from './allocated-doctor';
import { DoctorFilterComponent } from './doctor-filter';
import { OperatorFilterComponent } from './operator-filter';
import { LeavesFilterComponent } from './leaves-filter';
import { LanguageFilterComponent } from './language-filter';
import { LabelFilterComponent } from './label-filter';
import { MorningNightListComponent } from './morning-night-list';
import { FollowupReport } from '../app/chat/view/followup-report';
import { ArticleImageUploadModal } from './navbar/articleImageUploadModal';
import { OnCall } from './on-call';
import { UserOnAntibioticComponent } from './user-on-antibiotic';
import { BulkUpdateOrderModal } from './navbar/bulk-update-order';
import { LoadingComponent } from './loading/loading.component';
import { PopupDirective } from './popup';
import { FilterDropdown } from './filter-dropdown';
import { AppUpdateComponent } from './app-update';
import { LanguageVoiceRecorderComponent } from './language-voice/language-voice-recorder';
import { LanguageVoiceViewComponent } from './language-voice/language-voice-view';
import { PopupWrapperComponent } from './popup-wrapper';
import { CollapseComponent } from './collapse';
import { TeamFilterComponent } from './team-filter';
import { ChangeAllocatedDoctor } from './change-allocated-doctor';
import { TypeFilterComponent } from './type-filter';
import { ChangeTicketAllocationModule } from './change-ticket-allocation';
import { OrderListComponent } from '../app/chat/view/order-list';
import { WaitlistChatComponent } from '../app/chat/view/waitlist-chat';
import { RegimenListComponent } from '../app/chat/view/regimen-list';
import { LanguageViewModule } from './language-string/language-view';
import { InternalTeamFilterComponent } from './internal-team';
import { UploadAudioDialog } from './upload-audio';
import { TicketHistoryComponent } from './ticket-history';
import { ComparisonSliderComponent } from '../app/compare-progress/comparison-slider/comparison-slider.component';
import { AutoFaceModule } from './auto-face/auto-face.module';
import { TreatmentProgressAddModule } from './treatment-progress-add';
import { ChangeLeaveStatus } from './change-leave-status';
import { QuickActionButtonsComponent } from './quick-action-buttons/quick-action-buttons.components';
import { ExotelSoftPhoneComponent } from '../app/calls/exotel-softphone/exotel-softphone.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FileUploadModule,
    MomentModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSelectModule,
    LanguageViewModule,
    AutoFaceModule,
  ],
  declarations: [
    NavbarComponent,
    UserPriorityComponent,
    UserOnAntibioticComponent,
    DateSelectorComponent,
    SearchBoxComponent,
    FooterComponent,
    BeforeBottomView,
    BeforeTopView,
    FillComponentParentView,
    ChangePasswordModal,
    BulkUpdateOrderModal,
    ArticleImageUploadModal,
    BulkArticleModel,
    FileUploaderModel,
    PushNotificationModel,
    SMSDialog,
    ToastMessage,
    KeyboardShortcuts,
    ArrowLine,
    ImageUploadModal,
    DataUploadModal,
    DataSelectModal,
    AllocatedDoctorComponent,
    FilterDropdown,
    DoctorFilterComponent,
    OperatorFilterComponent,
    LeavesFilterComponent,
    LanguageFilterComponent,
    LabelFilterComponent,
    MorningNightListComponent,
    FollowupReport,
    OnCall,
    LoadingComponent,
    PopupDirective,
    AppUpdateComponent,
    LanguageVoiceRecorderComponent,
    LanguageVoiceViewComponent,
    PopupWrapperComponent,
    CollapseComponent,
    TeamFilterComponent,
    ChangeAllocatedDoctor,
    ChangeLeaveStatus,
    TypeFilterComponent,
    OrderListComponent,
    BlockedBotAssistantDialog,
    WaitlistChatComponent,
    RegimenListComponent,
    InternalTeamFilterComponent,
    UploadAudioDialog,
    TicketHistoryComponent,
    ComparisonSliderComponent,
    QuickActionButtonsComponent,
    ExotelSoftPhoneComponent,
  ],
  exports: [
    LanguageVoiceViewComponent,
    LanguageVoiceRecorderComponent,
    NavbarComponent,
    UserPriorityComponent,
    UserOnAntibioticComponent,
    DateSelectorComponent,
    SearchBoxComponent,
    FooterComponent,
    BeforeBottomView,
    BulkUpdateOrderModal,
    BeforeTopView,
    FillComponentParentView,
    ChangePasswordModal,
    BulkArticleModel,
    PushNotificationModel,
    SMSDialog,
    ArticleImageUploadModal,
    ToastMessage,
    KeyboardShortcuts,
    ArrowLine,
    ImageUploadModal,
    DataUploadModal,
    FilterDropdown,
    AllocatedDoctorComponent,
    DoctorFilterComponent,
    OperatorFilterComponent,
    LeavesFilterComponent,
    LanguageFilterComponent,
    LabelFilterComponent,
    MorningNightListComponent,
    FollowupReport,
    OnCall,
    LoadingComponent,
    PopupDirective,
    AppUpdateComponent,
    PopupWrapperComponent,
    CollapseComponent,
    TeamFilterComponent,
    ChangeAllocatedDoctor,
    ChangeLeaveStatus,
    TypeFilterComponent,
    ChangeTicketAllocationModule,
    OrderListComponent,
    BlockedBotAssistantDialog,
    WaitlistChatComponent,
    RegimenListComponent,
    InternalTeamFilterComponent,
    TicketHistoryComponent,
    ComparisonSliderComponent,
    QuickActionButtonsComponent,
    ExotelSoftPhoneComponent,
  ],
})
export class DirectivesModule {
}
