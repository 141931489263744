import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { ServiceEditComponent } from './service-edit.component';
import { LanguageStringSelectionWithTagModule } from '../../../../components/language-string/language-string-selection-with-tag';

const ROUTES: Routes = [
  { path: '', component: ServiceEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    LanguageStringSelectionWithTagModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [ServiceEditComponent],
  exports: [ServiceEditComponent],
})
export class ServiceEditModule {
}
