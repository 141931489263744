import { Component, NgZone, OnDestroy } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ParseKeys, RequestQueryPayload } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { LocalStorage } from '../../../../services/local-storage-service';

@Component({
  selector: 'question-list',
  templateUrl: './question-list.html',
})
export class QuestionListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  questions: any;
  components: any;
  fields: Array<string> = ['title', 'uniqueIdentifier'];
  ui: any = {};
  columnDefs: any;
  count: number;
  userRoles: Array<any> = [];
  searchKey: string;
  constructor(private router: Router,
              private windowRef: WindowRefService,
              private conn: ConnectionService,
              private zone: NgZone,
              private storage: LocalStorage) {
  }

  ngOnInit(): void {
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.listViewSetup();
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const data = await this.loadMore(params);
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 50,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const { data }: any = params;
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        const id = data.objectId;
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o pR10' target="_blank" href='/trees/question/${id}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'Question Id',
      field: 'uniqueIdentifier',
      width: 150,
      filter: true,
      cellRenderer: (params: any): any => {
        const url = this.router.url.split('/');
        url.splice(url.length - 1, 1);
        if (!params.data) {
          const eDiv = this.windowRef.nativeWindow.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = this.userRoles.includes('treeEditor')
          ? `<a class='col-xs-12 no-padder ellipsis' target="_blank" href='/trees/question/${
            params.data.objectId}'>${params.data.uniqueIdentifier}</a>`
          : '-';
        return eDiv;
      },
    }, {
      headerName: 'Updated At',
      field: 'updatedAt',
      width: 150,
      filter: true,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('MMM DD, YYYY');
        return eDiv;
      },
    }, {
      headerName: 'Title',
      field: 'title',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data || !params.value) {
          return '';
        }
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<a class="w100" style="display: block" target='_blank'> ${params.value}</a>`;
        return eDiv;
      },
    }];
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.Question> = {
      where: {},
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      project: ['title', 'uniqueIdentifier'],
    };
    const searchOn = ['title', 'uniqueIdentifier'];
    const { colId, sort }: { colId: ParseKeys<Table.Question>, sort: 'asc' | 'desc' } = params.sortModel[0]
      || { colId: 'updatedAt', sort: 'desc' };
    if (sort === 'desc') {
      requestPayload.descending = colId;
    } else {
      requestPayload.ascending = colId;
    }
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((e: any) => (requestPayload.where[e] = { $regex: params.filterModel[e].filter, $options: 'i' }));
    }
    let data: Array<any>;
    [data, this.count] = await Promise.all([
      this.conn.findQuestions(requestPayload)
        .then((items: any) => items.map((i: any) => JSON.parse(JSON.stringify(i)))),
      this.conn.countQuestions(requestPayload),
    ]);
    return data;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  resetFilters(): void {
    delete this.searchKey;
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.questions;
    delete this.fields;
    delete this.ui;
  }
}
