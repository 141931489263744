import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { QuestionConditionComponent } from './question-condition.component';
import { InputTextModule } from '../../../../../components/input/input-text';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  declarations: [QuestionConditionComponent],
  exports: [QuestionConditionComponent],
})
export class QuestionConditionModule {}
