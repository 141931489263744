
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Instant Checkup</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="col-xs-12 no-padder">
      <label class="col-xs-12 mt-0">Type</label>
      <div class="col-xs-12"></div>
      <select class="col-xs-12" [(ngModel)]="imageType" name="imageType" required="required">
        <option *ngFor="let type of types" value="{{type}}">{{ type }}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-xs pull-right" [disabled]="loading" (click)="updateInstantCheckup()"><span>Update</span><i class="fa fa-spin fa-spinner ml-2" *ngIf="loading"></i></button>
  </div>
</mat-dialog-content>