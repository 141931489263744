import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { ApiClientConstant, ParseKeys, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({ selector: 'list', templateUrl: './list.html', styleUrls: ['./list.scss'] })
export class ListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  count: number;

  // Local Variables
  products: any;
  filterByTag: any = { tags: [] };
  searchKey: string;
  inventoryStatus: Array<{ status: string, selected?: boolean }> = [];
  constructor(private router: Router,
              private conn: ConnectionService,
              private zone: NgZone,
              public appConfig: AppConfig,
              windowRef: WindowRefService,
              private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.listViewSetup();
    this.route.queryParams.subscribe((params: any) => {
      this.reset();
    });
    this.inventoryStatus = [
      { status: ApiClientConstant.Catalog.InventoryStatus.AVAILABLE, selected: true },
      { status: ApiClientConstant.Catalog.InventoryStatus.UNAVAILABLE, selected: true },
      { status: ApiClientConstant.Catalog.InventoryStatus.DISCONTINUED, selected: false },
      { status: ApiClientConstant.Catalog.InventoryStatus.RESTRICTED, selected: false },
    ];
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Catalog Id',
        field: 'objectId',
        pinned: 'left',
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) {
            const eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = 'Loading ...';
            return eDiv;
          }
          const eDiv = this.window.document.createElement('div');
          const id = params.value || params.data.objectId;
          eDiv.innerHTML = `<a class='col-xs-12 no-padder ellipsis' target="_blank" href='${url.join('/')}/catalog/${id}'>${id}</a>`;
          return eDiv;
        },
        width: 100,
      },
      {
        headerName: 'Edit',
        width: 50,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) return '';
          const id = params.value || params.data.objectId;
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `<a class='fa fa-pencil-square-o' target="_blank" href='${url.join('/')}/catalog/${id}/edit'></a>`;
          return eDiv;
        },
      },
      {
        headerName: 'Marg Id',
        field: 'margId',
        width: 100,
        filter: true,
      },
      {
        headerName: 'Title',
        field: 'title',
        width: 200,
        filter: true,
      },
      { headerName: 'Inventory Statuss', field: 'inventoryStatus', width: 150 },
      { headerName: 'Type', field: 'type', width: 80 },
      {
        headerName: 'MRP',
        field: 'mrp',
        width: 100,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `₹ ${params.data.mrp}`;
          return eDiv;
        },
      },
      {
        headerName: 'SP',
        field: 'price',
        width: 100,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `₹ ${params.data.price}`;
          return eDiv;
        },
      },
      { headerName: 'Ranking', field: 'ranking', width: 80 },
      {
        headerName: 'Quantity',
        field: 'quantity',
        width: 100,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = `${params.data.quantity} ${params.data.quantityUnit}`;
          return eDiv;
        },
      },
      {
        headerName: 'Purpose',
        width: 200,
        filter: true,
        cellRenderer: (params: any): any => {
          if (!params.data) return '';
          const eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = params.data.purposeLanguageString.en;
          return eDiv;
        },
      },
      { headerName: 'ingredient keyActive', field: 'ingredients.keyActive', width: 150, filter: true },
      { headerName: 'ingredient supportingActive', field: 'ingredients.supportingActive', width: 150, filter: true },
      { headerName: 'ingredient baseIngredients', field: 'ingredients.baseIngredients', width: 150, filter: true },
      { headerName: 'Market Equivalent Name', field: 'marketEquivalentName', width: 150, filter: true },
    ];
    this.columnDefs.forEach((e: any) => {
      const each = e;
      each.sortable = true;
    });
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.filterByTag = [];
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<any> {
    const searchOn = ['margId', 'title', 'purpose', 'objectId', 'ingredients.keyActive'];
    const requestPayload: RequestQueryPayload<Table.Catalog> = {
      where: { type: 'main' },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['purposeLanguageString'],
      project: ['margId', 'title', 'inventoryStatus', 'type', 'mrp', 'price', 'quantity', 'quantityUnit', 'purpose',
        'Manufacturer', 'purposeLanguageString', 'vendorName', 'ingredient', 'marketEquivalentName', 'ingredients',
        'ranking'],
      ascending: 'title',
    };
    const selectedInventoryStatus = [];
    this.inventoryStatus.forEach((each: { selected: boolean, status: string}) => {
      if (each.selected) {
        selectedInventoryStatus.push(each.status);
      }
    });
    requestPayload.where.inventoryStatus = { $in: selectedInventoryStatus };
    const { colId, sort }: { colId: ParseKeys<Table.Catalog>, sort: 'asc' | 'desc' } = params.sortModel[0]
      || { colId: 'updatedAt', sort: 'desc' };
    if (sort === 'desc') {
      requestPayload.descending = colId;
    } else {
      requestPayload.ascending = colId;
    }
    if (this.searchKey) {
      requestPayload.where.$or = searchOn.map((key: string) => ({ [key]: { $regex: this.searchKey, $options: 'i' } }));
    }
    if (this.filterByTag.tags.length) {
      requestPayload.where.tags = this.filterByTag.tags;
    }
    if (params.filterModel) {
      Object.keys(params.filterModel)
        .forEach((key: any) => (requestPayload.where[key] = params.filterModel[key].filter));
    }
    let data: Array<any>;
    [data, this.count] = await Promise.all([
      this.conn.findCatalogs(requestPayload)
        .then((items: any) => items.map((i: any) => JSON.parse(JSON.stringify(i)))),
      this.conn.countCatalogs(requestPayload),
    ]);
    return data;
  }

  toggleStatus(selectedRow: { status: string, selected?: boolean }): void {
    const row = selectedRow;
    row.selected = !row.selected;
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.ui;
  }
}
