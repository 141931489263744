
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Select which tree you want to Trigger</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <label class="mt-0 col-xs-12">Select Consultation</label>
    <select class="col-xs-12" [(ngModel)]="selectedConsultation" name="consultationSessionList">
      <option *ngFor="let consultation of consultationsList" [value]="consultation.id">{{consultation.get('PrivateMainConcernClass')}}</option>
    </select>
  </div>
  <div class="modal-footer">
    <button class="pull-right btn-secondary ml-3" (click)="selectConsultation()">Trigger</button>
  </div>
</mat-dialog-content>