import { Component, Input } from '@angular/core';
import { Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';
import { LocalStorage } from '../../../services/local-storage-service';

@Component({
  selector: 'user-notes-doctor',
  templateUrl: './user-notes-doctor.html',
})
export class UserNotesDoctorComponent {
  @Input('user')
  set setUser(user: any) {
    if (!user) {
      return;
    }
    this.user = user;
    this.loadNotes();
  }
  @Input() enableAddNote: boolean;
  user: any;
  notes: Array<any> = [];
  addNew: boolean;
  noteMessage: string;
  newNote: any = new Table.Note();
  currentUser: any;
  userRoles: any;
  editAllowed: boolean = false;
  constructor(private conn: ConnectionService, private appConfig: AppConfig, public storage: LocalStorage) {
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.userRoles = this.storage.getJsonValue('userRoles');
  }

  private async loadNotes(): Promise<void> {
    this.notes = await this.conn.findNotes({
      where: { userId: this.user.get('username') },
      project: ['Name', 'message'],
      descending: 'createdAt',
    });
    this.isEditAllowed();
  }

  isEditAllowed(): void {
    const note = this.notes[0];
    const currentTime = new Date();
    if (note) {
      const createdAt = new Date(note.createdAt);
      const timeDifference = currentTime.getTime() - createdAt.getTime();
      const timeDifferenceInMins = timeDifference / (1000 * 60);
      if (timeDifferenceInMins < 10) {
        this.editAllowed = true;
        return;
      }
      this.editAllowed = false;
    }
  }

  async addNote(): Promise<void> {
    if (!this.newNote.id) {
      this.newNote.set('userId', this.user.get('username'));
      this.newNote.set('AddedBy', this.currentUser);
      this.newNote.set('Name', this.currentUser.get('username'));
      this.newNote.set('type', this.appConfig.Notes.Type.MEDICAL);
    }
    try {
      this.newNote.set('message', this.noteMessage);
      await this.newNote.save();
      delete this.noteMessage;
      this.addNew = false;
      this.newNote = new Table.Note();
      await this.loadNotes();
    } catch (e) {
      alert('Unable to save medical note');
    }
  }

  cancelNote(): void {
    this.noteMessage = '';
    this.addNew = !this.addNew;
  }

  editSelectedNote(index: number): void {
    this.newNote = this.notes[index];
    this.noteMessage = this.newNote.get('message');
    this.addNew = true;
  }

  async deleteNote(index: number): Promise<void> {
    const selectedNote = this.notes[index];
    await selectedNote.destroy();
    await this.loadNotes();
  }
}
