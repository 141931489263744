import { NgZone, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { LocalStorage } from 'src/services/local-storage-service';
import { WindowRefService } from 'src/services/window-ref-service';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'assigned-list',
  templateUrl: './assigned-list.html',
  styleUrls: ['./assigned-list.scss'],
})
export class AssignedListComponent implements OnDestroy {
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  filters: Array<any> = [];
  operatorFilter: Array<any> = [];
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  count: any;
  resetOperatorFilter: boolean = false;
  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.listViewSetup();
  }

  udpateOperatorFilter(operatorFilter: Array<any>): void {
    this.resetOperatorFilter = false;
    this.operatorFilter = operatorFilter;
    this.reset();
  }

  resetFilters(): void {
    this.resetOperatorFilter = true;
    delete this.operatorFilter;
    this.reset();
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<any> => {
        const data = await this.loadMore();
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Patient Name',
      field: 'user',
      pinned: 'left',
      editable: true,
      cellRenderer: (params: any): any => {
        const url = this.router.url.split('/');
        let eDiv;
        url.splice(url.length - 1, 1);
        if (!params.data) {
          eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv = this.window.document.createElement('div');
        const id = params.value;
        eDiv.innerHTML = `<a href='/chat/${params.value.UserId
          || params.data.user.username}' target='_blank'> ${params.value.PatientName}</a>`;
        return eDiv;
      },
      width: 200,
    },
    {
      headerName: 'Tagged By',
      field: 'taggedBy.username',
      width: 200,
    },
    {
      headerName: 'Tagged To',
      field: 'taggedTo.username',
      width: 200,
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      width: 150,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<span> ${moment(params.value.iso).format('DD/MM/YY HH:mm A')} </span>`;
        return eDiv;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 120,
    },
    {
      headerName: 'Operator Message',
      field: 'description',
      width: 450,
      cellRenderer: (params: any): any => {
        if (!params.data || !params.value) {
          return '';
        }
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<a class="w100" style="display: block" href='/chat/${params.value.UserId
        || params.data.user.username}' target='_blank'> ${params.value}</a>`;
        eDiv.setAttribute('title', params.value);
        return eDiv;
      },
    }];
  }

  async loadMore(): Promise<any> {
    const where: any = { };
    if (this.operatorFilter && this.operatorFilter.length) {
      where.taggedTo = this.operatorFilter.map((doctor: any) => `${doctor.id}`);
    }
    if (this.operatorFilter && this.operatorFilter.length) {
      where.taggedTo = this.operatorFilter.map((doctor: any) => `${doctor.id}`);
    }
    const datas = JSON.parse(JSON.stringify(await this.conn.getTagsWithInstantCheckups({
      limit: this.ui.limit,
      offset: this.ui.page * this.ui.limit,
      where,
    })));
    this.count = await this.conn.getCountTagsWithInstantCheckups({ where });
    return datas;
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  ngOnDestroy(): void {
    delete this.ui;
    delete this.route;
    delete this.zone;
    delete this.conn;
    delete this.count;
    delete this.operatorFilter;
    delete this.resetOperatorFilter;
  }
}
