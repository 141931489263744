import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../../app/app.config';

@Component({ selector: 'dataUpload-modal', templateUrl: './dataUploadModal.html' })
export class DataUploadModal {
  response: string;

  constructor(public dialogRef: MatDialogRef<DataUploadModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      minCharLength?: number,
    },
    private appConfig: AppConfig) {
  }

  async assignTag(waitListMsg: string): Promise<any> {
    await this.dialogRef.close(waitListMsg);
  }
}
