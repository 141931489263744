
<mat-dialog-content style="border-radius:30px">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change password</h4>
    <button class="close pull-right" type="button" aria-label="Close" (click)="hideModal()"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <form class="form col-md-12 center-block" id="changePassword" (submit)="changePassword()">
      <div class="form-group">
        <label>Enter new password:</label>
        <div>
          <input class="form-control" autocomplete="false" type="password" style="background-color:#FFFFFF" name="password" [(ngModel)]="newPassword"/>
        </div>
      </div>
      <div class="form-group">
        <label>Retype new password:</label>
        <div>
          <input class="form-control" autocomplete="false" type="password" style="background-color:#FFFFFF" name="confirmPassword" [(ngModel)]="confirmPassword"/>
        </div>
      </div>
      <div class="form-group"><br/>
        <input class="btn btn-primary btn-lg btn-block" id="login-submit-btn" type="submit" value="Update" [disabled]="!newPassword || newPassword !== confirmPassword"/>
      </div>
    </form><i class="fa fa-spin fa-refresh center-block" *ngIf="ui.saving"></i>
    <div class="col-xs-12">
      <div class="text-danger" *ngIf="ui.error">{{ui.error}}</div>
    </div>
  </div>
</mat-dialog-content>