import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { LocalStorage } from '../../../services/local-storage-service';
import { AppConfig } from '../../app.config';
import { WindowRefService } from '../../../services/window-ref-service';
import { Broadcaster } from '../../../components/broadcaster';

@Component({
  selector: 'experiment',
  templateUrl: './experiment-edit.html',
  styleUrls: ['./experiment-edit.scss'],
})

export class ExperimentEditComponent {
  // local variable
  experiment: any;
  experimentId: string;
  endDate: Date;
  emailIds: string;

  constructor(
    private windowRefService: WindowRefService,
    private route: ActivatedRoute,
    private router: Router,
    private connectionService: ConnectionService,
    public appConfig: AppConfig,
    public broadcasterService: Broadcaster,
    private storage: LocalStorage) { }

  async ngOnInit(): Promise<void> {
    this.experimentId = this.route.snapshot.params.id;
    this.experiment = new Table.Experiment();
    if (this.experimentId) {
      this.experiment = await ApiConnector.findOne(Table.Experiment,
        { where: { objectId: this.experimentId } });
      this.endDate = this.experiment.get('endDate');
      this.emailIds = this.experiment.get('emailIds')?.join(',');
    }
  }

  onEndDateChange(): void {
    this.experiment.set('endDate', this.endDate);
  }

  async saveExperiment(): Promise<void> {
    const userRoles: Array<string> = this.storage.getJsonValue('userRoles');
    if (!userRoles.includes('admin') || !userRoles.includes('developerRole')) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'EYou doesnot have access to edit or create experiments',
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
    if (!this.endDate) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'End Date is missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    this.experiment.set('minHashCode', Number(this.experiment.get('minHashCode')));
    this.experiment.set('maxHashCode', Number(this.experiment.get('maxHashCode')));
    this.experiment.set('emailIds', this.emailIds.split(','));
    try {
      await this.experiment.save();
      this.broadcasterService.broadcast('NOTIFY', { message: 'Saved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.router.navigate(['/experiment']);
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
