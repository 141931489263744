import { NgZone, Component } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../../app/app.config';
import { Broadcaster } from '../../broadcaster';

@Component({
  selector: 'pincode-file-uploader-modal',
  templateUrl: './pincode-file-uploader.html',
})
export class FileUploaderModel {
  courierName: string;
  uploader: FileUploader;
  ui: { error?: string } = {};
  couriers: Array<{ name: string, value: string }> = [];

  public subscriptions: Subscription[] = [];

  constructor(private zone: NgZone,
              private conn: ConnectionService,
              private router: Router,
              private appConfig: AppConfig,
              private broadcaster: Broadcaster,
              private dialogRef: MatDialogRef<FileUploaderModel>) {
    this.couriers = Object.keys(ApiClientConstant.Order.Courier)
      .filter((key: string) => [
        ApiClientConstant.Order.Courier.ECOMEXPRESS,
        ApiClientConstant.Order.Courier.DELHIVERY,
      ].includes(ApiClientConstant.Order.Courier[key]))
      .map((key: string) => ({
        name: ApiClientConstant.Order.Courier[key].replace(/_/g, ' '),
        value: ApiClientConstant.Order.Courier[key],
      }));
    this.setUpUploader();
  }

  uploadFile(item: any): void {
    this.uploader.setOptions({ url: `${this.conn.getParseUrl()}/api/file/courier/${this.courierName}/upload/pinCodes` });
    item.upload();
  }

  setUpUploader(): void {
    this.uploader = new FileUploader({ url: this.conn.getParseUrl() });
    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): void => {
      this.zone.run(() => {
        item.remove();
        this.hideModal();
      });
    };
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      this.broadcaster.broadcast('NOTIFY', { message: response });
    };
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
