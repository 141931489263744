import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { HistoryViewComponent } from './history-view.component';
import { CatalogInstructionViewModule } from '../../catalog/view/catalog-instruction/catolog-instruction-view-modal';
import { LanguageViewModule } from '../../../../components/language-string/language-view';

const ROUTES: Routes = [
  {
    path: '',
    component: HistoryViewComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MomentModule,
    LanguageViewModule,
    CatalogInstructionViewModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [HistoryViewComponent],
  exports: [HistoryViewComponent],
})
export class HistoryViewModule {
}
