
<mat-dialog-content class="mat-dialog-content">
  <form role="form" name="dataSelectForm" #dataSelectForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Add Labels</h4><i class="fas fa-times pull-right fontL3" style="position: relative; right: -15px; bottom:23px" (click)="hideModal()"></i>
    </div>
    <div class="modal-body" *ngIf="multipleSelect">
      <select class="form-control mb-3" name="selectedData" (change)="addSelectedData($event.target.value)">
        <option value="" disabled="disabled" selected="selected">Select an option</option>
        <option *ngFor="let option of data.options, let i = index" value="{{option}}">{{option}}</option>
      </select>
      <div class="label label-primary ml-2 mt-2 px-3 py-2" *ngFor="let item of response; let i = index;">{{item}}<i class="fa fa-trash-o ml-2" (click)="removeItem(i)"></i></div>
    </div>
    <div class="modal-body" *ngIf="!multipleSelect">
      <select class="form-control mb-3" name="selectedData" (change)="sendDataSingle($event.target.value)">
        <option value="" disabled="disabled" selected="selected">Select an option</option>
        <option *ngFor="let option of data.options, let i = index" value="{{option}}">{{option}}</option>
      </select>
    </div>
    <div class="modal-footer" *ngIf="multipleSelect">
      <button class="pull-right" type="button" name="submit" (click)="sendDataMultiple()" [disabled]="!dataSelectForm.valid">Save</button>
    </div>
  </form>
</mat-dialog-content>