import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConnector } from 'api-client';
import { Broadcaster } from '../../components/broadcaster';
import { ConnectionService } from '../../services/connection-service';
import { LocalStorage } from '../../services/local-storage-service';
import { WindowRefService } from '../../services/window-ref-service';

declare const google: any;

@Component({
  selector: 'login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
})
export class LoginComponent implements OnDestroy {
  user: any;
  ui: { error?: string } = {};
  disableUserPasswordLogin: boolean = true;

  constructor(private zone: NgZone,
    private conn: ConnectionService,
    private router: Router,
    private window: WindowRefService,
    private broadcaster: Broadcaster,
    private storage: LocalStorage) {
  }

  login(): void {
    delete this.ui.error;
    const { username, password }: { username: string, password: string } = this.user;
    this.conn.login(username, password)
      .then(() => this.conn.findUserRoles())
      .then((roles: Array<any>) => this.storage.setJsonValue('userRoles', roles.map((role: any) => role.get('name'))))
      .then(() => this.zone.run(() => this.loginSuccessful()))
      .catch((err: any) => this.zone.run(() => (this.ui.error = err.message)));
  }

  async loginSuccessful(): Promise<void> {
    await this.router.navigate(['/chats']);
    this.broadcaster.broadcast('UserLogin');
  }

  ngOnInit(): void {
    this.user = {};
    google.accounts.id.initialize({
      client_id: '94368577483-mkdsslg5jivg34mjbsvj7i1j9akge3mt.apps.googleusercontent.com',
      hd: 'heallo.ai',
      callback: (response: any) => {
        this.handleLogin(response);
      },
    });

    google.accounts.id.renderButton(this.window.nativeWindow.document.getElementById('google-btn'), {
      theme: 'filled_blue',
      size: 'large',
      shape: 'rectangle',
      width: 350,
    });
    this.updateLoginWithPasswordStatus();
  }

  async handleLogin(response: { clientId: string, credential: string }): Promise<void> {
    const result = await this.conn.loginThroughMail(response.credential);
    const { sessionToken }: any = result[1];
    await ApiConnector.become(sessionToken, { sessionToken });
    const currentUser = ApiConnector.getCurrentUser();
    this.storage.setJsonValue('Parse/myAppId/currentUser', currentUser);
    const roles = await this.conn.findUserRoles(currentUser);
    this.storage.setJsonValue('userRoles', roles.map((role: any) => role.get('name')));
    this.broadcaster.broadcast('UserLogin');
    await this.router.navigate(['/chats']);
  }

  ngOnDestroy(): void {
    delete this.user;
    delete this.ui;
    // delete this.zone;
    delete this.conn;
    delete this.router;
    // delete this.broadcaster;
  }

  private async updateLoginWithPasswordStatus(): Promise<void> {
    try {
      const experiment = await this.conn.findExperiment({
        where: {
          name: 'disableDoctorWebSiteUsernamePasswordLogin',
          enable: true,
        },
      });
      this.disableUserPasswordLogin = !!experiment;
    } catch (error) {
      this.disableUserPasswordLogin = false;
    }
  }
}
