
<mat-dialog-content class="mat-dialog-content">
  <form [formGroup]="form" (ngSubmit)="addWaitingCall()" novalidate="novalidate">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Waiting Call</h4><i class="fas fa-close font16 pull-right" (click)="hideModal()"></i>
    </div>
    <div class="modal-body">
      <div class="col-xs-12">
        <label class="mr-3 mt-0 mb-0">Request Time</label>
        <input class="form-control input-sm" type="datetime-local" formControlName="requestTime" [min]="min" required="true"/>
      </div>
      <div class="col-xs-12">
        <label class="mr-3 mt-0 mb-0">Note</label>
        <textarea class="form-control" placeholder="note" formControlName="note" name="notes"></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button class="pull-right" type="submit">Schedule Call</button>
    </div>
  </form>
</mat-dialog-content>