import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'language-filter',
  templateUrl: './language-filter.html',
})

export class LanguageFilterComponent {
  @Output() updatedLanguageList: EventEmitter<any> = new EventEmitter();
  @Input() set reset(status: boolean) {
    this.resetting = status;
    if (status) this.resetSelection();
  }
  @Input('selectDefaultLanguage') selectDefaultLanguage: boolean;
  rows: Array<{ language: any, selected?: boolean }> = [];
  currentUser: any;
  resetting: boolean = false;
  languages: Array<any> = [];
  constructor(private conn: ConnectionService, private zone: NgZone, public appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.currentUser = this.conn.getCurrentUser();
    this.languages = Object.keys(ApiClientConstant.LanguageString).map((key: string) => ApiClientConstant.LanguageString[key]);
    this.zone.run(() => {
      this.rows = this.languages.map((language: any) => ({
        language,
        selected: this.currentUser.get('knownLanguages').includes(language),
      }));
    });
    this.sendUpdatedList();
  }

  sendUpdatedList(): void {
    if (this.resetting) {
      return;
    }
    this.updatedLanguageList.emit(this.rows.filter(({ selected }: any) => selected).map(({ language }: any) => language));
  }

  toggleSelection(r: { language: any, selected: boolean }): any {
    const row = r;
    row.selected = !row.selected;
    this.sendUpdatedList();
  }

  resetSelection(): void {
    this.zone.run(() => (this.rows = this.languages
      .map((language: any) => ({ language, selected: this.currentUser.get('knownLanguages').includes(language) }))));
    this.resetting = false;
    this.sendUpdatedList();
  }
}
