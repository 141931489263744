import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ApiConnector, Table } from 'api-client';
import { LocalStorage } from 'src/services/local-storage-service';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'internal-team-filter',
  templateUrl: './internal-team.html',
})
export class InternalTeamFilterComponent {
  @Output() updatedInternalTeamList: EventEmitter<any> = new EventEmitter();
  @Input('filter') filter: { [key: string]: unknown } = {};
  @Input() set reset(status: boolean) {
    this.resetting = status;
    if (status) this.resetSelection();
  }
  @Input('type') type: string = '';
  teams: Array<any> = [];
  resetting: boolean = false;
  rows: Array<{ team: any, selected?: boolean }> = [];
  currentUser: any;
  currentUserTeam: any;
  InternalUserRoles: Array<string> = [];
  constructor(private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    private storage: LocalStorage) {
  }

  async ngOnInit(): Promise<any> {
    this.InternalUserRoles = this.storage.getJsonValue('userRoles');
    this.currentUser = this.conn.getCurrentUser();
    await this.fetchActiveInternalTeams();
    this.sendUpdatedList();
  }

  async fetchActiveInternalTeams(): Promise<void> {
    this.teams = await ApiConnector.find(Table.InternalTeam, {
      where: { active: true,
        type: this.type },
    });
    await this.findCurrentUserTeam();
  }

  async findCurrentUserTeam(): Promise<void> {
    this.teams.forEach((each: any): any => {
      const inExtraMember = each.get('extraMembers')?.find((each_: any) => each_.id === this.currentUser.id);
      const inMember = each.get('members')?.find((each_: any) => each_.id === this.currentUser.id);
      if (inExtraMember || inMember) {
        this.currentUserTeam = each;
      }
    });
    this.zone.run(() => (this.rows = this.teams.map((team: any) => ({ team, selected: team.id === this.currentUserTeam?.id }))));
  }

  sendUpdatedList(): void {
    if (this.resetting) {
      return;
    }
    const selectedDoctors = [];
    const selectedTeam: Array<any> = this.rows.filter(({ selected }: any) => selected).map(({ team }: any) => team);
    selectedTeam.forEach((each: any) => {
      each.get('extraMembers')?.forEach((each_: any) => {
        selectedDoctors.push(each_);
      });
      each.get('members')?.forEach((each_: any) => {
        selectedDoctors.push(each_);
      });
    });
    this.updatedInternalTeamList.emit(selectedDoctors);
  }

  toggleSelection(r: { operator: any, selected: boolean }): any {
    if (!this.InternalUserRoles.some((role: string) => ['admin', 'adminDoctor'].includes(role))) return;
    const row = r;
    row.selected = !row.selected;
    this.sendUpdatedList();
  }

  resetSelection(): void {
    this.zone.run(() => (this.rows = this.teams.map((team: any) => ({ team }))));
    this.resetting = false;
    this.sendUpdatedList();
  }
}
