import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from './analytics.component';
import { ListModule } from './list';
import { ViewModule } from './view';

const ROUTES: Routes = [
  {
    path: 'analytics',
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'analytics/:id',
    loadChildren: (): any => ViewModule,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [BrowserModule, RouterModule.forChild(ROUTES)],
  declarations: [AnalyticsComponent],
  exports: [AnalyticsComponent],
})
export class AnalyticsModule {}
