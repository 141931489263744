import { Component, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadFileService } from '../../../services/uploadFileService/uploadFile.service';
import { AppConfig } from '../../../app/app.config';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';

@Component({ selector: 'articleImageUpload-modal', templateUrl: './articleImageUploadModal.html' })
export class ArticleImageUploadModal {
  message: any = '';
  thumbsUp: any;
  window: any;
  file: File;
  imageUrl: string;
  @ViewChild('url', { static: false }) url: ElementRef;
  subscriptions: Array<any> = [];
  constructor(private upload: UploadFileService,
              private appConfig: AppConfig,
              private conn: ConnectionService,
              private windowRef: WindowRefService,
              private dialogRef: MatDialogRef<ArticleImageUploadModal>) {
    this.window = windowRef.nativeWindow;
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  public uploadToAws(): void {
    this.upload.uploadFile({
      file: this.file,
      bucket: 'PRODUCT',
      username: this.conn.getCurrentUser().get('username'),
      source: 'article_image',
    })
      .then((data: any) => {
        this.url.nativeElement.value = data;
        this.imageUrl = data;
        setTimeout(() => {
          this.url.nativeElement.select();
          this.window.document.execCommand('copy');
          alert('Link copied to clipboard. If not copy it manually from the below input box');
        });
      });
  }

  public hideModal(): void {
    this.dialogRef.close(this.imageUrl);
  }
}
