import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { DoctorNotesComponent } from './doctor-notes.component';
import { DirectivesModule } from '../directives.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MomentModule,
    DirectivesModule,
  ],
  declarations: [DoctorNotesComponent],
  exports: [DoctorNotesComponent],
})
export class DoctorNotesModule {
}
