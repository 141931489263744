import { Component, Input } from '@angular/core';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'collapse',
  templateUrl: './collapse.html',
  styleUrls: ['./collapse.scss'],
})
export class CollapseComponent {
  @Input() title: string;
  @Input() shortName: string;
  @Input() showList: boolean;
  @Input() iconClass: string;
  @Input() extraClass: string;
  @Input() fromFollowUp: boolean;

  constructor(public appConfig: AppConfig) { }
}
