import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Table } from 'api-client';
import { AudioRecorderComponent } from '../../audio-recorder/audio-recorder.component';
import { AppConfig } from '../../../app/app.config';
import { ConnectionService } from '../../../services/connection-service';

@Component({
  selector: 'language-voice-recorder',
  templateUrl: './language-voice-recorder.html',
})
export class LanguageVoiceRecorderComponent {
  @Input('identifier')
  set updateIdentifier(identifier: string) {
    this.identifier = identifier;
    this.editView = false;
    this.findLanguageVoice();
  }
  @Input('language')
  set updateLanguage(language: string) {
    this.language = language;
    this.editView = false;
    this.findLanguageVoice();
  }
  @Output('recordingURL') recordingURL: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(AudioRecorderComponent) audioRecorderComponent: AudioRecorderComponent;

  language: string;
  languageVoice: any;
  editView: boolean = false;
  identifier: string;

  constructor(private appConfig: AppConfig, private conn: ConnectionService) {}

  save(): void {
    this.audioRecorderComponent.saveRecording();
  }

  async afterSaveAudioFile(url: string): Promise<void> {
    if (!this.identifier || !this.language) {
      alert('Identifier/language missing');
      return;
    }
    await this.languageVoice.save({ url });
    this.recordingURL.emit(this.languageVoice.get('url'));
    this.switchView();
    delete this.languageVoice;
    this.findLanguageVoice();
  }

  private findLanguageVoice(): void {
    if (!this.identifier || !this.language) {
      return;
    }
    this.conn.findLanguageVoices({ where: { identifier: this.identifier, language: this.language } })
      .then(([languageVoice]: any) => {
        this.languageVoice = languageVoice;
        if (!this.languageVoice) {
          this.languageVoice = new Table.LanguageVoice();
          this.languageVoice.set('language', this.language);
          this.languageVoice.set('identifier', this.identifier);
          this.switchView();
        } else {
          this.recordingURL.emit(this.languageVoice.get('url'));
        }
      });
  }

  switchView(): void {
    this.editView = !this.editView;
  }
}
