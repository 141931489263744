import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WysiwygDirective } from './wysiwyg.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [WysiwygDirective],
  exports: [WysiwygDirective],
})
export class WysiwygModule {
}
