
<div class="col-xs-12 p-0">
  <div class="col-xs-12 b-b p-2">
    <div class="col-xs-2 bold-600">Type</div>
    <div class="col-xs-2 bold-600">Status</div>
    <div class="col-xs-2 bold-600">Request Time</div>
    <div class="col-xs-2 bold-600">Completion Time</div>
    <div class="col-xs-2 bold-600">Completed By</div>
    <div class="col-xs-2 bold-600">Note</div>
  </div>
  <div class="col-xs-12 scroll-y p-2" *ngIf="!userCalls">Loading...</div>
  <div class="col-xs-12 scroll-y p-2" *ngIf="userCalls?.length === 0">No completed calls for the user.</div>
  <div class="col-xs-12 scroll-y p-2" *ngIf="userCalls" style="max-height: 450px;">
    <div class="col-xs-12 p-0 mt-1" *ngFor="let userCall of userCalls;">
      <div class="col-xs-2">{{userCall.type}}</div>
      <div class="col-xs-2">{{userCall.status}}</div>
      <div class="col-xs-2">{{userCall.requestTime.iso | amDateFormat: 'MMM DD, YYYY hh:mm a'}}</div>
      <div class="col-xs-2">{{userCall.updatedAt | amDateFormat: 'MMM DD, YYYY hh:mm a'}}</div>
      <div class="col-xs-2">{{userCall.completedBy?.username || '-'}}</div>
      <div class="col-xs-2">{{userCall.note}}</div>
    </div>
  </div>
  <div class="col-xs-12 mt-3 p-2">
    <button class="btn-danger m-1 btn-xs pull-right" (click)="cancel()">Cancel</button>
  </div>
</div>