<header class="header">
  <div class="flex">
    <h1 class="name">
      <span>{{patientUser?.PatientName}}, </span>
      <span>{{patientUser?.Age}}, </span>
      <span>{{patientUser?.Gender}} | </span>
      <span>{{patientUser?.communicationLanguagePreference || patientUser?.languagePreference}}</span>
    </h1>
    <img *ngIf="patientUser?.orderPlaced" src="/assets/images/paid-user.svg" alt="" class="icon">
  </div>

  <div class="actions flex">
    <div class="dropdown" *ngIf="patientUser?.Age < 18">
      <div class="dropdown-toggle" type="button" id="teenagerDropDown" data-bs-toggle="dropdown" aria-expanded="false">
        <button class="dangerCTA" aria-label="Alert for Teenager">
          <span>Teenager</span>
          <img *ngIf="user?.get('marketingTags').includes(teenagerNoConsent)"
            src="/assets/images/no-consent.svg" alt="Warning">
          <img *ngIf="user?.get('marketingTags').includes(teenagerConsent)"
            src="/assets/images/consented.svg" alt="Warning">
          <img
            *ngIf="!user?.get('marketingTags').includes(teenagerConsent) && !user?.get('marketingTags').includes(teenagerNoConsent)"
            src="/assets/images/warning.svg" alt="Warning">
        </button>
      </div>
      <ul class="dropdown-menu" aria-labelledby="teenagerDropDown">
        <li>
          <a class="dropdown-item">
            <button class="dangerCTA" aria-label="Alert for Teenager"
              (click)="teenagerCallConfirmation(teenagerNoConsent)">
              <span>Teenager</span>
              <img src="/assets/images/no-consent.svg" alt="Warning">
            </button>
          </a>
        </li>
        <li>
          <a class="dropdown-item">
            <button class="dangerCTA" aria-label="Alert for Teenager"
              (click)="teenagerCallConfirmation(teenagerConsent)">
              <span>Teenager</span>
              <img src="/assets/images/consented.svg" alt="Warning">
            </button>
          </a>
        </li>
      </ul>
    </div>

    <div class="dropdown">
      <div class="dropdown-toggle" type="button" id="docDropDown" data-bs-toggle="dropdown" aria-expanded="false">
        <button class="dangerCTA" aria-label="Schedule Doctor Call">
          <span>Doc Call</span>
          <img *ngIf="optedForDoctorCall" src="/assets/images/consented.svg" alt="Warning">
          <img *ngIf="!optedForDoctorCall" src="/assets/images/no-consent.svg" alt="Warning">
        </button>
      </div>
      <ul class="dropdown-menu" aria-labelledby="docDropDown">
        <li>
          <a class="dropdown-item">
            <button class="dangerCTA" aria-label="Alert for Teenager" (click)="updateDoctorCallNeeded(false)">
              <span>Doc Call</span>
              <img src="/assets/images/no-consent.svg" alt="Warning">
            </button>
          </a>
        </li>
        <li>
          <a class="dropdown-item">
            <button class="dangerCTA" aria-label="Alert for Teenager" (click)="updateDoctorCallNeeded(true)">
              <span>Doc Call</span>
              <img src="/assets/images/consented.svg" alt="Warning">
            </button>
          </a>
        </li>
      </ul>
    </div>

  </div>

  <div class="flex align-normal">
    <div class="dropdown select">
      <div class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
        aria-expanded="false">
        <div class="flex">
          <span>Add label</span>
          <img src="/assets/images/dropdown.svg">
        </div>
      </div>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <ng-container *ngFor="let label of labels">
          <li><a class="dropdown-item" (click)="addLabel(label)">{{label}}</a></li>
        </ng-container>
      </ul>
    </div>
    <div class="label-scroll" *ngIf="assignedUserLabelList.length">
      <div class="label label-primary ml-2 px-3 py-2" *ngFor="let label of assignedUserLabelList; let i = index;"
        [style]="{ backgroundColor: label.color }">{{label.name}}<i class="fa fa-trash-o ml-2"
          (click)="removeItem(i, 'userLabel')"></i>
      </div>
    </div>
  </div>


  <div class="skinOrHair">
    <div *ngIf="hasSkinOrder || hasSkinRegimen" type="button" class="flex h100"
      [ngClass]="{'border-bottom': selectedConcern === apiClientConstant.InstantCheckup.Type.FACE}" (click)="updateOrderInfo(apiClientConstant.InstantCheckup.Type.FACE)">
      <div>Skin</div>
    </div>
    <div *ngIf="hasHairOrder || hasHairRegimen" type="button" class="flex h100"
      [ngClass]="{'border-bottom':  selectedConcern === apiClientConstant.InstantCheckup.Type.HAIR}" (click)="updateOrderInfo(apiClientConstant.InstantCheckup.Type.HAIR)">
      <div>Hair</div>
    </div>
  </div>

  <div class="sales-call">
    <div class="dropdown">
      <img src="/assets/images/whatsapp.png" class="whatsapp" alt="WhatsApp" id="whatsappdropdownMenuButton">
      <ul class="dropdown-menu dropleft" aria-labelledby="whatsappdropdownMenuButton">
        <ng-container *ngFor="let action of whatsappActions; let i = index;">
          <li (click)="sendCleverTapEvent(clevertapSupportedEvents[i])"><a class="dropdown-item">{{action}}</a></li>
        </ng-container>
      </ul>
    </div>
    <img class="exotel" src="/assets/images/sales-call.svg" alt="Sales Call"
      (click)="callUserThroughExotel(patientUser?.MobileNumber)">
  </div>
</header>