import { Injectable } from '@angular/core';
import { ApiConnector, ParseObjectExtender } from 'api-client';
import { AppConfig } from '../../app/app.config';

@Injectable()
export class InputHelperService {
  static parameters: any = [AppConfig];
  constructor(public appConfig: AppConfig) {
  }

  getValue(parseObj: any, name: string): any {
    if (!parseObj) return undefined;
    if (parseObj instanceof (ApiConnector as unknown as { getMongoToParseQueryBase: () => any })
      .getMongoToParseQueryBase().getParse().Object) {
      return parseObj.get(name);
    }
    return parseObj[name];
  }

  setValue(parseObj_: any, name: string, parseValue: any): void {
    if (!parseObj_) return;
    const parseObj = parseObj_;
    if (parseObj instanceof (ApiConnector as unknown as { getMongoToParseQueryBase: () => any })
      .getMongoToParseQueryBase().getParse().Object) {
      (parseObj as ParseObjectExtender).set(name, parseValue);
      return;
    }
    parseObj[name] = parseValue;
  }
}
