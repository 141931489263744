import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { v4 as uuid } from 'uuid';
import { ApiClientConstant, ApiConnector, ParseKeys, RequestCountPayload, RequestQueryPayload, Table, ServerApi } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { AppConfig } from '../../app/app.config';
import { Broadcaster } from '../../components/broadcaster';
import { WindowRefService } from '../window-ref-service';
import { ServerAPIModels, ServerAPIRequest, ServerAPIResponse } from '../../app/server-api.constants';
import { ExotelService } from '../exotel-service';

interface CatalogType {
  name?: string;
  descending?: ParseKeys<Table.Catalog>;
  ascending?: ParseKeys<Table.Catalog>;
  q?: string;
  limit?: number;
  offset?: number;
  type?: string;
  where?: any;
  include?: Array<string>;
  mainProduct?: any;
  tags?: Array<any>;
  searchOn?: Array<any>;
  searchKey?: any;
  count?: any;
}
interface OrderType {
  where?: any;
  include?: Array<string>;
  limit?: number;
  offset?: number;
  whereNotEqualTo?: any;
  ascending?: any;
  descending?: any;
  searchOn?: Array<any>;
  searchKey?: any;
  count?: any;
}
interface TagType {
  where?: any;
  user?: any;
  tag?: string;
  status?: Array<string>;
  limit?: number;
  offset?: number;
  tagArray?: Array<string>;
  operators?: Array<any>;
  doctors?: Array<any>;
  teams?: Array<any>;
  type?: any;
  taggedBy?: Array<any>;
}
interface ListType {
  where?: any;
  tags?: number;
  limit?: number;
  followUpState?: string;
  offset?: number;
}
interface Payment {
  userId?: string;
  paymentType?: string;
  caseId?: string;
  TransactionAmount?: string;
  regimenId?: string;
  PaymentStage?: string;
  notes?: string;
  PaymentSource?: string;
  uniqueId?: string;
  byUserObjectId?: string;
  orderObjectId?: string;
  paymentPlan?: string;
  UserEmailId?: string;
  UserMobileNumber?: string;
}
interface PushNotification {
  username: string;
  typeName: string;
  message: string;
  openUrl?: string;
  imageLink?: string;
}
interface AssistantMessage {
  searchKey?: string;
  Inputs?: Array<any>;
  limit?: number;
  offset?: number;
  userId?: string;
  msg?: string;
  mode?: string;
  metadata?: string;
  metadataAction?: string;
  params?: any;
  userLastSeenMessageTime?: string;
  imageCompareLinkFlag?: boolean;
  confirmationNeededFlag?: boolean;
  config?: any;
  blockInputAfterMessage?: boolean;
  context: Record<string, unknown>;
}
interface PaymentType {
  userId: string[];
  caseId: string[];
}
interface OrderPaymentType {
  order: any;
}

interface InstantCheckup {
  userId?: string;
  id?: string;
  user?: any;
}
interface UserType {
  q?: any;
  limit?: number;
  typeRequired?: Array<any>;
  userId?: string;
  include?: string;
  offset?: number;
  ascending?: any;
  descending?: any;
  searchOn?: Array<any>;
  searchKey?: any;
  where?: any;
}
interface ActionLogType {
  Reference?: string;
  regimen?: any;
}
@Injectable()
export class ConnectionService {
  window: any;
  processingExotelCall: boolean;
  serverApi: ServerApi;

  constructor(private appConfig: AppConfig,
    windowRefService: WindowRefService,
    private http: HttpClient,
    private broadcastService: Broadcaster,
    private exotelService: ExotelService) {
    this.window = windowRefService.nativeWindow;
  }

  initialize(): any {
    ApiConnector.initialize('myAppId', `${this.getParseUrl()}/api/parse`);
    this.serverApi = new ServerApi(undefined, this.getParseUrl());
  }

  get isMobileBrowser(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);
  }

  async findSupportChat(payload: RequestQueryPayload<Table.SupportChat>): Promise<Array<any>> {
    return ApiConnector.find(Table.SupportChat, payload);
  }

  async optForRegimenDoctorCall(regimenId: string, opted: boolean): Promise<any> {
    return ApiConnector.cloudRun('optForRegimenDoctorCall', { regimenId, opted });
  }

  async uploadFileToStorage(data: any): Promise<any> {
    return ApiConnector.cloudRun('uploadFileToStorage', data);
  }

  async bulkUpdateOrder(payload: any): Promise<any> {
    return ApiConnector.cloudRun('bulkUpdateOrder', payload);
  }

  async changeOrderPaymentMode(paymentType: string, orderId: string): Promise<any> {
    return ApiConnector.cloudRun('changeOrderPaymentMode', { orderId, paymentType });
  }

  cancelOrder(orderId: any, cancelReason?: string): Promise<any> {
    return ApiConnector.cloudRun('cancelOrder', { orderId, cancelReason });
  }

  async createFollowUp(userId: any): Promise<any> {
    return ApiConnector.cloudRun('createFollowUp', { userId });
  }

  fetchInstantCheckup(username: any, id?: Array<any>, project: string[] = []): Promise<any> {
    return ApiConnector.cloudRun('previousInstantCheckup', { userId: username, id, project });
  }

  generateHashCode(key: string, hashMod: number = 11): number {
    let hash = 0;
    key.split('').forEach((character: string) => {
      const characterCode = character.charCodeAt(0);
      hash = ((hash << 5) - hash) + characterCode;
      hash &= hash;
    });
    return Math.abs(hash % hashMod) + 1;
  }

  getUserHash(username: string, hashMod?: number): string {
    if (!this.getCurrentUser() || !username) return '';
    return `S-${this.generateHashCode(username, hashMod)}`;
  }

  chromeVersion(): any {
    let version;
    const split = this.window.navigator.userAgent.split(' ');
    split.forEach((e: any) => {
      if (e.includes('Chrome')) {
        version = (e.split('/')[1]).split('.')[0];
      }
    });
    return Number(version);
  }

  safariVersion(): any {
    let version;
    const split = this.window.navigator.userAgent.split(' ');
    split.forEach((e: any) => {
      if (e.includes('Version')) {
        version = (e.split('/')[1]).split('.')[0];
      }
    });
    return Number(version);
  }

  rotationNeeded(): any {
    const chromeVersion = this.chromeVersion();
    const safariVersion = this.safariVersion();
    if (chromeVersion && chromeVersion >= 81) {
      return false;
    }
    if (safariVersion && safariVersion >= 13) {
      return false;
    }
    return true;
  }

  async fetchDoctorActivityTime(username: string, start: Date, end: Date): Promise<any> {
    const { hits: { hits } }: any = await ApiConnector.cloudRun('fetchDoctorActivityTime', {
      size: 10000,
      q: `username:${username} AND day:[${start.toISOString()} TO ${end.toISOString()}]`,
      sort: [{ day: { order: 'desc', unmapped_type: 'boolean' } }, { _doc: { order: 'desc' } }],
      script_fields: {},
    });
    return hits;
  }

  getUniqueId(): string {
    return uuid();
  }

  getWebAppUrl(): string {
    switch (this.window.location.hostname) {
      case 'test-doctor.cureskin.com': {
        return 'https://test-app.cureskin.com';
      }
      case 'doctor.cureskin.com': {
        return 'https://app.cureskin.com';
      }
      case 'staging-doctor.cureskin.com': {
        return 'https://staging-app.cureskin.com';
      }
      case 'localhost': {
        return 'https://test-app.cureskin.com';
      }
      case 'canary-doctor.cureskin.com': {
        return 'https://canary-app.cureskin.com';
      }
      case 'rc-doctor.cureskin.com': {
        return 'https://rc-app.cureskin.com';
      }
      default: {
        if (this.window.location.hostname.includes('.rc-app.cureskin.com')) {
          return `https://${this.window.location.hostname.replace('.rc-app.cureskin.com', '.rc-api.cureskin.com')}`;
        }
        if (this.window.location.hostname.includes('.dev-app.cureskin.com')) {
          return `https://${this.window.location.hostname.replace('.dev-app.cureskin.com', '.dev-api.cureskin.com')}`;
        }
        return `http://${this.window.location.hostname}:9000`;
      }
    }
  }

  getParseUrl(): string {
    switch (this.window.location.hostname) {
      case 'test-doctor.cureskin.com': {
        return 'https://test-api.cureskin.com';
      }
      case 'doctor.cureskin.com': {
        return 'https://api.cureskin.com';
      }
      case 'staging-doctor.cureskin.com': {
        return 'https://staging-api.cureskin.com';
      }
      case 'localhost': {
        return 'https://test-api.cureskin.com';
      }
      case 'canary-doctor.cureskin.com': {
        return 'https://canary-api.cureskin.com';
      }
      case 'rc-doctor.cureskin.com': {
        return 'https://rc-api.cureskin.com';
      }
      default: {
        if (this.window.location.hostname.includes('.rc-doctor.cureskin.com')) {
          return `https://${this.window.location.hostname.replace('.rc-doctor.cureskin.com', '.rc-api.cureskin.com')}`;
        }
        if (this.window.location.hostname.includes('.dev-doctor.cureskin.com')) {
          return `https://${this.window.location.hostname.replace('.dev-doctor.cureskin.com', '.dev-api.cureskin.com')}`;
        }
        return `https://${this.window.location.hostname}:9000`;
      }
    }
  }

  getCurrentUser(): any {
    return ApiConnector.getCurrentUser();
  }

  getBaseUrl(): string {
    let url = `${this.window.location.protocol}//${this.window.location.hostname}`;
    if (this.window.location.port) url = `${url}:${this.window.location.port}`;
    return url;
  }

  // Chat related queries : start
  async getUser({ userId, typeRequired, include }: UserType): Promise<any> {
    const payload: RequestQueryPayload<Table.User> = { where: {} };
    if (userId) {
      payload.where.$or = [{ objectId: userId }, { username: userId }, { MobileNumber: userId }, { alternateNumber: userId }];
    }
    payload.include = ['allocatedDoctor', 'allocatedOperator'];
    if (typeRequired) {
      typeRequired.map((type: any) => payload.where[type] = type);
    }
    if (include) include.split(',').forEach((x: string) => payload.include.push(x));
    return ApiConnector.find(Table.User, payload);
  }

  getUserInstantCheckup({ user }: InstantCheckup): any {
    const payload: RequestQueryPayload<Table.InstantCheckup> = {
      where: { user },
      include: ['user'],
      descending: 'createdAt',
      limit: 2000,
    };
    return ApiConnector.find(Table.InstantCheckup, payload);
  }

  fetchUserInstantCheckup({ userId, id, limit, project, skip, type, dateRange }
    : { userId: any; id?: string[]; project?: string[]; limit?: number; skip?: number; type?: string[],
      dateRange?: { startDate?: Date, endDate?: Date} }): Promise<any> {
    return ApiConnector.cloudRun('previousInstantCheckup', { userId, id, limit, project, skip, type, dateRange });
  }

  getDeletedRegimens(userId: string): Promise<any> {
    return ApiConnector.cloudRun('fetchDeletedRegimens', { userId });
  }

  getUserPaymentPlan({ userId }: Payment): any {
    return ApiConnector.find(Table.Payment, { where: { userId }, descending: 'createdAt' });
  }

  async savePayment(paymentDetails: Payment): Promise<any> {
    return ApiConnector.cloudRun('savePayment', { paymentDetails });
  }

  saveOrientation(instantCheckup1: any, instantCheckup2: any): any {
    const params = {
      id1: instantCheckup1.objectId,
      orientation1: instantCheckup1.orientation,
      id2: instantCheckup2.objectId,
      orientation2: instantCheckup2.orientation,
    };
    return ApiConnector.cloudRun('updateInstantCheckupWithOrientation', params);
  }

  async saveOrderMessage({ userId, msg, mode, metadata, user, order, params, userLastSeenMessageTime, Inputs, imageCompareLinkFlag,
    confirmationNeededFlag, config, blockInputAfterMessage, context, uniqueId, isPersonalisedVoiceNote }: any): Promise<any> {
    const feed = new Table.OrderChat();
    feed.set('Owner', ApiConnector.getCurrentUser().get('type')
      .toUpperCase() as ValueOf<typeof ApiClientConstant.ChatMessage.Owner>);
    feed.set('OperatorName', ApiConnector.getCurrentUser().get('username'));
    feed.set('Mode', mode);
    feed.set('Message', msg);
    feed.set('user', user);
    feed.set('order', order);
    feed.set('Metadata', metadata);
    feed.set('context', context || {});
    feed.set('userLastSeenMessageTime', userLastSeenMessageTime);
    feed.set('uniqueId', uniqueId);
    feed.set('isPersonalisedVoiceNote', isPersonalisedVoiceNote);
    if (ApiConnector.getCurrentUser().get('type') === 'doctor') {
      const sms: Array<ValueOf<typeof ApiClientConstant.ChatMessage.NotificationChannel>> = ['SMS'];
      feed.set('notificationChannels', sms);
    }
    if (Inputs) feed.set('Inputs', Inputs);
    if (blockInputAfterMessage !== undefined) feed.set('blockInputAfterMessage', blockInputAfterMessage);
    feed.set('UserId', userId);
    feed.set('uniqueId', uuid());
    await feed.save();
    return feed;
  }

  async replyToUserTicket({ user, userId, msg, mode, metadata, metadataAction, params,
    Inputs, blockInputAfterMessage, ticket, context }: Record<string, any>): Promise<any> {
    const supportChat = new Table.SupportChat();
    supportChat.set('supportTicket', ticket);
    supportChat.set('user', user);
    supportChat.set('Owner', ApiConnector.getCurrentUser().get('type')
      .toUpperCase() as ValueOf<typeof ApiClientConstant.ChatMessage.Owner>);
    supportChat.set('OperatorName', ApiConnector.getCurrentUser().get('username'));
    supportChat.set('Mode', mode);
    supportChat.set('Message', msg);
    supportChat.set('Metadata', metadata);
    supportChat.set('context', context || {});
    if (ApiConnector.getCurrentUser().get('type') === 'doctor') {
      const sms: Array<ValueOf<typeof ApiClientConstant.ChatMessage.NotificationChannel>> = ['SMS'];
      supportChat.set('notificationChannels', sms);
    }
    if (Inputs) supportChat.set('Inputs', Inputs);
    if (blockInputAfterMessage !== undefined) supportChat.set('blockInputAfterMessage', blockInputAfterMessage);
    supportChat.set('UserId', userId);
    supportChat.set('uniqueId', uuid());
    await supportChat.save();
    return supportChat;
  }

  pushNotification({ username, typeName, message, openUrl, imageLink }: PushNotification): any {
    return ApiConnector.cloudRun('pushNotification', { username, typeName, message, openUrl, imageLink });
  }

  getLastFollowUpCreatingDoctorFollowUp(user: any, project?: Array<ParseKeys<Table.FollowUp>>): Promise<any> {
    const payload: RequestQueryPayload<Table.FollowUp> = {
      where: {
        State: ['FINISHED', 'SKIP'],
        user,
        newInstructionCallNeeded: false,
      },
      descending: 'followUpCount',
    };
    if (project && project.length) {
      payload.project = project;
    }
    return ApiConnector.findOne(Table.FollowUp, payload);
  }

  async getFollowUpReports(user: any): Promise<any> {
    return ApiConnector.find(Table.FollowUpReport, {
      where: { user },
      include: ['user', 'beforeInstantCheckup', 'afterInstantCheckup'],
      descending: 'createdAt',
    });
  }

  async getFollowUpReportsById(id: string): Promise<any> {
    return ApiConnector.find(Table.FollowUpReport, {
      where: { objectId: id },
      include: ['user', 'beforeInstantCheckup', 'afterInstantCheckup'],
      descending: 'createdAt',
    });
  }

  getFollowUpObject(user: any, state?: Array<string>): any {
    const payload: RequestQueryPayload<Table.FollowUp> = { where: {}, include: ['allocatedDoctor', 'regimens'] };
    payload.where.user = user;
    if (state) {
      payload.where.$and.push({ State: { $in: state } });
      return ApiConnector.findOne(Table.FollowUp, payload);
    }
    payload.include.push('updatedBy');
    payload.descending = 'createdAt';
    return ApiConnector.findOne(Table.FollowUp, payload);
  }

  async findLatestResponseForFields(user: Table.User, fieldNames: Array<string>): Promise<string> {
    const result = await ApiConnector.findOne(Table.TemporaryAnswer, {
      where: {
        $or: fieldNames.map((fieldName: string) => ({ [fieldName]: { $exists: true } })),
        user,
        expireOn: { $gte: new Date() },
      },
      descending: 'createdAt',
    });
    if (!result) {
      return undefined;
    }
    return result.get(fieldNames.filter((fieldName: string) => result.get(fieldName))[0]);
  }

  resendProgressReport(id: any): Promise<any> {
    return ApiConnector.cloudRun('resendProgressReport', { id });
  }

  fetchFollowUpHistoryData(userId: any): Promise<any> {
    return ApiConnector.cloudRun('fetchFollowupHistory', { userId });
  }

  createEvent(userId: string, type: string, eventTime: Date, uniqueId: string, attributes: { [key: string]: unknown } = {}): Promise<any> {
    return ApiConnector.cloudRun('createEvent', { userId, type, eventTime, uniqueId, attributes });
  }

  getOrderPayments({ order }: OrderPaymentType): Promise<any> {
    return ApiConnector.find(Table.Payment, {
      where: { PaymentStage: { $in: ApiClientConstant.Payment.SuccessStages }, order },
      descending: 'createdAt',
      include: ['byUser'],
    });
  }

  getPayments({ userId, caseId }: PaymentType): any {
    const payload: RequestQueryPayload<Table.Payment> = { where: {} };
    payload.where.$or = [{ userId: { $in: userId } }, { paymentPlan: 'YEARLY' }, { caseId: { $in: caseId } }];
    return ApiConnector.find(Table.Payment, payload);
  }

  async findUsers(payload: RequestQueryPayload<Table.User>): Promise<Array<any>> {
    return ApiConnector.find(Table.User, payload);
  }

  findOneUser(payload: RequestQueryPayload<Table.User>): Promise<Table.User> {
    return ApiConnector.findOne(Table.User, payload);
  }

  countUsers(payload: RequestCountPayload<Table.User>): Promise<number> {
    return ApiConnector.count(Table.User, payload);
  }

  getUsers({ typeRequired = [], q = {}, limit, offset, searchOn, searchKey, where }: UserType): any {
    const payload: RequestQueryPayload<Table.User> = { where: { } };
    if (searchKey) {
      const queryObjects = [];
      searchOn.forEach((e: any) => {
        payload.where.$or.push({ [searchKey]: { $regex: e, $options: 'i' } });
      });
    }
    if (limit) payload.limit = limit;
    if (offset) payload.skip = offset;

    if (where) {
      Object.keys(where).forEach((field: any) => {
        if (where[field] instanceof Array) {
          payload.where[field] = where[field];
        } else if (typeof where[field] === 'string') payload.where[field] = { $regex: where[field], $options: 'i' };
        else payload.where[field] = where[field];
      });
    }
    payload.descending = 'createdAt';
    const queryResult = ApiConnector.find(Table.User, payload);
    return queryResult.then((users: any) => ApiConnector.find(Table.AttendToChatCache, { where: { user: { $in: users } } })
      .then((paidStateOfUsers: any) => {
        users.forEach((each: any, index: any) => {
          each.set('paid', (paidStateOfUsers[index]
              && paidStateOfUsers[index].get('orderState')
              && paidStateOfUsers[index].get('orderState') !== 'UNPAID'));
        });
        return Promise.resolve(users);
      }));
  }

  isPaidUser(userObj: any): any {
    const status = { ACTIVE: 0, IN_ACTIVE: 1, NEVER_ORDER: 2, CANCELED: 3 };
    const user = JSON.parse(JSON.stringify(userObj));
    const orderState = user.orderState || ApiClientConstant.User.OrderState.NEW_USER;
    if (orderState === ApiClientConstant.User.OrderState.NEW_USER) {
      return status.NEVER_ORDER;
    }
    if (orderState === ApiClientConstant.User.OrderState.PROCESSING) {
      return status.ACTIVE;
    }
    if (orderState === ApiClientConstant.User.OrderState.CANCELED) {
      return status.CANCELED;
    }
    if (orderState === ApiClientConstant.User.OrderState.DELIVERED) {
      let refreshDate = null;
      if (user.refreshDate) {
        refreshDate = new Date(user.refreshDate);
      }
      return refreshDate && moment(refreshDate).isAfter(moment().subtract(60, 'day'))
        ? status.ACTIVE : status.IN_ACTIVE;
    }
    return status.NEVER_ORDER;
  }

  findMainConcernsOptions(): Promise<any> {
    return ApiConnector.find(Table.MainConcern, { where: {} });
  }

  updateMainConcern(objectId: any, selection: any): any {
    return ApiConnector.cloudRun('updateMainConcernByUserId', { objectId, selection });
  }

  login(username: string, password: string): any {
    return ApiConnector.userLogIn(username, password);
  }

  logout(): any {
    return ApiConnector.userLogout();
  }

  get isUserLoggedIn(): boolean {
    return !!(this.getCurrentUser() && this.getCurrentUser().get('sessionToken'));
  }

  get isDevelopmentEnvironment(): boolean {
    return this.window.location.hostname.includes('test-doctor') || this.window.location.hostname.includes('localhost');
  }

  get analyticsUrl(): string {
    return 'https://analytics.cureskin.com';
  }

  findAllTags(): any {
    return ApiConnector.find(Table.Labels, { where: { } });
  }

  getAttendToChatMessages({ limit, offset, attended, orderDes, sort, count, operators, doctors, teams, languagePreference }:
      {
        limit?: number,
        offset?: number,
        attended: boolean,
        orderDes?: string,
        sort?: { field: ParseKeys<Table.AttendToChatCache>, ascending: boolean },
        count?: boolean,
        operators?: Array<any>,
        doctors?: Array<any>,
        teams?: Array<ValueOf<typeof ApiClientConstant.Role.Name>>,
        languagePreference?: Array<ValueOf<typeof ApiClientConstant.LanguageString>>,
      }): Promise<any> {
    const payload: RequestQueryPayload<Table.AttendToChatCache> = { where: { },
      include: [] };
    if (teams && teams.length) {
      const orQueries: Array<{}> = teams.map((team: ValueOf<typeof ApiClientConstant.Role.Name>) => {
        const teamQuery: { $and: Array<any>} = { $and: [{ teams: team }] };
        if (team === ApiClientConstant.Role.Name.CHAT_SUPPORT && operators && operators.length) {
          teamQuery.$and.push({ allocatedOperator: { $in: operators } });
        }
        if (team === ApiClientConstant.Role.Name.DOCTOR && doctors && doctors.length) {
          teamQuery.$and.push({ allocatedDoctor: { $in: doctors } });
        }
        return teamQuery;
      });
      payload.where.$or.push(...orQueries);
    }
    if (languagePreference && languagePreference.length) {
      payload.where.$and.push({ languagePreference });
    }
    payload.include.push('user');
    payload.include.push('message');
    payload.include.push('message.user' as 'message');
    payload.include.push('message.user.allocatedOperator' as 'message');
    if (offset) payload.skip = offset;
    if (limit) payload.limit = limit;

    payload.where.message = { $exists: true };
    payload.where.attended = attended;
    if (sort) {
      if (sort.ascending) {
        payload.ascending = sort.field;
      } else {
        payload.descending = sort.field;
      }
    }
    if (attended) {
      payload.where.operator = { $exists: true };
    }
    if (count) return ApiConnector.count(Table.AttendToChatCache, payload);
    return ApiConnector.find(Table.AttendToChatCache, payload);
  }

  getIncomingMessage(user: Table.User): Promise<any> {
    return ApiConnector.findOne(Table.AttendToChatCache, {
      where: { user },
      include: ['user', 'user.allocatedOperator' as 'user', 'message'],
    });
  }

  async findOperatorNotes(payload: RequestQueryPayload<Table.OperatorNote>): Promise<Array<any>> {
    return ApiConnector.find(Table.OperatorNote, payload);
  }

  findNotes(payload: RequestQueryPayload<Table.Note>): Promise<any> {
    return ApiConnector.find(Table.Note, payload);
  }

  findCatalogs(payload: RequestQueryPayload<Table.Catalog>): Promise<Array<any>> {
    this.replaceSpecialCharacterInWhere(payload.where);
    return ApiConnector.find(Table.Catalog, payload);
  }

  findAudioTemplates(payload: RequestQueryPayload<Table.AudioTemplate>): Promise<Array<any>> {
    return ApiConnector.find(Table.AudioTemplate, payload);
  }

  findOneAudioTemplates(payload: RequestQueryPayload<Table.AudioTemplate>): Promise<Table.AudioTemplate> {
    return ApiConnector.findOne(Table.AudioTemplate, payload);
  }

  countAudioTemplates(payload: RequestQueryPayload<Table.AudioTemplate>): Promise<number> {
    return ApiConnector.count(Table.AudioTemplate, payload);
  }

  private replaceSpecialCharacter(value: string): string {
    return ['+', '(', ')', '[', ']']
      .reduce((result: string, specialCharacter: string) => result
        .replace(new RegExp(`\\${specialCharacter}`, 'g'), `\\${specialCharacter}`), value);
  }

  private replaceSpecialCharacterInWhere(where_: { [key: string]: unknown; }): void {
    const where = where_;
    const keys = Object.keys(where);
    keys.forEach((key: string) => {
      if (key === '$regex') {
        where[key] = this.replaceSpecialCharacter(where[key] as string);
        return;
      }
      if (typeof where[key] === 'string') {
        where[key] = this.replaceSpecialCharacter(where[key] as string);
      }
      if (typeof where[key] === 'object') {
        this.replaceSpecialCharacterInWhere(where[key] as { [key: string]: unknown; });
      }
    });
  }

  countCatalogs(payload: RequestCountPayload<Table.Catalog>): Promise<number> {
    return ApiConnector.count(Table.Catalog, payload);
  }

  getCatalogs({ count, tags, limit, descending, ascending, type, mainProduct, offset, include, where, searchKey, searchOn }
                : CatalogType = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.Catalog> = { where: { }, include: [] };
    if (tags && tags.length) {
      tags.forEach((tag: any) => {
        payload.where.$and.push({ tags: tag });
      });
    }

    if (searchKey) {
      const queryObjects = [];
      searchOn.forEach((key: any) => {
        let value = searchKey;
        if (key === 'title') {
          ['+', '(', ')', '[', ']'].forEach((each: string) => (
            value = value.replace(new RegExp(`\\${each}`, 'g'), `\\${each}`)));
        }
        payload.where.$or.push({ [key]: { $regex: value, $options: 'i' } });
      });
    }

    if (where) {
      Object.keys(where).forEach((key: any) => {
        if (key === '$start') {
          Object.keys(where[key]).forEach((field: ParseKeys<Table.Catalog>) => {
            payload.where[field] = { $regex: where[key][field], $options: 'i' };
          });
          return;
        }
        if (where[key] instanceof Array) {
          payload.where[key] = where[key];
          return;
        }
        if (['margId'].includes(key)) {
          payload.where[key] = where[key];
          return;
        }
        let value = where[key];
        if (key === 'title') {
          ['+', '(', ')', '[', ']'].forEach((each: string) => (
            value = value.replace(new RegExp(`\\${each}`, 'g'), `\\${each}`)));
        }
        payload.where[key] = { $regex: value, $options: 'i' };
      });
    }

    if (limit) payload.limit = limit;
    if (offset) payload.skip = offset;
    if (type) payload.where.type = type;
    if (mainProduct) payload.where.mainProduct = { $regex: mainProduct, $options: 'i' };
    if (descending) payload.descending = descending;
    if (ascending) payload.ascending = ascending;
    if (include) include.forEach((key: ParseKeys<Table.Catalog>) => payload.include.push(key));
    payload.include.push('titleLanguageString');

    if (count) return ApiConnector.count(Table.Catalog, payload);
    return ApiConnector.find(Table.Catalog, payload);
  }

  getCatalog(id: string): Promise<any> {
    const payload: RequestQueryPayload<Table.Catalog> = { where: { objectId: id }, include: ['alternateProduct'] };
    return ApiConnector.findOne(Table.Catalog, payload);
  }

  sendUserOrderStatusMessage(userId: any, orderId: any): Promise<any> {
    return ApiConnector.cloudRun('sendOrderStatusMessageToUser', { userId, orderId });
  }

  async getService(id: string): Promise<any> {
    return ApiConnector.findOne(Table.Service, { where: { objectId: id } });
  }

  getArticle(id: string, include: Array<string>): Promise<any> {
    const payload: RequestQueryPayload<Table.ArticleData> = { where: { objectId: id }, include: [] };
    if (include) include.forEach((key: ParseKeys<Table.ArticleData>) => payload.include.push(key));
    return ApiConnector.findOne(Table.ArticleData, payload);
  }

  getEventScheduler(id: string): Promise<any> {
    return ApiConnector.find(Table.EventScheduler, { where: { batchId: id } });
  }

  getFAQ(id: string, include: Array<string>): Promise<any> {
    const payload: RequestQueryPayload<Table.FAQ> = { where: { objectId: id }, include: [] };
    if (include) include.forEach((key: ParseKeys<Table.FAQ>) => payload.include.push(key));
    return ApiConnector.findOne(Table.FAQ, payload);
  }

  findServices(payload: RequestQueryPayload<Table.Service>): Promise<Array<any>> {
    this.replaceSpecialCharacterInWhere(payload.where);
    return ApiConnector.find(Table.Service, payload);
  }

  getServices({ q, limit, descending, ascending, offset, include, type }: any): Promise<any> {
    const payload: RequestQueryPayload<Table.Service> = { where: {}, include: [] };
    if (q) payload.where.title = { $regex: q, $options: 'i' };
    if (limit) payload.limit = limit;
    if (offset) payload.skip = offset;
    if (type) payload.where.type = type;
    if (descending) payload.descending = descending;
    if (ascending) payload.ascending = ascending;
    if (include) include.forEach((key: ParseKeys<Table.Service>) => payload.include.push(key));
    return ApiConnector.find(Table.Service, payload);
  }

  findArticleData(payload: RequestQueryPayload<Table.ArticleData>): Promise<Array<any>> {
    return ApiConnector.find(Table.ArticleData, payload);
  }

  countArticleData(payload: RequestCountPayload<Table.ArticleData>): Promise<number> {
    return ApiConnector.count(Table.ArticleData, payload);
  }

  findLeavesAndHolidays(payload: RequestQueryPayload<Table.LeaveAndHoliday>): Promise<Array<any>> {
    return ApiConnector.find(Table.LeaveAndHoliday, payload);
  }

  findSKUs(): Promise<Array<any>> {
    return ApiConnector.find(Table.SKU, { where: {}, include: ['products'] });
  }

  findFAQ(payload: RequestQueryPayload<Table.FAQ>): Promise<Array<any>> {
    return ApiConnector.find(Table.FAQ, payload);
  }

  findBatchRequest(payload: RequestQueryPayload<Table.BatchRequest>): Promise<Array<any>> {
    return ApiConnector.find(Table.BatchRequest, payload);
  }

  findWareHouse(payload: RequestQueryPayload<Table.Warehouse>): Promise<Array<any>> {
    return ApiConnector.find(Table.Warehouse, payload);
  }

  async findWareHouseById(id: any): Promise<any> {
    return ApiConnector.findOne(Table.Warehouse, { where: { objectId: id } });
  }

  getInstantCheckup(payload: RequestQueryPayload<Table.InstantCheckup>): Promise<Array<any>> {
    return ApiConnector.find(Table.InstantCheckup, payload);
  }

  countBatchRequest(payload: RequestCountPayload<Table.BatchRequest>): Promise<number> {
    return ApiConnector.count(Table.BatchRequest, payload);
  }

  // findEventScheduler(payload: RequestQueryPayload<Table.EventScheduler>): Promise<Array<any>> {
  //   return ApiConnector.find(Table.EventScheduler, payload);
  // }

  // countEventScheduler(payload: RequestCountPayload<Table.EventScheduler>): Promise<number> {
  //   return ApiConnector.count(Table.EventScheduler, payload);
  // }

  findFailedEvents(payload: RequestQueryPayload<Table.Event>): Promise<Array<any>> {
    return ApiConnector.cloudRun('fetchFailedEvents', { where: payload.where, limit: payload.limit, skip: payload.skip });
  }

  countFailedEvents(payload: RequestQueryPayload<Table.Event>): Promise<{ count: number }> {
    return ApiConnector.cloudRun('fetchFailedEvents', { where: payload.where, count: true });
  }

  resetFailedEvent(messageId: string): Promise<number> {
    return ApiConnector.cloudRun('resetEventById', { messageId });
  }

  completeFailedEvent(messageId: string): Promise<number> {
    return ApiConnector.cloudRun('completeEventById', { messageId });
  }

  countLeavesAndHolidays(payload: RequestCountPayload<Table.LeaveAndHoliday>): Promise<number> {
    return ApiConnector.count(Table.LeaveAndHoliday, payload);
  }

  countFAQ(payload: RequestCountPayload<Table.FAQ>): Promise<number> {
    return ApiConnector.count(Table.FAQ, payload);
  }

  getLeavesAndHolidays(id: string, include: Array<string>): Promise<any> {
    const payload: RequestQueryPayload<Table.LeaveAndHoliday> = { where: { objectId: id }, include: [] };
    if (include) include.forEach((key: ParseKeys<Table.LeaveAndHoliday>) => payload.include.push(key));
    return ApiConnector.findOne(Table.LeaveAndHoliday, payload);
  }

  getArticleData({ q, limit, descending, ascending, offset, include, type }: any): Promise<any> {
    const payload: RequestQueryPayload<Table.ArticleData> = { where: { }, include: [] };
    if (q) payload.where.title = { $regex: q, $options: 'i' };
    if (limit) payload.limit = limit;
    if (offset) payload.skip = offset;
    if (type) payload.where.type = type;
    if (descending) payload.descending = descending;
    if (ascending) payload.ascending = ascending;
    if (include) include.forEach((key: ParseKeys<Table.ArticleData>) => payload.include.push(key));
    return ApiConnector.find(Table.ArticleData, payload);
  }

  getRegimen(id: string, alternateRegimen?: boolean, regimenClass?: string): Promise<any> {
    const payload: RequestQueryPayload<Table.Regimen> = {
      where: {
        $or: [
          { objectId: id },
          { regimenId: id },
        ],
      },
      include: [],
    };
    if (alternateRegimen) {
      payload.where.type = ApiClientConstant.Regimen.Type.ALTERNATE;
    }
    if (regimenClass) {
      payload.where.class = regimenClass;
    }
    payload.include.push('morning.product' as 'morning');
    payload.include.push('morning.instructionSet' as 'morning');
    payload.include.push('night.instructionSet' as 'night');
    payload.include.push('night.product' as 'night');
    payload.include.push('consultationService');
    payload.include.push('dietAndLifestyleQuestion');
    payload.include.push('products');
    payload.include.push('forUser');
    payload.include.push('variants.regimenTags' as 'variants');
    payload.include.push('variants.morning.instructionSet' as 'variants');
    payload.include.push('variants.night.instructionSet' as 'variants');
    payload.include.push('variants.products' as 'variants');
    payload.include.push('variants.morning.product' as 'variants');
    payload.include.push('variants.night.product' as 'variants');
    payload.include.push('variants.morning.purposeDescriptionLanguageString' as 'variants');
    payload.include.push('variants.night.purposeDescriptionLanguageString' as 'variants');
    payload.include.push('forUser');
    return ApiConnector.findOne(Table.Regimen, payload);
  }

  getRegimenHistoryById(id: string): Promise<any> {
    const payload: RequestQueryPayload<Table.RegimenHistory> = { where: { objectId: id },
      include: ['morning.product' as 'morning', 'night.product' as 'night', 'regimen', 'consultationService', 'dietAndLifestyleQuestion'] };
    return ApiConnector.findOne(Table.RegimenHistory, payload);
  }

  getSKUEntriesById(ids: Array<string>): Promise<any> {
    return ApiConnector.find(Table.SKU, { where: { objectId: ids } });
  }

  generateInvoice(orderId: any): Promise<any> {
    return ApiConnector.cloudRun('createInvoiceUploadUrl', { orderId });
  }

  generatePrescriptionForRegimen(objectId: any): any {
    return ApiConnector.cloudRun('createPrescriptionUploadUrl', { objectId });
  }

  addCashEntry({ type, amount, userObjectId, referenceType }:
                 { type: number, amount: number, userObjectId: string, referenceType: string }): void {
    ApiConnector.cloudRun('addCashTransaction', { type, amount, userObjectId, referenceType });
  }

  applyGiftCard(payload: Record<string, any>): Promise<any> {
    return ApiConnector.cloudRun('applyGiftCard', payload);
  }

  getRegimens({ count, tags, where, include, active, showMain, showAlternate, limit, offset, descending, ascending, searchKey, searchOn,
    project }: any = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.Regimen> = { where: { } };
    if (tags && tags.length) {
      tags.forEach((tag: any) => {
        payload.where.tags = tag;
      });
    }
    if (searchKey) {
      const queryObjects = [];
      searchOn.forEach((e: any) => {
        payload.where.$or.push({ [searchKey]: { $regex: e, $options: 'i' } });
      });
    }
    if (where) {
      Object.keys(where).forEach((key: any) => {
        if (key === '$start') {
          Object.keys(where[key]).forEach((field: ParseKeys<Table.Regimen>) => payload.where[field] = { $regex: `^${where[key][field]}` });
          return;
        }
        if (where[key] instanceof Array) {
          payload.where[key] = { $in: where[key] };
          return;
        }
        payload.where[key] = { $regex: where[key], $options: 'i' };
      });
    }

    if (typeof active === 'boolean') payload.where.active = active;

    if (showMain) {
      payload.where.type = ApiClientConstant.Regimen.Type.MAIN;
    }
    if (showAlternate) {
      payload.where.type = ApiClientConstant.Regimen.Type.ALTERNATE;
    }

    // ['morning.product', 'night.product', 'consultationService']
    if (include?.length) {
      include.forEach((key: ParseKeys<Table.Regimen>) => payload.include.push(key));
    }

    if (limit) payload.limit = limit;
    else payload.limit = 2000;
    if (offset) payload.skip = offset;
    if (descending) payload.descending = descending;
    if (ascending) payload.ascending = ascending;
    if (!ascending && !descending) payload.ascending = 'regimenId';
    if (count) return ApiConnector.count(Table.Regimen, payload);
    if (project?.length) {
      payload.project.push(project);
    }
    return ApiConnector.find(Table.Regimen, payload);
  }

  findTemporaryAnswer(payload_: RequestQueryPayload<Table.TemporaryAnswer>): Promise<Array<any>> {
    const payload = payload_;
    if (!payload.descending) {
      payload.ascending = payload.ascending || 'expireOn';
    }
    return ApiConnector.find(Table.TemporaryAnswer, payload);
  }

  findTree(payload_: RequestQueryPayload<Table.Tree>): Promise<Array<any>> {
    const payload = payload_;
    if (!payload.descending) {
      payload.ascending = payload.ascending || 'name';
    }
    return ApiConnector.find(Table.Tree, payload);
  }

  getTreeList({ where, notName }: { where?: any, notName?: string } = {}, limit: number = 100): Promise<any> {
    const payload: RequestQueryPayload<Table.Tree> = { where: { } };
    if (where) {
      Object.keys(where).forEach((field: any) => payload.where[field] = where[field]);
    }
    if (notName) payload.where.name = { $ne: notName };
    payload.limit = limit;
    payload.ascending = 'name';
    return ApiConnector.find(Table.Tree, payload);
  }

  createFileUploadUrl(param: Record<string, unknown>): Promise<any> {
    return ApiConnector.cloudRun('createFileUploadUrl', param);
  }

  sendNotificationSMS(message: string, number: string, userId: string, operator: string, messageRoute:string): Promise<any> {
    return ApiConnector.cloudRun('sendNotificationSMS', { message, number, userId, operator, messageRoute });
  }

  getFollowUp({ where, limit, offset }: ListType): any {
    return ApiConnector.cloudRun('findFollowupWithRecentInstantCheckup', { where, limit, offset });
  }

  getFollowUpCount({ where }: ListType): any {
    const payload: RequestQueryPayload<Table.FollowUp> = { where: { } };
    payload.where.State = 'PENDING';
    payload.where.effectiveFollowUpDate = {
      $lte: moment().endOf('day').add(1, 'day').toDate(),
    };
    Object.keys(where).forEach((key: ParseKeys<Table.FollowUp>) => {
      if (['_p_allocatedOperator'].includes(key)) {
        const fieldName = key.replace('_p_', '');
        const items = where[key].$in.map((pointer: string) => {
          const [className, objectId]: any = pointer.split('$');
          const item = new Table[className]();
          item.id = objectId;
          return item;
        });
        payload.where[fieldName] = items;
        return;
      }
      payload.where[key] = where[key];
    });
    return ApiConnector.count(Table.FollowUp, payload);
  }

  getFollowUpWithStatePending(state: string, ready: boolean, limit?: number, offset?: number): Promise<any> {
    const payload: RequestQueryPayload<Table.FollowUp> = { where: { }, include: ['user', 'nextFollowUpDate'] };
    payload.where.State = state;
    payload.where.ready = ready;
    if (limit) payload.limit = limit;
    if (offset) payload.skip = offset;
    return ApiConnector.find(Table.FollowUp, payload);
  }

  getCatalogRegimens(catalog: any): Promise<any> {
    const payload: RequestQueryPayload<Table.Regimen> = {
      where: { $and: [
        { products: catalog },
        { type: { $in: [ApiClientConstant.Regimen.Type.MAIN, ApiClientConstant.Regimen.Type.ALTERNATE] } }] },
      ascending: 'regimenId',
    };
    return ApiConnector.find(Table.Regimen, payload);
  }

  getQuestion(id: string): Promise<any> {
    return ApiConnector.findOne(Table.Question, { where: { objectId: id } });
  }

  getQuestions({ q, where, excludeIds }: { q?: string, where?: any, limit?: number, excludeIds?: Array<string> } = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.Question> = { where: {}, limit: 1000, include: ['titleLanguageString'] };
    if (where) {
      Object.keys(where).forEach((field: any) => {
        if (where[field] instanceof Array) {
          payload.where[field] = { $in: where[field] };
          return;
        }
        payload.where[field] = { $regex: where[field], $options: 'i' };
      });
    }
    if (q) {
      payload.where.$or = [{ title: { $regex: q, $options: 'i' } }, { uniqueIdentifier: { $regex: q, $options: 'i' } }];
    }
    if (excludeIds) {
      payload.where.uniqueIdentifier = { $nin: excludeIds };
    }
    return ApiConnector.find(Table.Question, payload);
  }

  getLanguageString(id: string): Promise<any> {
    const payload: RequestQueryPayload<Table.LanguageString> = { where: { objectId: id }, include: ['tags'], descending: 'createdAt' };
    return ApiConnector.findOne(Table.LanguageString, payload);
  }

  getLanguageStringByTag(tag: any): Promise<any> {
    const payload: RequestQueryPayload<Table.LanguageString> = { where: { tags: { $in: tag } }, descending: 'createdAt', limit: 1000 };
    return ApiConnector.find(Table.LanguageString, payload);
  }

  getLanguageTagByObjectId(objectId: string): Promise<any> {
    return ApiConnector.findOne(Table.LanguageStringTag, {
      where: { objectId },
      project: ['name'],
    });
  }

  async getUserPreferedLanguage(preferedLanguage: string, languageString: any): Promise<string> {
    if (languageString.get(preferedLanguage)) {
      return languageString.get(preferedLanguage);
    }
    return languageString.get(ApiClientConstant.LanguageString.EN);
  }

  getTranslatedStringOfUserLanguage(user: any, languageString: any): string {
    if (!languageString || !languageString.get(user.get('languagePreference'))) {
      this.broadcastService.broadcast('NOTIFY',
        { message: 'Language string is either not preset or translation iss not present',
          type: this.appConfig.Shared.Toast.Type.ERROR });
      return '';
    }
    return languageString.get(user.get('languagePreference'));
  }

  getLanguageTagByName(tagName: string[]): Promise<any> {
    return ApiConnector.find(Table.LanguageStringTag, { where: { name: tagName } });
  }

  getLanguageStrings({ q, tags, approved, hiApproved, knApproved, teApproved, fetchEmptyTags, id }: {
    q?: RegExp, tags?: Array<any>, approved?: boolean, fetchEmptyTags?: boolean, id?: Array<string>, hiApproved?: string,
    knApproved?: string, teApproved?: string } = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.LanguageString> = { where: { } };
    if (q) {
      payload.where.$or.push({ uniqueId: { $regex: q, $options: 'i' } });
      payload.where.$or.push({ en: { $regex: q, $options: 'i' } });
      payload.where.$or.push({ hi: { $regex: q, $options: 'i' } });
    }
    if (tags) {
      if (!tags.length && !fetchEmptyTags) return Promise.resolve([]);
      if (tags.length) {
        tags.forEach((tag: any) => {
          payload.where.$and.push({ tags: tag });
        });
      }
    }
    if (typeof approved === 'boolean') {
      payload.where.approved = approved;
    }
    if (typeof hiApproved === 'boolean') {
      payload.where.hiApproved = hiApproved;
    }
    if (typeof knApproved === 'boolean') {
      payload.where.knApproved = knApproved;
    }
    if (typeof teApproved === 'boolean') {
      payload.where.teApproved = teApproved;
    }
    if (id) {
      payload.where.objectId = id;
    }
    payload.ascending = 'en';
    payload.limit = 1000;
    return ApiConnector.find(Table.LanguageString, payload);
  }

  getProducts({ q, where }: { q?: RegExp, where?: any, limit?: number } = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.Catalog> = { where: { } };
    if (where) {
      Object.keys(where).forEach((field: any) => {
        if (where[field] instanceof Array) {
          payload.where[field] = { $in: where[field] };
          return;
        }
        payload.where[field] = { $regex: where[field], $options: 'i' };
      });
    }
    if (q) {
      payload.where.$or.push({ title: { $regex: q, $options: 'i' } });
      payload.where.$or.push({ uniqueIdentifier: { $regex: q, $options: 'i' } });
    }
    payload.limit = 1000;
    return ApiConnector.find(Table.Catalog, payload);
  }

  getTreeNodes({ where }: { where?: any } = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.Tree> = { where: { type: ApiClientConstant.Tree.Type.BASIC_INFO } };
    if (where) {
      Object.keys(where).forEach((field: any) => payload.where[field] = where[field]);
    }
    payload.ascending = 'name';
    return ApiConnector.find(Table.Tree, payload);
  }

  getIndicationTreeNodes({ q }: { q?: RegExp } = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.Tree> = { where: { type: ApiClientConstant.Tree.Type.INDICATION } };
    if (q) payload.where.name = { $regex: q, $options: 'i' };
    payload.ascending = 'name';
    return ApiConnector.find(Table.Tree, payload);
  }

  getActionTreeNodes({ q, notName }: { q?: RegExp, notName?: string } = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.Tree> = { where: { type: ApiClientConstant.Tree.Type.ACTION } };
    if (q) payload.where.name = { $regex: q, $options: 'i' };
    if (notName) payload.where.name = { $nin: notName };
    payload.ascending = 'name';
    return ApiConnector.find(Table.Tree, payload);
  }

  findOneTree(payload: RequestQueryPayload<Table.Tree>): Promise<any> {
    return ApiConnector.findOne(Table.Tree, payload);
  }

  getTree({ name, type }: { name: string, type: ValueOf<typeof ApiClientConstant.Tree.Type> }): Promise<any> {
    return ApiConnector.findOne(Table.Tree, { where: { name, type }, include: ['questions'] });
  }

  findRefund(payload: RequestQueryPayload<Table.Refund>): Promise<Array<any>> {
    return ApiConnector.find(Table.Refund, payload);
  }

  findActionLog(payload: RequestQueryPayload<Table.ActionLog>): Promise<any> {
    return ApiConnector.find(Table.ActionLog, payload);
  }

  getAttendToChatUser(payload: RequestQueryPayload<Table.AttendToChatCache>): Promise<any> {
    return ApiConnector.find(Table.AttendToChatCache, payload);
  }

  getActionLog({ Reference, regimen }: ActionLogType): Promise<any> {
    const payload: RequestQueryPayload<Table.ActionLog> = {
      where: {},
      descending: 'createdAt',
      include: ['regimen', 'ByUser'],
    };
    if (regimen) {
      payload.where.regimen = { $in: regimen };
    }
    if (Reference) {
      payload.where.Reference = Reference;
    }
    return ApiConnector.find(Table.ActionLog, payload);
  }

  getRegimenHistory(regimenId: any, skip: number = 0): Promise<any> {
    return ApiConnector.find(Table.RegimenHistory, {
      where: { regimenId },
      descending: 'createdAt',
      project: ['morning', 'night'],
      skip,
      limit: 10,
    });
  }

  fetchTreeHavingQuestion(question: any): Promise<any> {
    return ApiConnector.find(Table.Tree, { where: { questions: question }, ascending: 'name' });
  }

  findConsultationSession(payload: RequestQueryPayload<Table.ConsultationSession>): Promise<any> {
    return ApiConnector.find(Table.ConsultationSession, payload);
  }

  getOrders({ count, where, include, limit, offset, whereNotEqualTo, descending, ascending, searchOn, searchKey }
              : OrderType): Promise<any> {
    const payload: RequestQueryPayload<Table.Order> = { where: { }, include: [] };
    if (searchKey) {
      const queryObjects = [];
      searchOn.forEach((e: any) => {
        payload.where.$or.push({ [e]: { $regex: searchKey, $options: 'i' } });
      });
    }
    if (where) {
      Object.keys(where).forEach((field: any) => {
        if (where[field] instanceof Array) {
          payload.where[field] = { $in: where[field] };
        } else if (typeof where[field] === 'string') payload.where[field] = { $regex: where[field], $options: 'i' };
        else payload.where[field] = where[field];
      });
    }
    if (whereNotEqualTo) {
      Object.keys(whereNotEqualTo).forEach((field: any) => {
        if (whereNotEqualTo[field] instanceof Array) {
          payload.where[field] = { $nin: whereNotEqualTo[field] };
        } else payload.where[field] = { $ne: whereNotEqualTo[field] };
      });
    }
    if (include) include.forEach((key: ParseKeys<Table.Order>) => payload.include.push(key));
    if (descending) payload.descending = descending;
    if (ascending) payload.ascending = ascending;
    if (!ascending && !descending) payload.descending = 'createdAt';
    if (limit) payload.limit = limit;
    if (offset) payload.skip = offset;
    if (count) return ApiConnector.count(Table.Order, payload);
    return ApiConnector.find(Table.Order, payload);
  }

  getOrder(id: string): Promise<any> {
    const payload: RequestQueryPayload<Table.Order> = { where: { $or: [] }, include: ['user', 'products', 'createdBy'] };
    if (id) {
      payload.where.$or.push({ objectId: id });
      payload.where.$or.push({ orderNumber: id });
    }
    return ApiConnector.findOne(Table.Order, payload);
  }

  getOrderWithSignedURL(id: string): Promise<any> {
    return ApiConnector.cloudRun('findOrderWithSignedURL', { id, view: true });
  }

  getMediaLink(payload: RequestQueryPayload<Table.MediaLink>): Promise<any> {
    return ApiConnector.find(Table.MediaLink, payload);
  }

  async getCoverImageFromUniqueId(uniqueId: string): Promise<string> {
    const mediaLink = await ApiConnector.findOne(Table.MediaLink, {
      where: { uniqueId },
      project: ['link'],
    });
    return mediaLink.get('link');
  }

  async callThroughExotel(patientMobileNumber: string, fromPage: string, pendingCall?: Table.PendingCall): Promise<Table.CallLog> {
    if (!this.getCurrentUser().get('type')) return Promise.resolve(undefined);
    if (this.processingExotelCall) {
      return undefined;
    }
    this.processingExotelCall = true;
    const isSoftPhoneCall = this.getCurrentUser().get('callerType') === this.appConfig.Shared.User.CallerType.UNLIMITED_SOFTPHONE;
    try {
      if (isSoftPhoneCall) {
        if (this.exotelService.callActive) {
          return undefined;
        }
        await this.findOneUser({ where: { objectId: this.getCurrentUser().id }, project: ['isSoftphoneDeviceActive'] });
        this.exotelService.dial(patientMobileNumber, fromPage, this.getCurrentUser().get('isSoftphoneDeviceActive'));
        this.processingExotelCall = false;
        return undefined;
      }
      const result = await ApiConnector.cloudRun('callThroughExotel', {
        patientMobileNumber,
        userMobileNumber: this.getCurrentUser().get('exotelNumber') || this.getCurrentUser().get('MobileNumber'),
        callerId: this.getCurrentUser().get('CallerId'),
      });
      this.processingExotelCall = false;
      result.set('fromPage', fromPage);
      result.set('pendingCall', pendingCall);
      await result.save();
      return result;
    } catch (error) {
      this.processingExotelCall = false;
      this.exotelService.resetState();
      return Promise.reject(error);
    } finally {
      this.processingExotelCall = false;
    }
  }

  // getConsultations({ where, q, limit, descending, ascending, offset }: CatalogType = {}): Promise<any> {
  //   const query = new Parse.Query(this.appConfig.Table.Consultation);
  //   if (where) {
  //     Object.keys(where).forEach((field: any) => {
  //       if (typeof where[field] === 'string') query.matches(field, where[field], 'i');
  //       else query.equalTo(field, where[field]);
  //     });
  //   }
  //   query.include('payment');
  //   if (q) query.matches('title', q, 'i');
  //   if (limit) query.limit(limit);
  //   if (offset) query.skip(offset);
  //   if (descending) query.descending(descending);
  //   if (ascending) query.ascending(ascending);
  //   return query.find();
  // }

  // getConsultation(id: string): Promise<any> {
  //   const query = new Parse.Query(this.appConfig.Table.Consultation);
  //   query.equalTo('objectId', id);
  //   return query.first();
  // }

  getAllConsultationSession(user: any): Promise<any> {
    return ApiConnector.find(Table.ConsultationSession, { where: { user, archive: false }, descending: 'createdAt' });
  }

  activateState({ username, stateId, context, resetTree, storeResponse, language }:
    { username: string, stateId: string, context: any, resetTree?: any, storeResponse?: any; language?: string; }): Promise<any> {
    return ApiConnector.cloudRun('switchUserToState', { username, stateId, context, resetTree, storeResponse, language });
  }

  addUserToOptOutOfSMS(userId: any): Promise<any> {
    return ApiConnector.cloudRun('addUserToOptOutOfSMS', { userId });
  }

  updateUserData(data: any): Promise<any> {
    return ApiConnector.cloudRun('updateUserData', data);
  }

  findRegimens(payload: RequestQueryPayload<Table.Regimen>): Promise<Array<any>> {
    return ApiConnector.find(Table.Regimen, payload);
  }

  findPrescription(payload: RequestQueryPayload<Table.PrescriptionData>): Promise<Array<any>> {
    return ApiConnector.find(Table.PrescriptionData, payload);
  }

  getUserRegimens(user: any, activeOnly?: boolean): Promise<any> {
    const payload: RequestQueryPayload<Table.Regimen> = { where: { forUser: user },
      include: [
        'morning.product' as 'morning',
        'night.product' as 'night',
        'consultationService',
        'concernsLanguageString',
        'optedForDoctorCall',
      ] };
    if (activeOnly) {
      payload.where.active = true;
    }
    return ApiConnector.find(Table.Regimen, payload);
  }

  findTags(payload_: RequestQueryPayload<Table.Tag>): Promise<Array<any>> {
    const payload = payload_;
    if (!payload.ascending) {
      payload.descending = payload.descending || 'createdAt';
    }
    return ApiConnector.find(Table.Tag, payload);
  }

  getTags({ user, tag, status, limit, offset, operators, doctors, tagArray, type, taggedBy }: TagType, count?: boolean): Promise<any> {
    const payload: RequestQueryPayload<Table.Tag> = { where: { } };
    if (taggedBy && taggedBy.length) {
      payload.where.taggedBy = taggedBy;
    }
    if (limit) payload.limit = limit;
    if (offset) payload.skip = offset;
    if (user) payload.where.user = user;
    if (tag) payload.where.tag = { $regex: tag, $options: 'i' };
    if (status) payload.where.status = { $in: status };
    if (tagArray) payload.where.tag = { $in: tagArray };
    if (type) payload.where.type = type;
    payload.descending = 'createdAt';
    payload.include.push('tag');
    payload.include.push('user');
    payload.include.push('user.allocatedOperator' as 'user');
    payload.include.push('taggedBy');
    payload.include.push('taggedTo');
    payload.include.push('messageId');
    payload.include.push('tagNote');
    payload.include.push('completedBy');
    if (count) {
      return ApiConnector.count(Table.Tag, payload);
    }
    return ApiConnector.find(Table.Tag, payload);
  }

  getTagsWithInstantCheckups({ where, limit, offset }: TagType): Promise<any> {
    return ApiConnector.cloudRun('findDoctorAssignedChatsWithRecentInstantCheckupTime', { where, limit, offset, orderDes: 'createdAt' });
  }

  async getCountTagsWithInstantCheckups({ where }: TagType): Promise<number> {
    const data = await ApiConnector.cloudRun('findDoctorAssignedChatsWithRecentInstantCheckupTime', { where });
    return data.length;
  }

  async saveAll(items: Array<any>): Promise<any> {
    await ApiConnector.saveAll(items);
  }

  findUserRoles(user?: any): Promise<any> {
    const payload: RequestQueryPayload<Table.Role> = { where: { roleType: 'INTERNAL' } };
    if (user) {
      payload.where.users = user;
    }
    return ApiConnector.find(Table.Role, payload);
  }

  downloadOrderInShipStage(courier: string): Promise<any> {
    return ApiConnector.cloudRun('downloadOrderInShipStage', { courier });
  }

  getLinks(): Promise<any> {
    return ApiConnector.find(Table.EasyAccess, { where: { } });
  }

  getOrderLog(order: any): Promise<any> {
    return ApiConnector.find(Table.OrderConsultationLog, { where: { order }, include: ['createdBy'], descending: 'createdAt' });
  }

  getOrderStage(stage?: ValueOf<typeof ApiClientConstant.Order.Stage>): Promise<any> {
    const payload: RequestQueryPayload<Table.OrderStage> = { where: { }, include: ['possibleStageChange'] };
    if (stage) payload.where.name = stage;
    return ApiConnector.findOne(Table.OrderStage, payload);
  }

  getOrderStages(): Promise<any> {
    return ApiConnector.find(Table.OrderStage, { where: { }, include: ['possibleStageChange'], descending: 'createdAt' });
  }
  getLatestOrderPaymentType(user: any): Promise<any> {
    return ApiConnector.findOne(Table.Order,
      {
        where: {
          user,
          paymentType: { $ne: ApiClientConstant.Order.PaymentType.NA },
          stage: { $in: [ApiClientConstant.Order.Stage.DELIVERED, ApiClientConstant.Order.Stage.COMPLETED] },
        },
        descending: 'createdAt',
      });
  }

  removeNumberFromDND(username: string): Promise<any> {
    return ApiConnector.cloudRun('removeNumberFromDND', { username });
  }
  getTreeByType(type: ValueOf<typeof ApiClientConstant.Tree.Type>): Promise<any> {
    return ApiConnector.find(Table.Tree, { where: { type }, include: ['questions'], ascending: 'name' });
  }

  getUserAlternateNumber(id: any): Promise<any> {
    return ApiConnector.find(Table.User, { where: { objectId: id } });
  }

  getUserByNumber(number: any): Promise<any> {
    return ApiConnector.findOne(Table.User, { where: { $or: [{ MobileNumber: number }, { alternateNumber: number }] } });
  }

  getUserByAlternateNumber(alt: any): Promise<any> {
    return ApiConnector.find(Table.User, { where: { alternateNumber: alt } });
  }

  fetchCannedResponses(): Promise<any> {
    return ApiConnector.find(Table.CannedResponse,
      { where:
          { $or:
              [
                { user: this.getCurrentUser() },
                { user: { $exists: false } },
              ] } });
  }

  fetchAllArticles(): Promise<any> {
    return ApiConnector.find(Table.Question, { where: { mode: this.appConfig.Shared.Assistant.Mode.ARTICLE } });
  }

  verifyJobId(jobID: string): Promise<any> {
    return ApiConnector.findOne(Table.Question,
      {
        where: {
          mode: [this.appConfig.Shared.Assistant.Mode.IMAGE, this.appConfig.Shared.Assistant.Mode.ARTICLE],
          uniqueIdentifier: jobID,
        },
      });
  }

  fetchAllPrescription(userObjId: string, regimenObjId: string): Promise<any> {
    return ApiConnector.cloudRun('fetchPrescription',
      { id: userObjId, regimenId: regimenObjId },
      { context: { signedURL: true } });
  }

  getUserFiles(user: any): Promise<any> {
    return ApiConnector.find(Table.UserFiles, { where: { user } });
  }

  fetchAllActiveOperators(where: { [key: string]: unknown } = {}, project: Array<string> = undefined): Promise<any> {
    return ApiConnector.find(Table.User, { where: { type: 'operator', inactive: false, ...where }, project });
  }

  fetchAllRegimen(where: { [key: string]: unknown } = {}, project: Array<ParseKeys<Table.Regimen>> = undefined): Promise<any> {
    return ApiConnector.find(Table.Regimen, { where, project });
  }

  findInternalTeam(payload: RequestQueryPayload<Table.InternalTeam>): Promise<any> {
    return ApiConnector.find(Table.InternalTeam, payload);
  }

  findAllInternalUsers(active: boolean = true): Promise<any> {
    return ApiConnector.find(Table.User,
      {
        where: { type: { $in: ['operator', 'doctor'] }, inactive: !active },
        ascending: 'inactive',
      },
    );
  }

  getInternalUsers(): Promise<any> {
    return ApiConnector.find(Table.User,
      {
        where: {
          inactive: false,
          $or: [
            { type: ApiClientConstant.User.Type.DOCTOR, doctorAllocationFactor: { $exists: true } },
            { type: ApiClientConstant.User.Type.OPERATOR, operatorAllocationFactor: { $exists: true } },
          ],
        },
      });
  }

  findUserByObjectId(id: string): Promise<any> {
    return ApiConnector.findOne(Table.User, { where: { objectId: id } });
  }

  findPendingCallByObjectId(id: string): Promise<any> {
    return ApiConnector.findOne(Table.PendingCall, { where: { objectId: id } });
  }

  findNumberOfRequestedPendingCallForUser(user: any): Promise<any> {
    return ApiConnector.count(Table.PendingCall, { where: { user, type: 'UnpaidUserDoctorCall' } });
  }

  // fetchDoctorReviews(userObj: any): Promise<any> {
  //   return ApiConnector.find(Table.DoctorReviews,
  //     { where: { doctor: userObj }, include: ['treatedForLanguageString', 'reviewLanguageString'] });
  // }

  updateCannedResponse({ title, response, responseObj, mode, values }:
                         { title: string, response: any, responseObj: any, mode?: any, values?: any }): Promise<any> {
    responseObj.set('title', title);
    responseObj.set('response', response);
    responseObj.set('values', values);
    responseObj.set('mode', mode);
    return responseObj.save();
  }

  saveDoctorResponse({ title, response, values, mode }: { title: string, response: string, values?: any, mode?: any }): any {
    const responseObj = new Table.CannedResponse();
    responseObj.set('user', this.getCurrentUser());
    responseObj.set('title', title);
    responseObj.set('values', values);
    responseObj.set('response', response);
    responseObj.set('mode', mode);
    return responseObj.save();
  }

  getFAQCategories(): Promise<any> {
    return ApiConnector.find(Table.FAQCategories, { where: { } });
  }

  getFAQS(): Promise<any> {
    return ApiConnector.find(Table.FAQ, { where: { }, include: ['FAQId', 'questionLanguageString', 'answerLanguageString'] });
  }

  getLabels(): Promise<any> {
    return ApiConnector.find(Table.Labels, { where: { } });
  }

  saveFAQCategory({ title, titleLanguageString }: { title: string, titleLanguageString: any }): Promise<any> {
    const category = new Table.FAQCategories();
    category.set('title', title);
    category.set('titleLanguageString', titleLanguageString);
    return category.save();
  }

  saveQuestionInFAQ({ FAQId, question, answer, keywords, questionLanguageString, answerLanguageString }: any): any {
    const questionItem = new Table.FAQ();
    questionItem.set('FAQId', FAQId);
    questionItem.set('question', question);
    questionItem.set('answer', answer);
    questionItem.set('questionLanguageString', questionLanguageString);
    questionItem.set('answerLanguageString', answerLanguageString);
    questionItem.set('keywords', keywords);
    questionItem.save();
  }

  async deleteCannedResponse(cannedResponse: any): Promise<any> {
    const cannedResponseObj = await ApiConnector.find(Table.CannedResponse, { where: { objectId: cannedResponse.id } });
    const x = await cannedResponseObj[0].destroy();
  }

  getCalls({ where, limit, offset }
             : { where: any, limit: number, offset: number}): Promise<any> {
    return ApiConnector.cloudRun('getCalls', { where, limit, offset });
  }

  findAttendToChatCache(user: any): Promise<any> {
    return ApiConnector.findOne(Table.AttendToChatCache, { where: { user } });
  }

  updateBlockAssistantStage(id: Array<string>, action: string): Promise<any> {
    return ApiConnector.cloudRun('updateBlockAssistantStage', { id, action });
  }

  getInstantCheckUpWith(username: string, instantCheckUpId: string[], project: string[] = []): Promise<any> {
    if (instantCheckUpId && instantCheckUpId.length && instantCheckUpId.some((id: string) => !id)) {
      throw new Error(`Invalid Instant checkup Ids: ${JSON.stringify(instantCheckUpId)}`);
    }
    return ApiConnector.cloudRun('previousInstantCheckup', { userId: username, id: instantCheckUpId, project });
  }

  getInstantCheckupById(id: string): Promise<any> {
    return ApiConnector.findOne(Table.InstantCheckup, { where: { objectId: id } });
  }

  getUserByUserName(username: string): Promise<any> {
    return ApiConnector.findOne(Table.User, { where: { username }, include: ['allocatedDoctor'] });
  }

  async fetchDoctors(
    where: { [key: string]: unknown } = {},
    project: Array<string> = undefined,
    include: Array<string> = undefined): Promise<any> {
    return ApiConnector.find(Table.User, { where: { type: 'doctor', ...where }, project, include });
  }

  async validateTree(): Promise<any> {
    return ApiConnector.cloudRun('validateTree');
  }

  async fetchDietAndLifestyles(): Promise<any> {
    return ApiConnector.find(Table.Question, { where: { isDietArticle: true }, include: ['titleLanguageString'] });
  }

  async fetchLanguageStringTags({ tags, q, limit, excludeIds }
    : { tags?: Array<string>, q?: string, limit?: number, excludeIds?: Array<string> } = {}): Promise<any> {
    const payload: RequestQueryPayload<Table.LanguageStringTag> = { where: { } };
    if (tags) {
      if (!tags.length) return [];
      payload.where.name = { $in: tags };
    }
    if (q) payload.where.name = { $regex: q, $options: 'i' };
    if (limit) payload.limit = limit;
    if (excludeIds) payload.where.objectId = { $nin: excludeIds };
    payload.descending = 'createdAt';
    return ApiConnector.find(Table.LanguageStringTag, payload);
  }

  // async fetchCatalogTags({ tags, q, limit, excludeIds }
  //                          : { tags?: Array<string>, q?: RegExp, limit?: number, excludeIds?: Array<string> } = {}): Promise<any> {
  //   const payload: RequestQueryPayload<Table.CatalogTag> = { where: { } };
  //   if (tags) {
  //     if (!tags.length) return [];
  //     payload.where.name = tags;
  //   }
  //   if (q) payload.where.name = { $regex: q, option: 'i' };
  //   if (limit) payload.limit = limit;
  //   if (excludeIds) payload.where.objectId = { $nin: excludeIds };
  //   return ApiConnector.find(Table.CatalogTag, payload);
  // }

  async getAllTreesHaving(regimen: any): Promise<any> {
    const trees = await ApiConnector.find(Table.Tree, { where: { regimens: regimen }, ascending: 'name' });
    return Promise.resolve(trees);
  }

  async fetchAllMainConcernsForUser(username: string): Promise<any> {
    const allConsultationSessions = await ApiConnector.find(Table.ConsultationSession, {
      where: { username },
      project: ['PrivateMainConcern', 'class', 'concernList', 'PrivateMainConcernClass'],
      descending: 'updatedAt',
    });
    const seen = {};
    allConsultationSessions.forEach((consultationSession: any) => {
      const mainConcern = consultationSession.get('PrivateMainConcern');
      if (mainConcern && !seen[mainConcern]) {
        seen[mainConcern] = consultationSession;
      }
    });
    return Object.values(seen);
  }

  async updateConsultationSession(consultaionSession: any, selectedConcern: any): Promise<any> {
    consultaionSession.set('PrivateMainConcern', selectedConcern.get('value'));
    consultaionSession.set('PrivateMainConcernClass', selectedConcern.get('class'));
    await consultaionSession.save({}, { sessionToken: this.getCurrentUser().getSessionToken() });
  }

  async sendOrCreatePersonalizedRegimen(regimenId: string, username: string, context?: any): Promise<any> {
    return ApiConnector.cloudRun('findOrCreateAndSendPersonalizedCopy', { regimenId, username, context });
  }

  async sendQuestionTo(questionId: string, userId: string, context?: any): Promise<any> {
    return ApiConnector.cloudRun('executeQuestion', { questionId, userId, context });
  }

  async updateOnCallMember(objectId: string): Promise<any> {
    return ApiConnector.cloudRun('updateOnCallMember', { objectId });
  }

  async assignNewAWB(orderId: string): Promise<any> {
    return ApiConnector.cloudRun('assignNewAWB', { orderId });
  }

  async sendEMIMessageToUser(orderId: string): Promise<any> {
    return ApiConnector.cloudRun('sendOrderEMIMessage', { orderId });
  }

  async changeAllocation(userId: string, internalUserId: string): Promise<any> {
    return ApiConnector.cloudRun('changeAllocation', { userId, internalUserId });
  }

  async fetchObject(item: any, fieldCheck: string): Promise<any> {
    if (!item) return item;
    if (!item.get(fieldCheck)) {
      try {
        await item.fetch();
      } catch (error) {
        return undefined;
      }
    }
    return item;
  }

  async applyCoupon(order: any, couponCode: string): Promise<any> {
    const orderId = order?.id || order?.objectId;
    return ApiConnector.cloudRun('applyCoupon', { couponCode, orderId });
  }

  async fetchCashBalance(username?: any): Promise<any> {
    return ApiConnector.cloudRun('fetchCashBalance', { username });
  }

  async getReferredByUsernameForUserId(userObjectId:string): Promise<any> {
    if (!userObjectId) {
      throw Error('userObjectId is not provided');
    }
    return ApiConnector.cloudRun('getReferredByUsernameForUserId', { userObjectId });
  }

  async getCoupons(payload: RequestQueryPayload<Table.Coupon>): Promise<any> {
    return Promise.all([ApiConnector.find(Table.Coupon, payload), ApiConnector.count(Table.Coupon, payload)]);
  }

  async pullUserDataFromDifferentAccount(primaryNumber: string, secondaryNumber: string): Promise<any> {
    return ApiConnector.cloudRun('pullUserDataFromDifferentAccount', { primaryNumber, secondaryNumber });
  }

  async swapPrimaryNumbers(number1: string, number2: string): Promise<any> {
    return ApiConnector.cloudRun('swapPrimaryNumbers', { number1, number2 });
  }

  async getSignedMetabaseLink(params: { [key: string]: string | Date }): Promise<any> {
    return ApiConnector.cloudRun('getMetaBaseSignedURL', params);
  }

  async fetchEmbeddedLinks({ parentNode }: { parentNode?: any } = {}): Promise<Array<any>> {
    const payload: RequestQueryPayload<Table.EmbeddedLink> = { where: { } };
    if (parentNode) {
      payload.where.parentNode = parentNode;
    } else {
      payload.where.parentNode = { $exists: false };
    }
    return ApiConnector.find(Table.EmbeddedLink, payload);
  }

  async findEmbeddedLinkById(id: string): Promise<Table.EmbeddedLink> {
    return ApiConnector.findOne(Table.EmbeddedLink, { where: { objectId: id } });
  }

  async fetchTips(): Promise<Array<any>> {
    const languageStringTags: any[] = await this.getLanguageTagByName(['tips', 'followup-report']);
    const payload: RequestQueryPayload<Table.LanguageString> = { where: { } };
    languageStringTags.forEach((each: any) => {
      payload.where.$and.push({ tags: languageStringTags[0] });
    });
    return ApiConnector.find(Table.LanguageString, payload);
  }

  async fetchInvestigations(): Promise<Array<Table.Investigations>> {
    return ApiConnector.find(Table.Investigations, { where: { } });
  }

  findAssignedChats(payload_: RequestQueryPayload<Table.AssignedChat>): Promise<Array<any>> {
    const payload = payload_;
    if (!payload.ascending) {
      payload.descending = payload.descending || 'createdAt';
    }
    return ApiConnector.find(Table.AssignedChat, payload);
  }

  fetchAssignedChats({ user, status, includes }: { user: any,
    status?: ValueOf<typeof ApiClientConstant.AssignedChat.Status>,
    includes?: Array<string> }): Promise<Array<any>> {
    const payload: RequestQueryPayload<Table.AssignedChat> = { where: { user }, include: [] };
    if (status) {
      payload.where.status = status;
    }
    if (includes && includes.length) {
      includes.forEach((each: ParseKeys<Table.AssignedChat>) => payload.include.push(each));
    }
    payload.descending = 'createdAt';
    return ApiConnector.find(Table.AssignedChat, payload);
  }

  countFollowUps(payload: RequestCountPayload<Table.FollowUp>): Promise<number> {
    return ApiConnector.count(Table.FollowUp, payload);
  }

  async findFollowUps(payload: RequestQueryPayload<Table.FollowUp>): Promise<Array<any>> {
    return ApiConnector.find(Table.FollowUp, payload);
  }

  findFollowUp(payload: RequestQueryPayload<Table.FollowUp>): Promise<Table.FollowUp> {
    return ApiConnector.findOne(Table.FollowUp, payload);
  }

  findOneFollowUp(payload: RequestQueryPayload<Table.FollowUp>): any {
    return ApiConnector.findOne(Table.FollowUp, payload);
  }

  findOrders(payload: RequestQueryPayload<Table.Order>): Promise<Array<any>> {
    return ApiConnector.find(Table.Order, payload);
  }

  findOneOrder(payload: RequestQueryPayload<Table.Order>): any {
    return ApiConnector.findOne(Table.Order, payload);
  }

  findCart(payload: RequestQueryPayload<Table.Cart>): Promise<Table.Cart> {
    return ApiConnector.findOne(Table.Cart, payload);
  }

  findRefunds(payload: RequestQueryPayload<Table.Refund>): Promise<Array<any>> {
    return ApiConnector.find(Table.Refund, payload);
  }

  countOrders(payload: RequestCountPayload<Table.Order>): Promise<number> {
    return ApiConnector.count(Table.Order, payload);
  }

  countRefunds(payload: RequestCountPayload<Table.Refund>): Promise<number> {
    return ApiConnector.count(Table.Refund, payload);
  }

  findLanguageVoices(payload: RequestQueryPayload<Table.LanguageVoice>): Promise<Array<any>> {
    return ApiConnector.find(Table.LanguageVoice, payload);
  }

  countLanguageVoices(payload: RequestCountPayload<Table.LanguageVoice>): Promise<number> {
    return ApiConnector.count(Table.LanguageVoice, payload);
  }

  findLanguageStrings(payload: RequestQueryPayload<Table.LanguageString>): Promise<Array<any>> {
    return ApiConnector.find(Table.LanguageString, payload);
  }

  countLanguageStrings(payload: RequestCountPayload<Table.LanguageString>): Promise<number> {
    return ApiConnector.count(Table.LanguageString, payload);
  }

  findLanguageStringTags(payload: RequestQueryPayload<Table.LanguageStringTag>): Promise<Array<any>> {
    return ApiConnector.find(Table.LanguageStringTag, payload);
  }

  countLanguageStringTags(payload: RequestCountPayload<Table.LanguageStringTag>): Promise<number> {
    return ApiConnector.count(Table.LanguageStringTag, payload);
  }

  findQuestions(requestPayload: RequestQueryPayload<Table.Question>): Promise<Array<any>> {
    return ApiConnector.find(Table.Question, requestPayload);
  }

  countQuestions(requestPayload: RequestCountPayload<Table.Question>): Promise<number> {
    return ApiConnector.count(Table.Question, requestPayload);
  }

  findCannedResponses(payload: RequestQueryPayload<Table.CannedResponse>): Promise<Array<any>> {
    return ApiConnector.find(Table.CannedResponse, payload);
  }

  countCannedResponses(payload: RequestCountPayload<Table.CannedResponse>): Promise<number> {
    return ApiConnector.count(Table.CannedResponse, payload);
  }

  getCannedResponse(id: string, include: Array<string>): Promise<any> {
    const payload: RequestQueryPayload<Table.CannedResponse> = { where: { objectId: id }, include: [] };
    if (include) include.forEach((key: ParseKeys<Table.CannedResponse>) => payload.include.push(key));
    return ApiConnector.findOne(Table.CannedResponse, payload);
  }

  findMarketProducts(payload: RequestQueryPayload<Table.MarketProduct>): Promise<Array<any>> {
    return ApiConnector.find(Table.MarketProduct, payload);
  }

  countMarketProducts(payload: RequestCountPayload<Table.MarketProduct>): Promise<number> {
    return ApiConnector.count(Table.MarketProduct, payload);
  }

  getMarketProduct(id: string): Promise<any> {
    return ApiConnector.findOne(Table.MarketProduct, { where: { objectId: id } });
  }
  findFollowUpReports(payload: RequestCountPayload<Table.FollowUpReport>): Promise<any[]> {
    return ApiConnector.find(Table.FollowUpReport, payload);
  }

  findReports(payload: RequestQueryPayload<Table.FollowUpReport>): Promise<Array<any>> {
    return ApiConnector.find(Table.FollowUpReport, payload);
  }

  findExperiment(payload: RequestQueryPayload<Table.Experiment>): Promise<any> {
    return ApiConnector.findOne(Table.Experiment, payload);
  }

  async fetchTipsByRegimenDisplayConcerns(regimenClass: string): Promise<any> {
    return ApiConnector.find(Table.Tips, { where: { classes: regimenClass } });
  }

  async fetchMainConcerns(payload: RequestQueryPayload<Table.MainConcern>): Promise<any> {
    return ApiConnector.find(Table.MainConcern, payload);
  }

  async findMainConcernsById(id: any): Promise<any> {
    return ApiConnector.findOne(Table.MainConcern, {
      where: { objectId: id },
      include: ['displayNameLanguageString', 'keywords.keyLanguageString' as 'keywords', 'descriptionLanguageString'],
    });
  }

  findVoiceIdentifier(param: { regimenId: string }): Promise<{ voiceIdentifier: string }> {
    return ApiConnector.cloudRun('findVoiceIdentifier', param);
  }

  async fetchTriggerByKeyword(): Promise<any> {
    return ApiConnector.find(Table.KeywordTriggerTree, { where: { } });
  }

  async findKeywordTriggerTreeById(objectId: string): Promise<any> {
    return ApiConnector.findOne(Table.KeywordTriggerTree, { where: { objectId } });
  }

  async getAiResponse(file: any, fileType: string): Promise<any> {
    const formData = new FormData();
    formData.append('type', fileType);
    formData.append('file', file);
    return this.http.post('https://analyze.cureskin.com/uploadImage', formData).toPromise();
  }

  async getNumberOfApprovedLeavesForUserDuringLastYear(username: string): Promise<number> {
    const user = await this.getUserByUserName(username);
    let numberOfPublicLeavesBetweenUserLeave = 0;
    const todayDate = new Date();
    let userCreatedDate: Date = moment(user.get('serviceStartDate') || user.get('createdAt')).set('year', todayDate.getFullYear()).toDate();
    if (moment(userCreatedDate).isAfter(todayDate)) {
      userCreatedDate = moment(userCreatedDate).subtract(1, 'years').toDate();
    }
    const approvedLeaves = await ApiConnector.find(Table.LeaveAndHoliday,
      {
        where: {
          user,
          status: 'APPROVED',
          mode: { $in: [this.appConfig.Shared.LeaveAndHoliday.MODE.LEAVE, undefined] },
          startTime: { $gt: userCreatedDate },
        },
      });
    let numberOfApprovedLeaves = 0;
    let numberOfSundaysBetweenLeaves = 0;
    let publicLeavesCount = [];
    approvedLeaves.forEach((leave: any) => {
      numberOfApprovedLeaves += Math.ceil((leave.get('endTime') - leave.get('startTime')) / (1000 * 60 * 60 * 24));
      let startDate = leave.get('startTime');

      while (startDate < leave.get('endTime')) {
        const dayIndex = startDate.getDay();
        numberOfSundaysBetweenLeaves += (dayIndex === 0) ? 1 : 0;
        startDate = moment(startDate).add(1, 'days').toDate();
      }
      publicLeavesCount.push(ApiConnector.count(Table.LeaveAndHoliday,
        { where: { user: { $exists: false },
          startTime: { $gte: moment(leave.get('startTime')).toDate() },
          endTime: { $lte: moment(leave.get('endTime')).toDate() } } }));
    });
    publicLeavesCount = await Promise.all(publicLeavesCount);
    numberOfPublicLeavesBetweenUserLeave = publicLeavesCount.reduce((total: number, count: number) => total + count, 0);
    const leavesExcludingSundaysAndPublicLeaves = numberOfApprovedLeaves
      - numberOfPublicLeavesBetweenUserLeave
      - numberOfSundaysBetweenLeaves;

    return leavesExcludingSundaysAndPublicLeaves;
  }

  async getAllValidLeavesBetweenUserLeaves(startDate: Date, endDate: Date): Promise<any> {
    const publicLeaves = await ApiConnector.find(Table.LeaveAndHoliday,
      {
        where: {
          user: undefined,
          status: 'APPROVED',
          $or: [
            {
              $and: [
                { startTime: { $gte: startDate } },
                { startTime: { $lte: endDate } },
              ],
            },
            {
              $and: [
                { startTime: { $lte: startDate } },
                { endTime: { $gte: endDate } },
              ],
            },
            {
              $and: [
                { startTime: { $lte: startDate } },
                { endTime: { $gte: startDate } },
              ],
            },
          ],
        },
      });
    let totalAppliedLeaves = 0;
    let holidaysBetweenTwoDays = 0;
    const day = new Date(startDate);
    while (day <= endDate) {
      totalAppliedLeaves += 1;
      if (this.checkWhetherDayIsHolidayOrNot(day, publicLeaves) || day.getDay() === 0) {
        holidaysBetweenTwoDays += 1;
      }
      day.setDate(day.getDate() + 1);
    }
    return totalAppliedLeaves - holidaysBetweenTwoDays;
  }

  checkWhetherDayIsHolidayOrNot(day: Date, publicLeaves: any): boolean {
    const dayOfHoliday = publicLeaves.find((each: any) => (moment(day).isSameOrAfter(each.get('startTime'))
      && moment(day).isSameOrBefore(each.get('endTime'))));
    if (dayOfHoliday) {
      return true;
    }
    return false;
  }

  async getAllUsers(payload: RequestCountPayload<Table.User>): Promise<any> {
    return ApiConnector.find(Table.User, payload);
  }

  fetchSupportQuestions(payload: RequestQueryPayload<Table.SupportQuestion>): Promise<Array<any>> {
    return ApiConnector.find(Table.SupportQuestion, payload);
  }

  fetchQuizQuestions(payload: RequestQueryPayload<Table.QuizQuestion>): Promise<Array<any>> {
    return ApiConnector.find(Table.QuizQuestion, payload);
  }

  getCountOfQuizQuestions(payload: RequestCountPayload<Table.QuizQuestion>): Promise<number> {
    return ApiConnector.count(Table.QuizQuestion, payload);
  }

  fetchSupportCategories(payload: RequestQueryPayload<Table.SupportCategory>): Promise<Array<any>> {
    return ApiConnector.find(Table.SupportCategory, payload);
  }

  fetchQueryChatHistory(payload: RequestQueryPayload<Table.SupportChat>): Promise<Array<any>> {
    return ApiConnector.find(Table.SupportChat, payload);
  }

  fetchSupportTicket(payload: RequestQueryPayload<Table.SupportTicket>): Promise<Table.SupportTicket> {
    return ApiConnector.findOne(Table.SupportTicket, payload);
  }

  fetchSupportTickets(payload: RequestQueryPayload<Table.SupportTicket>): Promise<Array<any>> {
    return ApiConnector.find(Table.SupportTicket, payload);
  }

  fetchSupportTicketCount(payload: RequestQueryPayload<Table.SupportTicket>): Promise<number> {
    return ApiConnector.count(Table.SupportTicket, payload);
  }

  findAddressBook(payload: RequestQueryPayload<Table.AddressBook>): Promise<Array<any>> {
    return ApiConnector.find(Table.AddressBook, payload);
  }

  fetchReminderList(payload: RequestQueryPayload<Table.Reminder>): Promise<Array<any>> {
    return ApiConnector.find(Table.Reminder, payload);
  }

  async fetchConsultationSessions(payload: RequestQueryPayload<Table.ConsultationSession>): Promise<Array<any>> {
    return ApiConnector.find(Table.ConsultationSession, payload);
  }

  getSupportQuestions(payload: RequestQueryPayload<Table.SupportQuestion>): Promise<Array<any>> {
    return ApiConnector.find(Table.SupportQuestion, payload);
  }

  async getAllConsultationChatImage(user: any): Promise<Array<string>> {
    const instantCheckupQuestionIds = ['HairfallPhoto', 'HairfallPhotoTop', 'TakeInstantCheckup', 'TakeInstantCheckSideFace', 'BodyPhoto'];
    const payload: RequestQueryPayload<Table.ConsultationSession> = { where: { user }, project: instantCheckupQuestionIds };
    const consultationSessions = await ApiConnector.find(Table.ConsultationSession, payload);
    const consultationImages = [];
    consultationSessions.forEach((consultationSession: any) => {
      instantCheckupQuestionIds.forEach((instantCheckupQuestionId: string) => {
        if (consultationSession.has(instantCheckupQuestionId)) {
          consultationImages.push(consultationSession.get(instantCheckupQuestionId));
        }
      });
    });
    return consultationImages;
  }

  async deleteOrderMedicalRecord(order: any): Promise<void> {
    const orderMedicalRecord = await ApiConnector.findOne(Table.OrderMedicalRecord, { where: { order } });
    if (!orderMedicalRecord) {
      return;
    }
    await orderMedicalRecord.destroy();
  }

  async markRegimenOrderSkipDrApproval(orderId: string, note: string): Promise<any> {
    return ApiConnector.cloudRun('markRegimenOrderSkipDrApproval', { orderId, note });
  }

  async getAllOnlineDoctors(filter: Array<Record<string, unknown>> = []): Promise<Array<{ username: string; id: string; }>> {
    return ApiConnector.cloudRun('getOnlineUser', { filter });
  }

  async findTotalRespondedTickets(startDate: Date, endDate: Date, username: string): Promise<any> {
    return ApiConnector.cloudRun('getTotalRespondedTickets', { startDate, endDate, username });
  }

  async findRoster(user: any): Promise<any> {
    return ApiConnector.findOne(Table.Roster, { where: { user } });
  }

  async createQueueEvent(userId: string, type: string, eventTime: Date, data: Record<string, string>, uniqueId: string): Promise<any> {
    return ApiConnector.cloudRun('createQueueEvent', { userId, type, eventTime, data, uniqueId });
  }

  async findUserActiveExperiments(username?: string): Promise<any> {
    return ApiConnector.cloudRun('findUserActiveExperiments', { username });
  }

  async findCoupons(payload: RequestQueryPayload<Table.Coupon>): Promise<any> {
    return ApiConnector.find(Table.Coupon, payload);
  }

  async countCoupons(payload: RequestQueryPayload<Table.Coupon>): Promise<any> {
    return ApiConnector.count(Table.Coupon, payload);
  }

  createNPSFeedback(params: any): Promise<any> {
    return ApiConnector.cloudRun('createNPSFeedback', params);
  }

  async findSbarHistory(payload: RequestQueryPayload<Table.SBARHistory>): Promise<any> {
    return ApiConnector.find(Table.SBARHistory, payload);
  }

  async findOneRegimen(payload: RequestQueryPayload<Table.Regimen>): Promise<any> {
    return ApiConnector.findOne(Table.Regimen, payload);
  }

  async getDoctorsOnLeaveOnDate(date: Date): Promise<Array<any>> {
    const leaveAndHolidays = await ApiConnector.find(Table.LeaveAndHoliday, {
      where: {
        status: ApiClientConstant.LeaveAndHoliday.STATUS.APPROVED,
        effectiveStartTime: { $gte: date },
        endTime: { $lte: date },
        user: { $exists: true },
      },
      include: ['user'],
      project: ['user.type' as 'user'],
    });
    return leaveAndHolidays
      .map((leaveAndHoliday: any) => leaveAndHoliday.get('user').get('type') === ApiClientConstant.User.Type.DOCTOR);
  }

  changeRegimenVariant(regimenId: string, variantId: string): Promise<any> {
    return ApiConnector.cloudRun('changeRegimenVariant', { regimenId, variantId });
  }

  updateRegimenPrice(payload: any): Promise<any> {
    return ApiConnector.cloudRun('updateRegimenPriceAndAddExtraProducts', payload);
  }

  trackEventInCleverTap(eventName: string, username: string, eventData: any = {}): Promise<any> {
    return ApiConnector.cloudRun('trackEventInCleverTap', { eventName, eventData, username });
  }

  async findMediaLink(payload: RequestQueryPayload<Table.MediaLink>): Promise<any> {
    return ApiConnector.find(Table.MediaLink, payload);
  }

  async findOrderMedicalRecord(payload: RequestQueryPayload<Table.OrderMedicalRecord>): Promise<any> {
    return ApiConnector.find(Table.OrderMedicalRecord, payload);
  }

  async findProducts(payload: RequestQueryPayload<Table.Catalog>): Promise<any> {
    return ApiConnector.find(Table.Catalog, payload);
  }
  async findOrderMedicalRecordForOrder(order: any): Promise<any> {
    return ApiConnector.findOne(Table.OrderMedicalRecord, {
      where: { order, sopPhotoClear: { $exists: true } },
    });
  }

  findOneMargIdInfo(payload: RequestQueryPayload<Table.MargIdInfo>): Promise<any> {
    return ApiConnector.findOne(Table.MargIdInfo, payload);
  }
  getPersonalizedVoiceNoteEligibility(username: string, regimenId: string): Promise<any> {
    return ApiConnector.findOne(Table.ConsultationSession, {
      where: {
        username,
        PrivateRegimenGiven: regimenId,
        PrivateMainConcernClass: this.appConfig.Shared.ConsultationSession.PrivateMainConcernClass.HAIR,
        $or: [
          {
            differentStagesOfHairLoss: { $exists: true },
          },
          {
            stagesOfHairFallFemale: { $exists: true },
          },
        ],
      },
      project: [
        'differentStagesOfHairLoss',
        'stagesOfHairFallFemale',
      ],
    });
  }

  async findRegimenHistory(payload: RequestQueryPayload<Table.RegimenHistory>): Promise<Array<any>> {
    return ApiConnector.find(Table.RegimenHistory, payload);
  }

  async findSupportTicket(payload: RequestQueryPayload<Table.SupportTicket>): Promise<Array<any>> {
    return ApiConnector.find(Table.SupportTicket, payload);
  }

  async findOneInstantCheckUp(payload: RequestQueryPayload<Table.InstantCheckup>): Promise<Table.InstantCheckup> {
    return ApiConnector.findOne(Table.InstantCheckup, payload);
  }

  loginThroughMail(token: string): any {
    return ApiConnector.cloudRun('loginThroughMail', { token });
  }

  async findAudioTemplateByName(name: string, inLanguage: string): Promise<any> {
    return ApiConnector.findOne(Table.AudioTemplate, {
      where: { name },
      include: ['urlLanguageString'],
      option: { context: { translate: true, inLanguage } },
    });
  }

  async getSoftPhoneAuthTokenServerAPI(): Promise<string> {
    const sessionToken = this.getCurrentUser().getSessionToken();

    if (!sessionToken) {
      return '';
    }

    const response: ServerAPIResponse['ExotelAuthToken'] = await this.serverApi.getExotelAuthToken(sessionToken);
    return response.data?.result;
  }

  async trackOutboundCallLogServerAPI(exotelLogRequest: ServerAPIRequest['ExotelLogRequest']): Promise<void> {
    const sessionToken = this.getCurrentUser().getSessionToken();

    if (!sessionToken) {
      return;
    }

    await this.serverApi.trackExotelCallLog(exotelLogRequest, sessionToken);
  }

  async updateSoftphoneDeviceStatus(status: boolean): Promise<boolean> {
    const sessionToken = this.getCurrentUser().getSessionToken();

    if (!sessionToken) {
      return false;
    }

    try {
      await this.serverApi.updateSoftphoneUserDeviceStatus({
        deviceStatus: status,
      }, sessionToken);
      return true;
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : 'Something went wrong while updating the softphone device status';
      this.broadcastService.broadcast('NOTIFY',
        {
          message: errorMessage,
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
      return false;
    }
  }

  async getUserActivityData(
    userId: string,
    startDate: string,
    endDate: string,
  ): Promise<ServerAPIResponse['MonitoringData']> {
    const userActivityData = await this.serverApi
      .getData(
        userId,
        startDate,
        endDate,
        this.getCurrentUser().getSessionToken(),
      );
    return userActivityData;
  }

  getPinCodeInfo(pinCode: number, context: Record<string, string> = {}): Promise<any> {
    return ApiConnector.findOne(Table.PinCodes, {
      where: { pinCode },
      option: { context } as Parse.FullOptions,
    });
  }
}
