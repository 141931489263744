import { Component, Input } from '@angular/core';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { HelperService } from '../../services/helper-service';

@Component({ selector: 'user-on-antibiotic', templateUrl: './user-on-antibiotic.html' })
export class UserOnAntibioticComponent {
  userObj: any;
  showAttentionDoctor: boolean = false;
  regimens: Array<any> = [];
  isOnAntibioticOrTab: boolean = false;

  static parameters: any = [AppConfig, ConnectionService, HelperService];
  constructor(public appConfig: AppConfig, private conn: ConnectionService, public helper: HelperService) {
  }

  @Input()
  set user(userObj: any) {
    this.userObj = userObj;
    if (!this.regimens.length) {
      this.conn.findRegimens({
        where: { forUser: this.userObj, active: true },
        include: ['products'],
        project: ['products.isTablet' as 'products', 'products.isAntibiotic' as 'products'],
      }).then((data: any) => {
        this.regimens = data;
        this.isOnAntibioticOrTab = this.helper.isAntibioticRegimen(this.regimens);
      });
    }
  }

  @Input()
  set showText(show: boolean) {
    this.showAttentionDoctor = show;
  }
}
