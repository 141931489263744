import { Component, Input } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';
import { LocalStorage } from '../../../../services/local-storage-service';

@Component({ selector: 'lost-in-transit-modal', templateUrl: './lost-in-transit.html' })
export class LostInTransitModal {
  @Input('order')
  set setOrder(x: any) {
    this.updateOrder(x);
  }
  order: any;
  isLostInTransitOrder: boolean;
  disableEdit: boolean;
  isLossAlreadyClaimed: boolean;

  constructor(private localStorage: LocalStorage,
    private windowRefService: WindowRefService,
    private appConfig: AppConfig) {
  }

  async markLossClaimed(): Promise<any> {
    await this.order.save({ lostInTransitSettled: true });
    this.updateOrder(this.order);
  }

  private updateOrder(order: any): void {
    this.disableEdit = !this.localStorage.getJsonValue('userRoles').includes(ApiClientConstant.Role.Name.SHIPPING);
    this.order = order;
    this.isLossAlreadyClaimed = !!this.order.get('lostInTransitSettled');
    this.isLostInTransitOrder = this.order.get('stage') === ApiClientConstant.Order.Stage.LOST_IN_TRANSIT;
  }
}
