import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuizGameComponent } from './quiz-game.component';
import { EditModule } from './edit';
import { ListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'quiz-questions',
    component: QuizGameComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'quiz-questions/new',
    component: QuizGameComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: 'quiz-questions/:id/edit',
    component: QuizGameComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
];

@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [QuizGameComponent],
exports: [QuizGameComponent] })
export class QuizGameModule {}
