<div class="order-info-container">
  <div class="flex space-between">
    <div>
    <div class="flex">
      <order-info-header class="child" [orderObj]="orderObj"></order-info-header>
      <order-info-tags class="child" [orderObj]="orderObj" [parseOrderObj]="parseOrderObj"></order-info-tags>
    </div>
    <hr>
    <order-info-user-data [orderObj]="orderObj" [user]="user" [parseOrderObj]="parseOrderObj"></order-info-user-data>
    </div>
    <order-info-invoice [orderObj]="orderObj" [parseOrderObj]="parseOrderObj" [user]="user"></order-info-invoice>
  </div>
</div>