import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CatalogInstructionViewComponent } from './catalog-instruction-view.component';
import { LanguageViewModule } from '../../../../../../components/language-string/language-view';
import { DirectivesModule } from '../../../../../../components/directives.module';

@NgModule({
  imports: [CommonModule, FormsModule, LanguageViewModule, DirectivesModule],
  declarations: [CatalogInstructionViewComponent],
  exports: [CatalogInstructionViewComponent],
})
export class CatalogInstructionViewModule {
}
