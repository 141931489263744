import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConnectionService } from '../../../services/connection-service/index';

@Injectable()
export class AlternateRegimenResolver {
  constructor(private conn: ConnectionService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.conn
      .getRegimen(route.params.regimenId, true)
      .then((regimen: any): any => {
        if (regimen) return Promise.resolve(regimen);
        this.router.navigate([`/products/regimen/${route.params.id}`]);
        return false;
      });
  }
}
