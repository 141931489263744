import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'regimen-changes-view',
  templateUrl: './regimen-logs-view.html',
})
export class RegimenLogsViewModal {
  product: any;
  partOfDay: string;
  actionLog: any;
  instructions: Array<any>;
  selectedInstructionCreationId: string;

  constructor(private injector: Injector,
    private dialogRef: MatDialogRef<RegimenLogsViewModal>) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.actionLog = data.actionLog;
  }

  async ngOnInit(): Promise<void> {
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
