import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConnectionService } from '../../../services/connection-service';

@Injectable()
export class CatalogResolve {
  constructor(private conn: ConnectionService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.conn.getCatalog(route.params.id)
      .then((catalogueObj: any): any => {
        if (catalogueObj) return Promise.resolve(catalogueObj);
        this.router.navigate(['/products/catalogs']);
        return false;
      });
  }
}
