
<div class="col-xs-12 p-0 bg-white h100 flex of-scr alignFS">
  <div class="col-xs-12 pt-4 pb-4 of-scr" *ngIf="followupChat?.length" style="overflow-x: hidden; padding-left: 34px; padding-right: 40px;">
    <div class="col-xs-12 flex">
      <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">Primary Doctor :</div>
      <div class="col-xs-7"><span class="pr-2">Dr. {{user.get('allocatedDoctor')?.get('username')?.split('_')[0]}}</span></div>
    </div>
    <div class="col-xs-12 flex">
      <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">Active Regimens :</div>
      <div class="col-xs-7"><span class="pr-2">{{activeRegimens.join(', ').toLowerCase()}}</span></div>
    </div>
    <div class="col-xs-12 p-0 flex" *ngFor="let row of followupChat;let i = index;">
      <div class="col-xs-12 flex" *ngIf="row.value" [ngClass]="{ 'clr-red': row.highlight }">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700" *ngIf="row.key !== 'markedReadyOn'">{{displayFollowUpQuestions[row.key.toUpperCase()] || row.key}} :</div>
        <div class="col-xs-7"><span class="pr-2" *ngIf="row.key !== 'markedReadyOn'">{{row.value}}</span><span class="pr-2" *ngIf="row.date && row.value">{{row.date | amDateFormat: 'MMM DD, YYYY hh:mm a'}}</span></div>
      </div>
    </div>
    <div class="col-xs-12 p-0 flex">
      <span class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700" *ngIf="selectedFollowUpClass">
        Active Treatement:
      </span>
      <span class="pr-2">{{selectedFollowUpClass === 'FACE' ? activeFaceTree : activeHairTree}}</span>
    </div>
    <div class="col-xs-12 p-0 flex fontR" *ngFor="let row of followUpReports;let i = index;">
      <div class="col-xs-12 flex mt-2">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">{{row.key}} :</div>
        <div class="col-xs-7">
          <button class="btn-primary-outline"><a href="/user/follow-up-report/{{row.value}}" target="_blank">Open Report</a></button><a class="fas fa-edit ml-3" *ngIf="row.editable" [href]="row.editLink" target="_blank"></a>
        </div>
      </div>
    </div>
    <div class="col-xs-12 mt-2">
      <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">FollowUp history :</div>
      <div class="col-xs-7">
        <button class="btn-primary-outline" (click)="openFollowUpHistory(appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP)">Open History</button>
      </div>
    </div>
    <div class="col-xs-12 mt-2" style="margin-bottom: 40px;">
      <mat-expansion-panel *ngIf="hasTestHistory()" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title> Investigations highlight </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex" style="flex-direction: column-reverse;">
          @for (item of investigationHistoryTimeline; let i = $index; track item.id) {
            <div *ngIf="item.testsStatus.length" class="col-xs-12 flex bdr mb-1 mt-1 p-4" style="flex-wrap: wrap;">
              <div class="flex flex-dir-col alignFS">
                <div>
                  <span class="pr-2">{{item.followUpCount}} Followup, {{item.date | amDateFormat: 'MMM DD, YYYY'}}</span>
                </div>
                <div>
                  <ul *ngFor="let test of item.testsStatus; let testIndex = index;" style="list-style-type: none; padding: 5px;">
                    <li class="flex alignC gap-12" style="height: 24px;">
                      <span>{{ test.name }} : </span>
                      <span>
                        <input type="checkbox" [checked]="test.status" (change)="toggleTestStatus(testIndex, i)" name="investigation_{{i}}"/><i></i>
                      </span>
                      <span *ngIf="!test.status" (click)="deleteTest(testIndex, i)" class="fa fa-trash-o"></span>
                    </li>
                  </ul>
                </div>
              </div>
                <div *ngIf="item.testsStatus.length">
                  <div>
                    <span> Investigation Feedback </span>
                    <span> 
                      <textarea class="form-control" style="width: 220px; min-width: 120px;" [(ngModel)]="item.feedback" name="investigation_{{i}}_feedback" rows="3"></textarea>
                    </span>
                  </div>
                </div>
            </div>
          }
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>
