import { Output, EventEmitter, Input, ElementRef, Directive } from '@angular/core';
import { WindowRefService } from 'src/services/window-ref-service';
import { InstantCheckupInterface, ImageConfig } from '../checkup';

@Directive({
  selector: '[appAlignFace]',
})
export class AlignFaceDirective {
  @Input() side: string;
  @Input() set appAlignFace(checkup: InstantCheckupInterface) {
    if (!checkup) return;
    // Draw image
    this.draw(checkup);
    // if (this.side === 'left') this.drawLeft(checkup);
    // else this.drawRight(checkup);
  }
  @Output() imageConfig: EventEmitter<ImageConfig> = new EventEmitter<ImageConfig>();

  canvas: HTMLCanvasElement;
  canvasContext: CanvasRenderingContext2D;

  constructor(
    elementRef: ElementRef<HTMLCanvasElement>,
    private window: WindowRefService,
  ) {
    this.canvas = elementRef.nativeElement;
    this.canvas.height = 360;
    this.canvas.width = 360;
    this.canvasContext = this.canvas.getContext('2d');
  }

  drawRight(checkup: InstantCheckupInterface): void {
    let eyeCenter = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.MIDPOINT_BETWEEN_EYES;
    let mouthCenter = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.MOUTH_CENTER;
    const leftEye = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.LEFT_EYE;
    const rightEye = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.RIGHT_EYE;

    const image = new Image();
    image.src = checkup.imagePath;
    image.setAttribute('crossOrigin', 'anonymous');
    image.addEventListener('load', () => {
      // get the angle of rotation
      const angle = this.getAlignmentAngle(leftEye, rightEye);
      // rotate the current image and get new image
      const alignedImage = this.getAlignedImage(image, angle);

      alignedImage.addEventListener('load', () => {
        eyeCenter = this.getPointWithRotation(eyeCenter, angle);
        mouthCenter = this.getPointWithRotation(mouthCenter, angle);
        if (eyeCenter && mouthCenter) {
          const source = {
            x: eyeCenter.x,
            y: eyeCenter.y - (mouthCenter.y - eyeCenter.y) * 1.5,
            width: eyeCenter.x,
            height: (mouthCenter.y - eyeCenter.y) * 4,
          };

          const additionalWidth = source.height / 2 - source.width;
          source.width += additionalWidth;

          const destination = {
            x: 0,
            y: 0,
            width: this.canvas.width,
            height: this.canvas.height,
          };
          this.canvasContext.filter = 'blur(12px)';
          this.canvasContext.drawImage(
            alignedImage,
            0,
            0,
            source.width,
            source.height,
            destination.x,
            destination.y,
            destination.width,
            destination.height,
          );
          this.canvasContext.filter = 'blur(0)';
          this.canvasContext.drawImage(
            alignedImage,
            source.x,
            source.y,
            source.width,
            source.height,
            destination.x,
            destination.y,
            destination.width,
            destination.height,
          );
        } else {
          const mux = this.getMux(image, this.canvas);
          this.canvasContext.drawImage(image, 0, 0, image.width * mux, image.height * mux);
        }
      });
    });
  }

  drawLeft(checkup: InstantCheckupInterface): void {
    let eyeCenter = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.MIDPOINT_BETWEEN_EYES;
    let mouthCenter = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.MOUTH_CENTER;
    const leftEye = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.LEFT_EYE;
    const rightEye = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.RIGHT_EYE;

    const image = new Image();
    image.src = checkup.imagePath;
    image.setAttribute('crossOrigin', 'anonymous');
    image.addEventListener('load', () => {
      // get the angle of rotation
      const angle = this.getAlignmentAngle(leftEye, rightEye);
      // rotate the current image and get new image
      const alignedImage = this.getAlignedImage(image, angle);

      alignedImage.addEventListener('load', () => {
        eyeCenter = this.getPointWithRotation(eyeCenter, angle);
        mouthCenter = this.getPointWithRotation(mouthCenter, angle);
        if (eyeCenter && mouthCenter) {
          const source = {
            x: 0,
            y: eyeCenter.y - (mouthCenter.y - eyeCenter.y) * 1.5,
            width: eyeCenter.x,
            height: (mouthCenter.y - eyeCenter.y) * 4,
          };

          const additionalWidth = source.height / 2 - source.width;
          source.x = -additionalWidth;
          source.width += additionalWidth;

          const destination = {
            x: 0,
            y: 0,
            width: this.canvas.width,
            height: this.canvas.height,
          };
          this.canvasContext.filter = 'blur(12px)';
          this.canvasContext.drawImage(
            alignedImage,
            0,
            0,
            source.width,
            source.height,
            destination.x,
            destination.y,
            destination.width,
            destination.height,
          );
          this.canvasContext.filter = 'blur(0)';
          this.canvasContext.drawImage(
            alignedImage,
            source.x,
            source.y,
            source.width,
            source.height,
            destination.x,
            destination.y,
            destination.width,
            destination.height,
          );
        } else {
          const mux = this.getMux(image, this.canvas);
          this.canvasContext.drawImage(image, 0, 0, image.width * mux, image.height * mux);
        }
      });
    });
  }

  draw(checkup: InstantCheckupInterface): void {
    const eyeCenter = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.MIDPOINT_BETWEEN_EYES;
    const mouthCenter = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.MOUTH_CENTER;
    const leftEye = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.LEFT_EYE;
    const rightEye = checkup.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks.RIGHT_EYE;

    const image = new Image();
    image.src = checkup.imagePath;
    image.setAttribute('crossOrigin', 'anonymous');
    image.addEventListener('load', () => {
      // get the angle of rotation
      const angle = this.getAlignmentAngle(leftEye, rightEye);
      // rotate the current image and get new image
      const alignedImage = this.getAlignedImage(image, angle);

      alignedImage.addEventListener('load', () => {
        const eyeCenterRotated = this.getPointWithRotation(eyeCenter, angle);
        const mouthCenterRotated = this.getPointWithRotation(mouthCenter, angle);
        if (eyeCenter && mouthCenter) {
          const source = {
            x: 0,
            y: eyeCenterRotated.y - (mouthCenterRotated.y - eyeCenterRotated.y) * 1.5,
            width: eyeCenterRotated.x * 2,
            height: (mouthCenterRotated.y - eyeCenterRotated.y) * 4,
          };

          const additionalWidth = source.height - source.width;
          source.x = -additionalWidth / 2;
          source.width += additionalWidth;

          const destination = {
            x: 0,
            y: 0,
            width: this.canvas.width,
            height: this.canvas.height,
          };
          this.canvasContext.filter = 'blur(12px)';
          this.canvasContext.drawImage(image, 0, 0, destination.width, destination.height);
          this.canvasContext.filter = 'blur(0)';
          this.canvasContext.drawImage(
            alignedImage,
            source.x,
            source.y,
            source.width,
            source.height,
            destination.x,
            destination.y,
            destination.width,
            destination.height,
          );

          // emit config
          // emit config
          this.imageConfig.emit({
            compressedImage: !!checkup?.compressedImagePath,
            height: `${image.height * (360 / image.width)}px`,
            width: '360px',
            left: 0,
            top: (eyeCenter.y - (mouthCenter.y - eyeCenter.y) * 1.5) * (360 / image.width),
            instantCheckup: checkup.objectId,
          });
        } else {
          const mux = this.getMux(image, this.canvas);
          this.canvasContext.drawImage(image, 0, 0, image.width * mux, image.height * mux);
        }
      });
    });
  }

  // get mux
  // scale image
  getMux(image: HTMLImageElement, canvas: HTMLCanvasElement): number {
    const imageHeight = image.height;
    const imageWidth = image.width;
    const canvasHeight = canvas.height;
    const canvasWidth = canvas.width;
    return Math.max(canvasHeight / imageHeight, canvasWidth / imageWidth);
  }

  // Get the angle of rotation for image to rorate to align horizontally wrt to eyes
  getAlignmentAngle(leftEye: { x: number, y: number }, rightEye: { x: number, y: number }): number {
    const m = (leftEye.y - rightEye.y) / (leftEye.x - rightEye.x);
    return -Math.tan(m);
  }

  // get new point after rotation of angle 'a'
  getPointWithRotation(point: { x: number, y: number }, a: number): { x: number, y: number } {
    return {
      x: point.x * Math.cos(a) - point.y * Math.sin(a),
      y: point.x * Math.sin(a) + point.y * Math.cos(a),
    };
  }

  getAlignedImage(image: HTMLImageElement, angle: number): HTMLImageElement {
    const newCanvas = this.window.nativeWindow.document.createElement('canvas');
    const newContext = newCanvas.getContext('2d');
    newCanvas.height = image.height;
    newCanvas.width = image.width;
    newContext.translate(0, 0);
    newContext.rotate(angle);
    newContext.drawImage(image, 0, 0);

    const newImage = new Image();
    newImage.setAttribute('crossOrigin', 'anonymous');
    newImage.src = newCanvas.toDataURL('image/png');
    return newImage;
  }
}
