import { Router, ActivatedRoute } from '@angular/router';
import { Component, NgZone } from '@angular/core';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({
  selector: 'edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.scss'],
})
export class EditComponent {
  window: any;
  coupon: any;
  couponTypes: Array<any> = [];
  orderTypes: Array<any> = [];
  couponCode: string;
  currentUser: any;
  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private broadcaster: Broadcaster,
    private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.couponTypes = [{
      value: ApiClientConstant.Coupon.Type.NORMAL,
      display: ApiClientConstant.Coupon.Type.NORMAL,
    }];
    this.orderTypes = Object.keys(ApiClientConstant.Order.Type).map((key: string) => ({
      value: ApiClientConstant.Order.Type[key],
      display: ApiClientConstant.Order.Type[key],
    }));
    this.route.params.subscribe(async (params: any): Promise<any> => {
      if (params.id) {
        [[this.coupon]] = await this.conn.getCoupons({ where: { objectId: params.id } });
      } else {
        this.coupon = new Table.Coupon();
        this.coupon.set('serverOnly', false);
        this.coupon.set('active', true);
      }
      if (!this.coupon.get('orderTypes')) {
        this.coupon.set('orderTypes', []);
      }
      this.couponCode = this.coupon.get('code');
    });
    this.currentUser = this.conn.getCurrentUser();
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.currentUser });
  }

  async saveCoupon(): Promise<any> {
    if (!this.coupon.get('amount') && !this.coupon.get('percentage')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Enter discount percentage or amount',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (!this.coupon.get('orderTypes').length) {
      this.broadcaster.broadcast('NOTIFY', { message: 'orderTypes is missing',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (!this.coupon.id) {
      this.coupon.set('createdBy', this.conn.getCurrentUser());
    }
    try {
      await this.coupon.save();
      this.router.navigate(['/ecom/coupons']);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message || error,
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  codeChange(event: any): void {
    if (/[^a-zA-Z' ''_'0-9]/.test(event.key)) {
      this.couponCode = this.couponCode.substring(0, this.couponCode.length - 1);
      this.broadcaster.broadcast('NOTIFY', { message: 'No Special Character Allowed except "_"',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
    }
    if (event.key === ' ') {
      this.couponCode = this.couponCode.replace(' ', '_');
    }
    this.couponCode = this.couponCode.toUpperCase();
    this.coupon.set('code', this.couponCode);
  }
}
