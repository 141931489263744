import { Component, OnDestroy } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'tree-list',
  templateUrl: './tree-list.html',
})
export class TreeListComponent implements OnDestroy {
  filters: any;
  fields: Array<string> = ['name'];
  ui: any = { isFilterOpen: false };
  tempFilters: any;
  trees: any;
  constructor(private conn: ConnectionService, private appConfig: AppConfig) {
    this.filters = [];
  }

  ngOnInit(): void {
    this.reset();
  }

  reset(): void {
    this.trees = [];
    this.loadMore();
  }

  editFilters(): void {
    this.tempFilters = this.filters.map((x: any) => x);
  }

  loadMore(): void {
    const where = {};
    this.filters.forEach((item: any) => (where[item.field] = item.value));
    this.conn.getTreeNodes({ where: Object.assign(where, { type: ApiClientConstant.Tree.Type.BASIC_INFO }) })
      .then((trees: any) => (this.trees = trees));
  }

  applyFilter(): void {
    this.filters = this.tempFilters.map((x: any) => x);
    this.ui.isFilterOpen = false;
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.appConfig;
    delete this.trees;
    delete this.filters;
    delete this.fields;
    delete this.ui;
    delete this.tempFilters;
  }
}
