import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MomentModule } from 'ngx-moment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { DirectivesModule } from '../../../../components/directives.module';
import { RegimenEditComponent } from './regimen-edit.component';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputTextareaModule } from '../../../../components/input/input-textarea';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { InputSingleImageUploadModule } from '../../../../components/input/input-single-image-upload';
import { RegimenEditProductComponent } from './regimen-edit-part-of-day/regimen-edit-product';
import { RegimenEditPartOfDayComponent } from './regimen-edit-part-of-day';
import { CatalogInstructionViewModule } from '../../catalog/view/catalog-instruction/catolog-instruction-view-modal';
import { LanguageViewModule } from '../../../../components/language-string/language-view';
import { LanguageStringAutoCompleteModule } from '../../../../components/language-string/language-string-auto-complete';
import { LanguageStringMultipleSelectionModule } from '../../../../components/language-string/language-string-multiple-selection';
import { LanguageStringTagSelectionModule } from '../../../../components/language-string/language-string-tag-selection';
import { RegimenProductChangeHistoryComponent } from './regimen-product-change-history-model/regimen-product-change-history.component';
import { FollowUpSOPComponent } from './followup-sop/followup-sop.component';
import { InstantCheckupMagnifierModule } from '../../../../components/instant-checkup-magnifier';
import { UserChatViewModule } from '../../../../components/user-chat-view';

@NgModule({
  imports: [
    MatSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    DirectivesModule,
    MatDialogModule,
    MatDatepickerModule,
    LanguageStringSelectionModule,
    InputTextModule,
    InputSelectModule,
    InputTextareaModule,
    InputCheckboxModule,
    InputSingleImageUploadModule,
    CatalogInstructionViewModule,
    LanguageViewModule,
    MomentModule,
    LanguageStringAutoCompleteModule,
    LanguageStringMultipleSelectionModule,
    LanguageStringTagSelectionModule,
    InstantCheckupMagnifierModule,
    UserChatViewModule,
  ],
  declarations: [RegimenEditComponent, RegimenEditProductComponent, RegimenEditPartOfDayComponent,
    RegimenProductChangeHistoryComponent, FollowUpSOPComponent],
  exports: [RegimenEditComponent, RegimenEditProductComponent, RegimenEditPartOfDayComponent, FollowUpSOPComponent],
})
export class RegimenEditWithoutRouteModule {
}
