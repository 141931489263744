import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LanguageStringTagSelectionComponent } from './language-string-tag-selection.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
  declarations: [LanguageStringTagSelectionComponent],
  exports: [LanguageStringTagSelectionComponent],
})
export class LanguageStringTagSelectionModule {
}
