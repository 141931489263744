
<div class="flex-box" *ngIf="catalogs">
  <div class="flex-heading">
    <div class="list-header col-xs-12"><span class="pR25">Addon Products</span></div>
  </div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Search</div>
        </div>
        <div class="content">
          <input class="w-100 mt-3" type="text" name="searchText" placeholder="Type Here To Search..." [(ngModel)]="searchText" (ngModelChange)="onChangeText()"/>
        </div>
      </div>
      <div class="list-content of-scr">
        <div class="col-xs-12 flex flex-wrap bdr-btm" *ngIf="rxProducts.length">
          <label class="title col-xs-12 text-center">Rx-Products</label><a class="col-xs-3 bdr product-box" *ngFor="let catalog of rxProducts" href="/products/catalog/{{catalog.objectId}}" target="_blank"><span>{{catalog.title}}</span></a>
        </div>
        <div class="col-xs-12 flex flex-wrap" *ngIf="nonRxProducts.length">
          <label class="title col-xs-12 text-center">Non-Rx-Products</label><a class="col-xs-3 bdr product-box" *ngFor="let catalog of nonRxProducts" href="/products/catalog/{{catalog.objectId}}" target="_blank"><span>{{catalog.title}}</span></a>
        </div>
      </div>
    </div>
  </div>
</div>