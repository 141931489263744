import { Component, OnInit } from '@angular/core';
import { ConnectionService } from '../../services/connection-service';

@Component({ selector: 'on-call', templateUrl: './onCall.html' })
export class OnCall implements OnInit {
  onCallMembers: Array<any> = [];
  onCallThisWeek: any;
  editedOnCallMember: string;
  editOnCallFlag: boolean;
  isRootUser: boolean;
  hideBox: boolean = true;
  currentUserName: string;
  constructor(private conn: ConnectionService) {}

  async ngOnInit(): Promise<void> {
    const experiment = await this.conn.findExperiment({ where: { name: 'disable_onCall_card', enable: true } });
    if (experiment) {
      this.hideBox = true;
    } else {
      this.fetchOnCallMembers();
      this.isRootUser = false;
      this.editOnCallFlag = false;
      this.currentUserName = this.conn.getCurrentUser().get('username');
      if (this.conn.isUserLoggedIn && this.conn.getCurrentUser().get('onCallMember')) {
        this.isRootUser = true;
      }
    }
  }

  async fetchOnCallMembers(): Promise<any> {
    this.onCallMembers = await this.conn.findUsers({
      where: { onCallMember: true, inactive: false },
      project: ['onCall', 'exotelNumber', 'PatientName', 'username'],
    });
    const onCall = this.onCallMembers.filter((member: any) => member.get('onCall') === true);
    this.onCallThisWeek = onCall[0];
  }

  async saveOnCall(): Promise<any> {
    if (!this.editedOnCallMember) return 0;
    await this.conn.updateOnCallMember(this.editedOnCallMember);
    this.editOnCallFlag = false;
    return this.fetchOnCallMembers();
  }
}
