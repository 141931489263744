import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Broadcaster } from 'src/components/broadcaster';
import { ApiClientConstant } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { TicketHistoryComponent } from '../../../../components/ticket-history';
import { ChangeTicketAllocationComponent } from '../../../../components/change-ticket-allocation/change-ticket-allocation.component';
import { SBARService } from '../../../../services/sbar-service';

@Component({
  selector: 'support-chat-view',
  templateUrl: './support-chat-view.html',
  styleUrls: ['./support-chat-view.scss'],
})
export class SupportChatViewComponent {
  chatUser: any;
  user: any;
  ticketId: string;
  chats: any[];
  ticket: any;
  showCaptureButton: boolean;
  appIframeURL: SafeResourceUrl;
  @Input('user') set setUser(user: any) {
    this.chatUser = user;
  }
  @ViewChild('chatContainer') chatContainer: ElementRef<any>;

  public subscriptions: Subscription[] = [];

  constructor(public appConfig: AppConfig,
    private route: ActivatedRoute,
    private router: Router,
    private conn: ConnectionService,
    private dialog: MatDialog,
    private broadcaster: Broadcaster,
    private dom: DomSanitizer,
    private sbarService: SBARService) {
  }

  async ngOnInit(): Promise<any> {
    this.user = await this.conn.getCurrentUser();
    await this.processData();
  }

  async processData(): Promise<void> {
    this.route.queryParams.subscribe(async (params: Record<string, any>): Promise<void> => {
      this.ticketId = params.ticketId;
      await this.getTicket();
      await this.refreshChat();
    });
  }

  async getTicket(): Promise<void> {
    this.ticket = await this.conn.fetchSupportTicket({
      where: { objectId: this.ticketId },
      include: ['titleLanguageString'],
    });
    if (!this.ticket.get('attended') && this.ticket.get('status') === ApiClientConstant.SupportTicket.Status.Pending
      && this.user.get('type') === ApiClientConstant.User.Type.DOCTOR) {
      this.showCaptureButton = true;
    }
  }

  async refreshChat(): Promise<void> {
    this.appIframeURL = this.dom.bypassSecurityTrustResourceUrl(`${this.conn
      .getWebAppUrl()}/chatV2/${this.ticket.id}?type=supportTicket&loginType=token&username=${this.chatUser.get('username')}`);
  }

  async resetSupportTicketData(): Promise<void> {
    setTimeout(async () => {
      await this.getTicket();
    }, 1000);
  }

  async toggleDisableChat(): Promise<void> {
    this.ticket.set('disableChat', !this.ticket.get('disableChat'));
    await this.ticket.save();
  }

  async requestTicketClose(): Promise<void> {
    this.ticket.set('status', ApiClientConstant.SupportTicket.Status.RequestClosure);
    this.ticket = await this.ticket.save();
  }

  async reOpenTicket(): Promise<void> {
    this.ticket.set('status', ApiClientConstant.SupportTicket.Status.Pending);
    this.ticket = await this.ticket.save();
  }

  async updateTicketAttendedStatus(attended: boolean): Promise<void> {
    try {
      this.ticket.set('attended', attended);
      this.ticket = await this.ticket.save();
    } catch ({ message }) {
      alert(message);
    }
  }

  async captureTicket(): Promise<void> {
    if (this.ticket.get('attended') || this.ticket.get('status') === ApiClientConstant.SupportTicket.Status.Completed) {
      return;
    }
    if (this.user.get('type') !== ApiClientConstant.User.Type.DOCTOR) return;
    this.ticket.set('allocatedDoctor', this.user);
  }

  editTicket(ticket: any): void {
    const dialogRef = this.dialog.open(ChangeTicketAllocationComponent, {
      data: {
        allocatedDoctor: ticket.get('allocatedDoctor'),
        allocatedOperator: ticket.get('allocatedOperator'),
        teams: ticket.get('teams'),
        skipReAllocateDoctor: ticket.get('skipReAllocateDoctor'),
      },
    });
    dialogRef.afterClosed().subscribe(async (data: any): Promise<any> => {
      if (!data) return;
      const context = {
        reAllocateDoctor: false,
      };
      try {
        if (!data) return;
        if (data.selectedOperator?.id) ticket.set('allocatedOperator', data.selectedOperator);
        if (data.teams?.length) {
          ticket.set('teams', data.teams);
          if (data.teams.includes('doctor')) {
            context.reAllocateDoctor = true;
          }
        }
        if (data.selectedDoctor?.id) {
          ticket.set('allocatedDoctor', data.selectedDoctor);
          delete context.reAllocateDoctor;
        }
        ticket.set('skipReAllocateDoctor', data.skipReAllocateDoctor);
        await ticket.save({}, { context });
        this.broadcaster.broadcast('NOTIFY', { message: 'Allocation updated successfully.' });
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    });
  }

  openTicketHistory(ticket: any): void {
    this.dialog.open(TicketHistoryComponent, { width: '70%', data: { ticket } });
  }
}
