import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { InputSelectModule } from 'src/components/input/input-select';
import { DirectivesModule } from 'src/components/directives.module';
import { InputTextModule } from 'src/components/input/input-text';
import { ReminderRoutingModule } from './reminder.routing';
import { ReminderListComponent, ReminderFormComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    ReminderRoutingModule,
    FormsModule,
    MatDialogModule,
    MatSnackBarModule,
    InputSelectModule,
    InputTextModule,
    AgGridAngular,
    DirectivesModule,
  ],
  declarations: [
    ReminderListComponent,
    ReminderFormComponent,
  ],
})
export class ReminderModule { }
