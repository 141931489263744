import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'variant-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './variant-card.component.html',
  styleUrl: './variant-card.component.scss',
})
export class VariantCardComponent {
  @Input('selected') selected: boolean;
  @Input('regimenInfo') regimenInfo: any;
  @Input('index') index: number;
  @Input('selectedIndex') selectedIndex: number;
  @Output('variantSelection') variantSelection: EventEmitter<any> = new EventEmitter();

  variantCardClick(): void {
    this.variantSelection.emit(this.index);
  }
}
