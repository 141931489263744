import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-kiosk-doctor-modal',
  templateUrl: './add-kiosk-doctor-modal.component.html',
})
export class AddKioskDoctorModalComponent {
  doctorsList: any = [];
  selectedDoctorId:any;
  selectedStartTime: string;
  selectedEndTime: string;
  selectedDay: string;
  popUpData: any;

  constructor(private injector: Injector,
    private dialogRef: MatDialogRef<AddKioskDoctorModalComponent>,
  ) {
    this.popUpData = this.injector.get(MAT_DIALOG_DATA);
    this.doctorsList = this.popUpData.doctorsList;
    this.selectedDay = this.popUpData.day;
    this.selectedStartTime = this.convertMinutesToHours(this.popUpData.data.start);
    this.selectedEndTime = this.convertMinutesToHours(this.popUpData.data.end);
  }

  convertMinutesToHours(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    return `${hours}`;
  }

  addDoctor(): void {
    this.popUpData.addDoctorToRoaster(
      this.selectedStartTime, this.selectedEndTime, this.selectedDoctorId, this.selectedDay,
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
