import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { ApiConnector, Table } from 'api-client';
import { TreatmentProgressAddDialog } from 'src/components/treatment-progress-add/treatment-progress-add.component';
import { ConnectionService } from '../../../../../services/connection-service';
import { VoiceNoteAddDialog } from '../../../../../components/voice-note-add/voice-note-add.component';

declare type FollowUpSOPNode = {
  name?: string;
  shortName?: string;
  tests?: any;
  audioTemplateName?: string;
  treatmentProgress?: Array<{ issue: string, concernLanguageString: any, score: number }>;
  disabled?: boolean;
  nextFollowUps: Array<FollowUpSOPNode>,
  changes: Array<{ partOfDay?: string; type?: string; onHold?: boolean; isComplementory?: boolean;
    position?: any; productInfo?: any; productId?: string,
    instruction?: Record<string, unknown>; }>;
};

@Component({
  selector: 'followup-sop',
  templateUrl: './followup-sop.html',
  styleUrls: ['./followup-sop.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class FollowUpSOPComponent implements OnInit {
  @Input('node') node: FollowUpSOPNode;
  @Input('prefix') prefix: string = '';
  @Input('regimenId') regimenId: string;
  @Input('level')
  set levelValue(level: number) {
    this.level = level;
    this.getRandomColor();
  }
  level: number = 0;
  bgColor: string = '#ffffff';
  regimenProducts: any = [];
  @Output() formValue: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  testsList: any = [];
  treatmentProgress?: Array<{ issue: string, concernLanguageString: any, score: number }> = [];
  class: string;
  operationChange: any = ['addProduct', 'removeProduct', 'changeInstruction'];
  selectedInvestigation: { tests: string[], name?: string, index?: number } = { tests: [] };
  shortNameOptions: Array<{ display: string, value: string }> = [
    { display: 'Change as expected', value: 'CHANGE_AS_EXPECTED' },
    { display: 'Slow improvement', value: 'SLOW_IMPROVEMENT' },
    { display: 'No change', value: 'NO_CHANGE' },
    { display: 'Dis-comfort', value: 'DISCOMFORT' },
    { display: 'Slightly increased', value: 'SLIGHTLY_INCREASED' },
    { display: 'Flare up', value: 'FLARE_UP' },
    { display: 'others', value: 'Others' },
  ];
  showShortNameTextInput: boolean;
  @Input('prevLevelRegimen') prevLevelRegimen: any;
  constructor(private conn: ConnectionService,
    private router: ActivatedRoute,
    private appConfig: AppConfig,
    private dialog: MatDialog) { }

  async ngOnInit(): Promise<any> {
    (this.router.params.subscribe(async (queryParams: Params) => {
      const regimen = await this.conn.getRegimen(queryParams.id);
      this.class = regimen.get('class');
      this.regimenProducts = [...regimen.get('products')];
      if (!this.prevLevelRegimen?.length) {
        this.prevLevelRegimen = this.regimenProducts;
      }
      for (let i = 0; i < this.node.changes.length; i += 1) {
        if (this.node.changes[i].type === 'addProduct') {
          this.prevLevelRegimen.push(this.node.changes[i].productInfo.product);
        }
      }
      this.regimenProducts = [...this.prevLevelRegimen];
    }));

    this.testsList = await this.conn.fetchInvestigations();
    this.selectedInvestigation.index = this.testsList
      .findIndex((each: any) => each.get('name') === this.node?.tests?.name);
    this.getRandomColor();
  }

  onChangeShortName(): void {
    if (this.node.shortName !== 'Others') {
      this.showShortNameTextInput = false;
      return;
    }
    delete this.node.shortName;
    this.showShortNameTextInput = true;
  }
  changeInvestigation(): void {
    const selectedIndex = Number(this.selectedInvestigation.index);
    if (selectedIndex >= 0) this.selectedInvestigation.name = this.testsList[selectedIndex].get('name');
    if (selectedIndex >= 0) {
      this.selectedInvestigation.tests = this.testsList[selectedIndex].get('tests');
      this.node.tests = this.selectedInvestigation;
    } else {
      this.selectedInvestigation.tests = [];
      delete this.selectedInvestigation.name;
    }
  }

  addFollowUpForm(): any {
    this.node.nextFollowUps.push({ nextFollowUps: [], changes: [], treatmentProgress: [] });
  }

  addRegimenChanges(): any {
    this.node.changes.push({
      type: '',
      partOfDay: '',
      onHold: false,
      isComplementory: false,
      position: undefined,
      productInfo: {},
      productId: '',
      instruction: {},
    });
  }

  deleteFollowUp(index: any): any {
    this.node.nextFollowUps.splice(index, 1);
  }
  removeRegimenChange(index: number): any {
    this.node.changes.splice(index, 1);
  }

  getRandomColor(): void {
    const depthColorCode = ['#D3C6DD', '#EDE8F1', '#B6CCE7', '#BDDDD4', '#ECE089', '#F1C49C'];
    this.bgColor = depthColorCode[this.level];
  }

  removeFollowUp(): any {
    this.delete.emit();
  }

  regimenChange(index: number): any {
    const productData = this.node.changes[index]?.productInfo;
    if (this.node.changes[index].type === 'addProduct') {
      this.prevLevelRegimen.push(this.node.changes[index]?.productInfo.product);
    }
    if (this.node.changes[index].type === 'removeProduct') {
      const arrCopy = Array.from(this.prevLevelRegimen);
      const objWithIdIndex = arrCopy.findIndex((obj: any) => obj.id === productData?.product?.id);
      arrCopy.splice(objWithIdIndex, 1);
      this.prevLevelRegimen = arrCopy;
    }
    this.node.changes[index].productId = productData?.product?.id;
    this.node.changes[index].instruction = productData?.instructionSet;
  }
  checkForSpace(event: KeyboardEvent): any {
    if (event.key === ' ') {
      alert('Name should not contain space');
      event.preventDefault();
    }
  }
  async treatmentProgressEdit(): Promise<void> {
    if (!this.node.treatmentProgress) {
      this.node.treatmentProgress = [];
    }
    this.dialog.open(TreatmentProgressAddDialog, { data:
      { treatmentProgress: this.node.treatmentProgress, class: this.class } }).afterClosed().subscribe((result: any) => {
      if (result?.issue) {
        this.node.treatmentProgress.push({ issue: result.issue, concernLanguageString: result.concernLanguageString, score: result.score });
      }
    });
  }

  async updateVoiceNote(): Promise<void> {
    const uniqueAudioTemplateName = this.node.audioTemplateName || `${this.regimenId}_${this.node.name}_${this.level}`;
    this.dialog.open(VoiceNoteAddDialog, { data: { audioTemplateName: uniqueAudioTemplateName } })
      .afterClosed().subscribe(async (result: any) => {
        this.node.audioTemplateName = result;
      });
  }
}
