import { Component, Input } from '@angular/core';
import { OrderNoteModule } from 'src/app/order/view/order-note/order-note.module';
import { ConnectionService } from 'src/services/connection-service';

@Component({
  selector: 'sales-order-notes',
  standalone: true,
  imports: [OrderNoteModule],
  templateUrl: './sales-order-notes.component.html',
  styleUrl: './sales-order-notes.component.scss',
})
export class SalesOrderNotesComponent {
  @Input('orderObjId') orderObjId: string;
  @Input('note') note: string;
  orderObj: any;
  showAddNote: boolean = false;

  constructor(private conn: ConnectionService) { }

  async ngOnChanges(): Promise<void> {
    if (!this.orderObjId) return;
    this.orderObj = await this.conn.findOneOrder({
      where: { objectId: this.orderObjId },
      project: ['allocatedDoctor', 'stage', 'notes', 'user'],
    });
  }
}
