
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="col-xs-12 list-header"><span class="pR10">Leaves And Holidays ({{count || 0}})</span>
      <div class="pull-right"><i class="fa fa-plus-square-o" routerLink="new"></i></div>
    </div>
  </div>
  <div class="flex-content">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content p-0">
          <div class="flex mb-3 px-3 bdr-top pt-3" *ngIf="this.changingLeaveStatusList.length">
            <button class="btn-xxs btn-secondary" (click)="openStatusDialog()">Approve/ Reject Leave</button>
            <button class="btn-xxs btn-secondary ml-3" (click)="clearAllSelectedLeaves()">Clear</button>
          </div>
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.DOCTOR" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.DOCTOR">
              <doctor-filter (updatedDoctorList)="updateDoctorFilter($event)" [reset]="resetDoctorFilter"></doctor-filter>
            </collapse>
          </div>
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.OPERATOR" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.OPERATOR">
              <operator-filter (updatedOperatorList)="updateOperatorFilter($event)" [reset]="resetOperatorFilter"></operator-filter>
            </collapse>
          </div>
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.LEAVES" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.LEAVES">
              <leaves-filter (updatedLeavesList)="updateLeavesFilter($event)" [reset]="resetLeavesFilter"></leaves-filter>
            </collapse>
          </div>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>