import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiConnector, Table } from 'api-client';
import { UploadFileService } from '../../../services/uploadFileService/uploadFile.service';
import { AppConfig } from '../../../app/app.config';
import { ConnectionService } from '../../../services/connection-service';
import { Broadcaster } from '../../broadcaster';
import { HelperService } from '../../../services/helper-service';

@Component({ selector: 'imageUpload-modal', templateUrl: './imageUploadModal.html' })
export class ImageUploadModal {
  message: string = '';
  file: File;

  constructor(private dialogRef: MatDialogRef<ImageUploadModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private upload: UploadFileService,
    private appConfig: AppConfig,
    private conn: ConnectionService,
    private broadcast: Broadcaster,
    private helperService:HelperService) {
  }

  async hideModal(): Promise<any> {
    this.dialogRef.close();
  }

  public async uploadToAws(): Promise<void> {
    const url: string = await this.upload.uploadFile({
      file: this.file,
      bucket: 'OTHER',
      username: this.data.userObj.get('username'),
      source: 'doctor_other',
    });
    const userFile = new Table.UserFiles();

    userFile.set('fileUrl', url);
    userFile.set('createdBy', ApiConnector.getCurrentUser().get('type').toUpperCase());
    userFile.set('user', this.data.userObj);
    userFile.set('type', this.helperService.findFileType(this.file.name));
    try {
      await userFile.save({}, { context: { displayInChat: true, ...this.data.context } });
      this.broadcast.broadcast('NOTIFY', { message: 'User File Uploaded', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.dialogRef.close();
    } catch (error) {
      let message;
      if (typeof this.file === 'undefined') {
        message = 'Please choose a file';
      } else {
        message = 'Error occurred while uploading and sending image';
      }
      this.broadcast.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
