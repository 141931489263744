import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InternalUserComponent } from './internal-user.component';
import { EditModule } from './edit';
import { ListModule } from './list';

const ROUTES: Routes = [
  {
    path: 'internal-users',
    component: InternalUserComponent,
    loadChildren: (): any => ListModule,
    pathMatch: 'full',
  },
  {
    path: 'internal-users/new',
    component: InternalUserComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
  {
    path: 'internal-user/:id/edit',
    component: InternalUserComponent,
    loadChildren: (): any => EditModule,
    pathMatch: 'full',
  },
];

@NgModule({ providers: [],
  imports: [RouterModule.forChild(ROUTES)],
  declarations: [InternalUserComponent],
  exports: [InternalUserComponent] })
export class InternalUserModule {}
