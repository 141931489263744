import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { ParseKeys, RequestQueryPayload, Table } from 'api-client';
import * as moment from 'moment';

import { AppConfig } from 'src/app/app.config';
import { ConnectionService } from 'src/services/connection-service';
import { WindowRefService } from 'src/services/window-ref-service';
import { Broadcaster } from 'src/components/broadcaster';
import { ReminderFormComponent } from '../reminder-form/reminder-form.component';

@Component({
  selector: 'app-reminder-list',
  templateUrl: './reminder-list.component.html',
  styleUrls: ['./reminder-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReminderListComponent {
  currentUser: any;
  ui: any;
  window: any;
  dataSource: any;
  components: any;
  columnDefs: any;
  gridApi: GridApi;

  @ViewChild('confirmDelete') confirmDeleteTemplate: TemplateRef<any>;
  constructor(
    private conn: ConnectionService,
    private config: AppConfig,
    private dialog: MatDialog,
    private windowRef: WindowRefService,
    private broadcast: Broadcaster,
  ) {
    this.window = windowRef.nativeWindow;
    this.currentUser = this.conn.getCurrentUser();
    this.listViewSetup();
  }

  listViewSetup(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };

    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };

    // Define ag-grid columns
    this.columnDefs = [{
      headerName: 'Date',
      field: 'Date',
      width: 120,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `${moment(params.data?.get('createdAt')).format('DD-MM-YYYY')}`;
        return eDiv;
      },
    }, {
      headerName: 'Name',
      width: 170,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `${params.data?.get('name') || ''}`;
        return eDiv;
      },
    }, {
      headerName: 'Type',
      width: 170,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `${params.data?.get('type')}`;
        return eDiv;
      },
    }, {
      headerName: 'Image',
      field: 'image',
      width: 130,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<img style="max-height: 50px;" src="${params.data?.get('image')}">`;
        return eDiv;
      },
    }, {
      headerName: 'Link',
      field: 'Link',
      width: 570,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        const link = `https://test-app.cureskin.com/user(reminder:reminder/${params.data?.id})?tab=home&init=true`;
        eDiv.addEventListener('click', this.copy(link));
        eDiv.innerHTML = `${link}
          <br>
          <a class='fa fa-copy pR10'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'Delete',
      width: 75,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv: HTMLDivElement = this.window.document.createElement('div');
        eDiv.addEventListener('click', this.delete(params.data?.id));
        eDiv.innerHTML = `<a (click)="delete(${params.data?.id}) style="color: #dc3545;">Delete</a>`;
        return eDiv;
      },
    }];
  }

  copy(link: string): any {
    return () => {
      navigator.clipboard.writeText(link).then(() => {
        this.broadcast.broadcast('NOTIFY', { message: 'Link copied!', type: this.config.Shared.Toast.Type.SUCCESS });
      });
    };
  }

  delete(id: any): any {
    return () => {
      this.dialog.open(this.confirmDeleteTemplate).afterClosed().subscribe(async (confirm: boolean): Promise<void> => {
        if (!confirm) return;
        const reminder = await this.fetchReminderList({ where: { objectId: id }, limit: 1 });
        if (!reminder || !reminder.length) return;
        await reminder[0].destroy();
        this.reset();
        this.broadcast.broadcast('NOTIFY', { message: 'Reminder deleted', type: this.config.Shared.Toast.Type.WARNING });
      });
    };
  }

  loadData(params: {startRow: number, sortModel: any[]}): Promise<any> {
    const attendToChatCacheRequestPayload: RequestQueryPayload<Table.Reminder> = {
      where: { },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
    };
    const { colId, sort }: { colId: ParseKeys<Table.Reminder>, sort: 'asc' | 'desc' } = params.sortModel[0]
      || { colId: 'createdAt', sort: 'asc' };
    if (sort === 'desc') {
      attendToChatCacheRequestPayload.descending = colId;
    } else {
      attendToChatCacheRequestPayload.ascending = colId;
    }
    return this.fetchReminderList(attendToChatCacheRequestPayload);
  }

  fetchReminderList(params: RequestQueryPayload<Table.Reminder>): Promise<any> {
    return this.conn.fetchReminderList(params);
  }

  resetFilters(): void {
    // delete this.searchKey;
    // this.resetDoctorFilter = this.teams.includes(this.appConfig.Shared.Role.Name.DOCTOR);
    // this.resetOperatorFilter = this.teams.includes(this.appConfig.Shared.Role.Name.CHAT_SUPPORT);
    // this.resetTeamFilter = true;
    // this.resetLanguageFilter = true;
    // this.resetLabelFilter = true;
    // this.operatorFilter = this.resetOperatorFilter ? [] : undefined;
    // this.doctorFilter = this.resetDoctorFilter ? [] : undefined;
    // this.teamFilter = undefined;
    // this.labelFilter = undefined;
    // this.languageFilter = undefined;
    // this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  openForm(): void {
    this.dialog.open(ReminderFormComponent).afterClosed().subscribe((res: boolean): void => {
      if (res) this.reset();
    });
  }
}
