import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { InputTextareaModule } from 'src/components/input/input-textarea';
import { EditComponent } from './edit.component';
import { DirectivesModule } from '../../../../components/directives.module';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { InputInternalUserSelectModule } from '../../../../components/input/input-internal-user-select';

const ROUTES: Routes = [
  { path: '', component: EditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    InputTextModule,
    InputSelectModule,
    InputCheckboxModule,
    MatSelectModule,
    InputTextareaModule,
    InputInternalUserSelectModule,
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
})
export class EditModule {}
