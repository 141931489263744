import { v4 as uuid } from 'uuid';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AppConfig } from '../../../app/app.config';
import { InputHelperService } from '../input-helper';

@Component({
  selector: 'input-checkbox',
  templateUrl: './input-checkbox.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class InputCheckboxComponent {
  @Input('parseObj')
  set onUpdateParseObj(parseObj: any) {
    this.parseObj = parseObj;
    this.onInputChange();
  }
  @Input('name')
  set onUpdateFieldName(name: string) {
    this.name = name;
    this.onInputChange();
  }
  @Input('disabled')
  set onUpdateDisabled(status: any) {
    this.disabled = !!status;
  }
  @Input('title') title: string;
  @Input('class') className: string = 'bg-success';
  disabled: boolean = false;

  @Output('onValueChange') onValueChange: EventEmitter<boolean> = new EventEmitter();

  ui: any = {};
  parseValue: boolean = false;
  parseObj: any;
  name: string = uuid();

  static parameters: any = [AppConfig, InputHelperService];
  constructor(public appConfig: AppConfig, private inputHelper: InputHelperService) {
  }

  ngOnInit(): void {
  }

  onInputChange(): void {
    if (!this.parseObj || !this.name) return;
    this.parseValue = this.inputHelper.getValue(this.parseObj, this.name);
    this.onValueChange.emit(this.parseValue);
  }

  valueUpdated(): void {
    this.inputHelper.setValue(this.parseObj, this.name, this.parseValue);
    this.onValueChange.emit(this.parseValue);
  }
}
