import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class TimeService {
  pipe: DatePipe;

  static parameters: any = [];
  constructor() {
    this.pipe = new DatePipe('en-US');
  }

  isAfter(date1: Date, date2: Date, granularity?: string): boolean {
    if (!date1 || !date2) return false;
    const compareDate1 = new Date(date1.getTime());
    const compareDate2 = new Date(date2.getTime());
    if (['day'].includes(granularity)) {
      compareDate1.setHours(0, 0, 0, 0);
      compareDate2.setHours(0, 0, 0, 0);
    }
    return compareDate1.getTime() > compareDate2.getTime();
  }

  addDays(date: Date, days: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  addWeeks(date: Date, weeks: number): Date {
    return this.addDays(date, weeks * 7);
  }

  addYears(date: Date, years: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setFullYear(date.getFullYear() + years);
    return newDate;
  }

  format(date: Date, dateFormat: string): string {
    return this.pipe.transform(date, dateFormat);
  }

  difference(date1: any, date2: any): Date {
    return new Date(Math.abs(date1 - date2));
  }
}
