
<div class="hbox" *ngIf="serviceObj">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/products/services">Services</a></li>
          <li>View</li>
        </ul>
      </div>
    </div>
    <div class="flex-content p-4">
      <div class="col-xs-12 flex">
        <label class="col-xs-2 mt-0">Title</label>
        <div class="col pull-left">:</div>
        <div class="col-sm-6">{{serviceObj.get('title')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2"> Type</label>
        <div class="col pull-left">:</div>
        <div class="col-sm-6">{{serviceObj.get('type')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2"> Duration of extending regimen endDate</label>
        <div class="col pull-left">:</div>
        <div class="col-sm-6">{{serviceObj.get('duration')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Amount</label>
        <div class="col pull-left">:</div>
        <div class="col-sm-6">{{serviceObj.get('amount')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Visible to User</label>
        <div class="col pull-left">:</div>
        <div class="col-sm-6">{{serviceObj.get('visibility')}}</div>
      </div>
    </div>
  </div>
</div>