<div class="variant-card-container" [ngClass]='{"selected": index === selectedIndex}' (click)="variantCardClick()">
  <div class="flex relative">
    <div class="totalSP">{{ regimenInfo.price }} </div>
    <div class="noOfProducts">{{ regimenInfo.noOfProducts }} products</div>
    <img *ngIf="index === selectedIndex" src="/assets/images/variant-selected.svg" alt="" class="tick">
  </div>
  <div class="flex">
    <img src="/assets/images/new-tick.svg" alt="" class="icon">
    <p class="info">{{ regimenInfo.products }}</p>
  </div>
  <ng-container *ngFor="let info of regimenInfo.otherInfo">
    <div class="flex">
      <img src="/assets/images/new-tick.svg" alt="" class="icon">
      <p class="info">{{ info }}</p>
    </div>
  </ng-container>
  <!-- <div class="read-more">Read more</div> -->
</div>