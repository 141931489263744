import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { AppConfig } from 'src/app/app.config';
import { Broadcaster } from 'src/components/broadcaster';
import { ConnectionService } from 'src/services/connection-service';
import { ClipboardService } from 'src/services/copyToClipboard-service';
import { WindowRefService } from 'src/services/window-ref-service';

@Component({
  selector: 'order-info-user-data',
  standalone: true,
  imports: [CommonModule, MomentModule],
  templateUrl: './order-info-user-data.component.html',
  styleUrl: './order-info-user-data.component.scss',
})
export class OrderInfoUserDataComponent {
  @Input('orderObj') orderObj: any;
  @Input('parseOrderObj') parseOrderObj: any;
  @Input('user') user: any;

  constructor(
    private clipBoardService: ClipboardService,
    private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
    private window: WindowRefService) { }

  copyToClipboard(): void {
    this.clipBoardService.copy(this.user?.get('MobileNumber'));
    this.broadcaster.broadcast('NOTIFY', {
      message: 'copied to clipboard',
      type: this.appConfig.Shared.Toast.Type.SUCCESS,
    });
  }

  callUserThroughExotel(): void {
    const number = this.user?.get('MobileNumber');
    this.conn.callThroughExotel(number, 'newSalesPage-order')
      .then(() => this.broadcaster.broadcast('NOTIFY', {
        message: 'Connecting',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      }))
      .catch((error: any) => this.broadcaster.broadcast('NOTIFY', {
        message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      }));
  }

  activeContactNumber(): void {
    const confirmationText = 'Are you sure to mark this number as active?';
    if (confirm(confirmationText)) {
      try {
        this.parseOrderObj.set('activeContactNumber', this.user?.get('MobileNumber'));
        this.parseOrderObj.save();
        this.broadcaster.broadcast('NOTIFY', {
          message: 'Contact marked as active',
          type: this.appConfig.Shared.Toast.Type.SUCCESS,
        });
        this.window.nativeWindow.location.reload();
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    }
  }
}
