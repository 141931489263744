import { Component, OnDestroy, ViewChild } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { RequestQueryPayload, Table } from 'api-client';
import { AgGridAngular } from 'ag-grid-angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { WindowRefService } from '../../../services/window-ref-service';
import { ConnectionService } from '../../../services/connection-service';
import { ListActionCellComponent } from './list-action-cell/list-action-cell.component';
import { ListCompleteActionCellComponent } from './list-complete-action-cell/list-complete-action-cell.component';

@Component({ selector: 'list', templateUrl: './list.html' })
export class ListComponent implements OnDestroy {
  subscriptions: Array<Subscription> = [];
  gridApi: GridApi;
  dataSource: any;
  components: any;
  columnDefs: any;
  filter: any = {};
  ui: any;
  count: any;
  @ViewChild('agGrid') agGrid: AgGridAngular;

  constructor(private conn: ConnectionService,
    private windowRef: WindowRefService,
    private route: ActivatedRoute) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  async ngOnInit(): Promise<any> {
    this.ui = {
      enableLanguageFilter: false,
      enableOperatorFilter: false,
      initialize: { language: false, operator: false },
      grid: { rowModelType: 'infinite', pageSize: 100 },
    };
    this.setUpGrid();
    this.subscriptions.push(this.route.queryParams.subscribe((params: Params) => {
      this.filter = JSON.parse(params.filter || '{}');
      this.ui.enableLanguageFilter = params.languageFilter === 'true';
      this.ui.enableOperatorFilter = params.operatorFilter === 'true';
      this.ui.initialize.language = !this.ui.enableLanguageFilter;
      this.ui.initialize.operator = !this.ui.enableOperatorFilter;
      this.reset();
    }));
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any): Promise<void> => {
        const data = await this.loadMore(params);
        if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();
        params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Actions',
      field: '_id',
      width: 80,
      cellRenderer: ListActionCellComponent,
    }, {
      headerName: 'Status',
      field: 'state',
      width: 100,
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        if (!params.data) {
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv.innerHTML = params.value;
        return eDiv;
      },
    }, {
      headerName: 'Event Time',
      field: 'originalEventTime',
      width: 150,
      cellRenderer: (params: any): any => {
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        if (!params.data) {
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv.innerHTML = moment(params.value).format('DD MMM YY, HH:mm A') ?? '-';
        return eDiv;
      },
    }, {
      headerName: 'Type',
      field: 'MessageBody',
      width: 200,
    }, {
      headerName: 'Params',
      field: 'MessageAttribute',
      width: 200,
      editable: true,
      valueGetter: (params: any): string => JSON.stringify(params.data?.MessageAttribute || {}),
    }, {
      headerName: 'Response',
      field: 'failureResponse',
      flex: 1,
      editable: true,
    }, {
      headerName: '⚠️',
      field: '_id',
      width: 50,
      cellRenderer: ListCompleteActionCellComponent,
    }];
  }

  private async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.Event> = {
      where: { ...this.filter },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
    };

    let data;
    [data, { count: this.count }] = await Promise.all([
      this.conn.findFailedEvents(requestPayload)
        .then((result: Array<any>) => result.map((each_: any) => {
          const each = each_;
          const messageAttribute = {};
          Object.keys(each.MessageAttribute)
            .forEach((key: string) => (messageAttribute[key] = each.MessageAttribute[key].StringValue));
          each.MessageAttribute = messageAttribute;
          return each;
        })),
      this.conn.countFailedEvents(requestPayload),
    ]);
    return data;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  resetFilters(): void {
    this.filter = {};
    this.reset();
  }

  updateLanguageFilter(languages: Array<string>): void {
    if (languages.length) {
      this.filter['MessageAttribute.language.StringValue'] = { $in: languages };
    } else {
      delete this.filter['MessageAttribute.language.StringValue'];
    }
    this.ui.initialize.language = true;
    this.reset();
  }

  updateOperatorFilter(operators: Array<unknown>): void {
    if (operators.length) {
      this.filter['MessageAttribute.operatorId.StringValue'] = { $in: operators.map((operator: any) => operator.id) };
    } else {
      delete this.filter['MessageAttribute.operatorId.StringValue'];
    }
    this.ui.initialize.operator = true;
    this.reset();
  }

  async reset(): Promise<any> {
    if (!this.gridApi || !this.ui.initialize.language || !this.ui.initialize.operator) {
      return;
    }
    this.gridApi.setGridOption('datasource', this.dataSource);
  }
}
