import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../../app/app.config';
import { WindowRefService } from '../../../../services/window-ref-service';

@Component({
  selector: 'view',
  templateUrl: './view.html',
  styleUrls: ['./view.scss'],
})
export class ViewComponent implements OnInit {
  triggerByKeywordId: string;
  keywordTriggerTree: any = '';

  constructor(private route: ActivatedRoute,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    windowRef: WindowRefService) { }

  async ngOnInit(): Promise<any> {
    this.triggerByKeywordId = this.route.snapshot.params.id;
    this.keywordTriggerTree = await this.conn.findKeywordTriggerTreeById(this.triggerByKeywordId);
  }
}
