import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CouponsModule } from './coupons';
import { EcomComponent } from './ecom.component';

const ROUTES: Routes = [
  {
    path: 'ecom',
    component: EcomComponent,
    loadChildren: (): any => CouponsModule,
  },
];

@NgModule({ imports: [
  RouterModule.forChild(ROUTES),
],
declarations: [EcomComponent],
exports: [EcomComponent] })
export class EcomModule {}
