
<div class="col-xs-12">
  <label class="col-xs-4 p-0 mt-0 fontR">{{title}}<i class="fa fa-plus-square-o ml-2 font17" (click)="addProduct()"></i></label>
  <div class="col-xs-12 mt-2 p-0" *ngFor="let product of products; let i = index;">
    <div class="col-xs-12" [ngStyle]="{ 'color': product.product && (product.product.get('isTablet') || product.product.get('isAntibiotic')) ? 'green' : '' }">
      <regimen-edit-product [product]="product" name="{{name}}_{{i}}_{{variant}}" (onProductUpdate)="productChanged()" [hideNotNecessaryThings]="hideNotNecessaryThings" [isProductAdd]="[isProductAdd]"></regimen-edit-product>
      <div class="mt-3 px-2"><em class="fa fa-minus-square-o font17" (click)="deleteProduct(product)"></em><em class="fa fa-caret-up font25 pl-3" (click)="moveProductUp(i)" title="Move Product Upwards"></em><em class="fa fa-caret-down font25 pl-3" (click)="moveProductDown(i)" title="Move Product Downwards"></em></div>
    </div>
  </div>
</div>
