/* eslint-disable no-case-declarations */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InputType } from 'typings/client/input';
import { ApiConnector, Table } from 'api-client';
import { CollapseComponent } from '../../../components/collapse';
import { ConnectionService } from '../../../services/connection-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';
import { Broadcaster } from '../../../components/broadcaster';

@Component({
  selector: 'internal-team',
  templateUrl: 'internal-team.html',
})
export class InternalTeamComponent implements OnInit {
  window: any;
  roles: Array<string> = [];
  currentUser: any;
  resetDoctorFilter: boolean = false;
  doctorFilter: Array<string>;
  teams: Array<any> = [];
  currentDoctor: string;
  currentSelectedDoctors: string[] = [];
  selectedTeam: any;
  selectedDoctor: any;
  teamName: string = '';
  teamType: string = '';
  teamOptions: Array<InputType.SelectOption> = [
    { display: 'DERMAT TEAM', value: 'DERMAT_TEAM', disabled: false },
    { display: 'CHAT SUPPORT', value: 'CHAT_SUPPORT', disabled: false },
    { display: 'SALES', value: 'SALES', disabled: false },
    { display: 'INITIAL TEAM', value: 'INITIAL_TEAM', disabled: false },
    { display: 'COURIER', value: 'COURIER', disabled: false }];
  managerName: string = '';
  languageOptions: Array<InputType.SelectOption> = [];
  selectedLanguages: Array<string> = [];
  languagePreference: string = '';
  teamFilter: Array<string> = [];
  teamsCopy: Array<any> = [];

  constructor(
    private router: Router,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private broadcaster: Broadcaster,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.conn.getCurrentUser();
    this.roles = this.storage.getJsonValue('userRoles') || [];
    this.teams = await ApiConnector.find(Table.InternalTeam, {
      where: { active: true },
      include: ['extraMembers', 'members', 'lead'],
      project: [
        'extraMembers.username' as 'extraMembers',
        'members.username' as 'members',
        'name',
        'extraMembers.DoctorDisplayName' as 'extraMembers',
        'members.DoctorDisplayName' as 'members',
        'members.username' as 'members',
        'lead.DoctorDisplayName' as 'lead',
        'lead.username' as 'lead',
        'type',
        'languages',
      ],
    });
    this.teamsCopy = this.teams;
    if (!this.teams.length) {
      this.selectedTeam = new Table.InternalTeam();
    } else {
      this.selectedTeam = this.teamsCopy[0];
      this.teamName = this.selectedTeam.get('name');
      this.teamType = this.selectedTeam.get('type');
      this.selectedLanguages = this.selectedTeam.get('languages');
      this.managerName = this.selectedTeam.get('lead');
    }
    const langoptions = Object.values(this.appConfig.Shared.Languages);
    langoptions.forEach((each: string) => this.languageOptions.push({ display: each, value: each }));
  }

  async removeTeam(index: any): Promise<void> {
    if (index >= 0) {
      const team = this.teams[index];
      this.teams.splice(index, 1);
      await team.destroy();
      this.teamsCopy = this.teams;
    }
    this.toggleSelection(this.teamsCopy[0]);
  }

  toggleSelection(teamName: any): void {
    this.selectedTeam = teamName;
    this.teamName = this.selectedTeam.get('name');
    this.teamType = this.selectedTeam.get('type');
    if (this.selectedTeam?.get('languages')) {
      this.selectedLanguages = this.selectedTeam.get('languages');
    } else {
      this.selectedLanguages = [];
    }
    this.managerName = this.selectedTeam.get('lead');
  }

  async addTeam(): Promise<void> {
    this.teamFilter = [];
    const team = new Table.InternalTeam();
    team.set('active', true);
    this.teams.push(team);
    this.teamsCopy = this.teams;
    this.teamOptions = this.teamOptions.map((each: any) => {
      if (each.disabled !== false) {
        return { ...each, disabled: !each.disabled };
      }
      return each;
    });
    this.toggleSelection(team);
  }

  toggleFilter(team: any): void {
    const index = this.teamFilter.indexOf(team.value);
    if (index !== -1) {
      this.teamFilter.splice(index, 1);
      if (!this.teamFilter.length) {
        this.teamsCopy = this.teams;
        this.toggleSelection(this.teamsCopy[0]);
        return;
      }
    } else {
      this.teamFilter.push(team.value);
    }
    this.teamsCopy = this.teams.filter((each: any) => this.teamFilter.includes(each.get('type')));
    this.toggleSelection(this.teamsCopy[0]);
  }

  onSelectInternalUser(doctorFilter: any, memberType: string): void {
    this.resetDoctorFilter = false;
    this.doctorFilter = doctorFilter;
    this.currentDoctor = doctorFilter.name;
    if (!this.currentSelectedDoctors) {
      this.currentSelectedDoctors = [];
    }
    switch (memberType) {
      case 'extraMembers':
        const extraMembers: Array<Object> = this.selectedTeam.get('extraMembers');
        if (extraMembers) {
          const existingExtraMemberIds = extraMembers.map((member: any) => member.id);
          if (!existingExtraMemberIds.includes(doctorFilter.object.id)) {
            extraMembers.push(doctorFilter.object);
            this.selectedTeam.set('extraMembers', extraMembers);
          }
        } else {
          this.selectedTeam.set('extraMembers', [doctorFilter.object]);
        }
        break;
      case 'members':
        const members: Array<Object> = this.selectedTeam.get('members');
        if (members) {
          const existingMemberIds = members.map((member: any) => member.id);
          if (!existingMemberIds.includes(doctorFilter.object.id)) {
            members.push(doctorFilter.object);
            this.selectedTeam.set('members', members);
          }
        } else {
          this.selectedTeam.set('members', [doctorFilter.object]);
        }
        break;
      case 'manager':
        this.selectedTeam.set('lead', doctorFilter.object);
        this.managerName = doctorFilter.object.get('DoctorDisplayName');
        break;
      default:
    }
  }

  removeUserFromList(user: any, memberType: string): void {
    switch (memberType) {
      case 'extraMembers':
        const afterDeleteExtraMember = this.selectedTeam.get('extraMembers')
          .filter((each: any) => user.get('username') !== each.get('username'));
        this.selectedTeam.set('extraMembers', afterDeleteExtraMember);
        break;
      case 'members':
        const afterDeleteMember = this.selectedTeam.get('members')
          .filter((each: any) => user.get('username') !== each.get('username'));
        this.selectedTeam.set('members', afterDeleteMember);
        break;
      case 'manager':
        this.selectedTeam.set('lead', null);
        this.managerName = '';
        break;
      default:
    }
  }

  addToSelectedLanguages(selectedLanguage: string): void {
    if (!this.selectedLanguages?.includes(selectedLanguage)) {
      this.selectedLanguages.push(selectedLanguage);
      this.selectedTeam.set('languages', this.selectedLanguages);
    }
  }

  removeLanguage(language: string): void {
    const afterDeletingSelectedLanguage = this.selectedTeam.get('languages')
      .filter((each: string) => language !== each);
    this.selectedTeam.set('languages', afterDeletingSelectedLanguage);
    this.selectedLanguages = afterDeletingSelectedLanguage;
  }

  checkIfTeamCanBeSaved(): boolean {
    if (!this.selectedTeam.get('name')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Team Name can\'t be empty', type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    if (!this.selectedTeam.get('type')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Please choose a team type', type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    if (!this.selectedTeam.get('lead')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Team Manager can\'t be empty', type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    if (!this.selectedTeam.get('members').length) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Team should have members, please add team members',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    if (!this.selectedTeam.get('languages').length) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Team should have atleast one language',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    return true;
  }

  async saveTeam(): Promise<void> {
    if (this.roles.includes('admin') || this.roles.includes('adminDoctor')) {
      try {
        this.selectedTeam.set('name', this.teamName);
        this.selectedTeam.set('type', this.teamType);
        if (!this.checkIfTeamCanBeSaved()) return;
        await this.selectedTeam.save();
        this.broadcaster.broadcast('NOTIFY', { message: 'Saved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      } catch (error: any) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    } else {
      this.broadcaster.broadcast('NOTIFY', { message: 'Permisson Denied', type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
