import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { Broadcaster } from '../../../../components/broadcaster';
import { SMSDialog } from '../../../../components/SMS';
import { ChatType } from '../../../../../typings/client/chat';
import { SupportChatViewComponent } from '../support-chat-view/support-chat-view.component';
import { LocalStorage } from '../../../../services/local-storage-service';

@Component({ selector: 'chat-sidebar', templateUrl: './chat-sidebar.html', styleUrls: ['./chat-sidebar.scss'] })
export class ChatSidebarComponent implements AfterViewInit {
  // inputs
  @Input() patient: any;

  // variables
  internalUser: any;
  internalUserRoles: Array<string>;
  cashAmountToAdd: number;
  cashEntryType: number = 1;
  walletBalance: number = 0;
  referredBy: string;
  editDisplayConfig: { [key: string]: ChatType.EditConfig };
  displayConfig: { [key: string]: number };
  EDITABLE_TYPE: { BOOLEAN: number, DROP_DOWN: number, INPUT: number, DATE: number; LIST: number; } = {
    INPUT: 0,
    BOOLEAN: 1,
    DROP_DOWN: 2,
    DATE: 3,
    LIST: 4,
  };
  DISPLAY_TYPE: { LIST: number } = { LIST: 1 };
  consultationSessionData: Array<{ key: string, value: any, originalKey: string, selectedValue?: any }> = [];
  consultationSession: any;
  showConsultationSession: boolean = false;
  chatViewLink: SafeResourceUrl;
  supportTicketView: boolean;
  baseUrlForWebApp: string;
  showFilter: boolean;
  patientData: Array<{ key: string, value: any, originalKey: string, selectedValue?: any }>;

  @ViewChild(SupportChatViewComponent, { static: false }) supportQueryHistoryComponent: SupportChatViewComponent;
  @ViewChild('newChatView', { static: true }) newChatView: ElementRef;

  constructor(private connectionService: ConnectionService,
    public appConfig: AppConfig,
    private dialog: MatDialog,
    private dom: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private storage: LocalStorage,
    private broadcaster: Broadcaster) {
  }

  async ngOnInit(): Promise<void> {
    this.internalUser = this.connectionService.getCurrentUser();
    this.supportTicketView = this.router.url.includes('ticket');
    this.internalUserRoles = this.storage.getJsonValue('userRoles');
    this.initEditDisplayConfig();
    this.getBaseIframeUrl();
    this.reloadChat();
    await Promise.all([
      this.getPatientData(),
      this.connectionService.fetchCashBalance(this.patient.get('username'))
        .then((walletBalance: number) => this.walletBalance = Number(walletBalance.toFixed(2))),
      this.connectionService.getReferredByUsernameForUserId(this.patient.id)
        .then((referredBy: string) => this.referredBy = referredBy || '-'),
      this.getConsultationSessionData(),
    ]);
  }

  ngAfterViewInit(): void {
    this.getWebIframeUrl('chat');
    if (this.chatViewLink) this.newChatView.nativeElement.onload = (): void => this.sendCredentialsToIframe();
  }

  sendCredentialsToIframe(): void {
    const data = {
      'Parse/myAppId/currentUser': this.storage.getValue('Parse/myAppId/currentUser'),
    };
    this.newChatView.nativeElement.contentWindow.postMessage(JSON.stringify({ key: 'storage', data }), '*');
  }

  getPatientData(): void {
    this.patientData = this.getDisplayDataFromObjectWithDefaultFields(
      this.patient,
      [
        'userOnlineStatusTimeStamp',
        'isOnline',
        'disableCOD',
        'afterSaveUpdateAcl',
        'createdAt',
        'updatedAt',
        'Notification',
        'ACL',
        'objectId',
        'InstantCheckupTaken',
        'allocatedDoctor',
        'pagesVisited',
        'firstOrderDeliveredDate',
        'lastOrderDeliveredDate',
        'boostProductSelectedDate',
        'appDeletedTime',
      ],
      { alternateNumber: '',
        PatientName: '',
        disableCOD: false,
        refreshDate: undefined,
        disablePaymentGateway: [],
        isFixedDoctorCase: undefined });
  }

  async removeMessageFromDND(): Promise<void> {
    if (!this.patient.get('username')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Username not present.' });
      return;
    }
    try {
      const message = await this.connectionService.removeNumberFromDND(this.patient.get('username'));
      this.broadcaster.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.ERROR });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  sendSMS(): void {
    this.dialog.open(SMSDialog, { data: { userMobileNumber: this.patient.get('MobileNumber'),
      operator: this.internalUser.get('username'),
      userId: this.patient.get('username') } });
  }

  showConsultationSessions(): void {
    this.showConsultationSession = !this.showConsultationSession;
    if (this.showConsultationSession) {
      this.chatViewLink = false;
      this.showFilter = false;
    } else {
      this.reloadChat();
    }
  }

  async reloadIframe(): Promise<void> {
    if (this.supportTicketView) {
      this.supportQueryHistoryComponent.refreshChat();
      await this.supportQueryHistoryComponent.resetSupportTicketData();
    } else {
      this.reloadChat();
    }
  }

  updatePatientInfo():void {
    const promises: Array<Promise<any>> = [];
    this.consultationSessionData.forEach((item: any) => {
      if (this.consultationSession.get(item.originalKey) !== item.value) {
        this.consultationSession.set(item.originalKey, item.value);
      }
    });
    this.patientData.forEach((item: any) => {
      if (this.patient.get(item.originalKey) !== item.value) {
        this.patient.set(item.originalKey, item.value);
      }
    });
    if (this.patient.dirtyKeys().includes('alternateNumber') && this.patient.get('contactDetails')) {
      this.patientData.filter((item: any) => (item.originalKey === 'contactDetails'))[0].value.alternateNumber = this.patientData
        .filter((item: any) => (item.originalKey === 'alternateNumber'))[0].value;
    }
    if (this.consultationSession?.dirtyKeys().length) promises.push(this.consultationSession.save());
    if (this.patient.dirtyKeys().length) {
      const userChangedData: any = { id: this.patient.id };
      this.patientData
        .filter((item: any) => this.patient.dirtyKeys().includes(item.originalKey))
        .map((item: any) => (userChangedData[item.originalKey] = item.value));
      if (userChangedData.alternateNumber) {
        userChangedData.alternateNumber = userChangedData.alternateNumber.trim();
        const alternateNumber = this.patientData.find((each: any) => each.originalKey === 'alternateNumber');
        alternateNumber.value = userChangedData.alternateNumber;
      }
      promises.push(this.connectionService.updateUserData(userChangedData));
    }
    Promise.all(promises)
      .then(() => this.broadcaster.broadcast('NOTIFY', { message: 'User Details Updated.' }))
      .catch((err: any) => {
        this.broadcaster.broadcast('NOTIFY', { message: err.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      });
  }

  async pullFromAccount(phoneNumber: string): Promise<void> {
    if (!phoneNumber || phoneNumber?.trim()?.length < 10) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Enter a Valid Numbers', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    try {
      await this.connectionService.pullUserDataFromDifferentAccount(this.patient.get('MobileNumber'), phoneNumber);
      this.broadcaster.broadcast('NOTIFY', { message: 'Pull complete.', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async swapMobileNumbers(phoneNumber: string): Promise<any> {
    if (!phoneNumber || phoneNumber?.trim()?.length < 10) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Enter a Valid Numbers', type: this.appConfig.Shared.Toast.Type.ERROR });
      return Promise.resolve();
    }
    try {
      await this.connectionService.swapPrimaryNumbers(this.patient.get('MobileNumber'), phoneNumber);
      this.broadcaster.broadcast('NOTIFY', { message: 'Mobile Number Swapped. Ask user to login again' });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.toString(),
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
    return Promise.resolve();
  }

  updateCashEntryType(type: string): void {
    this.cashEntryType = parseInt(type, 10);
  }

  async addCashEntry(event_: any): Promise<void> {
    const event = event_;
    event.target.disabled = true;
    if (!this.cashAmountToAdd) {
      event.target.disabled = false;
      this.broadcaster.broadcast('NOTIFY', { message: 'Enter valid amount', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const payload = {
      type: this.cashEntryType,
      amount: this.cashAmountToAdd,
      userObjectId: this.patient.id,
      referenceType: this.appConfig.Shared.CashTransaction.referenceType.PROMOTIONAL,
    };
    try {
      await this.connectionService.addCashEntry(payload);
      this.broadcaster.broadcast('NOTIFY', { message: 'Cash Added', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.cashAmountToAdd = 0;
    } catch (err) {
      this.broadcaster.broadcast('NOTIFY', { message: err.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
    event.target.disabled = false;
  }

  async claimGift(code: string): Promise<void> {
    if (!code) return;
    const payload = { code: code.toUpperCase(), userId: this.patient.id };
    try {
      await this.connectionService.applyGiftCard(payload);
      this.broadcaster.broadcast('NOTIFY', { message: 'Amount added to user account', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (err) {
      this.broadcaster.broadcast('NOTIFY', { message: err.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  initEditDisplayConfig(): void {
    this.displayConfig = {
      'Marketing Tags': this.DISPLAY_TYPE.LIST,
      'Disable Payment Gateway': this.DISPLAY_TYPE.LIST,
    };
    this.editDisplayConfig = {
      Age: {
        editType: this.EDITABLE_TYPE.INPUT,
      },
      Gender: {
        editType: this.EDITABLE_TYPE.DROP_DOWN,
        options: [{ key: 'Male', value: 'Male' }, { key: 'Female', value: 'Female' }],
      },
      'Patient Name': {
        editType: this.EDITABLE_TYPE.INPUT,
      },
      'Patient Email': {
        editType: this.EDITABLE_TYPE.INPUT,
      },
      'Alternate Number': {
        editType: this.EDITABLE_TYPE.INPUT,
      },
      'Disable Payment Gateway': {
        editType: this.EDITABLE_TYPE.LIST,
        options: Object.keys(this.appConfig.Shared.Payment.PaymentSource).map((key: string) => ({ value: key, key })),
      },
      'Language Preference': {
        editType: this.EDITABLE_TYPE.DROP_DOWN,
        options: [
          { value: 'English', key: this.appConfig.Shared.Languages.EN },
          { value: 'Hindi', key: this.appConfig.Shared.Languages.HI },
          { value: 'Kannada', key: this.appConfig.Shared.Languages.KN },
          { value: 'Telugu', key: this.appConfig.Shared.Languages.TE },
          { value: 'Tamil', key: this.appConfig.Shared.Languages.TA },
        ],
      },
      'Communication Language Preference': {
        editType: this.EDITABLE_TYPE.DROP_DOWN,
        options: [
          { value: 'English', key: this.appConfig.Shared.Languages.EN },
          { value: 'Hindi', key: this.appConfig.Shared.Languages.HI },
          { value: 'Kannada', key: this.appConfig.Shared.Languages.KN },
          { value: 'Telugu', key: this.appConfig.Shared.Languages.TE },
          { value: 'Tamil', key: this.appConfig.Shared.Languages.TA },
          { value: 'Marathi', key: this.appConfig.Shared.Languages.MR },
          { value: 'Malayalam', key: this.appConfig.Shared.Languages.MA },
          { value: 'Bengali', key: this.appConfig.Shared.Languages.BN },
        ],
      },
      'Refresh Date': {
        editType: this.EDITABLE_TYPE.DATE,
      },
      'Disable C O D': {
        editType: this.EDITABLE_TYPE.BOOLEAN,
      },
      'Main Concern Mandatory': {
        editType: this.EDITABLE_TYPE.BOOLEAN,
      },
      'Is Fixed Doctor Case': {
        editType: this.EDITABLE_TYPE.BOOLEAN,
      },
    };
  }

  async getConsultationSessionData(): Promise<void> {
    [this.consultationSession] = await this.connectionService.findConsultationSession({
      where: { username: this.patient.get('username') },
      limit: 1,
      descending: 'createdAt',
    });
    const skipFields = ['createdAt', 'updatedAt', 'username', 'objectId', 'Welcome', 'user', 'ACL'];
    this.consultationSessionData = this.getDisplayDataFromObjectWithDefaultFields(this.consultationSession || {}, skipFields);
  }

  getDisplayDataFromObjectWithDefaultFields(
    object: any, skipFields: Array<string>,
    defaultValue: object = {}): Array<{ key: string, value: any, originalKey: string }> {
    const json = Object.assign(defaultValue, JSON.parse(JSON.stringify(object)));
    return Object.keys(json)
      .filter((key: string) => !skipFields.includes(key))
      .map((key: string) => {
        let tempKey = key;
        if (tempKey.includes('Private')) tempKey = tempKey.split('Private')[1];
        tempKey = tempKey
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (str: string) => str.toUpperCase());
        return { key: tempKey, value: json[key], originalKey: key };
      });
  }

  removeOption(values: Array<string>, index: number): void {
    values.splice(index, 1);
  }

  onOptionAdd(values: Array<string>, item_: any): void {
    const item = item_;
    if (!item.selectedValue) {
      return;
    }
    values.push(item.selectedValue);
    delete item.selectedValue;
  }

  reloadChat(): void {
    this.showConsultationSession = false;
    this.showFilter = false;
    this.getWebIframeUrl('chat');
  }

  getWebIframeUrl(type: 'chat' | 'ticket' | 'order' | 'consultation', id?: string): any {
    switch (type) {
      case 'chat': {
        this.chatViewLink = this.dom.bypassSecurityTrustResourceUrl(`${this.baseUrlForWebApp
        }/user/checkup?loginType=token&username=${this.patient.get('username')}`);
        break;
      }
      case 'ticket': {
        this.supportQueryHistoryComponent.refreshChat();
        this.supportQueryHistoryComponent.resetSupportTicketData();
        break;
      }
      case 'order': {
        this.chatViewLink = this.dom.bypassSecurityTrustResourceUrl(
          `${this.connectionService.getWebAppUrl()}/chatV2/${id}?userId=${this.patient.id}&type=order`);
        break;
      }
      case 'consultation': {
        this.chatViewLink = this.dom.bypassSecurityTrustResourceUrl(
          `${this.connectionService.getWebAppUrl()}/chatV2/${id}?userId=${this.patient.id}&type=consultationSession`);
        break;
      }
      default:
    }
  }

  getBaseIframeUrl(): void {
    this.baseUrlForWebApp = this.connectionService.getWebAppUrl();
  }
}
