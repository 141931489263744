
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Pincode file</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="col-xs-12 col-md-10">
      <label class="col-xs-12 mt-0">Select Courier Name</label>
      <select class="col-xs-8" [(ngModel)]="courierName" required="true">
        <option *ngFor="let courier of couriers;" value="{{courier.value}}">{{courier.name}}</option>
      </select>
    </div>
    <div class="col-xs-12 col-md-10"> 
      <label class="col-xs-12">Choose File</label>
      <input class="col-xs-8" type="file" ng2FileSelect="ng2FileSelect" [uploader]="uploader"/>
    </div>
  </div>
  <div class="modal-footer">
    <button class="pull-right" (click)="uploadFile(uploader?.queue[0])" [disabled]="!courierName || !uploader?.queue?.length || uploader?.queue[0].isReady || uploader?.queue[0].isUploading || uploader?.queue[0].isSuccess">Upload</button>
  </div>
</mat-dialog-content>