import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { InstantCheckupComponent } from './instant-checkup.component';
import { InstantCheckupViewComponent } from './view/view.component';
import { DirectivesModule } from '../../components/directives.module';

const ROUTES: Routes = [
  { path: 'user/instantCheckup/:id', component: InstantCheckupComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    MomentModule,
    CommonModule,
    NgxImageZoomModule,
  ],
  declarations: [InstantCheckupComponent, InstantCheckupViewComponent],
  exports: [InstantCheckupComponent],
})
export class InstantCheckupModule { }
