
<div class="flex-box" *ngIf="teams && selectedTeam">
  <div class="flex-heading">
    <div class="list-header col-xs-12"><span class="pR25">Internal Teams</span>
      <div class="pull-right">
        <button (click)="saveTeam()">Save</button>
      </div>
    </div>
  </div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header" style="display: block;">
          <collapse [title]="appConfig.Shared.ListSideBar.Types.TEAMS" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.TEAMS">
            <button class="bdr-rad-15 btn-xxs mr-2 mb-2" *ngFor="let team of teamOptions;" (click)="toggleFilter(team); team.disabled = !team.disabled" [ngClass]="{'btn-primary': team.disabled, 'btn-primary-outline': !team.disabled }">{{team.display}}</button>
          </collapse>
        </div>
        <div class="header">
          <div class="title">Team List</div>
          <div class="fa fa-plus-square-o pointer" (click)="addTeam()"></div>
        </div>
        <div class="content">
          <div class="flex justifySB mt-3" *ngFor="let team of teamsCopy; let i = index;">
            <div>
              <button class="btn-xs bdr-rad-15 mr-2" (click)="toggleSelection(team)" [ngClass]="{'btn-primary': team.get('name') == selectedTeam?.get('name'), 'btn-primary-outline': team.get('name') !== selectedTeam?.get('name') }">{{team.get("name")}}</button><i class="fa fa-trash-o pointer ml-3" *ngIf="roles.includes('admin') || roles.includes('adminDoctor')" (click)="removeTeam(i)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="list-content">
        <div class="col-xs-12 mb-4">
          <div class="col-xs-6 pl-0">
            <label>Team Name</label>
            <div class="col-xs-12 pl-0">
              <input class="form-control" type="text" [(ngModel)]="teamName"/>
            </div>
          </div>
          <div class="col-xs-6 pl-0">
            <label>Team Type</label>
            <div class="col-xs-12 pl-0">
              <select class="form-control" [(ngModel)]="teamType" name="teamType" style="font-weight: 400">
                <option *ngFor="let option of teamOptions" [selected]="option.value == teamType" [value]="option.value">{{option.display}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-xs-12 mb-4">
          <div class="col-xs-6 pl-0">
            <label>Preferred Languages</label>
            <div class="col-xs-12 pl-0">
              <select class="form-control" [(ngModel)]="languagePreference" name="languagePreference" style="font-weight: 400" (change)="addToSelectedLanguages($event.target.value)">
                <option *ngFor="let option of languageOptions" [selected]="option.value == languagePreference" [value]="option.value">{{option.display}}</option>
              </select>
            </div>
            <div class="col-xs-12 flex flex-wrap">
              <div class="badge mr-3 mt-3" *ngFor="let language of selectedTeam.get('languages')"><span>{{language}}</span><i class="fa fa-trash-o pointer ml-3" (click)="removeLanguage(language)"></i></div>
            </div>
          </div>
          <div class="col-xs-6 pl-0" *ngIf="teamType === 'CUSTOMER_SUPPORT' && selectedLanguages.length >= 1">
            <label>Team Manager</label>
            <div class="col-xs-12 pl-0" *ngIf="!managerName">
              <input-internal-user-select (onSelectInternalUser)="onSelectInternalUser($event, 'manager')" [type]="appConfig.Shared.User.Type.OPERATOR" [languages]="selectedTeam.get('languages')"></input-internal-user-select>
            </div>
            <div class="col-xs-12 flex flex-wrap">
              <div class="badge mr-3 mt-3" *ngIf="selectedTeam.get('lead')"><span>{{selectedTeam.get('lead')?.get('DoctorDisplayName') || selectedTeam.get('lead')?.get('username')}}</span><i class="fa fa-trash-o pointer ml-3" (click)="removeUserFromList(doctor, 'manager')"></i></div>
            </div>
          </div>
          <div class="col-xs-6 pl-0" *ngIf="teamType === 'DERMAT_TEAM' && selectedLanguages.length >= 1">
            <label>Team Manager</label>
            <div class="col-xs-12 pl-0" *ngIf="!managerName">
              <input-internal-user-select (onSelectInternalUser)="onSelectInternalUser($event, 'manager')" [type]="appConfig.Shared.User.Type.DOCTOR" [languages]="selectedTeam.get('languages')"></input-internal-user-select>
            </div>
            <div class="col-xs-12 flex flex-wrap">
              <div class="badge mr-3 mt-3" *ngIf="selectedTeam.get('lead')"><span>{{selectedTeam.get('lead')?.get('DoctorDisplayName') || selectedTeam.get('lead')?.get('username')}}</span><i class="fa fa-trash-o pointer ml-3" (click)="removeUserFromList(doctor, 'manager')"></i></div>
            </div>
          </div>
        </div>
        <section class="mb-4" *ngIf="teamType === 'CUSTOMER_SUPPORT' && selectedLanguages.length >= 1">
          <div class="col-xs-12">
            <label>Operators</label>
            <div class="col-xs-12 pl-0">
              <input-internal-user-select class="col-xs-4 pl-0" (onSelectInternalUser)="onSelectInternalUser($event, 'members')" [type]="appConfig.Shared.User.Type.OPERATOR" [userType]="appConfig.Shared.User.UserType.CHAT_SUPPORT" [languages]="selectedTeam.get('languages')"></input-internal-user-select>
              <div class="col-xs-12">
                <p *ngFor="let doctor of currentSelectedDoctors">{{ doctor }}</p>
              </div>
            </div>
            <div class="col-xs-12 flex flex-wrap">
              <div class="badge mr-3 mt-3" *ngFor="let operator of selectedTeam.get('members')"><span>{{operator?.get('username')}}</span><i class="fa fa-trash-o pointer ml-3" (click)="removeUserFromList(operator, 'members')"></i></div>
            </div>
          </div>
        </section>
        <div class="col-xs-12 mb-4" *ngIf="teamType === 'DERMAT_TEAM' && selectedLanguages.length >= 1">
          <div class="col-xs-6 pl-0">
            <label>MBBS Doctors</label>
            <div class="col-xs-12 pl-0">
              <input-internal-user-select (onSelectInternalUser)="onSelectInternalUser($event, 'extraMembers')" [type]="appConfig.Shared.User.Type.DOCTOR" [userType]="appConfig.Shared.User.UserType.MBBS" [languages]="selectedTeam.get('languages')"></input-internal-user-select>
              <div class="col-xs-12">
                <p *ngFor="let doctor of currentSelectedDoctors">{{ doctor }}</p>
              </div>
            </div>
            <div class="col-xs-12 flex flex-wrap">
              <div class="badge mr-3 mt-3" *ngFor="let doctor of selectedTeam.get('extraMembers')"><span>{{doctor.get('DoctorDisplayName') || doctor?.get('username')}}</span><i class="fa fa-trash-o pointer ml-3" (click)="removeUserFromList(doctor, 'extraMembers')"></i></div>
            </div>
          </div>
          <div class="col-xs-6 pl-0">
            <label>Dermat Doctors</label>
            <div class="col-xs-12 pl-0">
              <input-internal-user-select (onSelectInternalUser)="onSelectInternalUser($event, 'members')" [type]="appConfig.Shared.User.Type.DOCTOR" [userType]="appConfig.Shared.User.UserType.DERMATOLOGIST" [languages]="selectedTeam.get('languages')"></input-internal-user-select>
              <div class="col-xs-12">
                <p *ngFor="let doctor of currentSelectedDoctors">{{ doctor }}</p>
              </div>
            </div>
            <div class="col-xs-12 flex flex-wrap">
              <div class="badge mr-3 mt-3" *ngFor="let doctor of selectedTeam.get('members')"><span>{{doctor.get('DoctorDisplayName') || doctor?.get('username')}}</span><i class="fa fa-trash-o pointer ml-3" (click)="removeUserFromList(doctor, 'members')"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
