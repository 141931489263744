import { v4 as uuid } from 'uuid';
import { Component, Input } from '@angular/core';
import { ControlContainer, UntypedFormControl, NgForm } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { AppConfig } from '../../../app/app.config';
import { InputType } from '../../../../typings/client/input';
import { ConnectionService } from '../../../services/connection-service';
import { InputHelperService } from '../../input/input-helper';

@Component({
  selector: 'canned-response-selection',
  templateUrl: './canned-response-selection.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm,
  }],
})
export class CannedResponseSelectionComponent {
  @Input('object')
  set onUpdateParseObject(object: any) {
    this.object = object;
  }
  @Input('user')
  set onUpdateUser(user: any) {
    this.language = user.get('languagePreference');
  }
  @Input('name')
  set onUpdateName(name: string) {
    this.name = name;
  }
  @Input('required') required: boolean = false;

  prefix: string;
  formController: UntypedFormControl = new UntypedFormControl();
  ui: any = {};
  name: string = uuid();
  language: string = 'en';
  object: any;
  parseValue: any;
  allOptions: Array<{ tags: Array<string>, title: string, id: string }> = [];
  options: Array<InputType.SelectOption> = [];
  selectedTags: Array<string> = [];

  static parameters: any = [AppConfig, ConnectionService, InputHelperService];
  constructor(public appConfig: AppConfig, private conn: ConnectionService) {
  }

  ngOnInit(): void {
    this.fetchOptions();
    this.formController.valueChanges
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.filterByTags();
      });
  }

  async addCannedResponse(): Promise<void> {
    this.object[this.name] ??= '';
    const languageString = await this.conn.getLanguageString(this.parseValue);
    this.object[this.name] += ` ${languageString.get(this.language) ?? languageString.get('en')}\n`;
  }

  private async fetchOptions(): Promise<void> {
    this.options = [];
    const payload: RequestQueryPayload<Table.CannedResponse> = {
      where: { mode: 'WITH_TEXT_REPLY', $or: [{ user: ApiConnector.getCurrentUser() }, { user: { $exists: false } }] },
      project: ['title', 'responseLanguageString', 'tags'],
      limit: 1000,
    };
    const cannedResponses = await this.conn.findCannedResponses(payload);
    this.allOptions = cannedResponses.map((cannedResponse: any) => ({
      id: cannedResponse.get('responseLanguageString').id,
      title: cannedResponse.get('title'),
      tags: cannedResponse.get('tags'),
    }));
    this.options = cannedResponses.map((cannedResponse: any): InputType.SelectOption => ({
      display: cannedResponse.get('title'),
      value: cannedResponse.get('responseLanguageString')?.id,
    }));
  }

  resetOptions(): void {
    this.options = this.allOptions.map((key: { tags: Array<string>, title: string, id: string }) => ({
      display: key.title,
      value: key.id,
    }));
  }

  filterByTags(): void {
    this.resetOptions();
    if (this.selectedTags.length) {
      this.options = this.allOptions.filter((key: { tags: Array<string>, title: string, id: string }) => (
        key.tags?.some((tag: string) => this.selectedTags.includes(tag)) || false
      )).map((key: { tags: Array<string>, title: string, id: string }) => ({
        display: key.title,
        value: key.id,
      }));
    }
  }
}
