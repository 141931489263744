import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant } from 'api-client';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Broadcaster } from '../../../../components/broadcaster';
import { SupportTicketHistoryComponent } from '../../../patient/support-ticket-history/support-ticket-history.component';
import { ChangeTicketAllocationComponent } from '../../../../components/change-ticket-allocation/change-ticket-allocation.component';

@Component({ selector: 'ticket-list', templateUrl: './ticket-list.html', styleUrls: ['./ticket-list.scss'] })
export class TicketListComponent {
  @Input('user')
  set userFunction(user: any) {
    if (!user || !user.id) {
      this.user = undefined;
      return;
    }
    this.user = user;
    this.fetchTickets();
  }
  user: any;
  tickets: any[] = [];
  public subscriptions: Subscription[] = [];

  constructor(private conn: ConnectionService,
    private storage: LocalStorage,
    public appConfig: AppConfig,
    private dialog: MatDialog,
    private broadcaster: Broadcaster) {
  }

  editTicket(ticket: any): void {
    const dialogRef = this.dialog.open(ChangeTicketAllocationComponent, {
      data: {
        allocatedDoctor: ticket.get('allocatedDoctor'),
        allocatedOperator: ticket.get('allocatedOperator'),
        teams: ticket.get('teams'),
        skipReAllocateDoctor: ticket.get('skipReAllocateDoctor'),
      },
    });
    dialogRef.afterClosed().subscribe(async (data: any): Promise<any> => {
      if (!data) return;
      const context = {
        reAllocateDoctor: false,
      };
      try {
        if (!data) return;
        if (data.selectedOperator?.id) ticket.set('allocatedOperator', data.selectedOperator);
        if (data.teams?.length) {
          if (data.teams.includes('doctor')) {
            context.reAllocateDoctor = true;
          }
          ticket.set('teams', data.teams);
        }
        ticket.set('skipReAllocateDoctor', data.skipReAllocateDoctor);
        if (data.selectedDoctor?.id) {
          ticket.set('allocatedDoctor', data.selectedDoctor);
        }
        await ticket.save({}, { context });
        this.broadcaster.broadcast('NOTIFY', { message: 'Allocation updated successfully.' });
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    });
  }

  async fetchTickets(): Promise<any> {
    this.tickets = await this.conn.fetchSupportTickets(
      {
        where: { user: this.user },
        descending: 'createdAt',
        include: ['supportQuestion', 'supportQuestion.questionLanguageString' as 'supportQuestion',
          'titleLanguageString', 'createdBy', 'completedBy'],
      });
  }

  openSupportTicketHistory(): void {
    if (!this.user) {
      return;
    }
    this.dialog.open(SupportTicketHistoryComponent, {
      width: '95%',
      data: { user: this.user },
    });
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
