import { Component, Input, Signal, signal } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { Table } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { followupChatFieldsToSkip } from './fields-to-skip-in-followup-chat';
import { AppConfig } from '../../app/app.config';
import { FollowUpDialog } from '../../app/chat/view/follow-up';

interface InvestigationHistoryItem {
  date: Date;
  id: string;
  followUpCount: number;
  testsStatus?: Array<{ name: string; status: boolean }>;
  feedback?: string;
}
@Component({
  selector: 'followup-chat-view-summary',
  templateUrl: './followup-chat-view.html',
})
export class FollowupChatViewComponent {
  @Input('followupId')
  set setFollowUp(followupId: any) {
    if (!followupId) {
      return;
    }
    this.followUpId = followupId;
    this.loadData();
  }

  @Input('user')
  set setUser(user: any) {
    if (!user) {
      return;
    }
    this.user = user;
  }
  @Input('selectedFollowUpClass') selectedFollowUpClass: string = '';
  user: any;
  followUpId: string;
  followUp: any;
  followupChat: Array<any> = [];
  followUpReports: Array<{ key: string, value: string, editable?: boolean, editLink?: string, investigations?: any }> = [];
  // highlightedKeys: Array<string> = ['anyOtherConcernInFollowup', 'married', 'thyroidOrSeriousIllness', 'sensitiveSkin',
  //   'followUpBodyImprovement', 'followUpHairImprovement', 'followUpFaceImprovement', 'ProgressCheckFaceAppointment'];
  highlightedKeys: Array<string> = [];
  thyroidOrSeriousIllness: { value?: string, updatedAt?: Date } = {};
  thyroidOrSeriousIllnessOptions: Array<string> = ['Low iron', 'thyroid', 'Deficiency of any vitamin',
    'Significant weight loss in the last 6 months', 'Feeling excessively tired or stressed', 'None of the above', 'I don\'t know.'];
  displayFollowUpQuestions: any = {
    THYROIDORSERIOUSILLNESS: 'Thyroid Illness',
    FEMALEHORMONAL: 'Female Hormonal',
    MARRIED: 'Married',
    FOLLOWUPFACEIMPROVEMENT: 'Face Improvement Seen',
    FOLLOWUPFACEIMPROVEMENTNOCHANGE: 'Any Other Concern In Face',
    FOLLOWUPHAIRIMPROVEMENT: 'Hair Improvement Seen',
    FOLLOWUPHAIRIMPROVEMENTNOCHANGE: 'Any Other Concern In Hair',
    PRODUCTSUSEDV2FOLLOWUP: 'Products Using',
    PROGRESSCHECKFACEAPPOINTMENT: 'Problems Facing',
    ANYOTHERCONCERNINFOLLOWUP: 'Any Other Concern',
    FOLLOWUPBODYIMPROVEMENT: 'Body Improvement Seen',
    FOLLOWUPBODYIMPROVEMENTNOCHANGE: 'Any Other Concern In Body',
    WAITINGFORIMAGETYPES: 'Photos needed',
  };
  activeRegimens: Array<string> = [];
  activeHairTree: string = '';
  activeFaceTree: string = '';
  investigationHistoryTimeline: Array<InvestigationHistoryItem> = [];
  allFollowUpReports: Array<any> = [];
  readonly panelOpenState: Signal<boolean> = signal<boolean>(false);
  constructor(private conn: ConnectionService,
    private router: Router,
    public appConfig: AppConfig,
    public dialog: MatDialog) {
  }

  private async loadSummary(followupId: string): Promise<void> {
    this.followUp = JSON.parse(JSON.stringify(await this.conn.findOneFollowUp({
      where: { objectId: followupId },
      include: ['regimens'],
      project: ['markedReadyOn'],
    })));
    this.activeRegimens = this.followUp.regimens.map((regimen: any) => regimen.class);
    followupChatFieldsToSkip.forEach((each: string) => delete this.followUp[each]);
    Object.keys(this.followUp).forEach((each: string) => {
      if (this.highlightedKeys.includes(each)) {
        this.followupChat.push({ key: each, value: this.followUp[each], highlight: true });
      } else {
        this.followupChat.push({ key: each, value: this.followUp[each] });
      }
    });
    const answer = await this.conn.findTemporaryAnswer({
      where: { user: this.user, expireOn: { $gte: this.followUp.markedReadyOn } },
      limit: 1,
      ascending: 'expireOn',
      include: ['followUpPlanningPragnancy', 'FollowUpFemalePregOrNewMom', 'followUpPregaNewMom'],
    });
    this.followupChat.push(
      { key: 'followupPlanningPragnancy',
        value: answer[0]?.get('followUpPlanningPragnancy') ? answer[0].get('followUpPlanningPragnancy') : '' });
    this.followupChat.push(
      { key: 'FollowUpFemalePregOrNewMom',
        value: answer[0]?.get('FollowUpFemalePregOrNewMom') ? answer[0]?.get('FollowUpFemalePregOrNewMom') : '' });
    this.followupChat.push(
      { key: 'followUpPregaNewMom',
        value: answer[0]?.get('followUpPregaNewMom') ? answer[0]?.get('followUpPregaNewMom') : '' });
  }

  async loadData(): Promise<void> {
    await this.reset();
    await this.loadSummary(this.followUpId);
    await this.findFemaleHormonalFromActiveConsultationSession(this.user);
    await this.loadCurrentFollowUpReports();
    await this.loadInvestigationHistory();
  }
  async loadInvestigationHistory(): Promise<void> {
    this.investigationHistoryTimeline = [];
    this.allFollowUpReports = await this.conn.findReports({
      where: { user: this.user, isSecondaryComparison: false, investigations: { $exists: true } },
      project: ['type', 'investigations', 'createdAt', 'followUp.followUpCount' as 'followUp'],
    });
    this.allFollowUpReports.forEach((report: any) => {
      const node = {
        date: report.get('createdAt'),
        id: report.id,
        followUpCount: report.get('followUp').get('followUpCount'),
        testsStatus: report.get('investigations')?.testsStatus || [],
        feedback: report.get('investigations')?.feedback || '',
      };
      this.investigationHistoryTimeline.push(node);
    });
  }

  reset(): void {
    this.followUp = undefined;
    this.followupChat = [];
    this.followUpReports = [];
  }
  async findFemaleHormonalFromActiveConsultationSession(user: any): Promise<void> {
    const where = { user, archive: false, PrivateRegimenGiven: { $exists: true } };
    const consultationSessions = await this.conn.findConsultationSession({
      where,
      project: ['FemaleHormonal',
        'ThyroidOrSeriousIllness',
        'SensitiveSkin',
        'PrivateMainConcernClass',
        'PrivateInstantCheckupDebug',
        'ProductsUsing',
        'detailOfTheSkinConcern'],
      descending: 'createdAt',
    });
    if (!consultationSessions.length) {
      return;
    }
    const productsUsed = [];
    let detailOfTheSkinConcern;
    consultationSessions.forEach((session: any) => {
      if (session.get('PrivateMainConcernClass') === 'FACE') {
        this.activeFaceTree = session.get('PrivateInstantCheckupDebug')?.activeTree || '';
      } else if (session.get('PrivateMainConcernClass') === 'HAIR') {
        this.activeHairTree = session.get('PrivateInstantCheckupDebug')?.activeTree || '';
      }
    });
    const femaleHormonalUniqueAnswers = new Set();
    consultationSessions.forEach((session: any) => {
      femaleHormonalUniqueAnswers.add(session.get('FemaleHormonal'));
      if (!this.thyroidOrSeriousIllness.value) {
        const thyroidOrSeriousIllnessValue = this.thyroidOrSeriousIllnessOptions[
          this.convertToNumberIfValid(session.get('ThyroidOrSeriousIllness')) - 1];
        this.thyroidOrSeriousIllness.value = thyroidOrSeriousIllnessValue;
        this.thyroidOrSeriousIllness.updatedAt = session.createdAt;
      }
      if (session.get('ProductsUsing') !== 'None' && session.get('ProductsUsing') !== undefined) {
        productsUsed.push(session.get('ProductsUsing'));
      }
      detailOfTheSkinConcern = session.get('detailOfTheSkinConcern') ? session.get('detailOfTheSkinConcern') : '';
    });
    const productsUsedInString = productsUsed.join(', ');
    this.followupChat.push({ key: 'thyroidOrSeriousIllness',
      value: this.thyroidOrSeriousIllness.value,
      date: this.thyroidOrSeriousIllness.updatedAt });
    this.followupChat.push({ key: 'sensitiveSkin', value: consultationSessions[0].get('SensitiveSkin') });
    this.followupChat.push({ key: 'femaleHormonal', value: Array.from(femaleHormonalUniqueAnswers).toString() });
    this.followupChat.push({ key: 'productsUsed', value: productsUsedInString });
    this.followupChat.push({ key: 'detailOfTheSkinConcern', value: detailOfTheSkinConcern });
    const femaleMarriedRow = await this.conn.findLatestResponseForFields(user, ['FemaleMarried', 'followUpMarried']);
    if (femaleMarriedRow) {
      this.followupChat.push({ key: 'married', value: femaleMarriedRow });
    }
  }

  convertToNumberIfValid(input: string): any {
    if (/^[0-9]+(\.[0-9]*)?$/.test(input)) {
      return parseFloat(input);
    }
    return NaN;
  }

  async loadCurrentFollowUpReports(): Promise<void> {
    const followUp = new Table.FollowUp();
    followUp.id = this.followUpId;
    const reports = await this.conn.findReports({
      where: { user: this.user, isSecondaryComparison: false, followUp },
      project: ['type', 'read', 'beforeInstantCheckup', 'afterInstantCheckup', 'investigations'],
    });
    reports.forEach((report: any) => {
      this.followUpReports.push({ key: report.get('type'),
        value: report.id,
        editLink: `/compare/${report.get('beforeInstantCheckup').id}/${report.get('afterInstantCheckup').id}`
          + `/${this.user.get('username')}?edit=${report.id}`,
        editable: report.get('read') });
    });
  }

  openFollowUpHistory(SelectedPopUpType: string): void {
    this.dialog.open(FollowUpDialog, {
      data: {
        SelectedPopUpType,
        followUp: this.followUp,
        userId: this.user.get('username'),
        chatUser: this.user,
      },
    });
  }
  toggleTestStatus(testIndex: number, itemIndex: number): void {
    const test = this.investigationHistoryTimeline[itemIndex].testsStatus[testIndex];
    test.status = !test.status;
  }

  deleteTest(testIndex: number, itemIndex: number): void {
    this.investigationHistoryTimeline[itemIndex].testsStatus.splice(testIndex, 1);
  }

  async saveInvestigationHistoryTimeline(): Promise<void> {
    this.investigationHistoryTimeline.forEach(async (item: InvestigationHistoryItem, index: number): Promise<void> => {
      const report = new Table.FollowUpReport();
      report.id = this.allFollowUpReports[index].id;
      if (item.testsStatus) {
        const updatedInvestigations = { ...this.allFollowUpReports[index].get('investigations'),
          testsStatus: item.testsStatus,
          feedback: item.feedback };
        await report.save({ investigations: updatedInvestigations });
      }
    });
  }

  hasTestHistory(): boolean {
    return this.investigationHistoryTimeline.some((item: InvestigationHistoryItem) => item.testsStatus?.length > 0);
  }
}
