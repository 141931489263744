import { Component, NgZone, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { AppConfig } from '../../app.config';
import { ConnectionService } from '../../../services/connection-service';
import { ClipboardService } from '../../../services/copyToClipboard-service';
import { WindowRefService } from '../../../services/window-ref-service';
import { Broadcaster } from '../../../components/broadcaster';
import { AddPaymentModal } from './add-payment';
import { FaviconService } from '../../../services/favicon-service';

@Component({
  selector: 'order-view',
  templateUrl: './view.html',
  styleUrls: ['./view.scss'],
})
export class ViewComponent implements OnDestroy {
  orderObj: any;
  note: string = '';
  regimenId: any = '';
  payments: Array<any>;
  subscriptions: Array<Subscription>;
  orderHistory: Array<any>;
  invoices: Array<any>;
  alternateNumber: any;
  mobileNumber: any;
  regimen: any;
  products: Array<any> = [];
  services: any;
  prescriptions: Array<any> = [];
  totalQuantity: number = 0;
  @ViewChild('orderView', { static: false }) orderView: ElementRef;
  @ViewChild('toast', { static: false }) toast: ElementRef;
  message: any;
  ui: { modal: { payment: { open: boolean } } };
  unpaidOrders: Array<any> = [];
  transferPaymentAllowed: boolean;
  skuIds: Array<string> = [];
  orderMedicalRecord: any = {};
  sopFieldsToShow: Array<string> = [];
  sopEditingDoctor: any;
  addedProducts: Array<string> = [];
  removedProducts: Array<string> = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private conn: ConnectionService,
    private zone: NgZone,
    private clipBoardService: ClipboardService,
    private window: WindowRefService,
    private broadcaster: Broadcaster,
    private dialog: MatDialog,
    private faviconService: FaviconService) { }

  async ngOnInit(): Promise<void> {
    this.payments = [];
    this.subscriptions = [];
    this.orderHistory = [];
    this.invoices = [];
    this.ui = { modal: { payment: { open: false } } };
    this.orderObj = new Table.Order();
    await this.subscribeToUrlQueryParams();
    this.onlyProductsInOrder();
    await this.fetchSKUs();
  }

  updateOrderHistory(): void {
    this.conn
      .getOrderLog(this.orderObj)
      .then((orderHistory: any) => this.orderHistory = orderHistory);
  }

  updateTotalQuantity(): void {
    this.orderObj.get('productInfo')?.forEach((product: any) => this.totalQuantity += product.quantity || 1);
    this.orderObj.get('serviceInfo')?.forEach((service: any) => this.totalQuantity += service.quantity || 1);
    this.orderObj.get('couponInfo')?.forEach((coupon: any) => this.totalQuantity += coupon.quantity || 1);
  }

  async fetchSopQuestions(): Promise<void> {
    if (this.orderObj.get('type') !== ApiClientConstant.Order.Type.REGIMEN) return;
    const orderMedicalRecord = await ApiConnector.findOne(Table.OrderMedicalRecord, {
      where: { order: this.orderObj },
      include: ['doctor', 'order'],
    });
    if (!orderMedicalRecord) {
      this.orderMedicalRecord = undefined;
      return;
    }
    this.orderMedicalRecord = orderMedicalRecord.toJSON();
    delete this.orderMedicalRecord.ACL;
    delete this.orderMedicalRecord.createdAt;
    delete this.orderMedicalRecord.updatedAt;
    this.sopEditingDoctor = this.orderMedicalRecord.doctor.DoctorDisplayName;
    delete this.orderMedicalRecord.doctor;
    delete this.orderMedicalRecord.order;
    delete this.orderMedicalRecord.objectId;
    this.sopFieldsToShow = Object.keys(this.orderMedicalRecord);
    const sopRegimen = JSON.parse(JSON.stringify(await ApiConnector.findOne(Table.Regimen, {
      where: {
        regimenId: this.orderMedicalRecord.selectTreeRegimenOption,
        type: ApiClientConstant.Regimen.Type.MAIN,
      },
      include: ['products'],
      project: ['regimenId', 'products.title' as 'products'],
    })));
    const latestRegimen: any = JSON.parse(JSON.stringify(await ApiConnector.findOne(Table.RegimenHistory, {
      where: {
        regimenId: this.orderObj.get('regimenId'),
        doctorVerified: true,
      },
      ascending: 'createdAt',
      limit: 1,
      include: ['products'],
      project: ['regimenId', 'products.title' as 'products'],
    })));
    const changedRegimenProducts = await latestRegimen.products.map((each: any) => each.title);
    const sopRegimenProducts = await sopRegimen.products.map((each: any) => each.title);
    changedRegimenProducts.forEach((each: string) => {
      if (!sopRegimenProducts.includes(each)) {
        this.addedProducts.push(each);
      }
    });
    sopRegimenProducts.forEach((each: string) => {
      if (!changedRegimenProducts.includes(each)) {
        this.removedProducts.push(each);
      }
    });
  }

  async subscribeToUrlQueryParams(): Promise<void> {
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.order) {
        this.orderObj = this.route.parent.snapshot.data.order;
        this.updateTotalQuantity();
        this.updateOrderHistory();
        this.conn.getUserAlternateNumber(this.orderObj.get('user').id)
          .then(([user_]: any) => {
            this.broadcaster.broadcast('ChatUserUpdate', { user: user_ });
            this.faviconService.setFavicon(user_.get('PatientName'));
            this.alternateNumber = user_.get('alternateNumber');
            this.mobileNumber = user_.get('MobileNumber');
          });
        this.fetchPayments();
        this.fetchPrescriptions();
        if (this.orderObj.get('type') === ApiClientConstant.Order.Type.REGIMEN) this.fetchRegimen();
        this.fetchUnpaidOrders();
        this.transferPaymentAllowed = [
          ApiClientConstant.Order.Stage.REFUND_REQUESTED,
          ApiClientConstant.Order.Stage.STUCK_IN_TRANSIT,
          ApiClientConstant.Order.Stage.RETURN_INITIATED,
          ApiClientConstant.Order.Stage.RETURNED,
          ApiClientConstant.Order.Stage.LOST_IN_TRANSIT,
          ApiClientConstant.Order.Stage.CANCELED,
        ].includes(this.orderObj.get('stage'));
      }
    }));
    await this.fetchSopQuestions();
  }

  async fetchUnpaidOrders(): Promise<any> {
    this.unpaidOrders = await this.conn.getOrders({
      where: { stage: [ApiClientConstant.Order.Stage.ONLINE_PAYMENT_PENDING], user: this.orderObj.get('user') },
      include: ['payment'],
    });
  }

  fetchRegimen(): void {
    this.conn
      .getRegimen(this.orderObj.get('regimenId'))
      .then((regimen: any) => {
        if (!regimen) return;
        this.zone.run(() => {
          this.regimenId = regimen.id;
          this.regimen = regimen;
        });
      });
  }

  async fetchSKUs(): Promise<void> {
    if (!this.orderObj.get('skuEntries')) return;
    const ids = this.orderObj.get('skuEntries').map((entry: any) => entry.id);
    const skus = await this.conn.getSKUEntriesById(ids);
    this.skuIds = skus.map((sku: any) => sku.get('uniqueIdentifier'));
  }

  onlyProductsInOrder(): void {
    if (this.orderObj.get('type') === ApiClientConstant.Order.Type.PRODUCT) {
      const productsForProductOrder: Array<string> = this.orderObj.get('products');
      productsForProductOrder.forEach((product: any) => {
        this.products.push({
          name: product.get('title'),
          MRP: product.get('mrp'),
          SP: product.get('price'),
        });
      });
    } else {
      const productsInfo = this.orderObj.get('productInfo');
      const serviceInfo = this.orderObj.get('serviceInfo');
      const products: Array<string> = this.orderObj.get('products').map((product: any) => product.id);
      const services: Array<string> = this.orderObj.get('services').map((service: any) => service.id);
      this.products = productsInfo.filter((product: any) => products.includes(product.id));
      this.services = serviceInfo.filter((service: any) => services.includes(service.id));
    }
  }

  showToastMessage(message: string): void {
    this.message = message;
    this.toast.nativeElement.style.visibility = 'visible';
    setTimeout(() => {
      this.toast.nativeElement.style.visibility = 'hidden';
    }, 3000);
  }

  fetchPayments(): void {
    this.conn
      .getOrderPayments({ order: this.orderObj })
      .then((payments: any) => this.zone.run(() => this.payments.push(...payments)));
  }

  openAddPaymentModal(): void {
    const orderObj = JSON.parse((JSON.stringify(this.orderObj)));
    const dialogRef = this.dialog.open(AddPaymentModal, { data: { order: orderObj } });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result: any) => {
      this.addPayment(result);
    }));
  }

  addPayment(payment: any): void {
    this.ui.modal.payment.open = false;
    if (!payment) return;
    this.payments.unshift(payment);
  }

  async updateTrackingDetails(): Promise<void> {
    try {
      await this.conn.createQueueEvent(
        this.orderObj.get('user').id,
        'CLICK_POST_TRACKING_UPDATE',
        new Date(),
        { orderId: this.orderObj.id },
        `${this.conn.getCurrentUser().get('username')}_${new Date().getTime()}`);
      this.showToastMessage('Request has been accepted. Check order status after 5min.');
    } catch (error) {
      this.showToastMessage(`ERROR: ${error.message || error}`);
    }
  }

  async processWarehouse(): Promise<void> {
    try {
      await this.conn.createQueueEvent(
        this.orderObj.get('user').id,
        'SELECT_WAREHOUSE_AND_CREATE_ORDER',
        new Date(),
        { orderId: this.orderObj.id },
        `${this.conn.getCurrentUser().get('username')}_${this.orderObj.id}_${moment().format('YYYY-MM-DD')}`);
      this.showToastMessage('Success');
    } catch (error) {
      this.showToastMessage(`ERROR: ${error.message || error}`);
    }
  }

  async assignNewAWB(): Promise<void> {
    try {
      await this.conn.assignNewAWB(this.orderObj.id);
      this.showToastMessage('Success');
    } catch (error) {
      this.showToastMessage(`ERROR: ${error.message || error}`);
    }
  }

  async sendEmiMessage(): Promise<void> {
    try {
      await this.conn.sendEMIMessageToUser(this.orderObj.id);
      this.showToastMessage('Success');
    } catch (error) {
      this.showToastMessage(`ERROR: ${error.message || error}`);
    }
  }

  addLabelRemovalNote(note: string): void {
    if (!note) {
      return;
    }
    this.note = note;
  }

  generateInvoiceUrl(): void {
    this.conn
      .generateInvoice(this.orderObj.id)
      .then(() => this.showToastMessage('Invoice generated'))
      .catch(() => this.showToastMessage('Unable to generate invoice'));
  }

  generatePrescriptionUrl(): void {
    this.conn.generatePrescriptionForRegimen(this.regimen.id)
      .then(() => this.showToastMessage('Prescription generated'))
      .catch(() => this.showToastMessage('Unable to generate prescription'));
  }

  callUserThroughExotel(number: any): void {
    this.conn.callThroughExotel(number, 'OrderViewPage')
      .then(() => this.showToastMessage('Connecting'))
      .catch(() => this.showToastMessage('Unable to connect to exotel'));
  }

  copyToClipboard(field: any): void {
    this.clipBoardService.copy(String(field));
  }

  async movePayment(): Promise<any> {
    const orderId = this.window.nativeWindow.document.getElementById('transferPayment').value;
    const order = this.unpaidOrders.find((each: any) => each.id === orderId);
    if (!order || !orderId) return alert('no order found');
    const [payment]: any = await this.conn.getOrderPayments({ order: this.orderObj });
    if (!payment) return alert('no payment found');
    const notes = payment.get('notes') ? `${payment.get('notes')}. ` : '';
    payment.set('order', order);
    payment.set('notes', `${notes} Payment transferred from order ${this.orderObj.id}`);
    try {
      await payment.save();
      alert('Payment transferred successfully.');
    } catch (err) {
      alert(err.toString());
    }
    return Promise.resolve();
  }

  activeContactNumber(number: any): void {
    this.orderObj.set('activeContactNumber', number);
    this.orderObj.save();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.router;
    delete this.route;
    delete this.appConfig;
    delete this.subscriptions;
    delete this.orderObj;
  }

  private async fetchPrescriptions(): Promise<void> {
    if (this.orderObj.get('prescriptions')?.length) {
      this.prescriptions = await this.conn.findPrescription({
        where: { objectId: this.orderObj.get('prescriptions').map((prescription: any): string => prescription.id) },
        option: { context: { signedURL: true } },
      });
      return;
    }
    if (this.orderObj.get('regimen')) {
      this.prescriptions = await this.conn.fetchAllPrescription(this.orderObj.get('user').id, this.orderObj.get('regimen')?.id);
      return;
    }
    const regimens = await this.conn.findRegimens({ where: { forUser: this.orderObj.get('user'), active: true } });
    this.prescriptions = (await Promise.all(regimens
      .map((each: any) => this.conn.fetchAllPrescription(this.orderObj.get('user').id, each.id)))).flat();
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
