import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReminderFormComponent, ReminderListComponent } from './components';

const routes: Routes = [
  { path: 'reminder', component: ReminderListComponent },
  { path: 'reminder/new', component: ReminderFormComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReminderRoutingModule { }
