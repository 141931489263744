import { Input, Component } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'toast-message',
  templateUrl: './toast-message.html',
  styleUrls: ['./toast-message.scss'],
})
export class ToastMessage {
  @Input('title') title: string;
  @Input('message') message: string;
  @Input('type') type: string;

  constructor(public appConfig: AppConfig) {
  }

  ngOnInit(): void {
  }
}
