import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { addDays, differenceInDays, isAfter, startOfDay, addHours, subDays } from 'date-fns';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';

@Component({
  selector: 'dashboard-followup',
  templateUrl: './followup.html',
  styleUrls: ['../dashboard.scss'],
})
export class DashboardFollowupComponent {
  startDate: Date;
  endDate: Date;
  user: Table.User;

  // followup variables
  minFollowupsToDone: number = 35;
  currentFollowups: number = 0;
  finishedFollowups: number = 0;
  todayTotalApprovals: number = 0;
  diffInDays: number = 1;
  dataItems: Array<{ title: string, value: number }> = [];

  @Input('userDetails')
  set updateStartDate(userDetails: { startDate: Date, endDate: Date, user: Table.User }) {
    this.startDate = addHours(startOfDay(userDetails.startDate), 6);
    this.endDate = addHours(startOfDay(userDetails.endDate), 6);
    this.user = userDetails.user;
    this.getFollowupDetails();
  }

  constructor(private conn: ConnectionService,
    private storage: LocalStorage) {
  }

  async getCurrentFollowUps(): Promise<number> {
    return ApiConnector.count(Table.FollowUp, {
      where: {
        allocatedDoctor: this.user,
        doctorConfirmed: true,
        State: ApiClientConstant.FollowUp.State.PENDING,
        ready: true,
      },
    });
  }

  async getFinishedFollowUps(): Promise<number> {
    return ApiConnector.count(Table.FollowUp, {
      where: {
        allocatedDoctor: this.user,
        doctorConfirmed: true,
        completedOn: {
          $gte: this.startDate,
          $lte: this.endDate,
        },
        State: ApiClientConstant.FollowUp.State.FINISHED,
      },
    });
  }

  async getWFIFollowUps(): Promise<number> {
    return ApiConnector.count(Table.FollowUp, {
      where: {
        allocatedDoctor: this.user,
        State: ApiClientConstant.FollowUp.State.WAITING_FOR_IMAGE,
        capturedAt: {
          $gte: this.startDate,
          $lte: this.endDate,
        },
      },
    });
  }

  async getTwoRegimenFollowUps(): Promise<number> {
    const allReportsSentByDoctor = await this.conn.findReports({
      where: {
        doctor: this.user,
        isSecondaryComparison: false,
        createdAt: {
          $gte: this.startDate,
          $lte: this.endDate,
        },
      },
      project: ['followUp', 'type'],
      limit: 1000,
    });
    const uniqueFollowUps = {};
    let totalTwoRegimenFollowups = 0;
    allReportsSentByDoctor.forEach((report: any) => {
      const followUpId = report.get('followUp')?.id;
      uniqueFollowUps[followUpId] = (uniqueFollowUps[followUpId] || 0) + 1;
      if (uniqueFollowUps[followUpId] > 1) {
        totalTwoRegimenFollowups += 1;
      }
    });
    return totalTwoRegimenFollowups;
  }

  async getPendingFollowUps(): Promise<number> {
    const yesterdayCompleted = await ApiConnector.count(Table.FollowUp, {
      where: {
        allocatedDoctor: this.user,
        State: ApiClientConstant.FollowUp.State.FINISHED,
        completedOn: {
          $gte: addHours(startOfDay(subDays(this.startDate, 1)), 6),
          $lte: addHours(startOfDay(this.endDate), 6),
        },
      },
    });
    let pendingFollowups = this.minFollowupsToDone - yesterdayCompleted;
    if (pendingFollowups < 0) {
      pendingFollowups = 0;
    }
    return pendingFollowups;
  }

  async getFollowupDetails(): Promise<void> {
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.dataItems = [];
    if (!this.startDate || !this.endDate || !this.user) {
      return;
    }
    this.dataItems = [];
    this.diffInDays = differenceInDays(this.endDate, this.startDate);
    if ((this.user.get('userType') || []).includes(ApiClientConstant.User.UserType.DERMATOLOGIST)) {
      this.minFollowupsToDone = ApiClientConstant.Doctor.DermatDailyLoad.maxFollowUpLoad;
    } else {
      this.minFollowupsToDone = ApiClientConstant.Doctor.MbbsDailyLoad.maxFollowUpLoad;
    }
    if (isAfter(this.startDate, this.endDate)) {
      return;
    }
    this.finishedFollowups = await this.getFinishedFollowUps();
    this.todayTotalApprovals = this.finishedFollowups;
    const currentFollowups = await this.getCurrentFollowUps();
    const currentWaitingForImageFollowups = await this.getWFIFollowUps();
    const totalTwoRegimenFollowups = await this.getTwoRegimenFollowUps();
    const pendingFollowups = await this.getPendingFollowUps();
    this.dataItems.push(
      { title: 'Two Regimen FollowUps', value: totalTwoRegimenFollowups },
      { title: 'Captured/Allocated', value: currentFollowups },
      { title: 'Old Pending', value: pendingFollowups },
      { title: 'WFI', value: currentWaitingForImageFollowups },
    );
  }
}
