import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from '../../../../../components/directives.module';
import { IndicationEditComponent } from './indication-edit.component';
import { QuestionOptionMappingModal } from './question-option-mapping';
import { QuestionOptionRearrangeModal } from './question-option-rearrange';
import { RegimenSelectionModule } from '../../../../../components/regimen-selection';
import { MultiRegimenSelectionModule } from '../../../../../components/multi-regimen-selection';
import { AddQuestionModule } from './add-question/add-question.module';

const ROUTES: Routes = [
  { path: '', component: IndicationEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    DirectivesModule,
    RegimenSelectionModule,
    MultiRegimenSelectionModule,
    AddQuestionModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [IndicationEditComponent, QuestionOptionMappingModal, QuestionOptionRearrangeModal],
  exports: [IndicationEditComponent, QuestionOptionMappingModal, QuestionOptionRearrangeModal],
})
export class IndicationEditModule {
}
