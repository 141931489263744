import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'add-review-dialog',
  templateUrl: './add-review-dialog.html',
  styleUrls: ['./add-review-dialog.scss'],
})
export class AddReviewDialog {
  userObject: any;
  popUpModel: { loading: boolean };
  doctorReviewObject: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
      private data: any,
      private dialogRef: MatDialogRef<AddReviewDialog>,
      private appConfig: AppConfig,
  ) {
    this.userObject = this.data.user;
    this.popUpModel = { loading: false };
  }

  async ngOnInit(): Promise<any> {
    // this.doctorReviewObject = new Table.DoctorReviews();
  }

  async hideModal(): Promise<any> {
    this.dialogRef.close();
  }

  async updateDoctorReview(): Promise<any> {
    this.doctorReviewObject.set('doctor', this.userObject);
    await this.doctorReviewObject.save();
    this.dialogRef.close();
  }
}
