import { Component, Input } from '@angular/core';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'support-ticket-list',
  templateUrl: './ticket-list.html',
  styleUrls: ['./ticket-list.scss'],
})
export class SupportTicketListComponent {
  @Input('user')
  set onUpdateUser(user: any) {
    if (!user) {
      return;
    }
    this.user = user;
    this.loadTicket();
  }
  user: string;
  supportTicket: Array<any> = [];
  constructor(public appConfig: AppConfig, private connectionService: ConnectionService) {}

  async loadTicket(): Promise<void> {
    this.supportTicket = await this.connectionService.findSupportTicket({
      where: { user: this.user },
      include: ['supportQuestion', 'SBAR'],
      project: ['status', 'completed', 'supportQuestion.question' as 'supportQuestion', 'SBAR', 'user'],
      descending: 'createdAt',
    });
  }
}
