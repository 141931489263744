import { Component } from '@angular/core';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../../../services/connection-service';

@Component({ selector: 'add-product-modal', templateUrl: './add-product.html' })
export class AddProductModal {
  image: string = '';
  autoCompleteController: UntypedFormControl = new UntypedFormControl();
  options: Observable<Array<{ name: string; object: any }>>;
  public subscriptions: Subscription[] = [];
  constructor(private conn: ConnectionService, private dialogRef: MatDialogRef<AddProductModal>) {
  }

  ngOnInit(): void {
    this.autoCompleteController.setValue('');
    this.options = this.autoCompleteController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    if (!name || name.length < 1) {
      return [];
    }
    const products = await this.conn.findCatalogs({
      where: {
        $or: [
          { title: { $regex: name, $options: 'i' } },
          { uniqueIdentifier: { $regex: name, $options: 'i' } },
        ],
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
      },
      project: ['title'],
      limit: 10,
    });
    return products.map((object: any): { name: string; object: any } => ({ name: `${object.get('title')}`, object }));
  }

  hideModal(data: any): void {
    this.dialogRef.close(data);
  }

  autoCompleteOnSelect(item: { name: string; object: any }): void {
    this.hideModal(item.object);
  }
}
