import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from '../../../components/directives.module';
import { ChatViewComponent } from './chat-view.component';
import { ResponseTitleComponent } from './response-title.component';
import { LanguageViewModule } from '../../../components/language-string/language-view';
import { InputCheckboxModule } from '../../../components/input/input-checkbox';
import { AssignChatComponent } from './assign-chat';
import { AssignChatConfirmationComponent } from './blocked-bot-assistant/assign-chat-confirmation/assign-chat-confirmation.component';
import { AssignChatHistoryComponent } from './blocked-bot-assistant/assign-chat-history/assign-chat-history.component';
import { ImageUploadModal } from '../../../components/modal/imageUploadModal';
import { ImageUploadDialog } from './instant-checkup/image-upload-dialog';
import { CannedResponseSelectionModule } from '../../../components/canned-response/canned-response-selection';
import { UserChatViewModule } from '../../../components/user-chat-view';
import { EditInstantCheckup } from './instant-checkup/edit-instant-checkup';
import { InvestigationModule } from '../../compare-progress/investigation';
import { AddWaitingCallDialogComponent } from './add-waiting-call-dialog';
import { InstantCheckupModule } from './instant-checkup/instant-checkup.module';
import { UserListDialog } from './user-list';
import { ConsultationSessionsList } from './consultation-dialog';
import { TicketListModule } from './ticket-list';
import { TreeTriggerModule } from './tree-trigger';
import { CallLogModule } from './call-logs';
import { SupportChatViewModule } from './support-chat-view';
import { WaitingCallModule } from './waiting-call';
import { ConsultationListModule } from './consultation-list';
import { SBARModule } from './SBAR';
import { CartListModule } from './cart-list';
import { ChatSidebarModule } from './chat-sidebar/chat-sidebar.module';
import { SendMessageSectionModule } from './send-message-section/send-message-section.module';
import { FollowupSectionModule } from './followup-section/followup-section.module';
import { FollowUpDialog } from './follow-up';
import { UserNoteModule } from '../../../components/user-notes';
import { UserTagsModule } from './user-tags';

const ROUTES: Routes = [
  { path: '', component: ChatViewComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    DirectivesModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCardModule,
    MomentModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    LanguageViewModule,
    InputCheckboxModule,
    RouterModule.forChild(ROUTES),
    CannedResponseSelectionModule,
    UserChatViewModule,
    InstantCheckupModule,
    TicketListModule,
    TreeTriggerModule,
    CallLogModule,
    SupportChatViewModule,
    WaitingCallModule,
    ConsultationListModule,
    SBARModule,
    CartListModule,
    ChatSidebarModule,
    SendMessageSectionModule,
    FollowupSectionModule,
    UserNoteModule,
    UserTagsModule,
  ],
  declarations: [
    ChatViewComponent,
    ResponseTitleComponent,
    AssignChatHistoryComponent,
    AssignChatConfirmationComponent,
    AssignChatComponent,
    ImageUploadDialog,
    EditInstantCheckup,
    InvestigationModule,
    AddWaitingCallDialogComponent,
    UserListDialog,
    ConsultationSessionsList,
    FollowUpDialog,
  ],
  exports: [ChatViewComponent, FollowUpDialog],
})
export class ChatViewModule {}
