
<div class="d-flex flex-box">
  <div class="badge bg-black text-white mt-1 d-flex" *ngFor="let each of regimenIds; let i = index;" style="color: white !important">
    <div>{{each}}</div><i class="fa fa-times pl-2 pr-2 pointer" (click)="removeRegimen(i)"></i>
  </div>
</div>
<div class="d-flex mt-1">
  <input class="form-control p-0" [(ngModel)]="importantVariableForInputRequired" [required]="!regimenIds.length" type="text" name="{{name}}_verify" style="width:1px;"/>
  <input class="form-control" type="text" [(ngModel)]="inputText" placeholder="Select REGIMEN." name="{{name}}_select" autocomplete="off" matInput="matInput" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
  <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
    <mat-option *ngFor="let option of regimenOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteRegimenOnSelect(option)">{{option.name}}</mat-option>
  </mat-autocomplete>
</div>