
<div popup="">
  <div popupHeading="popupHeading"><em class="fas fa-filter" (click)="editFilters()" [ngClass]="{'clr-green': isFilterApplied}"></em></div>
  <div popupContent="popupContent">
    <div class="p-3 col-xs-12" style="min-width: 500px;" (click)="$event.stopPropagation();">
      <div class="col-xs-12 mt-3 p-0" *ngFor="let row of activeFilters;">
        <div class="col-xs-3 p-0 pr-3">
          <select class="form-control" [(ngModel)]="row.keyIndex" (ngModelChange)="setKey(row.keyIndex, row)" name="key">
            <option *ngFor="let input of inputArray;let i = index;" value="{{i}}">{{input.key}}</option>
          </select>
        </div>
        <div class="div" *ngIf="row.keyIndex !== undefined">
          <div class="col-xs-9" *ngIf="row.keyIndex === 1">
            <select class="form-control" [(ngModel)]="row.filterType" name="filterType">
              <option *ngFor="let filter of CONDITION_MAP[inputArray[row.keyIndex].type];" value="{{filter.key}}">{{filter.display}}</option>
            </select>
          </div>
          <div class="col-xs-3" *ngIf="row.keyIndex !== 1">
            <select class="form-control" [(ngModel)]="row.filterType" name="filterType">
              <option *ngFor="let filter of CONDITION_MAP[inputArray[row.keyIndex].type];" value="{{filter.key}}">{{filter.display}}</option>
            </select>
          </div>
          <div *ngIf="row.keyIndex !== 1">
            <div class="col-xs-6 pl-3" *ngIf="row.filterType === FILTER_TYPE.ENDS_WITH || row.filterType === FILTER_TYPE.STARTS_WITH || row.filterType === FILTER_TYPE.IS || row.filterType === FILTER_TYPE.EQUALS">
              <input class="form-control" [(ngModel)]="row.value" placeholder="value"/>
            </div>
            <div class="col-xs-6 pl-3" *ngIf="row.filterType === FILTER_TYPE.BOOLEAN_EQUAL_TO">
              <select class="form-control" [(ngModel)]="row.booleanValue" (ngModelChange)="setBoolean(row.booleanValue)" name="item.type">
                <option *ngFor="let value of keyValueTrueFalse;" value="{{value}}">{{value}}</option>
              </select>
            </div>
            <div class="col-xs-6 pl-3" *ngIf="row.filterType === FILTER_TYPE.EQUAL_TO">
              <select class="form-control" multiple="true" [(ngModel)]="row.value" name="selectedvalue" #selectedValue="ngModel">
                <option *ngFor="let value of inputArray[row.keyIndex].value;" value="{{value}}">{{value}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 mt-3 p-0">
        <button class="btn-xs" (click)="activeFilters.push({})">Add</button>
        <button class="btn-danger btn-xs ml-3" (click)="clearFilter()">Clear</button>
        <button class="pull-right btn-xs" (click)="applyFilter()">Apply</button>
      </div>
    </div>
  </div>
</div>