
<div class="col-xs-12 p-0" *ngIf="user">
  <div class="col-xs-12 title"><em class="fas fa-prescription-bottle-alt pr-3"></em><span>Support Ticket</span><a class="fa fa-plus-square-o ml-3 pointer" routerLink="/chats/open-tickets/new" [queryParams]="{ userId: user.id }" target="_blank"></a><a class="fa fa-history ml-3" (click)="openSupportTicketHistory()"></a></div>
  <div class="col-xs-12">
    <label class="col-xs-4">title</label>
    <label class="col-xs-2">created By</label>
    <label class="col-xs-2">completed By</label>
    <label class="col-xs-2">created At</label>
    <label class="col-xs-2">closed At</label>
  </div>
  <div class="col-xs-12 of-auto" style="max-height:185px;">
    <div class="mb-3 col-xs-12 p-0" *ngFor="let ticket of tickets;let i = index;">
      <col-xs-12 class="text-info bold" [ngClass]="{ 'text-danger': ticket.get('status') === apiClientConstant.SupportTicket.Status.Completed }">
        <div class="col-xs-4"><a routerLink="/ticket/{{user.id}}" [queryParams]="{ ticketId: ticket.id }" target="_blank">{{ticket.get('ticketNumber')}} : {{ticket.get('title') || ticket.get('titleLanguageString')?.get('en')}}</a><em class="fas fa-edit pl-3" *ngIf="ticket.get('status') !== apiClientConstant.SupportTicket.Status.Completed" (click)="editTicket(ticket)"></em></div>
        <div class="col-xs-2"><span *ngIf="ticket.get('createdBy')?.get('type')">{{ticket.get('createdBy')?.get('username') || ticket.get('createdBy')?.get('DoctorDisplayName')}}</span></div>
        <div class="col-xs-2"><span *ngIf="ticket.get('completedBy')?.get('type')">{{ticket.get('completedBy')?.get('username') || ticket.get('completedBy')?.get('DoctorDisplayName')}}</span></div>
        <div class="col-xs-2">{{ticket.get("createdAt") | amDateFormat: 'DD MMM YY'}}</div>
        <div class="col-xs-2"><span *ngIf="ticket.get('closedAt')">{{ticket.get("closedAt") | amDateFormat: 'DD MMM YY'}}</span></div>
      </col-xs-12>
    </div>
  </div>
</div>