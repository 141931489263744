import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MargIdInfoListComponent } from './marg-id-info.component';
import { DirectivesModule } from '../../../../components/directives.module';

const ROUTES: Routes = [
  { path: '', component: MargIdInfoListComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    RouterModule.forChild(ROUTES),
    AgGridAngular,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
  declarations: [MargIdInfoListComponent],
  exports: [MargIdInfoListComponent],
})
export class MargIdListModule {}
